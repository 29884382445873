<section class="slider-section">
  <nav class="navbar navbar-expand-lg navbar-light bg-transparent px-3 mt-2">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img src="../../assets/img/logo-dark.png" alt="">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div *ngIf="currentUser && currentUser['profile']" class="collapse navbar-collapse flex-grow-1 text-right" id="myNavbar">
        <ul class="navbar-nav ms-auto flex-nowrap">
          <li class="nav-item dropdown mr-2">
            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img *ngIf="currentUser['profile']['image']" class="profile-img rounded-circle" src="{{ currentUser['profile']['image'] }}" alt="">
              <div *ngIf="!currentUser['profile']['image']" class="profile-img-div d-flex justify-content-center align-items-center">
                <p id="navbar-profile-name" class="profile-name text-uppercase">{{ currentUser["username"].slice(0, 2) }}</p>
              </div>
            </a>
            <div class="dropdown-menu mb-2" aria-labelledby="navbarDropdown">
              <a class="dropdown-item cursor-pointer" (click)="logout()">Log Out</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</section>
<section class="cities-section vh-100">
  <div class="city-section-title text-center text-uppercase px-5 pt-5">
    <p class="mt-5">Projects</p>
  </div>
  <div class="row p-5">
    <div *ngFor="let project of projects" class="col-md-12 col-lg-4">
      <div class="card city-card cursor-pointer m-2 mb-4" (click)="selectProject(project)">
        <img *ngIf="project['image']" src="{{ project['image'] }}">
        <img *ngIf="!project['image']" src="../../../assets/img/no-image.jpg" style="object-fit: fill !important;">
        <div class="card-body text-center">
          <h5 class="card-title my-2">{{ project["name"] }}</h5>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-12 col-lg-4">
      <div class="card city-card cursor-pointer d-flex justify-content-center align-items-center m-2 mb-4" data-bs-toggle="modal" data-bs-target="#projectModal" style="height: 92%">
        <div class="d-flex justify-content-center align-items-center">
          <i class="plus-icon fa fa-plus fa-5x"></i>
        </div>
      </div>
    </div> -->
  </div>
</section>

<!-- Project Modal -->
<!-- <div class="modal fade" id="projectModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header p-3 mx-3 mt-3">
        <h5 class="modal-title text-uppercase">Create New Project</h5>
        <button type="button" class="btn-close btn-lg" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-3">
      </div>
      <div class="modal-footer px-3">
      </div>
    </div>
  </div>
</div> -->
