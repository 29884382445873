<section class="slider-section">
  <nav class="navbar navbar-expand-lg navbar-light bg-transparent px-3">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img src="../../assets/img/logo.png" alt="">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div *ngIf="currentUser && currentUser['profile']" class="collapse navbar-collapse flex-grow-1 text-right" id="myNavbar">
        <ul class="navbar-nav ms-auto flex-nowrap">
          <li class="nav-item dropdown mr-2">
            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img *ngIf="currentUser['profile']['image']" class="profile-img rounded-circle" src="{{ currentUser['profile']['image'] }}" alt="">
              <div *ngIf="!currentUser['profile']['image']" class="profile-img-div d-flex justify-content-center align-items-center">
                <p id="navbar-profile-name" class="profile-name text-uppercase">{{ currentUser["username"].slice(0, 2) }}</p>
              </div>
            </a>
            <div class="dropdown-menu mb-2" aria-labelledby="navbarDropdown">
              <a class="dropdown-item cursor-pointer" href="/">Homepage</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item cursor-pointer" (click)="logout()">Log Out</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</section>

<section *ngIf="isSettings" class="settings-section" style="height: 100vh !important;">
  <div class="container py-5">
    <h5 class="header-text text-uppercase m-2 mb-4 mt-5">Settings</h5>
    <div class="m-2 mb-4">
      <form class="settings-form" [formGroup]="settingsForm" (ngSubmit)="saveSettings(settingsForm.value)">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td class="w-25">
                <label class="required-field me-3 mt-2">Users:</label>
              </td>
              <td class="w-75">
                <select class="form-select" aria-label="multiple select example" formControlName="user" (change)="getUserProjects()">
                  <option disabled>Select a user</option>
                  <option *ngFor="let user of users" value="{{ user['id'] }}">{{ user['user']['username'] }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="w-25">
                <label class="required-field me-3 mt-2">Projects:</label>
              </td>
              <td class="w-75">
                <select class="form-select" aria-label="multiple select example" formControlName="project" (change)="getProjectFeatures()">
                  <option disabled>Select a project</option>
                  <option *ngFor="let project of projects" value="{{ project['id'] }}">{{ project['name'] }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="w-25">
                <label class="required-field me-3 mt-2">Features:</label>
              </td>
              <td class="w-75">
                <div *ngFor="let feature of features" class="d-flex">
                  <div class="form-check form-switch mb-3">
                    <input class="form-check-input cursor-pointer" type="checkbox" [(ngModel)]="feature['active']" id="{{ 'flexSwitchCheckDefault' +  feature['id'] }}" formControlName="{{ feature['slug'] }}" >
                    <label class="form-check-label cursor-pointer" for="{{ 'flexSwitchCheckDefault' +  feature['id'] }}">{{ feature['name'] }}</label>
                  </div>
                  <i class="fa fa-picture-o ms-3 mt-1 cursor-pointer" aria-hidden="true" data-bs-toggle="modal" data-bs-target="#imageModal" (click)="updateSelectedFeature(feature['slug'])"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="w-75">
                <div class="text-center">
                  <button type="submit" class="btn save-btn px-5 mt-3">Save</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>

  <!-- Section Image Modal -->
  <div class="modal fade" id="imageModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header p-3 mx-3 mt-3">
          <h5 class="modal-title">Section Image</h5>
          <button type="button" class="btn-close btn-lg" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body d-flex justify-content-center p-3">
          <img *ngIf="selected_feature" src='{{"../../assets/img/" + selected_feature + ".png"}}'>
        </div>
      </div>
    </div>
  </div>
</section>


<section *ngIf="isProjects" class="settings-section">
  <app-project></app-project>
</section>
