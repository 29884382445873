<nav id="navbar" class="navbar navbar-expand-lg sticky-top navbar-dark navbar-main">
  <div class="container-fluid p-4 px-5">
    <a id="navbar-brand" class="navbar-brand" href="/">
      <img src="../../assets/img/logo.png" alt="">
    </a>
    <a id="navbar-brand-dark" class="navbar-brand-dark" href="/">
      <img src="../../assets/img/logo-dark.png" alt="">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarScroll">
      <ul class="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 100px;">
        <!-- <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="/">HOME</a>
        </li> -->
        <!-- <li class="nav-item">
          <a class="nav-link active" href="#">ABOUT US</a>
        </li> -->
        <li *ngIf="userLogin" class="nav-item d-flex align-items-center">
          <a class="nav-link active" href="/dashboard">DASHBOARD</a>
        </li>
        <li class="nav-item d-flex align-items-center">
          <a class="nav-link active" href="#contact-section">CONTACT US</a>
        </li>
        <li *ngIf="!userLogin" class="nav-item">
          <a class="nav-link active" href="#" data-bs-toggle="modal" data-bs-target="#loginModal" (click)=resetLoginForm()>LOGIN/SIGN UP</a>
        </li>
        <li *ngIf="userLogin && currentUser && currentUser['profile']" class="nav-item text-right dropdown mr-2">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <img *ngIf="currentUser['profile']['image']" class="profile-img rounded-circle" src="{{ currentUser['profile']['image'] }}" alt="">
            <div *ngIf="!currentUser['profile']['image']" class="profile-img-div d-flex justify-content-center align-items-center">
              <p id="navbar-profile-name" class="profile-name text-uppercase">{{ currentUser["username"].slice(0, 2) }}</p>
            </div>
          </a>
          <div class="dropdown-menu mb-2" aria-labelledby="navbarDropdown">
            <!-- <a class="dropdown-item cursor-pointer" href="/dashboard">Dashboard</a>
            <div class="dropdown-divider"></div> -->
            <a class="dropdown-item" (click)="logout()">Log Out</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- Login Modal -->
<div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <h2 class="login-header my-5">Login</h2>
        <div class="text-left mx-5 mb-5 px-3">
          <form class="login-form" [formGroup]="loginForm" (ngSubmit)="login(loginForm.value)">
            <div class="form-group mb-4">
              <label class="required-field" for="exampleFormControlInput1">Username</label>
              <input type="text" class="form-control login-input" formControlName="username">
              <span class="validation-message pl-2 mt-" *ngIf="(username.dirty || username.touched) && username.invalid && username.errors.required">
                Username is required
              </span>
            </div>
            <div class="form-group mb-4">
              <label class="required-field" for="exampleFormControlInput1">Password</label>
              <input type="password" class="form-control login-input" formControlName="password">
              <span class="validation-message pl-2 mt-2" *ngIf="(password.dirty || password.touched) && password.invalid && password.errors.required">
                Password is required
              </span>
            </div>
            <div class="text-center">
              <span *ngIf="username.value != '' && password.value != '' && message" class="message">
                {{ message }}
              </span>
              <button type="submit" class="btn btn-dark login-btn px-5 my-3 cursor-pointer" [disabled]="!loginForm.valid">LOGIN</button>
            </div>
          </form>
          <a class="register-link text-uppercase cursor-pointer" href="#" data-bs-toggle="modal" data-bs-target="#registerModal">Register</a>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Register Modal -->
<div class="modal fade" id="registerModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <h2 class="login-header my-5">Register</h2>
        <div class="text-left mx-5 mb-5 px-3">
          <form class="login-form" [formGroup]="registerForm" (ngSubmit)="register(registerForm.value)">
            <div class="form-group mb-4">
              <label class="required-field" for="exampleFormControlInput1">Username</label>
              <input type="text" class="form-control login-input" formControlName="register_username">
              <span *ngIf="register_message" class="validation-message pl-2 mt-2">
                {{ register_message }}
              </span>
              <span class="validation-message pl-2 mt-2" *ngIf="(register_username.dirty || register_username.touched) && register_username.invalid && register_username.errors.required">
                Username is required
              </span>
            </div>
            <div class="form-group mb-4">
              <label class="required-field" for="exampleFormControlInput1">Password</label>
              <input type="password" class="form-control login-input" formControlName="register_password">
              <span class="validation-message pl-2 mt-2" *ngIf="(register_password.dirty || register_password.touched) && register_password.invalid && register_password.errors.required">
                Password is required
              </span>
            </div>
            <div class="text-center">
              <span *ngIf="register_username.value != '' && register_password.value != '' && register_message" class="message">
                {{ message }}
              </span>
              <button type="submit" class="btn btn-dark login-btn px-5 my-3 cursor-pointer " [disabled]="!registerForm.valid">REGISTER</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
