import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';

import { DataService } from 'src/app/services/data.service';

import { Options, ChangeContext } from '@angular-slider/ngx-slider';
import { Color } from 'ng2-charts';
import { ChartDataSets, ChartOptions } from 'chart.js';


@Component({
  selector: 'app-brand-radar',
  templateUrl: './brand-radar.component.html',
  styleUrls: ['./brand-radar.component.css']
})
export class BrandRadarComponent implements OnInit {


  selectedProject = {};
  selectedProjectId: Number;
  selectedBrand = {};
  is_selected_brand_ready: boolean = false;
  is_sended_request: boolean = false;

  districts = [];
  categories = [];
  sizes = [];
  strategy_focuses = [];

  statuses = [
    {"id": "trending", "name": "Trending"},
    {"id": "stable", "name": "Stable"},
    {"id": "slowing-down", "name": "Slowing Down"},
    {"id": "declining", "name": "Declining"},
  ];

  fact_sheet_statuses = [
    {"id": "available", "name": "Available"},
    {"id": "unavailable", "name": "Unavailable"},
    {"id": "requested", "name": "Requested"}
  ];

  distances = [
    {"id": "out_of_country", "name": "Out of Country"},
    {"id": "out_of_town", "name": "Out of Town"},
    {"id": "walking_distance", "name": "Walking Distance"},
    {"id": "online_only", "name": "Online Only"}
  ];

  orders = [
    {"id": "instagram_account", "name": "Name", "isSelected": false},
    {"id": "rank", "name": "Rank", "isSelected": false},
    {"id": "follower_count", "name": "Follower Count", "isSelected": false},
    {"id": "google_rating", "name": "Review Rating", "isSelected": false},
    {"id": "popularity_score_month", "name": "Popularity Score-30 days", "isSelected": false},
    {"id": "popularity_score_year", "name": "Popularity Score-1 year", "isSelected": false}
  ];

  minDistanceValue: number = 0;
  maxDistanceValue: number = 30000;
  distanceOptions: Options = {
    floor: 0,
    ceil: 30000,
    // step: 100
  };
  selectedDistanceNumber = "";
  distanceForm: FormGroup;

  groups = [
    {"id": "categories", "name": "Categories"}
  ];

  categoryForm: FormGroup;
  searchTextCategory = "";
  searchedCategories = []
  selectedCategoryCount = 0;

  sizeForm: FormGroup;
  selectedSizeCount = 0;

  strategyFocusForm: FormGroup;
  selectedStrategyFocusCount = 0;

  statusForm: FormGroup;
  selectedStatusCount = 0;

  factsheetsStatusForm: FormGroup;
  selectedFactsheetCount = 0;

  orderForm: FormGroup;
  selectedOrder;

  districtForm: FormGroup;
  selectedDistrict;
  selectedDistrictCount = 0;

  groupForm: FormGroup;
  selectedGroup = "";

  is_brands_ready: boolean = false;
  is_category_ui: boolean = false;
  is_distance_disable: boolean = false;

  brands_obj = {};
  paginator_count_list = [];
  paginator_count = [];
  is_initial_page: boolean = false;
  selected_page = 1;
  brands = [];

  filterPostData = {};

  category_trendiness = [];
  gap_and_opportunity = {};
  is_gap_and_opportunity_ready: boolean = false;


  lineChartLabels = [];
  lineChartData = [];
  lineChartColors = [];
  lineChartOptions = {};
  lineChartLegend: boolean = true;
  lineChartType = 'line';
  lineChartPlugins = [];

  radarChartLabels = [];
  radarChartData = [];
  radarChartColors = [];
  radarChartOptions = {};
  radarChartLegend: boolean = true;
  radarChartType = 'bar';
  radarChartPlugins = [];

  fact_sheet_brand = {};
  fact_sheet_brand_list = [];
  fact_sheet_insights = {};
  fact_sheet_insights_all = {};

  ageDataLabels = [];
  ageData = [];
  ageDataColors = [];
  ageOptions = {};

  incomeDataLabels = [];
  incomeData = [];
  incomeDataColors = [];
  incomeOptions = {};

  brandDataLabels = [];
  brandData = [];
  brandDataColors = [];
  brandOptions = {};

  constructor (
      public fb: FormBuilder,
      private dataservice: DataService,
      private router: Router
  ) { }

  ngOnInit(): void {
    let current_route = this.router.url
    let name = current_route.split("/")[1]
    this.dataservice.getProject(name).then((project: any)  => {
      this.selectedProject = project
      this.selectedProjectId = project["id"]
      if (this.selectedProject["gap_and_opportunity"]) {
        this.gap_and_opportunity = this.selectedProject["gap_and_opportunity"];
        this.is_gap_and_opportunity_ready = true;
      } else {
        this.gap_and_opportunity = {};
        this.is_gap_and_opportunity_ready = false;
      }

      this.getDistricts(this.selectedProjectId)
      this.getBrands(this.selectedProjectId, false)
      this.getCategoryTrendiness()
    });
    this.filterPostData["order_by"] = ["rank"];
    this.getCategories()
    this.getSizes()
    this.getStrategyFocuses()
  }

  getCategoryTrendiness() {
    this.category_trendiness = [];
    this.dataservice.getCategoryTrendiness(this.selectedProjectId, this.filterPostData).then((value: any)  => {
      this.category_trendiness = value;
      this.getLineChartData()
      this.getRadarChartData()
    });
  };

  getLineChartData() {
    this.lineChartColors = [{ backgroundColor: []}]
    let colors_codes = {
      "Clothing & Footwear": "#584d8d",
      "Convenience": "#a23b72",
      "Discount": "#3b1f2b",
      "Durable Goods": "#5e2728",
      "Electronics": "#f18f01",
      "Food & Beverage": "#dc670f",
      "House & Home": "#ca653a",
      "Leisure": "#c73e1d",
      "Luxury": "#8981b9",
      "Other": "#2e86ab",
      "Personal Goods": "#812f24"
    }

    this.lineChartLabels = [];
    let real_labels = [];
    let prediction_labels = [];
    for (let category of this.category_trendiness) {
      real_labels = Object.keys(category["trendiness_score"]["real"])
      prediction_labels = Object.keys(category["trendiness_score"]["prediction"])
    }
    this.lineChartLabels = this.lineChartLabels.concat(real_labels);
    this.lineChartLabels = this.lineChartLabels.concat(prediction_labels);

    this.lineChartData = [];
    let name = "";
    let real_values = [];
    let prediction_values = [];
    let data = [];
    for (let category of this.category_trendiness) {
      name = category["category"]["name"]
      real_values = Object.values(category["trendiness_score"]["real"])
      prediction_values = Object.values(category["trendiness_score"]["prediction"])
      data = [];
      data = data.concat(real_values);
      data = data.concat(prediction_values);
      let obj = {
        data: data,
        label: name,
        backgroundColor: 'rgba(148,159,177,0.0)',
        borderColor: colors_codes[name],
        pointBackgroundColor: colors_codes[name],
        pointBorderColor: colors_codes[name],
        pointHoverBackgroundColor: 'transparent',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
        // borderDash: [10, 10],
        borderWidth: 1.2
      }
      this.lineChartData.push(obj)
    }

    this.lineChartOptions = {
      responsive: true,
      elements: {
        line: {
          tension: 0
        }
      },
      legend: {
        position: 'top',
        yPadding: 5,
        labels: {
          boxWidth: 15,
          boxHeight: 20,
          padding: 10
        }}
    };
    this.lineChartLegend = true;
    this.lineChartType = 'line';
    this.lineChartPlugins = [];
  }

  getRadarChartData() {
    let colors_codes = {
      "Clothing & Footwear": "#584d8d",
      "Convenience": "#a23b72",
      "Discount": "#3b1f2b",
      "Durable Goods": "#5e2728",
      "Electronics": "#f18f01",
      "Food & Beverage": "#dc670f",
      "House & Home": "#ca653a",
      "Leisure": "#c73e1d",
      "Luxury": "#8981b9",
      "Other": "#2e86ab",
      "Personal Goods": "#812f24"
    }

    this.radarChartLabels = [];
    this.radarChartData = [];
    this.radarChartColors = [
      { backgroundColor: [],
        hoverBackgroundColor: [] }
    ]

    for (let category of this.category_trendiness) {
      let name = category["category"]["name"]
      let cost_efficiency = category["cost_efficiency"]
      this.radarChartLabels.push(name)
      this.radarChartData.push(cost_efficiency)
      this.radarChartColors[0]["backgroundColor"].push(colors_codes[name])
      this.radarChartColors[0]["hoverBackgroundColor"].push(colors_codes[name])
    }

    this.radarChartOptions = {
      responsive: true,
      scales: {
        xAxes: [{
          stacked: true,
          barPercentage: 0.5,
          gridLines: { display: false, drawBorder: false }
      }],
        yAxes: [{
          stacked: true,
          // ticks: { display: false },
          // gridLines: { display: false, drawBorder: false },
        }]
      },
      legend: { display: false}
    };

    this.radarChartType = 'bar';
  }

  getCategories() {
    this.dataservice.getCategories().then((value: any[]) => {
      this.categories = value;
      this.categoryForm = this.fb.group({});
      let categoryGroup = {};
      this.categories.forEach(x=>{
        categoryGroup[x["id"]]=new FormControl('');
      })
      this.categoryForm = this.fb.group(categoryGroup);
    })

    // STATUS
    this.statusForm = this.fb.group({});
    let statusGroup = {};
    this.statuses.forEach(x=>{
      statusGroup[x["id"]]=new FormControl('');
    })
    this.statusForm = this.fb.group(statusGroup);

    // FACTSHEET STATUS
    this.factsheetsStatusForm = this.fb.group({});
    let factsheetsStatusGroup = {};
    this.fact_sheet_statuses.forEach(x=>{
      factsheetsStatusGroup[x["id"]]=new FormControl('');
    })
    this.factsheetsStatusForm = this.fb.group(factsheetsStatusGroup);

    // DISTANCE
    this.distanceForm = this.fb.group({});
    let distanceGroup = {};
    this.distances.forEach(x=>{
      distanceGroup[x["id"]]=new FormControl('');
    })
    this.distanceForm = this.fb.group(distanceGroup);

    // ORDER
    this.orderForm = this.fb.group({});
    let orderGroup = {};
    this.orders.forEach(x=>{
      orderGroup[x["id"]]=new FormControl('');
    })
    this.orderForm = this.fb.group(orderGroup);

    // GROUP
    this.groupForm = this.fb.group({});
    let groupGroup = {};
    this.groups.forEach(x=>{
      groupGroup[x["id"]]=new FormControl('');
    })
    this.groupForm = this.fb.group(groupGroup);
  }

  getDistricts(project_id) {
    this.dataservice.getChilds(project_id).then((value: any[]) => {
      let districts_values = value;
      for (let item of districts_values) {
        item["isSelected"] = false;
        this.districts.push(item)
      }
      this.districtForm = this.fb.group({});
      let districtGroup = {};
      this.districts.forEach(x=>{
        districtGroup[x["id"]]=new FormControl('');
      })
      this.districtForm = this.fb.group(districtGroup);
    })
  }

  getSizes() {
    this.dataservice.getSizes().then((value: any[]) => {
      this.sizes = value;
      this.sizeForm = this.fb.group({});
      let sizeGroup = {};
      this.sizes.forEach(x=>{
        sizeGroup[x["id"]]=new FormControl('');
      })
      this.sizeForm = this.fb.group(sizeGroup);
    })
  }

  getStrategyFocuses() {
    this.dataservice.getStrategyFocuses().then((value: any[]) => {
      this.strategy_focuses = value;
      this.strategyFocusForm = this.fb.group({});
      let strategyFocusGroup = {};
      this.strategy_focuses.forEach(x=>{
        strategyFocusGroup[x["id"]]=new FormControl('');
      })
      this.strategyFocusForm = this.fb.group(strategyFocusGroup);
    })
  }

  searchCategory() {
    this.searchedCategories = this.categories
  }

  categorySearchFilter() {
    this.selectedCategoryCount = 0;
    let form_values = this.categoryForm.value;
    for (let item of this.categories) {
      if (form_values[item["id"]] == true) {
        this.selectedCategoryCount = this.selectedCategoryCount + 1
      }
    }
    this.applyBrandFilters("category")
  }

  statusSearchFilter() {
    this.selectedStatusCount = 0;
    let form_values = this.statusForm.value;
    for (let item of this.statuses) {
      if (form_values[item["id"]] == true) {
        this.selectedStatusCount = this.selectedStatusCount + 1
      }
    }
    this.applyBrandFilters("status")
  }

  factsheetStatusSearchFilter() {
    this.selectedFactsheetCount = 0;
    let form_values = this.factsheetsStatusForm.value;
    for (let item of this.fact_sheet_statuses) {
      if (form_values[item["id"]] == true) {
        this.selectedFactsheetCount = this.selectedFactsheetCount + 1
      }
    }
    this.applyBrandFilters("fact_sheet")
  }

  distanceSearchFilter() {
    this.filterPostData["distance"] = [];
    this.is_distance_disable = false;
    this.distanceOptions = Object.assign({}, this.distanceOptions, {disabled: this.is_distance_disable});
    let form_values = this.distanceForm.value;
    for (let item of this.distances) {
      if (form_values[item["id"]] == true) {
        this.is_distance_disable = true;
        this.distanceOptions = Object.assign({}, this.distanceOptions, {disabled: this.is_distance_disable});
      }
    }
  }

  sizeSearchFilter() {
    this.selectedSizeCount = 0;
    let form_values = this.sizeForm.value;
    for (let item of this.sizes) {
      if (form_values[item["id"]] == true) {
        this.selectedSizeCount = this.selectedSizeCount + 1
      }
    }
    this.applyBrandFilters("size")
  }

  strategyFocusSearchFilter() {
    this.selectedStrategyFocusCount = 0;
    let form_values = this.strategyFocusForm.value;
    for (let item of this.strategy_focuses) {
      if (form_values[item["id"]] == true) {
        this.selectedStrategyFocusCount = this.selectedStrategyFocusCount + 1
      }
    }
    this.applyBrandFilters("strategy_focus")
  }

  selectOrder(order) {
    if (this.selectedOrder == order) {
      for (let i in this.orders) {
        this.orders[i]["isSelected"] = false;
      }
    } else {
      for (let i in this.orders) {
        if (this.orders[i]["id"] == order) {
          this.orders[i]["isSelected"] = true;
          this.selectedOrder = order;
        } else {
          this.orders[i]["isSelected"] = false;
        }
      }
    }
    this.applyBrandFilters('order_by');
    setTimeout(() => {
      let orderDropdown: HTMLElement = document.getElementsByClassName("order-dropdown")[0] as HTMLElement
      orderDropdown.className = "dropdown-menu order-dropdown px-3 py-4 my-2";
      let orderDropdownLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[0] as HTMLElement
      orderDropdownLink.setAttribute('aria-expanded', 'false');
    }, 20);
  }

  returnOrderText(order) {
    for (let item of this.orders) {
      if (item["id"] == order) {
        return item["name"]
      }
    }
  }

  orderSearchFilter() {
    this.selectedOrder = "";
    let form_values = this.orderForm.value;
    for (let item of this.orders) {
      if (form_values[item["id"]] == true) {
        if (this.selectedOrder == "") {
          this.selectedOrder = this.selectedOrder + item["name"]
        } else {
          this.selectedOrder = this.selectedOrder + "," + item["name"]
        }
      }
    }
    this.applyBrandFilters("order_by")
  }

  selectDistrict(district) {
    let child_project = null;
    this.selectedDistrictCount = 0;
    if (this.selectedDistrict == district) {
      for (let i in this.districts) {
        this.districts[i]["isSelected"] = false;
      }
    } else {
      for (let i in this.districts) {
        if (this.districts[i]["id"] == district) {
          this.districts[i]["isSelected"] = true;
          this.selectedDistrict = district;
          child_project = this.districts[i]
          this.selectedDistrictCount = this.selectedDistrictCount + 1
        } else {
          this.districts[i]["isSelected"] = false;
        }
      }
    }
    this.applyBrandFilters('district');
    this.updateGapAndOpportunity(child_project);
  }

  updateGapAndOpportunity(child_project) {
    let slug;
    if (child_project) {
      slug = child_project["slug"];
    } else {
      slug = this.selectedProject["slug"]
    }
    this.dataservice.getProject(slug).then((project: any)  => {
      this.gap_and_opportunity = [];
      this.is_gap_and_opportunity_ready = false;
      let child_project = project
      if (child_project["gap_and_opportunity"]) {
        this.gap_and_opportunity = child_project["gap_and_opportunity"];
        this.is_gap_and_opportunity_ready = true;
      } else {
        this.gap_and_opportunity = {};
        this.is_gap_and_opportunity_ready = false;
      }
    });
  }

  returnDistrictText(district) {
    for (let item of this.districts) {
      if (item["id"] == district) {
        return item["name"]
      }
    }
  }

  districtSearchFilter() {
    this.selectedDistrict = "";
    let form_values = this.districtForm.value;
    for (let item of this.districts) {
      if (form_values[item["id"]] == true) {
        if (this.selectedDistrict == "") {
          this.selectedDistrict = this.selectedDistrict + item["name"]
        } else {
          this.selectedDistrict = this.selectedDistrict + "," + item["name"]
        }
      }
    }
    this.applyBrandFilters("district")
  }

  groupSearchFilter() {
    this.selectedGroup = "";
    this.is_category_ui = false;
    let form_values = this.groupForm.value;
    for (let item of this.groups) {
      if (form_values[item["id"]] == true) {
        if (this.selectedGroup == "") {
          this.selectedGroup = this.selectedGroup + item["name"]
        } else {
          this.selectedGroup = this.selectedGroup + "," + item["name"]
        }
        if (item["id"] == "categories") {
          this.is_category_ui = true;
        }
      }
    }
    this.applyBrandFilters("group_by")
  }

  getBrands(project_id, is_filter) {
    this.dataservice.getBrands(project_id, this.filterPostData).then((value: any[]) => {
      this.brands_obj = value;
      this.paginator_count_list = [];
      let page_count = Math.ceil(this.brands_obj["count"] / 20)
      for (let i = 1; i <= page_count; i++) {
        this.paginator_count_list.push(i)
      }
      if (!this.is_initial_page) {
        this.paginator_count = this.paginator_count_list.slice(0,5);
        this.is_initial_page = true;
      }
      if (is_filter) {
        this.paginator_count = this.paginator_count_list.slice(0,5);
      }
      this.brands = value["results"];
      if (this.selectedBrand.hasOwnProperty("id")) {
        for (let brand of this.brands) {
          if (brand["id"] == this.selectedBrand["id"]) {
            this.selectedBrand = brand;
          }
        }
      } else {
        this.selectedBrand = this.brands[0];
      }
      this.is_selected_brand_ready = true;
      this.is_brands_ready = true;
      this.is_sended_request = false;
    })
  }

  getBrand(brand) {
    this.is_selected_brand_ready = false;
    this.selectedBrand = {};
    this.selectedBrand = brand;
    this.is_selected_brand_ready = true;

    if (this.selectedBrand["project_dependant_value"]["fact_sheet_status"] == "available") {
      this.getFactsheet(this.selectedBrand)
    }
  }

  getFactsheet(brand) {
    this.dataservice.getFactsheet(this.selectedProjectId, brand["id"]).then((value: any[]) => {
      this.fact_sheet_brand = {};
      this.fact_sheet_brand_list = [];
      this.fact_sheet_insights = {};
      this.fact_sheet_insights_all = {};
      for (let item of value) {
        if (item["full_name"]) {
          this.fact_sheet_brand = item;
        }
        else if (item["insight_order"]) {
          this.fact_sheet_insights_all = item;
          this.fact_sheet_insights = item["insight_order"];
        }
        else {
          this.fact_sheet_brand_list.push(item)
        }
      }
      this.getAgeData();
      this.getIncomeData();
      this.getBrandData();
    });
  }

  getAgeData() {
    this.ageDataLabels = [];
    this.ageData = [];

    this.ageData = [
      {
        data: [],
        label: "Wider Catchment Area"
      },
      {
        data: [],
        label: "Area"
      },
      {
        data: [],
        label: this.fact_sheet_brand["full_name"]
      },
    ];

    for (let age of Object.keys(this.fact_sheet_insights_all["age_comparison"]["wider_area"])) {
      this.ageDataLabels.push(age)
      this.ageData[0]["data"].push(this.fact_sheet_insights_all["age_comparison"]["wider_area"][age])
    }

    for (let age of Object.keys(this.fact_sheet_insights_all["age_comparison"]["area"])) {
      this.ageData[1]["data"].push(this.fact_sheet_insights_all["age_comparison"]["area"][age])
    }

    for (let age of Object.keys(this.fact_sheet_insights_all["age_comparison"]["fact_sheet_brand"])) {
      this.ageData[2]["data"].push(this.fact_sheet_insights_all["age_comparison"]["fact_sheet_brand"][age])
    }

    this.ageDataColors = [
      { backgroundColor: '#ECB4AE' },
      { backgroundColor: '#E05B4C' },
      { backgroundColor: '#8f261a' },
    ]

    this.ageOptions = {
      responsive: true,
      borderRadius: 20,
      scales: {
        xAxes: [{
          // stacked: true,
      }],
        yAxes: [{
          // stacked: true,
          barPercentage: 0.6,
          gridLines: { display: false, drawBorder: false }
        }]
      },
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
          boxWidth: 10
        }
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
      }
    };
  }

  getIncomeData() {
    this.incomeDataLabels = [];
    this.incomeData = [];

    this.incomeData = [
      {
        data: [],
        label: "Wider Catchment Area"
      },
      {
        data: [],
        label: "Area"
      },
      {
        data: [],
        label: this.fact_sheet_brand["full_name"]
      },
    ];

    for (let income of Object.keys(this.fact_sheet_insights_all["income_comparison"]["wider_area"])) {
      this.incomeDataLabels.push(income)
      this.incomeData[0]["data"].push(this.fact_sheet_insights_all["income_comparison"]["wider_area"][income])
    }

    for (let income of Object.keys(this.fact_sheet_insights_all["income_comparison"]["area"])) {
      this.incomeData[1]["data"].push(this.fact_sheet_insights_all["income_comparison"]["area"][income])
    }

    for (let income of Object.keys(this.fact_sheet_insights_all["income_comparison"]["fact_sheet_brand"])) {
      this.incomeData[2]["data"].push(this.fact_sheet_insights_all["income_comparison"]["fact_sheet_brand"][income])
    }

    this.incomeDataColors = [
      { backgroundColor: '#938E9E' },
      { backgroundColor: '#42404a' },
      { backgroundColor: '#1a191c' },
    ]

    this.incomeOptions = {
      responsive: true,
      borderRadius: 20,
      scales: {
        xAxes: [{
          // stacked: true,
      }],
        yAxes: [{
          // stacked: true,
          barPercentage: 0.6,
          gridLines: { display: false, drawBorder: false }
        }]
      },
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
          boxWidth: 10
        }
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
      }
    };
  }

  getBrandData() {
    this.brandData = [];
    this.brandData = [
      {
        backgroundColor: "#ECB4AE",
        borderColor: "#E05B4C",
        label: this.fact_sheet_brand["full_name"],
        data: [
          {
            "x": this.fact_sheet_brand["competitive_advantage"],
            "y": this.fact_sheet_brand["consumer_relevance"],
            "r": 30
          }
        ]
      }
    ];


    for (let brand of this.fact_sheet_brand_list) {
      this.brandData.push({
        backgroundColor: "#c5c2cc",
        borderColor: "#938E9E",
        label: brand["username"],
        data: [
          {
            "x": brand["competitive_advantage"],
            "y": brand["consumer_relevance"],
            "r": 30
          }
        ]
      })
    }

    this.brandOptions = {
      responsive: true,
      borderRadius: 20,
      scales: {
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: "Competitive Advantage"
          },
          ticks: {
            display: false,
            beginAtZero: true,
            max: 20
          }
        }],
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: "Consumer Relevance"
          },
          ticks: {
            display: false,
            beginAtZero: true,
            max: 20
          }
        }]
      },
      legend: {
        display: true,
        position: "right",
        labels: {
          usePointStyle: true,
          boxWidth: 10
        }
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
        callbacks: {
           label: function(t, d) {
              return d.datasets[t.datasetIndex].label +
                ': (Competitive Advantage:' + t.xLabel + ', Consumer Relevance:' + t.yLabel + ')';
           }
        }
      }
    };
  }

  sendFactsheetRequest(brand) {
    let data = {"fact_sheet_status": "requested"}
    this.dataservice.sendFactsheetRequest(this.selectedProjectId, brand["id"], data).then((value)  => {
      this.getBrands(this.selectedProjectId, false);
      this.is_sended_request = true;
    });
  }

  getBrandColor(status) {
    let color_mapping = {
      "slowing-down": ["gray-border", "gray-background"],
      "trending": ["red-border", "red-background"],
      "declining": ["black-border", "black-background"],
      "stable": ["light-red-border", "light-red-background"]
    }
    return color_mapping[status]
  }

  applyBrandFilters(type) {
    this.is_brands_ready = false;
    if (type == "category") {
      let categoryIds = []
      for (let i in this.categoryForm.value) {
        if (this.categoryForm.value[i] == true) {
          categoryIds.push(Number(i))
        }
      }
      this.filterPostData["categories"] = categoryIds
      this.getCategoryTrendiness()
    }
    else if (type == "status") {
      let statusIds = []
      for (let i in this.statusForm.value) {
        if (this.statusForm.value[i] == true) {
          statusIds.push(i)
        }
      }
      this.filterPostData["statuses"] = statusIds
    }
    else if (type == "fact_sheet") {
      let factsheetStatusIds = []
      for (let i in this.factsheetsStatusForm.value) {
        if (this.factsheetsStatusForm.value[i] == true) {
          factsheetStatusIds.push(i)
        }
      }
      this.filterPostData["fact_sheet"] = factsheetStatusIds
    }
    else if (type == "size") {
      let sizeIds = []
      for (let i in this.sizeForm.value) {
        if (this.sizeForm.value[i] == true) {
          sizeIds.push(i)
        }
      }
      this.filterPostData["sizes"] = sizeIds
    }
    else if (type == "distance") {
      let distanceIds = []
      for (let i in this.distanceForm.value) {
        if (this.distanceForm.value[i] == true) {
          distanceIds.push(i)
        }
      }
      this.filterPostData["distance_locations"] = distanceIds

      if (distanceIds.length == 0) {
        let postData = [this.minDistanceValue, this.maxDistanceValue]
        this.filterPostData["distance"] = postData
      }
    }
    else if (type == "strategy_focus") {
      let strategyFocusIds = []
      for (let i in this.strategyFocusForm.value) {
        if (this.strategyFocusForm.value[i] == true) {
          strategyFocusIds.push(i)
        }
      }
      this.filterPostData["strategy_focuses"] = strategyFocusIds
    }
    else if (type == "order_by") {
      let orderIds = []
      for (let order of this.orders) {
        if (order["isSelected"] == true) {
          orderIds.push(order["id"])
        }
      }
      this.filterPostData["order_by"] = orderIds
      if (this.filterPostData["order_by"] == 0) {
        this.selectedOrder = "";
        this.filterPostData["order_by"] = ["rank"];
      }
    }
    else if (type == "district") {
      this.filterPostData["limit"] = [20];
      this.filterPostData["offset"] = [0];
      this.selected_page = 1;
      let districtIds = []
      for (let district of this.districts) {
        if (district["isSelected"] == true) {
          districtIds.push(district["id"])
        }
      }
      this.filterPostData["district"] = districtIds
      if (this.filterPostData["district"] == 0) {
        this.selectedDistrict = "";
        this.filterPostData["district"] = [];
      }
      this.getCategoryTrendiness()
    }
    else if (type == "group_by") {
      let groupIds = []
      for (let i in this.groupForm.value) {
        if (this.groupForm.value[i] == true) {
          groupIds.push(i)
        }
      }
      this.filterPostData["group_by"] = groupIds
    }
    this.getBrands(this.selectedProjectId, true)
  }

  resetFilters() {
    this.is_brands_ready = false;
    this.categoryForm.reset();
    this.statusForm.reset();
    this.factsheetsStatusForm.reset();
    this.sizeForm.reset();
    this.strategyFocusForm.reset();
    this.orderForm.reset();
    this.groupForm.reset();
    this.distanceForm.reset();
    this.districtForm.reset();
    this.selectedCategoryCount = 0;
    this.selectedStatusCount = 0;
    this.selectedFactsheetCount = 0;
    this.selectedSizeCount = 0;
    this.selectedStrategyFocusCount = 0;
    this.selectedDistrictCount = 0;
    this.selectedOrder = "";
    this.selectedGroup = "";
    this.selectedDistrict = "";
    this.filterPostData = {};
    this.filterPostData["order_by"] = ["rank"];
    this.filterPostData["limit"] = [20];
    this.filterPostData["offset"] = [0];
    this.is_category_ui = false;
    this.selected_page = 1;
    this.clearDistanceFilters();
    this.getBrands(this.selectedProjectId, false);
    this.getCategoryTrendiness();
    this.updateGapAndOpportunity(null);
  }

  toTitleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
  }

  milesSeperator(value) {
    if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  paginator(type, page) {
    if (type == "previous") {
      if (this.paginator_count.indexOf(1) < 0  ) {
        this.paginator_count = this.paginator_count_list.slice(this.paginator_count[0]-6, this.paginator_count[0]-1)
      } else {
        this.paginator_count = this.paginator_count_list.slice(0, 5)
      }
    }
    else if (type == "next") {
      if (this.paginator_count.indexOf(Math.ceil(this.brands_obj["count"] / 20)) < 0  ) {
        this.paginator_count = this.paginator_count_list.slice(this.paginator_count[4], this.paginator_count[4]+5)
      } else {
        this.paginator_count = this.paginator_count
      }
    }
    else if (type == "page") {
      this.selected_page = Number(page);
      this.filterPostData["limit"] = [20];
      this.filterPostData["offset"] = [(page-1)*20]
      this.getBrands(this.selectedProjectId, false)
    }
  }

  onMinDistanceValueChange(value) {
    this.minDistanceValue = value;
  }

  onMaxDistanceValueChange(value) {
    this.maxDistanceValue = value;
  }

  onDistanceNumberValueChange(changeContext: ChangeContext) {
    this.minDistanceValue = changeContext.value;
    this.maxDistanceValue = changeContext.highValue;
    this.selectedDistanceNumber = this.intToString(this.minDistanceValue) + " - " + this.intToString(this.maxDistanceValue)

    let distanceDropdownMenu: HTMLElement = document.getElementsByClassName("distance-dropdown-menu")[0] as HTMLElement
    distanceDropdownMenu.className = "dropdown-menu distance-dropdown-menu px-3 py-4 my-2 tree-dropdown";

    let dropdownMenuLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[5] as HTMLElement
    dropdownMenuLink.setAttribute('aria-expanded', 'true')
    setTimeout(() => {
      dropdownMenuLink.setAttribute('aria-expanded', 'true')
    }, 10);
  }

  intToString(num) {
    if (num < 1000) {
      return num + 'meters'; // convert to K for number from > 1000 < 1 million
    } else if (num >= 1000){
      return num + 'KM'; // convert to M for number from > 1 million
    }
  }

  clearDistanceFilters() {
    this.minDistanceValue = 0;
    this.maxDistanceValue = 30000;
    this.selectedDistanceNumber = "";
    this.filterPostData["distance"] = 0;
    this.distanceForm.reset();
    this.getBrands(this.selectedProjectId, true);
  }

  clickOutsideDistance(event) {
    let treeDropdownMenu: HTMLElement = document.getElementsByClassName("distance-dropdown-menu")[0] as HTMLElement
    treeDropdownMenu.className = "dropdown-menu distance-dropdown-menu px-3 py-4 my-2";
    let dropdownMenuLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[5] as HTMLElement
    dropdownMenuLink.setAttribute('aria-expanded', 'false')
  }

}
