import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';

import { DataService } from 'src/app/services/data.service';

import { FilterPipe } from "../../../pipes/filter.pipe";

import { Color } from 'ng2-charts';

import { ITreeOptions } from '@circlon/angular-tree-component';

// import { icon, latLng, marker, polyline, tileLayer } from 'leaflet';
import * as L from 'leaflet';
import * as $ from 'jquery'

@Component({
  selector: 'app-catchment',
  templateUrl: './catchment.component.html',
  styleUrls: ['./catchment.component.css']
})
export class CatchmentComponent implements OnInit {
  private map;
  private markerGroup;
  private iconMapping = {
    "extra": "extra.png",
    "related": "related.png",
    "without": "without.png"
  };

  private access_token = 'TAYcilZ01ddabfoaDiEzpgTBCqZ6EI7ZgGko8Qq0kICgDuoGx5jQfn8zmVEMBzfB'

  private initMap(): void {
    if (this.selectedProject["type"] == "area_based") {
      this.map = L.map('map', {
        zoom: 14,
        maxZoom: 18,
        center: L.latLng([ this.selectedProject["areas"][0]["town"]["lat"],  this.selectedProject["areas"][0]["town"]["lng"] ]),
      });
    }
    else if (this.selectedProject["type"] == "town_based") {
      this.map = L.map('map', {
        zoom: 14,
        maxZoom: 18,
        center: L.latLng([ this.selectedProject["town"]["lat"],  this.selectedProject["town"]["lng"] ]),
      });
    }

    const tiles = L.tileLayer('https://{s}.tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token=' + this.access_token, {
      detectRetina: true,
      attribution: '<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });

    tiles.addTo(this.map);

    this.markerGroup = L.layerGroup();
    this.map.addLayer(this.markerGroup);
  }

  isWidget: boolean = false;
  isShadow = "shadow";

  selectedProject: any;
  selectedProjectId: Number;
  selectedProjectSections = [];
  selectedProjectSectionList = [];
  isMapContainer: boolean = false;

  selectedFilter: any;
  by_category = "btn-light light-btn"
  by_period = "btn-light light-btn"
  by_catchment_area = "btn-light light-btn"
  by_street = "btn-light light-btn"

  isGrayMarker: boolean = false;

  isCategoryDataReady: boolean = false;
  isPeriodDataReady: boolean = false;
  isAreaDataReady: boolean = false;
  isStreetDataReady: boolean = false;
  isFilterDataReady: boolean = false;

  // categoryForm: FormGroup;
  periodForm: FormGroup;
  catchmentAreaForm: FormGroup;
  factsheetForm: FormGroup;
  brandCategoryForm: FormGroup;

  isFactsheetForm: boolean = true;

  searchTextCategory = "";
  searchedCategories = [];

  searchTextCatchmentArea = "";
  searchedCatchmentAreas = [];

  searchTextStreetName = "";
  searchedStreetName = [];

  selectedSubCategories = [];
  selectedStreets = [];

  locations = [];
  categories = [];
  subCategories = [];
  subCategoriesTreeData = [];
  subCategoriesTreeDataMapping = {};
  periods = [];
  catchmentAreas = [];
  streets = [];
  streetsTreeData = [];
  streetsTreeDataMapping = {};
  brandCategories = [];

  popularities = [];
  digital_footfall = [];
  topTenBusinesses = [];
  topTenBusinessesSlider = [];
  personas = [];
  audience = [];

  selectedCategoryCount = 0;
  selectedPeriodCount = 0;
  selectedCatchmentAreasCount = 0;
  selectedStreetCount = 0;
  selectedBrandCategoryCount = 0;

  occupations = [];
  all_occupations = [];
  isMoreOccupation: boolean = true;
  isScrollDivOccupation: boolean = false;

  like_and_interesets = [];
  all_like_and_interesets = [];
  isMoreLike: boolean = true;
  isScrollDivLike: boolean = false;

  top_brands = [];
  all_top_brands = [];
  isMoreTopBrands: boolean = true;
  isScrollDivTopBrands: boolean = false;

  mostly_liked_celebrities = [];
  all_mostly_liked_celebrities = [];
  isMoreCelebrity: boolean = true;
  isScrollDivCelebrity: boolean = false;

  priceBreakdownDataLabels = [];
  priceBreakdownData = [];
  priceBreakdownDataColors = [];
  priceBreakdownOptions = {};

  tenantBreakdownDataLabels = [];
  tenantBreakdownData = [];
  tenantBreakdownDataColors = [];
  tenantBreakdownOptions = {};

  ageDataLabels = [];
  ageData = [];
  ageDataColors = [];
  ageOptions = {};

  personalIncomeDataLabels = [];
  personalIncomeData = [];
  personalIncomeDataColors = [];
  personalIncomeOptions = {};

  genderDataLabels = [];
  genderData = [];
  genderDataColors = [];
  genderOptions = {};

  popularTimesHourDataLabels = [];
  popularTimesHourData = [];
  popularTimesHourDataColors = [];
  popularTimesHourOptions = {};

  popularTimesDayDataLabels = [];
  popularTimesDayData = [];
  popularTimesDayDataColors = [];
  popularTimesDayOptions = {};

  popularTimesMonthDataLabels = [];
  popularTimesMonthData = [];
  popularTimesMonthDataColors = [];
  popularTimesMonthOptions = {};

  options = {};

  filterPostData = {};

  treeOptions: ITreeOptions = {
    useCheckbox: true
  };

  constructor(
    public fb: FormBuilder,
    private dataservice: DataService,
    private router: Router,
  ) { }

  @ViewChild('tree') tree;

  ngAfterViewInit(): void {
    this.getSelectedProject();
    let current_route = this.router.url
    let name = current_route.split("/")[1]
    if (current_route.split("/").length > 2) {
      this.isWidget = true;
      this.isShadow = "shadow";
    }
    this.dataservice.getProject(name).then((project: any)  => {
      this.selectedProject = project;
      this.selectedProjectId = this.selectedProject["id"]
      this.getProjectFeatureSections()
      this.getSubCategories()
    })
  }

  ngOnInit(): void {
    let current_route = this.router.url
    let name = current_route.split("/")[1]
    if (current_route.split("/").length > 2) {
      this.isWidget = true;
      this.isShadow = "shadow";
    }
    this.dataservice.getProject(name).then((project: any)  => {
      this.selectedProjectId = project["id"]
      this.getCategories()

      this.dataservice.getPopularityData(this.selectedProjectId, this.filterPostData).then((value: any[]) => {
        this.popularities = value;
      })

      this.dataservice.getDigitalFootfall(this.selectedProjectId, this.filterPostData).then((value: any[]) => {
        this.digital_footfall = value;
      })

      this.dataservice.getTopTenBusinessData(this.selectedProjectId, this.filterPostData).then((value: any[]) => {
        this.topTenBusinesses = value;
        if (this.topTenBusinesses.length <= 3) {
          this.topTenBusinessesSlider = [];
        }
        else if ((3 < this.topTenBusinesses.length) && (this.topTenBusinesses.length < 7)) {
          this.topTenBusinessesSlider = [3];
        }
        else if ((6 < this.topTenBusinesses.length) && (this.topTenBusinesses.length < 10)) {
          this.topTenBusinessesSlider = [3, 6];
        }
        else if ( 9 < this.topTenBusinesses.length) {
          this.topTenBusinessesSlider = [3, 6, 9];
        }
      })

      this.dataservice.getPersonasData(this.selectedProjectId, this.filterPostData).then((value: any[]) => {
        this.personas = value;
      })

      this.dataservice.getAudienceData(this.selectedProjectId, this.filterPostData).then((value: any[]) => {
        this.audience = value;
        this.updateAudienceData()
        this.getAgeData()
        this.getPersonalIncomeData()
        this.getGenderData()
      })
    });

    // generate form group data
    // this.categoryForm = this.fb.group({});
    this.periodForm = this.fb.group({});
    this.catchmentAreaForm = this.fb.group({});
    this.brandCategoryForm = this.fb.group({});
    this.factsheetForm = this.fb.group({
      catchment_area: new FormControl(''),
      street: new FormControl(''),
      category: new FormControl('')
    });
  }

  getSelectedProject() {
    let current_route = this.router.url
    let name = current_route.split("/")[1]
    this.dataservice.getProject(name).then((project: any)  => {
      this.selectedProject = project;
    })
  }

  getProjectFeatureSections() {
    this.dataservice.getProjectFeatureSections(this.selectedProject["id"], 1).then((value: any)  => {
      this.selectedProjectSections = value;
      for (let feature of this.selectedProjectSections) {
        this.selectedProjectSectionList.push(feature["section"]["name"])
      }
      if (this.selectedProjectSectionList.indexOf("Map") > -1) {
        this.isMapContainer = true;
        setTimeout(() => {
          this.initMap()
        }, 3);
      }
    })
  }

  returnSectionSituation(section) {
    if (this.selectedProjectSectionList.indexOf(section) > 0) {
      return true
    } else {
      return false
    }
  }

  getData() {
    this.dataservice.getPopularityData(this.selectedProjectId, this.filterPostData).then((value: any[]) => {
      this.popularities = value;
    })

    this.dataservice.getDigitalFootfall(this.selectedProjectId, this.filterPostData).then((value: any[]) => {
      this.digital_footfall = value;
    })

    this.dataservice.getTopTenBusinessData(this.selectedProjectId, this.filterPostData).then((value: any[]) => {
      this.topTenBusinesses = value;
      if (this.topTenBusinesses.length <= 3) {
        this.topTenBusinessesSlider = [];
      }
      else if ((3 < this.topTenBusinesses.length) && (this.topTenBusinesses.length < 7)) {
        this.topTenBusinessesSlider = [3];
      }
      else if ((6 < this.topTenBusinesses.length) && (this.topTenBusinesses.length < 10)) {
        this.topTenBusinessesSlider = [3, 6];
      }
      else if ( 9 < this.topTenBusinesses.length) {
        this.topTenBusinessesSlider = [3, 6, 9];
      }
    })

    this.dataservice.getPersonasData(this.selectedProjectId, this.filterPostData).then((value: any[]) => {
      this.personas = value;
    })

    this.dataservice.getAudienceData(this.selectedProjectId, this.filterPostData).then((value: any[]) => {
      this.audience = value;
      this.updateAudienceData()
      this.getAgeData()
      this.getPersonalIncomeData()
      this.getGenderData()
    })
  }

  getInıtialChartData() {
    this.getPriceBreakdownData()
    this.getTenantBreakdownData()
    this.getPopularTimesHour()
    this.getPopularTimesDay()
    this.getPopularTimesMonth()
  }

  updateCharts() {
    this.getPriceBreakdownData()
    this.getTenantBreakdownData()
    this.getPopularTimesHour()
    this.getPopularTimesDay()
    this.getPopularTimesMonth()
    this.getAgeData()
    this.getPersonalIncomeData()
    this.getGenderData()
    this.updateAudienceData()

    this.isMoreOccupation = true;
    this.isScrollDivOccupation = false;
    this.isMoreLike = true;
    this.isScrollDivLike = false;
    this.isMoreTopBrands = true;
    this.isScrollDivTopBrands = false;
    this.isMoreCelebrity = true;
    this.isScrollDivCelebrity = false;
  }


  updateAudienceData() {
    this.all_occupations = [];
    this.occupations = [];
    for (let occupation of Object.keys(this.audience["occupations"])) {
      this.all_occupations.push({"name":occupation , "value":this.audience["occupations"][occupation]})
      this.occupations = this.all_occupations.slice(0,8)
    }
    if (this.all_occupations.length > 8) {
      this.isMoreOccupation = true;
    } else  {
      this.isMoreOccupation = false;
    }

    this.all_like_and_interesets = [];
    this.like_and_interesets = [];
    for (let like of Object.keys(this.audience["likes_and_interests"])) {
      this.all_like_and_interesets.push({"name": like, "value":this.audience["likes_and_interests"][like]})
      this.like_and_interesets = this.all_like_and_interesets.slice(0,8)
    }
    if (this.all_like_and_interesets.length > 8) {
      this.isMoreLike = true;
    } else {
      this.isMoreLike = false;
    }

    this.all_top_brands = [];
    this.top_brands = [];
    for (let top_brand of Object.keys(this.audience["top_brands"])) {
      this.all_top_brands.push({"name": top_brand, "value":this.audience["top_brands"][top_brand]})
      this.top_brands = this.all_top_brands.slice(0,8)
    }
    if (this.all_top_brands.length > 8) {
      this.isMoreTopBrands = true;
    } else {
      this.isMoreTopBrands = false;
    }

    this.all_mostly_liked_celebrities = [];
    this.mostly_liked_celebrities = [];
    for (let mostly_liked_celebrity of Object.keys(this.audience["mostly_liked_celebrities"])) {
      this.all_mostly_liked_celebrities.push({"name": mostly_liked_celebrity, "value":this.audience["mostly_liked_celebrities"][mostly_liked_celebrity]})
      this.mostly_liked_celebrities = this.all_mostly_liked_celebrities.slice(0,8)
    }
    if (this.all_mostly_liked_celebrities.length > 8) {
      this.isMoreCelebrity = true;
    } else {
      this.isMoreCelebrity = false;
    }
  }

  // DATA PART
  getBrandCategories() {
    this.dataservice.getBrandCategories().then((value: any[]) => {
      this.brandCategories = value;

      // create dynamic brand category form
      let categoryGroup = {};
      this.brandCategories.forEach(x=>{
        categoryGroup[x["id"]]=new FormControl('');
      })
      this.brandCategoryForm = this.fb.group(categoryGroup);
    })
  }

  categoryDataMapper(sub_categories, category_list) {
    let newCategoryData = [];
    let categories = [];
    for (let sub of sub_categories) {
      if (category_list.length) {
        if ((categories.indexOf(sub["category"]["name"]) < 0) && (category_list.indexOf(sub["category"]["id"]) > -1))  {
          categories.push(sub["category"]["name"])
          newCategoryData.push({
            // id: sub["category"]["id"],
            name: sub["category"]["name"],
            children: []
          })
        }
      } else {
        if (categories.indexOf(sub["category"]["name"]) < 0) {
          categories.push(sub["category"]["name"])
          newCategoryData.push({
            // id: sub["category"]["id"],
            name: sub["category"]["name"],
            children: []
          })
        }
      }
    }
    for (let sub of sub_categories) {
      for (let root of newCategoryData) {
        if (sub["category"]["name"] == root["name"]) {
          root["children"].push({
            // id: sub["id"],
            name: sub["name"],
          })
        }
      }
    }
    return newCategoryData
  }

  treeAndSubCategoryMapper(treeData) {
    for (let category of treeData) {
      for (let sub of category["children"]) {
        for (let subcat of this.subCategories) {
          if (sub["name"] == subcat["name"]) {
            this.subCategoriesTreeDataMapping[sub["id"]] = subcat["id"]
          }
        }
      }
    }
  }

  getCategories() {
    this.dataservice.getCategories().then((value: any[]) => {
      this.categories = value;
    })
  }

  getSubCategories() {
    this.dataservice.getSubCategories().then((value: any[]) => {
      this.subCategories = value;
      this.getPeriods()
      this.subCategoriesTreeData = this.categoryDataMapper(this.subCategories, [])
      this.isCategoryDataReady = true;
      this.getInıtialChartData()
      for (let category of this.subCategories) {
        this.iconMapping[category["category"]["id"]] = category["category"]["name"].toLowerCase().replaceAll(" ", "") + ".png"
      }
      this.applyLocationFilters("grayMarker", true)
      // create dynamic category form
      // let categoryGroup = {};
      // this.categories.forEach(x=>{
      //   categoryGroup[x["id"]]=new FormControl('');
      // })
      // this.categoryForm = this.fb.group(categoryGroup);
    })
  }

  getSubCategoryId(name) {
    for (let sub of this.subCategories) {
      if (sub["name"] == name) {
        return Number(sub["id"])
      }
    }
  }

  convertCategoryToSubcategory() {
    if (this.filterPostData["categories"]) {
      for (let cat of this.filterPostData["categories"]) {
        for (let sub of this.subCategories) {
          if (cat == sub["category"]["id"]) {
            this.filterPostData["sub-categories"].push(sub["id"])
          }
        }
      }
    }
  }

  onStateChangeSubCategory(event) {
    let selectedNodesIds = Object.keys(event.selectedLeafNodeIds)
    let selectedIds = [];
    let selectedSubIds = [];
    for (let nodeId of selectedNodesIds) {
      if (event.selectedLeafNodeIds[nodeId]) {
        let subId = this.subCategoriesTreeDataMapping[nodeId]
        if (selectedIds.indexOf(subId) == -1) {
          selectedIds.push(Number(subId))
        }
      }
    }

    selectedIds = selectedIds.sort()

    this.convertCategoryToSubcategory()
    setTimeout(() => {
      this.filterPostData["categories"]= [];
      this.selectedSubCategories = selectedIds;
      if (this.filterPostData["sub-categories"]) {
        selectedSubIds = this.filterPostData["sub-categories"].sort()
      }

      const equals = (selectedIds, selectedSubIds) => JSON.stringify(selectedIds) === JSON.stringify(selectedSubIds);

      let is_same = equals(selectedIds, selectedSubIds)
      if (!is_same) {
        this.applyLocationFilters("category", false);
      }
    }, 3);

  }

  onSelectSubCategory(event) {
    this.treeAndSubCategoryMapper(event.treeModel.nodes)
    let subCategoryId = this.getSubCategoryId(event.node.data.name)
    this.selectedSubCategories.push(subCategoryId)
    this.selectedCategoryCount = this.selectedCategoryCount + 1
  }

  onDeselectSubCategory(event) {
    let index = this.selectedSubCategories.indexOf(event.node.data.name, 0);
    if (index == -1) {
       this.selectedSubCategories.splice(index, 1);
       this.selectedCategoryCount = this.selectedCategoryCount - 1
    }
  }

  onToggleSubCategory(event) {
    let treeDropdownMenu: HTMLElement = document.getElementsByClassName("tree-dropdown-menu")[0] as HTMLElement
    treeDropdownMenu.className = "dropdown-menu tree-dropdown-menu px-3 py-4 my-2 tree-dropdown";

    let dropdownMenuLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[0] as HTMLElement
    dropdownMenuLink.setAttribute('aria-expanded', 'true')
    setTimeout(() => {
      dropdownMenuLink.setAttribute('aria-expanded', 'true')
    }, 10);
  }

  getPeriods() {
    this.dataservice.getPeriods().then((value: any[]) => {
      this.getCatchmentAreas()

      this.periods = value;
      this.isPeriodDataReady = true;
      // create dynamic period form
      let periodGroup = {};
      this.periods.forEach(x=>{
        periodGroup[x["id"]]=new FormControl('');
      })
      this.periodForm = this.fb.group(periodGroup);
    })
  }

  getCatchmentAreas() {
    this.dataservice.getAreas(this.selectedProjectId).then((value: any[]) => {
      this.catchmentAreas = value;
      if (this.catchmentAreas.length) {
        this.isAreaDataReady = true;
      }

      let catchments = [];
      for (let catchment of this.catchmentAreas) {
        catchments.push(catchment["id"])
      }

      this.getStreets(catchments)
      this.getBrandCategories()

      // create dynamic category form
      let catchmentAreaGroup = {};
      this.catchmentAreas.forEach(x=>{
        catchmentAreaGroup[x["id"]]=new FormControl('');
      })
      this.catchmentAreaForm = this.fb.group(catchmentAreaGroup);
    })
  }

  streetDataMapper(streets, catchment_list) {
    let newStreetData = [];
    let catchments = [];
    for (let street of streets) {
      if (catchment_list.length) {
        if ((catchments.indexOf(street["area"]["name"]) < 0) && (catchment_list.indexOf(street["area"]["id"]) > -1))  {
          catchments.push(street["area"]["name"])
          newStreetData.push({
            // id: street["area"]["id"],
            name: street["area"]["name"],
            children: []
          })
        }
      } else {
        if (catchments.indexOf(street["area"]["name"]) < 0) {
          catchments.push(street["area"]["name"])
          newStreetData.push({
            // id: street["area"]["id"],
            name: street["area"]["name"],
            children: []
          })
        }
      }
    }
    for (let street of streets) {
      for (let root of newStreetData) {
        if (street["area"]["name"] == root["name"]) {
          root["children"].push({
            // id: street["id"],
            name: street["name"],
          })
        }
      }
    }
    return newStreetData
  }

  treeAndStreetMapper(treeData) {
    for (let street of treeData) {
      for (let sub of street["children"]) {
        for (let st of this.streets) {
          if (sub["name"] == st["name"]) {
            this.streetsTreeDataMapping[sub["id"]] = st["id"]
          }
        }
      }
    }
  }

  getStreets(catchments) {
    if (catchments.length) {
      this.dataservice.getStreets(this.selectedProjectId, catchments).then((value: any[]) => {
        this.streets = value;
        this.streetsTreeData = this.streetDataMapper(value, []);
        this.isFilterDataReady = true;
      })
    } else {
      this.streetsTreeData = [];
      this.isFilterDataReady = true;
    }
  }

  getStreetId(name) {
    for (let street of this.streets) {
      if (street["name"] == name) {
        return Number(street["id"])
      }
    }
  }

  onStateChangeStreet(event) {
    let selectedNodesIds = Object.keys(event.selectedLeafNodeIds)
    let selectedIds = [];
    let selectedSubIds = [];
    for (let nodeId of selectedNodesIds) {
      let subId = this.streetsTreeDataMapping[nodeId]
      if (selectedIds.indexOf(subId) == -1) {
        selectedIds.push(Number(subId))
      }
    }

    selectedIds = selectedIds.sort()
    if (this.filterPostData["street"]) {
      selectedSubIds = this.filterPostData["street"].sort()
    }

    const equals = (selectedIds, selectedSubIds) => JSON.stringify(selectedIds) === JSON.stringify(selectedSubIds);

    let is_same = equals(selectedIds, selectedSubIds)
    if (!is_same) {
      this.applyLocationFilters("street", false);
    }
  }

  onSelectStreet(event) {
    this.treeAndStreetMapper(event.treeModel.nodes)
    let streetId = this.getStreetId(event.node.data.name)
    this.selectedStreets.push(streetId)
    this.selectedStreetCount = this.selectedStreetCount + 1
  }

  onDeselectStreet(event) {
    let index = this.selectedStreets.indexOf(event.node.data.name, 0);
    if (index == -1) {
       this.selectedStreets.splice(index, 1);
       this.selectedStreetCount = this.selectedStreetCount - 1
    }
  }

  onToggleStreet(event) {
    let treeDropdownMenu: HTMLElement = document.getElementsByClassName("tree-dropdown-menu")[1] as HTMLElement
    treeDropdownMenu.className = "dropdown-menu tree-dropdown-menu px-3 py-4 my-2 tree-dropdown";

    let dropdownMenuLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[3] as HTMLElement
    dropdownMenuLink.setAttribute('aria-expanded', 'true')
    setTimeout(() => {
      dropdownMenuLink.setAttribute('aria-expanded', 'true')
    }, 10);
  }

  clickOutside(event, type) {
    if (type == "street") {
      let treeDropdownMenu: HTMLElement = document.getElementsByClassName("tree-dropdown-menu")[1] as HTMLElement
      treeDropdownMenu.className = "dropdown-menu tree-dropdown-menu px-3 py-4 my-2";
      let dropdownMenuLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[3] as HTMLElement
      dropdownMenuLink.setAttribute('aria-expanded', 'false')
    }
    else if (type == "category") {
      let treeDropdownMenu2: HTMLElement = document.getElementsByClassName("tree-dropdown-menu")[0] as HTMLElement
      treeDropdownMenu2.className = "dropdown-menu tree-dropdown-menu px-3 py-4 my-2";
      let dropdownMenuLink2: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[0] as HTMLElement
      dropdownMenuLink2.setAttribute('aria-expanded', 'false')
    }
  }

  resetActiveNodes() {
    // for suncategory tree
    this.selectedCategoryCount = 0;
    this.subCategoriesTreeData = [];
    this.subCategoriesTreeData = this.categoryDataMapper(this.subCategories, [])
    // for street tree
    this.selectedStreetCount = 0;
    this.streetsTreeData = [];
    this.streetsTreeData = this.streetDataMapper(this.streets, []);
    // Object.entries(this.tree.treeModel.selectedLeafNodeIds).forEach(([key, value]) => {
    //   let node = this.tree.treeModel.getNodeById(key)
    //   if (node) {
    //     node.setIsSelected(false)
    //   }
    // });
    // this.resetActiveNodes2()
  }

  resetActiveNodes2() {
    Object.entries(this.tree.treeModel.selectedLeafNodeIds).forEach(([key, value]) => {
      let node = this.tree.treeModel.getNodeById(key)
      if (node) {
        node.setIsSelected(false)
      }
    });
  }

  toggleFilterButton(type) {
    this.selectedFilter = type
    if (type == "category") {
      this.by_category = "btn-dark dark-btn"
      this.by_period = "btn-light light-btn"
      this.by_catchment_area = "btn-light light-btn"
      this.by_street = "btn-light light-btn"
    }
    else if (type == "period") {
      this.by_category = "btn-light light-btn"
      this.by_period = "btn-dark dark-btn"
      this.by_catchment_area = "btn-light light-btn"
      this.by_street = "btn-light light-btn"
    }
    else if (type == "catchment") {
      this.by_category = "btn-light light-btn"
      this.by_period = "btn-light light-btn"
      this.by_catchment_area = "btn-dark dark-btn"
      this.by_street = "btn-light light-btn"
    }
    else if (type == "street") {
      this.by_category = "btn-light light-btn"
      this.by_period = "btn-light light-btn"
      this.by_catchment_area = "btn-light light-btn"
      this.by_street = "btn-dark dark-btn"
    }
  }

  resetFilters() {
    this.markerGroup.clearLayers();
    this.markerGroup = L.layerGroup();
    this.filterPostData = {};
    this.by_category = "btn-dark dark-btn";
    this.by_period = "btn-light light-btn";
    this.by_catchment_area = "btn-light light-btn";
    // this.categoryForm.reset();
    this.periodForm.reset();
    this.catchmentAreaForm.reset();
    this.applyLocationFilters("", false);
    this.resetActiveNodes()
    this.selectedStreets = [];
    this.selectedSubCategories = [];
    this.selectedCategoryCount = 0;
    this.selectedPeriodCount = 0;
    this.selectedCatchmentAreasCount = 0;
    this.selectedStreetCount = 0;
  }

  openFactsheetModal() {
    this.isFactsheetForm = true;
    this.factsheetForm.reset()
  }

  generateFactsheet() {
    this.isFactsheetForm = false;
  }

  searchCategory() {
    this.searchedCategories = this.subCategories
  }

  searchCatchmentArea() {
    this.searchedCatchmentAreas = this.catchmentAreas
  }

  checkCategoryOrSubCategory(categories) {
    let deleted_cat = []
    for (let category of categories) {
      for (let sub of this.subCategories) {
        let subs = []
        if (sub["category"]["id"] == category) {
          subs.push(sub["id"])
          if (this.selectedSubCategories.indexOf(sub["id"]) == -1) {
            deleted_cat.push(category)
          }
        }
      }
    }
    deleted_cat = [...new Set(deleted_cat)]
    this.filterPostData["categories"] = categories.filter(item => deleted_cat.indexOf(item) < 0);

    let deleted_sub = []
    for (let sub of this.selectedSubCategories) {
      for (let subCat of this.subCategories) {
        if (sub == subCat["id"]) {
          for (let cat of this.filterPostData["categories"]) {
            if (subCat["category"]["id"] == cat) {
              deleted_sub.push(sub)
            }
          }
        }
      }
    }
    deleted_sub = [...new Set(deleted_sub)]
    this.filterPostData["sub-categories"] = this.filterPostData["sub-categories"].filter(item => deleted_sub.indexOf(item) < 0);
  }

  applyLocationFilters(type, isInit) {
    if (type == "category") {
      // let postData = []
      // for (let i in this.categoryForm.value) {
      //   if (this.categoryForm.value[i] == true) {
      //     postData.push(Number(i))
      //   }
      // }
      // this.filterPostData["categories"] = postData
      // this.categorySearchFilter()
      let subCatPostData = this.selectedSubCategories
      this.filterPostData["sub-categories"] = subCatPostData
      let catPostData = []
      for (let sub_id of this.selectedSubCategories) {
        for (let sub of this.subCategories) {
          if (sub_id == sub["id"]) {
            if (catPostData.indexOf(sub["category"]["id"]) == -1) {
              catPostData.push(sub["category"]["id"])
            }
          }
        }
      }
      this.checkCategoryOrSubCategory(catPostData)
    }
    else if (type == "period") {
      let postData = []
      for (let i in this.periodForm.value) {
        if (this.periodForm.value[i] == true) {
          for (let period of this.periods) {
            if (Number(i) == period["id"]) {
              postData.push(period["name"])
            }
          }
        }
      }
      this.filterPostData["periods"] = postData
      this.periodSearchFilter()
    }
    else if (type == "catchment-area") {
      let postData = []
      for (let i in this.catchmentAreaForm.value) {
        if (this.catchmentAreaForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterPostData["catchments"] = postData
      // this.categorySearchFilter()
      this.periodSearchFilter()
      this.catchmentAreaSearchFilter()
      if (!postData.length) {
        this.selectedStreetCount = 0;
        delete this.filterPostData["streets"];
      } else {
        let catchment_id;
        let removed_street_ids = [];
        if (this.filterPostData["streets"]) {
          for (let street_id of this.filterPostData["streets"]) {
            for (let street of this.streets) {
              if (street_id == street["id"]) {
                catchment_id = street["area"]["id"]
                if (postData.indexOf(catchment_id) < 0) {
                  removed_street_ids.push(street_id)
                }
              }
            }
          }
          this.selectedStreetCount = this.selectedStreetCount - removed_street_ids.length;
          this.filterPostData["streets"] = this.filterPostData["streets"].filter(item => !removed_street_ids.includes(item))
        }
      }
      this.streetsTreeData = this.streetDataMapper(this.streets, postData);
      if (this.selectedCatchmentAreasCount) {
        this.isStreetDataReady = true;
      }
    }
    else if (type == "street") {
      let postData = this.selectedStreets
      this.filterPostData["streets"] = postData
      // this.categorySearchFilter()
      this.periodSearchFilter()
      this.catchmentAreaSearchFilter()
    }
    else if (type == "grayMarker") {
      this.isGrayMarker = !this.isGrayMarker
      this.filterPostData["grayMarker"] = this.isGrayMarker
    }

    if (this.isGrayMarker) {
      this.filterPostData["grayMarker"] = this.isGrayMarker
    }

    this.dataservice.applyLocationFilters(this.selectedProjectId, this.filterPostData).then((value: any[]) => {
      this.locations = value;

      if (!isInit) {
        this.updateCharts()
        // this.updateAudienceData()
        this.getData()
      }

      this.markerGroup.clearLayers();
      this.markerGroup = L.layerGroup();
      this.map.addLayer(this.markerGroup);

      for (let location of this.locations) {
        let price = this.convertPriceLevel(location["price_level"])
        let size;
        if (location["radius_tenant_size"] == null) {
          size = "-"
        } else {
          size = location["radius_tenant_size"]
        }
        let instagram_profile;
        if (location["instagram_profile"] == null) {
          instagram_profile = "-"
        } else {
          instagram_profile = `<a style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #6F56A6;" href="https://www.instagram.com/${location["instagram_profile"]}" target="_blank">@${location["instagram_profile"]}</a>`
        }

        let icon_name = "";
        if (location["related_area"]) {
          icon_name = this.iconMapping["related"]
        }
        else if (location["has_instagram_profile"]) {
          icon_name = this.iconMapping[location["radius_tenant_category"]]
        }
        else if ((!location["has_instagram_profile"]) && (!location["related_area"])) {
          icon_name = this.iconMapping["extra"]
        }

        if (!icon_name) {
          icon_name = this.iconMapping["without"]
        }
        // Marker
        let icon = L.marker([ location['lat'], location['lng'] ], {
          icon: L.icon({
            iconSize: [ 25, 41 ],
            iconAnchor: [ 13, 41 ],
            popupAnchor: [0, -30],
            iconUrl: "/assets/icon/map/" + icon_name
          })
        }).bindPopup(`
          <div class="text-center m-2 py-3">
            <div class="d-flex justify-content-center">
              -
            </div>
            <p class="m-2" style="font-family: Montserrat; font-weight: 500;font-size: 12px;color: #4A4A4A;">-</p>
            <p class="m-2" style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #6F56A6;">-<img src="../../assets/icon/open-in-new.png" style="margin-left:4px; width:15px; height:15px;"></p>
            <p class="m-2" style="font-family: Montserrat; font-weight: normal;font-size: 12px;color: #202020;">-</p>
            <div class="mt-1" style="text-align: left;">
              <p class="m-1" style="font-family: Montserrat; font-weight: normal;font-size: 12px;color: #202020;"><span style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #202020;">Size: </span>-</p>
              <p class="m-1" style="font-family: Montserrat; font-weight: normal;font-size: 12px;color: #202020;"><span style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #202020;">Sub-Category: </span>-</p>
              <p class="m-1" style="font-family: Montserrat; font-weight: normal;font-size: 12px;color: #202020;"><span style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #202020;">Follower Number: </span>-</p>
              <p class="m-1" style="font-family: Montserrat; font-weight: normal;font-size: 12px;color: #202020;"><span style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #202020;">Engagement Rate: </span>-</p>
              <p class="m-1" style="font-family: Montserrat; font-weight: normal;font-size: 12px;color: #202020;"><span style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #202020;">Digital Footfall: </span>-</p>
              <p class="m-1" style="font-family: Montserrat; font-weight: normal;font-size: 12px;color: #202020;"><span style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #202020;">New Generation Retail: </span>-</p>
              <p class="m-1" style="font-family: Montserrat; font-weight: normal;font-size: 12px;color: #202020;"><span style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #202020;">Price: </span>-</p>
              <p class="m-1" style="font-family: Montserrat; font-weight: normal;font-size: 12px;color: #202020;"><span style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #202020;">Rating: </span>-</p>
            </div>
          </div>
        `);
        icon.on('click', function(e) {
          var popup = e.target.getPopup();
          var url=`https://api.neare.co.uk/v1/locations/${location["id"]}`;
          $.get(url).done(function(data) {
            let location = data;
            console.log(location)
            let image = "";
            if (location["image"] == "-" || location["image"] == null) {
              image = `<p class="m-2" style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #4A4A4A; border: 1px solid #6e6e6e; width: max-content; padding: 10px;">${location["location"].slice(0,3)}.</p>`
            } else {
              image = `<img src="${location['image']}" style="width: 45px; height: 45px;">`
            }
            let follower_number;
            if (location["followers"] == null) {
              follower_number = "-"
            } else {
              follower_number = location["followers"]
            }
            let engagement_rate;
            if (location["engagement_rate"] == null) {
              engagement_rate = "-"
            } else {
              engagement_rate = location["engagement_rate"]
            }
            let footfall_category_avg;
            if (location["footfall_category_avg"] == null) {
              footfall_category_avg = "-"
            } else {
              footfall_category_avg = location["footfall_category_avg"]
            }
            popup.setContent(`
              <div class="text-center m-2">
                <div class="d-flex justify-content-center">
                  ${image}
                </div>
                <p class="m-2" style="font-family: Montserrat; font-weight: 500;font-size: 12px;color: #4A4A4A;">${location["location"]}</p>
                <p class="m-2" style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #6F56A6;">${instagram_profile}<img src="../../assets/icon/open-in-new.png" style="margin-left:4px; width:15px; height:15px;"></p>
                <p class="m-2" style="font-family: Montserrat; font-weight: normal;font-size: 12px;color: #202020;">${location["address"]}</p>
                <div class="mt-1" style="text-align: left;">
                  <p class="m-1" style="font-family: Montserrat; font-weight: normal;font-size: 12px;color: #202020; padding-bottom: 3px;"><span style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #202020; margin-left:26px;">Size: </span>${size}</p>
                  <p class="m-1" style="font-family: Montserrat; font-weight: normal;font-size: 12px;color: #202020; padding-bottom: 3px;"><span style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #202020; margin-left:26px;">Sub-Category: </span>${location["types"]}</p>
                  <p class="m-1" style="font-family: Montserrat; font-weight: normal;font-size: 12px;color: #202020; padding-bottom: 3px;"><span style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #202020; margin-left:26px;">Follower Number: </span>${follower_number}</p>
                  <p class="m-1" style="font-family: Montserrat; font-weight: normal;font-size: 12px;color: #202020; padding-bottom: 3px;"><span style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #202020; margin-left:26px;">Engagement Rate: </span>${engagement_rate}</p>
                  <div class="d-flex align-items-center">
                    <div class="dropdown" style="margin-bottom: 3px; margin-right:5px;">
                      <button class="btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false" style="height: 20px; width: 20px !important;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-info-circle" style="top: -9px;position: relative; margin-left: 5px; opacity: 0.5;" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style="min-width: 290px;">
                        <p style="font-family: Montserrat; color: #5D5864; font-size: 12px; margin:10px;">
                        The Digital Footfall Score is an intelligent ranking
                        method to estimate the amount of digital and physical
                        engagement for a brand in a location. </br>
                        This score is built from online reviews, social media
                        activity, physical visits, audience demographics and
                        other physical and digital measures.
                        </p>
                      </div>
                    </div>
                    <p class="m-1" style="font-family: Montserrat; font-weight: normal;font-size: 12px;color: #202020;"><span style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #202020;">Digital Footfall:
                      </span>${location["footfall_category_avg"]}
                    </p>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="dropdown" style="margin-bottom: 3px; margin-right:5px;">
                      <button class="btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false" style="height: 20px; width: 20px !important;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-info-circle" style="top: -9px;position: relative; margin-left: 5px; opacity: 0.5;" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style="min-width: 290px;">
                        <p style="font-family: Montserrat; color: #5D5864; font-size: 12px; margin:10px;">
                          A business is defined as "NGR" if it offers a service,
                          experience or product that is recognised as being unique.
                        </p>
                      </div>
                    </div>
                    <p class="m-1" style="font-family: Montserrat; font-weight: normal;font-size: 12px;color: #202020;"><span style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #202020;">New Generation Retail:
                      </span>${location["radius_tenant_new_gen"]}
                    </p>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="dropdown" style="margin-bottom: 3px; margin-right:5px;">
                      <button class="btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false" style="height: 20px; width: 20px !important;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-info-circle" style="top: -9px;position: relative; margin-left: 5px; opacity: 0.5;" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style="min-width: 290px;">
                        <p style="font-family: Montserrat; color: #5D5864; font-size: 12px; margin:10px;">
                          The relative price point of the specific location
                          (scale: 0 to 4). The exact amount indicated by a
                          specific value will vary from region to region.
                          Price levels are interpreted as follows: <br>
                          0 Free <br>
                          1 Inexpensive <br>
                          2 Moderate <br>
                          3 Expensive <br>
                          4 Very Expensive <br>
                        </p>
                      </div>
                    </div>
                    <p class="m-1" style="font-family: Montserrat; font-weight: normal;font-size: 12px;color: #202020;"><span style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #202020;">Price:
                      </span>${location["price_level"]}
                    </p>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="dropdown" style="margin-bottom: 3px; margin-right:5px;">
                      <button class="btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false" style="height: 20px; width: 20px !important;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-info-circle" style="top: -9px;position: relative; margin-left: 5px; opacity: 0.5;" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style="min-width: 290px;">
                        <p style="font-family: Montserrat; color: #5D5864; font-size: 12px; margin:10px;">
                          The place's rating, based on aggregated consumer
                          reviews from Google, TripAdvisor, Foursquare and
                          Twitter (scale: 1.0 to 5.0).
                        </p>
                      </div>
                    </div>
                    <p class="m-1" style="font-family: Montserrat; font-weight: normal;font-size: 12px;color: #202020;"><span style="font-family: Montserrat; font-weight: 600;font-size: 12px;color: #202020;">Rating:
                      </span>${location["rating"]}
                    </p>
                  </div>
                </div>
              </div>
            `);
            popup.update();
          });
        })
        this.markerGroup.addLayer(icon);
      }
      this.map.fitBounds(this.map.getBounds());
    })
  }

  convertPriceLevel(price_level) {
    let price = "";
    if (price_level == -1) {
      price = "-"
    }
    if (price_level == "-") {
      price = "-"
    }
    else if (price_level == 1) {
      price = "£"
    }
    else if (price_level == 2) {
      price = "££"
    }
    else if (price_level == 3) {
      price = "£££"
    }
    else if (price_level == 4) {
      price = "££££"
    }
    return price
  }

  // categorySearchFilter() {
  //   this.selectedCategoryCount = 0;
  //   let form_values = this.categoryForm.value;
  //   for (let item of this.categories) {
  //     if (form_values[item["id"]] == true) {
  //       this.selectedCategoryCount = this.selectedCategoryCount + 1
  //     }
  //   }
  // }

  periodSearchFilter() {
    this.selectedPeriodCount = 0;
    let form_values = this.periodForm.value;
    for (let item of this.periods) {
      if (form_values[item["id"]] == true) {
        this.selectedPeriodCount = this.selectedPeriodCount + 1
      }
    }
  }

  catchmentAreaSearchFilter() {
    this.selectedCatchmentAreasCount = 0;
    let form_values = this.catchmentAreaForm.value;
    for (let item of this.catchmentAreas) {
      if (form_values[item["id"]] == true) {
        this.selectedCatchmentAreasCount = this.selectedCatchmentAreasCount + 1
      }
    }
    if (this.selectedCatchmentAreasCount) {
      this.isStreetDataReady = true;
    } else {
      this.isStreetDataReady = false;
    }
  }

  brandCategorySearchFilter() {
    this.selectedBrandCategoryCount = 0;
    let form_values = this.brandCategoryForm.value;
    for (let item of this.brandCategories) {
      if (form_values[item["id"]] == true) {
        this.selectedBrandCategoryCount = this.selectedBrandCategoryCount + 1
      }
    }
  }

  getPriceBreakdownData() {
    this.dataservice.getPriceBreakdownData(this.selectedProjectId, this.filterPostData).then((value: any[]) => {
      this.priceBreakdownDataLabels = [];
      this.priceBreakdownData = [];
      let prices = value;
      for (let price of prices) {
        this.priceBreakdownDataLabels.push(price["price_level"])
        this.priceBreakdownData.push(price["value"])
      }
    })

    this.priceBreakdownDataColors = [
      { backgroundColor: ['#E05B4C', '#ECB4AE', 'rgba(39, 29, 61, 0.5)', '#271D3D'] }
    ]

    this.priceBreakdownOptions = {
      responsive: true,
      cutoutPercentage: 75,
      legend: {
        position: 'right',
        labels: {
          padding: 25
        }
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
        callbacks: {
          label : function(tooltipItem, data) {
            var dataset = data["datasets"][0];
            var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
             return previousValue + currentValue;
            });
            var currentValue = dataset["data"][tooltipItem.index];
            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
            return data["labels"][tooltipItem.index] + ": " + dataset["data"][tooltipItem.index] + " (" + percentage + "%)";
          }
        }
      }
    };
  }

  getTenantBreakdownData() {
    this.tenantBreakdownDataColors = [{ backgroundColor: []}]
    let colors_codes = ['#584d8d', '#a23b72', '#3b1f2b', '#5e2728', '#f18f01',
    '#dc670f', "#ca653a", "#c73e1d", "#8981b9", "#2e86ab", "#812f24"];
    let color_mapping = {};
    for (let category of this.categories) {
      color_mapping[category["name"]] = colors_codes[this.categories.indexOf(category)]
    }

    this.dataservice.getTenantBreakdownData(this.selectedProjectId, this.filterPostData).then((value: any[]) => {
      this.tenantBreakdownDataLabels = [];
      this.tenantBreakdownData = [];
      let categories = value;
      for (let category of categories) {
        this.tenantBreakdownDataLabels.push(category["name"])
        this.tenantBreakdownData.push(category["count"])
        this.tenantBreakdownDataColors[0]["backgroundColor"].push(color_mapping[category["name"]])
      }
    })

    this.tenantBreakdownOptions = {
      responsive: true,
      cutoutPercentage: 75,
      legend: {
        position: 'right',
        labels: {
          padding: 5
        }
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
        callbacks: {
          label : function(tooltipItem, data) {
            var dataset = data["datasets"][0];
            var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
             return previousValue + currentValue;
            });
            var currentValue = dataset["data"][tooltipItem.index];
            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
            return data["labels"][tooltipItem.index] + ": " + dataset["data"][tooltipItem.index] + " (" + percentage + "%)";
          }
        }
      }
    };
  }

  getAgeData() {
    this.ageDataLabels = [];
    this.ageData = [];
    for (let age of Object.keys(this.audience["age"])) {
      this.ageDataLabels.push(age)
      this.ageData.push(this.audience["age"][age])
    }

    this.ageDataColors = [
      { backgroundColor: '#ECB4AE' }
    ]

    this.ageOptions = {
      responsive: true,
      legend: { display: false },
      scales: {
        xAxes: [{
          ticks: { display: false, beginAtZero:true },
      }],
        yAxes: [{
          barPercentage : 0.6,
          gridLines: { display: false, drawBorder: false }
        }]
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
        callbacks: {
          label : function(tooltipItem, data) {
            var dataset = data["datasets"][0];
            var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
             return previousValue + currentValue;
            });
            var currentValue = dataset["data"][tooltipItem.index];
            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
            return " (" + percentage + "%)";
          }
        }
      }
    };
  }

  getPersonalIncomeData() {
    this.personalIncomeDataLabels = [];
    this.personalIncomeData = [];
    for (let pi of Object.keys(this.audience["personal_income"])) {
      this.personalIncomeDataLabels.push(pi)
      this.personalIncomeData.push(this.audience["personal_income"][pi])
    }

    this.personalIncomeDataColors = [
      { backgroundColor: '#ECB4AE' }
    ]

    this.personalIncomeOptions = {
      responsive: true,
      legend: { display: false },
      scales: {
        xAxes: [{
          ticks: { display: false, beginAtZero:true },
      }],
        yAxes: [{
          barPercentage : 0.6,
          gridLines: { display: false, drawBorder: false }
        }]
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
        callbacks: {
          label : function(tooltipItem, data) {
            var dataset = data["datasets"][0];
            var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
             return previousValue + currentValue;
            });
            var currentValue = dataset["data"][tooltipItem.index];
            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
            return " (" + percentage + "%)";
          }
        }
      }
    };
  }

  getGenderData() {
    this.genderDataLabels = [];
    this.genderData = [];
    for (let gender of Object.keys(this.audience["gender"])) {
      this.genderDataLabels.push(gender)
      this.genderData.push(this.audience["gender"][gender])
    }

    this.genderDataColors = [
      { backgroundColor: ['#ECB4AE', '#938E9E', '#271D3D'] }
    ]

    this.genderOptions = {
      responsive: true,
      legend: { display: false },
      scales: {
        xAxes: [{
          ticks: { display: false, beginAtZero:true },
      }],
        yAxes: [{
          barPercentage : 0.6,
          gridLines: { display: false, drawBorder: false }
        }]
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
        callbacks: {
          label : function(tooltipItem, data) {
            var dataset = data["datasets"][0];
            var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
             return previousValue + currentValue;
            });
            var currentValue = dataset["data"][tooltipItem.index];
            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
            return " (" + percentage + "%)";
          }
        }
      }
    };
  }

  getPopularTimesHour() {
    this.dataservice.getPopularTimesHour(this.selectedProjectId, this.filterPostData).then((value: any[]) => {
      this.popularTimesHourDataLabels = [];
      this.popularTimesHourData = [];
      let hours = value;
      for (let hour of Object.keys(hours)) {
        this.popularTimesHourDataLabels.push(hour)
        this.popularTimesHourData.push(hours[hour])
      }
    })

    this.popularTimesHourDataColors = [
      { backgroundColor: 'rgba(224, 91, 76, 0.5)'}
    ]

    this.popularTimesHourOptions = {
      responsive: true,
      legend: { display: false },
      scales: {
        xAxes: [{
          barPercentage : 0.4,
          gridLines: { display: false, drawBorder: false }
      }],
        yAxes: [{
          ticks: { display: false, beginAtZero:true },
        }]
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
      }
    };
  }

  dayMapping(day) {
    let days = {
      "Sunday": "Sun",
      "Monday": "Mon",
      "Tuesday": "Tue",
      "Wednesday": "Wed",
      "Thursday": "Thu",
      "Friday": "Fri",
      "Saturday": "Sat",
    }
    return days[day];
  }

  getPopularTimesDay() {
    this.dataservice.getPopularTimesDay(this.selectedProjectId, this.filterPostData).then((value: any[]) => {
      this.popularTimesDayDataLabels = [];
      this.popularTimesDayData = [];
      let days = value;
      for (let day of Object.keys(days)) {
        this.popularTimesDayDataLabels.push(this.dayMapping(day))
        this.popularTimesDayData.push(days[day])
      }
    })

    this.popularTimesDayDataColors = [
      { backgroundColor: 'rgba(224, 91, 76, 0.5)'}
    ]

    this.popularTimesDayOptions = {
      responsive: true,
      legend: { display: false },
      scales: {
        xAxes: [{
          barPercentage : 0.4,
          gridLines: { display: false, drawBorder: false }
      }],
        yAxes: [{
          ticks: { display: false, beginAtZero:true },
        }]
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
      }
    };
  }

  monthMapping(month) {
    let months = {
      "January": "Jan",
      "February": "Feb",
      "March": "Mar",
      "April": "Apr",
      "May": "May",
      "June": "June",
      "July": "July",
      "August": "Aug",
      "September": "Sep",
      "October": "Oct",
      "November": "Nov",
      "December": "Dec",
    }
    return months[month];
  }

  getPopularTimesMonth() {
    this.dataservice.getPopularTimesMonth(this.selectedProjectId, this.filterPostData).then((value: any[]) => {
      this.popularTimesMonthDataLabels = [];
      this.popularTimesMonthData = [];
      let months = value;
      for (let month of Object.keys(months)) {
        this.popularTimesMonthDataLabels.push(this.monthMapping(month))
        this.popularTimesMonthData.push(months[month])
      }
    })

    this.popularTimesMonthDataColors = [
      { backgroundColor: 'rgba(224, 91, 76, 0.5)'}
    ]

    this.popularTimesMonthOptions = {
      responsive: true,
      legend: { display: false },
      scales: {
        xAxes: [{
          barPercentage : 0.4,
          gridLines: { display: false, drawBorder: false }
      }],
        yAxes: [{
          ticks: { display: false, beginAtZero:true },
        }]
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
      }
    };
  }

  moreOccupation() {
    this.isMoreOccupation = false;
    this.isScrollDivOccupation = true;
    this.occupations = this.all_occupations;
  }

  moreLike() {
    this.isMoreLike = false;
    this.isScrollDivLike = true;
    this.like_and_interesets = this.all_like_and_interesets;
  }

  moreTopBrands() {
    this.isMoreTopBrands = false;
    this.isScrollDivTopBrands = true;
    this.top_brands = this.all_top_brands;
  }

  moreCelebrity() {
    this.isMoreCelebrity = false;
    this.isScrollDivCelebrity = true;
    this.mostly_liked_celebrities = this.all_mostly_liked_celebrities;
  }

  applyBrandCategoryFilter() {
    let postData = []
    for (let i in this.brandCategoryForm.value) {
      if (this.brandCategoryForm.value[i] == true) {
        postData.push(Number(i))
      }
    }
    this.filterPostData["brandCategories"] = postData
    this.brandCategorySearchFilter()
    this.dataservice.applyBrandCategoryFilter(this.selectedProjectId, this.filterPostData).then((value: any[]) => {
      this.audience = value;
      this.updateAudienceData()
      this.isScrollDivOccupation = false;
      this.isScrollDivLike = false;
      this.isScrollDivTopBrands = false;
      this.isScrollDivCelebrity = false;
    })
  }

  milesSeperator(value) {
    if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

}
