import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { NeareComponent } from './components/neare/neare.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CatchmentComponent } from './components/neare/catchment/catchment.component';
import { LeasingComponent } from './components/neare/leasing/leasing.component';
import { EngagementComponent } from './components/neare/engagement/engagement.component';
import { AudienceComponent } from './components/neare/audience/audience.component';
import { EngagementActivityComponent } from './components/neare/engagement-activity/engagement-activity.component';
import { ArchitectureComponent } from './components/neare/architecture/architecture.component';
import { BrandRadarComponent } from './components/neare/brand-radar/brand-radar.component';
import { BrandRadarDemoComponent } from './components/neare/brand-radar-demo/brand-radar-demo.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ProjectComponent } from './components/settings/project/project.component';
import { LogoComponent } from './components/logo/logo.component';
import { VideoComponent } from './components/video/video.component';
import { HealthComponent } from './components/health/health.component';
import { PingComponent } from './components/ping/ping.component';
import { WidgetComponent } from './components/widget/widget.component';
import { Widget2Component } from './components/widget2/widget2.component';
import { EmbedComponent } from './components/embed/embed.component';

const routes: Routes = [
  {path:'',component: HomeComponent},
  {path:'dashboard',component: DashboardComponent},
  {path:'logo',component: LogoComponent},
  {path:'video',component: VideoComponent},
  {path:'health',component: HealthComponent},
  {path:'ping',component: PingComponent},
  {path:'settings',component: SettingsComponent, children: [
      {
        path: 'projects',
        component: ProjectComponent,
      }
    ]
  },
  {path:'cbre',component: WidgetComponent},
  {path:'savills',component: Widget2Component},
  {path:'princesshay-strategy',component: EmbedComponent},
  {path:'sample-strategy-report',component: EmbedComponent},
  {path:'mount-gay-strategy',component: EmbedComponent},
  {path:'thecrownestate2022',component: EmbedComponent},
  {path:'rbge-strategy',component: EmbedComponent},
  {path:':city-name', component: NeareComponent, children: [
      {
        path: 'catchment-area',
        component: CatchmentComponent,
      },
      {
        path: 'smart-leasing',
        component: LeasingComponent,
      },
      {
        path: 'audience',
        component: AudienceComponent,
      },
      {
        path: 'engagement-curator',
        component: EngagementComponent,
      },
      {
        path: 'engagement-activity',
        component: EngagementActivityComponent,
      },
      {
        path: 'architecture',
        component: ArchitectureComponent,
      },
      {
        path: 'brand-radar',
        component: BrandRadarComponent,
      },
      {
        path: 'brand-radar-demo',
        component: BrandRadarDemoComponent,
      }
    ]
  },
  {path:'mayfair-soho/:wigdet', component: NeareComponent, children: [
      {
        path: 'catchment-area',
        component: CatchmentComponent,
      }
    ]
  },
  {path: '**',  redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
