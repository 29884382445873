import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/auth/auth.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  currentUser = {};
  projects = [];

  constructor(
    private authService:AuthService,
    private dataservice: DataService
  ) { }

  ngOnInit(): void {
    if (this.authService.getToken()) {
      this.authService.getCurrentUser().then(value => {
        this.currentUser = value;
        this.authService.getUserProfile(this.currentUser["id"]).then(value => {
          this.currentUser["profile"]  = value;
        })

        this.dataservice.getProjects().then((value: any[]) => {
          this.projects = value;
        })
      })
    } else {
      window.location.href = '/';
    }
  }

  selectProject(project) {
    window.location.href = project["slug"]
  }

  logout(){
    this.authService.logout();
    this.authService.removeToken();
    window.location.href = '/';
  }

}
