<iframe *ngIf="widget == '/princesshay-strategy'"
  allowfullscreen="allowfullscreen"
  scrolling="no"
  class="fp-iframe vh-100"
  src="https://heyzine.com/flip-book/cdfcb3db08.html"
  style="border: 1px solid lightgray; width: 100%;">
</iframe>

<iframe *ngIf="widget == '/sample-strategy-report'"
  allowfullscreen="allowfullscreen"
  scrolling="no"
  class="fp-iframe vh-100"
  src="https://heyzine.com/flip-book/25cf440460.html"
  style="border: 1px solid lightgray; width: 100%;">
</iframe>

<iframe *ngIf="widget == '/mount-gay-strategy'"
  allowfullscreen="allowfullscreen"
  scrolling="no"
  class="fp-iframe vh-100"
  src="https://heyzine.com/flip-book/d25387907c.html"
  style="border: 1px solid lightgray; width: 100%;">
</iframe>

<iframe *ngIf="widget == '/thecrownestate2022'"
  allowfullscreen="allowfullscreen"
  scrolling="no"
  class="fp-iframe vh-100"
  src="https://heyzine.com/flip-book/42c2a376d2.html"
  style="border: 1px solid lightgray; width: 100%;">
</iframe>

<iframe *ngIf="widget == '/rbge-strategy'"
  allowfullscreen="allowfullscreen"
  scrolling="no"
  class="fp-iframe vh-100"
  src="https://heyzine.com/flip-book/55de36cb46.html"
  style="border: 1px solid lightgray; width: 100%;">
</iframe>
