<app-header></app-header>

<section class="top-section p-5">
  <div class="text-center">
    <p class="top-text-lg p-4">Matching Brands with Spaces <br>
      <span>to Create Thriving Places</span>
    </p>
    <p class="top-text-sm px-5 py-2"><span>NeaRE</span> empowers asset owners to identify
      and secure the right brand for retail spaces,<br> based on actual
      customer preferences as well as asset strategy
    </p>
    <div class="top-image pt-3">
      <img src="../../assets/img/top-image.png" alt="">
    </div>
  </div>
</section>

<section class="best-fit-section p-5">
  <div class="text-center">
    <p class="top-text-lg p-4 text-uppercase">Identifying the 'best-fit' brands</p>
    <p class="top-text-sm px-5 py-2">NeaRE cuts out the middleman and puts
      property owners back in charge of the leasing process. <br> For a given
      retail space, NeaRE:
    </p>
    <div class="row mt-5 mx-5">
      <div class="col-md-12 col-lg-4 d-flex justify-content-center align-items-center mb-5">
        <div class="card best-fit-card">
          <div class="card-body my-3">
            <img class="best-fit-icon mb-3" src="../../../assets/icon/icon1.png">
            <p>Defines the <br>
              <span>Catchment Customer</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-4 d-flex justify-content-center align-items-center mb-5">
        <div class="card best-fit-card">
          <div class="card-body my-3">
            <img class="best-fit-icon mb-3" src="../../../assets/icon/icon2.png">
            <p>Identifies the <br>
              <span>Actual Consumer <br> Preferences</span> of the Customer
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-4 d-flex justify-content-center align-items-center mb-5">
        <div class="card best-fit-card">
          <div class="card-body my-3">
            <img class="best-fit-icon mb-3" src="../../../assets/icon/icon3.png">
            <p>Aligns Leasing <br>
              <span>to Asset Strategy</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="quotes-section p-5">
  <div class="text-center">
    <div class="row mt-5 mx-5">
      <div class="col-md-12 col-lg-4 d-flex justify-content-center mb-5">
        <div class="quotes-card">
          <div class="quotes-img mb-4">
            <img src="../../../assets/img/radius.jpg">
          </div>
          <div class="quotes-text mb-1">
            <p>“Understanding the audience is the missing link in retail
              property analysis. <br><br> We’re moving away from a fixation on
              catchment maps to understanding exactly who the customer is,
              what they like, what they want and where they will be willing
              to visit”
            </p>
          </div>
          <div class="quotes-writer-div mt-2">
            <p class="quotes-writer">Burak Capli <br> <span>Radius Tech</span></p>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-4 d-flex justify-content-center mb-5">
        <div class="quotes-card">
          <div class="quotes-img mb-4">
            <img src="../../../assets/img/alterx.png">
          </div>
          <div class="quotes-text mb-1">
            <p>“It’s time for asset owners to take control of their destiny by
              actively identifying brands that align with the customers’ needs
              and their strategic vision. <br><br> neaRE has been designed to
              handover this exact power” <br><br><br>
            </p>
          </div>
          <div class="quotes-writer-div mt-2">
            <p class="quotes-writer">Bert Broadhead <br> <span>ALTERX, Property Owner</span></p>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-4 d-flex justify-content-center mb-5">
        <div class="quotes-card">
          <div class="quotes-img mb-4">
            <img src="../../../assets/img/miznon.png">
          </div>
          <div class="quotes-text mb-1">
            <p>“Occupiers like us are looking for more than just a glossy
              brochure and a quoting rent. <br><br> neaRE’s ability to show how
              a specific asset audience aligns with our own is revolutionising
              how we think about acquiring new premises”” <br><br>
            </p>
          </div>
          <div class="quotes-writer-div mt-2">
            <p class="quotes-writer">David Goldstein <br> <span>Miznon, International Restaurant Brand</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="contact-section" class="contact-section p-5">
  <div class="row m-5 px-2">
    <div class="col-12 col-lg-5 d-flex align-items-center pr-5">
      <div class="contact-detail" style="padding-right: 100px;">
        <div class="mb-3">
          <p class="contact-header text-uppercase">Get In Touch</p>
        </div>
        <p class="contact-text">
          To talk us about how NeaRE can
          support your goals and one of our experts will be in touch with
          you soon.
        </p>
        <a class="contact-text-address" href="mailto:info@314radius.com">info@314radius.com</a>
        <br><br>
        <p class="contact-text-address">
          Radius Kemp House 152 City Road<br>
          London, EC1V 2NX <br>
          United Kingdom
        </p>
      </div>
    </div>
    <div class="col-12 col-lg-7 pl-4">
      <div class="contact-card text-left p-4" *ngIf="isForm">
        <h4 class="contact-form-header mb-5">Request a Demo</h4>
        <form class="contact-form" [formGroup]="contactForm" (ngSubmit)="submitContact(contactForm.value)">
          <div class="row">
            <div class="col-12 col-md-6 mb-2">
              <div class="form-group mb-3">
                <label class="required-field mb-2" for="exampleFormControlInput1">First Name</label>
                <input type="text" class="form-control" formControlName="firstname" id="exampleFormControlInput1" placeholder="Enter your name" required>
                <span class="validation-message pl-2" *ngIf="(firstname.dirty || firstname.touched) && firstname.invalid && firstname.errors.required">
                  First name is required
                </span>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <div class="form-group mb-3">
                <label class="required-field mb-2" for="exampleFormControlInput1">Last Name</label>
                <input type="text" class="form-control" formControlName="lastname" id="exampleFormControlInput1" placeholder="Enter your last name" required>
                <span class="validation-message pl-2" *ngIf="(lastname.dirty || lastname.touched) && lastname.invalid && lastname.errors.required">
                  Last name is required
                </span>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <div class="form-group mb-3">
                <label class="required-field mb-2" for="exampleFormControlInput1">Mobile Number</label>
                <input type="text" class="form-control" formControlName="mobile" id="exampleFormControlInput1" placeholder="Enter your mobile number" required>
                <span class="validation-message pl-2" *ngIf="(mobile.dirty || mobile.touched) && mobile.invalid && mobile.errors.required">
                  Mobile number is required
                </span>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <div class="form-group mb-3">
                <label class="required-field mb-2" for="exampleFormControlInput1">E-Mail</label>
                <input type="email" class="form-control" formControlName="email" id="exampleFormControlInput1" placeholder="Enter your e-mail address" required>
                <span class="validation-message pl-2" *ngIf="(email.dirty || email.touched) && email.invalid && email.errors.required">
                  E-mail is required
                </span>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <div class="form-group mb-3">
                <label class="required-field mb-2" for="exampleFormControlInput1">Company Website</label>
                <input type="text" class="form-control" formControlName="website" id="exampleFormControlInput1" placeholder="eg: http://www.brandwebsite.uk" required>
                <span class="validation-message pl-2" *ngIf="(website.dirty || website.touched) && website.invalid && website.errors.required">
                  Company website is required
                </span>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <div class="form-group mb-3">
                <label class="required-field mb-2" for="exampleFormControlSelect1">Interest</label>
                <select class="form-control placeholder-min" formControlName="interest" id="exampleFormControlSelect1">
                  <option disabled>Select subject</option>
                  <option>Catchment Area Analysis</option>
                  <option>Local Consumer Insights</option>
                  <option>Local Market Dynamics</option>
                  <option>Leasing Strategy and Prospective Tenant Finding</option>
                  <option>Local Engagement Strategy</option>
                  <option>Other</option>
                </select>
                <span class="validation-message pl-2" *ngIf="(interest.dirty || interest.touched) && interest.invalid && interest.errors.required">
                  Interest is required
                </span>
              </div>
            </div>
          </div>
          <div class="text-center my-2">
            <button type="submit" class="btn btn-dark submit-btn px-5" [disabled]="!contactForm.valid">SUBMIT</button>
          </div>
        </form>
      </div>
      <div class="contact-card d-flex align-items-center justify-content-center py-4" *ngIf="isFormSuccess">
        <div class="text-center mb-5">
          <i class="fa fa-check fa-xs fa-3x success-icon my-5"></i>
          <h4>Thank you!</h4>
          <br>
          <p>Your message has reached us! <br>
            Our team is excited to talk with you. <br>
            You’ll hear from us soon.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
