import { Component, OnInit } from '@angular/core';

import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  video_url = "";

  constructor(
    private dataservice: DataService,
  ) { }

  ngOnInit(): void {
    this.getVideoUrl()
  }

  getVideoUrl() {
    this.dataservice.getVideoUrl().then((value: string) => {
      this.video_url = value["video_url"];
    })
  }

}
