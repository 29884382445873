import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-embed',
  templateUrl: './embed.component.html',
  styleUrls: ['./embed.component.css']
})
export class EmbedComponent implements OnInit {

  widget = "";

  constructor(
        private router: Router,
  ) { }

  ngOnInit(): void {
    let current_route = this.router.url
    this.widget = current_route;
  }
}
