import { Component, OnInit } from '@angular/core';

import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.css']
})
export class HealthComponent implements OnInit {

  health = [];

  constructor(
    private dataservice: DataService,
  ) { }

  ngOnInit(): void {
    this.checkHealth()
  }

  checkHealth() {
    this.dataservice.checkHealth().then((value: any[] )=> {
      this.health = value;
    })
  }

}
