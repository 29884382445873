import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";

import { DataService } from 'src/app/services/data.service';
import { AuthService } from 'src/app/auth/auth.service';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  isSettings: boolean = true;
  isProjects: boolean = false;

  currentUser = {};
  selected_user = "";
  selected_project = "";
  selected_feature: "";
  users = [];
  projects = [];
  features = [];

  settingsForm: FormGroup;
  user = new FormControl('', Validators.compose([Validators.required]));
  project = new FormControl('', Validators.compose([Validators.required]));
  catchment_area = new FormControl('', Validators.compose([Validators.required]));
  smart_leasing = new FormControl('', Validators.compose([Validators.required]));
  engagement_curator = new FormControl('', Validators.compose([Validators.required]));
  engagement_activity = new FormControl('', Validators.compose([Validators.required]));
  audience = new FormControl('', Validators.compose([Validators.required]));
  architecture_building = new FormControl('', Validators.compose([Validators.required]));
  brand_radar = new FormControl('', Validators.compose([Validators.required]));
  brand_radar_demo = new FormControl('', Validators.compose([Validators.required]));

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private dataservice: DataService,
    private authService:AuthService
  ) { }

  ngOnInit(): void {
    this.checkAdmin();

    let current_route = this.router.url
    if (current_route == "/settings") {
      this.isSettings = true;
      this.isProjects = false;
      this.settingsForm = this.fb.group({
        user: this.user,
        project: this.project,
        catchment_area: this.catchment_area,
        smart_leasing: this.smart_leasing,
        engagement_curator: this.engagement_curator,
        engagement_activity: this.engagement_activity,
        audience: this.audience,
        architecture_building: this.architecture_building,
        brand_radar: this.brand_radar,
        brand_radar_demo: this.brand_radar_demo
      })
      this.getUsers()
    }
    else if (current_route.split("/")[2] == "projects") {
      this.isSettings = false;
      this.isProjects = true;
    }
  }

  checkAdmin() {
    if (this.authService.getToken()) {
      if (this.authService.getToken()) {
        this.authService.getCurrentUser().then(value => {
          this.currentUser = value
          console.log(this.currentUser)
          if (this.currentUser["is_superuser"] == true) {
            this.authService.getUserProfile(this.currentUser["id"]).then(value => {
              this.currentUser["profile"]  = value;
            })
          } else {
            this.logout()
          }
        })
      }
    }
  }

  logout(){
    this.authService.logout();
    this.authService.removeToken();
    window.location.href = '/';
  }

  updateSelectedFeature(section) {
    this.selected_feature = section;
  }

  getUsers() {
    this.dataservice.getUsers().then((value: any[]) => {
      this.users = value;
    });
  }

  getUserProjects() {
    this.projects = [];
    this.features = [];
    let user = this.settingsForm.value["user"]
    this.selected_user = user;
    this.dataservice.getUserProjects(this.selected_user).then((value: any[]) => {
      this.projects = value;
    });
  }

  getProjectFeatures() {
    let project = this.settingsForm.value["project"]
    this.selected_project = project;
    let feature_list = [];
    this.dataservice.getProjectSections(this.selected_project).then((value: any[]) => {
      for (let feature of value) {
        feature['slug'] = feature['slug'].replaceAll('-', '_')
        feature_list.push(feature)
      }
      this.features = feature_list;

    });
  }

  returnFeatureId(feature) {
    for (let f of this.features) {
      if (f["slug"] == feature) {
        return f["id"]
      }
    }
  }

  saveSettings(updated_data) {
    let user_id = updated_data["user"]
    let data = {
      "project_id": updated_data["project"],
      "features": []
    };
    for (let feature of Object.keys(updated_data)) {
      if (updated_data[feature] === true) {
        data["features"].push(this.returnFeatureId(feature))
      }
    }
    this.dataservice.updateSettings(user_id, data).then(value => {
      this.settingsForm.reset()
    });
  }

}
