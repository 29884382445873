import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private api = environment.apiURL

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };

  constructor(private http: HttpClient) { }

  submitContact(data) {
    let promise = new Promise((resolve, reject) => {
      let postData = data

      const req = this.http.post(this.api + "contact/send/", postData, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

}
