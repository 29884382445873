<!DOCTYPE html>
<html lang="en">

<head>
  <meta http-equiv="content-type" content="text/html; charset=UTF-8">
</head>

<body>
  <div class="sv-main">
    <div>
      <header class="sv-header" role="banner" data-sv-search="true"><noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5RL7HB" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>

        <script>
          function GetPageName() {
            function _c(d) {
              if ('currentScript' in d) return d.currentScript;
              var s = d.getElementsByTagName('script');
              return s[s.length - 1];
            }
            var d = document,
              t = d.title.split('|')[1],
              e = d.createTextNode(t),
              c = _c(d);
            c.parentNode.insertBefore(e, c);
          }
        </script>

        <div class="sv-header__wrap">
          <div class="sv-header__inner">
            <a href="https://www.savills.co.uk/" class="sv-header__home" title="Go to homepage">
              <picture><img class="sv-header__logo sv-lazy-load" src="https://www.savills.co.uk/_images/savills-square.svg" alt="Savills logo"><noscript><img
                    class="sv-header__logo" src="https://www.savills.co.uk/_images/savills-square.svg" alt="Savills logo"></noscript></picture>
            </a>
            <ul class="sv-header__tool-set">
              <!-- country selector, search tool, and login trigger will go here -->
              <li id="sv-country-selector" class="sv-header__tool-item sv--locale">
                <div class="sv-header__tool-action sv-js-modules" data-sv-modules="navPanel">
                  <div class="sv-nav-panel">
                    <div class="sv-nav-panel__header">
                      <div class="sv-nav-panel__title">Location and Language Selection</div>
                      <button type="button" class="sv-nav-panel__close">Close</button>
                    </div>
                    <div class="sv-nav-panel__content">
                      <div class="sv-language-selector">
                        <div class="sv-language-selector__country" tabindex="-1">
                          <div class="sv-language-selector__country-flag sv-flag sv--gb">
                            <div class="sv-language-selector__country-name">United Kingdom</div>
                          </div>
                          <div class="sv-language-selector__country-dropdown">
                            <h2 class="sv-language-selector__dropdown-label">Location</h2>
                            <div class="sv-flag sv--gb sv--form-flag">
                              <div class="sv-custom-input sv--dropdown sv-flex sv--flex-row sv--language-dropdown sv--calc-layout sv--has-icons sv--auto-width sv-js-modules sv--custom" data-sv-modules="dropdown,languageSelector" tabindex="0">
                                <div class="sv-select__container" data-sort-label="Sort by:">
                                  <select name="country" id="country_select_id" class="sv-dropdown__select">
                                    <option value="https://abudhabi.savills.ae">Abu Dhabi</option>
                                    <option value="https://www.savills.ag">Antigua</option>
                                    <option value="https://www.savills.com.au">Australia</option>
                                    <option value="https://www.savills.com/countries/austria.aspx">Austria</option>
                                    <option value="https://www.savills.com/countries/bahamas.aspx">The Bahamas</option>
                                    <option value="https://www.savills.bh">Bahrain</option>
                                    <option value="https://www.savills.com/countries/barbados.aspx">Barbados</option>
                                    <option value="https://www.savills.be">Belgium</option>
                                    <option value="https://www.savills.com/countries/botswana.aspx">Botswana</option>
                                    <option value="https://www.savills.com/countries/bulgaria.aspx">Bulgaria</option>
                                    <option value="https://savills.ca">Canada</option>
                                    <option value="https://en.savills.com.cn">China</option>
                                    <option value="https://www.savills.com/countries/croatia.aspx">Croatia</option>
                                    <option value="https://www.savills.com/countries/cyprus.aspx">Cyprus</option>
                                    <option value="https://www.savills.cz">Czech Republic</option>
                                    <option value="https://www.savills.com/countries/denmark.aspx">Denmark</option>
                                    <option value="https://dubai.savills.ae">Dubai</option>
                                    <option value="https://www.savills.com.eg">Egypt</option>
                                    <option value="https://www.savills.com/countries/finland.aspx">Finland</option>
                                    <option value="https://www.savills.fr">France</option>
                                    <option value="https://www.savills.de">Germany</option>
                                    <option value="https://www.savills.gi">Gibraltar</option>
                                    <option value="https://www.savills.gr">Greece</option>
                                    <option value="https://www.savills.gg">Guernsey</option>
                                    <option value="https://www.savills.com.hk">Hong Kong SAR</option>
                                    <option value="https://www.savills.com/countries/hungary.aspx">Hungary</option>
                                    <option value="https://www.savills.in">India</option>
                                    <option value="https://www.savills.co.id">Indonesia</option>
                                    <option value="https://www.savills.ie">Ireland</option>
                                    <option value="https://www.savills.com/countries/israel.aspx">Israel</option>
                                    <option value="https://www.savills.it">Italy</option>
                                    <option value="https://www.savills.co.jp">Japan</option>
                                    <option value="https://www.savills.je">Jersey</option>
                                    <option value="https://www.savills.com/countries/kenya.aspx">Kenya</option>
                                    <option value="https://www.savills.co.kr">Korea</option>
                                    <option value="https://www.savills.lu">Luxembourg</option>
                                    <option value="https://www.savills.com.mo">Macao SAR</option>
                                    <option value="https://www.savills.com.my">Malaysia</option>
                                    <option value="https://www.savills.com/countries/mauritius.aspx">Mauritius</option>
                                    <option value="https://www.savills.com.mx">Mexico</option>
                                    <option value="https://www.savills.mc">Monaco</option>
                                    <option value="https://www.savills.com/countries/montenegro.aspx">Montenegro</option>
                                    <option value="https://www.savills.com/countries/mozambique.aspx">Mozambique</option>
                                    <option value="https://www.savills.com/countries/namibia.aspx">Namibia</option>
                                    <option value="https://www.savills.nl">The Netherlands</option>
                                    <option value="https://www.savills.co.nz">New Zealand</option>
                                    <option value="https://www.savills.com/countries/norway.aspx">Norway</option>
                                    <option value="https://www.savills.om">Oman</option>
                                    <option value="https://www.savills.pk">Pakistan</option>
                                    <option value="https://www.savills.com/countries/philippines.aspx">The Philippines</option>
                                    <option value="https://www.savills.pl">Poland</option>
                                    <option value="https://www.savills.pt/">Portugal</option>
                                    <option value="https://www.savills.com/countries/romania.aspx">Romania</option>
                                    <option value="https://www.savills.sa">Saudi Arabia</option>
                                    <option value="https://www.savills.com/countries/serbia.aspx">Serbia</option>
                                    <option value="https://www.savills.com/countries/seychelles.aspx">The Seychelles</option>
                                    <option value="https://sharjah.savills.ae">Sharjah</option>
                                    <option value="https://www.savills.com.sg">Singapore</option>
                                    <option value="https://www.savills.com/countries/south-africa.aspx">South Africa</option>
                                    <option value="https://www.savills.es">Spain</option>
                                    <option value="https://www.savills.com/countries/st-kitts-and-nevis.aspx">St Kitts &amp; Nevis</option>
                                    <option value="https://www.savills.se">Sweden</option>
                                    <option value="https://www.savills.com/countries/switzerland.aspx">Switzerland</option>
                                    <option value="https://www.savills.com.tw">Taiwan, China</option>
                                    <option value="https://www.savills.co.th">Thailand</option>
                                    <option value="https://www.savills.ae">UAE</option>
                                    <option value="https://www.savills.co.uk" selected="selected">United Kingdom</option>
                                    <option value="https://www.savills.us">United States</option>
                                    <option value="https://www.savills.com.vn">Vietnam</option>
                                    <option value="https://www.savills.com/countries/zambia.aspx">Zambia</option>
                                    <option value="https://www.savills.com/countries/zimbabwe.aspx">Zimbabwe</option>
                                  </select>
                                  <select class="sv-dropdown__calc">
                                    <option class="sv-dropdown__calc__option" selected="selected"></option>
                                  </select>
                                </div>
                                <div class="sv-select__container">
                                  <span class="sv-select__value">United Kingdom</span>
                                  <ul id="" class="sv-select__options">
                                    <li class="sv-select__option sv-flag sv--list-flag sv--ae" data-sv-value="https://abudhabi.savills.ae">Abu Dhabi</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--ag" data-sv-value="https://www.savills.ag">Antigua</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--au" data-sv-value="https://www.savills.com.au">Australia</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--at" data-sv-value="https://www.savills.com/countries/austria.aspx">Austria</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--bs" data-sv-value="https://www.savills.com/countries/bahamas.aspx">The Bahamas</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--bh" data-sv-value="https://www.savills.bh">Bahrain</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--bb" data-sv-value="https://www.savills.com/countries/barbados.aspx">Barbados</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--be" data-sv-value="https://www.savills.be">Belgium</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--bw" data-sv-value="https://www.savills.com/countries/botswana.aspx">Botswana</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--bg" data-sv-value="https://www.savills.com/countries/bulgaria.aspx">Bulgaria</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--ca" data-sv-value="https://savills.ca">Canada</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--cn" data-sv-value="https://en.savills.com.cn">China</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--hr" data-sv-value="https://www.savills.com/countries/croatia.aspx">Croatia</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--cy" data-sv-value="https://www.savills.com/countries/cyprus.aspx">Cyprus</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--cz" data-sv-value="https://www.savills.cz">Czech Republic</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--dk" data-sv-value="https://www.savills.com/countries/denmark.aspx">Denmark</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--ae" data-sv-value="https://dubai.savills.ae">Dubai</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--eg" data-sv-value="https://www.savills.com.eg">Egypt</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--fi" data-sv-value="https://www.savills.com/countries/finland.aspx">Finland</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--fr" data-sv-value="https://www.savills.fr">France</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--de" data-sv-value="https://www.savills.de">Germany</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--gi" data-sv-value="https://www.savills.gi">Gibraltar</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--gr" data-sv-value="https://www.savills.gr">Greece</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--gg" data-sv-value="https://www.savills.gg">Guernsey</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--hk" data-sv-value="https://www.savills.com.hk">Hong Kong SAR</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--hu" data-sv-value="https://www.savills.com/countries/hungary.aspx">Hungary</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--in" data-sv-value="https://www.savills.in">India</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--id" data-sv-value="https://www.savills.co.id">Indonesia</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--ie" data-sv-value="https://www.savills.ie">Ireland</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--il" data-sv-value="https://www.savills.com/countries/israel.aspx">Israel</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--it" data-sv-value="https://www.savills.it">Italy</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--jp" data-sv-value="https://www.savills.co.jp">Japan</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--je" data-sv-value="https://www.savills.je">Jersey</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--ke" data-sv-value="https://www.savills.com/countries/kenya.aspx">Kenya</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--kr" data-sv-value="https://www.savills.co.kr">Korea</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--lu" data-sv-value="https://www.savills.lu">Luxembourg</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--mo" data-sv-value="https://www.savills.com.mo">Macao SAR</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--my" data-sv-value="https://www.savills.com.my">Malaysia</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--mu" data-sv-value="https://www.savills.com/countries/mauritius.aspx">Mauritius</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--mx" data-sv-value="https://www.savills.com.mx">Mexico</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--mc" data-sv-value="https://www.savills.mc">Monaco</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--me" data-sv-value="https://www.savills.com/countries/montenegro.aspx">Montenegro</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--mz" data-sv-value="https://www.savills.com/countries/mozambique.aspx">Mozambique</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--na" data-sv-value="https://www.savills.com/countries/namibia.aspx">Namibia</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--nl" data-sv-value="https://www.savills.nl">The Netherlands</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--nz" data-sv-value="https://www.savills.co.nz">New Zealand</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--no" data-sv-value="https://www.savills.com/countries/norway.aspx">Norway</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--om" data-sv-value="https://www.savills.om">Oman</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--pk" data-sv-value="https://www.savills.pk">Pakistan</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--ph" data-sv-value="https://www.savills.com/countries/philippines.aspx">The Philippines</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--pl" data-sv-value="https://www.savills.pl">Poland</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--pt" data-sv-value="https://www.savills.pt/">Portugal</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--ro" data-sv-value="https://www.savills.com/countries/romania.aspx">Romania</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--sa" data-sv-value="https://www.savills.sa">Saudi Arabia</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--rs" data-sv-value="https://www.savills.com/countries/serbia.aspx">Serbia</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--sc" data-sv-value="https://www.savills.com/countries/seychelles.aspx">The Seychelles</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--ae" data-sv-value="https://sharjah.savills.ae">Sharjah</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--sg" data-sv-value="https://www.savills.com.sg">Singapore</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--za" data-sv-value="https://www.savills.com/countries/south-africa.aspx">South Africa</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--es" data-sv-value="https://www.savills.es">Spain</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--kn" data-sv-value="https://www.savills.com/countries/st-kitts-and-nevis.aspx">St Kitts &amp; Nevis</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--se" data-sv-value="https://www.savills.se">Sweden</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--ch" data-sv-value="https://www.savills.com/countries/switzerland.aspx">Switzerland</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--tw" data-sv-value="https://www.savills.com.tw">Taiwan, China</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--th" data-sv-value="https://www.savills.co.th">Thailand</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--ae" data-sv-value="https://www.savills.ae">UAE</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--gb sv--active" data-sv-value="https://www.savills.co.uk">United Kingdom</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--us" data-sv-value="https://www.savills.us">United States</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--vn" data-sv-value="https://www.savills.com.vn">Vietnam</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--zm" data-sv-value="https://www.savills.com/countries/zambia.aspx">Zambia</li>
                                    <li class="sv-select__option sv-flag sv--list-flag sv--zw" data-sv-value="https://www.savills.com/countries/zimbabwe.aspx">Zimbabwe</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="sv-language-selector__language hidden" tabindex="-1">
                          <div class="sv-language-selector__language-dropdown">
                            <h2 class="sv-language-selector__dropdown-label">Language</h2>
                            <div class="sv-custom-input sv--dropdown sv-flex sv--flex-row sv--language-dropdown sv--calc-layout sv--auto-width sv-js-modules sv--custom" data-sv-modules="dropdown,languageSelector" tabindex="0">
                              <div class="sv-select__container" data-sort-label="Sort by:">
                                <select name="language" id="language_select_id" class="sv-dropdown__select">
                                  <option value="en" selected="selected">English</option>
                                  <option value="fr">Français</option>
                                  <option value="zh-cn">簡体中文</option>
                                  <option value="zh-tw">繁體中文</option>
                                  <option value="de">Deutsch</option>
                                  <option value="es">Español</option>
                                  <option value="cs">Čeština</option>
                                  <option value="ru">Русский</option>
                                  <option value="it">Italiano</option>
                                  <option value="th">ภาษาไทย</option>
                                  <option value="tr">Türk</option>
                                  <option value="ms">Melayu</option>
                                  <option value="pt">Português</option>
                                  <option value="pt-br">Português (Brazil)</option>
                                  <option value="nl">Nederlands</option>
                                  <option value="vi">Tiếng Việt</option>
                                  <option value="ko">한국의</option>
                                  <option value="id">Indonesia</option>
                                  <option value="ja">日文</option>
                                  <option value="pl">Polski</option>
                                  <option value="sv">Svensk</option>
                                  <option value="da">Dansk</option>
                                  <option value="no">Norsk</option>
                                  <option value="ar">عربى</option>
                                </select>
                                <select class="sv-dropdown__calc">
                                  <option class="sv-dropdown__calc__option" selected="selected"></option>
                                </select>
                              </div>
                              <div class="sv-select__container">
                                <span id="selected_language_id" class="sv-select__value">English</span>
                                <ul id="language_ul_id" class="sv-select__options">
                                  <li class="sv-select__option" data-sv-value="en">English</li>
                                  <li class="sv-select__option" data-sv-value="fr">Français</li>
                                  <li class="sv-select__option" data-sv-value="zh-cn">簡体中文</li>
                                  <li class="sv-select__option" data-sv-value="zh-tw">繁體中文</li>
                                  <li class="sv-select__option" data-sv-value="de">Deutsch</li>
                                  <li class="sv-select__option" data-sv-value="es">Español</li>
                                  <li class="sv-select__option" data-sv-value="cs">Čeština</li>
                                  <li class="sv-select__option" data-sv-value="ru">Русский</li>
                                  <li class="sv-select__option" data-sv-value="it">Italiano</li>
                                  <li class="sv-select__option" data-sv-value="th">ภาษาไทย</li>
                                  <li class="sv-select__option" data-sv-value="tr">Türk</li>
                                  <li class="sv-select__option" data-sv-value="ms">Melayu</li>
                                  <li class="sv-select__option" data-sv-value="pt">Português</li>
                                  <li class="sv-select__option" data-sv-value="pt-br">Português (Brazil)</li>
                                  <li class="sv-select__option" data-sv-value="nl">Nederlands</li>
                                  <li class="sv-select__option" data-sv-value="vi">Tiếng Việt</li>
                                  <li class="sv-select__option" data-sv-value="ko">한국의</li>
                                  <li class="sv-select__option" data-sv-value="id">Indonesia</li>
                                  <li class="sv-select__option" data-sv-value="ja">日文</li>
                                  <li class="sv-select__option" data-sv-value="pl">Polski</li>
                                  <li class="sv-select__option" data-sv-value="sv">Svensk</li>
                                  <li class="sv-select__option" data-sv-value="da">Dansk</li>
                                  <li class="sv-select__option" data-sv-value="no">Norsk</li>
                                  <li class="sv-select__option" data-sv-value="ar">عربى</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="sv-header__tool-item sv--appraisal sv--hide-mobile">
                <a href="https://www.savills.co.uk/forms/market-appraisal.aspx?id=175056" class="sv-header__tool-action">
                  <span>Book a valuation</span>
                </a>
              </li>
              <li id="sv-site-search" class="sv-header__tool-item sv--search sv-js-modules" data-sv-modules="navPanel, searchBox">
                <button class="sv-header__tool-action">
                  <span>Search</span>
                </button>
                <div class="sv-nav-panel">
                  <div class="sv-nav-panel__header">
                    <div class="sv-nav-panel__title"></div>
                    <button type="button" class="sv-nav-panel__close">Close</button>
                  </div>
                  <div class="sv-nav-panel__content">
                    <div class="sv-search-panel">
                      <div class="sv-search-panel__content">
                        <h2 class="sv-language-selector__dropdown-label">Site Search:</h2>
                        <form action="https://www.savills.co.uk/searchresults/site-search-results.aspx">
                          <div class="sv-custom-input sv-js-modules sv--search " data-sv-modules="textInput">
                            <input id="search_924" name="searchKey" placeholder="Type to search" type="search">
                          </div>
                          <button type="submit" name="button_1501839485753" class="sv-button sv--full">
                            Search
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sv-search-box">
                  <div class="sv-search-box__content">
                    <form action="https://www.savills.co.uk/searchresults/site-search-results.aspx">
                      <div class="sv-custom-input sv-js-modules sv--search sv--alt" data-sv-modules="textInput">
                        <input id="search_1331" name="searchKey" placeholder="Type to search" type="search">
                        <input type="hidden" id="page" name="page" value="1">
                        <input type="hidden" id="filter" name="filter" value="">
                        <button type="submit"></button>
                      </div>
                    </form>
                  </div>
                </div>
              </li>
              <li id="sv-my-account" class="sv-header__tool-item sv--login">
                <a href="https://search.savills.com/account" class="sv-header__tool-action">
                  <span>My Account</span>
                </a>
              </li>
              <li class="sv-header__tool-item sv--menu sv-view-lt-lrg">
                <button class="sv-header__tool-action sv-js-nav-toggle" aria-controls="sv-nav" aria-pressed="false">
                  <span>Menu</span>
                </button>
              </li>
            </ul>
            <nav class="sv-nav sv-nav--outer sv-js-modules" id="sv-nav" data-sv-modules="toggle, navigation" data-sv-toggle-trigger=".sv-js-nav-toggle" data-sv-toggle-breakrangex="[0,3]" data-sv-toggle-ispagetakeover="true" aria-expanded="false"
              data-sv-toggle="" data-is-open="false">
              <div class="sv-nav__inner sv-js-modules" id="sv-sub-nav" data-sv-modules="toggle" data-sv-toggle-trigger=".sv-js-sub-nav-toggle" data-sv-toggle-breakrangex="[0,3]" data-sv-toggle-scrolltotop="true" data-sv-toggle-ispagetakeover="true"
                aria-expanded="false" data-sv-toggle="">
                <div class="sv-nav__lead sv-view-lt-lrg">
                  <h2 class="sv-nav__heading">
                    <span class="sv-js-sub-nav-toggle" aria-controls="sv-sub-nav" aria-pressed="false">Menu</span>
                    <span class="sv-nav__breadcrumb sv-breadcrumb--t1"> &gt; Find a property</span>
                    <span class="sv-nav__breadcrumb sv-breadcrumb--t2"> &gt; Market your property</span>
                    <span class="sv-nav__breadcrumb sv-breadcrumb--t3"> &gt; Find a service</span>
                    <span class="sv-nav__breadcrumb sv-breadcrumb--t4"> &gt; Why Savills</span>
                    <span class="sv-nav__breadcrumb sv-breadcrumb--t5"> &gt; Insight &amp; Opinion</span>
                    <span class="sv-nav__breadcrumb sv-breadcrumb--t6"> &gt; Contact us</span>
                  </h2>
                  <button class="sv-js-nav-toggle sv-nav__close" aria-controls="sv-nav" aria-pressed="false">
                    <span>Close</span>
                  </button>
                </div>
                <ul class="sv-nav__items">
                  <li class="sv-nav__item">
                    <a href="https://www.savills.co.uk/find-a-property/" class="vx_text vx_include_super-header sv-nav__label sv-js-sub-nav-toggle sv-js-nav-item" data-sv-toggle="t1" id="txt_5010" aria-controls="sv-sub-nav">Find a property</a>



                    <!--find a property -->
                    <div class="sv-sub-nav" data-lazy-loaded="true">
                      <div class="sv-block-grid sv--med-3">
                        <div class="sv-col">


                          <article class="sv-card sv--cta-block" style="border-bottom:none !important; margin-bottom:0px !important;">
                            <div class="sv-card__content">
                              <div class="sv-card__content-inner">
                                <header data-sv-search="true">
                                  <p class="vx_text sv-card-title" id="txt_01"><a href="https://www.savills.co.uk/find-a-property/">Find a property</a></p>
                                </header>
                                <p class="vx_text sv-card-intro" id="txt_02">Whether buying a
                                  townhouse or selling a country cottage, leasing corporate office space
                                  or renting farmland, our experts make it their business to understand
                                  your needs and help you find the right property.</p>
                                <span class="vx_text sv-cta-link" id="txt_03"><a title="Find a property" href="https://www.savills.co.uk/find-a-property/" target="_self">Search for a property </a></span>
                              </div>
                            </div>
                          </article>


                          <div class="vx_text sv-sub-nav__link-tree" id="txt_04">
                            <ul>
                              <li>
                                <p id="txt_05" class="vx_text sv-card-title">Residential</p>
                              </li>
                              <li><a href="https://www.savills.co.uk/find-residential-property/">Search for a residential property</a></li>
                              <li><a href="https://search.savills.com/list/property-for-sale/england/london/london/ec3n">Buy a property in London</a></li>
                              <li><a href="https://search.savills.com/list/property-to-rent/england/london/london/ec3n">Rent a property in London</a></li>
                              <li><a href="https://search.savills.com/list/new-homes-for-sale/uk">New homes for sale</a></li>
                              <li><a href="https://search.savills.com/list/property-for-sale/europe">Properties in Europe</a></li>
                              <li><a href="https://search.savills.com/list/property-for-sale/caribbean">Properties in the Caribbean</a></li>
                              <li><a href="https://search.savills.com/list/property-for-sale/united-states-of-america">Properties in the US</a></li>
                              <li><a href="https://search.savills.com/list/property-for-sale/1245,1244,1240,46058,39450,46583,1235,1233">Properties in Asia Pacific</a></li>
                              <li><a href="https://search.savills.com/list/property-for-sale/54237,47320">Properties in The Alps</a></li>
                              <li><br>
                                <p id="txt_06" class="vx_text sv-card-title">Farms &amp; Estates</p>
                              </li>
                              <li><a href="https://www.savills.co.uk/find-rural-property/">Search for rural property</a></li>
                              <li><a href="https://search.savills.com/list/farms/farms-for-sale/uk">Farms for sale</a></li>
                              <li><a href="https://search.savills.com/list/estates-for-sale/uk">Estates for sale</a></li>
                              <li><a href="https://search.savills.com/list/farms/equestrian-property-for-sale/uk">Equestrian property for sale</a></li>
                            </ul>
                          </div>
                          <div class="vx_text sv-sub-nav__link-tree" id="txt_07">
                            <ul>
                              <li>
                                <p id="txt_08" class="vx_text sv-card-title">Commercial<strong></strong></p>
                              </li>
                              <li><a href="https://www.savills.co.uk/find-commercial-property/">Search for a commercial property</a></li>
                              <li><a href="https://search.savills.com/list/commercial/property-to-let/office/uk">Offices to lease in the UK</a></li>
                              <li><a href="https://search.savills.com/list/commercial/property-for-sale/development-land/uk">Development land for sale in the UK</a></li>
                              <li><a href="https://search.savills.com/list/commercial/property-to-let/retail/uk">Retail properties</a></li>
                              <li><a href="https://search.savills.com/list/commercial/property-to-let/industrial/uk">Industrial properties to lease</a></li>
                              <li><a href="https://search.savills.com/list/commercial/property-for-sale/leisure/uk">Leisure properties for sale</a></li>
                              <li><a href="https://search.savills.com/list/commercial/property-for-sale/hotel/uk">Hotels for sale</a></li>
                              <li><a href="https://search.savills.com/list/commercial/property-to-let/serviced-office/uk">Flexible office space</a></li>
                              <li><a href="https://search.savills.com/list/commercial/property-for-sale/investment/uk">Investment properties for sale</a></li>
                              <li><a href="https://search.savills.com/list/commercial/property-for-sale/investment/uk">Residential investment</a></li>
                              <li><a href="https://search.savills.com/list/commercial/property-for-sale/investment/uk">Student housing investment</a></li>
                            </ul>
                          </div>


                        </div>
                        <div class="sv-col">

                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">

                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title " id="txt_09"><a href="https://www.savills.co.uk/contact-us/people.aspx">Speak to an expert</a></p>
                                  </header>
                                  <p class="vx_text sv-card-intro " id="txt_10">Whatever your needs, we have someone who can help.</p>
                                  <span class="vx_text sv-cta-link" id="txt_11"><a href="https://www.savills.co.uk/contact-us/people.aspx">Find a local expert</a></span>

                                </div>
                              </div>
                            </article>
                          </div>

                          <article class="sv-card sv--cta-block ">
                            <div class="sv-card__content">
                              <div class="sv-card__content-inner">
                                <header data-sv-search="true">
                                  <p class="vx_text sv-card-title " id="txt_12"><a href="https://www.savills.co.uk/forms/market-appraisal.aspx?id=175056">What's your property worth?</a></p>
                                </header>
                                <p class="vx_text vx_include_find-a-property sv-card-intro " id="txt_2333">Get
                                  expert advice for residential, commercial or rural property, grounded
                                  in our experience and knowledge of local, regional and international
                                  markets.
                                </p>
                                <span class="vx_text sv-cta-link" id="txt_13"><a href="https://www.savills.co.uk/forms/market-appraisal.aspx?id=175056">Arrange a market appraisal</a></span>



                              </div>
                            </div>
                          </article>

                          <article class="sv-card sv--cta-block ">
                            <div class="sv-card__content">
                              <div class="sv-card__content-inner">
                                <header data-sv-search="true">
                                  <p class="vx_text sv-card-title " id="txt_14"><a href="https://auctions.savills.co.uk/" target="_blank">Property auctions</a></p>
                                </header>
                                <p class="vx_text vx_include_find-a-property sv-card-intro " id="txt_2333">As
                                  one of the UK’s leading auction houses, we offer a large selection of
                                  residential and commercial property for sale by auction.
                                </p>
                                <span class="vx_text sv-cta-link" id="txt_15"><a href="https://auctions.savills.co.uk/" target="_blank">More Information</a></span>



                              </div>
                            </div>
                          </article>


                        </div>





                        <div class="sv-col">


                          <!-- file="/_vx/sv-menu-find.aspx"-->

                          <article class="sv-sub-nav__promo">
                            <header class="sv-content-panel sv--dark sv-sub-nav__promo-header" data-sv-search="true">

                              <p class="vx_text sv-sub-nav__promo-title" id="txt_16" style="min-height: 10.75rem;">6 of the best...Former rectories</p>
                            </header>
                            <div class="sv-content-panel sv--dark sv-sub-nav__promo-content">

                              <span class="vx_text sv-tag sv--light" id="txt_17">Residential Sales</span>

                              <time class="vx_text sv-sub-nav__promo-date" datetime="2017-24-7" id="txt_18">17 June 2022</time>

                              <p id="txt_19" class="vx_text sv-sub-nav__promo-copy">Creating a living
                                space from scratch and putting your own stamp on the property can be an
                                appealing prospect. Indeed a study by the National Custom &amp; Self
                                Build Association (NaCSBA) suggests that one third of people in the UK
                                are interested in building their own home, with 9 per cent saying they
                                are likely to do so at some point. Here we’ve selected some of the best
                                self-build plots on the market with full or outline planning permission
                                already in place to bring your amazing space one step closer.</p>
                              <span id="txt_20" class="vx_text sv-button sv--icon sv--full sv--link"><a href="https://www.savills.co.uk/blog/article/321264/residential-property/6-of-the-best-self-build-projects.aspx">Read this article</a></span>
                            </div>
                          </article>

                        </div>
                      </div>
                    </div>





                    <script>
                      if (typeof Vx !== 'undefined') {
                        // We need to style the menus individually
                        var a = document.createElement('link');
                        a.rel = "stylesheet";
                        a.href = "https://admin.euro.savills.co.uk/_css/default.css";
                        a.type = 'text/css';
                        document.getElementsByTagName('head')[0].appendChild(a);
                      }
                    </script>
                  </li>
                  <li class="sv-nav__item">
                    <a href="https://www.savills.co.uk/market-your-property/" class="vx_text vx_include_super-header sv-nav__label sv-js-sub-nav-toggle sv-js-nav-item" data-sv-toggle="t2" id="txt_5001" aria-controls="sv-sub-nav">Market your
                      property</a>
                    <div class="sv-sub-nav" data-lazy-loaded="true">
                      <div class="sv-block-grid sv--med-3">
                        <div class="sv-col">




                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title " id="txt_01"><a href="https://www.savills.co.uk/forms/market-appraisal.aspx?id=175056">Book a valuation</a></p>
                                  </header>
                                  <p class="vx_text sv-card-intro " id="txt_02">Book your appointment with us today and let us help you keep moving. Our virtual options are still available if your prefer.</p>
                                  <span class="vx_text sv-cta-link" id="txt_03"><a href="https://www.savills.co.uk/forms/market-appraisal.aspx?id=175056">Book a valuation</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>

                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title" id="txt_04"><a href="https://www.savills.co.uk/contact-us/people.aspx">Speak to an agent</a></p>
                                  </header>
                                  <p class="vx_text sv-card-intro" id="txt_05">Whatever your needs, we have someone who can help.</p>
                                  <span class="vx_text sv-cta-link" id="txt_06"><a href="https://www.savills.co.uk/contact-us/people.aspx">Find a local expert</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>



                        </div>
                        <div class="sv-col">

                          <div class="sv-card sv--cta-block" style="border-bottom:none !important; margin-bottom:0px !important;">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title " id="txt_07"><a href="https://www.savills.co.uk/market-your-property/">Market your property</a></p>
                                  </header>
                                  <p class="vx_text sv-card-intro " id="txt_08">Whether you're planning to sell, rent or are undecided, we have an expert who can help.</p>
                                  <span class="vx_text sv-cta-link" id="txt_09"><a href="https://www.savills.co.uk/market-your-property/">More Information</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>


                          <div class="vx_text sv-sub-nav__link-tree" id="txt_10">
                            <ul>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/selling-a-residential-property.aspx">Selling a Residential Property</a></li>
                              <li><a href="https://www.savills.co.uk/services/letting-or-renting/letting-a-residential-property.aspx">Letting a Residential Property</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/auctioning-a-property.aspx">Auctioning a Property</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/selling-a-mews-property.aspx">Selling a Mews Property</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/selling-new-build-property.aspx">Selling New-Build Property</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/buying-and-selling-international-property.aspx">Selling International Property</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/buying-or-selling-a-farm.aspx">Selling a Farm</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/buying-or-selling-a-fishing-beat.aspx">Selling a Fishing Beat</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/buying-or-selling-a-forest-or-woodland.aspx">Selling a Forest or Woodland</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/buying-or-selling-a-quarry-or-waste-management-facility.aspx">Selling a Quarry or Waste Management Facility</a></li>
                            </ul>
                          </div>

                          <div class="vx_text sv-sub-nav__link-tree" id="txt_11">
                            <ul>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/buying-or-selling-a-shoot.aspx">Selling a Shoot</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/buying-or-selling-an-estate.aspx">Selling an Estate</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/buying-or-selling-country-property.aspx">Selling Country Property</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/buying-or-selling-equestrian-property.aspx">Selling Equestrian Property</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/buying-or-selling-waterfront-property.aspx">Selling Waterfront Property</a></li>
                              <li><a href="https://www.savills.co.uk/services/letting-or-renting/interior-furnishing-and-refurbishment-services.aspx">Interior, Furnishing and Refurbishment Services</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/private-office.aspx">Private Office</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/disposing-of-commercial-property.aspx">Selling Commercial Property</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/buying-or-selling-healthcare-property.aspx">Selling Healthcare Property</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/buying-or-selling-leisure-or-trade-related-property.aspx">Selling Leisure or Trade-Related Property</a></li>
                            </ul>
                          </div>





                        </div>








                        <div class="sv-col">


                          <!-- file="/_vx/sv-menu-find.aspx"-->

                          <article class="sv-sub-nav__promo">
                            <header class="sv-content-panel sv--dark sv-sub-nav__promo-header" data-sv-search="true">

                              <p class="vx_text sv-sub-nav__promo-title" id="txt_12">Is it time for a universal green rating for new homes?</p>
                            </header>
                            <div class="sv-content-panel sv--dark sv-sub-nav__promo-content">

                              <span class="vx_text sv-tag sv--light" id="txt_13">Residential Development Sales</span>

                              <time class="vx_text sv-sub-nav__promo-date" datetime="2017-24-7" id="txt_14">15 June 2022</time>

                              <p id="txt_15" class="vx_text sv-sub-nav__promo-copy">For the
                                residential development industry, the E in ESG has focused historically
                                on the energy efficiency of new buildings as measured through EPCs and
                                BREEAM. This is now evolving to include a more in-depth review of the
                                efficiency and climate resilience of its offering. But how easy is it
                                for developers to navigate their way to delivering the right product,
                                and for buyers to make a considered choice?</p>
                              <span id="txt_16" class="vx_text sv-button sv--icon sv--full sv--link"><a href="https://www.savills.co.uk/blog/article/329611/residential-property/is-it-time-for-a-universal-green-rating-for-new-homes-.aspx">Read this
                                  article</a></span>
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>


                    <script>
                      if (typeof Vx !== 'undefined') {
                        // We need to style the menus individually
                        var a = document.createElement('link');
                        a.rel = "stylesheet";
                        a.href = "https://admin.euro.savills.co.uk/_css/default.css";
                        a.type = 'text/css';
                        document.getElementsByTagName('head')[0].appendChild(a);
                      }
                    </script>
                  </li>
                  <li class="sv-nav__item">
                    <a href="https://www.savills.co.uk/find-a-service/" class="vx_text vx_include_super-header sv-nav__label sv-js-sub-nav-toggle sv-js-nav-item" data-sv-toggle="t3" id="txt_5002" aria-controls="sv-sub-nav">Find a service</a>
                    <div class="sv-sub-nav">
                      <div class="sv-block-grid sv--med-3">


                        <div class="sv-col">


                          <div class="sv-card sv--cta-block" style="border-bottom:none !important; margin-bottom:0px !important;">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title" id="txt_01"><a href="https://www.savills.co.uk/find-a-service/">Find a service</a></p>
                                  </header>
                                  <p class="vx_text sv-card-intro" id="txt_02">Savills offers a wide
                                    range of specialist services from financial and investment advice to
                                    valuation, planning and property management.</p>
                                  <span class="vx_text sv-cta-link" id="txt_03"><a title="" href="https://www.savills.co.uk/find-a-service/">More Information</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>
                          <div class="vx_text sv-sub-nav__link-tree" id="txt_04">
                            <ul>
                              <li><a href="https://www.savills.co.uk/services/asset-management.aspx">Asset Management</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling.aspx">Buying or Selling</a></li>
                              <li><a href="https://www.savills.co.uk/services/consultancy.aspx">Consultancy</a></li>
                              <li><a href="https://www.savills.co.uk/services/development.aspx">Development</a></li>
                              <li><a href="https://www.savills.co.uk/services/finance.aspx">Finance</a></li>
                              <li><a href="https://www.savills.co.uk/services/investment.aspx">Investment</a></li>
                              <li><a href="https://www.savills.co.uk/services/letting-or-renting.aspx">Letting or Renting</a></li>
                            </ul>
                          </div>
                          <div class="vx_text sv-sub-nav__link-tree" id="txt_05">
                            <ul>
                              <li><a href="https://www.savills.co.uk/services/occupier-services.aspx">Occupier Services</a></li>
                              <li><a href="https://www.savills.co.uk/services/planning.aspx">Planning</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/private-office.aspx">Private Office</a></li>
                              <li><a href="https://www.savills.co.uk/services/property-management.aspx">Property Management</a></li>
                              <li><a href="https://www.savills.co.uk/services/research.aspx">Research</a></li>
                              <li><a href="https://www.savills.co.uk/services/valuation.aspx">Valuation</a></li>
                            </ul>
                          </div>

                        </div>


                        <div class="sv-col">
                          <article class="sv-card sv--cta-block " style="border-bottom:none !important; margin-bottom:0px !important;">
                            <div class="sv-card__content">
                              <div class="sv-card__content-inner">
                                <header data-sv-search="true">
                                  <p class="vx_text sv-card-title " id="txt_06"><a href="https://www.savills.co.uk/find-a-service/">Browse by sector</a></p>
                                </header>
                                <p class="vx_text sv-card-intro " id="txt_07">Our services cover a broad range of property sectors.</p>
                                <span class="vx_text sv-cta-link" id="txt_08"><a href="https://www.savills.co.uk/find-a-service/">More Information</a></span>
                              </div>
                              <div class="sv-card__content-footer">
                              </div>
                            </div>
                          </article>

                          <div class="vx_text sv-sub-nav__link-tree" id="txt_09">
                            <ul>
                              <li><a href="https://www.savills.co.uk/sectors/affordable-housing.aspx">Affordable Housing</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/central-london-retail.aspx">Central London Retail</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/education.aspx">Education</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/energy-and-sustainability.aspx">Energy &amp; Sustainability</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/food-and-farming.aspx">Food &amp; Farming</a></li>
                              <li><a href="https://www.savills.co.uk/services/property-management/forestry-management.aspx">Forestry</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/healthcare.aspx">Healthcare</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/high-street-retail.aspx">High St/In Town Retail</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/hotels.aspx">Hotels</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/industrial-and-logistics.aspx">Industrial &amp; Logistics</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/infrastructure-and-transport.aspx">Infrastructure</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/leisure.aspx">Leisure</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/marine.aspx">Marine</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/minerals-and-commodities.aspx">Minerals &amp; Commodities</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/mixed-use.aspx">Mixed Use</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/office-and-business-space.aspx">Office &amp; Business Space</a></li>
                              <li><a href="https://www.savills.co.uk/services/investment/operational-capital-markets.aspx">Operational Capital Markets</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/out-of-town-retail.aspx">Out of Town Retail</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/public-sector.aspx">Public Sector</a></li>
                            </ul>
                          </div>
                          <div class="vx_text sv-sub-nav__link-tree" id="txt_10">
                            <ul>
                              <li><a href="https://www.savills.co.uk/sectors/pubs-bars-and-restaurants.aspx">Pubs, Bars &amp; Restaurants</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/pubs-bars-and-restaurants.aspx"></a><a href="https://www.savills.co.uk/sectors/residential.aspx">Residential</a></li>
                              <li><a href="https://www.savills.co.uk/services/investment/residential-investment-and-development.aspx">Residential Investment &amp; Development</a></li>
                              <li><a href="https://www.savills.co.uk/services/buying-or-selling/selling-new-build-property.aspx">Residential Development Sales</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/retail.aspx">Retail</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/rural.aspx">Rural</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/science-and-technology.aspx">Science &amp; Technology</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/senior-living.aspx">Senior Living</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/serviced-offices.aspx">Serviced Offices</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/shopping-centres.aspx">Shopping Centres</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/trade-related-and-specialist-sectors.aspx">Trade-Related</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/sports-venues.aspx">Sports Venues</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/student-accommodation.aspx">Student Accommodation</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/telecoms.aspx">Telecoms</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/utilities.aspx">Utilities</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/visitor-attractions.aspx">Visitor Attractions</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/vineyards.aspx">Vineyards</a></li>
                              <li><a href="https://www.savills.co.uk/sectors/waste-management.aspx">Waste Management</a></li>
                            </ul>
                          </div>


                        </div>
                        <div class="sv-col">
                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title " id="txt_11"><a href="https://www.savills.co.uk/forms/market-appraisal.aspx?id=175056">Book a valuation</a></p>
                                  </header>
                                  <p class="vx_text sv-card-intro " id="txt_12">Book your appointment with us today and let us help you keep moving. Our virtual options are still available if your prefer.</p>
                                  <span class="vx_text sv-cta-link" id="txt_13"><a href="https://www.savills.co.uk/forms/market-appraisal.aspx?id=175056">Book a valuation</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>

                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title " id="txt_14"><a href="https://www.savills.co.uk/contact-us/people.aspx">Speak to an expert</a></p>
                                  </header>
                                  <p class="vx_text sv-card-intro " id="txt_15">Whatever your needs, we have someone who can help.</p>
                                  <span class="vx_text sv-cta-link" id="txt_16"><a href="https://www.savills.co.uk/contact-us/people.aspx">Find a local expert</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>

                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title" id="txt_17"><a href="https://www.savills.co.uk/contact-us/office.aspx">Find your nearest office</a></p>
                                  </header>
                                  <p class="vx_text sv-card-intro" id="txt_18">With over 39,000 people working across 70 countries around the world, we'll always have an expert who is local to you.</p>
                                  <span class="vx_text sv-cta-link" id="txt_19"><a href="https://www.savills.co.uk/contact-us/office.aspx">Find your local office</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>


                        </div>
                      </div>
                    </div>

                    <script>
                      if (typeof Vx !== 'undefined') {
                        // We need to style the menus individually
                        var a = document.createElement('link');
                        a.rel = "stylesheet";
                        a.href = "https://admin.euro.savills.co.uk/_css/default.css";
                        a.type = 'text/css';
                        document.getElementsByTagName('head')[0].appendChild(a);
                      }
                    </script>
                  </li>
                  <li class="sv-nav__item">
                    <a href="https://www.savills.co.uk/why-savills/" class="vx_text vx_include_super-header sv-nav__label sv-js-sub-nav-toggle sv-js-nav-item" data-sv-toggle="t4" id="txt_5003" aria-controls="sv-sub-nav">Why Savills</a>

                    <div class="sv-sub-nav" data-lazy-loaded="true">
                      <div class="sv-block-grid sv--med-3">

                        <div class="sv-col">


                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title " id="txt_01"><a href="https://www.savills.co.uk/why-savills/">Why Savills</a></p>
                                  </header>
                                  <p class="sv-card-intro ">
                                    Founded in the UK in 1855, Savills is one of the world's leading
                                    property agents. Our experience and expertise spans the globe, with 600
                                    offices across the Americas, Europe, Asia Pacific, Africa and the Middle
                                    East.

                                  </p>
                                  <span class="vx_text sv-cta-link" id="txt_02"><a title="Why Savills" href="https://www.savills.co.uk/why-savills/">More Information</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>







                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title " id="txt_03"><a href="https://www.savills.co.uk/why-savills/how-we-work.aspx">How we work </a></p>
                                  </header>
                                  <p class="sv-card-intro ">
                                    Our integrity, honesty and professionalism is what gives our clients,
                                    colleagues, investors and business partners the confidence to work with
                                    us.

                                  </p>
                                  <span class="vx_text sv-cta-link" id="txt_04"><a title="Why Savills" href="https://www.savills.co.uk/why-savills/how-we-work.aspx">More Information</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>

                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title " id="txt_05"><a href="https://www.savills.co.uk/why-savills/innovation-and-technology.aspx">Innovation and technology</a></p>
                                  </header>
                                  <p class="sv-card-intro ">
                                    Savills is at the forefront of exploring and developing new
                                    capabilities, bringing together people and technology to push boundaries
                                    and take real estate to a new level.

                                  </p>
                                  <span class="vx_text sv-cta-link" id="txt_06"><a title="Innovation &amp; Technology" href="https://www.savills.co.uk/why-savills/innovation-and-technology.aspx">More Information</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>


                        </div>


                        <div class="sv-col">


                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title " id="txt_07"><a href="https://www.savills.co.uk/why-savills/awards.aspx">Awards </a></p>
                                  </header>
                                  <p class="sv-card-intro ">
                                    Over the years we have been recognised as ‘best in class’ in a diverse range of disciplines.
                                  </p>
                                  <span class="vx_text sv-cta-link" id="txt_08"><a title="Why Savills" href="https://www.savills.co.uk/why-savills/awards.aspx">More Information</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>

                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title " id="txt_09"><a href="https://www.savills.co.uk/why-savills/esg.aspx">Sustainability &amp; ESG</a></p>
                                  </header>
                                  <p class="sv-card-intro ">
                                    Savills UK sustainability strategy aims to achieve a positive impact on
                                    the environment and society, whilst maintaining robust governance
                                    measures.
                                  </p>
                                  <span class="vx_text sv-cta-link" id="txt_10"><a href="https://www.savills.co.uk/why-savills/esg.aspx">More Information</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>

                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title " id="txt_11"><a href="https://ir.savills.com/">Investor relations </a></p>
                                  </header>
                                  <p class="sv-card-intro ">
                                    Savills Plc is listed on the London Stock Exchange within the FTSE250
                                    index. Our annual reports and investor updates can be found online.
                                  </p>
                                  <span class="vx_text sv-cta-link" id="txt_12"><a title="Why Savills" href="https://ir.savills.com/">Visit our Investor Relations site</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>

                        </div>

                        <div class="sv-col">
                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title " id="txt_13"><a href="https://www.savills.co.uk/contact-us/careers/diversity-and-inclusion.aspx">Diversity &amp; Inclusion</a></p>
                                  </header>
                                  <p class="sv-card-intro ">
                                    Savills is committed to eliminating discrimination and encouraging diversity amongst our workforce.
                                  </p>
                                  <span class="vx_text sv-cta-link" id="txt_14"><a href="https://www.savills.co.uk/contact-us/careers/diversity-and-inclusion.aspx">More information</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>

                        </div>


                      </div>
                    </div>




                    <script>
                      if (typeof Vx !== 'undefined') {
                        // We need to style the menus individually
                        var a = document.createElement('link');
                        a.rel = "stylesheet";
                        a.href = "https://admin.euro.savills.co.uk/_css/default.css";
                        a.type = 'text/css';
                        document.getElementsByTagName('head')[0].appendChild(a);
                      }
                    </script>
                  </li>
                  <li class="sv-nav__item">
                    <a href="https://www.savills.co.uk/insight-and-opinion/" class="vx_text vx_include_super-header sv-nav__label sv-js-sub-nav-toggle sv-js-nav-item" data-sv-toggle="t5" id="txt_5004" aria-controls="sv-sub-nav">Insight &amp;
                      Opinion</a>
                    <div class="sv-sub-nav">
                      <div class="sv-block-grid sv--med-3">

                        <div class="sv-col">


                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title" id="txt_01"><a href="https://www.savills.co.uk/insight-and-opinion/">Insight &amp; Opinion</a></p>
                                  </header>
                                  <p class="vx_text sv-card-intro" id="txt_02">Insight &amp; Opinion is
                                    our hub for residential, commercial and rural articles and research from
                                    experts offering industry-leading advice and analysis.</p>

                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>
                          <div class="sv-card sv--cta-block" style="border-bottom:none !important; margin-bottom:0px !important;">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title" id="txt_03"><a href="https://www.savills.co.uk/insight-and-opinion/research.aspx">Research</a></p>
                                  </header>
                                  <p class="vx_text sv-card-intro" id="txt_04">In-depth research and
                                    analysis into property market trends, forecasts from our specialist
                                    research teams, and market-leading commentary to help you make the right
                                    property decisions.</p>
                                  <span class="vx_text sv-cta-link" id="txt_05"><a title="Research" href="https://www.savills.co.uk/insight-and-opinion/research.aspx">Browse our market research</a> <br><a style="margin-top: 0.75rem !important;"
                                      href="https://pdf.euro.savills.co.uk/forms/research-form.aspx">Subscribe to Savills Research</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>
                          <div class="vx_text sv-sub-nav__link-tree" id="txt_06">
                            <ul>
                              <li><a href="https://www.savills.co.uk/insight-and-opinion/research.aspx?rc=United%20Kingdom&amp;p=Residential&amp;t=&amp;f=date&amp;q=&amp;page=1">Residential reports</a></li>
                              <li><a href="https://www.savills.co.uk/insight-and-opinion/research-consultancy/residential-market-forecasts.aspx">Residential forecasts</a></li>
                              <li><a href="https://www.savills.co.uk/insight-and-opinion/research.aspx?rc=United%20Kingdom&amp;p=Offices&amp;t=&amp;f=date&amp;q=&amp;page=1">Office reports</a></li>
                              <li><a href="https://www.savills.co.uk/insight-and-opinion/research.aspx?rc=United%20Kingdom&amp;p=Retail&amp;t=&amp;f=date&amp;q=&amp;page=1">Retail reports</a></li>
                              <li><a href="https://www.savills.co.uk/insight-and-opinion/research.aspx?rc=United-Kingdom&amp;p=Rural&amp;t=&amp;f=date&amp;q=&amp;page=1">Rural reports</a></li>
                              <li><a href="https://www.savills.co.uk/landing-pages/savills-view.aspx">Residential property prices and trends</a></li>
                            </ul>
                          </div>

                          <div class="vx_text sv-sub-nav__link-tree" id="txt_07">
                            <ul>
                              <li><a href="https://www.savills.co.uk/insight-and-opinion/research.aspx?rc=United-Kingdom&amp;p=Energy-%26-Sustainability&amp;t=&amp;f=date&amp;q=&amp;page=1">ESG Research</a></li>
                              <li><a href="https://www.savills.co.uk/insight-and-opinion/research.aspx?rc=United%20Kingdom&amp;p=Industrial%20and%20Logistics&amp;t=&amp;f=date&amp;q=&amp;page=1">Industrial reports</a></li>
                              <li><a href="https://www.savills.co.uk/landing-pages/savills-view---development-and-investment.aspx">Residential development &amp; investment</a></li>
                              <li><a href="https://www.savills.co.uk/insight-and-opinion/research-consultancy/residential-indices.aspx">Savills Indices</a></li>
                              <li><a href="https://www.savills.co.uk/services/research.aspx">Research services</a></li>
                              <li><a href="https://www.savills.co.uk/insight-and-opinion/tagged-articles/savills-webinars">Webinars</a></li>
                            </ul>
                          </div>


                        </div>
                        <div class="sv-col">


                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title" id="txt_08"><a href="https://www.savills.co.uk/insight-and-opinion/the-savills-blog.aspx">The Savills Blog</a></p>
                                  </header>
                                  <p class="sv-card-intro vx_text" id="txt_09">The latest UK and
                                    international property market news and opinions, plus helpful guides and
                                    top tips from our renowned industry experts.</p>
                                  <span class="vx_text sv-cta-link" id="txt_10"><a title="The Savills Blog" href="https://www.savills.co.uk/insight-and-opinion/the-savills-blog.aspx">Read the Savills Blog</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>
                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title" id="txt_11"><a href="https://www.savills.co.uk/insight-and-opinion/savills-news.aspx">Savills News</a></p>
                                  </header>
                                  <p class="vx_text sv-card-intro" id="txt_12">Up-to-the-minute news from our press teams providing the latest developments within Savills, and across the property industry.</p>
                                  <span class="vx_text sv-cta-link" id="txt_13"><a title="Savills News" href="https://www.savills.co.uk/insight-and-opinion/savills-news.aspx">Read our press releases</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>
                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title" id="txt_14"><a href="https://www.savills.co.uk/insight-and-opinion/tagged-articles/Savills-Podcasts">Savills Podcasts</a></p>
                                  </header>
                                  <p class="vx_text sv-card-intro" id="txt_15">Keep yourself informed on
                                    the ‘real’ behind the real estate headlines, our podcast brings you
                                    engaging and insightful discussion with experts across our global
                                    business on the issues that matter most in property.</p>
                                  <span class="vx_text sv-cta-link" id="txt_16"><a title="Savills News" href="https://www.savills.co.uk/insight-and-opinion/tagged-articles/Savills-Podcasts">Listen to the podcasts</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>

                        </div>
                        <div class="sv-col">
                          <!-- file="/_vx/sv-menu-insights.aspx"-->

                          <article class="sv-sub-nav__promo">
                            <header class="sv-content-panel sv--dark sv-sub-nav__promo-header" data-sv-search="true">

                              <p class="vx_text sv-sub-nav__promo-title" id="txt_17">Portfolio by Savills</p>
                            </header>
                            <div class="sv-content-panel sv--dark sv-sub-nav__promo-content">





                              <p id="txt_18" class="vx_text sv-sub-nav__promo-copy">Portfolio by
                                Savills seamlessly blends the latest trends, inspirational lifestyle
                                content, and the world’s finest properties with the expert insight and
                                analysis you would expect from Savills. Portfolio has property at its
                                heart, and we’d like you to be part of it.</p>
                              <span id="txt_19" class="vx_text sv-button sv--icon sv--full sv--link"><a href="https://portfolio.savills.com/">Explore Portfolio by Savills</a></span>
                            </div>
                          </article>

                        </div>
                      </div>
                    </div>





                    <script>
                      if (typeof Vx !== 'undefined') {
                        // We need to style the menus individually
                        var a = document.createElement('link');
                        a.rel = "stylesheet";
                        a.href = "https://admin.euro.savills.co.uk/_css/default.css";
                        a.type = 'text/css';
                        document.getElementsByTagName('head')[0].appendChild(a);
                      }
                    </script>
                  </li>
                  <li class="sv-nav__item">
                    <a href="https://www.savills.co.uk/contact-us/office.aspx" class="vx_text vx_include_super-header sv-nav__label sv-js-sub-nav-toggle sv-js-nav-item" data-sv-toggle="t6" id="txt_5005" aria-controls="sv-sub-nav">Contact us</a>



                    <div class="sv-sub-nav">
                      <div class="sv-block-grid sv--med-3">


                        <div class="sv-col">

                          <div class="sv-card sv--cta-block" style="border-bottom:none !important; margin-bottom:0px !important;">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title" id="txt_01"><a href="https://www.savills.co.uk/contact-us/office.aspx">Find an office</a></p>
                                  </header>
                                  <p class="vx_text sv-card-intro" id="txt_02">With over 39,000 people working across 70 countries around the world, we'll always have an expert who is local to you.</p>
                                  <span class="vx_text sv-cta-link" id="txt_03"><a title="Talk to us" href="https://www.savills.co.uk/contact-us/office.aspx">Find your local office</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>
                          <header data-sv-search="true">
                            <p class="vx_text sv-card-title" id="txt_04">Our offices by region</p>
                          </header>
                          <div class="vx_text sv-sub-nav__link-tree" id="txt_05">
                            <ul>
                              <li><a href="https://www.savills.co.uk/contact-us/office.aspx?page=1&amp;countrySiteId=3197&amp;sectorId=&amp;sortOrder=asc&amp;nameSearchValue=&amp;lon=&amp;lat=&amp;location=London">London</a></li>
                              <li><a href="https://www.savills.co.uk/contact-us/office.aspx?page=1&amp;countrySiteId=3197&amp;sectorId=&amp;sortOrder=asc&amp;nameSearchValue=&amp;lon=&amp;lat=&amp;location=East%20of%20England">East of England</a>
                              </li>
                              <li><a href="https://www.savills.co.uk/contact-us/office.aspx?page=1&amp;countrySiteId=3197&amp;sectorId=&amp;sortOrder=asc&amp;nameSearchValue=&amp;lon=&amp;lat=&amp;location=East%20Midlands">East Midlands</a></li>
                              <li><a href="https://www.savills.co.uk/contact-us/office.aspx?page=1&amp;countrySiteId=3197&amp;sectorId=&amp;sortOrder=asc&amp;nameSearchValue=&amp;lon=&amp;lat=&amp;location=North%20East">North East</a></li>
                              <li><a href="https://www.savills.co.uk/contact-us/office.aspx?page=1&amp;countrySiteId=3197&amp;sectorId=&amp;sortOrder=asc&amp;nameSearchValue=&amp;lon=&amp;lat=&amp;location=North%20West">North West</a></li>
                              <li><a href="https://www.savills.co.uk/contact-us/office.aspx?page=1&amp;countrySiteId=3197&amp;sectorId=&amp;sortOrder=asc&amp;nameSearchValue=&amp;lon=&amp;lat=&amp;location=South%20East">South East</a></li>
                              <li><a href="https://www.savills.co.uk/contact-us/office.aspx?page=1&amp;countrySiteId=3197&amp;sectorId=&amp;sortOrder=asc&amp;nameSearchValue=&amp;lon=&amp;lat=&amp;location=South%20West">South West</a></li>
                            </ul>
                          </div>

                          <div class="vx_text sv-sub-nav__link-tree" id="txt_06">
                            <ul>
                              <li><a href="https://www.savills.co.uk/contact-us/office.aspx?page=1&amp;countrySiteId=3197&amp;sectorId=&amp;sortOrder=asc&amp;nameSearchValue=&amp;lon=&amp;lat=&amp;location=West%20Midlands">West Midlands</a></li>
                              <li><a href="https://www.savills.co.uk/contact-us/office.aspx?page=1&amp;countrySiteId=3197&amp;sectorId=&amp;sortOrder=asc&amp;nameSearchValue=&amp;lon=&amp;lat=&amp;location=Yorkshire%20%26%20The%20Humber">Yorkshire
                                  &amp; The Humber</a></li>
                              <li><a href="https://www.savills.co.uk/contact-us/office.aspx?page=1&amp;countrySiteId=3197&amp;sectorId=&amp;sortOrder=asc&amp;nameSearchValue=&amp;lon=&amp;lat=&amp;location=Belfast">Northern Ireland</a></li>
                              <li><a href="https://www.savills.co.uk/contact-us/office.aspx?page=1&amp;countrySiteId=3197&amp;sectorId=&amp;sortOrder=asc&amp;nameSearchValue=&amp;lon=&amp;lat=&amp;location=Scotland">Scotland</a></li>
                              <li><a href="https://www.savills.co.uk/contact-us/office.aspx?page=1&amp;countrySiteId=3197&amp;sectorId=&amp;sortOrder=asc&amp;nameSearchValue=&amp;lon=&amp;lat=&amp;location=Wales">Wales</a></li>
                              <li><a href="https://www.savills.co.uk/contact-us/office.aspx?page=1&amp;countrySiteId=3197&amp;sectorId=&amp;sortOrder=asc&amp;nameSearchValue=&amp;lon=&amp;lat=&amp;location=Channel%20Islands">Channel Islands</a></li>
                            </ul>
                          </div>

                        </div>

                        <div class="sv-col">


                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title" id="txt_07"><a href="https://www.savills.co.uk/contact-us/people.aspx">Find a person</a></p>
                                  </header>
                                  <p class="vx_text sv-card-intro" id="txt_08">Whatever your needs, we have someone who can help.</p>
                                  <span class="vx_text sv-cta-link" id="txt_09"><a title="Talk to us" href="https://www.savills.co.uk/contact-us/people.aspx">Find a local expert</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>

                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title" id="txt_10"><a href="https://www.savills.co.uk/contact-us/careers.aspx">Careers with Savills</a></p>
                                  </header>
                                  <p class="vx_text sv-card-intro" id="txt_11">At Savills, our most
                                    valuable resource is our people. We are known for our positive,
                                    entrepreneurial culture, and for attracting some of the most innovative,
                                    dedicated and knowledgeable people in the business.</p>
                                  <span class="vx_text sv-cta-link" id="txt_12"><a title="Careers with Savills" href="https://www.savills.co.uk/contact-us/careers.aspx">More Information</a></span><br>
                                  <span class="vx_text sv-cta-link" id="txt_13"><a title="Careers with Savills" href="https://savills.tal.net/vx/lang-en-GB/mobile-0/appcentre-Lat/brand-2/candidate">Current Vacancies</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>

                          <div class="sv-card sv--cta-block">
                            <article>
                              <div class="sv-card__content">
                                <div class="sv-card__content-inner">
                                  <header data-sv-search="true">
                                    <p class="vx_text sv-card-title" id="txt_14"><a href="https://www.savills.co.uk/forms/general-enquiries.aspx">General enquiries</a></p>
                                  </header>
                                  <p class="vx_text sv-card-intro" id="txt_15">We're here to provide you
                                    with more information, answer any questions you may have, or connect
                                    you with the right people to help with your needs. Use our general
                                    enquiry form to get in touch today.</p>
                                  <span class="vx_text sv-cta-link" id="txt_16"><a title="General Enquiries" href="https://www.savills.co.uk/forms/general-enquiries.aspx">Speak to the team</a></span>
                                </div>
                                <div class="sv-card__content-footer">
                                </div>
                              </div>
                            </article>
                          </div>





                        </div>



                        <div class="sv-col">

                          <!-- file="/_vx/sv-menu-talk.aspx"-->
                          <article class="sv-sub-nav__promo">
                            <header class="sv-content-panel sv--dark sv-sub-nav__promo-header" data-sv-search="true">

                              <p class="vx_text sv-sub-nav__promo-title" id="txt_17">Impacts 2022: Getting real estate versed in the metaverse</p>
                            </header>
                            <div class="sv-content-panel sv--dark sv-sub-nav__promo-content">

                              <span class="vx_text sv-tag sv--light" id="txt_18">Trends</span>


                              <time class="vx_text sv-sub-nav__promo-date" datetime="2017-24-7" id="txt_19">14 June 2022</time>

                              <p id="txt_20" class="vx_text sv-sub-nav__promo-copy">While public
                                consciousness of the metaverse exploded in autumn 2021 when Facebook
                                changed its name to ‘Meta’, signalling its intent to expand into this
                                area, we’ve been tracking the rise of the metaverse (or more accurately
                                ‘metaverses’ as no single metaverse exists – it’s a concept used across
                                multiple competing virtual worlds and experiences) for some time.</p>
                              <span id="txt_21" class="vx_text sv-button sv--icon sv--full sv--link"><a href="https://www.savills.co.uk/blog/article/329660/commercial-property/impacts-2022--getting-real-estate-versed-in-the-metaverse.aspx">Read this
                                  article</a></span>
                            </div>
                          </article>

                        </div>
                      </div>
                    </div>





                    <script>
                      if (typeof Vx !== 'undefined') {
                        // We need to style the menus individually
                        var a = document.createElement('link');
                        a.rel = "stylesheet";
                        a.href = "https://admin.euro.savills.co.uk/_css/default.css";
                        a.type = 'text/css';
                        document.getElementsByTagName('head')[0].appendChild(a);
                      }
                    </script>
                  </li>
                </ul>
              </div>
            </nav>
            <!-- file="/_inc/menu-style.aspx"-->
          </div>
        </div>
      </header>
    </div>
    <div id="__next">
      <script type="application/ld+json">
        {
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": "10 Argyll Street",
          "image": "https://assets.savills.com/properties/GB37650000104692/CG_10_Argyll_Street_London_picture_2021-10-18-11-44-13_m_gal.jpg",
          "offers": {
            "@type": "Offer",
            "price": 33333.33333,
            "priceCurrency": "GBP"
          },
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "10 Argyll Street, Argyll Street, London, W1F 7TQ"
          },
          "description": "3,001 sq ft Retail for let"
        }
      </script>
      <div class="sv-property-detail">
        <div>
          <nav class="sv-breadcrumb">
            <div class="sv-breadcrumbs__scroller">
              <ul class="sv-breadcrumbs__container">
                <li class="sv-breadcrumbs__crumb sv--crumb-home"><a href="https://www.savills.co.uk/" class="sv-breadcrumbs__link"><span>Go to homepage</span></a></li>
                <li class="sv-breadcrumbs__crumb"><span class="sv-breadcrumbs__link"><span>10 Argyll Street, Argyll Street, London, W1F 7TQ</span></span></li>
              </ul>
            </div>
          </nav>
          <div class="sv-property-lead">
            <div class="sv-property-lead__main">
              <div class="sv-property-lead__cover">
                <div class="sv-gallery">
                  <div class="sv-gallery__content" aria-hidden="true">
                    <div class="sv-slider">
                      <div class="slick-slider slick-initialized">
                        <div class="slick-list">
                          <div class="slick-track" style="width: 1920px; opacity: 1; transform: translate3d(0px, 0px, 0px);">
                            <div data-index="0" class="slick-slide slick-active slick-center slick-current" tabindex="-1" aria-hidden="false" style="outline: currentcolor none medium; width: 1920px;">
                              <div>
                                <div class="sv-gallery__slide-container" tabindex="-1" style="width: 100%; display: inline-block;">
                                  <div class="sv-slider__slide sv--gallery">
                                    <div class="sv-slider__content">
                                      <div class="sv-slider__image-container"><img class="sv-image"
                                          src="https://assets.savills.com/properties/GB37650000104692/CG_10_Argyll_Street_London_picture_2021-10-18-11-44-13_l_gal.jpg"
                                          alt="10 Argyll Street, London - Picture 2021-10-18-11-44-13 - Property to rent in 10 Argyll Street, Argyll Street, London, W1F 7TQ"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div><span class="sv-gallery__bg-blur" style="background-image: url(&quot;https://assets.savills.com/properties/GB37650000104692/CG_10_Argyll_Street_London_picture_2021-10-18-11-44-13_l_gal.jpg&quot;);"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="sv-property-lead__controls">
              <div class="sv-property-lead__controls-list sv-contained"><button class="sv-property-lead__control sv--gallery sv--selected" type="button" value="gallery">Gallery</button><button type="button"
                  class="sv-property-lead__control sv--gallery-expand"><span>Expand</span></button><button class="sv-property-lead__control sv--map" type="button" value="map">Map</button><button class="sv-property-lead__control sv--streetView"
                  type="button" value="streetView">Street View</button></div>
            </div>
          </div>
          <div class="sv-tabs" data-tabs="true">
            <ul class="sv-tabs__heading" role="tablist">
              <li class="sv-tabs__heading-item sv-tabs__heading-item--active" role="tab" id="react-tabs-0" aria-selected="true" aria-disabled="false" aria-controls="react-tabs-1" tabindex="0">Property details</li>
              <li class="sv-tabs__heading-item" role="tab" id="react-tabs-2" aria-selected="false" aria-disabled="false" aria-controls="react-tabs-3">Contact an agent</li>
            </ul>
            <div class="sv-contained">
              <div class="sv-col sv--lrg-8 sv-tabs__tab sv-tabs__tab--active" role="tabpanel" id="react-tabs-1" aria-labelledby="react-tabs-0">
                <div class="sv-property-intro sv--full-width sv--search-type-rent sv--commercial-type-retail">
                  <div class="sv-property-intro__inner">
                    <header class="sv-property-intro__header">
                      <div class="sv-property-intro__price-block">
                        <div class="sv-property-price sv--show-price sv--show-size sv--show-rent-basis sv--show-rent-unit sv--dark">
                          <div class="sv-property-price__size"><span>3,001 sq ft</span><span>(278 sq m)</span></div>
                          <div class="sv-property-price__wrap"> <span class="sv-property-price__value"><span class="sv-property-price__rent-unit"></span><span>£400,000</span></span></div>
                          <div class="sv-property-price__rent-basis-fees"><span class="sv-property-price__rent-basis">per year</span></div>
                        </div>
                      </div>
                      <div class="sv-property-intro__address-block">
                        <h1 class="sv-property-intro__address-line-1">10 Argyll Street<span class="sv-property-intro__address-line-2">Argyll Street, London, W1F 7TQ</span></h1>
                        <ul class="sv-property-intro__labels"></ul>
                      </div>
                    </header>
                    <hr class="sv-divider sv--dark">
                    <div class="sv-property-intro-footer">
                      <div class="sv-property-intro-footer__group">
                        <div class="sv-property-attribute sv--commercial sv--yellow sv--large sv--dark"><span class="sv-property-attribute__value" title="Retail">Retail</span></div>
                        <div class="sv-property-attribute sv--size sv--yellow sv--large sv--dark"><span class="sv-property-attribute__value" title="3,001 sq ft (278 sq m)">3,001 sq ft (278 sq m)</span></div>
                      </div>
                      <div class="sv-property-intro-footer__group"></div>
                    </div>
                  </div>
                </div>
                <div class="sv-sticky" style="padding-top: 0px;"></div>
                <div class="sv-property-details__actions sv-page-actions"><button type="button" class="sv-favourite sv-favourite--share">Share</button><button type="button" class="sv-favourite sv-favourite--dark">Save</button></div>
                <div class="sv-property-details">
                  <div class="sv-content-row sv-property-details__section">
                    <div>
                      <h2 class="sv-content-row__heading">Key features</h2>
                      <div class="sv-key-features">
                        <ul class="sv-list sv--bullets">
                          <li><span><span>The
                                property is a short distance from luxury fashion retailers that include
                                the new Canada Goose flagship Cos and the new Lavazza Flagship</span></span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="sv-property-details__section sv--about-property">
                    <p class="sv-property-details__description">Prime Unit To Let With Full Restaurant Consent</p>
                    <div>
                      <h2 class="sv-content-row__heading">About this property</h2>
                      <div class="sv-long-description">
                        <ul class="sv-long-description__points">
                          <li class="sv-long-description__point sv-description-point">
                            <div class="sv-description-point__body">
                              <p>
                              <p><strong>Description</strong></p>
                              <p>The property is arranged over ground and basement floors</p>
                              <p><strong>Use Class</strong></p>
                              <p>E</p>
                              <p><strong>Lease Type</strong></p>
                              <p>New Lease</p>
                              <p><strong>Lease Term</strong></p>
                              <p>The unit is available on a new lease for a term to be agreed</p>
                              <p><strong>Costs</strong></p>
                              <p>Each party is responsible for their own legal and professional costs incurred in the transaction</p>
                              <p><strong>Rates</strong></p>
                              <p>Parties
                                are advised to make their own enquires to verify the rates payable and
                                whether rates relief applies by contacting the local authority
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="sv-property-details__section">
                    <h2 class="sv-content-row__heading">Local information</h2>
                    <div class="sv-local">
                      <ul class="sv-list sv--bullets">
                        <li class="sv-local__point">Argyll
                          Street is located in the heart of the West End. It is an extremely busy
                          thoroughfare which links Oxford Street to the north with Great
                          Marlborough Street to the south but also provides access to Regent
                          Street via Little Argyll Street.</li>
                        <li class="sv-local__point">There
                          is considerable footfall which has been created by the diverse leisure
                          and retail offer in the immediate surrounding area, which include,
                          Liberty of London, London Palladium and Aqua</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="">
                  <iframe class="neare-widget ms-3 me-3" src="https://neare.co.uk/mayfair-soho/widget" width="1250px" height="2343px" scroll="no" style="overflow-x: hidden; overflow-y: hidden; margin-left: -35px !important;"></iframe>
                </div>
                <div class="sv-content-row">
                  <h2 class="sv-content-row__heading">Available space</h2>
                  <div class="sv-wrapper sv-generic-table">
                    <div class="sv-generic-table__container">
                      <table class="sv-generic-table__table">
                        <thead class="sv-generic-table__thead">
                          <tr>
                            <th scope="col">Area</th>
                            <th scope="col">sq ft</th>
                            <th scope="col">sq m</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="sv-generic-table__tr">
                            <td data-label="Area" class="sv-generic-table__td"><span>Ground Floor</span></td>
                            <td data-label="sq ft" class="sv-generic-table__td"><span>1250.00</span></td>
                            <td data-label="sq m" class="sv-generic-table__td"><span>116.00</span></td>
                          </tr>
                          <tr class="sv-generic-table__tr">
                            <td data-label="Area" class="sv-generic-table__td"><span>Basement</span></td>
                            <td data-label="sq ft" class="sv-generic-table__td"><span>1751.00</span></td>
                            <td data-label="sq m" class="sv-generic-table__td"><span>162.00</span></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sv-col sv--lrg-offset-1 sv--lrg-3 sv-tabs__tab sv-tabs__tab--aside" role="tabpanel" id="react-tabs-3" aria-labelledby="react-tabs-2">
                <aside class="sv-aside" style="width: 350px;">
                  <div class="sv-aside__col">
                    <div class="sv-agent-details">
                      <h2 class="sv-agent-details__heading">Contact an agent</h2>
                      <ul class="sv-agent-details__agents">
                        <li>
                          <div class="sv-agent">
                            <div class="sv-agent__photo"><img class="sv-image" src="https://assets.savills.com/agents/TCG_106058_m_ag.jpg"></div>
                            <div class="sv-agent__content">
                              <ul class="sv-agent__details">
                                <li class="sv-agent__point sv--name">James Fairley</li>
                                <li class="sv-agent__point sv--office">Head Office – London</li>
                                <li class="sv-agent__point sv--phone"><a class="sv-view-lt-lrg" href="tel: 020 7758 3877">020 7758 3877</a><span class="sv-view-gt-lrg">020 7758 3877</span></li>
                                <li class="sv-agent__point"><button type="button" class="sv-cta-link sv-agent__button"><span>Contact now</span></button></li>
                              </ul>
                            </div>
                          </div>
                          <hr class="sv-agent-details__spacer">
                        </li>
                        <li>
                          <div class="sv-agent">
                            <div class="sv-agent__photo"><img class="sv-image" src="../../../assets/savills/user_m_ag.jpeg"></div>
                            <div class="sv-agent__content">
                              <ul class="sv-agent__details">
                                <li class="sv-agent__point sv--name">Daniel Aboud</li>
                                <li class="sv-agent__point sv--office">Head Office – London</li>
                                <li class="sv-agent__point sv--phone"><a class="sv-view-lt-lrg" href="tel: 020 7499 8644">020 7499 8644</a><span class="sv-view-gt-lrg">020 7499 8644</span></li>
                                <li class="sv-agent__point"><button type="button" class="sv-cta-link sv-agent__button"><span>Contact now</span></button></li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <ul class="sv-agent-details__actions sv--show">
                        <li class="sv-agent-details__action sv-view-gt-lrg"><button type="button" class="sv-button sv--full">Request details</button></li>
                        <li class="sv-agent-details__action"><a class="sv-button sv--link sv--full sv--tertiary" href="https://assets.savills.com/properties/GB37650000104692/CG_10_Argyll_Street_London_brochure_2022-02-04-09-40-47.pdf" target="_blank"
                            rel="noopener" download="">Download PDF brochure</a></li>
                      </ul>
                      <ul class="sv-agent-details__actions sv--show">
                        <div class="d-flex justify-content-center card my-3">
                          <form class="form-inline pb-0 mt-3" (submit)="searchBrand()" action="#">
                            <div class="search-box">
                              <div class="d-flex align-items-center" style="height: 50px !important;">
                                <i class="fa fa-search"></i>
                                <input [(ngModel)]="searchText" name="search" style="border: 0px; margin: 0px; background-color: #fff0 !important;" type="search" class="form-control rounded search-input"  placeholder="Search Your Brand" aria-label="Search"
                                  aria-describedby="search-addon" autofocus/>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="row m-4">
                          <div *ngFor="let brand of searchResults | filter: searchText;" class="col-md-12 col-lg-4">
                            <i class="fa fa-file fa-4x cursor-pointer" aria-hidden="true" (click)="selectBrand(brand['id'])"></i>
                            <p class="search-label mt-2 cursor-pointer" (click)="selectBrand(brand['id'])">{{ brand["name"] }}</p>
                          </div>
                        </div>
                        <div *ngIf="selectedBrand != {}" class="text-center">
                          <embed *ngIf="selectedBrand['id'] == 1" src="https://drive.google.com/file/d/1lwXbEbcTx1VkvF8yQr0G4M7D1KkzDNt5/preview" type="application/pdf" width="100%" height="600px" />
                          <embed *ngIf="selectedBrand['id'] == 2" src="https://drive.google.com/file/d/1CWKiyRtecRPy7puUECtyFo--KIaAhqmb/preview" type="application/pdf" width="100%" height="600px" />
                        </div>
                      </ul>
                    </div>
                  </div>
                  <div class="sv-aside__col">
                    <article class="sv-rich-card sv--quarternary"><a href="https://www.savills.co.uk/market-your-property/">
                        <div class="sv-rich-card__content">
                          <div class="sv-rich-card__detail">
                            <h1 class="sv-card-title">Market your property</h1>
                            <p class="sv-card-intro">Whether you're selling or looking to attract new tenants, we're here to help</p><span class="sv-cta-link"><span>Read more</span></span>
                          </div>
                        </div>
                        <div class="sv-rich-card__bg">
                          <div style="min-height: 1px;"><img src="../../../assets/savills/market-your-property.jpg" alt="Market your property"></div>
                        </div>
                      </a></article>
                  </div>
                </aside>
              </div>
            </div>
          </div>
          <div class="sv-view-lt-lrg">
            <div class="sv-cta-links sv--overlay sv--fixed-lt-lrg"><button type="button" class="sv-arrange-view sv-button sv--full">Request details</button></div>
          </div>
        </div>
        <div class="sv-section-bg sv-related-properties">
          <div class="sv-row">
            <h2 class="sv-section-header__title">Related properties</h2>
          </div>
          <div class="sv-carousel" id="related-properties-carousel"><button class="sv-carousel__nav sv--prev sv--inactive" type="button" disabled="disabled">Previous</button>
            <div class="sv-carousel__track sv-contained" style="transform: translateX(0px);">
              <div class="sv-carousel__slide" style="min-width: 316px;">
                <article class="sv-property-card sv--type-commercial sv--search-type-rent sv--status-available    sv--secondary-type-retail">
                  <div class="sv-slider">
                    <div><a class="sv-details__link" id="GB37650000121848" href="https://search.savills.com/property-detail/gb37650000121848"></a>
                      <div>
                        <div style="min-height: 1px;">
                          <div class="sv-image sv--background-image" style="background-image: url(&quot;https://assets.savills.com/properties/GB37650000121848/CG_91-101_London_picture_2022-05-17-11-24-18_l_lis.jpg&quot;);"></div>
                        </div>
                      </div>
                    </div><span class="sv-slider__footer sv--bg"></span>
                    <div class="sv-slider__footer">
                      <div class="sv-slider__footer-labels"><span></span></div>
                      <div class="sv-slider__footer-actions"><span class="sv-slider__footer-action sv--save-inline"><button type="button" class="sv-favourite"><span>Save</span></button></span></div>
                    </div>
                  </div>
                  <div class="sv-details">
                    <div class="sv-details__content"><a class="sv-details__link" id="GB37650000121848" href="https://search.savills.com/property-detail/gb37650000121848"></a>
                      <h3 class="sv-details__address1">
                        <div><span class="sv-details__address1--truncate" width="0"><span><span>91-101</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                      </h3>
                      <p class="sv-details__address2">Oxford Street, London, W1R 1RA</p>
                      <div class="sv-details__price">
                        <div class="sv-property-price sv--show-size sv--show-rent-basis sv--show-rent-unit sv--show-guide"><span><span class="sv-property-price__guide">Price on application</span> </span>
                          <div class="sv-property-price__rent-basis-fees"><span class="sv-property-price__price-disclaimer">On application.</span></div>
                        </div>
                      </div>
                      <div class="sv-details__features">
                        <div class="sv-key-features">
                          <ul class="sv-list sv--bullets">
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Above Tottenham Court Road Crossrail Station.</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span
                                  class="sv-view-gt-med" width="0"><span></span><span><span>Above Tottenham Court Road Crossrail Station.</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Exceptional 90ft Frontage.</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span
                                  class="sv-view-gt-med" width="0"><span></span><span><span>Exceptional 90ft Frontage.</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>A Leading Retail Destination With 200m Visitors Per Year.</span></span><span
                                    style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med" width="0"><span></span><span><span>A Leading Retail Destination With 200m Visitors Per
                                      Year.</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="sv-details__contacts">
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">Callum White</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7499 8644">020 7499 8644</a><span class="sv-view-gt-lrg">020 7499 8644</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">Sam Foyle</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7409 8171">020 7409 8171</a><span class="sv-view-gt-lrg">020 7409 8171</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                      </div>
                    </div>
                    <footer class="sv-details__footer">
                      <div class="sv-details__footer-left">
                        <div class="sv-property-attribute sv--commercial"><span class="sv-property-attribute__value" title="Retail">Retail</span></div>
                      </div>
                      <div class="sv-details__footer-right"></div>
                    </footer>
                  </div>
                </article>
              </div>
              <div class="sv-carousel__slide" style="min-width: 316px;">
                <article class="sv-property-card sv--type-commercial sv--search-type-rent sv--status-available    sv--secondary-type-retail">
                  <div class="sv-slider">
                    <div><a class="sv-details__link" id="GB37650000116601" href="https://search.savills.com/property-detail/gb37650000116601"></a>
                      <div>
                        <div style="min-height: 1px;">
                          <div class="sv-image sv--background-image" style="background-image: url(&quot;https://assets.savills.com/properties/GB37650000116601/CG_192-194_Oxford_Street_London_picture_2021-09-30-11-46-25_l_lis.jpg&quot;);"></div>
                        </div>
                      </div>
                    </div><span class="sv-slider__footer sv--bg"></span>
                    <div class="sv-slider__footer">
                      <div class="sv-slider__footer-labels"><span></span></div>
                      <div class="sv-slider__footer-actions"><span class="sv-slider__footer-action sv--save-inline"><button type="button" class="sv-favourite"><span>Save</span></button></span></div>
                    </div>
                  </div>
                  <div class="sv-details">
                    <div class="sv-details__content"><a class="sv-details__link" id="GB37650000116601" href="https://search.savills.com/property-detail/gb37650000116601"></a>
                      <h3 class="sv-details__address1">
                        <div><span class="sv-details__address1--truncate" width="0"><span><span>192-194 Oxford Street</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                      </h3>
                      <p class="sv-details__address2">Oxford Street, London, W1D 1NS</p>
                      <div class="sv-details__price">
                        <div class="sv-property-price sv--show-size sv--show-rent-basis sv--show-rent-unit sv--show-guide"><span><span class="sv-property-price__guide">Price on application</span> </span>
                          <div class="sv-property-price__rent-basis-fees"></div>
                        </div>
                      </div>
                      <div class="sv-details__features">
                        <div class="sv-key-features">
                          <ul class="sv-list sv--bullets">
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>A Leading Retail Destination With 200M Visitors A Year</span></span><span
                                    style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med" width="0"><span></span><span><span>A Leading Retail Destination With 200M Visitors A
                                      Year</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>West End Sales To Reach £13.2BN By 2022</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span
                                  class="sv-view-gt-med" width="0"><span></span><span><span>West End Sales To Reach £13.2BN By 2022</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>One Of London's Largest Employment Centres, Employing Around 155,000 People Within The Oxford Street District</span></span><span
                                    style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med" width="0"><span></span><span><span>One Of London's Largest Employment Centres, Employing Around 155,000
                                      People Within The Oxford Street District</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="sv-details__contacts">
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">Callum White</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7499 8644">020 7499 8644</a><span class="sv-view-gt-lrg">020 7499 8644</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">Sam Foyle</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7409 8171">020 7409 8171</a><span class="sv-view-gt-lrg">020 7409 8171</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                      </div>
                    </div>
                    <footer class="sv-details__footer">
                      <div class="sv-details__footer-left">
                        <div class="sv-property-attribute sv--commercial"><span class="sv-property-attribute__value" title="Retail">Retail</span></div>
                      </div>
                      <div class="sv-details__footer-right"></div>
                    </footer>
                  </div>
                </article>
              </div>
              <div class="sv-carousel__slide" style="min-width: 316px;">
                <article class="sv-property-card sv--type-commercial sv--search-type-rent sv--status-available    sv--secondary-type-retail">
                  <div class="sv-slider">
                    <div><a class="sv-details__link" id="GB37650000121156" href="https://search.savills.com/property-detail/gb37650000121156"></a>
                      <div>
                        <div style="min-height: 1px;">
                          <div class="sv-image sv--background-image" style="background-image: url(&quot;https://assets.savills.com/properties/GB37650000121156/CG_9_Princes_Street_London_picture_2022-04-11-16-36-02_l_lis.jpg&quot;);"></div>
                        </div>
                      </div>
                    </div><span class="sv-slider__footer sv--bg"></span>
                    <div class="sv-slider__footer">
                      <div class="sv-slider__footer-labels"><span></span></div>
                      <div class="sv-slider__footer-actions"><span class="sv-slider__footer-action sv--save-inline"><button type="button" class="sv-favourite"><span>Save</span></button></span></div>
                    </div>
                  </div>
                  <div class="sv-details">
                    <div class="sv-details__content"><a class="sv-details__link" id="GB37650000121156" href="https://search.savills.com/property-detail/gb37650000121156"></a>
                      <h3 class="sv-details__address1">
                        <div><span class="sv-details__address1--truncate" width="0"><span><span>9 Princes Street</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                      </h3>
                      <p class="sv-details__address2">Princes Street, London, W1B 2LQ</p>
                      <div class="sv-details__price">
                        <div class="sv-property-price sv--show-size sv--show-rent-basis sv--show-rent-unit sv--show-guide"><span><span class="sv-property-price__guide">Price on application</span> </span>
                          <div class="sv-property-price__rent-basis-fees"><span class="sv-property-price__price-disclaimer">On application</span></div>
                        </div>
                      </div>
                      <div class="sv-details__features">
                        <div class="sv-key-features">
                          <ul class="sv-list sv--bullets">
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Nearby occupiers include: Neat Burger, Starbucks and Dirty Martini</span></span><span
                                    style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med" width="0"><span></span><span><span>Nearby occupiers include: Neat Burger, Starbucks and Dirty
                                      Martini</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="sv-details__contacts">
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">Claire Lakie</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 0207 758 3896">0207 758 3896</a><span class="sv-view-gt-lrg">0207 758 3896</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">Callum White</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7499 8644">020 7499 8644</a><span class="sv-view-gt-lrg">020 7499 8644</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                      </div>
                    </div>
                    <footer class="sv-details__footer">
                      <div class="sv-details__footer-left">
                        <div class="sv-property-attribute sv--commercial"><span class="sv-property-attribute__value" title="Retail">Retail</span></div>
                      </div>
                      <div class="sv-details__footer-right"></div>
                    </footer>
                  </div>
                </article>
              </div>
              <div class="sv-carousel__slide" style="min-width: 316px;">
                <article class="sv-property-card sv--type-commercial sv--search-type-rent sv--status-available    sv--secondary-type-retail">
                  <div class="sv-slider">
                    <div><a class="sv-details__link" id="GB37650000097455" href="https://search.savills.com/property-detail/gb37650000097455"></a>
                      <div>
                        <div style="min-height: 1px;">
                          <div class="sv-image sv--background-image"
                            style="background-image: url(&quot;https://assets.savills.com/properties/GB37650000097455/CG_Margaret_Court_Market_Place_London_W1_London_picture_2018-12-13-16-58-22_l_lis.jpg&quot;);"></div>
                        </div>
                      </div>
                    </div><span class="sv-slider__footer sv--bg"></span>
                    <div class="sv-slider__footer">
                      <div class="sv-slider__footer-labels"><span></span></div>
                      <div class="sv-slider__footer-actions"><span class="sv-slider__footer-action sv--save-inline"><button type="button" class="sv-favourite"><span>Save</span></button></span></div>
                    </div>
                  </div>
                  <div class="sv-details sv--show-price">
                    <div class="sv-details__content"><a class="sv-details__link" id="GB37650000097455" href="https://search.savills.com/property-detail/gb37650000097455"></a>
                      <h3 class="sv-details__address1">
                        <div><span class="sv-details__address1--truncate" width="0"><span><span>Margaret Court, Market</span><br><span>Place, London W1</span></span><span
                              style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                      </h3>
                      <p class="sv-details__address2">Margaret Court, Market Place, London, W1W 8AH</p>
                      <div class="sv-details__price">
                        <div class="sv-property-price sv--show-price sv--show-size sv--show-rent-basis sv--show-rent-unit">
                          <div class="sv-property-price__wrap"> <span class="sv-property-price__value"><span class="sv-property-price__rent-unit"></span><span>£185,000</span></span></div>
                          <div class="sv-property-price__rent-basis-fees"><span class="sv-property-price__rent-basis">per year</span></div>
                        </div>
                      </div>
                      <div class="sv-details__features">
                        <div class="sv-key-features">
                          <ul class="sv-list sv--bullets">
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>A3 Restaurant Opportunity Available</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span
                                  class="sv-view-gt-med" width="0"><span></span><span><span>A3 Restaurant Opportunity Available</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="sv-details__contacts">
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">Peter Thomas</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7499 8644">020 7499 8644</a><span class="sv-view-gt-lrg">020 7499 8644</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                      </div>
                    </div>
                    <footer class="sv-details__footer">
                      <div class="sv-details__footer-left">
                        <div class="sv-property-attribute sv--commercial"><span class="sv-property-attribute__value" title="Retail">Retail</span></div>
                      </div>
                      <div class="sv-details__footer-right"></div>
                    </footer>
                  </div>
                </article>
              </div>
              <div class="sv-carousel__slide" style="min-width: 316px;">
                <article class="sv-property-card sv--type-commercial sv--search-type-rent sv--status-available    sv--secondary-type-retail">
                  <div class="sv-slider">
                    <div><a class="sv-details__link" id="GB37650000113072" href="https://search.savills.com/property-detail/gb37650000113072"></a>
                      <div>
                        <div style="min-height: 1px;">
                          <div class="sv-image sv--background-image" style="background-image: url(&quot;https://assets.savills.com/properties/GB37650000113072/CG_73-89_Oxford_Street_Greater_London_picture_2021-05-19-09-29-34_l_lis.jpg&quot;);"></div>
                        </div>
                      </div>
                    </div><span class="sv-slider__footer sv--bg"></span>
                    <div class="sv-slider__footer">
                      <div class="sv-slider__footer-labels"><span></span></div>
                      <div class="sv-slider__footer-actions"><span class="sv-slider__footer-action sv--save-inline"><button type="button" class="sv-favourite"><span>Save</span></button></span></div>
                    </div>
                  </div>
                  <div class="sv-details">
                    <div class="sv-details__content"><a class="sv-details__link" id="GB37650000113072" href="https://search.savills.com/property-detail/gb37650000113072"></a>
                      <h3 class="sv-details__address1">
                        <div><span class="sv-details__address1--truncate" width="0"><span><span>73-89 Oxford Street</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                      </h3>
                      <p class="sv-details__address2">Oxford Street, Greater London, W1D 2JL</p>
                      <div class="sv-details__price">
                        <div class="sv-property-price sv--show-size sv--show-rent-basis sv--show-rent-unit sv--show-guide"><span><span class="sv-property-price__guide">Price on application</span> </span>
                          <div class="sv-property-price__rent-basis-fees"><span class="sv-property-price__price-disclaimer">Upon application</span></div>
                        </div>
                      </div>
                      <div class="sv-details__features">
                        <div class="sv-key-features">
                          <ul class="sv-list sv--bullets">
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Total area of 19,357 sq ft </span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span
                                  class="sv-view-gt-med" width="0"><span></span><span><span>Total area of 19,357 sq ft </span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Prime Highly Visible Flagship Unit </span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span
                                  class="sv-view-gt-med" width="0"><span></span><span><span>Prime Highly Visible Flagship Unit </span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="sv-details__contacts">
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">Sam Foyle</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7409 8171">020 7409 8171</a><span class="sv-view-gt-lrg">020 7409 8171</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">Anthony Selwyn</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7499 8644">020 7499 8644</a><span class="sv-view-gt-lrg">020 7499 8644</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                      </div>
                    </div>
                    <footer class="sv-details__footer">
                      <div class="sv-details__footer-left">
                        <div class="sv-property-attribute sv--commercial"><span class="sv-property-attribute__value" title="Retail">Retail</span></div>
                      </div>
                      <div class="sv-details__footer-right"></div>
                    </footer>
                  </div>
                </article>
              </div>
              <div class="sv-carousel__slide" style="min-width: 316px;">
                <article class="sv-property-card sv--type-commercial sv--search-type-rent sv--status-under-offer    sv--secondary-type-retail">
                  <div class="sv-slider">
                    <div><a class="sv-details__link" id="GB37650000105632" href="https://search.savills.com/property-detail/gb37650000105632"></a>
                      <div>
                        <div style="min-height: 1px;">
                          <div class="sv-image sv--background-image" style="background-image: url(&quot;https://assets.savills.com/properties/GB37650000105632/CG_56_Maddox_Street_London_W1_London_picture_2019-11-25-11-34-22_l_lis.jpg&quot;);"></div>
                        </div>
                      </div>
                    </div><span class="sv-slider__footer sv--bg"></span>
                    <div class="sv-slider__footer">
                      <div class="sv-slider__footer-labels"><span><span class="sv-card-label sv--negative">Under offer</span></span></div>
                      <div class="sv-slider__footer-actions"><span class="sv-slider__footer-action sv--save-inline"><button type="button" class="sv-favourite"><span>Save</span></button></span></div>
                    </div>
                  </div>
                  <div class="sv-details sv--show-price">
                    <div class="sv-details__content"><a class="sv-details__link" id="GB37650000105632" href="https://search.savills.com/property-detail/gb37650000105632"></a>
                      <h3 class="sv-details__address1">
                        <div><span class="sv-details__address1--truncate" width="0"><span><span>56 Maddox Street</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                      </h3>
                      <p class="sv-details__address2">Mayfair, London, W1S 2QJ</p>
                      <div class="sv-details__price">
                        <div class="sv-property-price sv--show-price sv--show-size sv--show-rent-basis sv--show-rent-unit">
                          <div class="sv-property-price__wrap"> <span class="sv-property-price__value"><span class="sv-property-price__rent-unit"></span><span>£120,000</span></span></div>
                          <div class="sv-property-price__rent-basis-fees"><span class="sv-property-price__rent-basis">per year</span></div>
                        </div>
                      </div>
                      <div class="sv-details__features">
                        <div class="sv-key-features">
                          <ul class="sv-list sv--bullets">
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Prime Retail Shop / Gallery to Let</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span
                                  class="sv-view-gt-med" width="0"><span></span><span><span>Prime Retail Shop / Gallery to Let</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Potential Double Unit Available</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span
                                  class="sv-view-gt-med" width="0"><span></span><span><span>Potential Double Unit Available</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="sv-details__contacts">
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">James Fairley</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7758 3877">020 7758 3877</a><span class="sv-view-gt-lrg">020 7758 3877</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                      </div>
                    </div>
                    <footer class="sv-details__footer">
                      <div class="sv-details__footer-left">
                        <div class="sv-property-attribute sv--commercial"><span class="sv-property-attribute__value" title="Retail">Retail</span></div>
                      </div>
                      <div class="sv-details__footer-right"></div>
                    </footer>
                  </div>
                </article>
              </div>
              <div class="sv-carousel__slide" style="min-width: 316px;">
                <article class="sv-property-card sv--type-commercial sv--search-type-rent sv--status-available    sv--secondary-type-retail">
                  <div class="sv-slider">
                    <div><a class="sv-details__link" id="GB37650000106778" href="https://search.savills.com/property-detail/gb37650000106778"></a>
                      <div>
                        <div style="min-height: 1px;"></div>
                      </div>
                    </div><span class="sv-slider__footer sv--bg"></span>
                    <div class="sv-slider__footer">
                      <div class="sv-slider__footer-labels"><span><span class="sv-card-label sv--neutral">Leasehold</span></span></div>
                      <div class="sv-slider__footer-actions"><span class="sv-slider__footer-action sv--save-inline"><button type="button" class="sv-favourite"><span>Save</span></button></span></div>
                    </div>
                  </div>
                  <div class="sv-details sv--show-price">
                    <div class="sv-details__content"><a class="sv-details__link" id="GB37650000106778" href="https://search.savills.com/property-detail/gb37650000106778"></a>
                      <h3 class="sv-details__address1">
                        <div><span class="sv-details__address1--truncate" width="0"><span><span>295 Regent Street, London,</span><br><span>W1B 2HL</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span>
                        </div>
                      </h3>
                      <p class="sv-details__address2">295 Regent Street, London, W1B 2HL</p>
                      <div class="sv-details__price">
                        <div class="sv-property-price sv--show-price sv--show-size sv--show-rent-basis sv--show-rent-unit">
                          <div class="sv-property-price__wrap"> <span class="sv-property-price__value"><span class="sv-property-price__rent-unit"></span><span>£350,000</span></span></div>
                          <div class="sv-property-price__rent-basis-fees"><span class="sv-property-price__rent-basis">per year</span></div>
                        </div>
                      </div>
                      <div class="sv-details__features">
                        <div class="sv-key-features">
                          <ul class="sv-list sv--bullets">
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Prime location</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med"
                                  width="0"><span></span><span><span>Prime location</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>High footfall</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med"
                                  width="0"><span></span><span><span>High footfall</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Benefit from Crossrail in 2021</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span
                                  class="sv-view-gt-med" width="0"><span></span><span><span>Benefit from Crossrail in 2021</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Vibrant mix of uses </span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med"
                                  width="0"><span></span><span><span>Vibrant mix of uses </span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="sv-details__contacts">
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">James Fairley</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7758 3877">020 7758 3877</a><span class="sv-view-gt-lrg">020 7758 3877</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                      </div>
                    </div>
                    <footer class="sv-details__footer">
                      <div class="sv-details__footer-left">
                        <div class="sv-property-attribute sv--commercial"><span class="sv-property-attribute__value" title="Retail">Retail</span></div>
                      </div>
                      <div class="sv-details__footer-right"></div>
                    </footer>
                  </div>
                </article>
              </div>
              <div class="sv-carousel__slide" style="min-width: 316px;">
                <article class="sv-property-card sv--type-commercial sv--search-type-rent sv--status-available    sv--secondary-type-retail">
                  <div class="sv-slider">
                    <div><a class="sv-details__link" id="GB37650000106794" href="https://search.savills.com/property-detail/gb37650000106794"></a>
                      <div>
                        <div style="min-height: 1px;"></div>
                      </div>
                    </div><span class="sv-slider__footer sv--bg"></span>
                    <div class="sv-slider__footer">
                      <div class="sv-slider__footer-labels"><span></span></div>
                      <div class="sv-slider__footer-actions"><span class="sv-slider__footer-action sv--save-inline"><button type="button" class="sv-favourite"><span>Save</span></button></span></div>
                    </div>
                  </div>
                  <div class="sv-details sv--show-price">
                    <div class="sv-details__content"><a class="sv-details__link" id="GB37650000106794" href="https://search.savills.com/property-detail/gb37650000106794"></a>
                      <h3 class="sv-details__address1">
                        <div><span class="sv-details__address1--truncate" width="0"><span><span>31 St George St, Mayfair,</span><br><span>London W1S 2FJ</span></span><span
                              style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                      </h3>
                      <p class="sv-details__address2">31 St George Street, London, W1S 2FJ</p>
                      <div class="sv-details__price">
                        <div class="sv-property-price sv--show-price sv--show-size sv--show-rent-basis sv--show-rent-unit">
                          <div class="sv-property-price__wrap"> <span class="sv-property-price__value"><span class="sv-property-price__rent-unit"></span><span>£552,500</span></span></div>
                          <div class="sv-property-price__rent-basis-fees"><span class="sv-property-price__rent-basis">per year</span></div>
                        </div>
                      </div>
                      <div class="sv-details__features">
                        <div class="sv-key-features">
                          <ul class="sv-list sv--bullets">
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Highly visible location</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span
                                  class="sv-view-gt-med" width="0"><span></span><span><span>Highly visible location</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Vibrant mix of uses</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med"
                                  width="0"><span></span><span><span>Vibrant mix of uses</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Benefit from the opening of Crossrail in 2021</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span
                                  class="sv-view-gt-med" width="0"><span></span><span><span>Benefit from the opening of Crossrail in 2021</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Nearby occupiers include Sothebys, ITSU &amp; Trinity House Gallery</span></span><span
                                    style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med" width="0"><span></span><span><span>Nearby occupiers include Sothebys, ITSU &amp; Trinity House
                                      Gallery</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="sv-details__contacts">
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">James Fairley</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7758 3877">020 7758 3877</a><span class="sv-view-gt-lrg">020 7758 3877</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">Laura Salisbury Jones</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7409 8830">020 7409 8830</a><span class="sv-view-gt-lrg">020 7409 8830</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                      </div>
                    </div>
                    <footer class="sv-details__footer">
                      <div class="sv-details__footer-left">
                        <div class="sv-property-attribute sv--commercial"><span class="sv-property-attribute__value" title="Retail">Retail</span></div>
                      </div>
                      <div class="sv-details__footer-right"></div>
                    </footer>
                  </div>
                </article>
              </div>
              <div class="sv-carousel__slide" style="min-width: 316px;">
                <article class="sv-property-card sv--type-commercial sv--search-type-rent sv--status-available    sv--secondary-type-retail">
                  <div class="sv-slider">
                    <div><a class="sv-details__link" id="GB37650000113131" href="https://search.savills.com/property-detail/gb37650000113131"></a>
                      <div>
                        <div style="min-height: 1px;"></div>
                      </div>
                    </div><span class="sv-slider__footer sv--bg"></span>
                    <div class="sv-slider__footer">
                      <div class="sv-slider__footer-labels"><span></span></div>
                      <div class="sv-slider__footer-actions"><span class="sv-slider__footer-action sv--save-inline"><button type="button" class="sv-favourite"><span>Save</span></button></span></div>
                    </div>
                  </div>
                  <div class="sv-details sv--show-price">
                    <div class="sv-details__content"><a class="sv-details__link" id="GB37650000113131" href="https://search.savills.com/property-detail/gb37650000113131"></a>
                      <h3 class="sv-details__address1">
                        <div><span class="sv-details__address1--truncate" width="0"><span><span>13a St George Street</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                      </h3>
                      <p class="sv-details__address2">St George Street, City of London, W1S 2FQ</p>
                      <div class="sv-details__price">
                        <div class="sv-property-price sv--show-price sv--show-size sv--show-rent-basis sv--show-rent-unit">
                          <div class="sv-property-price__wrap"> <span class="sv-property-price__value"><span class="sv-property-price__rent-unit"></span><span>£115,000</span></span></div>
                          <div class="sv-property-price__rent-basis-fees"><span class="sv-property-price__rent-basis">per year</span></div>
                        </div>
                      </div>
                      <div class="sv-details__features">
                        <div class="sv-key-features">
                          <ul class="sv-list sv--bullets">
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>The
                                      premises is in close proximity to the luxury shopping destination of
                                      New Bond Street and a short distance from Vogue House, home to the
                                      namesakes UK headquarters.</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med" width="0"><span></span><span><span>The
                                      premises is in close proximity to the luxury shopping destination of
                                      New Bond Street and a short distance from Vogue House, home to the
                                      namesakes UK headquarters.</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>The
                                      location will further benefit from the opening of Crossrail and The
                                      Residences at Mandarin Oriental Mayfair which are both nearby and due to
                                      open in the near future.</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med" width="0"><span></span><span><span>The
                                      location will further benefit from the opening of Crossrail and The
                                      Residences at Mandarin Oriental Mayfair which are both nearby and due to
                                      open in the near future.</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>There
                                      are an eclectic mix of uses in the vicinity with occupiers including
                                      Sotherby’s Auction House, BAPE, Victoria Miro Gallery, Goodman
                                      Restaurant, Bombay Bustle Restaurant, Neil Barrett and Berluti among
                                      various others.</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med" width="0"><span></span><span><span>There
                                      are an eclectic mix of uses in the vicinity with occupiers including
                                      Sotherby’s Auction House, BAPE, Victoria Miro Gallery, Goodman
                                      Restaurant, Bombay Bustle Restaurant, Neil Barrett and Berluti among
                                      various others.</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="sv-details__contacts">
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">Peter Thomas</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7499 8644">020 7499 8644</a><span class="sv-view-gt-lrg">020 7499 8644</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">Daniel Aboud</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7499 8644">020 7499 8644</a><span class="sv-view-gt-lrg">020 7499 8644</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                      </div>
                    </div>
                    <footer class="sv-details__footer">
                      <div class="sv-details__footer-left">
                        <div class="sv-property-attribute sv--commercial"><span class="sv-property-attribute__value" title="Retail">Retail</span></div>
                      </div>
                      <div class="sv-details__footer-right"></div>
                    </footer>
                  </div>
                </article>
              </div>
              <div class="sv-carousel__slide" style="min-width: 316px;">
                <article class="sv-property-card sv--type-commercial sv--search-type-rent sv--status-available    sv--secondary-type-retail">
                  <div class="sv-slider">
                    <div><a class="sv-details__link" id="GB37650000106039" href="https://search.savills.com/property-detail/gb37650000106039"></a>
                      <div>
                        <div style="min-height: 1px;"></div>
                      </div>
                    </div><span class="sv-slider__footer sv--bg"></span>
                    <div class="sv-slider__footer">
                      <div class="sv-slider__footer-labels"><span></span></div>
                      <div class="sv-slider__footer-actions"><span class="sv-slider__footer-action sv--save-inline"><button type="button" class="sv-favourite"><span>Save</span></button></span></div>
                    </div>
                  </div>
                  <div class="sv-details">
                    <div class="sv-details__content"><a class="sv-details__link" id="GB37650000106039" href="https://search.savills.com/property-detail/gb37650000106039"></a>
                      <h3 class="sv-details__address1">
                        <div><span class="sv-details__address1--truncate" width="0"><span><span>58 Maddox Street</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                      </h3>
                      <p class="sv-details__address2">Mayfair, London, W1S 1AY</p>
                      <div class="sv-details__price">
                        <div class="sv-property-price sv--show-size sv--show-rent-basis sv--show-rent-unit sv--show-guide"><span><span class="sv-property-price__guide">Price on application</span> </span>
                          <div class="sv-property-price__rent-basis-fees"><span class="sv-property-price__price-disclaimer">On application.</span></div>
                        </div>
                      </div>
                      <div class="sv-details__features">
                        <div class="sv-key-features">
                          <ul class="sv-list sv--bullets">
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>A1 Shop / Gallery To Let</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span
                                  class="sv-view-gt-med" width="0"><span></span><span><span>A1 Shop / Gallery To Let</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="sv-details__contacts">
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">James Fairley</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7758 3877">020 7758 3877</a><span class="sv-view-gt-lrg">020 7758 3877</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                      </div>
                    </div>
                    <footer class="sv-details__footer">
                      <div class="sv-details__footer-left">
                        <div class="sv-property-attribute sv--commercial"><span class="sv-property-attribute__value" title="Retail">Retail</span></div>
                      </div>
                      <div class="sv-details__footer-right"></div>
                    </footer>
                  </div>
                </article>
              </div>
              <div class="sv-carousel__slide" style="min-width: 316px;">
                <article class="sv-property-card sv--type-commercial sv--search-type-rent sv--status-available    sv--secondary-type-retail">
                  <div class="sv-slider">
                    <div><a class="sv-details__link" id="GB37650000099534" href="https://search.savills.com/property-detail/gb37650000099534"></a>
                      <div>
                        <div style="min-height: 1px;"></div>
                      </div>
                    </div><span class="sv-slider__footer sv--bg"></span>
                    <div class="sv-slider__footer">
                      <div class="sv-slider__footer-labels"><span></span></div>
                      <div class="sv-slider__footer-actions"><span class="sv-slider__footer-action sv--save-inline"><button type="button" class="sv-favourite"><span>Save</span></button></span></div>
                    </div>
                  </div>
                  <div class="sv-details">
                    <div class="sv-details__content"><a class="sv-details__link" id="GB37650000099534" href="https://search.savills.com/property-detail/gb37650000099534"></a>
                      <h3 class="sv-details__address1">
                        <div><span class="sv-details__address1--truncate" width="0"><span><span>6 Warwick Street</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                      </h3>
                      <p class="sv-details__address2">Warwick Street, London, W1B 5NE</p>
                      <div class="sv-details__price">
                        <div class="sv-property-price sv--show-size sv--show-rent-basis sv--show-rent-unit sv--show-guide"><span><span class="sv-property-price__guide">Price on application</span> </span>
                          <div class="sv-property-price__rent-basis-fees"></div>
                        </div>
                      </div>
                      <div class="sv-details__features">
                        <div class="sv-key-features">
                          <ul class="sv-list sv--bullets">
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Soho Shop to Let</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med"
                                  width="0"><span></span><span><span>Soho Shop to Let</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="sv-details__contacts">
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">James Fairley</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7758 3877">020 7758 3877</a><span class="sv-view-gt-lrg">020 7758 3877</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">Peter Thomas</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7499 8644">020 7499 8644</a><span class="sv-view-gt-lrg">020 7499 8644</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                      </div>
                    </div>
                    <footer class="sv-details__footer">
                      <div class="sv-details__footer-left">
                        <div class="sv-property-attribute sv--commercial"><span class="sv-property-attribute__value" title="Retail">Retail</span></div>
                      </div>
                      <div class="sv-details__footer-right"></div>
                    </footer>
                  </div>
                </article>
              </div>
              <div class="sv-carousel__slide" style="min-width: 316px;">
                <article class="sv-property-card sv--type-commercial sv--search-type-rent sv--status-available    sv--secondary-type-retail">
                  <div class="sv-slider">
                    <div><a class="sv-details__link" id="GB37650000115339" href="https://search.savills.com/property-detail/gb37650000115339"></a>
                      <div>
                        <div style="min-height: 1px;"></div>
                      </div>
                    </div><span class="sv-slider__footer sv--bg"></span>
                    <div class="sv-slider__footer">
                      <div class="sv-slider__footer-labels"><span></span></div>
                      <div class="sv-slider__footer-actions"><span class="sv-slider__footer-action sv--save-inline"><button type="button" class="sv-favourite"><span>Save</span></button></span></div>
                    </div>
                  </div>
                  <div class="sv-details">
                    <div class="sv-details__content"><a class="sv-details__link" id="GB37650000115339" href="https://search.savills.com/property-detail/gb37650000115339"></a>
                      <h3 class="sv-details__address1">
                        <div><span class="sv-details__address1--truncate" width="0"><span><span>147-149 Wardour Street</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                      </h3>
                      <p class="sv-details__address2">Wardour Street, Soho, London, W1F 8WD</p>
                      <div class="sv-details__price">
                        <div class="sv-property-price sv--show-size sv--show-rent-basis sv--show-rent-unit sv--show-guide"><span><span class="sv-property-price__guide">Price on application</span> </span>
                          <div class="sv-property-price__rent-basis-fees"><span class="sv-property-price__price-disclaimer">On Application</span></div>
                        </div>
                      </div>
                      <div class="sv-details__features">
                        <div class="sv-key-features">
                          <ul class="sv-list sv--bullets">
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Wardour Street is one of the most recognised and iconic locations in the heart of Soho</span></span><span
                                    style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med" width="0"><span></span><span><span>Wardour Street is one of the most recognised and iconic locations in
                                      the heart of Soho</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Close proximity to the pre-eminent retail locations of Carnaby Street, Oxford Street and Regent Street</span></span><span
                                    style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med" width="0"><span></span><span><span>Close proximity to the pre-eminent retail locations of Carnaby
                                      Street, Oxford Street and Regent Street</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="sv-details__contacts">
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">Daniel Aboud</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7499 8644">020 7499 8644</a><span class="sv-view-gt-lrg">020 7499 8644</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">James Fairley</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7758 3877">020 7758 3877</a><span class="sv-view-gt-lrg">020 7758 3877</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                      </div>
                    </div>
                    <footer class="sv-details__footer">
                      <div class="sv-details__footer-left">
                        <div class="sv-property-attribute sv--commercial"><span class="sv-property-attribute__value" title="Retail">Retail</span></div>
                      </div>
                      <div class="sv-details__footer-right"></div>
                    </footer>
                  </div>
                </article>
              </div>
              <div class="sv-carousel__slide" style="min-width: 316px;">
                <article class="sv-property-card sv--type-commercial sv--search-type-rent sv--status-available    sv--secondary-type-retail">
                  <div class="sv-slider">
                    <div><a class="sv-details__link" id="GB37650000105945" href="https://search.savills.com/property-detail/gb37650000105945"></a>
                      <div>
                        <div style="min-height: 1px;"></div>
                      </div>
                    </div><span class="sv-slider__footer sv--bg"></span>
                    <div class="sv-slider__footer">
                      <div class="sv-slider__footer-labels"><span></span></div>
                      <div class="sv-slider__footer-actions"><span class="sv-slider__footer-action sv--save-inline"><button type="button" class="sv-favourite"><span>Save</span></button></span></div>
                    </div>
                  </div>
                  <div class="sv-details">
                    <div class="sv-details__content"><a class="sv-details__link" id="GB37650000105945" href="https://search.savills.com/property-detail/gb37650000105945"></a>
                      <h3 class="sv-details__address1">
                        <div><span class="sv-details__address1--truncate" width="0"><span><span>324 Oxford Street</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                      </h3>
                      <p class="sv-details__address2">Oxford Street, London, W1C 1JA</p>
                      <div class="sv-details__price">
                        <div class="sv-property-price sv--show-size sv--show-rent-basis sv--show-rent-unit sv--show-guide"><span><span class="sv-property-price__guide">Price on application</span> </span>
                          <div class="sv-property-price__rent-basis-fees"></div>
                        </div>
                      </div>
                      <div class="sv-details__features">
                        <div class="sv-key-features">
                          <ul class="sv-list sv--bullets">
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Prime pitch of the street</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span
                                  class="sv-view-gt-med" width="0"><span></span><span><span>Prime pitch of the street</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Arranged over six floors </span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span
                                  class="sv-view-gt-med" width="0"><span></span><span><span>Arranged over six floors </span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Close to some of the largest department stores in London including House of Fraser, Debenhams and John Lewis</span></span><span
                                    style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med" width="0"><span></span><span><span>Close to some of the largest department stores in London including
                                      House of Fraser, Debenhams and John Lewis</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="sv-details__contacts">
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">Sam Foyle</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7409 8171">020 7409 8171</a><span class="sv-view-gt-lrg">020 7409 8171</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">Callum White</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7499 8644">020 7499 8644</a><span class="sv-view-gt-lrg">020 7499 8644</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                      </div>
                    </div>
                    <footer class="sv-details__footer">
                      <div class="sv-details__footer-left">
                        <div class="sv-property-attribute sv--commercial"><span class="sv-property-attribute__value" title="Retail">Retail</span></div>
                      </div>
                      <div class="sv-details__footer-right"></div>
                    </footer>
                  </div>
                </article>
              </div>
              <div class="sv-carousel__slide" style="min-width: 316px;">
                <article class="sv-property-card sv--type-commercial sv--search-type-rent sv--status-available    sv--secondary-type-retail">
                  <div class="sv-slider">
                    <div><a class="sv-details__link" id="GB37650000105354" href="https://search.savills.com/property-detail/gb37650000105354"></a>
                      <div>
                        <div style="min-height: 1px;"></div>
                      </div>
                    </div><span class="sv-slider__footer sv--bg"></span>
                    <div class="sv-slider__footer">
                      <div class="sv-slider__footer-labels"><span></span></div>
                      <div class="sv-slider__footer-actions"><span class="sv-slider__footer-action sv--save-inline"><button type="button" class="sv-favourite"><span>Save</span></button></span></div>
                    </div>
                  </div>
                  <div class="sv-details">
                    <div class="sv-details__content"><a class="sv-details__link" id="GB37650000105354" href="https://search.savills.com/property-detail/gb37650000105354"></a>
                      <h3 class="sv-details__address1">
                        <div><span class="sv-details__address1--truncate" width="0"><span><span>9-10 Avery Row</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                      </h3>
                      <p class="sv-details__address2">Mayfair, London, W1K 4AL</p>
                      <div class="sv-details__price">
                        <div class="sv-property-price sv--show-size sv--show-rent-basis sv--show-rent-unit sv--show-guide"><span><span class="sv-property-price__guide">Price on application</span> </span>
                          <div class="sv-property-price__rent-basis-fees"><span class="sv-property-price__price-disclaimer">Upon Application</span></div>
                        </div>
                      </div>
                      <div class="sv-details__features">
                        <div class="sv-key-features">
                          <ul class="sv-list sv--bullets">
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>Occupiers
                                      within close proximity include Teds Grooming Room, Watch Finder, Pret a
                                      Manger, Paul Smith, La Petite Maison and Annie Bing</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med"
                                  width="0"><span></span><span><span>Occupiers
                                      within close proximity include Teds Grooming Room, Watch Finder, Pret a
                                      Manger, Paul Smith, La Petite Maison and Annie Bing</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>In
                                      the wider area you have Claridges, the soon to be opened Mandarin
                                      Oriental Residences and the new Browns flagship store at the corner of
                                      Avery Row and Brook Street.</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med" width="0"><span></span><span><span>In
                                      the wider area you have Claridges, the soon to be opened Mandarin
                                      Oriental Residences and the new Browns flagship store at the corner of
                                      Avery Row and Brook Street.</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="sv-details__contacts">
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">James Fairley</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7758 3877">020 7758 3877</a><span class="sv-view-gt-lrg">020 7758 3877</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                      </div>
                    </div>
                    <footer class="sv-details__footer">
                      <div class="sv-details__footer-left">
                        <div class="sv-property-attribute sv--commercial"><span class="sv-property-attribute__value" title="Retail">Retail</span></div>
                      </div>
                      <div class="sv-details__footer-right"></div>
                    </footer>
                  </div>
                </article>
              </div>
              <div class="sv-carousel__slide" style="min-width: 316px;">
                <article class="sv-property-card sv--type-commercial sv--search-type-rent sv--status-available    sv--secondary-type-retail">
                  <div class="sv-slider">
                    <div><a class="sv-details__link" id="GB37650000099906" href="https://search.savills.com/property-detail/gb37650000099906"></a>
                      <div>
                        <div style="min-height: 1px;"></div>
                      </div>
                    </div><span class="sv-slider__footer sv--bg"></span>
                    <div class="sv-slider__footer">
                      <div class="sv-slider__footer-labels"><span></span></div>
                      <div class="sv-slider__footer-actions"><span class="sv-slider__footer-action sv--save-inline"><button type="button" class="sv-favourite"><span>Save</span></button></span></div>
                    </div>
                  </div>
                  <div class="sv-details">
                    <div class="sv-details__content"><a class="sv-details__link" id="GB37650000099906" href="https://search.savills.com/property-detail/gb37650000099906"></a>
                      <h3 class="sv-details__address1">
                        <div><span class="sv-details__address1--truncate" width="0"><span><span>35 Brook Street</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                      </h3>
                      <p class="sv-details__address2">Mayfair, London, W1K 4HQ</p>
                      <div class="sv-details__price">
                        <div class="sv-property-price sv--show-size sv--show-rent-basis sv--show-rent-unit sv--show-guide"><span><span class="sv-property-price__guide">Price on application</span> </span>
                          <div class="sv-property-price__rent-basis-fees"></div>
                        </div>
                      </div>
                      <div class="sv-details__features">
                        <div class="sv-key-features">
                          <ul class="sv-list sv--bullets">
                            <li>
                              <div><span class="sv-view-lt-med" width="0"><span></span><span><span>A1 Unit to Let</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span><span class="sv-view-gt-med"
                                  width="0"><span></span><span><span>A1 Unit to Let</span></span><span style="position: fixed; visibility: hidden; top: 0px; left: 0px;">…</span></span></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="sv-details__contacts">
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">Peter Thomas</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7499 8644">020 7499 8644</a><span class="sv-view-gt-lrg">020 7499 8644</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                        <div>
                          <div class="sv-details__contacts-photo"></div><address>
                            <h6 class="sv-details__contacts-name">James Fairley</h6>
                            <p class="sv-details__contacts-location">Head Office – London</p>
                            <p class="sv-details__contacts-phone"><a class="sv-view-lt-lrg" href="tel: 020 7758 3877">020 7758 3877</a><span class="sv-view-gt-lrg">020 7758 3877</span></p><button type="button"
                              class="sv-details__contacts-link">Contact now</button>
                          </address>
                        </div>
                      </div>
                    </div>
                    <footer class="sv-details__footer">
                      <div class="sv-details__footer-left">
                        <div class="sv-property-attribute sv--commercial"><span class="sv-property-attribute__value" title="Retail">Retail</span></div>
                      </div>
                      <div class="sv-details__footer-right"></div>
                    </footer>
                  </div>
                </article>
              </div>
            </div><button class="sv-carousel__nav sv--next" type="button">Next</button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <footer class="sv-footer">
        <div class="sv-contained">
          <div class="sv-footer__header">
            <div class="sv-footer__inner">


              <div class="sv-newsletter-signup sv-js-modules" data-sv-modules="newsletterSignup" style="display:none;">
                <div class="sv-newsletter-signup__container">
                  <span class="sv-newsletter-signup__label">
                    <h2>Subscribe to receive exclusive news and listings</h2>
                  </span>
                  <div class="sv-newsletter-signup__form-container sv-js-modules" data-sv-modules="validation" novalidate="novalidate">
                    <div class="sv-newsletter-signup__form-elements-container">
                      <span class="sv-newsletter-signup__form-item sv-newsletter-signup__name">

                        <div class="sv-custom-input sv-js-modules sv-input-optional" data-sv-modules="textInput">

                          <input id="vx_form_element_1711" name="VxForm_" placeholder="Name" type="text" data-is-clean="true">
                        </div>
                      </span>
                      <span class="sv-newsletter-signup__form-item sv-newsletter-signup__email">

                        <div class="sv-custom-input sv-js-modules" data-sv-modules="textInput">

                          <input id="email_721" name="VxForm_" placeholder="Email Address*" type="email" data-sv-validation-rules="email" data-is-clean="true">
                        </div>
                      </span> free
                    </div>
                    <span class="sv-newsletter-signup__form-item sv-newsletter-signup__submit">

                      <button type="submit" name="button_1500641402420" class="sv-button" data-sv-validation-trigger="true" disabled="disabled"> Send</button>
                    </span>
                  </div>
                </div>
                <div class="sv-newsletter-signup__result">
                  <div class="sv-newsletter-signup__success">
                    <h2>Success! You are now subscribed to the Savills mailing list.</h2>
                  </div>
                  <div class="sv-newsletter-signup__failure">
                    <h2>Sorry, something went wrong.</h2>
                  </div>
                </div>
              </div>




            </div>
          </div>
          <div class="sv-footer__body">
            <div class="sv-footer__inner">
              <div class="sv-col sv--med-3 sv-footer__info">
                <h2 id="txt_1001" class="vx_text vx_include_footer sv-footer__heading">Residential Property</h2>
                <div class="sv-col sv--xsml-6 sv--sml-12 sv--med-6">
                  <h3 id="txt_1004" class="vx_text vx_include_footer sv-footer__subheading">For sale</h3>
                  <ul class="sv-footer__list">
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/find-residential-property/" title="Search for property for sale">Property for sale</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/property-for-sale/scotland" title="Property for sale Scotland">Property for sale Scotland</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/property-for-sale/england" title="Property for sale England">Property for sale England</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/property-for-sale/wales" title="Property for sale Wales">Property for sale Wales</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/property-for-sale/england/london/london/ec3n" title="Property for sale in London">Property for sale in London</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/help-to-buy-for-sale/england/london/london/ec3n" title="Help to Buy in London">Help to Buy in London</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/help-to-buy-for-sale/uk" title="Help to Buy in UK">Help to Buy in UK</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/new-homes-for-sale/england" title="New developments for sale in England">New developments for sale in England</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/new-homes-for-sale/england/london/london/ec3n" title="New developments for sale in London">New developments for sale in London</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/new-homes-for-sale/scotland" title="New developments for sale in Scotland">New developments for sale in Scotland</a>
                    </li>
                  </ul>



                </div>
                <div class="sv-col sv--xsml-6 sv--sml-12 sv--med-6">
                  <h3 id="txt_1004" class="vx_text vx_include_footer sv-footer__subheading">For rent</h3>
                  <ul class="sv-footer__list">
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/property-to-rent/uk" title="Property to rent in UK">Property to rent in UK</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/property-to-rent/scotland" title="Property to rent Scotland">Property to rent Scotland</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/property-to-rent/england" title="Property to rent England">Property to rent England</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/property-to-rent/wales" title="Property to rent Wales">Property to rent Wales</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/property-to-rent/england/london/london/ec3n" title="Property to rent in London">Property to rent in London</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/new-homes-to-rent/england" title="New developments to rent in England">New developments to rent in England</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/new-homes-to-rent/england/london/london/ec3n" title="New developments to rent in London">New developments to rent in London</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/new-homes-to-rent/scotland" title="New developments to rent in Scotland">New developments to rent in Scotland</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="sv-col sv--sml-7 sv--med-5">
                <div class="sv-col sv--sml-6">
                  <h2 id="txt_1002" class="vx_text vx_include_footer sv-footer__heading">Commercial Property</h2>
                  <div class="sv-col sv--xsml-6">
                    <h3 id="txt_1003" class="vx_text vx_include_footer sv-footer__subheading">For sale</h3>
                    <ul class="sv-footer__list">
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/find-commercial-property/" title="Commercial property for sale">Commercial property for sale</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://search.savills.com/list/commercial/property-for-sale/development-land/uk" title="Development property for sale">Development property for sale</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://search.savills.com/list/commercial/property-for-sale/industrial/uk" title="Industrial properties for sale">Industrial properties for sale</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://search.savills.com/list/commercial/property-for-sale/leisure/uk" title="Leisure properties for sale">Leisure properties for sale</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://search.savills.com/list/commercial/property-for-sale/office/uk" title="Offices for sale">Offices for sale</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://search.savills.com/list/commercial/property-for-sale/hotel/uk" title="Hotels for sale">Hotels for sale</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://search.savills.com/list/commercial/property-for-sale/development-land/uk" title="Land for sale">Land for sale</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://search.savills.com/list/commercial/property-for-sale/industrial/england" title="Industrial property for sale England">Industrial property for sale England</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://search.savills.com/list/commercial/property-for-sale/hotel/england" title="Hotels for sale in England">Hotels for sale in England</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://search.savills.com/list/commercial/property-for-sale/office/england" title="Offices for sale in England">Offices for sale in England</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.workthere.com/" title="Flexible office space">Flexible office space</a>
                      </li>
                    </ul>
                  </div>
                  <div class="sv-col sv--xsml-6">
                    <h3 id="txt_1004" class="vx_text vx_include_footer sv-footer__subheading">For rent</h3>
                    <ul class="sv-footer__list">
                      <li class="sv-footer__list-item">
                        <a href="https://search.savills.com/list/commercial/property-to-let/office/uk" title="Office property to let">Office property to let</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://search.savills.com/list/commercial/property-to-let/industrial/uk" title="Industrial property to let">Industrial property to let</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://search.savills.com/list/commercial/property-to-let/leisure/uk" title="Pubs to let">Pubs to let</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="sv-col sv--sml-6">
                  <h2 id="txt_1008" class="vx_text vx_include_footer sv-footer__heading">Rural Property</h2>
                  <ul class="sv-footer__list">
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/find-rural-property/" title="Rural property for sale">Rural property for sale</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/farms/farms-for-sale/uk" title="Farms for sale">Farms for sale</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/farms/equestrian-property-for-sale/uk" title="Equestrian property for sale">Equestrian property for sale</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/farms/estates-for-sale/uk" title="Country estates for sale">Country estates for sale</a>
                    </li>
                  </ul>
                  <br>
                  <h2 id="txt_1010" class="vx_text vx_include_footer sv-footer__heading">International</h2>
                  <ul class="sv-footer__list">
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/property-for-sale/europe" title="Property for sale in Europe">Property for sale in Europe</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/property-for-sale/france" title="Property for sale in France">Property for sale in France</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/property-for-sale/united-states-of-america" title="Property for sale in the US">Property for sale in the US</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/property-for-sale/italy" title="Property for sale in Italy">Property for sale in Italy</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://search.savills.com/list/property-for-sale/australia/aud" title="Property for sale in Australia">Property for sale in Australia</a>
                    </li>
                  </ul>
                  <br>
                  <h2 id="txt_1010" class="vx_text vx_include_footer sv-footer__heading">Offices</h2>
                  <ul class="sv-footer__list">
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/talk-to-us/office.aspx" title="All UK offices">All UK offices</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/estate-agents/offices-by-county.aspx" title="UK offices by county">UK offices by county</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/talk-to-us/office.aspx?page=1&amp;countrySiteId=3197&amp;sectorId=&amp;sortOrder=asc&amp;nameSearchValue=&amp;lon=&amp;lat=&amp;location=London" title="London Offices">London Offices</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/talk-to-us/office.aspx" title="Find an office worldwide">Find an office worldwide</a>
                    </li>
                  </ul>
                  <div class="sv-social__action sv-onthemarket"></div>

                  <picture><img class="sv-lazy-load" src="https://www.savills.co.uk/_images/REGULATED-BY-RICS-LOGO_WHITE-01.svg" alt="RICS" width="160px"
                      height="64px"><noscript><img src="https://www.savills.co.uk/_images/REGULATED-BY-RICS-LOGO_WHITE-01.svg" alt="RICS" height="64px" width="160px"></noscript></picture>

                </div>
              </div>
              <div class="sv-col sv--sml-5 sv--med-4">
                <div class="sv-col sv--sml-6">
                  <h2 id="txt_1005" class="vx_text vx_include_footer sv-footer__heading">Services &amp; Sectors</h2>
                  <div class="sv-col sv--xsml-6">
                    <h3 id="txt_1006" class="vx_text vx_include_footer sv-footer__subheading">Services</h3>
                    <ul class="sv-footer__list">
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/services/asset-management.aspx" title="Asset Management">Asset Management</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/services/buying-or-selling.aspx" title="Buying or Selling Property">Buying or Selling Property</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/services/consultancy.aspx" title="Consultancy">Consultancy</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/services/development.aspx" title="Development">Development</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/services/finance.aspx" title="Finance">Finance</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/services/investment.aspx" title="Investment">Investment</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/services/letting-or-renting.aspx" title="Letting or Renting">Letting or Renting</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/services/occupier-services.aspx" title="Occupier Services">Occupier Services</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/services/planning.aspx" title="Planning">Planning</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/services/property-management.aspx" title="Property Management">Property Management</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/services/valuation.aspx" title="Valuation">Valuation</a>
                      </li>
                    </ul>
                  </div>
                  <div class="sv-col sv--xsml-6">
                    <h3 id="txt_1007" class="vx_text vx_include_footer sv-footer__subheading">Sectors</h3>
                    <ul class="sv-footer__list">
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/affordable-housing.aspx" title="Affordable Housing">Affordable Housing</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/central-london-retail.aspx" title="Central London Retail">Central London Retail</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/education.aspx" title="Education">Education</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/energy-and-sustainability.aspx" title="Energy">Energy &amp; Sustainability</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/food-and-farming.aspx" title="Food and Farming">Food and Farming</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/healthcare.aspx" title="Healthcare">Healthcare</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/hotels.aspx" title="Hotels">Hotels</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/industrial-and-logistics.aspx" title="Industrial And logistics">Industrial And logistics</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/infrastructure-and-transport.aspx" title="Infrastructure and Transport">Infrastructure and Transport</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/leisure.aspx" title="Leisure">Leisure</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/minerals-and-commodities.aspx" title="Minerals and Commodities">Minerals and Commodities</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/office-and-business-space.aspx" title="Office Space">Office &amp; Business Space</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/public-sector.aspx" title="Public Sector">Public Sector</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/residential.aspx" title="Residential">Residential</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/retail.aspx" title="Retail">Retail</a>
                      </li>

                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/rural.aspx" title="Rural">Rural</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/senior-living.aspx" title="Senior Living">Senior Living</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/serviced-offices.aspx" title="Serviced Offices">Serviced Offices</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/student-accommodation.aspx" title="Student Accommodation">Student Accommodation</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/telecoms.aspx" title="Telecoms">Telecoms</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/trade-related-and-specialist-sectors.aspx" title="Trade-Related and Specialist Sectors">Trade-Related &amp; Specialist Sectors</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/utilities.aspx" title="Utilities">Utilities</a>
                      </li>
                      <li class="sv-footer__list-item">
                        <a href="https://www.savills.co.uk/sectors/waste-management.aspx" title="Waste Management">Waste Management</a>
                      </li>

                    </ul>
                  </div>
                </div>

                <div class="sv-col sv--sml-6">
                  <h2 id="txt_1010" class="vx_text vx_include_footer sv-footer__heading">More Information</h2>
                  <ul class="sv-footer__list">
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/footer/site-map.aspx" title="Sitemap">Sitemap</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/footer/rss-feeds.aspx" title="RSS Feeds">RSS Feeds</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/footer/accessibility.aspx" title="Accessibility">Accessibility</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/footer/feedback.aspx" title="Feedback">Feedback</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://pdf.savills.com/documents/what-is-the-tenancy-deposit-scheme.pdf" title="TDS Explained">TDS Explained</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/footer/provision-of-services-regulations.aspx" title="Provision of Services">Provision of Services</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/footer/slavery-and-human-trafficking-statement.aspx" title="Slavery &amp; Human Trafficking">Slavery &amp; Human Trafficking</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/footer/terms-and-conditions.aspx" title="Terms &amp; Conditions">Terms &amp; Conditions</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/footer/privacy-policy.aspx" title="Privacy &amp; Cookies">Privacy &amp; Cookies</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/footer/client-money-protection.aspx" title="Client Money Protection">Client Money Protection</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.gov.scot/publications/letting-agent-code-practice/" target="_blank" title="Scotland Letting Agent Code of Practice">Scotland Letting Agent Code of Practice</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/resources-and-tools/help-to-buy-calculator.aspx" title="Help to Buy Calculator">Help to Buy Calculator</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/resources-and-tools/stamp-duty-calculator.aspx" title="Stamp Duty Calculator">Stamp Duty Calculator</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/resources-and-tools/lbtt-calculator.aspx" title="Land &amp; Buildings Transaction Tax (LBTT) Calculator">Land &amp; Buildings Transaction Tax (LBTT) Calculator</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/why-savills/esg.aspx" title="ESG at Savills">ESG at Savills</a>
                    </li>
                  </ul>
                  <br>
                  <h2 id="txt_1010" class="vx_text vx_include_footer sv-footer__heading">Fees &amp; Charges</h2>
                  <ul class="sv-footer__list">
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/services/letting-or-renting/residential-tenant-fees-and-charges.aspx" title="Residential Tenant Fees &amp; Charges">Residential Tenant Fees &amp; Charges</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/services/letting-or-renting/residential-landlord-fees-and-charges.aspx" title="Residential Landlord Fees &amp; Charges">Residential Landlord Fees &amp; Charges</a>
                    </li>
                  </ul>
                  <br>
                  <h2 id="txt_1010" class="vx_text vx_include_footer sv-footer__heading">Talk to Us</h2>
                  <ul class="sv-footer__list">
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/talk-to-us/office.aspx" title="Find a Savills Office">Find a Savills Office</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/talk-to-us/people.aspx" title="Find a Savills Employee">Find a Savills Employee</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/forms/market-appraisal.aspx?id=175056" title="Book a Property Valuation">Book a Property Valuation</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/talk-to-us/careers.aspx" title="Careers with Savills">Careers with Savills</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://www.savills.co.uk/forms/general-enquiries.aspx" title="Ask a Question">Ask a Question</a>
                    </li>
                    <li class="sv-footer__list-item">
                      <a href="https://ir.savills.com/" title="Investor Relations">Investor Relations</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sv-footer__footer">
          <div class="sv-contained">
            <div class="sv-footer__inner">
              <div class="sv-col sv--sml-7 sv--med-8">
                <p id="txt_1012" class="vx_text vx_include_footer sv-smallprint">All content © copyright <script type="text/javascript">
                    document.write(new Date().getFullYear());
                  </script>2022
                  Savills. All rights reserved. Savills plc, incorporated and registered
                  in England and Wales. Registered office: 33 Margaret Street, London, W1G
                  0JD. Registered number: 2122174. Savills plc is a holding company, some
                  of whose subsidiaries are authorised and regulated by the Financial
                  Conduct Authority (FCA), and some of whose subsidiaries are regulated by
                  the Royal Institution of Chartered Surveyors (RICS).
                </p>
              </div>
              <div class="sv-col sv--sml-5 sv--med-4">
                <div class="sv-social sv--inverse">
                  <h2 id="txt_1013" class="vx_text vx_include_footer sv-social__heading">Find us on social</h2>
                  <ul class="sv-social__group">
                    <li class="sv-social__item">
                      <a href="https://twitter.com/savills" target="_blank" class="sv-social__action sv--twitter">Twitter</a>
                    </li>
                    <li class="sv-social__item">
                      <a href="https://www.linkedin.com/company/savills" target="_blank" class="sv-social__action sv--linkedin">LinkedIn</a>
                    </li>
                    <li class="sv-social__item">
                      <a href="https://www.instagram.com/savills" target="_blank" class="sv-social__action sv--instagram"> Instagram</a>
                    </li>
                    <li class="sv-social__item">
                      <a href="https://www.facebook.com/Savills/" target="_blank" class="sv-social__action sv--facebook">Facebook</a>
                    </li>
                    <li class="sv-social__item">
                      <a href="https://www.youtube.com/user/SavillsUK" target="_blank" class="sv-social__action sv--youtube">YouTube</a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
  <div id="modal-root"></div>
  <script id="__NEXT_DATA__" type="application/json">
    {
      "dataManager": "[]",
      "props": {
        "routes": {
          "property-search": "/property-search",
          "list": "/list",
          "propertyDetail": "/property-detail",
          "account": "/account",
          "forgot": "/forgot",
          "forgotReset": "/forgot-reset",
          "unsubscribe": "/account/unsubscribe",
          "isolatedSearch": "/isolated-search"
        },
        "pageProps": {},
        "initialReduxState": {
          "global": {
            "localization": {
              "countryCode": "gb",
              "languageCode": "en",
              "marketProperty": ["RES", "FES", "COM"],
              "privateArea": "",
              "routes": {
                "property-search": "/property-search",
                "list": "/list",
                "propertyDetail": "/property-detail",
                "account": "/account",
                "forgot": "/forgot",
                "forgotReset": "/forgot-reset",
                "unsubscribe": "/account/unsubscribe",
                "isolatedSearch": "/isolated-search"
              }
            },
            "authenticationEndpoint": "https://livev6-authentication.savills.com",
            "cmsDomain": "https://www.savills.co.uk",
            "contentEndpoint": "https://assets.savills.com",
            "countryConfigEndpoint": "https://assets.savills.com/static/country-configs",
            "dataEndpoint": "https://livev6-searchapi.savills.com",
            "dialogEndpoint": "https://livev6-dialog.savills.com",
            "fallbackImagesEndPoint": "https://assets.savills.com/awaiting/",
            "googleMapsApiKey": "key=AIzaSyDt9o_IDTfi-PEeGs4psHxMBUlmx69k-Eo",
            "googleMapsApiVersion": "quarterly",
            "headerFooterEndpoint": "http://admin.euro.savills.co.uk/api-endpoints/header-and-footer-endpoint.aspx?location=LOCATION\u0026language=LANGUAGE",
            "profileEndpoint": "https://livev6-profile.savills.com",
            "searchDomain": "https://search.savills.com",
            "cacheEnabled": true,
            "msTrackingApiScript": "https://assets.savills.com/static/tracking/ping.js",
            "trackingEnabled": true,
            "googleTagManagerKey": "GTM-NLWT6FC",
            "cmsPages": [{
              "url": "https://www.savills.co.uk/find-a-property/",
              "text": "Find a property",
              "id": "find-a-property",
              "showOnError": true
            }, {
              "url": "https://www.savills.co.uk/market-your-property/",
              "text": "Market your property",
              "id": "market-your-property-promo",
              "showOnError": true
            }, {
              "url": "https://www.savills.co.uk/find-a-service/",
              "text": "Find a service",
              "id": "find-a-service",
              "showOnError": true
            }, {
              "url": "https://www.savills.co.uk/why-savills/",
              "text": "Why Savills",
              "id": "why-savills",
              "showOnError": true
            }, {
              "url": "https://www.savills.co.uk/insight-and-opinion/",
              "text": "Insight \u0026 opinion",
              "id": "insight-and-opinion",
              "showOnError": true
            }, {
              "url": "https://www.savills.co.uk/contact-us/",
              "text": "Contact us",
              "id": "contact-us",
              "showOnError": true
            }, {
              "url": "https://www.savills.co.uk/footer/privacy-policy.aspx",
              "text": "Privacy policy",
              "id": "privacy-policy",
              "showOnError": false
            }, {
              "url": "https://www.savills.co.uk/landing-pages/shared-ownership-eligibility.aspx",
              "text": "Check your eligibility",
              "id": "shared-ownership",
              "showOnError": false
            }],
            "entranceRoute": "property-detail/gb37650000104692",
            "criticalRoute": "propertyDetail",
            "socialNetworks": [{
              "title": "Facebook",
              "className": "facebook",
              "url": "https://www.facebook.com/sharer/sharer.php?u=\u003curl\u003e"
            }, {
              "title": "Twitter",
              "className": "twitter",
              "url": "https://twitter.com/intent/tweet?url=\u003curl\u003e"
            }, {
              "title": "LinkedIn",
              "className": "linkedin",
              "url": "https://www.linkedin.com/shareArticle?url=\u003curl\u003e"
            }, {
              "title": "Pinterest",
              "className": "pinterest",
              "url": "http://pinterest.com/pin/create/button/?url=\u003curl\u003e"
            }, {
              "title": "Weibo",
              "className": "weibo",
              "url": "http://service.weibo.com/share/share.php?url=\u003curl\u003e\u0026pic=http%3A%2F%2Fwww.savills.com.cn%2F_images%2Flogo_savills.gif\u0026searchPic=true\u0026style=simple"
            }],
            "appVersion": "4.1.0",
            "fallbackImages": {
              "agent": {
                "ImageUrl_S": "https://assets.savills.com/awaiting/AwaitingImage_Agent_S.jpg",
                "ImageUrl_M": "https://assets.savills.com/awaiting/AwaitingImage_Agent_M.jpg",
                "ImageUrl_L": "https://assets.savills.com/awaiting/AwaitingImage_Agent_L.jpg"
              },
              "card": {
                "ImageUrl_S": "https://assets.savills.com/awaiting/ENAwaitingImage_Card_S.jpg",
                "ImageUrl_M": "https://assets.savills.com/awaiting/ENAwaitingImage_Card_M.jpg",
                "ImageUrl_L": "https://assets.savills.com/awaiting/ENAwaitingImage_Card_L.jpg"
              },
              "gallery": {
                "ImageUrl_S": "https://assets.savills.com/awaiting/ENAwaitingImage_Gallery_S.jpg",
                "ImageUrl_M": "https://assets.savills.com/awaiting/ENAwaitingImage_Gallery_M.jpg",
                "ImageUrl_L": "https://assets.savills.com/awaiting/ENAwaitingImage_Gallery_L.jpg"
              }
            },
            "tempResultsMarketPropertyBanner": "https://www.savills.co.uk/forms/market-appraisal.aspx?id=175056",
            "formConfig": null,
            "regionSwitch": null,
            "selfCanonical": false,
            "accountAltSearchLink": false,
            "grossSaleableSymbols": false
          },
          "account": {
            "errorMsgKey": "",
            "isBusy": false,
            "changePasswordSuccess": false,
            "unsubscribeState": "INIT",
            "forgotPassword": {
              "Success": null
            }
          },
          "profile": {
            "hasMessage": false,
            "id": {},
            "isBusy": false,
            "profileMessage": "",
            "savedProperties": {
              "data": [],
              "hasError": false,
              "isBusy": false
            },
            "savedSearches": [],
            "user": {},
            "updateProfileSuccess": false,
            "GpsProfileId": "ec8ee7f1-47d4-445a-ab1d-6aad4a007cb1"
          },
          "map": {
            "bounds": {
              "Top": 90,
              "Right": 180,
              "Bottom": -90,
              "Left": -180
            },
            "context": {
              "CentreX": -0.12883,
              "CentreY": 51.50748,
              "ZoomLevel": 4
            },
            "infoBox": null,
            "isUpdating": false,
            "pins": {},
            "pois": {
              "airport": false,
              "school": false,
              "station": false
            },
            "searchAreas": {
              "circles": {},
              "polygons": {},
              "multiPolygons": {},
              "areaRequestPending": false
            },
            "size": {
              "HeightPx": 350,
              "WidthPx": 1024
            }
          },
          "searchContext": {
            "UserSetBounds": false
          },
          "propertyDetail": {
            "isBusy": false,
            "isShareModalActive": false,
            "isRequestingRelatedProperties": false,
            "isRequestingNewDevelopmentProperties": false,
            "newDevelopmentProperties": [],
            "property": {
              "ID": 843608,
              "PropertyID": 843608,
              "ExternalPropertyID": "GB37650000104692",
              "ExternalPropertyIDFormatted": "gb37650000104692",
              "BuyOrRent": 1,
              "DetailPageUrl": "/property-detail",
              "AssetBaseUrl": "https://assets.savills.com/",
              "GeoLocationCountryCode": "gb",
              "LocalizedAgent": {
                "Name": null,
                "Phone": null,
                "ImageUrl": null
              },
              "MetaInformation": {
                "CanonicalUrl": "property-detail/gb37650000104692",
                "NextUrl": null,
                "PrevUrl": null,
                "Description": "Property details for 10 Argyll Street. One of many properties to rent in Argyll Street, London, W1F 7TQ from Savills, world leading estate agents."
              },
              "PropertyCardImagesGallery": [{
                "DisplayOrder": 0,
                "ImageUrl": null,
                "ImageUrl_S": "https://assets.savills.com/properties/GB37650000104692/CG_10_Argyll_Street_London_picture_2021-10-18-11-44-13_s_lis.jpg",
                "ImageUrl_M": "https://assets.savills.com/properties/GB37650000104692/CG_10_Argyll_Street_London_picture_2021-10-18-11-44-13_m_lis.jpg",
                "ImageUrl_L": "https://assets.savills.com/properties/GB37650000104692/CG_10_Argyll_Street_London_picture_2021-10-18-11-44-13_l_lis.jpg",
                "Caption": "10 Argyll Street, London - Picture 2021-10-18-11-44-13"
              }],
              "ImagesGallery": [{
                "DisplayOrder": 0,
                "ImageUrl": null,
                "ImageUrl_S": "https://assets.savills.com/properties/GB37650000104692/CG_10_Argyll_Street_London_picture_2021-10-18-11-44-13_s_gal.jpg",
                "ImageUrl_M": "https://assets.savills.com/properties/GB37650000104692/CG_10_Argyll_Street_London_picture_2021-10-18-11-44-13_m_gal.jpg",
                "ImageUrl_L": "https://assets.savills.com/properties/GB37650000104692/CG_10_Argyll_Street_London_picture_2021-10-18-11-44-13_l_gal.jpg",
                "Caption": "10 Argyll Street, London - Picture 2021-10-18-11-44-13"
              }],
              "EpcGallery": [],
              "FloorPlanGallery": [],
              "FloorplanPDFUrl": null,
              "BrochureGallery": [{
                "DisplayOrder": 0,
                "ImageUrl": "https://assets.savills.com/properties/GB37650000104692/CG_10_Argyll_Street_London_brochure_2022-02-04-09-40-47.pdf",
                "ImageUrl_S": null,
                "ImageUrl_M": null,
                "ImageUrl_L": null,
                "Caption": "10 Argyll Street, London - Brochure 2022-02-04-09-40-47"
              }],
              "IsNewDevelopment": false,
              "IsFeatured": false,
              "NewDevelopmentType": 0,
              "IsSaved": false,
              "DisplayPriceText": "£400,000",
              "OriginalPriceText": "£400,000",
              "ShortDisplayPriceText": "£400k",
              "ShortOriginalPriceText": "£400k",
              "BaseCurrency": "GBP",
              "DisplayCurrency": "GBP",
              "ShowPrice": true,
              "OtherFeesText": "",
              "OtherFeesLink": "",
              "Description": "Prime Unit To Let With Full Restaurant Consent",
              "Location": [
                "Argyll Street is located in the heart of the West End. It is an extremely busy thoroughfare which links Oxford Street to the north with Great Marlborough Street to the south but also provides access to Regent Street via Little Argyll Street.",
                "There is considerable footfall which has been created by the diverse leisure and retail offer in the immediate surrounding area, which include, Liberty of London, London Palladium and Aqua"
              ],
              "LongDescription": [{
                "Head": "",
                "Body": "\u003cp\u003e\u003cstrong\u003eDescription\u003c/strong\u003e\u003c/p\u003e\u003cp\u003eThe property is arranged over ground and basement floors\u003c/p\u003e\u003cp\u003e\u003cstrong\u003eUse Class\u003c/strong\u003e\u003c/p\u003e\u003cp\u003eE\u003c/p\u003e\u003cp\u003e\u003cstrong\u003eLease Type\u003c/strong\u003e\u003c/p\u003e\u003cp\u003eNew Lease\u003c/p\u003e\u003cp\u003e\u003cstrong\u003eLease Term\u003c/strong\u003e\u003c/p\u003e\u003cp\u003eThe unit is available on a new lease for a term to be agreed\u003c/p\u003e\u003cp\u003e\u003cstrong\u003eCosts\u003c/strong\u003e\u003c/p\u003e\u003cp\u003eEach party is responsible for their own legal and professional costs incurred in the transaction\u003c/p\u003e\u003cp\u003e\u003cstrong\u003eRates\u003c/strong\u003e\u003c/p\u003e\u003cp\u003eParties are advised to make their own enquires to verify the rates payable and whether rates relief applies by contacting the local authority\u003c/p\u003e"
              }],
              "AdditionalInformation": [""],
              "AreaGuide": {
                "AreaGuideId": 1,
                "AreaGuideBaseTitle": "AREA GUIDES",
                "AreaGuideTitle": "Discover Richmond Upon Thames",
                "AreaGuideDescription": "Explore Richmond one of London's most picturesque boroughs and favourite retreat of royalty. Truely a jewel in London's crown. ",
                "AreaGuideImageUrl": "http://dev-search.savills.com/content/img/MockedAreaGuide.PNG",
                "AreaGuideDetailCaption": "Read the area guide",
                "AreaGuideDetailUrl": "http://savills.co.uk"
              },
              "QuoteText": "",
              "PointsOfInterest": [],
              "ShowFacebook": true,
              "HasFloorplan": false,
              "VideoUrl": {
                "URL": null,
                "CaptionTranslations": "{}",
                "UrlCaption": ""
              },
              "Video360": {
                "URL": null,
                "CaptionTranslations": "{}",
                "UrlCaption": ""
              },
              "ExternalUrl": {
                "URL": null,
                "CaptionTranslations": "{}",
                "UrlCaption": null
              },
              "LetTypeFormatted": "",
              "LaunchingDate": null,
              "OwnershipTypeDescription": null,
              "OwnershipType": "-1",
              "TenureType": "GRS_T_R",
              "HasOpenHouse": false,
              "OpenHouse": "",
              "IsManaged": false,
              "IsFarm": false,
              "IsAgency": false,
              "ManagedProperty": null,
              "PriceRentalFormatted": null,
              "InvariantFullPriceText": "Guide price £33,333",
              "CreateDate": "0001-01-01T00:00:00",
              "RentBasisDescription": "per year",
              "AddressLine1": "10 Argyll Street",
              "AddressLine2": "Argyll Street, London, W1F 7TQ",
              "WebFeatureList": ["The property is a short distance from luxury fashion retailers that include the new Canada Goose flagship Cos and the new Lavazza Flagship"],
              "FurnishedFormatted": "Unfurnished",
              "PrimaryAgent": {
                "ID": 510523,
                "SystemName": "TCG",
                "ExternalAgentID": "106058",
                "Office": {
                  "ID": 6093,
                  "SystemName": "TCG",
                  "ExternalOfficeID": "GB3765",
                  "OfficeName": "Head Office – London",
                  "OfficePhoneIntCode": "+44",
                  "OfficePhoneNumber": "020 7495 3773",
                  "OfficeEmail": null,
                  "LicenceNumber": null,
                  "LicenceType": null,
                  "Address1": null,
                  "Address1Set": {
                    "Translations": {}
                  },
                  "Address2": null,
                  "Address2Set": {
                    "Translations": {}
                  },
                  "Address3": null,
                  "Address3Set": {
                    "Translations": {}
                  },
                  "CityTown": "London",
                  "PostCode": "W1G 0JD",
                  "Country": "London",
                  "Region": null,
                  "Latitude": 51.5119667,
                  "Longitude": -0.146936,
                  "OfficeSeoUrl": "Head-Office-–-London",
                  "OfficeUrlTranslated": "",
                  "OfficeUrl": {
                    "Translations": {}
                  },
                  "OfficeUrlTranslations": "{}",
                  "OfficeNameSet": {
                    "Translations": {
                      "en": "Head Office – London"
                    }
                  }
                },
                "DepartmentPhoneIntCode": "+44",
                "DepartmentPhoneNumber": "020 7758 3877",
                "EmailAddress": "jfairley@savills.com",
                "FirstName": null,
                "LastName": null,
                "AgentPhoneIntCode": null,
                "AgentPhoneNumber": "020 7758 3877",
                "AgentImage": {
                  "ID": 609757225,
                  "AssetType": 5,
                  "FileName": "TCG_106058.jpg",
                  "CaptionTranslations": "{}",
                  "Caption": {
                    "Translations": {}
                  },
                  "Order": 0
                },
                "AgentImageUrl": "https://assets.savills.com/agents/TCG_106058_m_ag.jpg",
                "AgentImageAltUrl": "https://assets.savills.com/awaiting/awaitingimage_agent.jpg",
                "LicenceNumber": null,
                "LicenceType": null,
                "LicenceNameTranslation": null,
                "AgentName": "James Fairley",
                "AgentNameSet": {
                  "Translations": {
                    "en": "James Fairley"
                  }
                }
              },
              "SecondaryAgent": {
                "ID": 510885,
                "SystemName": "TCG",
                "ExternalAgentID": "126014",
                "Office": {
                  "ID": 6093,
                  "SystemName": "TCG",
                  "ExternalOfficeID": "GB3765",
                  "OfficeName": "Head Office – London",
                  "OfficePhoneIntCode": "+44",
                  "OfficePhoneNumber": "020 7495 3773",
                  "OfficeEmail": null,
                  "LicenceNumber": null,
                  "LicenceType": null,
                  "Address1": null,
                  "Address1Set": {
                    "Translations": {}
                  },
                  "Address2": null,
                  "Address2Set": {
                    "Translations": {}
                  },
                  "Address3": null,
                  "Address3Set": {
                    "Translations": {}
                  },
                  "CityTown": "London",
                  "PostCode": "W1G 0JD",
                  "Country": "London",
                  "Region": null,
                  "Latitude": 51.5119667,
                  "Longitude": -0.146936,
                  "OfficeSeoUrl": "Head-Office-–-London",
                  "OfficeUrlTranslated": "",
                  "OfficeUrl": {
                    "Translations": {}
                  },
                  "OfficeUrlTranslations": "{}",
                  "OfficeNameSet": {
                    "Translations": {
                      "en": "Head Office – London"
                    }
                  }
                },
                "DepartmentPhoneIntCode": "+44",
                "DepartmentPhoneNumber": "020 7499 8644",
                "EmailAddress": "daboud@savills.com",
                "FirstName": null,
                "LastName": null,
                "AgentPhoneIntCode": null,
                "AgentPhoneNumber": "020 7499 8644",
                "AgentImage": {
                  "ID": 609941241,
                  "AssetType": 5,
                  "FileName": "user.png",
                  "CaptionTranslations": "{}",
                  "Caption": {
                    "Translations": {}
                  },
                  "Order": 0
                },
                "AgentImageUrl": "https://assets.savills.com/agents/user_m_ag.png",
                "AgentImageAltUrl": "https://assets.savills.com/awaiting/awaitingimage_agent.jpg",
                "LicenceNumber": null,
                "LicenceType": null,
                "LicenceNameTranslation": null,
                "AgentName": "Daniel Aboud",
                "AgentNameSet": {
                  "Translations": {
                    "en": "Daniel Aboud"
                  }
                }
              },
              "QuoteContact": null,
              "IsSold": false,
              "IsLet": false,
              "PrimaryAgentOfficeUrl": "#",
              "HasPrimaryAgent": true,
              "Receptions": 0,
              "Bathrooms": 0,
              "CarSpaces": 0,
              "Bedrooms": 0,
              "Size": {
                "Ping": 0,
                "SqMt": 278,
                "SqFt": 3001,
                "Acres": 0,
                "Hectares": 0
              },
              "SizeMaximum": {
                "Ping": 0,
                "SqMt": 0,
                "SqFt": 0,
                "Acres": 0,
                "Hectares": 0
              },
              "AvailableSize": {
                "Ping": 0,
                "SqMt": 0,
                "SqFt": 0,
                "Acres": 0,
                "Hectares": 0
              },
              "AvailableSizeMaximum": {
                "Ping": 0,
                "SqMt": 0,
                "SqFt": 0,
                "Acres": 0,
                "Hectares": 0
              },
              "SizeSqFt": 3001,
              "SizeSqMt": 278,
              "SizePing": 0,
              "SizeAcres": 0,
              "SizeDescription": "3,001 sq ft Retail for let",
              "SizeMaximumSqFt": 0,
              "SizeMaximumSqMt": 0,
              "SizeMaximumPing": 0,
              "SizeMaximumAcres": 0,
              "HeaderSizeFormatted": ["3,001 sq ft", "278 sq m"],
              "FooterSizeFormatted": "3,001 sq ft (278 sq m)",
              "PrioritisedFooterSizeFormatted": {
                "PropertySize": "3,001 sq ft (278 sq m)"
              },
              "SizeFormatted": "3,001 sq ft (278 sq m)",
              "AvailableSizeFormatted": "",
              "SaleableAreaSqFt": 0,
              "SaleableAreaSqMt": 0,
              "SaleableAreaPing": 0,
              "BathroomsFormatted": null,
              "CarSpacesFormatted": null,
              "ReceptionsFormatted": null,
              "BedroomsFormatted": null,
              "YieldFormatted": "",
              "ShortYield": "",
              "SaleableAreaFormatted": null,
              "HideStreetView": false,
              "PolygonSet": null,
              "RentBasis": 2,
              "PropertyStatusFlagTranslation": null,
              "PropertyStatusFlag": 0,
              "PropertyStatusFlagEnum": 0,
              "PropertyPageTitle": "Savills | 10 Argyll Street, Argyll Street, London, W1F 7TQ | Property to rent",
              "IsHelpToBuy": false,
              "IsSharedOwnership": false,
              "Latitude": 51.5148224,
              "Longitude": -0.1406154,
              "IsConfidential": false,
              "IsCommercial": true,
              "ByUnit": [{
                "Area": "Ground Floor",
                "sq ft": "1250.00",
                "sq m": "116.00"
              }, {
                "Area": "Basement",
                "sq ft": "1751.00",
                "sq m": "162.00"
              }],
              "EnergyText": "",
              "LandAreaFormatted": null,
              "FlagLabel": "",
              "PropertyTypes": [{
                "Type": "retail",
                "TypeTag": "R",
                "Caption": "Retail",
                "PriorityWeight": 1
              }],
              "MarketTypes": [],
              "PriceRentUnit": null,
              "PriceDisclaimer": {
                "Href": "",
                "Caption": ""
              },
              "OfficePeople": null,
              "ShortOfficePeople": null,
              "OfficeGrade": null,
              "ShortOfficeGrade": null,
              "PropertyCategoryEnum": 3,
              "Tracking": {
                "searchType": null,
                "noOfResults": null,
                "sortByChanged": null,
                "userType": "guest",
                "userId": null,
                "pageLanguage": "en",
                "pageCountry": "gb",
                "pageCurrency": "GBP",
                "pageType": "property",
                "propertySoldState": "Available",
                "price": "33333.33333",
                "parentPropertyId": null,
                "propertyType": ["retail"],
                "propertyLocation": "Head Office – London",
                "propertyBedrooms": "0",
                "propertyBathrooms": "0",
                "propertyRoomsOther": "0",
                "propertySaveState": "not saved",
                "searchSaveState": null,
                "event": "propertyImpressions",
                "ecommerce": null,
                "propertyCountry": "gb"
              },
              "ParentPropertyID": null,
              "Price": 33333.33333,
              "GuidePriceText": ""
            },
            "relatedProperties": []
          },
          "properties": {},
          "searchLocations": {
            "visibleSection": "",
            "areSelectionsVisible": false,
            "isFocus": false,
            "user": {
              "isBusy": false,
              "error": null
            },
            "results": {
              "isBusy": false,
              "error": null,
              "grouped": {},
              "top": []
            },
            "listings": {
              "isBusy": false,
              "error": null,
              "areCountriesVisible": false,
              "selectedCountryId": "",
              "selectedRegionId": "",
              "selectedRegionMapRef": "",
              "countries": [],
              "regions": [],
              "map": {}
            },
            "selections": [],
            "searchString": "",
            "term": {
              "raw": "",
              "list": []
            }
          },
          "searchPanel": {
            "isOpen": false,
            "yScrollOnOpen": null,
            "regionSwitchDirty": false
          },
          "listPage": {
            "isBusy": false,
            "isNewSearch": false,
            "breadcrumbs": [],
            "totalItems": 0,
            "currentPage": 1,
            "pageMap": {},
            "isSortOrderVisible": false,
            "sortOrderChange": false,
            "error": null,
            "trackingTag": {
              "ecommerce": {
                "impressions": []
              }
            }
          },
          "searchFilters": {
            "isBusy": false,
            "visibleControl": "",
            "analytics": {},
            "baseAnalytics": {},
            "filters": {
              "defaults": {
                "All": {
                  "Currency": "GBP",
                  "HiddenFilters": ["ping"],
                  "DisabledSearchCategories": ["Agent"],
                  "PrioritizedCategoriesOrder": ["cities", "stations", "schools"],
                  "DefaultSearchType": "GRS_CAT_RES",
                  "DefaultCommercialPropertyType": null,
                  "DefaultLocationId": null,
                  "DefaultSortOrder": "SO_PCDD"
                },
                "GRS_CAT_RES": {
                  "Tenure": "GRS_T_B",
                  "Options": {
                    "GRS_T_B": {
                      "CommercialSizeUnit": null,
                      "AvailableSizeUnit": null,
                      "SaleableAreaUnit": "SquareMeter",
                      "Period": null,
                      "ResidentialSizeUnit": "SquareFeet",
                      "LandAreaUnit": "Acre"
                    },
                    "GRS_T_R": {
                      "CommercialSizeUnit": null,
                      "AvailableSizeUnit": null,
                      "SaleableAreaUnit": "SquareMeter",
                      "Period": "Week",
                      "ResidentialSizeUnit": "SquareFeet",
                      "LandAreaUnit": "Acre"
                    }
                  },
                  "DefaultSortOrder": null
                },
                "GRS_CAT_FES": {
                  "Tenure": "GRS_T_B",
                  "Options": {
                    "GRS_T_B": {
                      "CommercialSizeUnit": null,
                      "AvailableSizeUnit": null,
                      "SaleableAreaUnit": null,
                      "Period": null,
                      "ResidentialSizeUnit": null,
                      "LandAreaUnit": "Acre"
                    },
                    "GRS_T_R": {
                      "CommercialSizeUnit": null,
                      "AvailableSizeUnit": null,
                      "SaleableAreaUnit": null,
                      "Period": "Year",
                      "ResidentialSizeUnit": null,
                      "LandAreaUnit": "Acre"
                    }
                  },
                  "DefaultSortOrder": null
                },
                "GRS_CAT_COM": {
                  "GRS_CPT_D": {
                    "CommercialPropertyType": "GRS_CPT_D",
                    "DefaultSortOrder": null,
                    "Tenure": "GRS_T_B",
                    "Options": {
                      "GRS_T_B": {
                        "CommercialSizeUnit": "Acre",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      },
                      "GRS_T_R": {
                        "CommercialSizeUnit": "Acre",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      }
                    }
                  },
                  "GRS_CPT_I": {
                    "CommercialPropertyType": "GRS_CPT_I",
                    "DefaultSortOrder": null,
                    "Tenure": "GRS_T_R",
                    "Options": {
                      "GRS_T_B": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      },
                      "GRS_T_R": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      }
                    }
                  },
                  "GRS_CPT_L": {
                    "CommercialPropertyType": "GRS_CPT_L",
                    "DefaultSortOrder": null,
                    "Tenure": "GRS_T_B",
                    "Options": {
                      "GRS_T_B": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      },
                      "GRS_T_R": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      }
                    }
                  },
                  "GRS_CPT_O": {
                    "CommercialPropertyType": "GRS_CPT_O",
                    "DefaultSortOrder": null,
                    "Tenure": "GRS_T_R",
                    "Options": {
                      "GRS_T_B": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": "SquareFeet",
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      },
                      "GRS_T_R": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": "SquareFeet",
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      }
                    }
                  },
                  "GRS_CPT_HO": {
                    "CommercialPropertyType": "GRS_CPT_HO",
                    "DefaultSortOrder": "SO_PD",
                    "Tenure": "GRS_T_B",
                    "Options": {
                      "GRS_T_B": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      },
                      "GRS_T_R": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      }
                    }
                  },
                  "GRS_CPT_H": {
                    "CommercialPropertyType": "GRS_CPT_H",
                    "DefaultSortOrder": null,
                    "Tenure": "GRS_T_B",
                    "Options": {
                      "GRS_T_B": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      },
                      "GRS_T_R": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      }
                    }
                  },
                  "GRS_CPT_OC": {
                    "CommercialPropertyType": "GRS_CPT_OC",
                    "DefaultSortOrder": null,
                    "Tenure": "GRS_T_B",
                    "Options": {
                      "GRS_T_B": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      },
                      "GRS_T_R": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      }
                    }
                  },
                  "GRS_CPT_R": {
                    "CommercialPropertyType": "GRS_CPT_R",
                    "DefaultSortOrder": null,
                    "Tenure": "GRS_T_R",
                    "Options": {
                      "GRS_T_B": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      },
                      "GRS_T_R": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      }
                    }
                  },
                  "GRS_CPT_LAN": {
                    "CommercialPropertyType": "GRS_CPT_LAN",
                    "DefaultSortOrder": null,
                    "Tenure": "GRS_T_B",
                    "Options": {
                      "GRS_T_B": {
                        "CommercialSizeUnit": "Acre",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      },
                      "GRS_T_R": {
                        "CommercialSizeUnit": "Acre",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      }
                    }
                  },
                  "GRS_CPT_IN": {
                    "CommercialPropertyType": "GRS_CPT_IN",
                    "DefaultSortOrder": null,
                    "Tenure": "GRS_T_B",
                    "Options": {
                      "GRS_T_B": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      },
                      "GRS_T_R": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      }
                    }
                  },
                  "GRS_CPT_SO": {
                    "CommercialPropertyType": "GRS_CPT_SO",
                    "DefaultSortOrder": null,
                    "Tenure": "GRS_T_R",
                    "Options": {
                      "GRS_T_B": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": "SquareFeet",
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      },
                      "GRS_T_R": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": "SquareFeet",
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      }
                    }
                  },
                  "GRS_CPT_SNL": {
                    "CommercialPropertyType": "GRS_CPT_SNL",
                    "DefaultSortOrder": null,
                    "Tenure": "GRS_T_B",
                    "Options": {
                      "GRS_T_B": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      },
                      "GRS_T_R": {
                        "CommercialSizeUnit": "SquareFeet",
                        "AvailableSizeUnit": null,
                        "SaleableAreaUnit": null,
                        "Period": "Year",
                        "ResidentialSizeUnit": null,
                        "LandAreaUnit": null
                      }
                    }
                  }
                }
              },
              "definitions": {
                "PriceInterval_Buy": {
                  "AED": {
                    "ID": "AED",
                    "Description": "AED",
                    "Index": 0
                  },
                  "ARS": {
                    "ID": "ARS",
                    "Description": "ARS",
                    "Index": 1
                  },
                  "AUD": {
                    "ID": "AUD",
                    "Description": "AUD",
                    "Index": 2
                  },
                  "BGN": {
                    "ID": "BGN",
                    "Description": "BGN",
                    "Index": 3
                  },
                  "BHD": {
                    "ID": "BHD",
                    "Description": "BHD",
                    "Index": 4
                  },
                  "BND": {
                    "ID": "BND",
                    "Description": "BND",
                    "Index": 5
                  },
                  "BRL": {
                    "ID": "BRL",
                    "Description": "BRL",
                    "Index": 6
                  },
                  "BWP": {
                    "ID": "BWP",
                    "Description": "BWP",
                    "Index": 7
                  },
                  "CAD": {
                    "ID": "CAD",
                    "Description": "CAD",
                    "Index": 8
                  },
                  "CHF": {
                    "ID": "CHF",
                    "Description": "CHF",
                    "Index": 9
                  },
                  "CLP": {
                    "ID": "CLP",
                    "Description": "CLP",
                    "Index": 10
                  },
                  "CNY": {
                    "ID": "CNY",
                    "Description": "CNY",
                    "Index": 11
                  },
                  "COP": {
                    "ID": "COP",
                    "Description": "COP",
                    "Index": 12
                  },
                  "CZK": {
                    "ID": "CZK",
                    "Description": "CZK",
                    "Index": 13
                  },
                  "DKK": {
                    "ID": "DKK",
                    "Description": "DKK",
                    "Index": 14
                  },
                  "EGP": {
                    "ID": "EGP",
                    "Description": "EGP",
                    "Index": 15
                  },
                  "EUR": {
                    "ID": "EUR",
                    "Description": "EUR",
                    "Index": 16
                  },
                  "GBP": {
                    "ID": "GBP",
                    "Description": "GBP",
                    "Options": {
                      "MinPrice": {
                        "ID": "MinPrice",
                        "Description": "minimum",
                        "ShortDescription": "min",
                        "Options": {
                          "100000": {
                            "ID": "100000",
                            "Description": "£100,000",
                            "ShortDescription": "£100k",
                            "Index": 1
                          },
                          "200000": {
                            "ID": "200000",
                            "Description": "£200,000",
                            "ShortDescription": "£200k",
                            "Index": 2
                          },
                          "300000": {
                            "ID": "300000",
                            "Description": "£300,000",
                            "ShortDescription": "£300k",
                            "Index": 3
                          },
                          "400000": {
                            "ID": "400000",
                            "Description": "£400,000",
                            "ShortDescription": "£400k",
                            "Index": 4
                          },
                          "500000": {
                            "ID": "500000",
                            "Description": "£500,000",
                            "ShortDescription": "£500k",
                            "Index": 5
                          },
                          "600000": {
                            "ID": "600000",
                            "Description": "£600,000",
                            "ShortDescription": "£600k",
                            "Index": 6
                          },
                          "700000": {
                            "ID": "700000",
                            "Description": "£700,000",
                            "ShortDescription": "£700k",
                            "Index": 7
                          },
                          "800000": {
                            "ID": "800000",
                            "Description": "£800,000",
                            "ShortDescription": "£800k",
                            "Index": 8
                          },
                          "900000": {
                            "ID": "900000",
                            "Description": "£900,000",
                            "ShortDescription": "£900k",
                            "Index": 9
                          },
                          "1000000": {
                            "ID": "1000000",
                            "Description": "£1,000,000",
                            "ShortDescription": "£1m",
                            "Index": 10
                          },
                          "1250000": {
                            "ID": "1250000",
                            "Description": "£1,250,000",
                            "ShortDescription": "£1.25m",
                            "Index": 11
                          },
                          "1500000": {
                            "ID": "1500000",
                            "Description": "£1,500,000",
                            "ShortDescription": "£1.5m",
                            "Index": 12
                          },
                          "1750000": {
                            "ID": "1750000",
                            "Description": "£1,750,000",
                            "ShortDescription": "£1.75m",
                            "Index": 13
                          },
                          "2000000": {
                            "ID": "2000000",
                            "Description": "£2,000,000",
                            "ShortDescription": "£2m",
                            "Index": 14
                          },
                          "2500000": {
                            "ID": "2500000",
                            "Description": "£2,500,000",
                            "ShortDescription": "£2.5m",
                            "Index": 15
                          },
                          "3000000": {
                            "ID": "3000000",
                            "Description": "£3,000,000",
                            "ShortDescription": "£3m",
                            "Index": 16
                          },
                          "4000000": {
                            "ID": "4000000",
                            "Description": "£4,000,000",
                            "ShortDescription": "£4m",
                            "Index": 17
                          },
                          "5000000": {
                            "ID": "5000000",
                            "Description": "£5,000,000",
                            "ShortDescription": "£5m",
                            "Index": 18
                          },
                          "7500000": {
                            "ID": "7500000",
                            "Description": "£7,500,000",
                            "ShortDescription": "£7.5m",
                            "Index": 19
                          },
                          "10000000": {
                            "ID": "10000000",
                            "Description": "£10,000,000",
                            "ShortDescription": "£10m",
                            "Index": 20
                          },
                          "15000000": {
                            "ID": "15000000",
                            "Description": "£15,000,000",
                            "ShortDescription": "£15m",
                            "Index": 21
                          },
                          "20000000": {
                            "ID": "20000000",
                            "Description": "£20,000,000",
                            "ShortDescription": "£20m",
                            "Index": 22
                          },
                          "30000000": {
                            "ID": "30000000",
                            "Description": "£30,000,000",
                            "ShortDescription": "£30m",
                            "Index": 23
                          },
                          "40000000": {
                            "ID": "40000000",
                            "Description": "£40,000,000",
                            "ShortDescription": "£40m",
                            "Index": 24
                          },
                          "50000000": {
                            "ID": "50000000",
                            "Description": "£50,000,000",
                            "ShortDescription": "£50m",
                            "Index": 25
                          },
                          "-1": {
                            "ID": "-1",
                            "Description": "No min",
                            "ShortDescription": "No min",
                            "HierarchicalID": "-1",
                            "Index": 0
                          }
                        },
                        "Index": 0
                      },
                      "MaxPrice": {
                        "ID": "MaxPrice",
                        "Description": "maximum",
                        "ShortDescription": "max",
                        "Options": {
                          "100000": {
                            "ID": "100000",
                            "Description": "£100,000",
                            "ShortDescription": "£100k",
                            "Index": 0
                          },
                          "200000": {
                            "ID": "200000",
                            "Description": "£200,000",
                            "ShortDescription": "£200k",
                            "Index": 1
                          },
                          "300000": {
                            "ID": "300000",
                            "Description": "£300,000",
                            "ShortDescription": "£300k",
                            "Index": 2
                          },
                          "400000": {
                            "ID": "400000",
                            "Description": "£400,000",
                            "ShortDescription": "£400k",
                            "Index": 3
                          },
                          "500000": {
                            "ID": "500000",
                            "Description": "£500,000",
                            "ShortDescription": "£500k",
                            "Index": 4
                          },
                          "600000": {
                            "ID": "600000",
                            "Description": "£600,000",
                            "ShortDescription": "£600k",
                            "Index": 5
                          },
                          "700000": {
                            "ID": "700000",
                            "Description": "£700,000",
                            "ShortDescription": "£700k",
                            "Index": 6
                          },
                          "800000": {
                            "ID": "800000",
                            "Description": "£800,000",
                            "ShortDescription": "£800k",
                            "Index": 7
                          },
                          "900000": {
                            "ID": "900000",
                            "Description": "£900,000",
                            "ShortDescription": "£900k",
                            "Index": 8
                          },
                          "1000000": {
                            "ID": "1000000",
                            "Description": "£1,000,000",
                            "ShortDescription": "£1m",
                            "Index": 9
                          },
                          "1250000": {
                            "ID": "1250000",
                            "Description": "£1,250,000",
                            "ShortDescription": "£1.25m",
                            "Index": 10
                          },
                          "1500000": {
                            "ID": "1500000",
                            "Description": "£1,500,000",
                            "ShortDescription": "£1.5m",
                            "Index": 11
                          },
                          "1750000": {
                            "ID": "1750000",
                            "Description": "£1,750,000",
                            "ShortDescription": "£1.75m",
                            "Index": 12
                          },
                          "2000000": {
                            "ID": "2000000",
                            "Description": "£2,000,000",
                            "ShortDescription": "£2m",
                            "Index": 13
                          },
                          "2500000": {
                            "ID": "2500000",
                            "Description": "£2,500,000",
                            "ShortDescription": "£2.5m",
                            "Index": 14
                          },
                          "3000000": {
                            "ID": "3000000",
                            "Description": "£3,000,000",
                            "ShortDescription": "£3m",
                            "Index": 15
                          },
                          "4000000": {
                            "ID": "4000000",
                            "Description": "£4,000,000",
                            "ShortDescription": "£4m",
                            "Index": 16
                          },
                          "5000000": {
                            "ID": "5000000",
                            "Description": "£5,000,000",
                            "ShortDescription": "£5m",
                            "Index": 17
                          },
                          "7500000": {
                            "ID": "7500000",
                            "Description": "£7,500,000",
                            "ShortDescription": "£7.5m",
                            "Index": 18
                          },
                          "10000000": {
                            "ID": "10000000",
                            "Description": "£10,000,000",
                            "ShortDescription": "£10m",
                            "Index": 19
                          },
                          "15000000": {
                            "ID": "15000000",
                            "Description": "£15,000,000",
                            "ShortDescription": "£15m",
                            "Index": 20
                          },
                          "20000000": {
                            "ID": "20000000",
                            "Description": "£20,000,000",
                            "ShortDescription": "£20m",
                            "Index": 21
                          },
                          "30000000": {
                            "ID": "30000000",
                            "Description": "£30,000,000",
                            "ShortDescription": "£30m",
                            "Index": 22
                          },
                          "40000000": {
                            "ID": "40000000",
                            "Description": "£40,000,000",
                            "ShortDescription": "£40m",
                            "Index": 23
                          },
                          "50000000": {
                            "ID": "50000000",
                            "Description": "£50,000,000",
                            "ShortDescription": "£50m",
                            "Index": 24
                          },
                          "-1": {
                            "ID": "-1",
                            "Description": "No max",
                            "ShortDescription": "No max",
                            "HierarchicalID": "-1",
                            "Index": 25
                          }
                        },
                        "Index": 1
                      }
                    },
                    "Index": 17
                  },
                  "HKD": {
                    "ID": "HKD",
                    "Description": "HKD",
                    "Index": 18
                  },
                  "HRK": {
                    "ID": "HRK",
                    "Description": "HRK",
                    "Index": 19
                  },
                  "HUF": {
                    "ID": "HUF",
                    "Description": "HUF",
                    "Index": 20
                  },
                  "IDR": {
                    "ID": "IDR",
                    "Description": "IDR",
                    "Index": 21
                  },
                  "ILS": {
                    "ID": "ILS",
                    "Description": "ILS",
                    "Index": 22
                  },
                  "INR": {
                    "ID": "INR",
                    "Description": "INR",
                    "Index": 23
                  },
                  "IRR": {
                    "ID": "IRR",
                    "Description": "IRR",
                    "Index": 24
                  },
                  "ISK": {
                    "ID": "ISK",
                    "Description": "ISK",
                    "Index": 25
                  },
                  "JPY": {
                    "ID": "JPY",
                    "Description": "JPY",
                    "Index": 26
                  },
                  "KRW": {
                    "ID": "KRW",
                    "Description": "KRW",
                    "Index": 27
                  },
                  "KWD": {
                    "ID": "KWD",
                    "Description": "KWD",
                    "Index": 28
                  },
                  "KZT": {
                    "ID": "KZT",
                    "Description": "KZT",
                    "Index": 29
                  },
                  "LBP": {
                    "ID": "LBP",
                    "Description": "LBP",
                    "Index": 30
                  },
                  "LKR": {
                    "ID": "LKR",
                    "Description": "LKR",
                    "Index": 31
                  },
                  "LTL": {
                    "ID": "LTL",
                    "Description": "LTL",
                    "Index": 32
                  },
                  "LVL": {
                    "ID": "LVL",
                    "Description": "LVL",
                    "Index": 33
                  },
                  "LYD": {
                    "ID": "LYD",
                    "Description": "LYD",
                    "Index": 34
                  },
                  "MAD": {
                    "ID": "MAD",
                    "Description": "MAD",
                    "Index": 35
                  },
                  "MUR": {
                    "ID": "MUR",
                    "Description": "MUR",
                    "Index": 36
                  },
                  "MXN": {
                    "ID": "MXN",
                    "Description": "MXN",
                    "Index": 37
                  },
                  "MYR": {
                    "ID": "MYR",
                    "Description": "MYR",
                    "Index": 38
                  },
                  "NGN": {
                    "ID": "NGN",
                    "Description": "NGN",
                    "Index": 39
                  },
                  "NOK": {
                    "ID": "NOK",
                    "Description": "NOK",
                    "Index": 40
                  },
                  "NPR": {
                    "ID": "NPR",
                    "Description": "NPR",
                    "Index": 41
                  },
                  "NZD": {
                    "ID": "NZD",
                    "Description": "NZD",
                    "Index": 42
                  },
                  "OMR": {
                    "ID": "OMR",
                    "Description": "OMR",
                    "Index": 43
                  },
                  "PHP": {
                    "ID": "PHP",
                    "Description": "PHP",
                    "Index": 44
                  },
                  "PKR": {
                    "ID": "PKR",
                    "Description": "PKR",
                    "Index": 45
                  },
                  "PLN": {
                    "ID": "PLN",
                    "Description": "PLN",
                    "Index": 46
                  },
                  "QAR": {
                    "ID": "QAR",
                    "Description": "QAR",
                    "Index": 47
                  },
                  "RMB": {
                    "ID": "RMB",
                    "Description": "RMB",
                    "Index": 48
                  },
                  "RON": {
                    "ID": "RON",
                    "Description": "RON",
                    "Index": 49
                  },
                  "RSD": {
                    "ID": "RSD",
                    "Description": "RSD",
                    "Index": 50
                  },
                  "RUB": {
                    "ID": "RUB",
                    "Description": "RUB",
                    "Index": 51
                  },
                  "SAR": {
                    "ID": "SAR",
                    "Description": "SAR",
                    "Index": 52
                  },
                  "SEK": {
                    "ID": "SEK",
                    "Description": "SEK",
                    "Index": 53
                  },
                  "SGD": {
                    "ID": "SGD",
                    "Description": "SGD",
                    "Index": 54
                  },
                  "THB": {
                    "ID": "THB",
                    "Description": "THB",
                    "Index": 55
                  },
                  "TRY": {
                    "ID": "TRY",
                    "Description": "TRY",
                    "Index": 56
                  },
                  "TWD": {
                    "ID": "TWD",
                    "Description": "TWD",
                    "Index": 57
                  },
                  "USD": {
                    "ID": "USD",
                    "Description": "USD",
                    "Index": 58
                  },
                  "VND": {
                    "ID": "VND",
                    "Description": "VND",
                    "Index": 59
                  },
                  "ZAR": {
                    "ID": "ZAR",
                    "Description": "ZAR",
                    "Index": 60
                  }
                },
                "PropertyTypes": {
                  "GRS_PT_ND": {
                    "ID": "ND",
                    "Description": "New development",
                    "ShortDescription": "NewDevelopment",
                    "HierarchicalID": "GRS_PT_ND",
                    "Index": 0
                  },
                  "GRS_PT_BP": {
                    "ID": "BP",
                    "Description": "Building plot",
                    "ShortDescription": "BuildingPlot",
                    "HierarchicalID": "GRS_PT_BP",
                    "Index": 1
                  },
                  "GRS_PT_B": {
                    "ID": "B",
                    "Description": "Bungalow",
                    "ShortDescription": "Bungalow",
                    "HierarchicalID": "GRS_PT_B",
                    "Index": 2
                  },
                  "GRS_PT_CAST": {
                    "ID": "CAST",
                    "Description": "Castle / Chateaux",
                    "ShortDescription": "Castle",
                    "HierarchicalID": "GRS_PT_CAST",
                    "Index": 3
                  },
                  "GRS_PT_CONDO": {
                    "ID": "CONDO",
                    "Description": "Condominium",
                    "ShortDescription": "Condominium",
                    "HierarchicalID": "GRS_PT_CONDO",
                    "Index": 4
                  },
                  "GRS_PT_D": {
                    "ID": "D",
                    "Description": "Duplex",
                    "ShortDescription": "Duplex",
                    "HierarchicalID": "GRS_PT_D",
                    "Index": 5
                  },
                  "GRS_PT_EST": {
                    "ID": "EST",
                    "Description": "Estate",
                    "ShortDescription": "Estate",
                    "HierarchicalID": "GRS_PT_EST",
                    "Index": 6
                  },
                  "GRS_PT_ECONDO": {
                    "ID": "ECONDO",
                    "Description": "Executive Condominium",
                    "ShortDescription": "ExecutiveCondominium",
                    "HierarchicalID": "GRS_PT_ECONDO",
                    "Index": 7
                  },
                  "GRS_PT_APT": {
                    "ID": "APT",
                    "Description": "Flat / Apartment",
                    "ShortDescription": "FlatApartment",
                    "HierarchicalID": "GRS_PT_APT",
                    "Index": 8
                  },
                  "GRS_PT_HDB": {
                    "ID": "HDB",
                    "Description": "HDB Apartment",
                    "ShortDescription": "HDBApartment",
                    "HierarchicalID": "GRS_PT_HDB",
                    "Index": 9
                  },
                  "GRS_PT_HRB": {
                    "ID": "HRB",
                    "Description": "High rise building",
                    "ShortDescription": "HighRiseBuilding",
                    "HierarchicalID": "GRS_PT_HRB",
                    "Index": 10
                  },
                  "GRS_PT_H": {
                    "ID": "H",
                    "Description": "House",
                    "ShortDescription": "House",
                    "HierarchicalID": "GRS_PT_H",
                    "Index": 11
                  },
                  "GRS_PT_M": {
                    "ID": "M",
                    "Description": "Mews",
                    "ShortDescription": "Mews",
                    "HierarchicalID": "GRS_PT_M",
                    "Index": 12
                  },
                  "GRS_PT_MRB": {
                    "ID": "MRB",
                    "Description": "Middle rise building",
                    "ShortDescription": "MiddleRiseBuilding",
                    "HierarchicalID": "GRS_PT_MRB",
                    "Index": 13
                  },
                  "GRS_PT_PENT": {
                    "ID": "PENT",
                    "Description": "Penthouse",
                    "ShortDescription": "Penthouse",
                    "HierarchicalID": "GRS_PT_PENT",
                    "Index": 14
                  },
                  "GRS_PT_SAPT": {
                    "ID": "SAPT",
                    "Description": "Serviced apartment",
                    "ShortDescription": "ServicedApartment",
                    "HierarchicalID": "GRS_PT_SAPT",
                    "Index": 15
                  },
                  "GRS_PT_STU": {
                    "ID": "STU",
                    "Description": "Studio",
                    "ShortDescription": "Studio",
                    "HierarchicalID": "GRS_PT_STU",
                    "Index": 16
                  },
                  "GRS_PT_C": {
                    "ID": "C",
                    "Description": "Compound",
                    "ShortDescription": "Compound",
                    "HierarchicalID": "GRS_PT_C",
                    "Index": 17
                  },
                  "GRS_PT_T": {
                    "ID": "T",
                    "Description": "Triplex",
                    "ShortDescription": "Triplex",
                    "HierarchicalID": "GRS_PT_T",
                    "Index": 18
                  },
                  "GRS_PT_TH": {
                    "ID": "TH",
                    "Description": "Townhouse",
                    "ShortDescription": "Townhouse",
                    "HierarchicalID": "GRS_PT_TH",
                    "Index": 19
                  },
                  "GRS_PT_V": {
                    "ID": "V",
                    "Description": "Villa",
                    "ShortDescription": "Villa",
                    "HierarchicalID": "GRS_PT_V",
                    "Index": 20
                  },
                  "GRS_PT_TUA": {
                    "ID": "TUA",
                    "Description": "Temporary use apartments",
                    "ShortDescription": "TemporaryUseApartments",
                    "HierarchicalID": "GRS_PT_TUA",
                    "Index": 21
                  },
                  "GRS_PT_FOC": {
                    "ID": "FOC",
                    "Description": "Flat outside the city",
                    "ShortDescription": "FlatOutsideCity",
                    "HierarchicalID": "GRS_PT_FOC",
                    "Index": 22
                  },
                  "GRS_PT_LP": {
                    "ID": "LP",
                    "Description": "Land plot",
                    "ShortDescription": "LandPlot",
                    "HierarchicalID": "GRS_PT_LP",
                    "Index": 23
                  },
                  "GRS_PT_HTB": {
                    "ID": "HTB",
                    "Description": "Help To Buy",
                    "ShortDescription": "HelpToBuy",
                    "HierarchicalID": "GRS_PT_HTB",
                    "Index": 24
                  },
                  "GRS_PT_SOW": {
                    "ID": "SOW",
                    "Description": "Shared Ownership",
                    "ShortDescription": "SharedOwnership",
                    "HierarchicalID": "GRS_PT_SOW",
                    "Index": 25
                  },
                  "GRS_PT_VY": {
                    "ID": "VY",
                    "Description": "Vineyard",
                    "ShortDescription": "Vineyard",
                    "HierarchicalID": "GRS_PT_VY",
                    "Index": 26
                  },
                  "GRS_PT_SNRL": {
                    "ID": "SNRL",
                    "Description": "Senior living",
                    "ShortDescription": "SeniorLiving",
                    "HierarchicalID": "GRS_PT_SNRL",
                    "Index": 27
                  },
                  "GRS_PT_CHLT": {
                    "ID": "CHLT",
                    "Description": "Chalet",
                    "ShortDescription": "Chalet",
                    "HierarchicalID": "GRS_PT_CHLT",
                    "Index": 28
                  },
                  "GRS_PT_CTTG": {
                    "ID": "CTTG",
                    "Description": "Cottage",
                    "ShortDescription": "Cottage",
                    "HierarchicalID": "GRS_PT_CTTG",
                    "Index": 29
                  }
                },
                "Bedrooms": {
                  "-1": {
                    "ID": "-1",
                    "Description": "No min",
                    "ShortDescription": "No min",
                    "HierarchicalID": "-1",
                    "Index": 0
                  },
                  "GRS_B_1": {
                    "ID": "1",
                    "Description": "1",
                    "ShortDescription": "Bedroom1",
                    "HierarchicalID": "GRS_B_1",
                    "Index": 1
                  },
                  "GRS_B_2": {
                    "ID": "2",
                    "Description": "2",
                    "ShortDescription": "Bedroom2",
                    "HierarchicalID": "GRS_B_2",
                    "Index": 2
                  },
                  "GRS_B_3": {
                    "ID": "3",
                    "Description": "3",
                    "ShortDescription": "Bedroom3",
                    "HierarchicalID": "GRS_B_3",
                    "Index": 3
                  },
                  "GRS_B_4": {
                    "ID": "4",
                    "Description": "4",
                    "ShortDescription": "Bedroom4",
                    "HierarchicalID": "GRS_B_4",
                    "Index": 4
                  },
                  "GRS_B_5": {
                    "ID": "5",
                    "Description": "5",
                    "ShortDescription": "Bedroom5",
                    "HierarchicalID": "GRS_B_5",
                    "Index": 5
                  },
                  "GRS_B_6+": {
                    "ID": "6+",
                    "Description": "6",
                    "ShortDescription": "Bedroom6",
                    "HierarchicalID": "GRS_B_6+",
                    "Index": 6
                  }
                },
                "Receptions": {
                  "-1": {
                    "ID": "-1",
                    "Description": "No min",
                    "ShortDescription": "No min",
                    "HierarchicalID": "-1",
                    "Index": 0
                  },
                  "GRS_REC_1": {
                    "ID": "1",
                    "Description": "1",
                    "ShortDescription": "Receptions1",
                    "HierarchicalID": "GRS_REC_1",
                    "Index": 1
                  },
                  "GRS_REC_2": {
                    "ID": "2",
                    "Description": "2",
                    "ShortDescription": "Receptions2",
                    "HierarchicalID": "GRS_REC_2",
                    "Index": 2
                  },
                  "GRS_REC_3": {
                    "ID": "3",
                    "Description": "3",
                    "ShortDescription": "Receptions3",
                    "HierarchicalID": "GRS_REC_3",
                    "Index": 3
                  },
                  "GRS_REC_4": {
                    "ID": "4",
                    "Description": "4",
                    "ShortDescription": "Receptions4",
                    "HierarchicalID": "GRS_REC_4",
                    "Index": 4
                  },
                  "GRS_REC_5": {
                    "ID": "5",
                    "Description": "5",
                    "ShortDescription": "Receptions5",
                    "HierarchicalID": "GRS_REC_5",
                    "Index": 5
                  },
                  "GRS_REC_6+": {
                    "ID": "6+",
                    "Description": "6",
                    "ShortDescription": "Receptions6",
                    "HierarchicalID": "GRS_REC_6+",
                    "Index": 6
                  }
                },
                "Bathrooms": {
                  "-1": {
                    "ID": "-1",
                    "Description": "No min",
                    "ShortDescription": "No min",
                    "HierarchicalID": "-1",
                    "Index": 0
                  },
                  "GRS_BT_1": {
                    "ID": "1",
                    "Description": "1",
                    "ShortDescription": "Bathrooms1",
                    "HierarchicalID": "GRS_BT_1",
                    "Index": 1
                  },
                  "GRS_BT_2": {
                    "ID": "2",
                    "Description": "2",
                    "ShortDescription": "Bathrooms2",
                    "HierarchicalID": "GRS_BT_2",
                    "Index": 2
                  },
                  "GRS_BT_3": {
                    "ID": "3",
                    "Description": "3",
                    "ShortDescription": "Bathrooms3",
                    "HierarchicalID": "GRS_BT_3",
                    "Index": 3
                  },
                  "GRS_BT_4": {
                    "ID": "4",
                    "Description": "4",
                    "ShortDescription": "Bathrooms4",
                    "HierarchicalID": "GRS_BT_4",
                    "Index": 4
                  },
                  "GRS_BT_5": {
                    "ID": "5",
                    "Description": "5",
                    "ShortDescription": "Bathrooms5",
                    "HierarchicalID": "GRS_BT_5",
                    "Index": 5
                  },
                  "GRS_BT_6+": {
                    "ID": "6+",
                    "Description": "6",
                    "ShortDescription": "Bathrooms6",
                    "HierarchicalID": "GRS_BT_6+",
                    "Index": 6
                  }
                },
                "CarSpaces": {
                  "-1": {
                    "ID": "-1",
                    "Description": "No min",
                    "ShortDescription": "No min",
                    "HierarchicalID": "-1",
                    "Index": 0
                  },
                  "GRS_CSPC_1": {
                    "ID": "1",
                    "Description": "1",
                    "ShortDescription": "CarSpaces1",
                    "HierarchicalID": "GRS_CSPC_1",
                    "Index": 1
                  },
                  "GRS_CSPC_2": {
                    "ID": "2",
                    "Description": "2",
                    "ShortDescription": "CarSpaces2",
                    "HierarchicalID": "GRS_CSPC_2",
                    "Index": 2
                  },
                  "GRS_CSPC_3": {
                    "ID": "3",
                    "Description": "3",
                    "ShortDescription": "CarSpaces3",
                    "HierarchicalID": "GRS_CSPC_3",
                    "Index": 3
                  },
                  "GRS_CSPC_4": {
                    "ID": "4",
                    "Description": "4",
                    "ShortDescription": "CarSpaces4",
                    "HierarchicalID": "GRS_CSPC_4",
                    "Index": 4
                  },
                  "GRS_CSPC_5": {
                    "ID": "5",
                    "Description": "5",
                    "ShortDescription": "CarSpaces5",
                    "HierarchicalID": "GRS_CSPC_5",
                    "Index": 5
                  },
                  "GRS_CSPC_6+": {
                    "ID": "6+",
                    "Description": "6",
                    "ShortDescription": "CarSpaces6",
                    "HierarchicalID": "GRS_CSPC_6+",
                    "Index": 6
                  }
                },
                "Lifestyles": {
                  "GRS_LS_MET": {
                    "ID": "MET",
                    "Description": "Metropolitan",
                    "ShortDescription": "Metropolitan",
                    "HierarchicalID": "GRS_LS_MET",
                    "Index": 0
                  },
                  "GRS_LS_BT": {
                    "ID": "BT",
                    "Description": "Boat",
                    "ShortDescription": "Boat",
                    "HierarchicalID": "GRS_LS_BT",
                    "Index": 1
                  },
                  "GRS_LS_CT": {
                    "ID": "CT",
                    "Description": "Close to transport",
                    "ShortDescription": "CloseToTransport",
                    "HierarchicalID": "GRS_LS_CT",
                    "Index": 2
                  },
                  "GRS_LS_CTS": {
                    "ID": "CTS",
                    "Description": "Close to school",
                    "ShortDescription": "CloseToSchool",
                    "HierarchicalID": "GRS_LS_CTS",
                    "Index": 3
                  },
                  "GRS_LS_CTIS": {
                    "ID": "CTIS",
                    "Description": "Close to international school",
                    "ShortDescription": "CloseToInternationalSchool",
                    "HierarchicalID": "GRS_LS_CTIS",
                    "Index": 4
                  },
                  "GRS_LS_CTP": {
                    "ID": "CTP",
                    "Description": "Close to park",
                    "ShortDescription": "CloseToPark",
                    "HierarchicalID": "GRS_LS_CTP",
                    "Index": 5
                  },
                  "GRS_LS_CTG": {
                    "ID": "CTG",
                    "Description": "Close to gym",
                    "ShortDescription": "CloseToGym",
                    "HierarchicalID": "GRS_LS_CTG",
                    "Index": 6
                  },
                  "GRS_LS_EQ": {
                    "ID": "EQ",
                    "Description": "Equestrian",
                    "ShortDescription": "Equestrian",
                    "HierarchicalID": "GRS_LS_EQ",
                    "Index": 7
                  },
                  "GRS_LS_FSH": {
                    "ID": "FSH",
                    "Description": "Fishing",
                    "ShortDescription": "Fishing",
                    "HierarchicalID": "GRS_LS_FSH",
                    "Index": 8
                  },
                  "GRS_LS_GLF": {
                    "ID": "GLF",
                    "Description": "Golf",
                    "ShortDescription": "Golf",
                    "HierarchicalID": "GRS_LS_GLF",
                    "Index": 9
                  },
                  "GRS_LS_MAR": {
                    "ID": "MAR",
                    "Description": "Marina",
                    "ShortDescription": "Marina",
                    "HierarchicalID": "GRS_LS_MAR",
                    "Index": 10
                  },
                  "GRS_LS_B": {
                    "ID": "B",
                    "Description": "Beach",
                    "ShortDescription": "Beach",
                    "HierarchicalID": "GRS_LS_B",
                    "Index": 11
                  },
                  "GRS_LS_RET": {
                    "ID": "RET",
                    "Description": "Retirement",
                    "ShortDescription": "Retirement",
                    "HierarchicalID": "GRS_LS_RET",
                    "Index": 12
                  },
                  "GRS_LS_HTB": {
                    "ID": "HTB",
                    "Description": "Help To Buy",
                    "ShortDescription": "HelpToBuy",
                    "HierarchicalID": "GRS_LS_HTB",
                    "Index": 13
                  },
                  "GRS_LS_SV": {
                    "ID": "SV",
                    "Description": "Sea view",
                    "ShortDescription": "Seaview",
                    "HierarchicalID": "GRS_LS_SV",
                    "Index": 14
                  },
                  "GRS_LS_SHT": {
                    "ID": "SHT",
                    "Description": "Shooting",
                    "ShortDescription": "Shooting",
                    "HierarchicalID": "GRS_LS_SHT",
                    "Index": 15
                  },
                  "GRS_LS_S": {
                    "ID": "S",
                    "Description": "Skiing",
                    "ShortDescription": "Skiing",
                    "HierarchicalID": "GRS_LS_S",
                    "Index": 16
                  },
                  "GRS_LS_WS": {
                    "ID": "WS",
                    "Description": "Waterfront",
                    "ShortDescription": "Waterside",
                    "HierarchicalID": "GRS_LS_WS",
                    "Index": 17
                  },
                  "GRS_LS_WC": {
                    "ID": "WC",
                    "Description": "Wine country",
                    "ShortDescription": "WineCountry",
                    "HierarchicalID": "GRS_LS_WC",
                    "Index": 18
                  },
                  "GRS_LS_PF": {
                    "ID": "PF",
                    "Description": "Pet-friendly",
                    "ShortDescription": "PetFriendly",
                    "HierarchicalID": "GRS_LS_PF",
                    "Index": 19
                  },
                  "GRS_LS_ICH": {
                    "ID": "ICH",
                    "Description": "Country Homes",
                    "ShortDescription": "IrishCountryHome",
                    "HierarchicalID": "GRS_LS_ICH",
                    "Index": 20
                  }
                },
                "Features_Buy": {
                  "GRS_FTR_B": {
                    "ID": "B",
                    "Description": "Balcony",
                    "ShortDescription": "Balcony",
                    "HierarchicalID": "GRS_FTR_B",
                    "Index": 0
                  },
                  "GRS_FTR_CP": {
                    "ID": "CP",
                    "Description": "Car Park",
                    "ShortDescription": "CarPark",
                    "HierarchicalID": "GRS_FTR_CP",
                    "Index": 1
                  },
                  "GRS_FTR_CHF": {
                    "ID": "CHF",
                    "Description": "Children's facilities",
                    "ShortDescription": "ChildrenFacilities",
                    "HierarchicalID": "GRS_FTR_CHF",
                    "Index": 2
                  },
                  "GRS_FTR_CF": {
                    "ID": "CF",
                    "Description": "Club facilities",
                    "ShortDescription": "ClubFacilities",
                    "HierarchicalID": "GRS_FTR_CF",
                    "Index": 3
                  },
                  "GRS_FTR_CON": {
                    "ID": "CON",
                    "Description": "Concierge",
                    "ShortDescription": "Concierge",
                    "HierarchicalID": "GRS_FTR_CON",
                    "Index": 4
                  },
                  "GRS_FTR_GAR": {
                    "ID": "GAR",
                    "Description": "Garage",
                    "ShortDescription": "Garage",
                    "HierarchicalID": "GRS_FTR_GAR",
                    "Index": 5
                  },
                  "GRS_FTR_GDN": {
                    "ID": "GDN",
                    "Description": "Garden",
                    "ShortDescription": "Garden",
                    "HierarchicalID": "GRS_FTR_GDN",
                    "Index": 6
                  },
                  "GRS_FTR_G": {
                    "ID": "G",
                    "Description": "Gym",
                    "ShortDescription": "Gym",
                    "HierarchicalID": "GRS_FTR_G",
                    "Index": 7
                  },
                  "GRS_FTR_HLPQ": {
                    "ID": "HLPQ",
                    "Description": "Staff Accommodation",
                    "ShortDescription": "HelpersQuarters",
                    "HierarchicalID": "GRS_FTR_HLPQ",
                    "Index": 8
                  },
                  "GRS_FTR_JKZI": {
                    "ID": "JKZI",
                    "Description": "Jacuzzi",
                    "ShortDescription": "Jacuzzi",
                    "HierarchicalID": "GRS_FTR_JKZI",
                    "Index": 9
                  },
                  "GRS_FTR_LND": {
                    "ID": "LND",
                    "Description": "Land",
                    "ShortDescription": "Land",
                    "HierarchicalID": "GRS_FTR_LND",
                    "Index": 10
                  },
                  "GRS_FTR_L": {
                    "ID": "L",
                    "Description": "Lift",
                    "ShortDescription": "Lift",
                    "HierarchicalID": "GRS_FTR_L",
                    "Index": 11
                  },
                  "GRS_FTR_OSP": {
                    "ID": "OSP",
                    "Description": "Off-street parking",
                    "ShortDescription": "OffStreetParking",
                    "HierarchicalID": "GRS_FTR_OSP",
                    "Index": 12
                  },
                  "GRS_FTR_RT": {
                    "ID": "RT",
                    "Description": "Roof terrace",
                    "ShortDescription": "RoofTerrace",
                    "HierarchicalID": "GRS_FTR_RT",
                    "Index": 13
                  },
                  "GRS_FTR_SB": {
                    "ID": "SB",
                    "Description": "Shuttle bus",
                    "ShortDescription": "ShuttleBus",
                    "HierarchicalID": "GRS_FTR_SB",
                    "Index": 14
                  },
                  "GRS_FTR_SP": {
                    "ID": "SP",
                    "Description": "Swimming pool",
                    "ShortDescription": "SwimmingPool",
                    "HierarchicalID": "GRS_FTR_SP",
                    "Index": 15
                  },
                  "GRS_FTR_T": {
                    "ID": "T",
                    "Description": "Terrace",
                    "ShortDescription": "Terrace",
                    "HierarchicalID": "GRS_FTR_T",
                    "Index": 16
                  },
                  "GRS_FTR_TC": {
                    "ID": "TC",
                    "Description": "Tennis Court",
                    "ShortDescription": "TennisCourt",
                    "HierarchicalID": "GRS_FTR_TC",
                    "Index": 17
                  },
                  "GRS_FTR_Y": {
                    "ID": "Y",
                    "Description": "Yard",
                    "ShortDescription": "Yard",
                    "HierarchicalID": "GRS_FTR_Y",
                    "Index": 18
                  },
                  "GRS_FTR_RB": {
                    "ID": "RB",
                    "Description": "Renting business",
                    "ShortDescription": "RentingBusiness",
                    "HierarchicalID": "GRS_FTR_RB",
                    "Index": 19
                  },
                  "GRS_FTR_US": {
                    "ID": "US",
                    "Description": "Urgent sale",
                    "ShortDescription": "UrgentSale",
                    "HierarchicalID": "GRS_FTR_US",
                    "Index": 20
                  },
                  "GRS_FTR_INVS": {
                    "ID": "INVS",
                    "Description": "Investments",
                    "ShortDescription": "Investments",
                    "HierarchicalID": "GRS_FTR_INVS",
                    "Index": 21
                  },
                  "GRS_FTR_SPA": {
                    "ID": "SPA",
                    "Description": "SPA",
                    "ShortDescription": "SPA",
                    "HierarchicalID": "GRS_FTR_SPA",
                    "Index": 22
                  },
                  "GRS_FTR_SEC": {
                    "ID": "SEC",
                    "Description": "Security",
                    "ShortDescription": "Security",
                    "HierarchicalID": "GRS_FTR_SEC",
                    "Index": 23
                  },
                  "GRS_FTR_LS": {
                    "ID": "LS",
                    "Description": "Landscaping",
                    "ShortDescription": "Landscaping",
                    "HierarchicalID": "GRS_FTR_LS",
                    "Index": 24
                  },
                  "GRS_FTR_PV": {
                    "ID": "PV",
                    "Description": "Panoramic view",
                    "ShortDescription": "PanoramicView",
                    "HierarchicalID": "GRS_FTR_PV",
                    "Index": 25
                  },
                  "GRS_FTR_FP": {
                    "ID": "FP",
                    "Description": "Fireplace",
                    "ShortDescription": "Fireplace",
                    "HierarchicalID": "GRS_FTR_FP",
                    "Index": 26
                  },
                  "GRS_FTR_UP": {
                    "ID": "UP",
                    "Description": "Underground parking",
                    "ShortDescription": "UndergroundParking",
                    "HierarchicalID": "GRS_FTR_UP",
                    "Index": 27
                  },
                  "GRS_FTR_PRD": {
                    "ID": "PRD",
                    "Description": "Period",
                    "ShortDescription": "Period",
                    "HierarchicalID": "GRS_FTR_PRD",
                    "Index": 28
                  },
                  "GRS_FTR_PP": {
                    "ID": "PP",
                    "Description": "Planning permission",
                    "ShortDescription": "PlanningPermission",
                    "HierarchicalID": "GRS_FTR_PP",
                    "Index": 29
                  },
                  "GRS_FTR_G1": {
                    "ID": "G1",
                    "Description": "Grade I",
                    "ShortDescription": "Grade1",
                    "HierarchicalID": "GRS_FTR_G1",
                    "Index": 30
                  },
                  "GRS_FTR_G2": {
                    "ID": "G2",
                    "Description": "Grade II",
                    "ShortDescription": "Grade2",
                    "HierarchicalID": "GRS_FTR_G2",
                    "Index": 31
                  },
                  "GRS_FTR_LL": {
                    "ID": "LL",
                    "Description": "Lateral living",
                    "ShortDescription": "LateralLiving",
                    "HierarchicalID": "GRS_FTR_LL",
                    "Index": 32
                  },
                  "GRS_FTR_SFA": {
                    "ID": "SFA",
                    "Description": "Step free access",
                    "ShortDescription": "StepFreeAccess",
                    "HierarchicalID": "GRS_FTR_SFA",
                    "Index": 33
                  },
                  "GRS_FTR_WRFFS": {
                    "ID": "WRFFS",
                    "Description": "Wet room/flat floor shower",
                    "ShortDescription": "WetRoomFlatFloorShower",
                    "HierarchicalID": "GRS_FTR_WRFFS",
                    "Index": 34
                  }
                },
                "ResidentialSize_SquareFeet": {
                  "MinResidentialSize": {
                    "ID": "MinResidentialSize",
                    "Description": "minimum",
                    "ShortDescription": "min",
                    "Options": {
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 1
                      },
                      "750": {
                        "ID": "750",
                        "Description": "750",
                        "ShortDescription": "750",
                        "HierarchicalID": "750",
                        "Index": 2
                      },
                      "1000": {
                        "ID": "1000",
                        "Description": "1,000",
                        "ShortDescription": "1k",
                        "HierarchicalID": "1000",
                        "Index": 3
                      },
                      "1250": {
                        "ID": "1250",
                        "Description": "1,250",
                        "ShortDescription": "1.25k",
                        "HierarchicalID": "1250",
                        "Index": 4
                      },
                      "1500": {
                        "ID": "1500",
                        "Description": "1,500",
                        "ShortDescription": "1.5k",
                        "HierarchicalID": "1500",
                        "Index": 5
                      },
                      "1750": {
                        "ID": "1750",
                        "Description": "1,750",
                        "ShortDescription": "1.75k",
                        "HierarchicalID": "1750",
                        "Index": 6
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2,000",
                        "ShortDescription": "2k",
                        "HierarchicalID": "2000",
                        "Index": 7
                      },
                      "2500": {
                        "ID": "2500",
                        "Description": "2,500",
                        "ShortDescription": "2.5k",
                        "HierarchicalID": "2500",
                        "Index": 8
                      },
                      "3000": {
                        "ID": "3000",
                        "Description": "3,000",
                        "ShortDescription": "3k",
                        "HierarchicalID": "3000",
                        "Index": 9
                      },
                      "3500": {
                        "ID": "3500",
                        "Description": "3,500",
                        "ShortDescription": "3.5k",
                        "HierarchicalID": "3500",
                        "Index": 10
                      },
                      "4000": {
                        "ID": "4000",
                        "Description": "4,000",
                        "ShortDescription": "4k",
                        "HierarchicalID": "4000",
                        "Index": 11
                      },
                      "4500": {
                        "ID": "4500",
                        "Description": "4,500",
                        "ShortDescription": "4.5k",
                        "HierarchicalID": "4500",
                        "Index": 12
                      },
                      "5000": {
                        "ID": "5000",
                        "Description": "5,000",
                        "ShortDescription": "5k",
                        "HierarchicalID": "5000",
                        "Index": 13
                      },
                      "6000": {
                        "ID": "6000",
                        "Description": "6,000",
                        "ShortDescription": "6k",
                        "HierarchicalID": "6000",
                        "Index": 14
                      },
                      "7000": {
                        "ID": "7000",
                        "Description": "7,000",
                        "ShortDescription": "7k",
                        "HierarchicalID": "7000",
                        "Index": 15
                      },
                      "8000": {
                        "ID": "8000",
                        "Description": "8,000",
                        "ShortDescription": "8k",
                        "HierarchicalID": "8000",
                        "Index": 16
                      },
                      "9000": {
                        "ID": "9000",
                        "Description": "9,000",
                        "ShortDescription": "9k",
                        "HierarchicalID": "9000",
                        "Index": 17
                      },
                      "10000": {
                        "ID": "10000",
                        "Description": "10,000",
                        "ShortDescription": "10k",
                        "HierarchicalID": "10000",
                        "Index": 18
                      },
                      "12500": {
                        "ID": "12500",
                        "Description": "12,500",
                        "ShortDescription": "12.5k",
                        "HierarchicalID": "12500",
                        "Index": 19
                      },
                      "15000": {
                        "ID": "15000",
                        "Description": "15,000",
                        "ShortDescription": "15k",
                        "HierarchicalID": "15000",
                        "Index": 20
                      },
                      "17500": {
                        "ID": "17500",
                        "Description": "17,500",
                        "ShortDescription": "17.5k",
                        "HierarchicalID": "17500",
                        "Index": 21
                      },
                      "20000": {
                        "ID": "20000",
                        "Description": "20,000",
                        "ShortDescription": "20k",
                        "HierarchicalID": "20000",
                        "Index": 22
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No min",
                        "ShortDescription": "No min",
                        "HierarchicalID": "-1",
                        "Index": 0
                      }
                    },
                    "Index": 0
                  },
                  "MaxResidentialSize": {
                    "ID": "MaxResidentialSize",
                    "Description": "maximum",
                    "Options": {
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 0
                      },
                      "750": {
                        "ID": "750",
                        "Description": "750",
                        "ShortDescription": "750",
                        "HierarchicalID": "750",
                        "Index": 1
                      },
                      "1000": {
                        "ID": "1000",
                        "Description": "1,000",
                        "ShortDescription": "1k",
                        "HierarchicalID": "1000",
                        "Index": 2
                      },
                      "1250": {
                        "ID": "1250",
                        "Description": "1,250",
                        "ShortDescription": "1.25k",
                        "HierarchicalID": "1250",
                        "Index": 3
                      },
                      "1500": {
                        "ID": "1500",
                        "Description": "1,500",
                        "ShortDescription": "1.5k",
                        "HierarchicalID": "1500",
                        "Index": 4
                      },
                      "1750": {
                        "ID": "1750",
                        "Description": "1,750",
                        "ShortDescription": "1.75k",
                        "HierarchicalID": "1750",
                        "Index": 5
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2,000",
                        "ShortDescription": "2k",
                        "HierarchicalID": "2000",
                        "Index": 6
                      },
                      "2500": {
                        "ID": "2500",
                        "Description": "2,500",
                        "ShortDescription": "2.5k",
                        "HierarchicalID": "2500",
                        "Index": 7
                      },
                      "3000": {
                        "ID": "3000",
                        "Description": "3,000",
                        "ShortDescription": "3k",
                        "HierarchicalID": "3000",
                        "Index": 8
                      },
                      "3500": {
                        "ID": "3500",
                        "Description": "3,500",
                        "ShortDescription": "3.5k",
                        "HierarchicalID": "3500",
                        "Index": 9
                      },
                      "4000": {
                        "ID": "4000",
                        "Description": "4,000",
                        "ShortDescription": "4k",
                        "HierarchicalID": "4000",
                        "Index": 10
                      },
                      "4500": {
                        "ID": "4500",
                        "Description": "4,500",
                        "ShortDescription": "4.5k",
                        "HierarchicalID": "4500",
                        "Index": 11
                      },
                      "5000": {
                        "ID": "5000",
                        "Description": "5,000",
                        "ShortDescription": "5k",
                        "HierarchicalID": "5000",
                        "Index": 12
                      },
                      "6000": {
                        "ID": "6000",
                        "Description": "6,000",
                        "ShortDescription": "6k",
                        "HierarchicalID": "6000",
                        "Index": 13
                      },
                      "7000": {
                        "ID": "7000",
                        "Description": "7,000",
                        "ShortDescription": "7k",
                        "HierarchicalID": "7000",
                        "Index": 14
                      },
                      "8000": {
                        "ID": "8000",
                        "Description": "8,000",
                        "ShortDescription": "8k",
                        "HierarchicalID": "8000",
                        "Index": 15
                      },
                      "9000": {
                        "ID": "9000",
                        "Description": "9,000",
                        "ShortDescription": "9k",
                        "HierarchicalID": "9000",
                        "Index": 16
                      },
                      "10000": {
                        "ID": "10000",
                        "Description": "10,000",
                        "ShortDescription": "10k",
                        "HierarchicalID": "10000",
                        "Index": 17
                      },
                      "12500": {
                        "ID": "12500",
                        "Description": "12,500",
                        "ShortDescription": "12.5k",
                        "HierarchicalID": "12500",
                        "Index": 18
                      },
                      "15000": {
                        "ID": "15000",
                        "Description": "15,000",
                        "ShortDescription": "15k",
                        "HierarchicalID": "15000",
                        "Index": 19
                      },
                      "17500": {
                        "ID": "17500",
                        "Description": "17,500",
                        "ShortDescription": "17.5k",
                        "HierarchicalID": "17500",
                        "Index": 20
                      },
                      "20000": {
                        "ID": "20000",
                        "Description": "20,000",
                        "ShortDescription": "20k",
                        "HierarchicalID": "20000",
                        "Index": 21
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No max",
                        "ShortDescription": "No max",
                        "HierarchicalID": "-1",
                        "Index": 22
                      }
                    },
                    "Index": 1
                  }
                },
                "ResidentialSize_SquareMeter": {
                  "MinResidentialSize": {
                    "ID": "MinResidentialSize",
                    "Description": "minimum",
                    "ShortDescription": "min",
                    "Options": {
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 1
                      },
                      "75": {
                        "ID": "75",
                        "Description": "75",
                        "ShortDescription": "75",
                        "HierarchicalID": "75",
                        "Index": 2
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 3
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 4
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 5
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 6
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 7
                      },
                      "250": {
                        "ID": "250",
                        "Description": "250",
                        "ShortDescription": "250",
                        "HierarchicalID": "250",
                        "Index": 8
                      },
                      "300": {
                        "ID": "300",
                        "Description": "300",
                        "ShortDescription": "300",
                        "HierarchicalID": "300",
                        "Index": 9
                      },
                      "350": {
                        "ID": "350",
                        "Description": "350",
                        "ShortDescription": "350",
                        "HierarchicalID": "350",
                        "Index": 10
                      },
                      "400": {
                        "ID": "400",
                        "Description": "400",
                        "ShortDescription": "400",
                        "HierarchicalID": "400",
                        "Index": 11
                      },
                      "450": {
                        "ID": "450",
                        "Description": "450",
                        "ShortDescription": "450",
                        "HierarchicalID": "450",
                        "Index": 12
                      },
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 13
                      },
                      "600": {
                        "ID": "600",
                        "Description": "600",
                        "ShortDescription": "600",
                        "HierarchicalID": "600",
                        "Index": 14
                      },
                      "700": {
                        "ID": "700",
                        "Description": "700",
                        "ShortDescription": "700",
                        "HierarchicalID": "700",
                        "Index": 15
                      },
                      "800": {
                        "ID": "800",
                        "Description": "800",
                        "ShortDescription": "800",
                        "HierarchicalID": "800",
                        "Index": 16
                      },
                      "900": {
                        "ID": "900",
                        "Description": "900",
                        "ShortDescription": "900",
                        "HierarchicalID": "900",
                        "Index": 17
                      },
                      "1000": {
                        "ID": "1000",
                        "Description": "1,000",
                        "ShortDescription": "1k",
                        "HierarchicalID": "1000",
                        "Index": 18
                      },
                      "1250": {
                        "ID": "1250",
                        "Description": "1,250",
                        "ShortDescription": "1.25k",
                        "HierarchicalID": "1250",
                        "Index": 19
                      },
                      "1500": {
                        "ID": "1500",
                        "Description": "1,500",
                        "ShortDescription": "1.5k",
                        "HierarchicalID": "1500",
                        "Index": 20
                      },
                      "1750": {
                        "ID": "1750",
                        "Description": "1,750",
                        "ShortDescription": "1.75k",
                        "HierarchicalID": "1750",
                        "Index": 21
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2,000",
                        "ShortDescription": "2k",
                        "HierarchicalID": "2000",
                        "Index": 22
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No min",
                        "ShortDescription": "No min",
                        "HierarchicalID": "-1",
                        "Index": 0
                      }
                    },
                    "Index": 0
                  },
                  "MaxResidentialSize": {
                    "ID": "MaxResidentialSize",
                    "Description": "maximum",
                    "Options": {
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 0
                      },
                      "75": {
                        "ID": "75",
                        "Description": "75",
                        "ShortDescription": "75",
                        "HierarchicalID": "75",
                        "Index": 1
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 2
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 3
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 4
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 5
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 6
                      },
                      "250": {
                        "ID": "250",
                        "Description": "250",
                        "ShortDescription": "250",
                        "HierarchicalID": "250",
                        "Index": 7
                      },
                      "300": {
                        "ID": "300",
                        "Description": "300",
                        "ShortDescription": "300",
                        "HierarchicalID": "300",
                        "Index": 8
                      },
                      "350": {
                        "ID": "350",
                        "Description": "350",
                        "ShortDescription": "350",
                        "HierarchicalID": "350",
                        "Index": 9
                      },
                      "400": {
                        "ID": "400",
                        "Description": "400",
                        "ShortDescription": "400",
                        "HierarchicalID": "400",
                        "Index": 10
                      },
                      "450": {
                        "ID": "450",
                        "Description": "450",
                        "ShortDescription": "450",
                        "HierarchicalID": "450",
                        "Index": 11
                      },
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 12
                      },
                      "600": {
                        "ID": "600",
                        "Description": "600",
                        "ShortDescription": "600",
                        "HierarchicalID": "600",
                        "Index": 13
                      },
                      "700": {
                        "ID": "700",
                        "Description": "700",
                        "ShortDescription": "700",
                        "HierarchicalID": "700",
                        "Index": 14
                      },
                      "800": {
                        "ID": "800",
                        "Description": "800",
                        "ShortDescription": "800",
                        "HierarchicalID": "800",
                        "Index": 15
                      },
                      "900": {
                        "ID": "900",
                        "Description": "900",
                        "ShortDescription": "900",
                        "HierarchicalID": "900",
                        "Index": 16
                      },
                      "1000": {
                        "ID": "1000",
                        "Description": "1,000",
                        "ShortDescription": "1k",
                        "HierarchicalID": "1000",
                        "Index": 17
                      },
                      "1250": {
                        "ID": "1250",
                        "Description": "1,250",
                        "ShortDescription": "1.25k",
                        "HierarchicalID": "1250",
                        "Index": 18
                      },
                      "1500": {
                        "ID": "1500",
                        "Description": "1,500",
                        "ShortDescription": "1.5k",
                        "HierarchicalID": "1500",
                        "Index": 19
                      },
                      "1750": {
                        "ID": "1750",
                        "Description": "1,750",
                        "ShortDescription": "1.75k",
                        "HierarchicalID": "1750",
                        "Index": 20
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2,000",
                        "ShortDescription": "2k",
                        "HierarchicalID": "2000",
                        "Index": 21
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No max",
                        "ShortDescription": "No max",
                        "HierarchicalID": "-1",
                        "Index": 22
                      }
                    },
                    "Index": 1
                  }
                },
                "LandArea_Acre": {
                  "MinLandArea": {
                    "ID": "MinLandArea",
                    "Description": "minimum",
                    "ShortDescription": "min",
                    "Options": {
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 1
                      },
                      "75": {
                        "ID": "75",
                        "Description": "75",
                        "ShortDescription": "75",
                        "HierarchicalID": "75",
                        "Index": 2
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 3
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 4
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 5
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 6
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 7
                      },
                      "250": {
                        "ID": "250",
                        "Description": "250",
                        "ShortDescription": "250",
                        "HierarchicalID": "250",
                        "Index": 8
                      },
                      "300": {
                        "ID": "300",
                        "Description": "300",
                        "ShortDescription": "300",
                        "HierarchicalID": "300",
                        "Index": 9
                      },
                      "350": {
                        "ID": "350",
                        "Description": "350",
                        "ShortDescription": "350",
                        "HierarchicalID": "350",
                        "Index": 10
                      },
                      "400": {
                        "ID": "400",
                        "Description": "400",
                        "ShortDescription": "400",
                        "HierarchicalID": "400",
                        "Index": 11
                      },
                      "450": {
                        "ID": "450",
                        "Description": "450",
                        "ShortDescription": "450",
                        "HierarchicalID": "450",
                        "Index": 12
                      },
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 13
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No min",
                        "ShortDescription": "No min",
                        "HierarchicalID": "-1",
                        "Index": 0
                      }
                    },
                    "Index": 0
                  },
                  "MaxLandArea": {
                    "ID": "MaxLandArea",
                    "Description": "maximum",
                    "Options": {
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 0
                      },
                      "75": {
                        "ID": "75",
                        "Description": "75",
                        "ShortDescription": "75",
                        "HierarchicalID": "75",
                        "Index": 1
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 2
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 3
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 4
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 5
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 6
                      },
                      "250": {
                        "ID": "250",
                        "Description": "250",
                        "ShortDescription": "250",
                        "HierarchicalID": "250",
                        "Index": 7
                      },
                      "300": {
                        "ID": "300",
                        "Description": "300",
                        "ShortDescription": "300",
                        "HierarchicalID": "300",
                        "Index": 8
                      },
                      "350": {
                        "ID": "350",
                        "Description": "350",
                        "ShortDescription": "350",
                        "HierarchicalID": "350",
                        "Index": 9
                      },
                      "400": {
                        "ID": "400",
                        "Description": "400",
                        "ShortDescription": "400",
                        "HierarchicalID": "400",
                        "Index": 10
                      },
                      "450": {
                        "ID": "450",
                        "Description": "450",
                        "ShortDescription": "450",
                        "HierarchicalID": "450",
                        "Index": 11
                      },
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 12
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No max",
                        "ShortDescription": "No max",
                        "HierarchicalID": "-1",
                        "Index": 13
                      }
                    },
                    "Index": 1
                  }
                },
                "LandArea_Hectare": {
                  "MinLandArea": {
                    "ID": "MinLandArea",
                    "Description": "minimum",
                    "ShortDescription": "min",
                    "Options": {
                      "10": {
                        "ID": "10",
                        "Description": "10",
                        "ShortDescription": "10",
                        "HierarchicalID": "10",
                        "Index": 1
                      },
                      "20": {
                        "ID": "20",
                        "Description": "20",
                        "ShortDescription": "20",
                        "HierarchicalID": "20",
                        "Index": 2
                      },
                      "30": {
                        "ID": "30",
                        "Description": "30",
                        "ShortDescription": "30",
                        "HierarchicalID": "30",
                        "Index": 3
                      },
                      "40": {
                        "ID": "40",
                        "Description": "40",
                        "ShortDescription": "40",
                        "HierarchicalID": "40",
                        "Index": 4
                      },
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 5
                      },
                      "60": {
                        "ID": "60",
                        "Description": "60",
                        "ShortDescription": "60",
                        "HierarchicalID": "60",
                        "Index": 6
                      },
                      "70": {
                        "ID": "70",
                        "Description": "70",
                        "ShortDescription": "70",
                        "HierarchicalID": "70",
                        "Index": 7
                      },
                      "80": {
                        "ID": "80",
                        "Description": "80",
                        "ShortDescription": "80",
                        "HierarchicalID": "80",
                        "Index": 8
                      },
                      "90": {
                        "ID": "90",
                        "Description": "90",
                        "ShortDescription": "90",
                        "HierarchicalID": "90",
                        "Index": 9
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 10
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 11
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 12
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 13
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 14
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No min",
                        "ShortDescription": "No min",
                        "HierarchicalID": "-1",
                        "Index": 0
                      }
                    },
                    "Index": 0
                  },
                  "MaxLandArea": {
                    "ID": "MaxLandArea",
                    "Description": "maximum",
                    "Options": {
                      "10": {
                        "ID": "10",
                        "Description": "10",
                        "ShortDescription": "10",
                        "HierarchicalID": "10",
                        "Index": 0
                      },
                      "20": {
                        "ID": "20",
                        "Description": "20",
                        "ShortDescription": "20",
                        "HierarchicalID": "20",
                        "Index": 1
                      },
                      "30": {
                        "ID": "30",
                        "Description": "30",
                        "ShortDescription": "30",
                        "HierarchicalID": "30",
                        "Index": 2
                      },
                      "40": {
                        "ID": "40",
                        "Description": "40",
                        "ShortDescription": "40",
                        "HierarchicalID": "40",
                        "Index": 3
                      },
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 4
                      },
                      "60": {
                        "ID": "60",
                        "Description": "60",
                        "ShortDescription": "60",
                        "HierarchicalID": "60",
                        "Index": 5
                      },
                      "70": {
                        "ID": "70",
                        "Description": "70",
                        "ShortDescription": "70",
                        "HierarchicalID": "70",
                        "Index": 6
                      },
                      "80": {
                        "ID": "80",
                        "Description": "80",
                        "ShortDescription": "80",
                        "HierarchicalID": "80",
                        "Index": 7
                      },
                      "90": {
                        "ID": "90",
                        "Description": "90",
                        "ShortDescription": "90",
                        "HierarchicalID": "90",
                        "Index": 8
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 9
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 10
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 11
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 12
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 13
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No max",
                        "ShortDescription": "No max",
                        "HierarchicalID": "-1",
                        "Index": 14
                      }
                    },
                    "Index": 1
                  }
                },
                "SaleableArea_SquareFeet": {
                  "MinSaleableArea": {
                    "ID": "MinSaleableArea",
                    "Description": "minimum",
                    "ShortDescription": "min",
                    "Options": {
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 1
                      },
                      "750": {
                        "ID": "750",
                        "Description": "750",
                        "ShortDescription": "750",
                        "HierarchicalID": "750",
                        "Index": 2
                      },
                      "1000": {
                        "ID": "1000",
                        "Description": "1,000",
                        "ShortDescription": "1k",
                        "HierarchicalID": "1000",
                        "Index": 3
                      },
                      "1250": {
                        "ID": "1250",
                        "Description": "1,250",
                        "ShortDescription": "1.25k",
                        "HierarchicalID": "1250",
                        "Index": 4
                      },
                      "1500": {
                        "ID": "1500",
                        "Description": "1,500",
                        "ShortDescription": "1.5k",
                        "HierarchicalID": "1500",
                        "Index": 5
                      },
                      "1750": {
                        "ID": "1750",
                        "Description": "1,750",
                        "ShortDescription": "1.75k",
                        "HierarchicalID": "1750",
                        "Index": 6
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2,000",
                        "ShortDescription": "2k",
                        "HierarchicalID": "2000",
                        "Index": 7
                      },
                      "2500": {
                        "ID": "2500",
                        "Description": "2,500",
                        "ShortDescription": "2.5k",
                        "HierarchicalID": "2500",
                        "Index": 8
                      },
                      "3000": {
                        "ID": "3000",
                        "Description": "3,000",
                        "ShortDescription": "3k",
                        "HierarchicalID": "3000",
                        "Index": 9
                      },
                      "3500": {
                        "ID": "3500",
                        "Description": "3,500",
                        "ShortDescription": "3.5k",
                        "HierarchicalID": "3500",
                        "Index": 10
                      },
                      "4000": {
                        "ID": "4000",
                        "Description": "4,000",
                        "ShortDescription": "4k",
                        "HierarchicalID": "4000",
                        "Index": 11
                      },
                      "4500": {
                        "ID": "4500",
                        "Description": "4,500",
                        "ShortDescription": "4.5k",
                        "HierarchicalID": "4500",
                        "Index": 12
                      },
                      "5000": {
                        "ID": "5000",
                        "Description": "5,000",
                        "ShortDescription": "5k",
                        "HierarchicalID": "5000",
                        "Index": 13
                      },
                      "6000": {
                        "ID": "6000",
                        "Description": "6,000",
                        "ShortDescription": "6k",
                        "HierarchicalID": "6000",
                        "Index": 14
                      },
                      "7000": {
                        "ID": "7000",
                        "Description": "7,000",
                        "ShortDescription": "7k",
                        "HierarchicalID": "7000",
                        "Index": 15
                      },
                      "8000": {
                        "ID": "8000",
                        "Description": "8,000",
                        "ShortDescription": "8k",
                        "HierarchicalID": "8000",
                        "Index": 16
                      },
                      "9000": {
                        "ID": "9000",
                        "Description": "9,000",
                        "ShortDescription": "9k",
                        "HierarchicalID": "9000",
                        "Index": 17
                      },
                      "10000": {
                        "ID": "10000",
                        "Description": "10,000",
                        "ShortDescription": "10k",
                        "HierarchicalID": "10000",
                        "Index": 18
                      },
                      "12500": {
                        "ID": "12500",
                        "Description": "12,500",
                        "ShortDescription": "12.5k",
                        "HierarchicalID": "12500",
                        "Index": 19
                      },
                      "15000": {
                        "ID": "15000",
                        "Description": "15,000",
                        "ShortDescription": "15k",
                        "HierarchicalID": "15000",
                        "Index": 20
                      },
                      "17500": {
                        "ID": "17500",
                        "Description": "17,500",
                        "ShortDescription": "17.5k",
                        "HierarchicalID": "17500",
                        "Index": 21
                      },
                      "20000": {
                        "ID": "20000",
                        "Description": "20,000",
                        "ShortDescription": "20k",
                        "HierarchicalID": "20000",
                        "Index": 22
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No min",
                        "ShortDescription": "No min",
                        "HierarchicalID": "-1",
                        "Index": 0
                      }
                    },
                    "Index": 0
                  },
                  "MaxSaleableArea": {
                    "ID": "MaxSaleableArea",
                    "Description": "maximum",
                    "Options": {
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 0
                      },
                      "750": {
                        "ID": "750",
                        "Description": "750",
                        "ShortDescription": "750",
                        "HierarchicalID": "750",
                        "Index": 1
                      },
                      "1000": {
                        "ID": "1000",
                        "Description": "1,000",
                        "ShortDescription": "1k",
                        "HierarchicalID": "1000",
                        "Index": 2
                      },
                      "1250": {
                        "ID": "1250",
                        "Description": "1,250",
                        "ShortDescription": "1.25k",
                        "HierarchicalID": "1250",
                        "Index": 3
                      },
                      "1500": {
                        "ID": "1500",
                        "Description": "1,500",
                        "ShortDescription": "1.5k",
                        "HierarchicalID": "1500",
                        "Index": 4
                      },
                      "1750": {
                        "ID": "1750",
                        "Description": "1,750",
                        "ShortDescription": "1.75k",
                        "HierarchicalID": "1750",
                        "Index": 5
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2,000",
                        "ShortDescription": "2k",
                        "HierarchicalID": "2000",
                        "Index": 6
                      },
                      "2500": {
                        "ID": "2500",
                        "Description": "2,500",
                        "ShortDescription": "2.5k",
                        "HierarchicalID": "2500",
                        "Index": 7
                      },
                      "3000": {
                        "ID": "3000",
                        "Description": "3,000",
                        "ShortDescription": "3k",
                        "HierarchicalID": "3000",
                        "Index": 8
                      },
                      "3500": {
                        "ID": "3500",
                        "Description": "3,500",
                        "ShortDescription": "3.5k",
                        "HierarchicalID": "3500",
                        "Index": 9
                      },
                      "4000": {
                        "ID": "4000",
                        "Description": "4,000",
                        "ShortDescription": "4k",
                        "HierarchicalID": "4000",
                        "Index": 10
                      },
                      "4500": {
                        "ID": "4500",
                        "Description": "4,500",
                        "ShortDescription": "4.5k",
                        "HierarchicalID": "4500",
                        "Index": 11
                      },
                      "5000": {
                        "ID": "5000",
                        "Description": "5,000",
                        "ShortDescription": "5k",
                        "HierarchicalID": "5000",
                        "Index": 12
                      },
                      "6000": {
                        "ID": "6000",
                        "Description": "6,000",
                        "ShortDescription": "6k",
                        "HierarchicalID": "6000",
                        "Index": 13
                      },
                      "7000": {
                        "ID": "7000",
                        "Description": "7,000",
                        "ShortDescription": "7k",
                        "HierarchicalID": "7000",
                        "Index": 14
                      },
                      "8000": {
                        "ID": "8000",
                        "Description": "8,000",
                        "ShortDescription": "8k",
                        "HierarchicalID": "8000",
                        "Index": 15
                      },
                      "9000": {
                        "ID": "9000",
                        "Description": "9,000",
                        "ShortDescription": "9k",
                        "HierarchicalID": "9000",
                        "Index": 16
                      },
                      "10000": {
                        "ID": "10000",
                        "Description": "10,000",
                        "ShortDescription": "10k",
                        "HierarchicalID": "10000",
                        "Index": 17
                      },
                      "12500": {
                        "ID": "12500",
                        "Description": "12,500",
                        "ShortDescription": "12.5k",
                        "HierarchicalID": "12500",
                        "Index": 18
                      },
                      "15000": {
                        "ID": "15000",
                        "Description": "15,000",
                        "ShortDescription": "15k",
                        "HierarchicalID": "15000",
                        "Index": 19
                      },
                      "17500": {
                        "ID": "17500",
                        "Description": "17,500",
                        "ShortDescription": "17.5k",
                        "HierarchicalID": "17500",
                        "Index": 20
                      },
                      "20000": {
                        "ID": "20000",
                        "Description": "20,000",
                        "ShortDescription": "20k",
                        "HierarchicalID": "20000",
                        "Index": 21
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No max",
                        "ShortDescription": "No max",
                        "HierarchicalID": "-1",
                        "Index": 22
                      }
                    },
                    "Index": 1
                  }
                },
                "SaleableArea_SquareMeter": {
                  "MinSaleableArea": {
                    "ID": "MinSaleableArea",
                    "Description": "minimum",
                    "ShortDescription": "min",
                    "Options": {
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 1
                      },
                      "75": {
                        "ID": "75",
                        "Description": "75",
                        "ShortDescription": "75",
                        "HierarchicalID": "75",
                        "Index": 2
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 3
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 4
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 5
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 6
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 7
                      },
                      "250": {
                        "ID": "250",
                        "Description": "250",
                        "ShortDescription": "250",
                        "HierarchicalID": "250",
                        "Index": 8
                      },
                      "300": {
                        "ID": "300",
                        "Description": "300",
                        "ShortDescription": "300",
                        "HierarchicalID": "300",
                        "Index": 9
                      },
                      "350": {
                        "ID": "350",
                        "Description": "350",
                        "ShortDescription": "350",
                        "HierarchicalID": "350",
                        "Index": 10
                      },
                      "400": {
                        "ID": "400",
                        "Description": "400",
                        "ShortDescription": "400",
                        "HierarchicalID": "400",
                        "Index": 11
                      },
                      "450": {
                        "ID": "450",
                        "Description": "450",
                        "ShortDescription": "450",
                        "HierarchicalID": "450",
                        "Index": 12
                      },
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 13
                      },
                      "600": {
                        "ID": "600",
                        "Description": "600",
                        "ShortDescription": "600",
                        "HierarchicalID": "600",
                        "Index": 14
                      },
                      "700": {
                        "ID": "700",
                        "Description": "700",
                        "ShortDescription": "700",
                        "HierarchicalID": "700",
                        "Index": 15
                      },
                      "800": {
                        "ID": "800",
                        "Description": "800",
                        "ShortDescription": "800",
                        "HierarchicalID": "800",
                        "Index": 16
                      },
                      "900": {
                        "ID": "900",
                        "Description": "900",
                        "ShortDescription": "900",
                        "HierarchicalID": "900",
                        "Index": 17
                      },
                      "1000": {
                        "ID": "1000",
                        "Description": "1,000",
                        "ShortDescription": "1k",
                        "HierarchicalID": "1000",
                        "Index": 18
                      },
                      "1250": {
                        "ID": "1250",
                        "Description": "1,250",
                        "ShortDescription": "1.25k",
                        "HierarchicalID": "1250",
                        "Index": 19
                      },
                      "1500": {
                        "ID": "1500",
                        "Description": "1,500",
                        "ShortDescription": "1.5k",
                        "HierarchicalID": "1500",
                        "Index": 20
                      },
                      "1750": {
                        "ID": "1750",
                        "Description": "1,750",
                        "ShortDescription": "1.75k",
                        "HierarchicalID": "1750",
                        "Index": 21
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2,000",
                        "ShortDescription": "2k",
                        "HierarchicalID": "2000",
                        "Index": 22
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No min",
                        "ShortDescription": "No min",
                        "HierarchicalID": "-1",
                        "Index": 0
                      }
                    },
                    "Index": 0
                  },
                  "MaxSaleableArea": {
                    "ID": "MaxSaleableArea",
                    "Description": "maximum",
                    "Options": {
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 0
                      },
                      "75": {
                        "ID": "75",
                        "Description": "75",
                        "ShortDescription": "75",
                        "HierarchicalID": "75",
                        "Index": 1
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 2
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 3
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 4
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 5
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 6
                      },
                      "250": {
                        "ID": "250",
                        "Description": "250",
                        "ShortDescription": "250",
                        "HierarchicalID": "250",
                        "Index": 7
                      },
                      "300": {
                        "ID": "300",
                        "Description": "300",
                        "ShortDescription": "300",
                        "HierarchicalID": "300",
                        "Index": 8
                      },
                      "350": {
                        "ID": "350",
                        "Description": "350",
                        "ShortDescription": "350",
                        "HierarchicalID": "350",
                        "Index": 9
                      },
                      "400": {
                        "ID": "400",
                        "Description": "400",
                        "ShortDescription": "400",
                        "HierarchicalID": "400",
                        "Index": 10
                      },
                      "450": {
                        "ID": "450",
                        "Description": "450",
                        "ShortDescription": "450",
                        "HierarchicalID": "450",
                        "Index": 11
                      },
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 12
                      },
                      "600": {
                        "ID": "600",
                        "Description": "600",
                        "ShortDescription": "600",
                        "HierarchicalID": "600",
                        "Index": 13
                      },
                      "700": {
                        "ID": "700",
                        "Description": "700",
                        "ShortDescription": "700",
                        "HierarchicalID": "700",
                        "Index": 14
                      },
                      "800": {
                        "ID": "800",
                        "Description": "800",
                        "ShortDescription": "800",
                        "HierarchicalID": "800",
                        "Index": 15
                      },
                      "900": {
                        "ID": "900",
                        "Description": "900",
                        "ShortDescription": "900",
                        "HierarchicalID": "900",
                        "Index": 16
                      },
                      "1000": {
                        "ID": "1000",
                        "Description": "1,000",
                        "ShortDescription": "1k",
                        "HierarchicalID": "1000",
                        "Index": 17
                      },
                      "1250": {
                        "ID": "1250",
                        "Description": "1,250",
                        "ShortDescription": "1.25k",
                        "HierarchicalID": "1250",
                        "Index": 18
                      },
                      "1500": {
                        "ID": "1500",
                        "Description": "1,500",
                        "ShortDescription": "1.5k",
                        "HierarchicalID": "1500",
                        "Index": 19
                      },
                      "1750": {
                        "ID": "1750",
                        "Description": "1,750",
                        "ShortDescription": "1.75k",
                        "HierarchicalID": "1750",
                        "Index": 20
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2,000",
                        "ShortDescription": "2k",
                        "HierarchicalID": "2000",
                        "Index": 21
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No max",
                        "ShortDescription": "No max",
                        "HierarchicalID": "-1",
                        "Index": 22
                      }
                    },
                    "Index": 1
                  }
                },
                "SaleableArea_Acre": {
                  "MinSaleableArea": {
                    "ID": "MinSaleableArea",
                    "Description": "minimum",
                    "ShortDescription": "min",
                    "Options": {
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 1
                      },
                      "75": {
                        "ID": "75",
                        "Description": "75",
                        "ShortDescription": "75",
                        "HierarchicalID": "75",
                        "Index": 2
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 3
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 4
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 5
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 6
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 7
                      },
                      "250": {
                        "ID": "250",
                        "Description": "250",
                        "ShortDescription": "250",
                        "HierarchicalID": "250",
                        "Index": 8
                      },
                      "300": {
                        "ID": "300",
                        "Description": "300",
                        "ShortDescription": "300",
                        "HierarchicalID": "300",
                        "Index": 9
                      },
                      "350": {
                        "ID": "350",
                        "Description": "350",
                        "ShortDescription": "350",
                        "HierarchicalID": "350",
                        "Index": 10
                      },
                      "400": {
                        "ID": "400",
                        "Description": "400",
                        "ShortDescription": "400",
                        "HierarchicalID": "400",
                        "Index": 11
                      },
                      "450": {
                        "ID": "450",
                        "Description": "450",
                        "ShortDescription": "450",
                        "HierarchicalID": "450",
                        "Index": 12
                      },
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 13
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No min",
                        "ShortDescription": "No min",
                        "HierarchicalID": "-1",
                        "Index": 0
                      }
                    },
                    "Index": 0
                  },
                  "MaxSaleableArea": {
                    "ID": "MaxSaleableArea",
                    "Description": "maximum",
                    "Options": {
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 0
                      },
                      "75": {
                        "ID": "75",
                        "Description": "75",
                        "ShortDescription": "75",
                        "HierarchicalID": "75",
                        "Index": 1
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 2
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 3
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 4
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 5
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 6
                      },
                      "250": {
                        "ID": "250",
                        "Description": "250",
                        "ShortDescription": "250",
                        "HierarchicalID": "250",
                        "Index": 7
                      },
                      "300": {
                        "ID": "300",
                        "Description": "300",
                        "ShortDescription": "300",
                        "HierarchicalID": "300",
                        "Index": 8
                      },
                      "350": {
                        "ID": "350",
                        "Description": "350",
                        "ShortDescription": "350",
                        "HierarchicalID": "350",
                        "Index": 9
                      },
                      "400": {
                        "ID": "400",
                        "Description": "400",
                        "ShortDescription": "400",
                        "HierarchicalID": "400",
                        "Index": 10
                      },
                      "450": {
                        "ID": "450",
                        "Description": "450",
                        "ShortDescription": "450",
                        "HierarchicalID": "450",
                        "Index": 11
                      },
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 12
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No max",
                        "ShortDescription": "No max",
                        "HierarchicalID": "-1",
                        "Index": 13
                      }
                    },
                    "Index": 1
                  }
                },
                "SaleableArea_Hectare": {
                  "MinSaleableArea": {
                    "ID": "MinSaleableArea",
                    "Description": "minimum",
                    "ShortDescription": "min",
                    "Options": {
                      "10": {
                        "ID": "10",
                        "Description": "10",
                        "ShortDescription": "10",
                        "HierarchicalID": "10",
                        "Index": 1
                      },
                      "20": {
                        "ID": "20",
                        "Description": "20",
                        "ShortDescription": "20",
                        "HierarchicalID": "20",
                        "Index": 2
                      },
                      "30": {
                        "ID": "30",
                        "Description": "30",
                        "ShortDescription": "30",
                        "HierarchicalID": "30",
                        "Index": 3
                      },
                      "40": {
                        "ID": "40",
                        "Description": "40",
                        "ShortDescription": "40",
                        "HierarchicalID": "40",
                        "Index": 4
                      },
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 5
                      },
                      "60": {
                        "ID": "60",
                        "Description": "60",
                        "ShortDescription": "60",
                        "HierarchicalID": "60",
                        "Index": 6
                      },
                      "70": {
                        "ID": "70",
                        "Description": "70",
                        "ShortDescription": "70",
                        "HierarchicalID": "70",
                        "Index": 7
                      },
                      "80": {
                        "ID": "80",
                        "Description": "80",
                        "ShortDescription": "80",
                        "HierarchicalID": "80",
                        "Index": 8
                      },
                      "90": {
                        "ID": "90",
                        "Description": "90",
                        "ShortDescription": "90",
                        "HierarchicalID": "90",
                        "Index": 9
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 10
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 11
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 12
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 13
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 14
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No min",
                        "ShortDescription": "No min",
                        "HierarchicalID": "-1",
                        "Index": 0
                      }
                    },
                    "Index": 0
                  },
                  "MaxSaleableArea": {
                    "ID": "MaxSaleableArea",
                    "Description": "maximum",
                    "Options": {
                      "10": {
                        "ID": "10",
                        "Description": "10",
                        "ShortDescription": "10",
                        "HierarchicalID": "10",
                        "Index": 0
                      },
                      "20": {
                        "ID": "20",
                        "Description": "20",
                        "ShortDescription": "20",
                        "HierarchicalID": "20",
                        "Index": 1
                      },
                      "30": {
                        "ID": "30",
                        "Description": "30",
                        "ShortDescription": "30",
                        "HierarchicalID": "30",
                        "Index": 2
                      },
                      "40": {
                        "ID": "40",
                        "Description": "40",
                        "ShortDescription": "40",
                        "HierarchicalID": "40",
                        "Index": 3
                      },
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 4
                      },
                      "60": {
                        "ID": "60",
                        "Description": "60",
                        "ShortDescription": "60",
                        "HierarchicalID": "60",
                        "Index": 5
                      },
                      "70": {
                        "ID": "70",
                        "Description": "70",
                        "ShortDescription": "70",
                        "HierarchicalID": "70",
                        "Index": 6
                      },
                      "80": {
                        "ID": "80",
                        "Description": "80",
                        "ShortDescription": "80",
                        "HierarchicalID": "80",
                        "Index": 7
                      },
                      "90": {
                        "ID": "90",
                        "Description": "90",
                        "ShortDescription": "90",
                        "HierarchicalID": "90",
                        "Index": 8
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 9
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 10
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 11
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 12
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 13
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No max",
                        "ShortDescription": "No max",
                        "HierarchicalID": "-1",
                        "Index": 14
                      }
                    },
                    "Index": 1
                  }
                },
                "PriceInterval_Rent_Week": {
                  "AED": {
                    "ID": "AED",
                    "Description": "AED",
                    "Index": 0
                  },
                  "ARS": {
                    "ID": "ARS",
                    "Description": "ARS",
                    "Index": 1
                  },
                  "AUD": {
                    "ID": "AUD",
                    "Description": "AUD",
                    "Index": 2
                  },
                  "BGN": {
                    "ID": "BGN",
                    "Description": "BGN",
                    "Index": 3
                  },
                  "BHD": {
                    "ID": "BHD",
                    "Description": "BHD",
                    "Index": 4
                  },
                  "BND": {
                    "ID": "BND",
                    "Description": "BND",
                    "Index": 5
                  },
                  "BRL": {
                    "ID": "BRL",
                    "Description": "BRL",
                    "Index": 6
                  },
                  "BWP": {
                    "ID": "BWP",
                    "Description": "BWP",
                    "Index": 7
                  },
                  "CAD": {
                    "ID": "CAD",
                    "Description": "CAD",
                    "Index": 8
                  },
                  "CHF": {
                    "ID": "CHF",
                    "Description": "CHF",
                    "Index": 9
                  },
                  "CLP": {
                    "ID": "CLP",
                    "Description": "CLP",
                    "Index": 10
                  },
                  "CNY": {
                    "ID": "CNY",
                    "Description": "CNY",
                    "Index": 11
                  },
                  "COP": {
                    "ID": "COP",
                    "Description": "COP",
                    "Index": 12
                  },
                  "CZK": {
                    "ID": "CZK",
                    "Description": "CZK",
                    "Index": 13
                  },
                  "DKK": {
                    "ID": "DKK",
                    "Description": "DKK",
                    "Index": 14
                  },
                  "EGP": {
                    "ID": "EGP",
                    "Description": "EGP",
                    "Index": 15
                  },
                  "EUR": {
                    "ID": "EUR",
                    "Description": "EUR",
                    "Index": 16
                  },
                  "GBP": {
                    "ID": "GBP",
                    "Description": "GBP",
                    "Options": {
                      "MinPrice": {
                        "ID": "MinPrice",
                        "Description": "minimum",
                        "ShortDescription": "min",
                        "Options": {
                          "100": {
                            "ID": "100",
                            "Description": "£100",
                            "ShortDescription": "£100",
                            "Index": 1
                          },
                          "125": {
                            "ID": "125",
                            "Description": "£125",
                            "ShortDescription": "£125",
                            "Index": 2
                          },
                          "150": {
                            "ID": "150",
                            "Description": "£150",
                            "ShortDescription": "£150",
                            "Index": 3
                          },
                          "175": {
                            "ID": "175",
                            "Description": "£175",
                            "ShortDescription": "£175",
                            "Index": 4
                          },
                          "200": {
                            "ID": "200",
                            "Description": "£200",
                            "ShortDescription": "£200",
                            "Index": 5
                          },
                          "225": {
                            "ID": "225",
                            "Description": "£225",
                            "ShortDescription": "£225",
                            "Index": 6
                          },
                          "250": {
                            "ID": "250",
                            "Description": "£250",
                            "ShortDescription": "£250",
                            "Index": 7
                          },
                          "275": {
                            "ID": "275",
                            "Description": "£275",
                            "ShortDescription": "£275",
                            "Index": 8
                          },
                          "300": {
                            "ID": "300",
                            "Description": "£300",
                            "ShortDescription": "£300",
                            "Index": 9
                          },
                          "325": {
                            "ID": "325",
                            "Description": "£325",
                            "ShortDescription": "£325",
                            "Index": 10
                          },
                          "350": {
                            "ID": "350",
                            "Description": "£350",
                            "ShortDescription": "£350",
                            "Index": 11
                          },
                          "375": {
                            "ID": "375",
                            "Description": "£375",
                            "ShortDescription": "£375",
                            "Index": 12
                          },
                          "400": {
                            "ID": "400",
                            "Description": "£400",
                            "ShortDescription": "£400",
                            "Index": 13
                          },
                          "425": {
                            "ID": "425",
                            "Description": "£425",
                            "ShortDescription": "£425",
                            "Index": 14
                          },
                          "450": {
                            "ID": "450",
                            "Description": "£450",
                            "ShortDescription": "£450",
                            "Index": 15
                          },
                          "475": {
                            "ID": "475",
                            "Description": "£475",
                            "ShortDescription": "£475",
                            "Index": 16
                          },
                          "500": {
                            "ID": "500",
                            "Description": "£500",
                            "ShortDescription": "£500",
                            "Index": 17
                          },
                          "563": {
                            "ID": "563",
                            "Description": "£563",
                            "ShortDescription": "£563",
                            "Index": 18
                          },
                          "625": {
                            "ID": "625",
                            "Description": "£625",
                            "ShortDescription": "£625",
                            "Index": 19
                          },
                          "688": {
                            "ID": "688",
                            "Description": "£688",
                            "ShortDescription": "£688",
                            "Index": 20
                          },
                          "750": {
                            "ID": "750",
                            "Description": "£750",
                            "ShortDescription": "£750",
                            "Index": 21
                          },
                          "875": {
                            "ID": "875",
                            "Description": "£875",
                            "ShortDescription": "£875",
                            "Index": 22
                          },
                          "1000": {
                            "ID": "1000",
                            "Description": "£1,000",
                            "ShortDescription": "£1k",
                            "Index": 23
                          },
                          "1130": {
                            "ID": "1130",
                            "Description": "£1,130",
                            "ShortDescription": "£1.13k",
                            "Index": 24
                          },
                          "1250": {
                            "ID": "1250",
                            "Description": "£1,250",
                            "ShortDescription": "£1.25k",
                            "Index": 25
                          },
                          "1500": {
                            "ID": "1500",
                            "Description": "£1,500",
                            "ShortDescription": "£1.5k",
                            "Index": 26
                          },
                          "1750": {
                            "ID": "1750",
                            "Description": "£1,750",
                            "ShortDescription": "£1.75k",
                            "Index": 27
                          },
                          "2000": {
                            "ID": "2000",
                            "Description": "£2,000",
                            "ShortDescription": "£2k",
                            "Index": 28
                          },
                          "2250": {
                            "ID": "2250",
                            "Description": "£2,250",
                            "ShortDescription": "£2.25k",
                            "Index": 29
                          },
                          "2500": {
                            "ID": "2500",
                            "Description": "£2,500",
                            "ShortDescription": "£2.5k",
                            "Index": 30
                          },
                          "3130": {
                            "ID": "3130",
                            "Description": "£3,130",
                            "ShortDescription": "£3.13k",
                            "Index": 31
                          },
                          "3750": {
                            "ID": "3750",
                            "Description": "£3,750",
                            "ShortDescription": "£3.75k",
                            "Index": 32
                          },
                          "4380": {
                            "ID": "4380",
                            "Description": "£4,380",
                            "ShortDescription": "£4.38k",
                            "Index": 33
                          },
                          "5000": {
                            "ID": "5000",
                            "Description": "£5,000",
                            "ShortDescription": "£5k",
                            "Index": 34
                          },
                          "6250": {
                            "ID": "6250",
                            "Description": "£6,250",
                            "ShortDescription": "£6.25k",
                            "Index": 35
                          },
                          "7500": {
                            "ID": "7500",
                            "Description": "£7,500",
                            "ShortDescription": "£7.5k",
                            "Index": 36
                          },
                          "8750": {
                            "ID": "8750",
                            "Description": "£8,750",
                            "ShortDescription": "£8.75k",
                            "Index": 37
                          },
                          "10000": {
                            "ID": "10000",
                            "Description": "£10,000",
                            "ShortDescription": "£10k",
                            "Index": 38
                          },
                          "-1": {
                            "ID": "-1",
                            "Description": "No min",
                            "ShortDescription": "No min",
                            "HierarchicalID": "-1",
                            "Index": 0
                          }
                        },
                        "Index": 0
                      },
                      "MaxPrice": {
                        "ID": "MaxPrice",
                        "Description": "maximum",
                        "ShortDescription": "max",
                        "Options": {
                          "100": {
                            "ID": "100",
                            "Description": "£100",
                            "ShortDescription": "£100",
                            "Index": 0
                          },
                          "125": {
                            "ID": "125",
                            "Description": "£125",
                            "ShortDescription": "£125",
                            "Index": 1
                          },
                          "150": {
                            "ID": "150",
                            "Description": "£150",
                            "ShortDescription": "£150",
                            "Index": 2
                          },
                          "175": {
                            "ID": "175",
                            "Description": "£175",
                            "ShortDescription": "£175",
                            "Index": 3
                          },
                          "200": {
                            "ID": "200",
                            "Description": "£200",
                            "ShortDescription": "£200",
                            "Index": 4
                          },
                          "225": {
                            "ID": "225",
                            "Description": "£225",
                            "ShortDescription": "£225",
                            "Index": 5
                          },
                          "250": {
                            "ID": "250",
                            "Description": "£250",
                            "ShortDescription": "£250",
                            "Index": 6
                          },
                          "275": {
                            "ID": "275",
                            "Description": "£275",
                            "ShortDescription": "£275",
                            "Index": 7
                          },
                          "300": {
                            "ID": "300",
                            "Description": "£300",
                            "ShortDescription": "£300",
                            "Index": 8
                          },
                          "325": {
                            "ID": "325",
                            "Description": "£325",
                            "ShortDescription": "£325",
                            "Index": 9
                          },
                          "350": {
                            "ID": "350",
                            "Description": "£350",
                            "ShortDescription": "£350",
                            "Index": 10
                          },
                          "375": {
                            "ID": "375",
                            "Description": "£375",
                            "ShortDescription": "£375",
                            "Index": 11
                          },
                          "400": {
                            "ID": "400",
                            "Description": "£400",
                            "ShortDescription": "£400",
                            "Index": 12
                          },
                          "425": {
                            "ID": "425",
                            "Description": "£425",
                            "ShortDescription": "£425",
                            "Index": 13
                          },
                          "450": {
                            "ID": "450",
                            "Description": "£450",
                            "ShortDescription": "£450",
                            "Index": 14
                          },
                          "475": {
                            "ID": "475",
                            "Description": "£475",
                            "ShortDescription": "£475",
                            "Index": 15
                          },
                          "500": {
                            "ID": "500",
                            "Description": "£500",
                            "ShortDescription": "£500",
                            "Index": 16
                          },
                          "563": {
                            "ID": "563",
                            "Description": "£563",
                            "ShortDescription": "£563",
                            "Index": 17
                          },
                          "625": {
                            "ID": "625",
                            "Description": "£625",
                            "ShortDescription": "£625",
                            "Index": 18
                          },
                          "688": {
                            "ID": "688",
                            "Description": "£688",
                            "ShortDescription": "£688",
                            "Index": 19
                          },
                          "750": {
                            "ID": "750",
                            "Description": "£750",
                            "ShortDescription": "£750",
                            "Index": 20
                          },
                          "875": {
                            "ID": "875",
                            "Description": "£875",
                            "ShortDescription": "£875",
                            "Index": 21
                          },
                          "1000": {
                            "ID": "1000",
                            "Description": "£1,000",
                            "ShortDescription": "£1k",
                            "Index": 22
                          },
                          "1130": {
                            "ID": "1130",
                            "Description": "£1,130",
                            "ShortDescription": "£1.13k",
                            "Index": 23
                          },
                          "1250": {
                            "ID": "1250",
                            "Description": "£1,250",
                            "ShortDescription": "£1.25k",
                            "Index": 24
                          },
                          "1500": {
                            "ID": "1500",
                            "Description": "£1,500",
                            "ShortDescription": "£1.5k",
                            "Index": 25
                          },
                          "1750": {
                            "ID": "1750",
                            "Description": "£1,750",
                            "ShortDescription": "£1.75k",
                            "Index": 26
                          },
                          "2000": {
                            "ID": "2000",
                            "Description": "£2,000",
                            "ShortDescription": "£2k",
                            "Index": 27
                          },
                          "2250": {
                            "ID": "2250",
                            "Description": "£2,250",
                            "ShortDescription": "£2.25k",
                            "Index": 28
                          },
                          "2500": {
                            "ID": "2500",
                            "Description": "£2,500",
                            "ShortDescription": "£2.5k",
                            "Index": 29
                          },
                          "3130": {
                            "ID": "3130",
                            "Description": "£3,130",
                            "ShortDescription": "£3.13k",
                            "Index": 30
                          },
                          "3750": {
                            "ID": "3750",
                            "Description": "£3,750",
                            "ShortDescription": "£3.75k",
                            "Index": 31
                          },
                          "4380": {
                            "ID": "4380",
                            "Description": "£4,380",
                            "ShortDescription": "£4.38k",
                            "Index": 32
                          },
                          "5000": {
                            "ID": "5000",
                            "Description": "£5,000",
                            "ShortDescription": "£5k",
                            "Index": 33
                          },
                          "6250": {
                            "ID": "6250",
                            "Description": "£6,250",
                            "ShortDescription": "£6.25k",
                            "Index": 34
                          },
                          "7500": {
                            "ID": "7500",
                            "Description": "£7,500",
                            "ShortDescription": "£7.5k",
                            "Index": 35
                          },
                          "8750": {
                            "ID": "8750",
                            "Description": "£8,750",
                            "ShortDescription": "£8.75k",
                            "Index": 36
                          },
                          "10000": {
                            "ID": "10000",
                            "Description": "£10,000",
                            "ShortDescription": "£10k",
                            "Index": 37
                          },
                          "-1": {
                            "ID": "-1",
                            "Description": "No max",
                            "ShortDescription": "No max",
                            "HierarchicalID": "-1",
                            "Index": 38
                          }
                        },
                        "Index": 1
                      }
                    },
                    "Index": 17
                  },
                  "HKD": {
                    "ID": "HKD",
                    "Description": "HKD",
                    "Index": 18
                  },
                  "HRK": {
                    "ID": "HRK",
                    "Description": "HRK",
                    "Index": 19
                  },
                  "HUF": {
                    "ID": "HUF",
                    "Description": "HUF",
                    "Index": 20
                  },
                  "IDR": {
                    "ID": "IDR",
                    "Description": "IDR",
                    "Index": 21
                  },
                  "ILS": {
                    "ID": "ILS",
                    "Description": "ILS",
                    "Index": 22
                  },
                  "INR": {
                    "ID": "INR",
                    "Description": "INR",
                    "Index": 23
                  },
                  "IRR": {
                    "ID": "IRR",
                    "Description": "IRR",
                    "Index": 24
                  },
                  "ISK": {
                    "ID": "ISK",
                    "Description": "ISK",
                    "Index": 25
                  },
                  "JPY": {
                    "ID": "JPY",
                    "Description": "JPY",
                    "Index": 26
                  },
                  "KRW": {
                    "ID": "KRW",
                    "Description": "KRW",
                    "Index": 27
                  },
                  "KWD": {
                    "ID": "KWD",
                    "Description": "KWD",
                    "Index": 28
                  },
                  "KZT": {
                    "ID": "KZT",
                    "Description": "KZT",
                    "Index": 29
                  },
                  "LBP": {
                    "ID": "LBP",
                    "Description": "LBP",
                    "Index": 30
                  },
                  "LKR": {
                    "ID": "LKR",
                    "Description": "LKR",
                    "Index": 31
                  },
                  "LTL": {
                    "ID": "LTL",
                    "Description": "LTL",
                    "Index": 32
                  },
                  "LVL": {
                    "ID": "LVL",
                    "Description": "LVL",
                    "Index": 33
                  },
                  "LYD": {
                    "ID": "LYD",
                    "Description": "LYD",
                    "Index": 34
                  },
                  "MAD": {
                    "ID": "MAD",
                    "Description": "MAD",
                    "Index": 35
                  },
                  "MUR": {
                    "ID": "MUR",
                    "Description": "MUR",
                    "Index": 36
                  },
                  "MXN": {
                    "ID": "MXN",
                    "Description": "MXN",
                    "Index": 37
                  },
                  "MYR": {
                    "ID": "MYR",
                    "Description": "MYR",
                    "Index": 38
                  },
                  "NGN": {
                    "ID": "NGN",
                    "Description": "NGN",
                    "Index": 39
                  },
                  "NOK": {
                    "ID": "NOK",
                    "Description": "NOK",
                    "Index": 40
                  },
                  "NPR": {
                    "ID": "NPR",
                    "Description": "NPR",
                    "Index": 41
                  },
                  "NZD": {
                    "ID": "NZD",
                    "Description": "NZD",
                    "Index": 42
                  },
                  "OMR": {
                    "ID": "OMR",
                    "Description": "OMR",
                    "Index": 43
                  },
                  "PHP": {
                    "ID": "PHP",
                    "Description": "PHP",
                    "Index": 44
                  },
                  "PKR": {
                    "ID": "PKR",
                    "Description": "PKR",
                    "Index": 45
                  },
                  "PLN": {
                    "ID": "PLN",
                    "Description": "PLN",
                    "Index": 46
                  },
                  "QAR": {
                    "ID": "QAR",
                    "Description": "QAR",
                    "Index": 47
                  },
                  "RMB": {
                    "ID": "RMB",
                    "Description": "RMB",
                    "Index": 48
                  },
                  "RON": {
                    "ID": "RON",
                    "Description": "RON",
                    "Index": 49
                  },
                  "RSD": {
                    "ID": "RSD",
                    "Description": "RSD",
                    "Index": 50
                  },
                  "RUB": {
                    "ID": "RUB",
                    "Description": "RUB",
                    "Index": 51
                  },
                  "SAR": {
                    "ID": "SAR",
                    "Description": "SAR",
                    "Index": 52
                  },
                  "SEK": {
                    "ID": "SEK",
                    "Description": "SEK",
                    "Index": 53
                  },
                  "SGD": {
                    "ID": "SGD",
                    "Description": "SGD",
                    "Index": 54
                  },
                  "THB": {
                    "ID": "THB",
                    "Description": "THB",
                    "Index": 55
                  },
                  "TRY": {
                    "ID": "TRY",
                    "Description": "TRY",
                    "Index": 56
                  },
                  "TWD": {
                    "ID": "TWD",
                    "Description": "TWD",
                    "Index": 57
                  },
                  "USD": {
                    "ID": "USD",
                    "Description": "USD",
                    "Index": 58
                  },
                  "VND": {
                    "ID": "VND",
                    "Description": "VND",
                    "Index": 59
                  },
                  "ZAR": {
                    "ID": "ZAR",
                    "Description": "ZAR",
                    "Index": 60
                  }
                },
                "PriceInterval_Rent_Month": {
                  "AED": {
                    "ID": "AED",
                    "Description": "AED",
                    "Index": 0
                  },
                  "ARS": {
                    "ID": "ARS",
                    "Description": "ARS",
                    "Index": 1
                  },
                  "AUD": {
                    "ID": "AUD",
                    "Description": "AUD",
                    "Index": 2
                  },
                  "BGN": {
                    "ID": "BGN",
                    "Description": "BGN",
                    "Index": 3
                  },
                  "BHD": {
                    "ID": "BHD",
                    "Description": "BHD",
                    "Index": 4
                  },
                  "BND": {
                    "ID": "BND",
                    "Description": "BND",
                    "Index": 5
                  },
                  "BRL": {
                    "ID": "BRL",
                    "Description": "BRL",
                    "Index": 6
                  },
                  "BWP": {
                    "ID": "BWP",
                    "Description": "BWP",
                    "Index": 7
                  },
                  "CAD": {
                    "ID": "CAD",
                    "Description": "CAD",
                    "Index": 8
                  },
                  "CHF": {
                    "ID": "CHF",
                    "Description": "CHF",
                    "Index": 9
                  },
                  "CLP": {
                    "ID": "CLP",
                    "Description": "CLP",
                    "Index": 10
                  },
                  "CNY": {
                    "ID": "CNY",
                    "Description": "CNY",
                    "Index": 11
                  },
                  "COP": {
                    "ID": "COP",
                    "Description": "COP",
                    "Index": 12
                  },
                  "CZK": {
                    "ID": "CZK",
                    "Description": "CZK",
                    "Index": 13
                  },
                  "DKK": {
                    "ID": "DKK",
                    "Description": "DKK",
                    "Index": 14
                  },
                  "EGP": {
                    "ID": "EGP",
                    "Description": "EGP",
                    "Index": 15
                  },
                  "EUR": {
                    "ID": "EUR",
                    "Description": "EUR",
                    "Index": 16
                  },
                  "GBP": {
                    "ID": "GBP",
                    "Description": "GBP",
                    "Options": {
                      "MinPrice": {
                        "ID": "MinPrice",
                        "Description": "minimum",
                        "ShortDescription": "min",
                        "Options": {
                          "400": {
                            "ID": "400",
                            "Description": "£400",
                            "ShortDescription": "£400",
                            "Index": 1
                          },
                          "500": {
                            "ID": "500",
                            "Description": "£500",
                            "ShortDescription": "£500",
                            "Index": 2
                          },
                          "600": {
                            "ID": "600",
                            "Description": "£600",
                            "ShortDescription": "£600",
                            "Index": 3
                          },
                          "700": {
                            "ID": "700",
                            "Description": "£700",
                            "ShortDescription": "£700",
                            "Index": 4
                          },
                          "800": {
                            "ID": "800",
                            "Description": "£800",
                            "ShortDescription": "£800",
                            "Index": 5
                          },
                          "900": {
                            "ID": "900",
                            "Description": "£900",
                            "ShortDescription": "£900",
                            "Index": 6
                          },
                          "1000": {
                            "ID": "1000",
                            "Description": "£1,000",
                            "ShortDescription": "£1k",
                            "Index": 7
                          },
                          "2000": {
                            "ID": "2000",
                            "Description": "£2,000",
                            "ShortDescription": "£2k",
                            "Index": 8
                          },
                          "3000": {
                            "ID": "3000",
                            "Description": "£3,000",
                            "ShortDescription": "£3k",
                            "Index": 9
                          },
                          "4000": {
                            "ID": "4000",
                            "Description": "£4,000",
                            "ShortDescription": "£4k",
                            "Index": 10
                          },
                          "5000": {
                            "ID": "5000",
                            "Description": "£5,000",
                            "ShortDescription": "£5k",
                            "Index": 11
                          },
                          "6000": {
                            "ID": "6000",
                            "Description": "£6,000",
                            "ShortDescription": "£6k",
                            "Index": 12
                          },
                          "7000": {
                            "ID": "7000",
                            "Description": "£7,000",
                            "ShortDescription": "£7k",
                            "Index": 13
                          },
                          "8000": {
                            "ID": "8000",
                            "Description": "£8,000",
                            "ShortDescription": "£8k",
                            "Index": 14
                          },
                          "9000": {
                            "ID": "9000",
                            "Description": "£9,000",
                            "ShortDescription": "£9k",
                            "Index": 15
                          },
                          "10000": {
                            "ID": "10000",
                            "Description": "£10,000",
                            "ShortDescription": "£10k",
                            "Index": 16
                          },
                          "20000": {
                            "ID": "20000",
                            "Description": "£20,000",
                            "ShortDescription": "£20k",
                            "Index": 17
                          },
                          "30000": {
                            "ID": "30000",
                            "Description": "£30,000",
                            "ShortDescription": "£30k",
                            "Index": 18
                          },
                          "40000": {
                            "ID": "40000",
                            "Description": "£40,000",
                            "ShortDescription": "£40k",
                            "Index": 19
                          },
                          "-1": {
                            "ID": "-1",
                            "Description": "No min",
                            "ShortDescription": "No min",
                            "HierarchicalID": "-1",
                            "Index": 0
                          }
                        },
                        "Index": 0
                      },
                      "MaxPrice": {
                        "ID": "MaxPrice",
                        "Description": "maximum",
                        "ShortDescription": "max",
                        "Options": {
                          "400": {
                            "ID": "400",
                            "Description": "£400",
                            "ShortDescription": "£400",
                            "Index": 0
                          },
                          "500": {
                            "ID": "500",
                            "Description": "£500",
                            "ShortDescription": "£500",
                            "Index": 1
                          },
                          "600": {
                            "ID": "600",
                            "Description": "£600",
                            "ShortDescription": "£600",
                            "Index": 2
                          },
                          "700": {
                            "ID": "700",
                            "Description": "£700",
                            "ShortDescription": "£700",
                            "Index": 3
                          },
                          "800": {
                            "ID": "800",
                            "Description": "£800",
                            "ShortDescription": "£800",
                            "Index": 4
                          },
                          "900": {
                            "ID": "900",
                            "Description": "£900",
                            "ShortDescription": "£900",
                            "Index": 5
                          },
                          "1000": {
                            "ID": "1000",
                            "Description": "£1,000",
                            "ShortDescription": "£1k",
                            "Index": 6
                          },
                          "2000": {
                            "ID": "2000",
                            "Description": "£2,000",
                            "ShortDescription": "£2k",
                            "Index": 7
                          },
                          "3000": {
                            "ID": "3000",
                            "Description": "£3,000",
                            "ShortDescription": "£3k",
                            "Index": 8
                          },
                          "4000": {
                            "ID": "4000",
                            "Description": "£4,000",
                            "ShortDescription": "£4k",
                            "Index": 9
                          },
                          "5000": {
                            "ID": "5000",
                            "Description": "£5,000",
                            "ShortDescription": "£5k",
                            "Index": 10
                          },
                          "6000": {
                            "ID": "6000",
                            "Description": "£6,000",
                            "ShortDescription": "£6k",
                            "Index": 11
                          },
                          "7000": {
                            "ID": "7000",
                            "Description": "£7,000",
                            "ShortDescription": "£7k",
                            "Index": 12
                          },
                          "8000": {
                            "ID": "8000",
                            "Description": "£8,000",
                            "ShortDescription": "£8k",
                            "Index": 13
                          },
                          "9000": {
                            "ID": "9000",
                            "Description": "£9,000",
                            "ShortDescription": "£9k",
                            "Index": 14
                          },
                          "10000": {
                            "ID": "10000",
                            "Description": "£10,000",
                            "ShortDescription": "£10k",
                            "Index": 15
                          },
                          "20000": {
                            "ID": "20000",
                            "Description": "£20,000",
                            "ShortDescription": "£20k",
                            "Index": 16
                          },
                          "30000": {
                            "ID": "30000",
                            "Description": "£30,000",
                            "ShortDescription": "£30k",
                            "Index": 17
                          },
                          "40000": {
                            "ID": "40000",
                            "Description": "£40,000",
                            "ShortDescription": "£40k",
                            "Index": 18
                          },
                          "-1": {
                            "ID": "-1",
                            "Description": "No max",
                            "ShortDescription": "No max",
                            "HierarchicalID": "-1",
                            "Index": 19
                          }
                        },
                        "Index": 1
                      }
                    },
                    "Index": 17
                  },
                  "HKD": {
                    "ID": "HKD",
                    "Description": "HKD",
                    "Index": 18
                  },
                  "HRK": {
                    "ID": "HRK",
                    "Description": "HRK",
                    "Index": 19
                  },
                  "HUF": {
                    "ID": "HUF",
                    "Description": "HUF",
                    "Index": 20
                  },
                  "IDR": {
                    "ID": "IDR",
                    "Description": "IDR",
                    "Index": 21
                  },
                  "ILS": {
                    "ID": "ILS",
                    "Description": "ILS",
                    "Index": 22
                  },
                  "INR": {
                    "ID": "INR",
                    "Description": "INR",
                    "Index": 23
                  },
                  "IRR": {
                    "ID": "IRR",
                    "Description": "IRR",
                    "Index": 24
                  },
                  "ISK": {
                    "ID": "ISK",
                    "Description": "ISK",
                    "Index": 25
                  },
                  "JPY": {
                    "ID": "JPY",
                    "Description": "JPY",
                    "Index": 26
                  },
                  "KRW": {
                    "ID": "KRW",
                    "Description": "KRW",
                    "Index": 27
                  },
                  "KWD": {
                    "ID": "KWD",
                    "Description": "KWD",
                    "Index": 28
                  },
                  "KZT": {
                    "ID": "KZT",
                    "Description": "KZT",
                    "Index": 29
                  },
                  "LBP": {
                    "ID": "LBP",
                    "Description": "LBP",
                    "Index": 30
                  },
                  "LKR": {
                    "ID": "LKR",
                    "Description": "LKR",
                    "Index": 31
                  },
                  "LTL": {
                    "ID": "LTL",
                    "Description": "LTL",
                    "Index": 32
                  },
                  "LVL": {
                    "ID": "LVL",
                    "Description": "LVL",
                    "Index": 33
                  },
                  "LYD": {
                    "ID": "LYD",
                    "Description": "LYD",
                    "Index": 34
                  },
                  "MAD": {
                    "ID": "MAD",
                    "Description": "MAD",
                    "Index": 35
                  },
                  "MUR": {
                    "ID": "MUR",
                    "Description": "MUR",
                    "Index": 36
                  },
                  "MXN": {
                    "ID": "MXN",
                    "Description": "MXN",
                    "Index": 37
                  },
                  "MYR": {
                    "ID": "MYR",
                    "Description": "MYR",
                    "Index": 38
                  },
                  "NGN": {
                    "ID": "NGN",
                    "Description": "NGN",
                    "Index": 39
                  },
                  "NOK": {
                    "ID": "NOK",
                    "Description": "NOK",
                    "Index": 40
                  },
                  "NPR": {
                    "ID": "NPR",
                    "Description": "NPR",
                    "Index": 41
                  },
                  "NZD": {
                    "ID": "NZD",
                    "Description": "NZD",
                    "Index": 42
                  },
                  "OMR": {
                    "ID": "OMR",
                    "Description": "OMR",
                    "Index": 43
                  },
                  "PHP": {
                    "ID": "PHP",
                    "Description": "PHP",
                    "Index": 44
                  },
                  "PKR": {
                    "ID": "PKR",
                    "Description": "PKR",
                    "Index": 45
                  },
                  "PLN": {
                    "ID": "PLN",
                    "Description": "PLN",
                    "Index": 46
                  },
                  "QAR": {
                    "ID": "QAR",
                    "Description": "QAR",
                    "Index": 47
                  },
                  "RMB": {
                    "ID": "RMB",
                    "Description": "RMB",
                    "Index": 48
                  },
                  "RON": {
                    "ID": "RON",
                    "Description": "RON",
                    "Index": 49
                  },
                  "RSD": {
                    "ID": "RSD",
                    "Description": "RSD",
                    "Index": 50
                  },
                  "RUB": {
                    "ID": "RUB",
                    "Description": "RUB",
                    "Index": 51
                  },
                  "SAR": {
                    "ID": "SAR",
                    "Description": "SAR",
                    "Index": 52
                  },
                  "SEK": {
                    "ID": "SEK",
                    "Description": "SEK",
                    "Index": 53
                  },
                  "SGD": {
                    "ID": "SGD",
                    "Description": "SGD",
                    "Index": 54
                  },
                  "THB": {
                    "ID": "THB",
                    "Description": "THB",
                    "Index": 55
                  },
                  "TRY": {
                    "ID": "TRY",
                    "Description": "TRY",
                    "Index": 56
                  },
                  "TWD": {
                    "ID": "TWD",
                    "Description": "TWD",
                    "Index": 57
                  },
                  "USD": {
                    "ID": "USD",
                    "Description": "USD",
                    "Index": 58
                  },
                  "VND": {
                    "ID": "VND",
                    "Description": "VND",
                    "Index": 59
                  },
                  "ZAR": {
                    "ID": "ZAR",
                    "Description": "ZAR",
                    "Index": 60
                  }
                },
                "Features_Rent": {
                  "GRS_FTR_B": {
                    "ID": "B",
                    "Description": "Balcony",
                    "ShortDescription": "Balcony",
                    "HierarchicalID": "GRS_FTR_B",
                    "Index": 0
                  },
                  "GRS_FTR_CP": {
                    "ID": "CP",
                    "Description": "Car Park",
                    "ShortDescription": "CarPark",
                    "HierarchicalID": "GRS_FTR_CP",
                    "Index": 1
                  },
                  "GRS_FTR_CHF": {
                    "ID": "CHF",
                    "Description": "Children's facilities",
                    "ShortDescription": "ChildrenFacilities",
                    "HierarchicalID": "GRS_FTR_CHF",
                    "Index": 2
                  },
                  "GRS_FTR_CF": {
                    "ID": "CF",
                    "Description": "Club facilities",
                    "ShortDescription": "ClubFacilities",
                    "HierarchicalID": "GRS_FTR_CF",
                    "Index": 3
                  },
                  "GRS_FTR_CON": {
                    "ID": "CON",
                    "Description": "Concierge",
                    "ShortDescription": "Concierge",
                    "HierarchicalID": "GRS_FTR_CON",
                    "Index": 4
                  },
                  "GRS_FTR_GAR": {
                    "ID": "GAR",
                    "Description": "Garage",
                    "ShortDescription": "Garage",
                    "HierarchicalID": "GRS_FTR_GAR",
                    "Index": 5
                  },
                  "GRS_FTR_GDN": {
                    "ID": "GDN",
                    "Description": "Garden",
                    "ShortDescription": "Garden",
                    "HierarchicalID": "GRS_FTR_GDN",
                    "Index": 6
                  },
                  "GRS_FTR_G": {
                    "ID": "G",
                    "Description": "Gym",
                    "ShortDescription": "Gym",
                    "HierarchicalID": "GRS_FTR_G",
                    "Index": 7
                  },
                  "GRS_FTR_HLPQ": {
                    "ID": "HLPQ",
                    "Description": "Staff Accommodation",
                    "ShortDescription": "HelpersQuarters",
                    "HierarchicalID": "GRS_FTR_HLPQ",
                    "Index": 8
                  },
                  "GRS_FTR_JKZI": {
                    "ID": "JKZI",
                    "Description": "Jacuzzi",
                    "ShortDescription": "Jacuzzi",
                    "HierarchicalID": "GRS_FTR_JKZI",
                    "Index": 9
                  },
                  "GRS_FTR_LND": {
                    "ID": "LND",
                    "Description": "Land",
                    "ShortDescription": "Land",
                    "HierarchicalID": "GRS_FTR_LND",
                    "Index": 10
                  },
                  "GRS_FTR_L": {
                    "ID": "L",
                    "Description": "Lift",
                    "ShortDescription": "Lift",
                    "HierarchicalID": "GRS_FTR_L",
                    "Index": 11
                  },
                  "GRS_FTR_OSP": {
                    "ID": "OSP",
                    "Description": "Off-street parking",
                    "ShortDescription": "OffStreetParking",
                    "HierarchicalID": "GRS_FTR_OSP",
                    "Index": 12
                  },
                  "GRS_FTR_RT": {
                    "ID": "RT",
                    "Description": "Roof terrace",
                    "ShortDescription": "RoofTerrace",
                    "HierarchicalID": "GRS_FTR_RT",
                    "Index": 13
                  },
                  "GRS_FTR_SB": {
                    "ID": "SB",
                    "Description": "Shuttle bus",
                    "ShortDescription": "ShuttleBus",
                    "HierarchicalID": "GRS_FTR_SB",
                    "Index": 14
                  },
                  "GRS_FTR_SP": {
                    "ID": "SP",
                    "Description": "Swimming pool",
                    "ShortDescription": "SwimmingPool",
                    "HierarchicalID": "GRS_FTR_SP",
                    "Index": 15
                  },
                  "GRS_FTR_T": {
                    "ID": "T",
                    "Description": "Terrace",
                    "ShortDescription": "Terrace",
                    "HierarchicalID": "GRS_FTR_T",
                    "Index": 16
                  },
                  "GRS_FTR_TC": {
                    "ID": "TC",
                    "Description": "Tennis Court",
                    "ShortDescription": "TennisCourt",
                    "HierarchicalID": "GRS_FTR_TC",
                    "Index": 17
                  },
                  "GRS_FTR_Y": {
                    "ID": "Y",
                    "Description": "Yard",
                    "ShortDescription": "Yard",
                    "HierarchicalID": "GRS_FTR_Y",
                    "Index": 18
                  },
                  "GRS_FTR_RB": {
                    "ID": "RB",
                    "Description": "Renting business",
                    "ShortDescription": "RentingBusiness",
                    "HierarchicalID": "GRS_FTR_RB",
                    "Index": 19
                  },
                  "GRS_FTR_US": {
                    "ID": "US",
                    "Description": "Urgent sale",
                    "ShortDescription": "UrgentSale",
                    "HierarchicalID": "GRS_FTR_US",
                    "Index": 20
                  },
                  "GRS_FTR_INVS": {
                    "ID": "INVS",
                    "Description": "Investments",
                    "ShortDescription": "Investments",
                    "HierarchicalID": "GRS_FTR_INVS",
                    "Index": 21
                  },
                  "GRS_FTR_SPA": {
                    "ID": "SPA",
                    "Description": "SPA",
                    "ShortDescription": "SPA",
                    "HierarchicalID": "GRS_FTR_SPA",
                    "Index": 22
                  },
                  "GRS_FTR_SEC": {
                    "ID": "SEC",
                    "Description": "Security",
                    "ShortDescription": "Security",
                    "HierarchicalID": "GRS_FTR_SEC",
                    "Index": 23
                  },
                  "GRS_FTR_LS": {
                    "ID": "LS",
                    "Description": "Landscaping",
                    "ShortDescription": "Landscaping",
                    "HierarchicalID": "GRS_FTR_LS",
                    "Index": 24
                  },
                  "GRS_FTR_PV": {
                    "ID": "PV",
                    "Description": "Panoramic view",
                    "ShortDescription": "PanoramicView",
                    "HierarchicalID": "GRS_FTR_PV",
                    "Index": 25
                  },
                  "GRS_FTR_FP": {
                    "ID": "FP",
                    "Description": "Fireplace",
                    "ShortDescription": "Fireplace",
                    "HierarchicalID": "GRS_FTR_FP",
                    "Index": 26
                  },
                  "GRS_FTR_UP": {
                    "ID": "UP",
                    "Description": "Underground parking",
                    "ShortDescription": "UndergroundParking",
                    "HierarchicalID": "GRS_FTR_UP",
                    "Index": 27
                  },
                  "GRS_FTR_PRD": {
                    "ID": "PRD",
                    "Description": "Period",
                    "ShortDescription": "Period",
                    "HierarchicalID": "GRS_FTR_PRD",
                    "Index": 28
                  },
                  "GRS_FTR_PP": {
                    "ID": "PP",
                    "Description": "Planning permission",
                    "ShortDescription": "PlanningPermission",
                    "HierarchicalID": "GRS_FTR_PP",
                    "Index": 29
                  },
                  "GRS_FTR_G1": {
                    "ID": "G1",
                    "Description": "Grade I",
                    "ShortDescription": "Grade1",
                    "HierarchicalID": "GRS_FTR_G1",
                    "Index": 30
                  },
                  "GRS_FTR_G2": {
                    "ID": "G2",
                    "Description": "Grade II",
                    "ShortDescription": "Grade2",
                    "HierarchicalID": "GRS_FTR_G2",
                    "Index": 31
                  },
                  "GRS_FTR_LL": {
                    "ID": "LL",
                    "Description": "Lateral living",
                    "ShortDescription": "LateralLiving",
                    "HierarchicalID": "GRS_FTR_LL",
                    "Index": 32
                  },
                  "GRS_FTR_SFA": {
                    "ID": "SFA",
                    "Description": "Step free access",
                    "ShortDescription": "StepFreeAccess",
                    "HierarchicalID": "GRS_FTR_SFA",
                    "Index": 33
                  },
                  "GRS_FTR_WRFFS": {
                    "ID": "WRFFS",
                    "Description": "Wet room/flat floor shower",
                    "ShortDescription": "WetRoomFlatFloorShower",
                    "HierarchicalID": "GRS_FTR_WRFFS",
                    "Index": 34
                  },
                  "GRS_LT_ST": {
                    "ID": "ST",
                    "Description": "Short term",
                    "ShortDescription": "ShortTerm",
                    "HierarchicalID": "GRS_LT_ST",
                    "Index": 35
                  },
                  "GRS_LT_LT": {
                    "ID": "LT",
                    "Description": "Long term",
                    "ShortDescription": "LongTerm",
                    "HierarchicalID": "GRS_LT_LT",
                    "Index": 36
                  },
                  "GRS_FU_UF": {
                    "ID": "UF",
                    "Description": "Unfurnished",
                    "ShortDescription": "Unfurnished",
                    "HierarchicalID": "GRS_FU_UF",
                    "Index": 37
                  },
                  "GRS_FU_F": {
                    "ID": "F",
                    "Description": "Furnished",
                    "ShortDescription": "Furnished",
                    "HierarchicalID": "GRS_FU_F",
                    "Index": 38
                  },
                  "GRS_FU_PF": {
                    "ID": "PF",
                    "Description": "Part furnished",
                    "ShortDescription": "PartFurnished",
                    "HierarchicalID": "GRS_FU_PF",
                    "Index": 39
                  },
                  "GRS_FU_BF": {
                    "ID": "BF",
                    "Description": "Furnished / unfurnished",
                    "ShortDescription": "BothFurnished",
                    "HierarchicalID": "GRS_FU_BF",
                    "Index": 40
                  },
                  "GRS_FU_FF": {
                    "ID": "FF",
                    "Description": "Flexible furnishing",
                    "ShortDescription": "FlexibleFurnishing",
                    "HierarchicalID": "GRS_FU_FF",
                    "Index": 41
                  }
                },
                "PropertyUses": {
                  "GRS_PU_HEA": {
                    "ID": "HEA",
                    "Description": "Healthcare",
                    "ShortDescription": "Healthcare",
                    "HierarchicalID": "GRS_PU_HEA",
                    "Index": 0
                  },
                  "GRS_PU_EXI": {
                    "ID": "EXI",
                    "Description": "Existing building",
                    "ShortDescription": "ExistingBuilding",
                    "HierarchicalID": "GRS_PU_EXI",
                    "Index": 1
                  },
                  "GRS_PU_STU": {
                    "ID": "STU",
                    "Description": "Student accommodation",
                    "ShortDescription": "StudentAccomodation",
                    "HierarchicalID": "GRS_PU_STU",
                    "Index": 2
                  },
                  "GRS_PU_HOT": {
                    "ID": "HOT",
                    "Description": "Hotel",
                    "ShortDescription": "Hotel",
                    "HierarchicalID": "GRS_PU_HOT",
                    "Index": 3
                  },
                  "GRS_PU_IND": {
                    "ID": "IND",
                    "Description": "Industrial",
                    "ShortDescription": "Industrial",
                    "HierarchicalID": "GRS_PU_IND",
                    "Index": 4
                  },
                  "GRS_PU_LAN": {
                    "ID": "LAN",
                    "Description": "Land",
                    "ShortDescription": "Land",
                    "HierarchicalID": "GRS_PU_LAN",
                    "Index": 5
                  },
                  "GRS_PU_RET": {
                    "ID": "RET",
                    "Description": "Retail",
                    "ShortDescription": "Retail",
                    "HierarchicalID": "GRS_PU_RET",
                    "Index": 6
                  },
                  "GRS_PU_LEI": {
                    "ID": "LEI",
                    "Description": "Leisure",
                    "ShortDescription": "Leisure",
                    "HierarchicalID": "GRS_PU_LEI",
                    "Index": 7
                  },
                  "GRS_PU_MIX": {
                    "ID": "MIX",
                    "Description": "Mixed Use",
                    "ShortDescription": "MixedUse",
                    "HierarchicalID": "GRS_PU_MIX",
                    "Index": 8
                  },
                  "GRS_PU_OFF": {
                    "ID": "OFF",
                    "Description": "Office",
                    "ShortDescription": "Office",
                    "HierarchicalID": "GRS_PU_OFF",
                    "Index": 9
                  },
                  "GRS_PU_POR": {
                    "ID": "POR",
                    "Description": "Portfolio",
                    "ShortDescription": "Portfolio",
                    "HierarchicalID": "GRS_PU_POR",
                    "Index": 10
                  },
                  "GRS_PU_RES": {
                    "ID": "RES",
                    "Description": "Residential \u0026 New Developments",
                    "ShortDescription": "Residential",
                    "HierarchicalID": "GRS_PU_RES",
                    "Index": 11
                  },
                  "GRS_PU_CAR": {
                    "ID": "CAR",
                    "Description": "Caravan park",
                    "ShortDescription": "CaravanPark",
                    "HierarchicalID": "GRS_PU_CAR",
                    "Index": 12
                  },
                  "GRS_PU_ENT": {
                    "ID": "ENT",
                    "Description": "Entertainment venue",
                    "ShortDescription": "EntertainmentVenue",
                    "HierarchicalID": "GRS_PU_ENT",
                    "Index": 13
                  },
                  "GRS_PU_GOL": {
                    "ID": "GOL",
                    "Description": "Golf",
                    "ShortDescription": "Golf",
                    "HierarchicalID": "GRS_PU_GOL",
                    "Index": 14
                  },
                  "GRS_PU_HOL": {
                    "ID": "HOL",
                    "Description": "Holiday park",
                    "ShortDescription": "Holidaypark",
                    "HierarchicalID": "GRS_PU_HOL",
                    "Index": 15
                  },
                  "GRS_PU_HCD": {
                    "ID": "HCD",
                    "Description": "Holiday cottage complex",
                    "ShortDescription": "Holidaycottagecomplex",
                    "HierarchicalID": "GRS_PU_HCD",
                    "Index": 16
                  },
                  "GRS_PU_RMH": {
                    "ID": "RMH",
                    "Description": "Residential mobile home park",
                    "ShortDescription": "Residentialmobilehomepark",
                    "HierarchicalID": "GRS_PU_RMH",
                    "Index": 17
                  },
                  "GRS_PU_RPB": {
                    "ID": "RPB",
                    "Description": "Restaurants/Pub/Bars",
                    "ShortDescription": "RestaurantsPubBars",
                    "HierarchicalID": "GRS_PU_RPB",
                    "Index": 18
                  },
                  "GRS_PU_SPO": {
                    "ID": "SPO",
                    "Description": "Sports venue",
                    "ShortDescription": "Sportsvenue",
                    "HierarchicalID": "GRS_PU_SPO",
                    "Index": 19
                  },
                  "GRS_PU_VIS": {
                    "ID": "VIS",
                    "Description": "Visitor attraction",
                    "ShortDescription": "VisitorAttraction",
                    "HierarchicalID": "GRS_PU_VIS",
                    "Index": 20
                  },
                  "GRS_PU_WAT": {
                    "ID": "WAT",
                    "Description": "Water based leisure",
                    "ShortDescription": "WaterBasedLeisure",
                    "HierarchicalID": "GRS_PU_WAT",
                    "Index": 21
                  },
                  "GRS_PU_OTH": {
                    "ID": "OTH",
                    "Description": "Other trading property",
                    "ShortDescription": "Othertradingproperty",
                    "HierarchicalID": "GRS_PU_OTH",
                    "Index": 22
                  },
                  "GRS_PU_CRE": {
                    "ID": "CRE",
                    "Description": "Care home",
                    "ShortDescription": "CareHome",
                    "HierarchicalID": "GRS_PU_CRE",
                    "Index": 23
                  },
                  "GRS_PU_NUR": {
                    "ID": "NUR",
                    "Description": "Nursing home",
                    "ShortDescription": "NursingHome",
                    "HierarchicalID": "GRS_PU_NUR",
                    "Index": 24
                  },
                  "GRS_PU_D1D": {
                    "ID": "D1D",
                    "Description": "D1 development land",
                    "ShortDescription": "D1DevelopmentLand",
                    "HierarchicalID": "GRS_PU_D1D",
                    "Index": 25
                  },
                  "GRS_PU_C2D": {
                    "ID": "C2D",
                    "Description": "C2 Development Land",
                    "ShortDescription": "C2DevelopmentLand",
                    "HierarchicalID": "GRS_PU_C2D",
                    "Index": 26
                  },
                  "GRS_PU_EXC": {
                    "ID": "EXC",
                    "Description": "Extra Care",
                    "ShortDescription": "ExtraCare",
                    "HierarchicalID": "GRS_PU_EXC",
                    "Index": 27
                  },
                  "GRS_PU_CHI": {
                    "ID": "CHI",
                    "Description": "Children's Day Nursery",
                    "ShortDescription": "ChildrensDayNursery",
                    "HierarchicalID": "GRS_PU_CHI",
                    "Index": 28
                  },
                  "GRS_PU_D1P": {
                    "ID": "D1P",
                    "Description": "D1 Property",
                    "ShortDescription": "D1Property",
                    "HierarchicalID": "GRS_PU_D1P",
                    "Index": 29
                  },
                  "GRS_PU_SCH": {
                    "ID": "SCH",
                    "Description": "Specialist care home",
                    "ShortDescription": "SpecialistCareHome",
                    "HierarchicalID": "GRS_PU_SCH",
                    "Index": 30
                  },
                  "GRS_PU_SHC": {
                    "ID": "SHC",
                    "Description": "Shopping centre",
                    "ShortDescription": "ShoppingCentre",
                    "HierarchicalID": "GRS_PU_SHC",
                    "Index": 31
                  },
                  "GRS_PU_REP": {
                    "ID": "REP",
                    "Description": "Retail park",
                    "ShortDescription": "RetailPark",
                    "HierarchicalID": "GRS_PU_REP",
                    "Index": 32
                  },
                  "GRS_PU_HIS": {
                    "ID": "HIS",
                    "Description": "High Street",
                    "ShortDescription": "HighStreet",
                    "HierarchicalID": "GRS_PU_HIS",
                    "Index": 33
                  },
                  "GRS_PU_SUB": {
                    "ID": "SUB",
                    "Description": "Suburban",
                    "ShortDescription": "Suburban",
                    "HierarchicalID": "GRS_PU_SUB",
                    "Index": 34
                  },
                  "GRS_PU_WAR": {
                    "ID": "WAR",
                    "Description": "Warehouse",
                    "ShortDescription": "Warehouse",
                    "HierarchicalID": "GRS_PU_WAR",
                    "Index": 35
                  },
                  "GRS_PU_LOG": {
                    "ID": "LOG",
                    "Description": "Logistic",
                    "ShortDescription": "Logistic",
                    "HierarchicalID": "GRS_PU_LOG",
                    "Index": 36
                  },
                  "GRS_PU_PRD": {
                    "ID": "PRD",
                    "Description": "Production",
                    "ShortDescription": "Production",
                    "HierarchicalID": "GRS_PU_PRD",
                    "Index": 37
                  },
                  "GRS_PU_CLP": {
                    "ID": "CLP",
                    "Description": "Commercial plot",
                    "ShortDescription": "CommercialPlot",
                    "HierarchicalID": "GRS_PU_CLP",
                    "Index": 38
                  },
                  "GRS_PU_INP": {
                    "ID": "INP",
                    "Description": "Industrial plot",
                    "ShortDescription": "IndustrialPlot",
                    "HierarchicalID": "GRS_PU_INP",
                    "Index": 39
                  },
                  "GRS_PU_ASL": {
                    "ID": "ASL",
                    "Description": "Assisted living",
                    "ShortDescription": "AssistedLiving",
                    "HierarchicalID": "GRS_PU_ASL",
                    "Index": 40
                  },
                  "GRS_PU_C2L": {
                    "ID": "C2L",
                    "Description": "C2 Land",
                    "ShortDescription": "C2Land",
                    "HierarchicalID": "GRS_PU_C2L",
                    "Index": 41
                  },
                  "GRS_PU_BTS": {
                    "ID": "BTS",
                    "Description": "Build to Suit",
                    "ShortDescription": "BuildToSuit",
                    "HierarchicalID": "GRS_PU_BTS",
                    "Index": 42
                  },
                  "GRS_PU_MZZ": {
                    "ID": "MZZ",
                    "Description": "Mezzanine",
                    "ShortDescription": "Mezzanine",
                    "HierarchicalID": "GRS_PU_MZZ",
                    "Index": 43
                  },
                  "GRS_PU_HTL": {
                    "ID": "HTL",
                    "Description": "Hotels",
                    "ShortDescription": "Hotels",
                    "HierarchicalID": "GRS_PU_HTL",
                    "Index": 44
                  },
                  "GRS_PU_AHT": {
                    "ID": "AHT",
                    "Description": "Aparthotels",
                    "ShortDescription": "Aparthotels",
                    "HierarchicalID": "GRS_PU_AHT",
                    "Index": 45
                  },
                  "GRS_PU_SAPT": {
                    "ID": "SAPT",
                    "Description": "Serviced apartment",
                    "ShortDescription": "ServicedApartment",
                    "HierarchicalID": "GRS_PU_SAPT",
                    "Index": 46
                  }
                },
                "CommercialSize_Acre": {
                  "MinCommercialSize": {
                    "ID": "MinCommercialSize",
                    "Description": "minimum",
                    "ShortDescription": "min",
                    "Options": {
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 1
                      },
                      "75": {
                        "ID": "75",
                        "Description": "75",
                        "ShortDescription": "75",
                        "HierarchicalID": "75",
                        "Index": 2
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 3
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 4
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 5
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 6
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 7
                      },
                      "250": {
                        "ID": "250",
                        "Description": "250",
                        "ShortDescription": "250",
                        "HierarchicalID": "250",
                        "Index": 8
                      },
                      "300": {
                        "ID": "300",
                        "Description": "300",
                        "ShortDescription": "300",
                        "HierarchicalID": "300",
                        "Index": 9
                      },
                      "350": {
                        "ID": "350",
                        "Description": "350",
                        "ShortDescription": "350",
                        "HierarchicalID": "350",
                        "Index": 10
                      },
                      "400": {
                        "ID": "400",
                        "Description": "400",
                        "ShortDescription": "400",
                        "HierarchicalID": "400",
                        "Index": 11
                      },
                      "450": {
                        "ID": "450",
                        "Description": "450",
                        "ShortDescription": "450",
                        "HierarchicalID": "450",
                        "Index": 12
                      },
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 13
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No min",
                        "ShortDescription": "No min",
                        "HierarchicalID": "-1",
                        "Index": 0
                      }
                    },
                    "Index": 0
                  },
                  "MaxCommercialSize": {
                    "ID": "MaxCommercialSize",
                    "Description": "maximum",
                    "Options": {
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 0
                      },
                      "75": {
                        "ID": "75",
                        "Description": "75",
                        "ShortDescription": "75",
                        "HierarchicalID": "75",
                        "Index": 1
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 2
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 3
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 4
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 5
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 6
                      },
                      "250": {
                        "ID": "250",
                        "Description": "250",
                        "ShortDescription": "250",
                        "HierarchicalID": "250",
                        "Index": 7
                      },
                      "300": {
                        "ID": "300",
                        "Description": "300",
                        "ShortDescription": "300",
                        "HierarchicalID": "300",
                        "Index": 8
                      },
                      "350": {
                        "ID": "350",
                        "Description": "350",
                        "ShortDescription": "350",
                        "HierarchicalID": "350",
                        "Index": 9
                      },
                      "400": {
                        "ID": "400",
                        "Description": "400",
                        "ShortDescription": "400",
                        "HierarchicalID": "400",
                        "Index": 10
                      },
                      "450": {
                        "ID": "450",
                        "Description": "450",
                        "ShortDescription": "450",
                        "HierarchicalID": "450",
                        "Index": 11
                      },
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 12
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No max",
                        "ShortDescription": "No max",
                        "HierarchicalID": "-1",
                        "Index": 13
                      }
                    },
                    "Index": 1
                  }
                },
                "CommercialSize_SquareFeet": {
                  "MinCommercialSize": {
                    "ID": "MinCommercialSize",
                    "Description": "minimum",
                    "ShortDescription": "min",
                    "Options": {
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 1
                      },
                      "750": {
                        "ID": "750",
                        "Description": "750",
                        "ShortDescription": "750",
                        "HierarchicalID": "750",
                        "Index": 2
                      },
                      "1000": {
                        "ID": "1000",
                        "Description": "1,000",
                        "ShortDescription": "1k",
                        "HierarchicalID": "1000",
                        "Index": 3
                      },
                      "1250": {
                        "ID": "1250",
                        "Description": "1,250",
                        "ShortDescription": "1.25k",
                        "HierarchicalID": "1250",
                        "Index": 4
                      },
                      "1500": {
                        "ID": "1500",
                        "Description": "1,500",
                        "ShortDescription": "1.5k",
                        "HierarchicalID": "1500",
                        "Index": 5
                      },
                      "1750": {
                        "ID": "1750",
                        "Description": "1,750",
                        "ShortDescription": "1.75k",
                        "HierarchicalID": "1750",
                        "Index": 6
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2,000",
                        "ShortDescription": "2k",
                        "HierarchicalID": "2000",
                        "Index": 7
                      },
                      "2500": {
                        "ID": "2500",
                        "Description": "2,500",
                        "ShortDescription": "2.5k",
                        "HierarchicalID": "2500",
                        "Index": 8
                      },
                      "3000": {
                        "ID": "3000",
                        "Description": "3,000",
                        "ShortDescription": "3k",
                        "HierarchicalID": "3000",
                        "Index": 9
                      },
                      "3500": {
                        "ID": "3500",
                        "Description": "3,500",
                        "ShortDescription": "3.5k",
                        "HierarchicalID": "3500",
                        "Index": 10
                      },
                      "4000": {
                        "ID": "4000",
                        "Description": "4,000",
                        "ShortDescription": "4k",
                        "HierarchicalID": "4000",
                        "Index": 11
                      },
                      "4500": {
                        "ID": "4500",
                        "Description": "4,500",
                        "ShortDescription": "4.5k",
                        "HierarchicalID": "4500",
                        "Index": 12
                      },
                      "5000": {
                        "ID": "5000",
                        "Description": "5,000",
                        "ShortDescription": "5k",
                        "HierarchicalID": "5000",
                        "Index": 13
                      },
                      "6000": {
                        "ID": "6000",
                        "Description": "6,000",
                        "ShortDescription": "6k",
                        "HierarchicalID": "6000",
                        "Index": 14
                      },
                      "7000": {
                        "ID": "7000",
                        "Description": "7,000",
                        "ShortDescription": "7k",
                        "HierarchicalID": "7000",
                        "Index": 15
                      },
                      "8000": {
                        "ID": "8000",
                        "Description": "8,000",
                        "ShortDescription": "8k",
                        "HierarchicalID": "8000",
                        "Index": 16
                      },
                      "9000": {
                        "ID": "9000",
                        "Description": "9,000",
                        "ShortDescription": "9k",
                        "HierarchicalID": "9000",
                        "Index": 17
                      },
                      "10000": {
                        "ID": "10000",
                        "Description": "10,000",
                        "ShortDescription": "10k",
                        "HierarchicalID": "10000",
                        "Index": 18
                      },
                      "12500": {
                        "ID": "12500",
                        "Description": "12,500",
                        "ShortDescription": "12.5k",
                        "HierarchicalID": "12500",
                        "Index": 19
                      },
                      "15000": {
                        "ID": "15000",
                        "Description": "15,000",
                        "ShortDescription": "15k",
                        "HierarchicalID": "15000",
                        "Index": 20
                      },
                      "17500": {
                        "ID": "17500",
                        "Description": "17,500",
                        "ShortDescription": "17.5k",
                        "HierarchicalID": "17500",
                        "Index": 21
                      },
                      "20000": {
                        "ID": "20000",
                        "Description": "20,000",
                        "ShortDescription": "20k",
                        "HierarchicalID": "20000",
                        "Index": 22
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No min",
                        "ShortDescription": "No min",
                        "HierarchicalID": "-1",
                        "Index": 0
                      }
                    },
                    "Index": 0
                  },
                  "MaxCommercialSize": {
                    "ID": "MaxCommercialSize",
                    "Description": "maximum",
                    "Options": {
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 0
                      },
                      "750": {
                        "ID": "750",
                        "Description": "750",
                        "ShortDescription": "750",
                        "HierarchicalID": "750",
                        "Index": 1
                      },
                      "1000": {
                        "ID": "1000",
                        "Description": "1,000",
                        "ShortDescription": "1k",
                        "HierarchicalID": "1000",
                        "Index": 2
                      },
                      "1250": {
                        "ID": "1250",
                        "Description": "1,250",
                        "ShortDescription": "1.25k",
                        "HierarchicalID": "1250",
                        "Index": 3
                      },
                      "1500": {
                        "ID": "1500",
                        "Description": "1,500",
                        "ShortDescription": "1.5k",
                        "HierarchicalID": "1500",
                        "Index": 4
                      },
                      "1750": {
                        "ID": "1750",
                        "Description": "1,750",
                        "ShortDescription": "1.75k",
                        "HierarchicalID": "1750",
                        "Index": 5
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2,000",
                        "ShortDescription": "2k",
                        "HierarchicalID": "2000",
                        "Index": 6
                      },
                      "2500": {
                        "ID": "2500",
                        "Description": "2,500",
                        "ShortDescription": "2.5k",
                        "HierarchicalID": "2500",
                        "Index": 7
                      },
                      "3000": {
                        "ID": "3000",
                        "Description": "3,000",
                        "ShortDescription": "3k",
                        "HierarchicalID": "3000",
                        "Index": 8
                      },
                      "3500": {
                        "ID": "3500",
                        "Description": "3,500",
                        "ShortDescription": "3.5k",
                        "HierarchicalID": "3500",
                        "Index": 9
                      },
                      "4000": {
                        "ID": "4000",
                        "Description": "4,000",
                        "ShortDescription": "4k",
                        "HierarchicalID": "4000",
                        "Index": 10
                      },
                      "4500": {
                        "ID": "4500",
                        "Description": "4,500",
                        "ShortDescription": "4.5k",
                        "HierarchicalID": "4500",
                        "Index": 11
                      },
                      "5000": {
                        "ID": "5000",
                        "Description": "5,000",
                        "ShortDescription": "5k",
                        "HierarchicalID": "5000",
                        "Index": 12
                      },
                      "6000": {
                        "ID": "6000",
                        "Description": "6,000",
                        "ShortDescription": "6k",
                        "HierarchicalID": "6000",
                        "Index": 13
                      },
                      "7000": {
                        "ID": "7000",
                        "Description": "7,000",
                        "ShortDescription": "7k",
                        "HierarchicalID": "7000",
                        "Index": 14
                      },
                      "8000": {
                        "ID": "8000",
                        "Description": "8,000",
                        "ShortDescription": "8k",
                        "HierarchicalID": "8000",
                        "Index": 15
                      },
                      "9000": {
                        "ID": "9000",
                        "Description": "9,000",
                        "ShortDescription": "9k",
                        "HierarchicalID": "9000",
                        "Index": 16
                      },
                      "10000": {
                        "ID": "10000",
                        "Description": "10,000",
                        "ShortDescription": "10k",
                        "HierarchicalID": "10000",
                        "Index": 17
                      },
                      "12500": {
                        "ID": "12500",
                        "Description": "12,500",
                        "ShortDescription": "12.5k",
                        "HierarchicalID": "12500",
                        "Index": 18
                      },
                      "15000": {
                        "ID": "15000",
                        "Description": "15,000",
                        "ShortDescription": "15k",
                        "HierarchicalID": "15000",
                        "Index": 19
                      },
                      "17500": {
                        "ID": "17500",
                        "Description": "17,500",
                        "ShortDescription": "17.5k",
                        "HierarchicalID": "17500",
                        "Index": 20
                      },
                      "20000": {
                        "ID": "20000",
                        "Description": "20,000",
                        "ShortDescription": "20k",
                        "HierarchicalID": "20000",
                        "Index": 21
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No max",
                        "ShortDescription": "No max",
                        "HierarchicalID": "-1",
                        "Index": 22
                      }
                    },
                    "Index": 1
                  }
                },
                "CommercialSize_SquareMeter": {
                  "MinCommercialSize": {
                    "ID": "MinCommercialSize",
                    "Description": "minimum",
                    "ShortDescription": "min",
                    "Options": {
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 1
                      },
                      "75": {
                        "ID": "75",
                        "Description": "75",
                        "ShortDescription": "75",
                        "HierarchicalID": "75",
                        "Index": 2
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 3
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 4
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 5
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 6
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 7
                      },
                      "250": {
                        "ID": "250",
                        "Description": "250",
                        "ShortDescription": "250",
                        "HierarchicalID": "250",
                        "Index": 8
                      },
                      "300": {
                        "ID": "300",
                        "Description": "300",
                        "ShortDescription": "300",
                        "HierarchicalID": "300",
                        "Index": 9
                      },
                      "350": {
                        "ID": "350",
                        "Description": "350",
                        "ShortDescription": "350",
                        "HierarchicalID": "350",
                        "Index": 10
                      },
                      "400": {
                        "ID": "400",
                        "Description": "400",
                        "ShortDescription": "400",
                        "HierarchicalID": "400",
                        "Index": 11
                      },
                      "450": {
                        "ID": "450",
                        "Description": "450",
                        "ShortDescription": "450",
                        "HierarchicalID": "450",
                        "Index": 12
                      },
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 13
                      },
                      "600": {
                        "ID": "600",
                        "Description": "600",
                        "ShortDescription": "600",
                        "HierarchicalID": "600",
                        "Index": 14
                      },
                      "700": {
                        "ID": "700",
                        "Description": "700",
                        "ShortDescription": "700",
                        "HierarchicalID": "700",
                        "Index": 15
                      },
                      "800": {
                        "ID": "800",
                        "Description": "800",
                        "ShortDescription": "800",
                        "HierarchicalID": "800",
                        "Index": 16
                      },
                      "900": {
                        "ID": "900",
                        "Description": "900",
                        "ShortDescription": "900",
                        "HierarchicalID": "900",
                        "Index": 17
                      },
                      "1000": {
                        "ID": "1000",
                        "Description": "1,000",
                        "ShortDescription": "1k",
                        "HierarchicalID": "1000",
                        "Index": 18
                      },
                      "1250": {
                        "ID": "1250",
                        "Description": "1,250",
                        "ShortDescription": "1.25k",
                        "HierarchicalID": "1250",
                        "Index": 19
                      },
                      "1500": {
                        "ID": "1500",
                        "Description": "1,500",
                        "ShortDescription": "1.5k",
                        "HierarchicalID": "1500",
                        "Index": 20
                      },
                      "1750": {
                        "ID": "1750",
                        "Description": "1,750",
                        "ShortDescription": "1.75k",
                        "HierarchicalID": "1750",
                        "Index": 21
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2,000",
                        "ShortDescription": "2k",
                        "HierarchicalID": "2000",
                        "Index": 22
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No min",
                        "ShortDescription": "No min",
                        "HierarchicalID": "-1",
                        "Index": 0
                      }
                    },
                    "Index": 0
                  },
                  "MaxCommercialSize": {
                    "ID": "MaxCommercialSize",
                    "Description": "maximum",
                    "Options": {
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 0
                      },
                      "75": {
                        "ID": "75",
                        "Description": "75",
                        "ShortDescription": "75",
                        "HierarchicalID": "75",
                        "Index": 1
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 2
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 3
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 4
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 5
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 6
                      },
                      "250": {
                        "ID": "250",
                        "Description": "250",
                        "ShortDescription": "250",
                        "HierarchicalID": "250",
                        "Index": 7
                      },
                      "300": {
                        "ID": "300",
                        "Description": "300",
                        "ShortDescription": "300",
                        "HierarchicalID": "300",
                        "Index": 8
                      },
                      "350": {
                        "ID": "350",
                        "Description": "350",
                        "ShortDescription": "350",
                        "HierarchicalID": "350",
                        "Index": 9
                      },
                      "400": {
                        "ID": "400",
                        "Description": "400",
                        "ShortDescription": "400",
                        "HierarchicalID": "400",
                        "Index": 10
                      },
                      "450": {
                        "ID": "450",
                        "Description": "450",
                        "ShortDescription": "450",
                        "HierarchicalID": "450",
                        "Index": 11
                      },
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 12
                      },
                      "600": {
                        "ID": "600",
                        "Description": "600",
                        "ShortDescription": "600",
                        "HierarchicalID": "600",
                        "Index": 13
                      },
                      "700": {
                        "ID": "700",
                        "Description": "700",
                        "ShortDescription": "700",
                        "HierarchicalID": "700",
                        "Index": 14
                      },
                      "800": {
                        "ID": "800",
                        "Description": "800",
                        "ShortDescription": "800",
                        "HierarchicalID": "800",
                        "Index": 15
                      },
                      "900": {
                        "ID": "900",
                        "Description": "900",
                        "ShortDescription": "900",
                        "HierarchicalID": "900",
                        "Index": 16
                      },
                      "1000": {
                        "ID": "1000",
                        "Description": "1,000",
                        "ShortDescription": "1k",
                        "HierarchicalID": "1000",
                        "Index": 17
                      },
                      "1250": {
                        "ID": "1250",
                        "Description": "1,250",
                        "ShortDescription": "1.25k",
                        "HierarchicalID": "1250",
                        "Index": 18
                      },
                      "1500": {
                        "ID": "1500",
                        "Description": "1,500",
                        "ShortDescription": "1.5k",
                        "HierarchicalID": "1500",
                        "Index": 19
                      },
                      "1750": {
                        "ID": "1750",
                        "Description": "1,750",
                        "ShortDescription": "1.75k",
                        "HierarchicalID": "1750",
                        "Index": 20
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2,000",
                        "ShortDescription": "2k",
                        "HierarchicalID": "2000",
                        "Index": 21
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No max",
                        "ShortDescription": "No max",
                        "HierarchicalID": "-1",
                        "Index": 22
                      }
                    },
                    "Index": 1
                  }
                },
                "CommercialSize_Hectare": {
                  "MinCommercialSize": {
                    "ID": "MinCommercialSize",
                    "Description": "minimum",
                    "ShortDescription": "min",
                    "Options": {
                      "10": {
                        "ID": "10",
                        "Description": "10",
                        "ShortDescription": "10",
                        "HierarchicalID": "10",
                        "Index": 1
                      },
                      "20": {
                        "ID": "20",
                        "Description": "20",
                        "ShortDescription": "20",
                        "HierarchicalID": "20",
                        "Index": 2
                      },
                      "30": {
                        "ID": "30",
                        "Description": "30",
                        "ShortDescription": "30",
                        "HierarchicalID": "30",
                        "Index": 3
                      },
                      "40": {
                        "ID": "40",
                        "Description": "40",
                        "ShortDescription": "40",
                        "HierarchicalID": "40",
                        "Index": 4
                      },
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 5
                      },
                      "60": {
                        "ID": "60",
                        "Description": "60",
                        "ShortDescription": "60",
                        "HierarchicalID": "60",
                        "Index": 6
                      },
                      "70": {
                        "ID": "70",
                        "Description": "70",
                        "ShortDescription": "70",
                        "HierarchicalID": "70",
                        "Index": 7
                      },
                      "80": {
                        "ID": "80",
                        "Description": "80",
                        "ShortDescription": "80",
                        "HierarchicalID": "80",
                        "Index": 8
                      },
                      "90": {
                        "ID": "90",
                        "Description": "90",
                        "ShortDescription": "90",
                        "HierarchicalID": "90",
                        "Index": 9
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 10
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 11
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 12
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 13
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 14
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No min",
                        "ShortDescription": "No min",
                        "HierarchicalID": "-1",
                        "Index": 0
                      }
                    },
                    "Index": 0
                  },
                  "MaxCommercialSize": {
                    "ID": "MaxCommercialSize",
                    "Description": "maximum",
                    "Options": {
                      "10": {
                        "ID": "10",
                        "Description": "10",
                        "ShortDescription": "10",
                        "HierarchicalID": "10",
                        "Index": 0
                      },
                      "20": {
                        "ID": "20",
                        "Description": "20",
                        "ShortDescription": "20",
                        "HierarchicalID": "20",
                        "Index": 1
                      },
                      "30": {
                        "ID": "30",
                        "Description": "30",
                        "ShortDescription": "30",
                        "HierarchicalID": "30",
                        "Index": 2
                      },
                      "40": {
                        "ID": "40",
                        "Description": "40",
                        "ShortDescription": "40",
                        "HierarchicalID": "40",
                        "Index": 3
                      },
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 4
                      },
                      "60": {
                        "ID": "60",
                        "Description": "60",
                        "ShortDescription": "60",
                        "HierarchicalID": "60",
                        "Index": 5
                      },
                      "70": {
                        "ID": "70",
                        "Description": "70",
                        "ShortDescription": "70",
                        "HierarchicalID": "70",
                        "Index": 6
                      },
                      "80": {
                        "ID": "80",
                        "Description": "80",
                        "ShortDescription": "80",
                        "HierarchicalID": "80",
                        "Index": 7
                      },
                      "90": {
                        "ID": "90",
                        "Description": "90",
                        "ShortDescription": "90",
                        "HierarchicalID": "90",
                        "Index": 8
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 9
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 10
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 11
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 12
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 13
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No max",
                        "ShortDescription": "No max",
                        "HierarchicalID": "-1",
                        "Index": 14
                      }
                    },
                    "Index": 1
                  }
                },
                "OwnershipTypes": {
                  "GRS_OST_0": {
                    "ID": "0",
                    "Description": "Freehold",
                    "ShortDescription": "TenureFreehold",
                    "HierarchicalID": "GRS_OST_0",
                    "Index": 0
                  },
                  "GRS_OST_1": {
                    "ID": "1",
                    "Description": "Leasehold",
                    "ShortDescription": "TenureLeasehold",
                    "HierarchicalID": "GRS_OST_1",
                    "Index": 1
                  },
                  "GRS_OST_2": {
                    "ID": "2",
                    "Description": "Share of freehold",
                    "ShortDescription": "TenureShareOfFreehold",
                    "HierarchicalID": "GRS_OST_2",
                    "Index": 2
                  },
                  "GRS_OST_3": {
                    "ID": "3",
                    "Description": "Flying freehold",
                    "ShortDescription": "TenureFlyingFreehold",
                    "HierarchicalID": "GRS_OST_3",
                    "Index": 3
                  },
                  "GRS_OST_4": {
                    "ID": "4",
                    "Description": "Share transfer",
                    "ShortDescription": "TenureShareTransfer",
                    "HierarchicalID": "GRS_OST_4",
                    "Index": 4
                  },
                  "GRS_OST_5": {
                    "ID": "5",
                    "Description": "Virtual freehold",
                    "ShortDescription": "TenureVirtualFreehold",
                    "HierarchicalID": "GRS_OST_5",
                    "Index": 5
                  }
                },
                "PriceInterval_Comm_Rent_Month": {
                  "AED": {
                    "ID": "AED",
                    "Description": "AED",
                    "Index": 0
                  },
                  "ARS": {
                    "ID": "ARS",
                    "Description": "ARS",
                    "Index": 1
                  },
                  "AUD": {
                    "ID": "AUD",
                    "Description": "AUD",
                    "Index": 2
                  },
                  "BGN": {
                    "ID": "BGN",
                    "Description": "BGN",
                    "Index": 3
                  },
                  "BHD": {
                    "ID": "BHD",
                    "Description": "BHD",
                    "Index": 4
                  },
                  "BND": {
                    "ID": "BND",
                    "Description": "BND",
                    "Index": 5
                  },
                  "BRL": {
                    "ID": "BRL",
                    "Description": "BRL",
                    "Index": 6
                  },
                  "BWP": {
                    "ID": "BWP",
                    "Description": "BWP",
                    "Index": 7
                  },
                  "CAD": {
                    "ID": "CAD",
                    "Description": "CAD",
                    "Index": 8
                  },
                  "CHF": {
                    "ID": "CHF",
                    "Description": "CHF",
                    "Index": 9
                  },
                  "CLP": {
                    "ID": "CLP",
                    "Description": "CLP",
                    "Index": 10
                  },
                  "CNY": {
                    "ID": "CNY",
                    "Description": "CNY",
                    "Index": 11
                  },
                  "COP": {
                    "ID": "COP",
                    "Description": "COP",
                    "Index": 12
                  },
                  "CZK": {
                    "ID": "CZK",
                    "Description": "CZK",
                    "Index": 13
                  },
                  "DKK": {
                    "ID": "DKK",
                    "Description": "DKK",
                    "Index": 14
                  },
                  "EGP": {
                    "ID": "EGP",
                    "Description": "EGP",
                    "Index": 15
                  },
                  "EUR": {
                    "ID": "EUR",
                    "Description": "EUR",
                    "Index": 16
                  },
                  "GBP": {
                    "ID": "GBP",
                    "Description": "GBP",
                    "Options": {
                      "MinPrice": {
                        "ID": "MinPrice",
                        "Description": "minimum",
                        "ShortDescription": "min",
                        "Options": {
                          "15": {
                            "ID": "15",
                            "Description": "£15",
                            "ShortDescription": "£15",
                            "Index": 1
                          },
                          "20": {
                            "ID": "20",
                            "Description": "£20",
                            "ShortDescription": "£20",
                            "Index": 2
                          },
                          "25": {
                            "ID": "25",
                            "Description": "£25",
                            "ShortDescription": "£25",
                            "Index": 3
                          },
                          "30": {
                            "ID": "30",
                            "Description": "£30",
                            "ShortDescription": "£30",
                            "Index": 4
                          },
                          "35": {
                            "ID": "35",
                            "Description": "£35",
                            "ShortDescription": "£35",
                            "Index": 5
                          },
                          "40": {
                            "ID": "40",
                            "Description": "£40",
                            "ShortDescription": "£40",
                            "Index": 6
                          },
                          "45": {
                            "ID": "45",
                            "Description": "£45",
                            "ShortDescription": "£45",
                            "Index": 7
                          },
                          "50": {
                            "ID": "50",
                            "Description": "£50",
                            "ShortDescription": "£50",
                            "Index": 8
                          },
                          "200": {
                            "ID": "200",
                            "Description": "£200",
                            "ShortDescription": "£200",
                            "Index": 9
                          },
                          "300": {
                            "ID": "300",
                            "Description": "£300",
                            "ShortDescription": "£300",
                            "Index": 10
                          },
                          "400": {
                            "ID": "400",
                            "Description": "£400",
                            "ShortDescription": "£400",
                            "Index": 11
                          },
                          "500": {
                            "ID": "500",
                            "Description": "£500",
                            "ShortDescription": "£500",
                            "Index": 12
                          },
                          "600": {
                            "ID": "600",
                            "Description": "£600",
                            "ShortDescription": "£600",
                            "Index": 13
                          },
                          "700": {
                            "ID": "700",
                            "Description": "£700",
                            "ShortDescription": "£700",
                            "Index": 14
                          },
                          "800": {
                            "ID": "800",
                            "Description": "£800",
                            "ShortDescription": "£800",
                            "Index": 15
                          },
                          "900": {
                            "ID": "900",
                            "Description": "£900",
                            "ShortDescription": "£900",
                            "Index": 16
                          },
                          "1000": {
                            "ID": "1000",
                            "Description": "£1,000",
                            "ShortDescription": "£1k",
                            "Index": 17
                          },
                          "2000": {
                            "ID": "2000",
                            "Description": "£2,000",
                            "ShortDescription": "£2k",
                            "Index": 18
                          },
                          "3000": {
                            "ID": "3000",
                            "Description": "£3,000",
                            "ShortDescription": "£3k",
                            "Index": 19
                          },
                          "4000": {
                            "ID": "4000",
                            "Description": "£4,000",
                            "ShortDescription": "£4k",
                            "Index": 20
                          },
                          "5000": {
                            "ID": "5000",
                            "Description": "£5,000",
                            "ShortDescription": "£5k",
                            "Index": 21
                          },
                          "6000": {
                            "ID": "6000",
                            "Description": "£6,000",
                            "ShortDescription": "£6k",
                            "Index": 22
                          },
                          "7000": {
                            "ID": "7000",
                            "Description": "£7,000",
                            "ShortDescription": "£7k",
                            "Index": 23
                          },
                          "8000": {
                            "ID": "8000",
                            "Description": "£8,000",
                            "ShortDescription": "£8k",
                            "Index": 24
                          },
                          "9000": {
                            "ID": "9000",
                            "Description": "£9,000",
                            "ShortDescription": "£9k",
                            "Index": 25
                          },
                          "10000": {
                            "ID": "10000",
                            "Description": "£10,000",
                            "ShortDescription": "£10k",
                            "Index": 26
                          },
                          "20000": {
                            "ID": "20000",
                            "Description": "£20,000",
                            "ShortDescription": "£20k",
                            "Index": 27
                          },
                          "30000": {
                            "ID": "30000",
                            "Description": "£30,000",
                            "ShortDescription": "£30k",
                            "Index": 28
                          },
                          "40000": {
                            "ID": "40000",
                            "Description": "£40,000",
                            "ShortDescription": "£40k",
                            "Index": 29
                          },
                          "-1": {
                            "ID": "-1",
                            "Description": "No min",
                            "ShortDescription": "No min",
                            "HierarchicalID": "-1",
                            "Index": 0
                          }
                        },
                        "Index": 0
                      },
                      "MaxPrice": {
                        "ID": "MaxPrice",
                        "Description": "maximum",
                        "ShortDescription": "max",
                        "Options": {
                          "15": {
                            "ID": "15",
                            "Description": "£15",
                            "ShortDescription": "£15",
                            "Index": 0
                          },
                          "20": {
                            "ID": "20",
                            "Description": "£20",
                            "ShortDescription": "£20",
                            "Index": 1
                          },
                          "25": {
                            "ID": "25",
                            "Description": "£25",
                            "ShortDescription": "£25",
                            "Index": 2
                          },
                          "30": {
                            "ID": "30",
                            "Description": "£30",
                            "ShortDescription": "£30",
                            "Index": 3
                          },
                          "35": {
                            "ID": "35",
                            "Description": "£35",
                            "ShortDescription": "£35",
                            "Index": 4
                          },
                          "40": {
                            "ID": "40",
                            "Description": "£40",
                            "ShortDescription": "£40",
                            "Index": 5
                          },
                          "45": {
                            "ID": "45",
                            "Description": "£45",
                            "ShortDescription": "£45",
                            "Index": 6
                          },
                          "50": {
                            "ID": "50",
                            "Description": "£50",
                            "ShortDescription": "£50",
                            "Index": 7
                          },
                          "200": {
                            "ID": "200",
                            "Description": "£200",
                            "ShortDescription": "£200",
                            "Index": 8
                          },
                          "300": {
                            "ID": "300",
                            "Description": "£300",
                            "ShortDescription": "£300",
                            "Index": 9
                          },
                          "400": {
                            "ID": "400",
                            "Description": "£400",
                            "ShortDescription": "£400",
                            "Index": 10
                          },
                          "500": {
                            "ID": "500",
                            "Description": "£500",
                            "ShortDescription": "£500",
                            "Index": 11
                          },
                          "600": {
                            "ID": "600",
                            "Description": "£600",
                            "ShortDescription": "£600",
                            "Index": 12
                          },
                          "700": {
                            "ID": "700",
                            "Description": "£700",
                            "ShortDescription": "£700",
                            "Index": 13
                          },
                          "800": {
                            "ID": "800",
                            "Description": "£800",
                            "ShortDescription": "£800",
                            "Index": 14
                          },
                          "900": {
                            "ID": "900",
                            "Description": "£900",
                            "ShortDescription": "£900",
                            "Index": 15
                          },
                          "1000": {
                            "ID": "1000",
                            "Description": "£1,000",
                            "ShortDescription": "£1k",
                            "Index": 16
                          },
                          "2000": {
                            "ID": "2000",
                            "Description": "£2,000",
                            "ShortDescription": "£2k",
                            "Index": 17
                          },
                          "3000": {
                            "ID": "3000",
                            "Description": "£3,000",
                            "ShortDescription": "£3k",
                            "Index": 18
                          },
                          "4000": {
                            "ID": "4000",
                            "Description": "£4,000",
                            "ShortDescription": "£4k",
                            "Index": 19
                          },
                          "5000": {
                            "ID": "5000",
                            "Description": "£5,000",
                            "ShortDescription": "£5k",
                            "Index": 20
                          },
                          "6000": {
                            "ID": "6000",
                            "Description": "£6,000",
                            "ShortDescription": "£6k",
                            "Index": 21
                          },
                          "7000": {
                            "ID": "7000",
                            "Description": "£7,000",
                            "ShortDescription": "£7k",
                            "Index": 22
                          },
                          "8000": {
                            "ID": "8000",
                            "Description": "£8,000",
                            "ShortDescription": "£8k",
                            "Index": 23
                          },
                          "9000": {
                            "ID": "9000",
                            "Description": "£9,000",
                            "ShortDescription": "£9k",
                            "Index": 24
                          },
                          "10000": {
                            "ID": "10000",
                            "Description": "£10,000",
                            "ShortDescription": "£10k",
                            "Index": 25
                          },
                          "20000": {
                            "ID": "20000",
                            "Description": "£20,000",
                            "ShortDescription": "£20k",
                            "Index": 26
                          },
                          "30000": {
                            "ID": "30000",
                            "Description": "£30,000",
                            "ShortDescription": "£30k",
                            "Index": 27
                          },
                          "40000": {
                            "ID": "40000",
                            "Description": "£40,000",
                            "ShortDescription": "£40k",
                            "Index": 28
                          },
                          "-1": {
                            "ID": "-1",
                            "Description": "No max",
                            "ShortDescription": "No max",
                            "HierarchicalID": "-1",
                            "Index": 29
                          }
                        },
                        "Index": 1
                      }
                    },
                    "Index": 17
                  },
                  "HKD": {
                    "ID": "HKD",
                    "Description": "HKD",
                    "Index": 18
                  },
                  "HRK": {
                    "ID": "HRK",
                    "Description": "HRK",
                    "Index": 19
                  },
                  "HUF": {
                    "ID": "HUF",
                    "Description": "HUF",
                    "Index": 20
                  },
                  "IDR": {
                    "ID": "IDR",
                    "Description": "IDR",
                    "Index": 21
                  },
                  "ILS": {
                    "ID": "ILS",
                    "Description": "ILS",
                    "Index": 22
                  },
                  "INR": {
                    "ID": "INR",
                    "Description": "INR",
                    "Index": 23
                  },
                  "IRR": {
                    "ID": "IRR",
                    "Description": "IRR",
                    "Index": 24
                  },
                  "ISK": {
                    "ID": "ISK",
                    "Description": "ISK",
                    "Index": 25
                  },
                  "JPY": {
                    "ID": "JPY",
                    "Description": "JPY",
                    "Index": 26
                  },
                  "KRW": {
                    "ID": "KRW",
                    "Description": "KRW",
                    "Index": 27
                  },
                  "KWD": {
                    "ID": "KWD",
                    "Description": "KWD",
                    "Index": 28
                  },
                  "KZT": {
                    "ID": "KZT",
                    "Description": "KZT",
                    "Index": 29
                  },
                  "LBP": {
                    "ID": "LBP",
                    "Description": "LBP",
                    "Index": 30
                  },
                  "LKR": {
                    "ID": "LKR",
                    "Description": "LKR",
                    "Index": 31
                  },
                  "LTL": {
                    "ID": "LTL",
                    "Description": "LTL",
                    "Index": 32
                  },
                  "LVL": {
                    "ID": "LVL",
                    "Description": "LVL",
                    "Index": 33
                  },
                  "LYD": {
                    "ID": "LYD",
                    "Description": "LYD",
                    "Index": 34
                  },
                  "MAD": {
                    "ID": "MAD",
                    "Description": "MAD",
                    "Index": 35
                  },
                  "MUR": {
                    "ID": "MUR",
                    "Description": "MUR",
                    "Index": 36
                  },
                  "MXN": {
                    "ID": "MXN",
                    "Description": "MXN",
                    "Index": 37
                  },
                  "MYR": {
                    "ID": "MYR",
                    "Description": "MYR",
                    "Index": 38
                  },
                  "NGN": {
                    "ID": "NGN",
                    "Description": "NGN",
                    "Index": 39
                  },
                  "NOK": {
                    "ID": "NOK",
                    "Description": "NOK",
                    "Index": 40
                  },
                  "NPR": {
                    "ID": "NPR",
                    "Description": "NPR",
                    "Index": 41
                  },
                  "NZD": {
                    "ID": "NZD",
                    "Description": "NZD",
                    "Index": 42
                  },
                  "OMR": {
                    "ID": "OMR",
                    "Description": "OMR",
                    "Index": 43
                  },
                  "PHP": {
                    "ID": "PHP",
                    "Description": "PHP",
                    "Index": 44
                  },
                  "PKR": {
                    "ID": "PKR",
                    "Description": "PKR",
                    "Index": 45
                  },
                  "PLN": {
                    "ID": "PLN",
                    "Description": "PLN",
                    "Index": 46
                  },
                  "QAR": {
                    "ID": "QAR",
                    "Description": "QAR",
                    "Index": 47
                  },
                  "RMB": {
                    "ID": "RMB",
                    "Description": "RMB",
                    "Index": 48
                  },
                  "RON": {
                    "ID": "RON",
                    "Description": "RON",
                    "Index": 49
                  },
                  "RSD": {
                    "ID": "RSD",
                    "Description": "RSD",
                    "Index": 50
                  },
                  "RUB": {
                    "ID": "RUB",
                    "Description": "RUB",
                    "Index": 51
                  },
                  "SAR": {
                    "ID": "SAR",
                    "Description": "SAR",
                    "Index": 52
                  },
                  "SEK": {
                    "ID": "SEK",
                    "Description": "SEK",
                    "Index": 53
                  },
                  "SGD": {
                    "ID": "SGD",
                    "Description": "SGD",
                    "Index": 54
                  },
                  "THB": {
                    "ID": "THB",
                    "Description": "THB",
                    "Index": 55
                  },
                  "TRY": {
                    "ID": "TRY",
                    "Description": "TRY",
                    "Index": 56
                  },
                  "TWD": {
                    "ID": "TWD",
                    "Description": "TWD",
                    "Index": 57
                  },
                  "USD": {
                    "ID": "USD",
                    "Description": "USD",
                    "Index": 58
                  },
                  "VND": {
                    "ID": "VND",
                    "Description": "VND",
                    "Index": 59
                  },
                  "ZAR": {
                    "ID": "ZAR",
                    "Description": "ZAR",
                    "Index": 60
                  }
                },
                "PriceInterval_Comm_Rent_Year": {
                  "AED": {
                    "ID": "AED",
                    "Description": "AED",
                    "Index": 0
                  },
                  "ARS": {
                    "ID": "ARS",
                    "Description": "ARS",
                    "Index": 1
                  },
                  "AUD": {
                    "ID": "AUD",
                    "Description": "AUD",
                    "Index": 2
                  },
                  "BGN": {
                    "ID": "BGN",
                    "Description": "BGN",
                    "Index": 3
                  },
                  "BHD": {
                    "ID": "BHD",
                    "Description": "BHD",
                    "Index": 4
                  },
                  "BND": {
                    "ID": "BND",
                    "Description": "BND",
                    "Index": 5
                  },
                  "BRL": {
                    "ID": "BRL",
                    "Description": "BRL",
                    "Index": 6
                  },
                  "BWP": {
                    "ID": "BWP",
                    "Description": "BWP",
                    "Index": 7
                  },
                  "CAD": {
                    "ID": "CAD",
                    "Description": "CAD",
                    "Index": 8
                  },
                  "CHF": {
                    "ID": "CHF",
                    "Description": "CHF",
                    "Index": 9
                  },
                  "CLP": {
                    "ID": "CLP",
                    "Description": "CLP",
                    "Index": 10
                  },
                  "CNY": {
                    "ID": "CNY",
                    "Description": "CNY",
                    "Index": 11
                  },
                  "COP": {
                    "ID": "COP",
                    "Description": "COP",
                    "Index": 12
                  },
                  "CZK": {
                    "ID": "CZK",
                    "Description": "CZK",
                    "Index": 13
                  },
                  "DKK": {
                    "ID": "DKK",
                    "Description": "DKK",
                    "Index": 14
                  },
                  "EGP": {
                    "ID": "EGP",
                    "Description": "EGP",
                    "Index": 15
                  },
                  "EUR": {
                    "ID": "EUR",
                    "Description": "EUR",
                    "Index": 16
                  },
                  "GBP": {
                    "ID": "GBP",
                    "Description": "GBP",
                    "Options": {
                      "MinPrice": {
                        "ID": "MinPrice",
                        "Description": "minimum",
                        "ShortDescription": "min",
                        "Options": {
                          "180": {
                            "ID": "180",
                            "Description": "£180",
                            "ShortDescription": "£180",
                            "Index": 1
                          },
                          "240": {
                            "ID": "240",
                            "Description": "£240",
                            "ShortDescription": "£240",
                            "Index": 2
                          },
                          "300": {
                            "ID": "300",
                            "Description": "£300",
                            "ShortDescription": "£300",
                            "Index": 3
                          },
                          "360": {
                            "ID": "360",
                            "Description": "£360",
                            "ShortDescription": "£360",
                            "Index": 4
                          },
                          "420": {
                            "ID": "420",
                            "Description": "£420",
                            "ShortDescription": "£420",
                            "Index": 5
                          },
                          "480": {
                            "ID": "480",
                            "Description": "£480",
                            "ShortDescription": "£480",
                            "Index": 6
                          },
                          "540": {
                            "ID": "540",
                            "Description": "£540",
                            "ShortDescription": "£540",
                            "Index": 7
                          },
                          "600": {
                            "ID": "600",
                            "Description": "£600",
                            "ShortDescription": "£600",
                            "Index": 8
                          },
                          "2500": {
                            "ID": "2500",
                            "Description": "£2,500",
                            "ShortDescription": "£2.5k",
                            "Index": 9
                          },
                          "3000": {
                            "ID": "3000",
                            "Description": "£3,000",
                            "ShortDescription": "£3k",
                            "Index": 10
                          },
                          "3500": {
                            "ID": "3500",
                            "Description": "£3,500",
                            "ShortDescription": "£3.5k",
                            "Index": 11
                          },
                          "4000": {
                            "ID": "4000",
                            "Description": "£4,000",
                            "ShortDescription": "£4k",
                            "Index": 12
                          },
                          "4500": {
                            "ID": "4500",
                            "Description": "£4,500",
                            "ShortDescription": "£4.5k",
                            "Index": 13
                          },
                          "5000": {
                            "ID": "5000",
                            "Description": "£5,000",
                            "ShortDescription": "£5k",
                            "Index": 14
                          },
                          "7500": {
                            "ID": "7500",
                            "Description": "£7,500",
                            "ShortDescription": "£7.5k",
                            "Index": 15
                          },
                          "10000": {
                            "ID": "10000",
                            "Description": "£10,000",
                            "ShortDescription": "£10k",
                            "Index": 16
                          },
                          "12500": {
                            "ID": "12500",
                            "Description": "£12,500",
                            "ShortDescription": "£12.5k",
                            "Index": 17
                          },
                          "15000": {
                            "ID": "15000",
                            "Description": "£15,000",
                            "ShortDescription": "£15k",
                            "Index": 18
                          },
                          "17500": {
                            "ID": "17500",
                            "Description": "£17,500",
                            "ShortDescription": "£17.5k",
                            "Index": 19
                          },
                          "20000": {
                            "ID": "20000",
                            "Description": "£20,000",
                            "ShortDescription": "£20k",
                            "Index": 20
                          },
                          "22500": {
                            "ID": "22500",
                            "Description": "£22,500",
                            "ShortDescription": "£22.5k",
                            "Index": 21
                          },
                          "25000": {
                            "ID": "25000",
                            "Description": "£25,000",
                            "ShortDescription": "£25k",
                            "Index": 22
                          },
                          "27500": {
                            "ID": "27500",
                            "Description": "£27,500",
                            "ShortDescription": "£27.5k",
                            "Index": 23
                          },
                          "30000": {
                            "ID": "30000",
                            "Description": "£30,000",
                            "ShortDescription": "£30k",
                            "Index": 24
                          },
                          "32500": {
                            "ID": "32500",
                            "Description": "£32,500",
                            "ShortDescription": "£32.5k",
                            "Index": 25
                          },
                          "35000": {
                            "ID": "35000",
                            "Description": "£35,000",
                            "ShortDescription": "£35k",
                            "Index": 26
                          },
                          "37500": {
                            "ID": "37500",
                            "Description": "£37,500",
                            "ShortDescription": "£37.5k",
                            "Index": 27
                          },
                          "40000": {
                            "ID": "40000",
                            "Description": "£40,000",
                            "ShortDescription": "£40k",
                            "Index": 28
                          },
                          "42500": {
                            "ID": "42500",
                            "Description": "£42,500",
                            "ShortDescription": "£42.5k",
                            "Index": 29
                          },
                          "45000": {
                            "ID": "45000",
                            "Description": "£45,000",
                            "ShortDescription": "£45k",
                            "Index": 30
                          },
                          "47500": {
                            "ID": "47500",
                            "Description": "£47,500",
                            "ShortDescription": "£47.5k",
                            "Index": 31
                          },
                          "50000": {
                            "ID": "50000",
                            "Description": "£50,000",
                            "ShortDescription": "£50k",
                            "Index": 32
                          },
                          "55000": {
                            "ID": "55000",
                            "Description": "£55,000",
                            "ShortDescription": "£55k",
                            "Index": 33
                          },
                          "60000": {
                            "ID": "60000",
                            "Description": "£60,000",
                            "ShortDescription": "£60k",
                            "Index": 34
                          },
                          "65000": {
                            "ID": "65000",
                            "Description": "£65,000",
                            "ShortDescription": "£65k",
                            "Index": 35
                          },
                          "70000": {
                            "ID": "70000",
                            "Description": "£70,000",
                            "ShortDescription": "£70k",
                            "Index": 36
                          },
                          "80000": {
                            "ID": "80000",
                            "Description": "£80,000",
                            "ShortDescription": "£80k",
                            "Index": 37
                          },
                          "90000": {
                            "ID": "90000",
                            "Description": "£90,000",
                            "ShortDescription": "£90k",
                            "Index": 38
                          },
                          "100000": {
                            "ID": "100000",
                            "Description": "£100,000",
                            "ShortDescription": "£100k",
                            "Index": 39
                          },
                          "120000": {
                            "ID": "120000",
                            "Description": "£120,000",
                            "ShortDescription": "£120k",
                            "Index": 40
                          },
                          "140000": {
                            "ID": "140000",
                            "Description": "£140,000",
                            "ShortDescription": "£140k",
                            "Index": 41
                          },
                          "160000": {
                            "ID": "160000",
                            "Description": "£160,000",
                            "ShortDescription": "£160k",
                            "Index": 42
                          },
                          "180000": {
                            "ID": "180000",
                            "Description": "£180,000",
                            "ShortDescription": "£180k",
                            "Index": 43
                          },
                          "200000": {
                            "ID": "200000",
                            "Description": "£200,000",
                            "ShortDescription": "£200k",
                            "Index": 44
                          },
                          "250000": {
                            "ID": "250000",
                            "Description": "£250,000",
                            "ShortDescription": "£250k",
                            "Index": 45
                          },
                          "300000": {
                            "ID": "300000",
                            "Description": "£300,000",
                            "ShortDescription": "£300k",
                            "Index": 46
                          },
                          "350000": {
                            "ID": "350000",
                            "Description": "£350,000",
                            "ShortDescription": "£350k",
                            "Index": 47
                          },
                          "400000": {
                            "ID": "400000",
                            "Description": "£400,000",
                            "ShortDescription": "£400k",
                            "Index": 48
                          },
                          "450000": {
                            "ID": "450000",
                            "Description": "£450,000",
                            "ShortDescription": "£450k",
                            "Index": 49
                          },
                          "500000": {
                            "ID": "500000",
                            "Description": "£500,000",
                            "ShortDescription": "£500k",
                            "Index": 50
                          },
                          "-1": {
                            "ID": "-1",
                            "Description": "No min",
                            "ShortDescription": "No min",
                            "HierarchicalID": "-1",
                            "Index": 0
                          }
                        },
                        "Index": 0
                      },
                      "MaxPrice": {
                        "ID": "MaxPrice",
                        "Description": "maximum",
                        "ShortDescription": "max",
                        "Options": {
                          "180": {
                            "ID": "180",
                            "Description": "£180",
                            "ShortDescription": "£180",
                            "Index": 0
                          },
                          "240": {
                            "ID": "240",
                            "Description": "£240",
                            "ShortDescription": "£240",
                            "Index": 1
                          },
                          "300": {
                            "ID": "300",
                            "Description": "£300",
                            "ShortDescription": "£300",
                            "Index": 2
                          },
                          "360": {
                            "ID": "360",
                            "Description": "£360",
                            "ShortDescription": "£360",
                            "Index": 3
                          },
                          "420": {
                            "ID": "420",
                            "Description": "£420",
                            "ShortDescription": "£420",
                            "Index": 4
                          },
                          "480": {
                            "ID": "480",
                            "Description": "£480",
                            "ShortDescription": "£480",
                            "Index": 5
                          },
                          "540": {
                            "ID": "540",
                            "Description": "£540",
                            "ShortDescription": "£540",
                            "Index": 6
                          },
                          "600": {
                            "ID": "600",
                            "Description": "£600",
                            "ShortDescription": "£600",
                            "Index": 7
                          },
                          "2500": {
                            "ID": "2500",
                            "Description": "£2,500",
                            "ShortDescription": "£2.5k",
                            "Index": 8
                          },
                          "3000": {
                            "ID": "3000",
                            "Description": "£3,000",
                            "ShortDescription": "£3k",
                            "Index": 9
                          },
                          "3500": {
                            "ID": "3500",
                            "Description": "£3,500",
                            "ShortDescription": "£3.5k",
                            "Index": 10
                          },
                          "4000": {
                            "ID": "4000",
                            "Description": "£4,000",
                            "ShortDescription": "£4k",
                            "Index": 11
                          },
                          "4500": {
                            "ID": "4500",
                            "Description": "£4,500",
                            "ShortDescription": "£4.5k",
                            "Index": 12
                          },
                          "5000": {
                            "ID": "5000",
                            "Description": "£5,000",
                            "ShortDescription": "£5k",
                            "Index": 13
                          },
                          "7500": {
                            "ID": "7500",
                            "Description": "£7,500",
                            "ShortDescription": "£7.5k",
                            "Index": 14
                          },
                          "10000": {
                            "ID": "10000",
                            "Description": "£10,000",
                            "ShortDescription": "£10k",
                            "Index": 15
                          },
                          "12500": {
                            "ID": "12500",
                            "Description": "£12,500",
                            "ShortDescription": "£12.5k",
                            "Index": 16
                          },
                          "15000": {
                            "ID": "15000",
                            "Description": "£15,000",
                            "ShortDescription": "£15k",
                            "Index": 17
                          },
                          "17500": {
                            "ID": "17500",
                            "Description": "£17,500",
                            "ShortDescription": "£17.5k",
                            "Index": 18
                          },
                          "20000": {
                            "ID": "20000",
                            "Description": "£20,000",
                            "ShortDescription": "£20k",
                            "Index": 19
                          },
                          "22500": {
                            "ID": "22500",
                            "Description": "£22,500",
                            "ShortDescription": "£22.5k",
                            "Index": 20
                          },
                          "25000": {
                            "ID": "25000",
                            "Description": "£25,000",
                            "ShortDescription": "£25k",
                            "Index": 21
                          },
                          "27500": {
                            "ID": "27500",
                            "Description": "£27,500",
                            "ShortDescription": "£27.5k",
                            "Index": 22
                          },
                          "30000": {
                            "ID": "30000",
                            "Description": "£30,000",
                            "ShortDescription": "£30k",
                            "Index": 23
                          },
                          "32500": {
                            "ID": "32500",
                            "Description": "£32,500",
                            "ShortDescription": "£32.5k",
                            "Index": 24
                          },
                          "35000": {
                            "ID": "35000",
                            "Description": "£35,000",
                            "ShortDescription": "£35k",
                            "Index": 25
                          },
                          "37500": {
                            "ID": "37500",
                            "Description": "£37,500",
                            "ShortDescription": "£37.5k",
                            "Index": 26
                          },
                          "40000": {
                            "ID": "40000",
                            "Description": "£40,000",
                            "ShortDescription": "£40k",
                            "Index": 27
                          },
                          "42500": {
                            "ID": "42500",
                            "Description": "£42,500",
                            "ShortDescription": "£42.5k",
                            "Index": 28
                          },
                          "45000": {
                            "ID": "45000",
                            "Description": "£45,000",
                            "ShortDescription": "£45k",
                            "Index": 29
                          },
                          "47500": {
                            "ID": "47500",
                            "Description": "£47,500",
                            "ShortDescription": "£47.5k",
                            "Index": 30
                          },
                          "50000": {
                            "ID": "50000",
                            "Description": "£50,000",
                            "ShortDescription": "£50k",
                            "Index": 31
                          },
                          "55000": {
                            "ID": "55000",
                            "Description": "£55,000",
                            "ShortDescription": "£55k",
                            "Index": 32
                          },
                          "60000": {
                            "ID": "60000",
                            "Description": "£60,000",
                            "ShortDescription": "£60k",
                            "Index": 33
                          },
                          "65000": {
                            "ID": "65000",
                            "Description": "£65,000",
                            "ShortDescription": "£65k",
                            "Index": 34
                          },
                          "70000": {
                            "ID": "70000",
                            "Description": "£70,000",
                            "ShortDescription": "£70k",
                            "Index": 35
                          },
                          "80000": {
                            "ID": "80000",
                            "Description": "£80,000",
                            "ShortDescription": "£80k",
                            "Index": 36
                          },
                          "90000": {
                            "ID": "90000",
                            "Description": "£90,000",
                            "ShortDescription": "£90k",
                            "Index": 37
                          },
                          "100000": {
                            "ID": "100000",
                            "Description": "£100,000",
                            "ShortDescription": "£100k",
                            "Index": 38
                          },
                          "120000": {
                            "ID": "120000",
                            "Description": "£120,000",
                            "ShortDescription": "£120k",
                            "Index": 39
                          },
                          "140000": {
                            "ID": "140000",
                            "Description": "£140,000",
                            "ShortDescription": "£140k",
                            "Index": 40
                          },
                          "160000": {
                            "ID": "160000",
                            "Description": "£160,000",
                            "ShortDescription": "£160k",
                            "Index": 41
                          },
                          "180000": {
                            "ID": "180000",
                            "Description": "£180,000",
                            "ShortDescription": "£180k",
                            "Index": 42
                          },
                          "200000": {
                            "ID": "200000",
                            "Description": "£200,000",
                            "ShortDescription": "£200k",
                            "Index": 43
                          },
                          "250000": {
                            "ID": "250000",
                            "Description": "£250,000",
                            "ShortDescription": "£250k",
                            "Index": 44
                          },
                          "300000": {
                            "ID": "300000",
                            "Description": "£300,000",
                            "ShortDescription": "£300k",
                            "Index": 45
                          },
                          "350000": {
                            "ID": "350000",
                            "Description": "£350,000",
                            "ShortDescription": "£350k",
                            "Index": 46
                          },
                          "400000": {
                            "ID": "400000",
                            "Description": "£400,000",
                            "ShortDescription": "£400k",
                            "Index": 47
                          },
                          "450000": {
                            "ID": "450000",
                            "Description": "£450,000",
                            "ShortDescription": "£450k",
                            "Index": 48
                          },
                          "500000": {
                            "ID": "500000",
                            "Description": "£500,000",
                            "ShortDescription": "£500k",
                            "Index": 49
                          },
                          "-1": {
                            "ID": "-1",
                            "Description": "No max",
                            "ShortDescription": "No max",
                            "HierarchicalID": "-1",
                            "Index": 50
                          }
                        },
                        "Index": 1
                      }
                    },
                    "Index": 17
                  },
                  "HKD": {
                    "ID": "HKD",
                    "Description": "HKD",
                    "Index": 18
                  },
                  "HRK": {
                    "ID": "HRK",
                    "Description": "HRK",
                    "Index": 19
                  },
                  "HUF": {
                    "ID": "HUF",
                    "Description": "HUF",
                    "Index": 20
                  },
                  "IDR": {
                    "ID": "IDR",
                    "Description": "IDR",
                    "Index": 21
                  },
                  "ILS": {
                    "ID": "ILS",
                    "Description": "ILS",
                    "Index": 22
                  },
                  "INR": {
                    "ID": "INR",
                    "Description": "INR",
                    "Index": 23
                  },
                  "IRR": {
                    "ID": "IRR",
                    "Description": "IRR",
                    "Index": 24
                  },
                  "ISK": {
                    "ID": "ISK",
                    "Description": "ISK",
                    "Index": 25
                  },
                  "JPY": {
                    "ID": "JPY",
                    "Description": "JPY",
                    "Index": 26
                  },
                  "KRW": {
                    "ID": "KRW",
                    "Description": "KRW",
                    "Index": 27
                  },
                  "KWD": {
                    "ID": "KWD",
                    "Description": "KWD",
                    "Index": 28
                  },
                  "KZT": {
                    "ID": "KZT",
                    "Description": "KZT",
                    "Index": 29
                  },
                  "LBP": {
                    "ID": "LBP",
                    "Description": "LBP",
                    "Index": 30
                  },
                  "LKR": {
                    "ID": "LKR",
                    "Description": "LKR",
                    "Index": 31
                  },
                  "LTL": {
                    "ID": "LTL",
                    "Description": "LTL",
                    "Index": 32
                  },
                  "LVL": {
                    "ID": "LVL",
                    "Description": "LVL",
                    "Index": 33
                  },
                  "LYD": {
                    "ID": "LYD",
                    "Description": "LYD",
                    "Index": 34
                  },
                  "MAD": {
                    "ID": "MAD",
                    "Description": "MAD",
                    "Index": 35
                  },
                  "MUR": {
                    "ID": "MUR",
                    "Description": "MUR",
                    "Index": 36
                  },
                  "MXN": {
                    "ID": "MXN",
                    "Description": "MXN",
                    "Index": 37
                  },
                  "MYR": {
                    "ID": "MYR",
                    "Description": "MYR",
                    "Index": 38
                  },
                  "NGN": {
                    "ID": "NGN",
                    "Description": "NGN",
                    "Index": 39
                  },
                  "NOK": {
                    "ID": "NOK",
                    "Description": "NOK",
                    "Index": 40
                  },
                  "NPR": {
                    "ID": "NPR",
                    "Description": "NPR",
                    "Index": 41
                  },
                  "NZD": {
                    "ID": "NZD",
                    "Description": "NZD",
                    "Index": 42
                  },
                  "OMR": {
                    "ID": "OMR",
                    "Description": "OMR",
                    "Index": 43
                  },
                  "PHP": {
                    "ID": "PHP",
                    "Description": "PHP",
                    "Index": 44
                  },
                  "PKR": {
                    "ID": "PKR",
                    "Description": "PKR",
                    "Index": 45
                  },
                  "PLN": {
                    "ID": "PLN",
                    "Description": "PLN",
                    "Index": 46
                  },
                  "QAR": {
                    "ID": "QAR",
                    "Description": "QAR",
                    "Index": 47
                  },
                  "RMB": {
                    "ID": "RMB",
                    "Description": "RMB",
                    "Index": 48
                  },
                  "RON": {
                    "ID": "RON",
                    "Description": "RON",
                    "Index": 49
                  },
                  "RSD": {
                    "ID": "RSD",
                    "Description": "RSD",
                    "Index": 50
                  },
                  "RUB": {
                    "ID": "RUB",
                    "Description": "RUB",
                    "Index": 51
                  },
                  "SAR": {
                    "ID": "SAR",
                    "Description": "SAR",
                    "Index": 52
                  },
                  "SEK": {
                    "ID": "SEK",
                    "Description": "SEK",
                    "Index": 53
                  },
                  "SGD": {
                    "ID": "SGD",
                    "Description": "SGD",
                    "Index": 54
                  },
                  "THB": {
                    "ID": "THB",
                    "Description": "THB",
                    "Index": 55
                  },
                  "TRY": {
                    "ID": "TRY",
                    "Description": "TRY",
                    "Index": 56
                  },
                  "TWD": {
                    "ID": "TWD",
                    "Description": "TWD",
                    "Index": 57
                  },
                  "USD": {
                    "ID": "USD",
                    "Description": "USD",
                    "Index": 58
                  },
                  "VND": {
                    "ID": "VND",
                    "Description": "VND",
                    "Index": 59
                  },
                  "ZAR": {
                    "ID": "ZAR",
                    "Description": "ZAR",
                    "Index": 60
                  }
                },
                "NumberOfPeople": {
                  "MinNumberOfPeople": {
                    "ID": "MinNumberOfPeople",
                    "Description": "minimum",
                    "ShortDescription": "min",
                    "Options": {
                      "10": {
                        "ID": "10",
                        "Description": "10",
                        "ShortDescription": "10",
                        "HierarchicalID": "10",
                        "Index": 1
                      },
                      "20": {
                        "ID": "20",
                        "Description": "20",
                        "ShortDescription": "20",
                        "HierarchicalID": "20",
                        "Index": 2
                      },
                      "30": {
                        "ID": "30",
                        "Description": "30",
                        "ShortDescription": "30",
                        "HierarchicalID": "30",
                        "Index": 3
                      },
                      "40": {
                        "ID": "40",
                        "Description": "40",
                        "ShortDescription": "40",
                        "HierarchicalID": "40",
                        "Index": 4
                      },
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 5
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 6
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 7
                      },
                      "300": {
                        "ID": "300",
                        "Description": "300",
                        "ShortDescription": "300",
                        "HierarchicalID": "300",
                        "Index": 8
                      },
                      "400": {
                        "ID": "400",
                        "Description": "400",
                        "ShortDescription": "400",
                        "HierarchicalID": "400",
                        "Index": 9
                      },
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 10
                      },
                      "750": {
                        "ID": "750",
                        "Description": "750",
                        "ShortDescription": "750",
                        "HierarchicalID": "750",
                        "Index": 11
                      },
                      "1000": {
                        "ID": "1000",
                        "Description": "1000",
                        "ShortDescription": "1000",
                        "HierarchicalID": "1000",
                        "Index": 12
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2000",
                        "ShortDescription": "2000",
                        "HierarchicalID": "2000",
                        "Index": 13
                      },
                      "3000": {
                        "ID": "3000",
                        "Description": "3000",
                        "ShortDescription": "3000",
                        "HierarchicalID": "3000",
                        "Index": 14
                      },
                      "4000": {
                        "ID": "4000",
                        "Description": "4000",
                        "ShortDescription": "4000",
                        "HierarchicalID": "4000",
                        "Index": 15
                      },
                      "5000": {
                        "ID": "5000",
                        "Description": "5000",
                        "ShortDescription": "5000",
                        "HierarchicalID": "5000",
                        "Index": 16
                      },
                      "7500": {
                        "ID": "7500",
                        "Description": "7500",
                        "ShortDescription": "7500",
                        "HierarchicalID": "7500",
                        "Index": 17
                      },
                      "10000": {
                        "ID": "10000",
                        "Description": "10000",
                        "ShortDescription": "10000",
                        "HierarchicalID": "10000",
                        "Index": 18
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No min",
                        "ShortDescription": "No min",
                        "HierarchicalID": "-1",
                        "Index": 0
                      }
                    },
                    "Index": 0
                  },
                  "MaxNumberOfPeople": {
                    "ID": "MaxNumberOfPeople",
                    "Description": "maximum",
                    "ShortDescription": "max",
                    "Options": {
                      "10": {
                        "ID": "10",
                        "Description": "10",
                        "ShortDescription": "10",
                        "HierarchicalID": "10",
                        "Index": 0
                      },
                      "20": {
                        "ID": "20",
                        "Description": "20",
                        "ShortDescription": "20",
                        "HierarchicalID": "20",
                        "Index": 1
                      },
                      "30": {
                        "ID": "30",
                        "Description": "30",
                        "ShortDescription": "30",
                        "HierarchicalID": "30",
                        "Index": 2
                      },
                      "40": {
                        "ID": "40",
                        "Description": "40",
                        "ShortDescription": "40",
                        "HierarchicalID": "40",
                        "Index": 3
                      },
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 4
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 5
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 6
                      },
                      "300": {
                        "ID": "300",
                        "Description": "300",
                        "ShortDescription": "300",
                        "HierarchicalID": "300",
                        "Index": 7
                      },
                      "400": {
                        "ID": "400",
                        "Description": "400",
                        "ShortDescription": "400",
                        "HierarchicalID": "400",
                        "Index": 8
                      },
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 9
                      },
                      "750": {
                        "ID": "750",
                        "Description": "750",
                        "ShortDescription": "750",
                        "HierarchicalID": "750",
                        "Index": 10
                      },
                      "1000": {
                        "ID": "1000",
                        "Description": "1000",
                        "ShortDescription": "1000",
                        "HierarchicalID": "1000",
                        "Index": 11
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2000",
                        "ShortDescription": "2000",
                        "HierarchicalID": "2000",
                        "Index": 12
                      },
                      "3000": {
                        "ID": "3000",
                        "Description": "3000",
                        "ShortDescription": "3000",
                        "HierarchicalID": "3000",
                        "Index": 13
                      },
                      "4000": {
                        "ID": "4000",
                        "Description": "4000",
                        "ShortDescription": "4000",
                        "HierarchicalID": "4000",
                        "Index": 14
                      },
                      "5000": {
                        "ID": "5000",
                        "Description": "5000",
                        "ShortDescription": "5000",
                        "HierarchicalID": "5000",
                        "Index": 15
                      },
                      "7500": {
                        "ID": "7500",
                        "Description": "7500",
                        "ShortDescription": "7500",
                        "HierarchicalID": "7500",
                        "Index": 16
                      },
                      "10000": {
                        "ID": "10000",
                        "Description": "10000",
                        "ShortDescription": "10000",
                        "HierarchicalID": "10000",
                        "Index": 17
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No max",
                        "ShortDescription": "No max",
                        "HierarchicalID": "-1",
                        "Index": 18
                      }
                    },
                    "Index": 1
                  }
                },
                "BuildingAge": {
                  "MinBuildingAge": {
                    "ID": "MinBuildingAge",
                    "Description": "minimum",
                    "ShortDescription": "min",
                    "Options": {
                      "1850": {
                        "ID": "1850",
                        "Description": "1850",
                        "ShortDescription": "1850",
                        "HierarchicalID": "1850",
                        "Index": 1
                      },
                      "1875": {
                        "ID": "1875",
                        "Description": "1875",
                        "ShortDescription": "1875",
                        "HierarchicalID": "1875",
                        "Index": 2
                      },
                      "1900": {
                        "ID": "1900",
                        "Description": "1900",
                        "ShortDescription": "1900",
                        "HierarchicalID": "1900",
                        "Index": 3
                      },
                      "1925": {
                        "ID": "1925",
                        "Description": "1925",
                        "ShortDescription": "1925",
                        "HierarchicalID": "1925",
                        "Index": 4
                      },
                      "1950": {
                        "ID": "1950",
                        "Description": "1950",
                        "ShortDescription": "1950",
                        "HierarchicalID": "1950",
                        "Index": 5
                      },
                      "1975": {
                        "ID": "1975",
                        "Description": "1975",
                        "ShortDescription": "1975",
                        "HierarchicalID": "1975",
                        "Index": 6
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2000",
                        "ShortDescription": "2000",
                        "HierarchicalID": "2000",
                        "Index": 7
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No min",
                        "ShortDescription": "No min",
                        "HierarchicalID": "-1",
                        "Index": 0
                      }
                    },
                    "Index": 0
                  },
                  "MaxBuildingAge": {
                    "ID": "MaxBuildingAge",
                    "Description": "maximum",
                    "ShortDescription": "max",
                    "Options": {
                      "1850": {
                        "ID": "1850",
                        "Description": "1850",
                        "ShortDescription": "1850",
                        "HierarchicalID": "1850",
                        "Index": 0
                      },
                      "1875": {
                        "ID": "1875",
                        "Description": "1875",
                        "ShortDescription": "1875",
                        "HierarchicalID": "1875",
                        "Index": 1
                      },
                      "1900": {
                        "ID": "1900",
                        "Description": "1900",
                        "ShortDescription": "1900",
                        "HierarchicalID": "1900",
                        "Index": 2
                      },
                      "1925": {
                        "ID": "1925",
                        "Description": "1925",
                        "ShortDescription": "1925",
                        "HierarchicalID": "1925",
                        "Index": 3
                      },
                      "1950": {
                        "ID": "1950",
                        "Description": "1950",
                        "ShortDescription": "1950",
                        "HierarchicalID": "1950",
                        "Index": 4
                      },
                      "1975": {
                        "ID": "1975",
                        "Description": "1975",
                        "ShortDescription": "1975",
                        "HierarchicalID": "1975",
                        "Index": 5
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2000",
                        "ShortDescription": "2000",
                        "HierarchicalID": "2000",
                        "Index": 6
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No max",
                        "ShortDescription": "No max",
                        "HierarchicalID": "-1",
                        "Index": 7
                      }
                    },
                    "Index": 1
                  }
                },
                "AvailableSize_SquareFeet": {
                  "MinAvailableSize": {
                    "ID": "MinAvailableSize",
                    "Description": "minimum",
                    "ShortDescription": "min",
                    "Options": {
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 1
                      },
                      "750": {
                        "ID": "750",
                        "Description": "750",
                        "ShortDescription": "750",
                        "HierarchicalID": "750",
                        "Index": 2
                      },
                      "1000": {
                        "ID": "1000",
                        "Description": "1,000",
                        "ShortDescription": "1k",
                        "HierarchicalID": "1000",
                        "Index": 3
                      },
                      "1250": {
                        "ID": "1250",
                        "Description": "1,250",
                        "ShortDescription": "1.25k",
                        "HierarchicalID": "1250",
                        "Index": 4
                      },
                      "1500": {
                        "ID": "1500",
                        "Description": "1,500",
                        "ShortDescription": "1.5k",
                        "HierarchicalID": "1500",
                        "Index": 5
                      },
                      "1750": {
                        "ID": "1750",
                        "Description": "1,750",
                        "ShortDescription": "1.75k",
                        "HierarchicalID": "1750",
                        "Index": 6
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2,000",
                        "ShortDescription": "2k",
                        "HierarchicalID": "2000",
                        "Index": 7
                      },
                      "2500": {
                        "ID": "2500",
                        "Description": "2,500",
                        "ShortDescription": "2.5k",
                        "HierarchicalID": "2500",
                        "Index": 8
                      },
                      "3000": {
                        "ID": "3000",
                        "Description": "3,000",
                        "ShortDescription": "3k",
                        "HierarchicalID": "3000",
                        "Index": 9
                      },
                      "3500": {
                        "ID": "3500",
                        "Description": "3,500",
                        "ShortDescription": "3.5k",
                        "HierarchicalID": "3500",
                        "Index": 10
                      },
                      "4000": {
                        "ID": "4000",
                        "Description": "4,000",
                        "ShortDescription": "4k",
                        "HierarchicalID": "4000",
                        "Index": 11
                      },
                      "4500": {
                        "ID": "4500",
                        "Description": "4,500",
                        "ShortDescription": "4.5k",
                        "HierarchicalID": "4500",
                        "Index": 12
                      },
                      "5000": {
                        "ID": "5000",
                        "Description": "5,000",
                        "ShortDescription": "5k",
                        "HierarchicalID": "5000",
                        "Index": 13
                      },
                      "6000": {
                        "ID": "6000",
                        "Description": "6,000",
                        "ShortDescription": "6k",
                        "HierarchicalID": "6000",
                        "Index": 14
                      },
                      "7000": {
                        "ID": "7000",
                        "Description": "7,000",
                        "ShortDescription": "7k",
                        "HierarchicalID": "7000",
                        "Index": 15
                      },
                      "8000": {
                        "ID": "8000",
                        "Description": "8,000",
                        "ShortDescription": "8k",
                        "HierarchicalID": "8000",
                        "Index": 16
                      },
                      "9000": {
                        "ID": "9000",
                        "Description": "9,000",
                        "ShortDescription": "9k",
                        "HierarchicalID": "9000",
                        "Index": 17
                      },
                      "10000": {
                        "ID": "10000",
                        "Description": "10,000",
                        "ShortDescription": "10k",
                        "HierarchicalID": "10000",
                        "Index": 18
                      },
                      "12500": {
                        "ID": "12500",
                        "Description": "12,500",
                        "ShortDescription": "12.5k",
                        "HierarchicalID": "12500",
                        "Index": 19
                      },
                      "15000": {
                        "ID": "15000",
                        "Description": "15,000",
                        "ShortDescription": "15k",
                        "HierarchicalID": "15000",
                        "Index": 20
                      },
                      "17500": {
                        "ID": "17500",
                        "Description": "17,500",
                        "ShortDescription": "17.5k",
                        "HierarchicalID": "17500",
                        "Index": 21
                      },
                      "20000": {
                        "ID": "20000",
                        "Description": "20,000",
                        "ShortDescription": "20k",
                        "HierarchicalID": "20000",
                        "Index": 22
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No min",
                        "ShortDescription": "No min",
                        "HierarchicalID": "-1",
                        "Index": 0
                      }
                    },
                    "Index": 0
                  },
                  "MaxAvailableSize": {
                    "ID": "MaxAvailableSize",
                    "Description": "maximum",
                    "Options": {
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 0
                      },
                      "750": {
                        "ID": "750",
                        "Description": "750",
                        "ShortDescription": "750",
                        "HierarchicalID": "750",
                        "Index": 1
                      },
                      "1000": {
                        "ID": "1000",
                        "Description": "1,000",
                        "ShortDescription": "1k",
                        "HierarchicalID": "1000",
                        "Index": 2
                      },
                      "1250": {
                        "ID": "1250",
                        "Description": "1,250",
                        "ShortDescription": "1.25k",
                        "HierarchicalID": "1250",
                        "Index": 3
                      },
                      "1500": {
                        "ID": "1500",
                        "Description": "1,500",
                        "ShortDescription": "1.5k",
                        "HierarchicalID": "1500",
                        "Index": 4
                      },
                      "1750": {
                        "ID": "1750",
                        "Description": "1,750",
                        "ShortDescription": "1.75k",
                        "HierarchicalID": "1750",
                        "Index": 5
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2,000",
                        "ShortDescription": "2k",
                        "HierarchicalID": "2000",
                        "Index": 6
                      },
                      "2500": {
                        "ID": "2500",
                        "Description": "2,500",
                        "ShortDescription": "2.5k",
                        "HierarchicalID": "2500",
                        "Index": 7
                      },
                      "3000": {
                        "ID": "3000",
                        "Description": "3,000",
                        "ShortDescription": "3k",
                        "HierarchicalID": "3000",
                        "Index": 8
                      },
                      "3500": {
                        "ID": "3500",
                        "Description": "3,500",
                        "ShortDescription": "3.5k",
                        "HierarchicalID": "3500",
                        "Index": 9
                      },
                      "4000": {
                        "ID": "4000",
                        "Description": "4,000",
                        "ShortDescription": "4k",
                        "HierarchicalID": "4000",
                        "Index": 10
                      },
                      "4500": {
                        "ID": "4500",
                        "Description": "4,500",
                        "ShortDescription": "4.5k",
                        "HierarchicalID": "4500",
                        "Index": 11
                      },
                      "5000": {
                        "ID": "5000",
                        "Description": "5,000",
                        "ShortDescription": "5k",
                        "HierarchicalID": "5000",
                        "Index": 12
                      },
                      "6000": {
                        "ID": "6000",
                        "Description": "6,000",
                        "ShortDescription": "6k",
                        "HierarchicalID": "6000",
                        "Index": 13
                      },
                      "7000": {
                        "ID": "7000",
                        "Description": "7,000",
                        "ShortDescription": "7k",
                        "HierarchicalID": "7000",
                        "Index": 14
                      },
                      "8000": {
                        "ID": "8000",
                        "Description": "8,000",
                        "ShortDescription": "8k",
                        "HierarchicalID": "8000",
                        "Index": 15
                      },
                      "9000": {
                        "ID": "9000",
                        "Description": "9,000",
                        "ShortDescription": "9k",
                        "HierarchicalID": "9000",
                        "Index": 16
                      },
                      "10000": {
                        "ID": "10000",
                        "Description": "10,000",
                        "ShortDescription": "10k",
                        "HierarchicalID": "10000",
                        "Index": 17
                      },
                      "12500": {
                        "ID": "12500",
                        "Description": "12,500",
                        "ShortDescription": "12.5k",
                        "HierarchicalID": "12500",
                        "Index": 18
                      },
                      "15000": {
                        "ID": "15000",
                        "Description": "15,000",
                        "ShortDescription": "15k",
                        "HierarchicalID": "15000",
                        "Index": 19
                      },
                      "17500": {
                        "ID": "17500",
                        "Description": "17,500",
                        "ShortDescription": "17.5k",
                        "HierarchicalID": "17500",
                        "Index": 20
                      },
                      "20000": {
                        "ID": "20000",
                        "Description": "20,000",
                        "ShortDescription": "20k",
                        "HierarchicalID": "20000",
                        "Index": 21
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No max",
                        "ShortDescription": "No max",
                        "HierarchicalID": "-1",
                        "Index": 22
                      }
                    },
                    "Index": 1
                  }
                },
                "AvailableSize_SquareMeter": {
                  "MinAvailableSize": {
                    "ID": "MinAvailableSize",
                    "Description": "minimum",
                    "ShortDescription": "min",
                    "Options": {
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 1
                      },
                      "75": {
                        "ID": "75",
                        "Description": "75",
                        "ShortDescription": "75",
                        "HierarchicalID": "75",
                        "Index": 2
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 3
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 4
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 5
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 6
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 7
                      },
                      "250": {
                        "ID": "250",
                        "Description": "250",
                        "ShortDescription": "250",
                        "HierarchicalID": "250",
                        "Index": 8
                      },
                      "300": {
                        "ID": "300",
                        "Description": "300",
                        "ShortDescription": "300",
                        "HierarchicalID": "300",
                        "Index": 9
                      },
                      "350": {
                        "ID": "350",
                        "Description": "350",
                        "ShortDescription": "350",
                        "HierarchicalID": "350",
                        "Index": 10
                      },
                      "400": {
                        "ID": "400",
                        "Description": "400",
                        "ShortDescription": "400",
                        "HierarchicalID": "400",
                        "Index": 11
                      },
                      "450": {
                        "ID": "450",
                        "Description": "450",
                        "ShortDescription": "450",
                        "HierarchicalID": "450",
                        "Index": 12
                      },
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 13
                      },
                      "600": {
                        "ID": "600",
                        "Description": "600",
                        "ShortDescription": "600",
                        "HierarchicalID": "600",
                        "Index": 14
                      },
                      "700": {
                        "ID": "700",
                        "Description": "700",
                        "ShortDescription": "700",
                        "HierarchicalID": "700",
                        "Index": 15
                      },
                      "800": {
                        "ID": "800",
                        "Description": "800",
                        "ShortDescription": "800",
                        "HierarchicalID": "800",
                        "Index": 16
                      },
                      "900": {
                        "ID": "900",
                        "Description": "900",
                        "ShortDescription": "900",
                        "HierarchicalID": "900",
                        "Index": 17
                      },
                      "1000": {
                        "ID": "1000",
                        "Description": "1,000",
                        "ShortDescription": "1k",
                        "HierarchicalID": "1000",
                        "Index": 18
                      },
                      "1250": {
                        "ID": "1250",
                        "Description": "1,250",
                        "ShortDescription": "1.25k",
                        "HierarchicalID": "1250",
                        "Index": 19
                      },
                      "1500": {
                        "ID": "1500",
                        "Description": "1,500",
                        "ShortDescription": "1.5k",
                        "HierarchicalID": "1500",
                        "Index": 20
                      },
                      "1750": {
                        "ID": "1750",
                        "Description": "1,750",
                        "ShortDescription": "1.75k",
                        "HierarchicalID": "1750",
                        "Index": 21
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2,000",
                        "ShortDescription": "2k",
                        "HierarchicalID": "2000",
                        "Index": 22
                      },
                      "3000": {
                        "ID": "3000",
                        "Description": "3,000",
                        "ShortDescription": "3k",
                        "HierarchicalID": "3000",
                        "Index": 23
                      },
                      "5000": {
                        "ID": "5000",
                        "Description": "5,000",
                        "ShortDescription": "5k",
                        "HierarchicalID": "5000",
                        "Index": 24
                      },
                      "10000": {
                        "ID": "10000",
                        "Description": "10,000",
                        "ShortDescription": "10k",
                        "HierarchicalID": "10000",
                        "Index": 25
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No min",
                        "ShortDescription": "No min",
                        "HierarchicalID": "-1",
                        "Index": 0
                      }
                    },
                    "Index": 0
                  },
                  "MaxAvailableSize": {
                    "ID": "MaxAvailableSize",
                    "Description": "maximum",
                    "Options": {
                      "50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "50",
                        "Index": 0
                      },
                      "75": {
                        "ID": "75",
                        "Description": "75",
                        "ShortDescription": "75",
                        "HierarchicalID": "75",
                        "Index": 1
                      },
                      "100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "100",
                        "Index": 2
                      },
                      "125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "125",
                        "Index": 3
                      },
                      "150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "150",
                        "Index": 4
                      },
                      "175": {
                        "ID": "175",
                        "Description": "175",
                        "ShortDescription": "175",
                        "HierarchicalID": "175",
                        "Index": 5
                      },
                      "200": {
                        "ID": "200",
                        "Description": "200",
                        "ShortDescription": "200",
                        "HierarchicalID": "200",
                        "Index": 6
                      },
                      "250": {
                        "ID": "250",
                        "Description": "250",
                        "ShortDescription": "250",
                        "HierarchicalID": "250",
                        "Index": 7
                      },
                      "300": {
                        "ID": "300",
                        "Description": "300",
                        "ShortDescription": "300",
                        "HierarchicalID": "300",
                        "Index": 8
                      },
                      "350": {
                        "ID": "350",
                        "Description": "350",
                        "ShortDescription": "350",
                        "HierarchicalID": "350",
                        "Index": 9
                      },
                      "400": {
                        "ID": "400",
                        "Description": "400",
                        "ShortDescription": "400",
                        "HierarchicalID": "400",
                        "Index": 10
                      },
                      "450": {
                        "ID": "450",
                        "Description": "450",
                        "ShortDescription": "450",
                        "HierarchicalID": "450",
                        "Index": 11
                      },
                      "500": {
                        "ID": "500",
                        "Description": "500",
                        "ShortDescription": "500",
                        "HierarchicalID": "500",
                        "Index": 12
                      },
                      "600": {
                        "ID": "600",
                        "Description": "600",
                        "ShortDescription": "600",
                        "HierarchicalID": "600",
                        "Index": 13
                      },
                      "700": {
                        "ID": "700",
                        "Description": "700",
                        "ShortDescription": "700",
                        "HierarchicalID": "700",
                        "Index": 14
                      },
                      "800": {
                        "ID": "800",
                        "Description": "800",
                        "ShortDescription": "800",
                        "HierarchicalID": "800",
                        "Index": 15
                      },
                      "900": {
                        "ID": "900",
                        "Description": "900",
                        "ShortDescription": "900",
                        "HierarchicalID": "900",
                        "Index": 16
                      },
                      "1000": {
                        "ID": "1000",
                        "Description": "1,000",
                        "ShortDescription": "1k",
                        "HierarchicalID": "1000",
                        "Index": 17
                      },
                      "1250": {
                        "ID": "1250",
                        "Description": "1,250",
                        "ShortDescription": "1.25k",
                        "HierarchicalID": "1250",
                        "Index": 18
                      },
                      "1500": {
                        "ID": "1500",
                        "Description": "1,500",
                        "ShortDescription": "1.5k",
                        "HierarchicalID": "1500",
                        "Index": 19
                      },
                      "1750": {
                        "ID": "1750",
                        "Description": "1,750",
                        "ShortDescription": "1.75k",
                        "HierarchicalID": "1750",
                        "Index": 20
                      },
                      "2000": {
                        "ID": "2000",
                        "Description": "2,000",
                        "ShortDescription": "2k",
                        "HierarchicalID": "2000",
                        "Index": 21
                      },
                      "3000": {
                        "ID": "3000",
                        "Description": "3,000",
                        "ShortDescription": "3k",
                        "HierarchicalID": "3000",
                        "Index": 22
                      },
                      "5000": {
                        "ID": "5000",
                        "Description": "5,000",
                        "ShortDescription": "5k",
                        "HierarchicalID": "5000",
                        "Index": 23
                      },
                      "10000": {
                        "ID": "10000",
                        "Description": "10,000",
                        "ShortDescription": "10k",
                        "HierarchicalID": "10000",
                        "Index": 24
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No max",
                        "ShortDescription": "No max",
                        "HierarchicalID": "-1",
                        "Index": 25
                      }
                    },
                    "Index": 1
                  }
                },
                "Amenities": {
                  "GRS_A_REC": {
                    "ID": "REC",
                    "Description": "Reception",
                    "ShortDescription": "Reception",
                    "HierarchicalID": "GRS_A_REC",
                    "Index": 0
                  },
                  "GRS_A_CAN": {
                    "ID": "CAN",
                    "Description": "Canteen",
                    "ShortDescription": "Canteen",
                    "HierarchicalID": "GRS_A_CAN",
                    "Index": 1
                  },
                  "GRS_A_CONF": {
                    "ID": "CONF",
                    "Description": "Conference area",
                    "ShortDescription": "ConferenceArea",
                    "HierarchicalID": "GRS_A_CONF",
                    "Index": 2
                  },
                  "GRS_A_PAR": {
                    "ID": "PAR",
                    "Description": "Parking",
                    "ShortDescription": "Parking",
                    "HierarchicalID": "GRS_A_PAR",
                    "Index": 3
                  },
                  "GRS_A_AIR": {
                    "ID": "AIR",
                    "Description": "Air conditioning",
                    "ShortDescription": "Airconditioning",
                    "HierarchicalID": "GRS_A_AIR",
                    "Index": 4
                  },
                  "GRS_A_CAB": {
                    "ID": "CAB",
                    "Description": "Cable System",
                    "ShortDescription": "CableSystem",
                    "HierarchicalID": "GRS_A_CAB",
                    "Index": 5
                  },
                  "GRS_A_TER": {
                    "ID": "TER",
                    "Description": "Terrace",
                    "ShortDescription": "Terrace",
                    "HierarchicalID": "GRS_A_TER",
                    "Index": 6
                  },
                  "GRS_A_ACC": {
                    "ID": "ACC",
                    "Description": "Accessible",
                    "ShortDescription": "Accessible",
                    "HierarchicalID": "GRS_A_ACC",
                    "Index": 7
                  },
                  "GRS_A_RAF": {
                    "ID": "RAF",
                    "Description": "Raised access floors",
                    "ShortDescription": "RaisedAccessFloors",
                    "HierarchicalID": "GRS_A_RAF",
                    "Index": 8
                  },
                  "GRS_A_SUC": {
                    "ID": "SUC",
                    "Description": "Suspended ceiling",
                    "ShortDescription": "SuspendedCeiling",
                    "HierarchicalID": "GRS_A_SUC",
                    "Index": 9
                  },
                  "GRS_A_EME": {
                    "ID": "EME",
                    "Description": "Exposed M\u0026E",
                    "ShortDescription": "ExposedME",
                    "HierarchicalID": "GRS_A_EME",
                    "Index": 10
                  },
                  "GRS_A_BIP": {
                    "ID": "BIP",
                    "Description": "Bicycle parking",
                    "ShortDescription": "BicycleParking",
                    "HierarchicalID": "GRS_A_BIP",
                    "Index": 11
                  },
                  "GRS_A_DFR": {
                    "ID": "DFR",
                    "Description": "Disabled friendly",
                    "ShortDescription": "DisabledFriendly",
                    "HierarchicalID": "GRS_A_DFR",
                    "Index": 12
                  },
                  "GRS_A_LFT": {
                    "ID": "LFT",
                    "Description": "Lift",
                    "ShortDescription": "Lift",
                    "HierarchicalID": "GRS_A_LFT",
                    "Index": 13
                  },
                  "GRS_A_FIT": {
                    "ID": "FIT",
                    "Description": "Fitted",
                    "ShortDescription": "Fitted",
                    "HierarchicalID": "GRS_A_FIT",
                    "Index": 14
                  }
                },
                "OfficeGrades": {
                  "GRS_OG_3": {
                    "ID": "3",
                    "Description": "Premium",
                    "ShortDescription": "GradePremium",
                    "HierarchicalID": "GRS_OG_3",
                    "Index": 0
                  },
                  "GRS_OG_0": {
                    "ID": "0",
                    "Description": "Grade A",
                    "ShortDescription": "GradeA",
                    "HierarchicalID": "GRS_OG_0",
                    "Index": 1
                  },
                  "GRS_OG_1": {
                    "ID": "1",
                    "Description": "Grade B",
                    "ShortDescription": "GradeB",
                    "HierarchicalID": "GRS_OG_1",
                    "Index": 2
                  },
                  "GRS_OG_2": {
                    "ID": "2",
                    "Description": "Grade C",
                    "ShortDescription": "GradeC",
                    "HierarchicalID": "GRS_OG_2",
                    "Index": 3
                  }
                },
                "CommercialBedrooms": {
                  "MinCommercialBedrooms": {
                    "ID": "MinCommercialBedrooms",
                    "Description": "minimum",
                    "ShortDescription": "min",
                    "Options": {
                      "-1": {
                        "ID": "-1",
                        "Description": "No min",
                        "ShortDescription": "No min",
                        "HierarchicalID": "-1",
                        "Index": 0
                      },
                      "GRS_CB_5": {
                        "ID": "5",
                        "Description": "5",
                        "ShortDescription": "5",
                        "HierarchicalID": "GRS_CB_5",
                        "Index": 1
                      },
                      "GRS_CB_10": {
                        "ID": "10",
                        "Description": "10",
                        "ShortDescription": "10",
                        "HierarchicalID": "GRS_CB_10",
                        "Index": 2
                      },
                      "GRS_CB_15": {
                        "ID": "15",
                        "Description": "15",
                        "ShortDescription": "15",
                        "HierarchicalID": "GRS_CB_15",
                        "Index": 3
                      },
                      "GRS_CB_20": {
                        "ID": "20",
                        "Description": "20",
                        "ShortDescription": "20",
                        "HierarchicalID": "GRS_CB_20",
                        "Index": 4
                      },
                      "GRS_CB_25": {
                        "ID": "25",
                        "Description": "25",
                        "ShortDescription": "25",
                        "HierarchicalID": "GRS_CB_25",
                        "Index": 5
                      },
                      "GRS_CB_30": {
                        "ID": "30",
                        "Description": "30",
                        "ShortDescription": "30",
                        "HierarchicalID": "GRS_CB_30",
                        "Index": 6
                      },
                      "GRS_CB_35": {
                        "ID": "35",
                        "Description": "35",
                        "ShortDescription": "35",
                        "HierarchicalID": "GRS_CB_35",
                        "Index": 7
                      },
                      "GRS_CB_40": {
                        "ID": "40",
                        "Description": "40",
                        "ShortDescription": "40",
                        "HierarchicalID": "GRS_CB_40",
                        "Index": 8
                      },
                      "GRS_CB_45": {
                        "ID": "45",
                        "Description": "45",
                        "ShortDescription": "45",
                        "HierarchicalID": "GRS_CB_45",
                        "Index": 9
                      },
                      "GRS_CB_50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "GRS_CB_50",
                        "Index": 10
                      },
                      "GRS_CB_60": {
                        "ID": "60",
                        "Description": "60",
                        "ShortDescription": "60",
                        "HierarchicalID": "GRS_CB_60",
                        "Index": 11
                      },
                      "GRS_CB_70": {
                        "ID": "70",
                        "Description": "70",
                        "ShortDescription": "70",
                        "HierarchicalID": "GRS_CB_70",
                        "Index": 12
                      },
                      "GRS_CB_80": {
                        "ID": "80",
                        "Description": "80",
                        "ShortDescription": "80",
                        "HierarchicalID": "GRS_CB_80",
                        "Index": 13
                      },
                      "GRS_CB_90": {
                        "ID": "90",
                        "Description": "90",
                        "ShortDescription": "90",
                        "HierarchicalID": "GRS_CB_90",
                        "Index": 14
                      },
                      "GRS_CB_100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "GRS_CB_100",
                        "Index": 15
                      },
                      "GRS_CB_125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "GRS_CB_125",
                        "Index": 16
                      },
                      "GRS_CB_150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "GRS_CB_150",
                        "Index": 17
                      }
                    },
                    "Index": 0
                  },
                  "MaxCommercialBedrooms": {
                    "ID": "MaxCommercialBedrooms",
                    "Description": "maximum",
                    "ShortDescription": "max",
                    "Options": {
                      "GRS_CB_5": {
                        "ID": "5",
                        "Description": "5",
                        "ShortDescription": "5",
                        "HierarchicalID": "GRS_CB_5",
                        "Index": 0
                      },
                      "GRS_CB_10": {
                        "ID": "10",
                        "Description": "10",
                        "ShortDescription": "10",
                        "HierarchicalID": "GRS_CB_10",
                        "Index": 1
                      },
                      "GRS_CB_15": {
                        "ID": "15",
                        "Description": "15",
                        "ShortDescription": "15",
                        "HierarchicalID": "GRS_CB_15",
                        "Index": 2
                      },
                      "GRS_CB_20": {
                        "ID": "20",
                        "Description": "20",
                        "ShortDescription": "20",
                        "HierarchicalID": "GRS_CB_20",
                        "Index": 3
                      },
                      "GRS_CB_25": {
                        "ID": "25",
                        "Description": "25",
                        "ShortDescription": "25",
                        "HierarchicalID": "GRS_CB_25",
                        "Index": 4
                      },
                      "GRS_CB_30": {
                        "ID": "30",
                        "Description": "30",
                        "ShortDescription": "30",
                        "HierarchicalID": "GRS_CB_30",
                        "Index": 5
                      },
                      "GRS_CB_35": {
                        "ID": "35",
                        "Description": "35",
                        "ShortDescription": "35",
                        "HierarchicalID": "GRS_CB_35",
                        "Index": 6
                      },
                      "GRS_CB_40": {
                        "ID": "40",
                        "Description": "40",
                        "ShortDescription": "40",
                        "HierarchicalID": "GRS_CB_40",
                        "Index": 7
                      },
                      "GRS_CB_45": {
                        "ID": "45",
                        "Description": "45",
                        "ShortDescription": "45",
                        "HierarchicalID": "GRS_CB_45",
                        "Index": 8
                      },
                      "GRS_CB_50": {
                        "ID": "50",
                        "Description": "50",
                        "ShortDescription": "50",
                        "HierarchicalID": "GRS_CB_50",
                        "Index": 9
                      },
                      "GRS_CB_60": {
                        "ID": "60",
                        "Description": "60",
                        "ShortDescription": "60",
                        "HierarchicalID": "GRS_CB_60",
                        "Index": 10
                      },
                      "GRS_CB_70": {
                        "ID": "70",
                        "Description": "70",
                        "ShortDescription": "70",
                        "HierarchicalID": "GRS_CB_70",
                        "Index": 11
                      },
                      "GRS_CB_80": {
                        "ID": "80",
                        "Description": "80",
                        "ShortDescription": "80",
                        "HierarchicalID": "GRS_CB_80",
                        "Index": 12
                      },
                      "GRS_CB_90": {
                        "ID": "90",
                        "Description": "90",
                        "ShortDescription": "90",
                        "HierarchicalID": "GRS_CB_90",
                        "Index": 13
                      },
                      "GRS_CB_100": {
                        "ID": "100",
                        "Description": "100",
                        "ShortDescription": "100",
                        "HierarchicalID": "GRS_CB_100",
                        "Index": 14
                      },
                      "GRS_CB_125": {
                        "ID": "125",
                        "Description": "125",
                        "ShortDescription": "125",
                        "HierarchicalID": "GRS_CB_125",
                        "Index": 15
                      },
                      "GRS_CB_150": {
                        "ID": "150",
                        "Description": "150",
                        "ShortDescription": "150",
                        "HierarchicalID": "GRS_CB_150",
                        "Index": 16
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No max",
                        "ShortDescription": "No max",
                        "HierarchicalID": "-1",
                        "Index": 17
                      }
                    },
                    "Index": 1
                  }
                },
                "Yield": {
                  "MinYield": {
                    "ID": "MinYield",
                    "Description": "minimum",
                    "ShortDescription": "min",
                    "Options": {
                      "1": {
                        "ID": "1",
                        "Description": "1%",
                        "ShortDescription": "1%",
                        "HierarchicalID": "1",
                        "Index": 1
                      },
                      "2": {
                        "ID": "2",
                        "Description": "2%",
                        "ShortDescription": "2%",
                        "HierarchicalID": "2",
                        "Index": 2
                      },
                      "3": {
                        "ID": "3",
                        "Description": "3%",
                        "ShortDescription": "3%",
                        "HierarchicalID": "3",
                        "Index": 3
                      },
                      "4": {
                        "ID": "4",
                        "Description": "4%",
                        "ShortDescription": "4%",
                        "HierarchicalID": "4",
                        "Index": 4
                      },
                      "5": {
                        "ID": "5",
                        "Description": "5%",
                        "ShortDescription": "5%",
                        "HierarchicalID": "5",
                        "Index": 5
                      },
                      "6": {
                        "ID": "6",
                        "Description": "6%",
                        "ShortDescription": "6%",
                        "HierarchicalID": "6",
                        "Index": 6
                      },
                      "7": {
                        "ID": "7",
                        "Description": "7%",
                        "ShortDescription": "7%",
                        "HierarchicalID": "7",
                        "Index": 7
                      },
                      "8": {
                        "ID": "8",
                        "Description": "8%",
                        "ShortDescription": "8%",
                        "HierarchicalID": "8",
                        "Index": 8
                      },
                      "9": {
                        "ID": "9",
                        "Description": "9%",
                        "ShortDescription": "9%",
                        "HierarchicalID": "9",
                        "Index": 9
                      },
                      "10": {
                        "ID": "10",
                        "Description": "10%",
                        "ShortDescription": "10%",
                        "HierarchicalID": "10",
                        "Index": 10
                      },
                      "11": {
                        "ID": "11",
                        "Description": "11%",
                        "ShortDescription": "11%",
                        "HierarchicalID": "11",
                        "Index": 11
                      },
                      "12": {
                        "ID": "12",
                        "Description": "12%",
                        "ShortDescription": "12%",
                        "HierarchicalID": "12",
                        "Index": 12
                      },
                      "13": {
                        "ID": "13",
                        "Description": "13%",
                        "ShortDescription": "13%",
                        "HierarchicalID": "13",
                        "Index": 13
                      },
                      "14": {
                        "ID": "14",
                        "Description": "14%",
                        "ShortDescription": "14%",
                        "HierarchicalID": "14",
                        "Index": 14
                      },
                      "15": {
                        "ID": "15",
                        "Description": "15%",
                        "ShortDescription": "15%",
                        "HierarchicalID": "15",
                        "Index": 15
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No min",
                        "ShortDescription": "No min",
                        "HierarchicalID": "-1",
                        "Index": 0
                      }
                    },
                    "Index": 0
                  },
                  "MaxYield": {
                    "ID": "MaxYield",
                    "Description": "maximum",
                    "ShortDescription": "max",
                    "Options": {
                      "1": {
                        "ID": "1",
                        "Description": "1%",
                        "ShortDescription": "1%",
                        "HierarchicalID": "1",
                        "Index": 0
                      },
                      "2": {
                        "ID": "2",
                        "Description": "2%",
                        "ShortDescription": "2%",
                        "HierarchicalID": "2",
                        "Index": 1
                      },
                      "3": {
                        "ID": "3",
                        "Description": "3%",
                        "ShortDescription": "3%",
                        "HierarchicalID": "3",
                        "Index": 2
                      },
                      "4": {
                        "ID": "4",
                        "Description": "4%",
                        "ShortDescription": "4%",
                        "HierarchicalID": "4",
                        "Index": 3
                      },
                      "5": {
                        "ID": "5",
                        "Description": "5%",
                        "ShortDescription": "5%",
                        "HierarchicalID": "5",
                        "Index": 4
                      },
                      "6": {
                        "ID": "6",
                        "Description": "6%",
                        "ShortDescription": "6%",
                        "HierarchicalID": "6",
                        "Index": 5
                      },
                      "7": {
                        "ID": "7",
                        "Description": "7%",
                        "ShortDescription": "7%",
                        "HierarchicalID": "7",
                        "Index": 6
                      },
                      "8": {
                        "ID": "8",
                        "Description": "8%",
                        "ShortDescription": "8%",
                        "HierarchicalID": "8",
                        "Index": 7
                      },
                      "9": {
                        "ID": "9",
                        "Description": "9%",
                        "ShortDescription": "9%",
                        "HierarchicalID": "9",
                        "Index": 8
                      },
                      "10": {
                        "ID": "10",
                        "Description": "10%",
                        "ShortDescription": "10%",
                        "HierarchicalID": "10",
                        "Index": 9
                      },
                      "11": {
                        "ID": "11",
                        "Description": "11%",
                        "ShortDescription": "11%",
                        "HierarchicalID": "11",
                        "Index": 10
                      },
                      "12": {
                        "ID": "12",
                        "Description": "12%",
                        "ShortDescription": "12%",
                        "HierarchicalID": "12",
                        "Index": 11
                      },
                      "13": {
                        "ID": "13",
                        "Description": "13%",
                        "ShortDescription": "13%",
                        "HierarchicalID": "13",
                        "Index": 12
                      },
                      "14": {
                        "ID": "14",
                        "Description": "14%",
                        "ShortDescription": "14%",
                        "HierarchicalID": "14",
                        "Index": 13
                      },
                      "15": {
                        "ID": "15",
                        "Description": "15%",
                        "ShortDescription": "15%",
                        "HierarchicalID": "15",
                        "Index": 14
                      },
                      "-1": {
                        "ID": "-1",
                        "Description": "No max",
                        "ShortDescription": "No max",
                        "HierarchicalID": "-1",
                        "Index": 15
                      }
                    },
                    "Index": 1
                  }
                },
                "FarmsPropertyTypes": {
                  "GRS_FPT_F": {
                    "ID": "F",
                    "Description": "Farm",
                    "ShortDescription": "Farm",
                    "HierarchicalID": "GRS_FPT_F",
                    "Index": 0
                  },
                  "GRS_FPT_AF": {
                    "ID": "AF",
                    "Description": "Arable farm",
                    "ShortDescription": "ArableFarm",
                    "HierarchicalID": "GRS_FPT_AF",
                    "Index": 1
                  },
                  "GRS_FPT_DF": {
                    "ID": "DF",
                    "Description": "Dairy farm",
                    "ShortDescription": "DairyFarm",
                    "HierarchicalID": "GRS_FPT_DF",
                    "Index": 2
                  },
                  "GRS_FPT_LF": {
                    "ID": "LF",
                    "Description": "Livestock farm",
                    "ShortDescription": "LivestockFarm",
                    "HierarchicalID": "GRS_FPT_LF",
                    "Index": 3
                  },
                  "GRS_FPT_BL": {
                    "ID": "BL",
                    "Description": "Bare land",
                    "ShortDescription": "BareLand",
                    "HierarchicalID": "GRS_FPT_BL",
                    "Index": 4
                  },
                  "GRS_FPT_EQ": {
                    "ID": "EQ",
                    "Description": "Equestrian",
                    "ShortDescription": "Equestrian",
                    "HierarchicalID": "GRS_FPT_EQ",
                    "Index": 5
                  },
                  "GRS_FPT_RFE": {
                    "ID": "RFE",
                    "Description": "Residential farm/estate",
                    "ShortDescription": "ResidentialFarmEstate",
                    "HierarchicalID": "GRS_FPT_RFE",
                    "Index": 6
                  },
                  "GRS_FPT_S": {
                    "ID": "S",
                    "Description": "Sporting",
                    "ShortDescription": "Sporting",
                    "HierarchicalID": "GRS_FPT_S",
                    "Index": 7
                  },
                  "GRS_FPT_FSH": {
                    "ID": "FSH",
                    "Description": "Fishing",
                    "ShortDescription": "Fishing",
                    "HierarchicalID": "GRS_FPT_FSH",
                    "Index": 8
                  },
                  "GRS_FPT_W": {
                    "ID": "W",
                    "Description": "Woodland",
                    "ShortDescription": "Woodland",
                    "HierarchicalID": "GRS_FPT_W",
                    "Index": 9
                  },
                  "GRS_FPT_VY": {
                    "ID": "VY",
                    "Description": "Vineyard",
                    "ShortDescription": "Vineyard",
                    "HierarchicalID": "GRS_FPT_VY",
                    "Index": 10
                  }
                },
                "PriceInterval_Rent_Year": {
                  "AED": {
                    "ID": "AED",
                    "Description": "AED",
                    "Index": 0
                  },
                  "ARS": {
                    "ID": "ARS",
                    "Description": "ARS",
                    "Index": 1
                  },
                  "AUD": {
                    "ID": "AUD",
                    "Description": "AUD",
                    "Index": 2
                  },
                  "BGN": {
                    "ID": "BGN",
                    "Description": "BGN",
                    "Index": 3
                  },
                  "BHD": {
                    "ID": "BHD",
                    "Description": "BHD",
                    "Index": 4
                  },
                  "BND": {
                    "ID": "BND",
                    "Description": "BND",
                    "Index": 5
                  },
                  "BRL": {
                    "ID": "BRL",
                    "Description": "BRL",
                    "Index": 6
                  },
                  "BWP": {
                    "ID": "BWP",
                    "Description": "BWP",
                    "Index": 7
                  },
                  "CAD": {
                    "ID": "CAD",
                    "Description": "CAD",
                    "Index": 8
                  },
                  "CHF": {
                    "ID": "CHF",
                    "Description": "CHF",
                    "Index": 9
                  },
                  "CLP": {
                    "ID": "CLP",
                    "Description": "CLP",
                    "Index": 10
                  },
                  "CNY": {
                    "ID": "CNY",
                    "Description": "CNY",
                    "Index": 11
                  },
                  "COP": {
                    "ID": "COP",
                    "Description": "COP",
                    "Index": 12
                  },
                  "CZK": {
                    "ID": "CZK",
                    "Description": "CZK",
                    "Index": 13
                  },
                  "DKK": {
                    "ID": "DKK",
                    "Description": "DKK",
                    "Index": 14
                  },
                  "EGP": {
                    "ID": "EGP",
                    "Description": "EGP",
                    "Index": 15
                  },
                  "EUR": {
                    "ID": "EUR",
                    "Description": "EUR",
                    "Index": 16
                  },
                  "GBP": {
                    "ID": "GBP",
                    "Description": "GBP",
                    "Options": {
                      "MinPrice": {
                        "ID": "MinPrice",
                        "Description": "minimum",
                        "ShortDescription": "min",
                        "Options": {
                          "4800": {
                            "ID": "4800",
                            "Description": "£4,800",
                            "ShortDescription": "£4.8k",
                            "Index": 1
                          },
                          "6000": {
                            "ID": "6000",
                            "Description": "£6,000",
                            "ShortDescription": "£6k",
                            "Index": 2
                          },
                          "7200": {
                            "ID": "7200",
                            "Description": "£7,200",
                            "ShortDescription": "£7.2k",
                            "Index": 3
                          },
                          "8400": {
                            "ID": "8400",
                            "Description": "£8,400",
                            "ShortDescription": "£8.4k",
                            "Index": 4
                          },
                          "9600": {
                            "ID": "9600",
                            "Description": "£9,600",
                            "ShortDescription": "£9.6k",
                            "Index": 5
                          },
                          "10800": {
                            "ID": "10800",
                            "Description": "£10,800",
                            "ShortDescription": "£10.8k",
                            "Index": 6
                          },
                          "12000": {
                            "ID": "12000",
                            "Description": "£12,000",
                            "ShortDescription": "£12k",
                            "Index": 7
                          },
                          "13200": {
                            "ID": "13200",
                            "Description": "£13,200",
                            "ShortDescription": "£13.2k",
                            "Index": 8
                          },
                          "14400": {
                            "ID": "14400",
                            "Description": "£14,400",
                            "ShortDescription": "£14.4k",
                            "Index": 9
                          },
                          "15600": {
                            "ID": "15600",
                            "Description": "£15,600",
                            "ShortDescription": "£15.6k",
                            "Index": 10
                          },
                          "16800": {
                            "ID": "16800",
                            "Description": "£16,800",
                            "ShortDescription": "£16.8k",
                            "Index": 11
                          },
                          "18000": {
                            "ID": "18000",
                            "Description": "£18,000",
                            "ShortDescription": "£18k",
                            "Index": 12
                          },
                          "19200": {
                            "ID": "19200",
                            "Description": "£19,200",
                            "ShortDescription": "£19.2k",
                            "Index": 13
                          },
                          "20400": {
                            "ID": "20400",
                            "Description": "£20,400",
                            "ShortDescription": "£20.4k",
                            "Index": 14
                          },
                          "21600": {
                            "ID": "21600",
                            "Description": "£21,600",
                            "ShortDescription": "£21.6k",
                            "Index": 15
                          },
                          "22800": {
                            "ID": "22800",
                            "Description": "£22,800",
                            "ShortDescription": "£22.8k",
                            "Index": 16
                          },
                          "24000": {
                            "ID": "24000",
                            "Description": "£24,000",
                            "ShortDescription": "£24k",
                            "Index": 17
                          },
                          "27000": {
                            "ID": "27000",
                            "Description": "£27,000",
                            "ShortDescription": "£27k",
                            "Index": 18
                          },
                          "30000": {
                            "ID": "30000",
                            "Description": "£30,000",
                            "ShortDescription": "£30k",
                            "Index": 19
                          },
                          "33000": {
                            "ID": "33000",
                            "Description": "£33,000",
                            "ShortDescription": "£33k",
                            "Index": 20
                          },
                          "36000": {
                            "ID": "36000",
                            "Description": "£36,000",
                            "ShortDescription": "£36k",
                            "Index": 21
                          },
                          "42000": {
                            "ID": "42000",
                            "Description": "£42,000",
                            "ShortDescription": "£42k",
                            "Index": 22
                          },
                          "48000": {
                            "ID": "48000",
                            "Description": "£48,000",
                            "ShortDescription": "£48k",
                            "Index": 23
                          },
                          "54000": {
                            "ID": "54000",
                            "Description": "£54,000",
                            "ShortDescription": "£54k",
                            "Index": 24
                          },
                          "60000": {
                            "ID": "60000",
                            "Description": "£60,000",
                            "ShortDescription": "£60k",
                            "Index": 25
                          },
                          "72000": {
                            "ID": "72000",
                            "Description": "£72,000",
                            "ShortDescription": "£72k",
                            "Index": 26
                          },
                          "84000": {
                            "ID": "84000",
                            "Description": "£84,000",
                            "ShortDescription": "£84k",
                            "Index": 27
                          },
                          "96000": {
                            "ID": "96000",
                            "Description": "£96,000",
                            "ShortDescription": "£96k",
                            "Index": 28
                          },
                          "108000": {
                            "ID": "108000",
                            "Description": "£108,000",
                            "ShortDescription": "£108k",
                            "Index": 29
                          },
                          "120000": {
                            "ID": "120000",
                            "Description": "£120,000",
                            "ShortDescription": "£120k",
                            "Index": 30
                          },
                          "150000": {
                            "ID": "150000",
                            "Description": "£150,000",
                            "ShortDescription": "£150k",
                            "Index": 31
                          },
                          "180000": {
                            "ID": "180000",
                            "Description": "£180,000",
                            "ShortDescription": "£180k",
                            "Index": 32
                          },
                          "210000": {
                            "ID": "210000",
                            "Description": "£210,000",
                            "ShortDescription": "£210k",
                            "Index": 33
                          },
                          "240000": {
                            "ID": "240000",
                            "Description": "£240,000",
                            "ShortDescription": "£240k",
                            "Index": 34
                          },
                          "300000": {
                            "ID": "300000",
                            "Description": "£300,000",
                            "ShortDescription": "£300k",
                            "Index": 35
                          },
                          "360000": {
                            "ID": "360000",
                            "Description": "£360,000",
                            "ShortDescription": "£360k",
                            "Index": 36
                          },
                          "420000": {
                            "ID": "420000",
                            "Description": "£420,000",
                            "ShortDescription": "£420k",
                            "Index": 37
                          },
                          "480000": {
                            "ID": "480000",
                            "Description": "£480,000",
                            "ShortDescription": "£480k",
                            "Index": 38
                          },
                          "-1": {
                            "ID": "-1",
                            "Description": "No min",
                            "ShortDescription": "No min",
                            "HierarchicalID": "-1",
                            "Index": 0
                          }
                        },
                        "Index": 0
                      },
                      "MaxPrice": {
                        "ID": "MaxPrice",
                        "Description": "maximum",
                        "ShortDescription": "max",
                        "Options": {
                          "4800": {
                            "ID": "4800",
                            "Description": "£4,800",
                            "ShortDescription": "£4.8k",
                            "Index": 0
                          },
                          "6000": {
                            "ID": "6000",
                            "Description": "£6,000",
                            "ShortDescription": "£6k",
                            "Index": 1
                          },
                          "7200": {
                            "ID": "7200",
                            "Description": "£7,200",
                            "ShortDescription": "£7.2k",
                            "Index": 2
                          },
                          "8400": {
                            "ID": "8400",
                            "Description": "£8,400",
                            "ShortDescription": "£8.4k",
                            "Index": 3
                          },
                          "9600": {
                            "ID": "9600",
                            "Description": "£9,600",
                            "ShortDescription": "£9.6k",
                            "Index": 4
                          },
                          "10800": {
                            "ID": "10800",
                            "Description": "£10,800",
                            "ShortDescription": "£10.8k",
                            "Index": 5
                          },
                          "12000": {
                            "ID": "12000",
                            "Description": "£12,000",
                            "ShortDescription": "£12k",
                            "Index": 6
                          },
                          "13200": {
                            "ID": "13200",
                            "Description": "£13,200",
                            "ShortDescription": "£13.2k",
                            "Index": 7
                          },
                          "14400": {
                            "ID": "14400",
                            "Description": "£14,400",
                            "ShortDescription": "£14.4k",
                            "Index": 8
                          },
                          "15600": {
                            "ID": "15600",
                            "Description": "£15,600",
                            "ShortDescription": "£15.6k",
                            "Index": 9
                          },
                          "16800": {
                            "ID": "16800",
                            "Description": "£16,800",
                            "ShortDescription": "£16.8k",
                            "Index": 10
                          },
                          "18000": {
                            "ID": "18000",
                            "Description": "£18,000",
                            "ShortDescription": "£18k",
                            "Index": 11
                          },
                          "19200": {
                            "ID": "19200",
                            "Description": "£19,200",
                            "ShortDescription": "£19.2k",
                            "Index": 12
                          },
                          "20400": {
                            "ID": "20400",
                            "Description": "£20,400",
                            "ShortDescription": "£20.4k",
                            "Index": 13
                          },
                          "21600": {
                            "ID": "21600",
                            "Description": "£21,600",
                            "ShortDescription": "£21.6k",
                            "Index": 14
                          },
                          "22800": {
                            "ID": "22800",
                            "Description": "£22,800",
                            "ShortDescription": "£22.8k",
                            "Index": 15
                          },
                          "24000": {
                            "ID": "24000",
                            "Description": "£24,000",
                            "ShortDescription": "£24k",
                            "Index": 16
                          },
                          "27000": {
                            "ID": "27000",
                            "Description": "£27,000",
                            "ShortDescription": "£27k",
                            "Index": 17
                          },
                          "30000": {
                            "ID": "30000",
                            "Description": "£30,000",
                            "ShortDescription": "£30k",
                            "Index": 18
                          },
                          "33000": {
                            "ID": "33000",
                            "Description": "£33,000",
                            "ShortDescription": "£33k",
                            "Index": 19
                          },
                          "36000": {
                            "ID": "36000",
                            "Description": "£36,000",
                            "ShortDescription": "£36k",
                            "Index": 20
                          },
                          "42000": {
                            "ID": "42000",
                            "Description": "£42,000",
                            "ShortDescription": "£42k",
                            "Index": 21
                          },
                          "48000": {
                            "ID": "48000",
                            "Description": "£48,000",
                            "ShortDescription": "£48k",
                            "Index": 22
                          },
                          "54000": {
                            "ID": "54000",
                            "Description": "£54,000",
                            "ShortDescription": "£54k",
                            "Index": 23
                          },
                          "60000": {
                            "ID": "60000",
                            "Description": "£60,000",
                            "ShortDescription": "£60k",
                            "Index": 24
                          },
                          "72000": {
                            "ID": "72000",
                            "Description": "£72,000",
                            "ShortDescription": "£72k",
                            "Index": 25
                          },
                          "84000": {
                            "ID": "84000",
                            "Description": "£84,000",
                            "ShortDescription": "£84k",
                            "Index": 26
                          },
                          "96000": {
                            "ID": "96000",
                            "Description": "£96,000",
                            "ShortDescription": "£96k",
                            "Index": 27
                          },
                          "108000": {
                            "ID": "108000",
                            "Description": "£108,000",
                            "ShortDescription": "£108k",
                            "Index": 28
                          },
                          "120000": {
                            "ID": "120000",
                            "Description": "£120,000",
                            "ShortDescription": "£120k",
                            "Index": 29
                          },
                          "150000": {
                            "ID": "150000",
                            "Description": "£150,000",
                            "ShortDescription": "£150k",
                            "Index": 30
                          },
                          "180000": {
                            "ID": "180000",
                            "Description": "£180,000",
                            "ShortDescription": "£180k",
                            "Index": 31
                          },
                          "210000": {
                            "ID": "210000",
                            "Description": "£210,000",
                            "ShortDescription": "£210k",
                            "Index": 32
                          },
                          "240000": {
                            "ID": "240000",
                            "Description": "£240,000",
                            "ShortDescription": "£240k",
                            "Index": 33
                          },
                          "300000": {
                            "ID": "300000",
                            "Description": "£300,000",
                            "ShortDescription": "£300k",
                            "Index": 34
                          },
                          "360000": {
                            "ID": "360000",
                            "Description": "£360,000",
                            "ShortDescription": "£360k",
                            "Index": 35
                          },
                          "420000": {
                            "ID": "420000",
                            "Description": "£420,000",
                            "ShortDescription": "£420k",
                            "Index": 36
                          },
                          "480000": {
                            "ID": "480000",
                            "Description": "£480,000",
                            "ShortDescription": "£480k",
                            "Index": 37
                          },
                          "-1": {
                            "ID": "-1",
                            "Description": "No max",
                            "ShortDescription": "No max",
                            "HierarchicalID": "-1",
                            "Index": 38
                          }
                        },
                        "Index": 1
                      }
                    },
                    "Index": 17
                  },
                  "HKD": {
                    "ID": "HKD",
                    "Description": "HKD",
                    "Index": 18
                  },
                  "HRK": {
                    "ID": "HRK",
                    "Description": "HRK",
                    "Index": 19
                  },
                  "HUF": {
                    "ID": "HUF",
                    "Description": "HUF",
                    "Index": 20
                  },
                  "IDR": {
                    "ID": "IDR",
                    "Description": "IDR",
                    "Index": 21
                  },
                  "ILS": {
                    "ID": "ILS",
                    "Description": "ILS",
                    "Index": 22
                  },
                  "INR": {
                    "ID": "INR",
                    "Description": "INR",
                    "Index": 23
                  },
                  "IRR": {
                    "ID": "IRR",
                    "Description": "IRR",
                    "Index": 24
                  },
                  "ISK": {
                    "ID": "ISK",
                    "Description": "ISK",
                    "Index": 25
                  },
                  "JPY": {
                    "ID": "JPY",
                    "Description": "JPY",
                    "Index": 26
                  },
                  "KRW": {
                    "ID": "KRW",
                    "Description": "KRW",
                    "Index": 27
                  },
                  "KWD": {
                    "ID": "KWD",
                    "Description": "KWD",
                    "Index": 28
                  },
                  "KZT": {
                    "ID": "KZT",
                    "Description": "KZT",
                    "Index": 29
                  },
                  "LBP": {
                    "ID": "LBP",
                    "Description": "LBP",
                    "Index": 30
                  },
                  "LKR": {
                    "ID": "LKR",
                    "Description": "LKR",
                    "Index": 31
                  },
                  "LTL": {
                    "ID": "LTL",
                    "Description": "LTL",
                    "Index": 32
                  },
                  "LVL": {
                    "ID": "LVL",
                    "Description": "LVL",
                    "Index": 33
                  },
                  "LYD": {
                    "ID": "LYD",
                    "Description": "LYD",
                    "Index": 34
                  },
                  "MAD": {
                    "ID": "MAD",
                    "Description": "MAD",
                    "Index": 35
                  },
                  "MUR": {
                    "ID": "MUR",
                    "Description": "MUR",
                    "Index": 36
                  },
                  "MXN": {
                    "ID": "MXN",
                    "Description": "MXN",
                    "Index": 37
                  },
                  "MYR": {
                    "ID": "MYR",
                    "Description": "MYR",
                    "Index": 38
                  },
                  "NGN": {
                    "ID": "NGN",
                    "Description": "NGN",
                    "Index": 39
                  },
                  "NOK": {
                    "ID": "NOK",
                    "Description": "NOK",
                    "Index": 40
                  },
                  "NPR": {
                    "ID": "NPR",
                    "Description": "NPR",
                    "Index": 41
                  },
                  "NZD": {
                    "ID": "NZD",
                    "Description": "NZD",
                    "Index": 42
                  },
                  "OMR": {
                    "ID": "OMR",
                    "Description": "OMR",
                    "Index": 43
                  },
                  "PHP": {
                    "ID": "PHP",
                    "Description": "PHP",
                    "Index": 44
                  },
                  "PKR": {
                    "ID": "PKR",
                    "Description": "PKR",
                    "Index": 45
                  },
                  "PLN": {
                    "ID": "PLN",
                    "Description": "PLN",
                    "Index": 46
                  },
                  "QAR": {
                    "ID": "QAR",
                    "Description": "QAR",
                    "Index": 47
                  },
                  "RMB": {
                    "ID": "RMB",
                    "Description": "RMB",
                    "Index": 48
                  },
                  "RON": {
                    "ID": "RON",
                    "Description": "RON",
                    "Index": 49
                  },
                  "RSD": {
                    "ID": "RSD",
                    "Description": "RSD",
                    "Index": 50
                  },
                  "RUB": {
                    "ID": "RUB",
                    "Description": "RUB",
                    "Index": 51
                  },
                  "SAR": {
                    "ID": "SAR",
                    "Description": "SAR",
                    "Index": 52
                  },
                  "SEK": {
                    "ID": "SEK",
                    "Description": "SEK",
                    "Index": 53
                  },
                  "SGD": {
                    "ID": "SGD",
                    "Description": "SGD",
                    "Index": 54
                  },
                  "THB": {
                    "ID": "THB",
                    "Description": "THB",
                    "Index": 55
                  },
                  "TRY": {
                    "ID": "TRY",
                    "Description": "TRY",
                    "Index": 56
                  },
                  "TWD": {
                    "ID": "TWD",
                    "Description": "TWD",
                    "Index": 57
                  },
                  "USD": {
                    "ID": "USD",
                    "Description": "USD",
                    "Index": 58
                  },
                  "VND": {
                    "ID": "VND",
                    "Description": "VND",
                    "Index": 59
                  },
                  "ZAR": {
                    "ID": "ZAR",
                    "Description": "ZAR",
                    "Index": 60
                  }
                }
              },
              "group": {
                "GRS_CAT_RES": {
                  "ID": "RES",
                  "Description": "Residential \u0026 New Developments",
                  "ShortDescription": "Residential",
                  "HierarchicalID": "GRS_CAT_RES",
                  "Options": {
                    "Tenure": {
                      "ID": "Tenure",
                      "Description": "Tenure",
                      "Options": {
                        "GRS_T_B": {
                          "ID": "B",
                          "Description": "Buy",
                          "ShortDescription": "Buy",
                          "HierarchicalID": "GRS_T_B",
                          "Options": {
                            "Currency": {
                              "ID": "Currency",
                              "Description": "Price range",
                              "OptionsReference": "PriceInterval_Buy",
                              "Index": 0
                            },
                            "PropertyTypes": {
                              "ID": "PropertyTypes",
                              "Description": "Property types",
                              "OptionsReference": "PropertyTypes",
                              "Index": 1
                            },
                            "Bedrooms": {
                              "ID": "Bedrooms",
                              "Description": "Bedrooms",
                              "OptionsReference": "Bedrooms",
                              "Index": 2
                            },
                            "Receptions": {
                              "ID": "Receptions",
                              "Description": "Receptions",
                              "OptionsReference": "Receptions",
                              "Index": 3
                            },
                            "Bathrooms": {
                              "ID": "Bathrooms",
                              "Description": "Bathrooms",
                              "OptionsReference": "Bathrooms",
                              "Index": 4
                            },
                            "CarSpaces": {
                              "ID": "CarSpaces",
                              "Description": "Parking Spaces",
                              "OptionsReference": "CarSpaces",
                              "Index": 5
                            },
                            "Lifestyles": {
                              "ID": "Lifestyles",
                              "Description": "Lifestyle",
                              "OptionsReference": "Lifestyles",
                              "Index": 6
                            },
                            "Features": {
                              "ID": "Features",
                              "Description": "Features",
                              "OptionsReference": "Features_Buy",
                              "Index": 7
                            },
                            "ResidentialSize": {
                              "ID": "ResidentialSize",
                              "Description": "Property size",
                              "Options": {
                                "UnitOfMeasure": {
                                  "ID": "UnitOfMeasure",
                                  "Options": {
                                    "SquareFeet": {
                                      "ID": "SquareFeet",
                                      "Description": "sq. feet",
                                      "ShortDescription": "sq ft",
                                      "OptionsReference": "ResidentialSize_SquareFeet",
                                      "Index": 0
                                    },
                                    "SquareMeter": {
                                      "ID": "SquareMeter",
                                      "Description": "sq. metres",
                                      "ShortDescription": "sq m",
                                      "OptionsReference": "ResidentialSize_SquareMeter",
                                      "Index": 1
                                    }
                                  },
                                  "Index": 0
                                }
                              },
                              "Index": 8
                            },
                            "LandArea": {
                              "ID": "LandArea",
                              "Description": "Land size",
                              "Options": {
                                "UnitOfMeasure": {
                                  "ID": "UnitOfMeasure",
                                  "Options": {
                                    "Acre": {
                                      "ID": "Acre",
                                      "Description": "Acres",
                                      "ShortDescription": "Ac",
                                      "OptionsReference": "LandArea_Acre",
                                      "Index": 0
                                    },
                                    "Hectare": {
                                      "ID": "Hectare",
                                      "Description": "Hectares",
                                      "ShortDescription": "Ha",
                                      "OptionsReference": "LandArea_Hectare",
                                      "Index": 1
                                    }
                                  },
                                  "Index": 0
                                }
                              },
                              "Index": 9
                            },
                            "SaleableArea": {
                              "ID": "SaleableArea",
                              "Description": "Saleable area",
                              "Options": {
                                "UnitOfMeasure": {
                                  "ID": "UnitOfMeasure",
                                  "Options": {
                                    "SquareFeet": {
                                      "ID": "SquareFeet",
                                      "Description": "sq. feet",
                                      "ShortDescription": "sq ft",
                                      "OptionsReference": "SaleableArea_SquareFeet",
                                      "Index": 0
                                    },
                                    "SquareMeter": {
                                      "ID": "SquareMeter",
                                      "Description": "sq. metres",
                                      "ShortDescription": "sq m",
                                      "OptionsReference": "SaleableArea_SquareMeter",
                                      "Index": 1
                                    },
                                    "Acre": {
                                      "ID": "Acre",
                                      "Description": "Acres",
                                      "ShortDescription": "Ac",
                                      "OptionsReference": "SaleableArea_Acre",
                                      "Index": 2
                                    },
                                    "Hectare": {
                                      "ID": "Hectare",
                                      "Description": "Hectares",
                                      "ShortDescription": "Ha",
                                      "OptionsReference": "SaleableArea_Hectare",
                                      "Index": 3
                                    }
                                  },
                                  "Index": 0
                                }
                              },
                              "Index": 10
                            }
                          },
                          "Index": 0
                        },
                        "GRS_T_R": {
                          "ID": "R",
                          "Description": "Rent",
                          "ShortDescription": "Rent",
                          "HierarchicalID": "GRS_T_R",
                          "Options": {
                            "Price": {
                              "ID": "Price",
                              "Description": "Price range",
                              "Options": {
                                "Period": {
                                  "ID": "Period",
                                  "Options": {
                                    "Week": {
                                      "ID": "Week",
                                      "Description": "Per week",
                                      "ShortDescription": "Per week",
                                      "OptionsReference": "PriceInterval_Rent_Week",
                                      "Index": 0
                                    },
                                    "Month": {
                                      "ID": "Month",
                                      "Description": "Per month",
                                      "ShortDescription": "Per month",
                                      "OptionsReference": "PriceInterval_Rent_Month",
                                      "Index": 1
                                    }
                                  },
                                  "Index": 0
                                }
                              },
                              "Index": 0
                            },
                            "PropertyTypes": {
                              "ID": "PropertyTypes",
                              "Description": "Property types",
                              "OptionsReference": "PropertyTypes",
                              "Index": 1
                            },
                            "Bedrooms": {
                              "ID": "Bedrooms",
                              "Description": "Bedrooms",
                              "OptionsReference": "Bedrooms",
                              "Index": 2
                            },
                            "Receptions": {
                              "ID": "Receptions",
                              "Description": "Receptions",
                              "OptionsReference": "Receptions",
                              "Index": 3
                            },
                            "Bathrooms": {
                              "ID": "Bathrooms",
                              "Description": "Bathrooms",
                              "OptionsReference": "Bathrooms",
                              "Index": 4
                            },
                            "CarSpaces": {
                              "ID": "CarSpaces",
                              "Description": "Parking Spaces",
                              "OptionsReference": "CarSpaces",
                              "Index": 5
                            },
                            "Lifestyles": {
                              "ID": "Lifestyles",
                              "Description": "Lifestyle",
                              "OptionsReference": "Lifestyles",
                              "Index": 6
                            },
                            "Features": {
                              "ID": "Features",
                              "Description": "Features",
                              "OptionsReference": "Features_Rent",
                              "Index": 7
                            },
                            "ResidentialSize": {
                              "ID": "ResidentialSize",
                              "Description": "Property size",
                              "Options": {
                                "UnitOfMeasure": {
                                  "ID": "UnitOfMeasure",
                                  "Options": {
                                    "SquareFeet": {
                                      "ID": "SquareFeet",
                                      "Description": "sq. feet",
                                      "ShortDescription": "sq ft",
                                      "OptionsReference": "ResidentialSize_SquareFeet",
                                      "Index": 0
                                    },
                                    "SquareMeter": {
                                      "ID": "SquareMeter",
                                      "Description": "sq. metres",
                                      "ShortDescription": "sq m",
                                      "OptionsReference": "ResidentialSize_SquareMeter",
                                      "Index": 1
                                    }
                                  },
                                  "Index": 0
                                }
                              },
                              "Index": 8
                            },
                            "LandArea": {
                              "ID": "LandArea",
                              "Description": "Land size",
                              "Options": {
                                "UnitOfMeasure": {
                                  "ID": "UnitOfMeasure",
                                  "Options": {
                                    "Acre": {
                                      "ID": "Acre",
                                      "Description": "Acres",
                                      "ShortDescription": "Ac",
                                      "OptionsReference": "LandArea_Acre",
                                      "Index": 0
                                    },
                                    "Hectare": {
                                      "ID": "Hectare",
                                      "Description": "Hectares",
                                      "ShortDescription": "Ha",
                                      "OptionsReference": "LandArea_Hectare",
                                      "Index": 1
                                    }
                                  },
                                  "Index": 0
                                }
                              },
                              "Index": 9
                            },
                            "SaleableArea": {
                              "ID": "SaleableArea",
                              "Description": "Saleable area",
                              "Options": {
                                "UnitOfMeasure": {
                                  "ID": "UnitOfMeasure",
                                  "Options": {
                                    "SquareFeet": {
                                      "ID": "SquareFeet",
                                      "Description": "sq. feet",
                                      "ShortDescription": "sq ft",
                                      "OptionsReference": "SaleableArea_SquareFeet",
                                      "Index": 0
                                    },
                                    "SquareMeter": {
                                      "ID": "SquareMeter",
                                      "Description": "sq. metres",
                                      "ShortDescription": "sq m",
                                      "OptionsReference": "SaleableArea_SquareMeter",
                                      "Index": 1
                                    },
                                    "Acre": {
                                      "ID": "Acre",
                                      "Description": "Acres",
                                      "ShortDescription": "Ac",
                                      "OptionsReference": "SaleableArea_Acre",
                                      "Index": 2
                                    },
                                    "Hectare": {
                                      "ID": "Hectare",
                                      "Description": "Hectares",
                                      "ShortDescription": "Ha",
                                      "OptionsReference": "SaleableArea_Hectare",
                                      "Index": 3
                                    }
                                  },
                                  "Index": 0
                                }
                              },
                              "Index": 10
                            }
                          },
                          "Index": 1
                        }
                      },
                      "Index": 0
                    }
                  },
                  "Index": 0
                },
                "GRS_CAT_COM": {
                  "ID": "COM",
                  "Description": "Commercial \u0026 development land",
                  "ShortDescription": "Commercial",
                  "HierarchicalID": "GRS_CAT_COM",
                  "Options": {
                    "CommercialPropertyType": {
                      "ID": "CommercialPropertyType",
                      "Description": "Commercial property types",
                      "Options": {
                        "GRS_CPT_D": {
                          "ID": "D",
                          "Description": "Development land",
                          "ShortDescription": "Development",
                          "HierarchicalID": "GRS_CPT_D",
                          "Options": {
                            "Tenure": {
                              "ID": "Tenure",
                              "Description": "Tenure",
                              "Options": {
                                "GRS_T_B": {
                                  "ID": "B",
                                  "Description": "Buy",
                                  "ShortDescription": "Buy",
                                  "HierarchicalID": "GRS_T_B",
                                  "Options": {
                                    "PropertyUses": {
                                      "ID": "PropertyUses",
                                      "Description": "Property use",
                                      "OptionsReference": "PropertyUses",
                                      "Index": 0
                                    },
                                    "Currency": {
                                      "ID": "Currency",
                                      "Description": "Price range",
                                      "OptionsReference": "PriceInterval_Buy",
                                      "Index": 1
                                    },
                                    "CommercialSize": {
                                      "ID": "CommercialSize",
                                      "Description": "Property size",
                                      "Options": {
                                        "UnitOfMeasure": {
                                          "ID": "UnitOfMeasure",
                                          "Options": {
                                            "Acre": {
                                              "ID": "Acre",
                                              "Description": "Acres",
                                              "ShortDescription": "Ac",
                                              "OptionsReference": "CommercialSize_Acre",
                                              "Index": 0
                                            },
                                            "SquareFeet": {
                                              "ID": "SquareFeet",
                                              "Description": "sq. feet",
                                              "ShortDescription": "sq ft",
                                              "OptionsReference": "CommercialSize_SquareFeet",
                                              "Index": 1
                                            },
                                            "SquareMeter": {
                                              "ID": "SquareMeter",
                                              "Description": "sq. metres",
                                              "ShortDescription": "sq m",
                                              "OptionsReference": "CommercialSize_SquareMeter",
                                              "Index": 2
                                            },
                                            "Hectare": {
                                              "ID": "Hectare",
                                              "Description": "Hectares",
                                              "ShortDescription": "Ha",
                                              "OptionsReference": "CommercialSize_Hectare",
                                              "Index": 3
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 2
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 3
                                    }
                                  },
                                  "Index": 0
                                },
                                "GRS_T_R": {
                                  "ID": "R",
                                  "Description": "Rent",
                                  "ShortDescription": "Rent",
                                  "HierarchicalID": "GRS_T_R",
                                  "Options": {
                                    "PropertyUses": {
                                      "ID": "PropertyUses",
                                      "Description": "Property use",
                                      "OptionsReference": "PropertyUses",
                                      "Index": 0
                                    },
                                    "Price": {
                                      "ID": "Price",
                                      "Description": "Price range",
                                      "Options": {
                                        "Period": {
                                          "ID": "Period",
                                          "Options": {
                                            "Month": {
                                              "ID": "Month",
                                              "Description": "Per month",
                                              "ShortDescription": "Per month",
                                              "OptionsReference": "PriceInterval_Comm_Rent_Month",
                                              "Index": 0
                                            },
                                            "Year": {
                                              "ID": "Year",
                                              "Description": "Per year",
                                              "ShortDescription": "Per year",
                                              "OptionsReference": "PriceInterval_Comm_Rent_Year",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 1
                                    },
                                    "CommercialSize": {
                                      "ID": "CommercialSize",
                                      "Description": "Property size",
                                      "Options": {
                                        "UnitOfMeasure": {
                                          "ID": "UnitOfMeasure",
                                          "Options": {
                                            "Acre": {
                                              "ID": "Acre",
                                              "Description": "Acres",
                                              "ShortDescription": "Ac",
                                              "OptionsReference": "CommercialSize_Acre",
                                              "Index": 0
                                            },
                                            "Hectare": {
                                              "ID": "Hectare",
                                              "Description": "Hectares",
                                              "ShortDescription": "Ha",
                                              "OptionsReference": "CommercialSize_Hectare",
                                              "Index": 1
                                            },
                                            "SquareFeet": {
                                              "ID": "SquareFeet",
                                              "Description": "sq. feet",
                                              "ShortDescription": "sq ft",
                                              "OptionsReference": "CommercialSize_SquareFeet",
                                              "Index": 2
                                            },
                                            "SquareMeter": {
                                              "ID": "SquareMeter",
                                              "Description": "sq. metres",
                                              "ShortDescription": "sq m",
                                              "OptionsReference": "CommercialSize_SquareMeter",
                                              "Index": 3
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 2
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 3
                                    }
                                  },
                                  "Index": 1
                                }
                              },
                              "Index": 0
                            }
                          },
                          "Index": 0
                        },
                        "GRS_CPT_I": {
                          "ID": "I",
                          "Description": "Industrial",
                          "ShortDescription": "Industrial",
                          "HierarchicalID": "GRS_CPT_I",
                          "Options": {
                            "Tenure": {
                              "ID": "Tenure",
                              "Description": "Tenure",
                              "Options": {
                                "GRS_T_B": {
                                  "ID": "B",
                                  "Description": "Buy",
                                  "ShortDescription": "Buy",
                                  "HierarchicalID": "GRS_T_B",
                                  "Options": {
                                    "PropertyUses": {
                                      "ID": "PropertyUses",
                                      "Description": "Property use",
                                      "OptionsReference": "PropertyUses",
                                      "Index": 0
                                    },
                                    "CommercialSize": {
                                      "ID": "CommercialSize",
                                      "Description": "Property size",
                                      "Options": {
                                        "UnitOfMeasure": {
                                          "ID": "UnitOfMeasure",
                                          "Options": {
                                            "SquareFeet": {
                                              "ID": "SquareFeet",
                                              "Description": "sq. feet",
                                              "ShortDescription": "sq ft",
                                              "OptionsReference": "CommercialSize_SquareFeet",
                                              "Index": 0
                                            },
                                            "SquareMeter": {
                                              "ID": "SquareMeter",
                                              "Description": "sq. metres",
                                              "ShortDescription": "sq m",
                                              "OptionsReference": "CommercialSize_SquareMeter",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 1
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 2
                                    }
                                  },
                                  "Index": 0
                                },
                                "GRS_T_R": {
                                  "ID": "R",
                                  "Description": "Rent",
                                  "ShortDescription": "Rent",
                                  "HierarchicalID": "GRS_T_R",
                                  "Options": {
                                    "PropertyUses": {
                                      "ID": "PropertyUses",
                                      "Description": "Property use",
                                      "OptionsReference": "PropertyUses",
                                      "Index": 0
                                    },
                                    "CommercialSize": {
                                      "ID": "CommercialSize",
                                      "Description": "Property size",
                                      "Options": {
                                        "UnitOfMeasure": {
                                          "ID": "UnitOfMeasure",
                                          "Options": {
                                            "SquareFeet": {
                                              "ID": "SquareFeet",
                                              "Description": "sq. feet",
                                              "ShortDescription": "sq ft",
                                              "OptionsReference": "CommercialSize_SquareFeet",
                                              "Index": 0
                                            },
                                            "SquareMeter": {
                                              "ID": "SquareMeter",
                                              "Description": "sq. metres",
                                              "ShortDescription": "sq m",
                                              "OptionsReference": "CommercialSize_SquareMeter",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 1
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 2
                                    }
                                  },
                                  "Index": 1
                                }
                              },
                              "Index": 0
                            }
                          },
                          "Index": 1
                        },
                        "GRS_CPT_L": {
                          "ID": "L",
                          "Description": "Leisure",
                          "ShortDescription": "Leisure",
                          "HierarchicalID": "GRS_CPT_L",
                          "Options": {
                            "Tenure": {
                              "ID": "Tenure",
                              "Description": "Tenure",
                              "Options": {
                                "GRS_T_B": {
                                  "ID": "B",
                                  "Description": "Buy",
                                  "ShortDescription": "Buy",
                                  "HierarchicalID": "GRS_T_B",
                                  "Options": {
                                    "PropertyUses": {
                                      "ID": "PropertyUses",
                                      "Description": "Property use",
                                      "OptionsReference": "PropertyUses",
                                      "Index": 0
                                    },
                                    "Currency": {
                                      "ID": "Currency",
                                      "Description": "Price range",
                                      "OptionsReference": "PriceInterval_Buy",
                                      "Index": 1
                                    },
                                    "CommercialSize": {
                                      "ID": "CommercialSize",
                                      "Description": "Property size",
                                      "Options": {
                                        "UnitOfMeasure": {
                                          "ID": "UnitOfMeasure",
                                          "Options": {
                                            "SquareFeet": {
                                              "ID": "SquareFeet",
                                              "Description": "sq. feet",
                                              "ShortDescription": "sq ft",
                                              "OptionsReference": "CommercialSize_SquareFeet",
                                              "Index": 0
                                            },
                                            "SquareMeter": {
                                              "ID": "SquareMeter",
                                              "Description": "sq. metres",
                                              "ShortDescription": "sq m",
                                              "OptionsReference": "CommercialSize_SquareMeter",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 2
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 3
                                    }
                                  },
                                  "Index": 0
                                },
                                "GRS_T_R": {
                                  "ID": "R",
                                  "Description": "Rent",
                                  "ShortDescription": "Rent",
                                  "HierarchicalID": "GRS_T_R",
                                  "Options": {
                                    "PropertyUses": {
                                      "ID": "PropertyUses",
                                      "Description": "Property use",
                                      "OptionsReference": "PropertyUses",
                                      "Index": 0
                                    },
                                    "Price": {
                                      "ID": "Price",
                                      "Description": "Price range",
                                      "Options": {
                                        "Period": {
                                          "ID": "Period",
                                          "Options": {
                                            "Month": {
                                              "ID": "Month",
                                              "Description": "Per month",
                                              "ShortDescription": "Per month",
                                              "OptionsReference": "PriceInterval_Comm_Rent_Month",
                                              "Index": 0
                                            },
                                            "Year": {
                                              "ID": "Year",
                                              "Description": "Per year",
                                              "ShortDescription": "Per year",
                                              "OptionsReference": "PriceInterval_Comm_Rent_Year",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 1
                                    },
                                    "CommercialSize": {
                                      "ID": "CommercialSize",
                                      "Description": "Property size",
                                      "Options": {
                                        "UnitOfMeasure": {
                                          "ID": "UnitOfMeasure",
                                          "Options": {
                                            "SquareFeet": {
                                              "ID": "SquareFeet",
                                              "Description": "sq. feet",
                                              "ShortDescription": "sq ft",
                                              "OptionsReference": "CommercialSize_SquareFeet",
                                              "Index": 0
                                            },
                                            "SquareMeter": {
                                              "ID": "SquareMeter",
                                              "Description": "sq. metres",
                                              "ShortDescription": "sq m",
                                              "OptionsReference": "CommercialSize_SquareMeter",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 2
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 3
                                    }
                                  },
                                  "Index": 1
                                }
                              },
                              "Index": 0
                            }
                          },
                          "Index": 2
                        },
                        "GRS_CPT_O": {
                          "ID": "O",
                          "Description": "Office",
                          "ShortDescription": "Office",
                          "HierarchicalID": "GRS_CPT_O",
                          "Options": {
                            "Tenure": {
                              "ID": "Tenure",
                              "Description": "Tenure",
                              "Options": {
                                "GRS_T_B": {
                                  "ID": "B",
                                  "Description": "Buy",
                                  "ShortDescription": "Buy",
                                  "HierarchicalID": "GRS_T_B",
                                  "Options": {
                                    "CommercialSize": {
                                      "ID": "CommercialSize",
                                      "Description": "Property size",
                                      "Options": {
                                        "UnitOfMeasure": {
                                          "ID": "UnitOfMeasure",
                                          "Options": {
                                            "SquareFeet": {
                                              "ID": "SquareFeet",
                                              "Description": "sq. feet",
                                              "ShortDescription": "sq ft",
                                              "OptionsReference": "CommercialSize_SquareFeet",
                                              "Index": 0
                                            },
                                            "SquareMeter": {
                                              "ID": "SquareMeter",
                                              "Description": "sq. metres",
                                              "ShortDescription": "sq m",
                                              "OptionsReference": "CommercialSize_SquareMeter",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 0
                                    },
                                    "NumberOfPeople": {
                                      "ID": "NumberOfPeople",
                                      "Description": "Number of people",
                                      "OptionsReference": "NumberOfPeople",
                                      "Index": 1
                                    },
                                    "BuildingAge": {
                                      "ID": "BuildingAge",
                                      "Description": "Building age",
                                      "OptionsReference": "BuildingAge",
                                      "Index": 2
                                    },
                                    "AvailableSize": {
                                      "ID": "AvailableSize",
                                      "Description": "Available space",
                                      "Options": {
                                        "UnitOfMeasure": {
                                          "ID": "UnitOfMeasure",
                                          "Options": {
                                            "SquareFeet": {
                                              "ID": "SquareFeet",
                                              "Description": "sq. feet",
                                              "ShortDescription": "sq ft",
                                              "OptionsReference": "AvailableSize_SquareFeet",
                                              "Index": 0
                                            },
                                            "SquareMeter": {
                                              "ID": "SquareMeter",
                                              "Description": "sq. metres",
                                              "ShortDescription": "sq m",
                                              "OptionsReference": "AvailableSize_SquareMeter",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 3
                                    },
                                    "Amenities": {
                                      "ID": "Amenities",
                                      "Description": "Amenities",
                                      "OptionsReference": "Amenities",
                                      "Index": 4
                                    },
                                    "OfficeGrades": {
                                      "ID": "OfficeGrades",
                                      "Description": "Grade of space",
                                      "OptionsReference": "OfficeGrades",
                                      "Index": 5
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 6
                                    }
                                  },
                                  "Index": 0
                                },
                                "GRS_T_R": {
                                  "ID": "R",
                                  "Description": "Rent",
                                  "ShortDescription": "Rent",
                                  "HierarchicalID": "GRS_T_R",
                                  "Options": {
                                    "CommercialSize": {
                                      "ID": "CommercialSize",
                                      "Description": "Property size",
                                      "Options": {
                                        "UnitOfMeasure": {
                                          "ID": "UnitOfMeasure",
                                          "Options": {
                                            "SquareFeet": {
                                              "ID": "SquareFeet",
                                              "Description": "sq. feet",
                                              "ShortDescription": "sq ft",
                                              "OptionsReference": "CommercialSize_SquareFeet",
                                              "Index": 0
                                            },
                                            "SquareMeter": {
                                              "ID": "SquareMeter",
                                              "Description": "sq. metres",
                                              "ShortDescription": "sq m",
                                              "OptionsReference": "CommercialSize_SquareMeter",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 0
                                    },
                                    "NumberOfPeople": {
                                      "ID": "NumberOfPeople",
                                      "Description": "Number of people",
                                      "OptionsReference": "NumberOfPeople",
                                      "Index": 1
                                    },
                                    "BuildingAge": {
                                      "ID": "BuildingAge",
                                      "Description": "Building age",
                                      "OptionsReference": "BuildingAge",
                                      "Index": 2
                                    },
                                    "AvailableSize": {
                                      "ID": "AvailableSize",
                                      "Description": "Available space",
                                      "Options": {
                                        "UnitOfMeasure": {
                                          "ID": "UnitOfMeasure",
                                          "Options": {
                                            "SquareFeet": {
                                              "ID": "SquareFeet",
                                              "Description": "sq. feet",
                                              "ShortDescription": "sq ft",
                                              "OptionsReference": "AvailableSize_SquareFeet",
                                              "Index": 0
                                            },
                                            "SquareMeter": {
                                              "ID": "SquareMeter",
                                              "Description": "sq. metres",
                                              "ShortDescription": "sq m",
                                              "OptionsReference": "AvailableSize_SquareMeter",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 3
                                    },
                                    "Amenities": {
                                      "ID": "Amenities",
                                      "Description": "Amenities",
                                      "OptionsReference": "Amenities",
                                      "Index": 4
                                    },
                                    "OfficeGrades": {
                                      "ID": "OfficeGrades",
                                      "Description": "Grade of space",
                                      "OptionsReference": "OfficeGrades",
                                      "Index": 5
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 6
                                    }
                                  },
                                  "Index": 1
                                }
                              },
                              "Index": 0
                            }
                          },
                          "Index": 3
                        },
                        "GRS_CPT_HO": {
                          "ID": "HO",
                          "Description": "Hotel",
                          "ShortDescription": "Hotel",
                          "HierarchicalID": "GRS_CPT_HO",
                          "Options": {
                            "Tenure": {
                              "ID": "Tenure",
                              "Description": "Tenure",
                              "Options": {
                                "GRS_T_B": {
                                  "ID": "B",
                                  "Description": "Buy",
                                  "ShortDescription": "Buy",
                                  "HierarchicalID": "GRS_T_B",
                                  "Options": {
                                    "Currency": {
                                      "ID": "Currency",
                                      "Description": "Price range",
                                      "OptionsReference": "PriceInterval_Buy",
                                      "Index": 0
                                    },
                                    "CommercialBedrooms": {
                                      "ID": "CommercialBedrooms",
                                      "Description": "Bedrooms",
                                      "OptionsReference": "CommercialBedrooms",
                                      "Index": 1
                                    },
                                    "PropertyUses": {
                                      "ID": "PropertyUses",
                                      "Description": "Property use",
                                      "OptionsReference": "PropertyUses",
                                      "Index": 2
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 3
                                    }
                                  },
                                  "Index": 0
                                },
                                "GRS_T_R": {
                                  "ID": "R",
                                  "Description": "Rent",
                                  "ShortDescription": "Rent",
                                  "HierarchicalID": "GRS_T_R",
                                  "Options": {
                                    "Price": {
                                      "ID": "Price",
                                      "Description": "Price range",
                                      "Options": {
                                        "Period": {
                                          "ID": "Period",
                                          "Options": {
                                            "Month": {
                                              "ID": "Month",
                                              "Description": "Per month",
                                              "ShortDescription": "Per month",
                                              "OptionsReference": "PriceInterval_Comm_Rent_Month",
                                              "Index": 0
                                            },
                                            "Year": {
                                              "ID": "Year",
                                              "Description": "Per year",
                                              "ShortDescription": "Per year",
                                              "OptionsReference": "PriceInterval_Comm_Rent_Year",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 0
                                    },
                                    "CommercialBedrooms": {
                                      "ID": "CommercialBedrooms",
                                      "Description": "Bedrooms",
                                      "OptionsReference": "CommercialBedrooms",
                                      "Index": 1
                                    },
                                    "PropertyUses": {
                                      "ID": "PropertyUses",
                                      "Description": "Property use",
                                      "OptionsReference": "PropertyUses",
                                      "Index": 2
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 3
                                    }
                                  },
                                  "Index": 1
                                }
                              },
                              "Index": 0
                            }
                          },
                          "Index": 4
                        },
                        "GRS_CPT_H": {
                          "ID": "H",
                          "Description": "Healthcare",
                          "ShortDescription": "Healthcare",
                          "HierarchicalID": "GRS_CPT_H",
                          "Options": {
                            "Tenure": {
                              "ID": "Tenure",
                              "Description": "Tenure",
                              "Options": {
                                "GRS_T_B": {
                                  "ID": "B",
                                  "Description": "Buy",
                                  "ShortDescription": "Buy",
                                  "HierarchicalID": "GRS_T_B",
                                  "Options": {
                                    "PropertyUses": {
                                      "ID": "PropertyUses",
                                      "Description": "Property use",
                                      "OptionsReference": "PropertyUses",
                                      "Index": 0
                                    },
                                    "Currency": {
                                      "ID": "Currency",
                                      "Description": "Price range",
                                      "OptionsReference": "PriceInterval_Buy",
                                      "Index": 1
                                    },
                                    "CommercialBedrooms": {
                                      "ID": "CommercialBedrooms",
                                      "Description": "Bedrooms",
                                      "OptionsReference": "CommercialBedrooms",
                                      "Index": 2
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 3
                                    }
                                  },
                                  "Index": 0
                                },
                                "GRS_T_R": {
                                  "ID": "R",
                                  "Description": "Rent",
                                  "ShortDescription": "Rent",
                                  "HierarchicalID": "GRS_T_R",
                                  "Options": {
                                    "PropertyUses": {
                                      "ID": "PropertyUses",
                                      "Description": "Property use",
                                      "OptionsReference": "PropertyUses",
                                      "Index": 0
                                    },
                                    "Price": {
                                      "ID": "Price",
                                      "Description": "Price range",
                                      "Options": {
                                        "Period": {
                                          "ID": "Period",
                                          "Options": {
                                            "Month": {
                                              "ID": "Month",
                                              "Description": "Per month",
                                              "ShortDescription": "Per month",
                                              "OptionsReference": "PriceInterval_Comm_Rent_Month",
                                              "Index": 0
                                            },
                                            "Year": {
                                              "ID": "Year",
                                              "Description": "Per year",
                                              "ShortDescription": "Per year",
                                              "OptionsReference": "PriceInterval_Comm_Rent_Year",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 1
                                    },
                                    "CommercialBedrooms": {
                                      "ID": "CommercialBedrooms",
                                      "Description": "Bedrooms",
                                      "OptionsReference": "CommercialBedrooms",
                                      "Index": 2
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 3
                                    }
                                  },
                                  "Index": 1
                                }
                              },
                              "Index": 0
                            }
                          },
                          "Index": 5
                        },
                        "GRS_CPT_OC": {
                          "ID": "OC",
                          "Description": "Other commercial",
                          "ShortDescription": "OtherCommercial",
                          "HierarchicalID": "GRS_CPT_OC",
                          "Options": {
                            "Tenure": {
                              "ID": "Tenure",
                              "Description": "Tenure",
                              "Options": {
                                "GRS_T_B": {
                                  "ID": "B",
                                  "Description": "Buy",
                                  "ShortDescription": "Buy",
                                  "HierarchicalID": "GRS_T_B",
                                  "Options": {
                                    "Currency": {
                                      "ID": "Currency",
                                      "Description": "Price range",
                                      "OptionsReference": "PriceInterval_Buy",
                                      "Index": 0
                                    },
                                    "CommercialSize": {
                                      "ID": "CommercialSize",
                                      "Description": "Property size",
                                      "Options": {
                                        "UnitOfMeasure": {
                                          "ID": "UnitOfMeasure",
                                          "Options": {
                                            "SquareFeet": {
                                              "ID": "SquareFeet",
                                              "Description": "sq. feet",
                                              "ShortDescription": "sq ft",
                                              "OptionsReference": "CommercialSize_SquareFeet",
                                              "Index": 0
                                            },
                                            "SquareMeter": {
                                              "ID": "SquareMeter",
                                              "Description": "sq. metres",
                                              "ShortDescription": "sq m",
                                              "OptionsReference": "CommercialSize_SquareMeter",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 1
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 2
                                    }
                                  },
                                  "Index": 0
                                },
                                "GRS_T_R": {
                                  "ID": "R",
                                  "Description": "Rent",
                                  "ShortDescription": "Rent",
                                  "HierarchicalID": "GRS_T_R",
                                  "Options": {
                                    "Price": {
                                      "ID": "Price",
                                      "Description": "Price range",
                                      "Options": {
                                        "Period": {
                                          "ID": "Period",
                                          "Options": {
                                            "Month": {
                                              "ID": "Month",
                                              "Description": "Per month",
                                              "ShortDescription": "Per month",
                                              "OptionsReference": "PriceInterval_Comm_Rent_Month",
                                              "Index": 0
                                            },
                                            "Year": {
                                              "ID": "Year",
                                              "Description": "Per year",
                                              "ShortDescription": "Per year",
                                              "OptionsReference": "PriceInterval_Comm_Rent_Year",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 0
                                    },
                                    "CommercialSize": {
                                      "ID": "CommercialSize",
                                      "Description": "Property size",
                                      "Options": {
                                        "UnitOfMeasure": {
                                          "ID": "UnitOfMeasure",
                                          "Options": {
                                            "SquareFeet": {
                                              "ID": "SquareFeet",
                                              "Description": "sq. feet",
                                              "ShortDescription": "sq ft",
                                              "OptionsReference": "CommercialSize_SquareFeet",
                                              "Index": 0
                                            },
                                            "SquareMeter": {
                                              "ID": "SquareMeter",
                                              "Description": "sq. metres",
                                              "ShortDescription": "sq m",
                                              "OptionsReference": "CommercialSize_SquareMeter",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 1
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 2
                                    }
                                  },
                                  "Index": 1
                                }
                              },
                              "Index": 0
                            }
                          },
                          "Index": 6
                        },
                        "GRS_CPT_IN": {
                          "ID": "IN",
                          "Description": "Investment",
                          "ShortDescription": "Investment",
                          "HierarchicalID": "GRS_CPT_IN",
                          "Options": {
                            "Tenure": {
                              "ID": "Tenure",
                              "Description": "Tenure",
                              "Options": {
                                "GRS_T_B": {
                                  "ID": "B",
                                  "Description": "Buy",
                                  "ShortDescription": "Buy",
                                  "HierarchicalID": "GRS_T_B",
                                  "Options": {
                                    "PropertyUses": {
                                      "ID": "PropertyUses",
                                      "Description": "Property use",
                                      "OptionsReference": "PropertyUses",
                                      "Index": 0
                                    },
                                    "Yield": {
                                      "ID": "Yield",
                                      "Description": "Yield",
                                      "OptionsReference": "Yield",
                                      "Index": 1
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 2
                                    }
                                  },
                                  "Index": 0
                                }
                              },
                              "Index": 0
                            }
                          },
                          "Index": 7
                        },
                        "GRS_CPT_SO": {
                          "ID": "SO",
                          "Description": "Serviced office",
                          "ShortDescription": "ServiceOffice",
                          "HierarchicalID": "GRS_CPT_SO",
                          "Options": {
                            "Tenure": {
                              "ID": "Tenure",
                              "Description": "Tenure",
                              "Options": {
                                "GRS_T_R": {
                                  "ID": "R",
                                  "Description": "Rent",
                                  "ShortDescription": "Rent",
                                  "HierarchicalID": "GRS_T_R",
                                  "Options": {
                                    "NumberOfPeople": {
                                      "ID": "NumberOfPeople",
                                      "Description": "Number of people",
                                      "OptionsReference": "NumberOfPeople",
                                      "Index": 0
                                    },
                                    "CommercialSize": {
                                      "ID": "CommercialSize",
                                      "Description": "Property size",
                                      "Options": {
                                        "UnitOfMeasure": {
                                          "ID": "UnitOfMeasure",
                                          "Options": {
                                            "SquareFeet": {
                                              "ID": "SquareFeet",
                                              "Description": "sq. feet",
                                              "ShortDescription": "sq ft",
                                              "OptionsReference": "CommercialSize_SquareFeet",
                                              "Index": 0
                                            },
                                            "SquareMeter": {
                                              "ID": "SquareMeter",
                                              "Description": "sq. metres",
                                              "ShortDescription": "sq m",
                                              "OptionsReference": "CommercialSize_SquareMeter",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 1
                                    },
                                    "BuildingAge": {
                                      "ID": "BuildingAge",
                                      "Description": "Building age",
                                      "OptionsReference": "BuildingAge",
                                      "Index": 2
                                    },
                                    "AvailableSize": {
                                      "ID": "AvailableSize",
                                      "Description": "Available space",
                                      "Options": {
                                        "UnitOfMeasure": {
                                          "ID": "UnitOfMeasure",
                                          "Options": {
                                            "SquareFeet": {
                                              "ID": "SquareFeet",
                                              "Description": "sq. feet",
                                              "ShortDescription": "sq ft",
                                              "OptionsReference": "AvailableSize_SquareFeet",
                                              "Index": 0
                                            },
                                            "SquareMeter": {
                                              "ID": "SquareMeter",
                                              "Description": "sq. metres",
                                              "ShortDescription": "sq m",
                                              "OptionsReference": "AvailableSize_SquareMeter",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 3
                                    },
                                    "Amenities": {
                                      "ID": "Amenities",
                                      "Description": "Amenities",
                                      "OptionsReference": "Amenities",
                                      "Index": 4
                                    },
                                    "OfficeGrades": {
                                      "ID": "OfficeGrades",
                                      "Description": "Grade of space",
                                      "OptionsReference": "OfficeGrades",
                                      "Index": 5
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 6
                                    }
                                  },
                                  "Index": 0
                                }
                              },
                              "Index": 0
                            }
                          },
                          "Index": 8
                        },
                        "GRS_CPT_R": {
                          "ID": "R",
                          "Description": "Retail",
                          "ShortDescription": "Retail",
                          "HierarchicalID": "GRS_CPT_R",
                          "Options": {
                            "Tenure": {
                              "ID": "Tenure",
                              "Description": "Tenure",
                              "Options": {
                                "GRS_T_B": {
                                  "ID": "B",
                                  "Description": "Buy",
                                  "ShortDescription": "Buy",
                                  "HierarchicalID": "GRS_T_B",
                                  "Options": {
                                    "PropertyUses": {
                                      "ID": "PropertyUses",
                                      "Description": "Property use",
                                      "OptionsReference": "PropertyUses",
                                      "Index": 0
                                    },
                                    "CommercialSize": {
                                      "ID": "CommercialSize",
                                      "Description": "Property size",
                                      "Options": {
                                        "UnitOfMeasure": {
                                          "ID": "UnitOfMeasure",
                                          "Options": {
                                            "SquareFeet": {
                                              "ID": "SquareFeet",
                                              "Description": "sq. feet",
                                              "ShortDescription": "sq ft",
                                              "OptionsReference": "CommercialSize_SquareFeet",
                                              "Index": 0
                                            },
                                            "SquareMeter": {
                                              "ID": "SquareMeter",
                                              "Description": "sq. metres",
                                              "ShortDescription": "sq m",
                                              "OptionsReference": "CommercialSize_SquareMeter",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 1
                                    },
                                    "Currency": {
                                      "ID": "Currency",
                                      "Description": "Price range",
                                      "OptionsReference": "PriceInterval_Buy",
                                      "Index": 2
                                    },
                                    "AvailableSize": {
                                      "ID": "AvailableSize",
                                      "Description": "Available space",
                                      "Options": {
                                        "UnitOfMeasure": {
                                          "ID": "UnitOfMeasure",
                                          "Options": {
                                            "SquareFeet": {
                                              "ID": "SquareFeet",
                                              "Description": "sq. feet",
                                              "ShortDescription": "sq ft",
                                              "OptionsReference": "AvailableSize_SquareFeet",
                                              "Index": 0
                                            },
                                            "SquareMeter": {
                                              "ID": "SquareMeter",
                                              "Description": "sq. metres",
                                              "ShortDescription": "sq m",
                                              "OptionsReference": "AvailableSize_SquareMeter",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 3
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 4
                                    }
                                  },
                                  "Index": 0
                                },
                                "GRS_T_R": {
                                  "ID": "R",
                                  "Description": "Rent",
                                  "ShortDescription": "Rent",
                                  "HierarchicalID": "GRS_T_R",
                                  "Options": {
                                    "PropertyUses": {
                                      "ID": "PropertyUses",
                                      "Description": "Property use",
                                      "OptionsReference": "PropertyUses",
                                      "Index": 0
                                    },
                                    "CommercialSize": {
                                      "ID": "CommercialSize",
                                      "Description": "Property size",
                                      "Options": {
                                        "UnitOfMeasure": {
                                          "ID": "UnitOfMeasure",
                                          "Options": {
                                            "SquareFeet": {
                                              "ID": "SquareFeet",
                                              "Description": "sq. feet",
                                              "ShortDescription": "sq ft",
                                              "OptionsReference": "CommercialSize_SquareFeet",
                                              "Index": 0
                                            },
                                            "SquareMeter": {
                                              "ID": "SquareMeter",
                                              "Description": "sq. metres",
                                              "ShortDescription": "sq m",
                                              "OptionsReference": "CommercialSize_SquareMeter",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 1
                                    },
                                    "Price": {
                                      "ID": "Price",
                                      "Description": "Price range",
                                      "Options": {
                                        "Period": {
                                          "ID": "Period",
                                          "Options": {
                                            "Month": {
                                              "ID": "Month",
                                              "Description": "Per month",
                                              "ShortDescription": "Per month",
                                              "OptionsReference": "PriceInterval_Comm_Rent_Month",
                                              "Index": 0
                                            },
                                            "Year": {
                                              "ID": "Year",
                                              "Description": "Per year",
                                              "ShortDescription": "Per year",
                                              "OptionsReference": "PriceInterval_Comm_Rent_Year",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 2
                                    },
                                    "AvailableSize": {
                                      "ID": "AvailableSize",
                                      "Description": "Available space",
                                      "Options": {
                                        "UnitOfMeasure": {
                                          "ID": "UnitOfMeasure",
                                          "Options": {
                                            "SquareFeet": {
                                              "ID": "SquareFeet",
                                              "Description": "sq. feet",
                                              "ShortDescription": "sq ft",
                                              "OptionsReference": "AvailableSize_SquareFeet",
                                              "Index": 0
                                            },
                                            "SquareMeter": {
                                              "ID": "SquareMeter",
                                              "Description": "sq. metres",
                                              "ShortDescription": "sq m",
                                              "OptionsReference": "AvailableSize_SquareMeter",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 3
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 4
                                    }
                                  },
                                  "Index": 1
                                }
                              },
                              "Index": 0
                            }
                          },
                          "Index": 9
                        },
                        "GRS_CPT_SNL": {
                          "ID": "SNL",
                          "Description": "Senior living",
                          "ShortDescription": "SeniorLiving",
                          "HierarchicalID": "GRS_CPT_SNL",
                          "Options": {
                            "Tenure": {
                              "ID": "Tenure",
                              "Description": "Tenure",
                              "Options": {
                                "GRS_T_B": {
                                  "ID": "B",
                                  "Description": "Buy",
                                  "ShortDescription": "Buy",
                                  "HierarchicalID": "GRS_T_B",
                                  "Options": {
                                    "PropertyUses": {
                                      "ID": "PropertyUses",
                                      "Description": "Property use",
                                      "OptionsReference": "PropertyUses",
                                      "Index": 0
                                    },
                                    "Currency": {
                                      "ID": "Currency",
                                      "Description": "Price range",
                                      "OptionsReference": "PriceInterval_Buy",
                                      "Index": 1
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 2
                                    }
                                  },
                                  "Index": 0
                                },
                                "GRS_T_R": {
                                  "ID": "R",
                                  "Description": "Rent",
                                  "ShortDescription": "Rent",
                                  "HierarchicalID": "GRS_T_R",
                                  "Options": {
                                    "PropertyUses": {
                                      "ID": "PropertyUses",
                                      "Description": "Property use",
                                      "OptionsReference": "PropertyUses",
                                      "Index": 0
                                    },
                                    "Price": {
                                      "ID": "Price",
                                      "Description": "Price range",
                                      "Options": {
                                        "Period": {
                                          "ID": "Period",
                                          "Options": {
                                            "Month": {
                                              "ID": "Month",
                                              "Description": "Per month",
                                              "ShortDescription": "Per month",
                                              "OptionsReference": "PriceInterval_Comm_Rent_Month",
                                              "Index": 0
                                            },
                                            "Year": {
                                              "ID": "Year",
                                              "Description": "Per year",
                                              "ShortDescription": "Per year",
                                              "OptionsReference": "PriceInterval_Comm_Rent_Year",
                                              "Index": 1
                                            }
                                          },
                                          "Index": 0
                                        }
                                      },
                                      "Index": 1
                                    },
                                    "OwnershipTypes": {
                                      "ID": "OwnershipTypes",
                                      "Description": "Ownership type",
                                      "OptionsReference": "OwnershipTypes",
                                      "Index": 2
                                    }
                                  },
                                  "Index": 1
                                }
                              },
                              "Index": 0
                            }
                          },
                          "Index": 10
                        }
                      },
                      "Index": 0
                    }
                  },
                  "Index": 1
                },
                "GRS_CAT_FES": {
                  "ID": "FES",
                  "Description": "Farms, estates \u0026 agricultural land",
                  "ShortDescription": "Rural",
                  "HierarchicalID": "GRS_CAT_FES",
                  "Options": {
                    "Tenure": {
                      "ID": "Tenure",
                      "Description": "Tenure",
                      "Options": {
                        "GRS_T_B": {
                          "ID": "B",
                          "Description": "Buy",
                          "ShortDescription": "Buy",
                          "HierarchicalID": "GRS_T_B",
                          "Options": {
                            "Currency": {
                              "ID": "Currency",
                              "Description": "Price range",
                              "OptionsReference": "PriceInterval_Buy",
                              "Index": 0
                            },
                            "FarmsPropertyTypes": {
                              "ID": "FarmsPropertyTypes",
                              "Description": "Property types",
                              "OptionsReference": "FarmsPropertyTypes",
                              "Index": 1
                            },
                            "LandArea": {
                              "ID": "LandArea",
                              "Description": "Land size",
                              "Options": {
                                "UnitOfMeasure": {
                                  "ID": "UnitOfMeasure",
                                  "Options": {
                                    "Acre": {
                                      "ID": "Acre",
                                      "Description": "Acres",
                                      "ShortDescription": "Ac",
                                      "OptionsReference": "LandArea_Acre",
                                      "Index": 0
                                    },
                                    "Hectare": {
                                      "ID": "Hectare",
                                      "Description": "Hectares",
                                      "ShortDescription": "Ha",
                                      "OptionsReference": "LandArea_Hectare",
                                      "Index": 1
                                    }
                                  },
                                  "Index": 0
                                }
                              },
                              "Index": 2
                            }
                          },
                          "Index": 0
                        },
                        "GRS_T_R": {
                          "ID": "R",
                          "Description": "Rent",
                          "ShortDescription": "Rent",
                          "HierarchicalID": "GRS_T_R",
                          "Options": {
                            "Price": {
                              "ID": "Price",
                              "Description": "Price range",
                              "Options": {
                                "Period": {
                                  "ID": "Period",
                                  "Options": {
                                    "Week": {
                                      "ID": "Week",
                                      "Description": "Per week",
                                      "ShortDescription": "Per week",
                                      "OptionsReference": "PriceInterval_Rent_Week",
                                      "Index": 0
                                    },
                                    "Month": {
                                      "ID": "Month",
                                      "Description": "Per month",
                                      "ShortDescription": "Per month",
                                      "OptionsReference": "PriceInterval_Rent_Month",
                                      "Index": 1
                                    },
                                    "Year": {
                                      "ID": "Year",
                                      "Description": "Per year",
                                      "ShortDescription": "Per year",
                                      "OptionsReference": "PriceInterval_Rent_Year",
                                      "Index": 2
                                    }
                                  },
                                  "Index": 0
                                }
                              },
                              "Index": 0
                            },
                            "FarmsPropertyTypes": {
                              "ID": "FarmsPropertyTypes",
                              "Description": "Property types",
                              "OptionsReference": "FarmsPropertyTypes",
                              "Index": 1
                            },
                            "LandArea": {
                              "ID": "LandArea",
                              "Description": "Land size",
                              "Options": {
                                "UnitOfMeasure": {
                                  "ID": "UnitOfMeasure",
                                  "Options": {
                                    "Acre": {
                                      "ID": "Acre",
                                      "Description": "Acres",
                                      "ShortDescription": "Ac",
                                      "OptionsReference": "LandArea_Acre",
                                      "Index": 0
                                    },
                                    "Hectare": {
                                      "ID": "Hectare",
                                      "Description": "Hectares",
                                      "ShortDescription": "Ha",
                                      "OptionsReference": "LandArea_Hectare",
                                      "Index": 1
                                    }
                                  },
                                  "Index": 0
                                }
                              },
                              "Index": 2
                            }
                          },
                          "Index": 1
                        }
                      },
                      "Index": 0
                    }
                  },
                  "Index": 2
                }
              }
            },
            "persistedOptions": {},
            "currencyRequest": {
              "fetchedIds": [],
              "pending": []
            }
          },
          "uiResource": {
            "A": "A",
            "AboutThisProperty": "About this property",
            "Accessible": "Accessible",
            "AccountLocked": "Account locked",
            "Acres": "Acres",
            "AddFilters": "Add filters",
            "AdditionalInformation": "Additional information",
            "Address": "Address",
            "Address_label": "Find your address",
            "AddressInvalidValidation": "Please enter a valid address",
            "Agent": "Agent",
            "AgentDetails": "Agent details",
            "AgentLicence": "Agent licence",
            "Airconditioning": "Air conditioning",
            "Airport": "Airport",
            "AlertCustomLocation": "{0} {1} {2}",
            "AlertsEmailIntroduction1Property": "We are pleased to enclose details of a new property which has come to the market and fits your search criteria.",
            "AlertsEmailIntroductionMoreThan1Property": "We are pleased to enclose details of new properties which have come to the market and fit your search criteria.",
            "AlertsEmailSubject": "New properties from Savills",
            "AlertsEmailUnsubscribe": "If you wish to completely unsubscribe from Savills+, please click {0} here {1}",
            "AlertsEmailUpdatePropertyAlerts": "If you would like to remove or update your property alerts, please log in {0} here {1}",
            "AlertTextMultipleLocations": "{0} in {1}",
            "AlertTextOffice": "{0} from {1}",
            "AlertTextPolygon": "{0} in {1}",
            "AlertTextRadial": "{0} {1} {2} of {3}",
            "AllBathrooms": "All bathrooms",
            "AllBedrooms": "All bedrooms",
            "AllCarSpaces": "All parking spaces",
            "AllCommercialPropertyType": "All property types",
            "AllFarmsPropertyTypes": "All property types",
            "AllMarketTypes": "Open or local",
            "AllPrices": "All prices",
            "AllPropertyTypes": "All property types",
            "AllPropertyUses": "All property uses",
            "AllReceptions": "All receptions",
            "Amenities": "Amenities",
            "Any": "Any",
            "AnyAge": "Any age",
            "AnyAvailableSpace": "Any available space",
            "AnyGrade": "Any grade",
            "AnyNumberOfPeople": "Any number of people",
            "AnyOwnershipType": "Any ownership type",
            "AnyPrice": "Any price",
            "AnySize": "Any size",
            "AnyYield": "Any yield",
            "Aparthotels": "Aparthotels",
            "ApplicationTitle": "Global Search",
            "Apply": "Apply",
            "AppointedAgent": "Appointed agent",
            "April": "April",
            "ArableFarm": "Arable farm",
            "AreaGuide": "Area guide",
            "AreaGuides": "Area guides",
            "AreaSize": "Property size",
            "ArrangeAMarketAppraisal": "Arrange a market appraisal",
            "ArrangeAViewingClientIntroductionText": "Thank you for your request. One of our agents will be in touch shortly.",
            "ArrangeAViewingClientSubject": "Request received",
            "ArrangeAViewingClientTitle": "Request received",
            "ArrangeAViewingClientTitleDescription": "Thank you for getting in touch",
            "ArrangeAViewingDialogMessage": "Please fill in the form below to request details for this property",
            "ArrangeAViewingOfThisProperty": "Arrange a viewing of this property (optional)",
            "ArrangeAViewingSavillsSubject": "Savills Global Search - Arrange A Viewing",
            "ArrangeAViewingSavillsTitle": "Your Savills Viewing",
            "ArrangeAViewingSavillsTitleDescription": "Details of your appointment",
            "ArrangeMarketAppraisalDialogSuccessMessage": "We will contact you shortly to arrange your appraisal",
            "ArrangeMarketAppraisalSuccessMessage": "Thank you",
            "ArrangeViewing": "Request details",
            "ArrangeViewingDialogSuccessMessage": "An agent from the office will respond to your enquiry regarding the property. We will send details to {0}.",
            "ArrangeViewingDialogSuccessTitle": "Thank you",
            "AsAnEmailAlert": "as an email alert",
            "AskingPrice": "Asking price",
            "AssistedLiving": "Assisted living",
            "AuctionsTag": "Auctions",
            "August": "August",
            "Availability": "Availability",
            "Available": "Available",
            "AvailableInLots": "Available in lots",
            "AvailableLots": "Available Lots",
            "AvailableSize": "Available space",
            "AvailableSpace": "Available space",
            "B": "B",
            "Back": "Back",
            "BackToListings": "Back to listings",
            "BackToSavedListings": "Back to saved listings",
            "Balcony": "Balcony",
            "BareLand": "Bare land",
            "BathroomFilterDescription": "Please set your minimum amount of bathrooms required",
            "Bathrooms": "Bathrooms",
            "Bathrooms1": "1",
            "Bathrooms2": "2",
            "Bathrooms3": "3",
            "Bathrooms4": "4",
            "Bathrooms5": "5",
            "Bathrooms6": "6",
            "BathroomsCount": "{0} bathrooms",
            "BathroomsCountSingle": "1 bathroom",
            "Beach": "Beach",
            "Bedroom1": "1",
            "Bedroom2": "2",
            "Bedroom3": "3",
            "Bedroom4": "4",
            "Bedroom5": "5",
            "Bedroom6": "6",
            "BedroomFilterDescription": "Please set your minimum amount of bedrooms required",
            "BedroomFilterLabelSuffix": "+ bedrooms",
            "Bedrooms": "Bedrooms",
            "BedroomsCount": "{0} bedrooms",
            "BedroomsCountSingle": "1 bedroom",
            "BicycleParking": "Bicycle parking",
            "Boat": "Boat",
            "BodyMarketYourPropertyLink": "Whether you're selling or looking to attract new tenants, we're here to help",
            "BodyMortgageAdviceLink": "Make your next property move with advice from our trusted partners at SPF clients",
            "BodySubscribeToResearchLink": "Subscribe to our research emails and receive the latest analysis on property markets across the globe.",
            "BothFurnished": "Furnished / unfurnished",
            "BottomLinks_AF": "Arable farms",
            "BottomLinks_APT": "Flats / Apartments",
            "BottomLinks_B": "Bungalows",
            "BottomLinks_BL": "Bare lands",
            "BottomLinks_BP": "Building plots",
            "BottomLinks_C": "Compounds",
            "BottomLinks_CAST": "Castles",
            "BottomLinks_CHLT": "Chalet",
            "BottomLinks_CONDO": "Condominiums",
            "BottomLinks_CTTG": "Cottages",
            "BottomLinks_D": "Duplex",
            "BottomLinks_DF": "Dairy farms",
            "BottomLinks_ECONDO": "Executive condominiums",
            "BottomLinks_EQ": "Equestrian property",
            "BottomLinks_EST": "Estates",
            "BottomLinks_F": "Farms",
            "BottomLinks_FOC": "Flat outside the city",
            "BottomLinks_FSH": "Fishing estates",
            "BottomLinks_GRS_CPT_BP": "Business Park",
            "BottomLinks_GRS_CPT_D": "Development land",
            "BottomLinks_GRS_CPT_FS": "FlexSpace",
            "BottomLinks_GRS_CPT_H": "Healthcare",
            "BottomLinks_GRS_CPT_HO": "Hotel",
            "BottomLinks_GRS_CPT_I": "Industrial",
            "BottomLinks_GRS_CPT_IN": "Investment",
            "BottomLinks_GRS_CPT_L": "Leisure",
            "BottomLinks_GRS_CPT_LAN": "Land",
            "BottomLinks_GRS_CPT_O": "Office",
            "BottomLinks_GRS_CPT_OC": "Other Commercial",
            "BottomLinks_GRS_CPT_R": "Retail",
            "BottomLinks_GRS_CPT_SH": "Shophouse",
            "BottomLinks_GRS_CPT_SNL": "Senior living properties",
            "BottomLinks_GRS_CPT_SO": "Serviced office",
            "BottomLinks_H": "Houses",
            "BottomLinks_HDB": "HDB apartments",
            "BottomLinks_HRB": "High rise buildings",
            "BottomLinks_HTB": "Help to buy",
            "BottomLinks_LF": "Livestock farms",
            "BottomLinks_LP": "Land plots",
            "BottomLinks_M": "Mews",
            "BottomLinks_MRB": "Middle rise buildings",
            "BottomLinks_ND": "New homes",
            "BottomLinks_PENT": "Penthouses",
            "BottomLinks_RFE": "Estates",
            "BottomLinks_S": "Sporting estates",
            "BottomLinks_SAPT": "Serviced apartments",
            "BottomLinks_SNRL": "Senior living properties",
            "BottomLinks_SOW": "Shared Ownership properties",
            "BottomLinks_STU": "Studios",
            "BottomLinks_T": "Triplex",
            "BottomLinks_TH": "Townhouses",
            "BottomLinks_TUA": "Temporary use apartments",
            "BottomLinks_V": "Villas",
            "BottomLinks_VY": "Vineyards",
            "BottomLinks_W": "Woodlands",
            "BroadenYourSearch": "Please try broadening your search criteria.",
            "BroadenYourSearchClickHere": "Click here to clear your filters.",
            "Brochure": "Brochure",
            "BrowsePropertiesForRent": "Browse properties to let in:",
            "BrowsePropertiesForSale": "Browse properties for sale in:",
            "buildingAge": "Building age",
            "BuildingNameOrStreet": "Building name or street",
            "BuildingPlot": "Building plot",
            "Buildings": "Buildings",
            "BuildToSuit": "Build to Suit",
            "Bungalow": "Bungalow",
            "BusinessPark": "Business park",
            "Buy": "Buy",
            "C": "C",
            "C2DevelopmentLand": "C2 Development Land",
            "C2Land": "C2 Land",
            "CableSystem": "Cable System",
            "CalendarDateFormat": "DD/MM/YYYY",
            "Cancel": "Cancel",
            "Canteen": "Canteen",
            "CaravanPark": "Caravan park",
            "CareHome": "Care home",
            "CarPark": "Car Park",
            "CarSpaceFilterDescription": "Please set your minimum amount of parking spaces required",
            "CarSpaces": "Parking Spaces",
            "CarSpaces1": "1",
            "CarSpaces2": "2",
            "CarSpaces3": "3",
            "CarSpaces4": "4",
            "CarSpaces5": "5",
            "CarSpaces6": "6",
            "CarSpacesCount": "{0} parking spaces",
            "CarSpacesCountSingle": "1 parking space",
            "Castle": "Castle / Chateaux",
            "Category": "Category",
            "Category_Heading": "What can we do for you?",
            "CategoryAgent": "Savills agents",
            "CategoryAirport": "Airports",
            "CategoryBuilding": "Buildings",
            "CategoryBuildingSearch": "Buildings",
            "CategoryContinent": "Continents",
            "CategoryLocality": "Locality",
            "CategoryNewDevelopment": "New Developments",
            "CategoryPostcode": "Postcodes \u0026 Zipcodes",
            "CategoryProperty": "Properties",
            "CategoryRegionCountyCountry": "Regions, counties \u0026 countries",
            "CategoryRoad": "Roads",
            "CategoryRuralLots": "Lots",
            "CategorySchool": "Schools",
            "CategoryStation": "Stations",
            "CategoryTownVillageCity": "Cities, districts, towns \u0026 villages",
            "CEARegNumber": "CEA reg. number",
            "Chalet": "Chalet",
            "ChangeDetails": "Change my details",
            "ChangePassword": "Change password",
            "CharactersMax": "300 characters max",
            "CharactersMinimum": "8 characters minimum",
            "CheckYourEmail": "Check your email",
            "CheckYourEmailMessage": "Please check {0} for the confirmation email. It will have a link to reset your password",
            "ChildrenFacilities": "Children's facilities",
            "ChildrensDayNursery": "Children's Day Nursery",
            "CitiesDistrictsTownVillages": "Cities, districts, towns \u0026 villages",
            "Clear": "Clear",
            "ClearFilters": "Clear Filters",
            "ClearPanel": "Clear selection",
            "ClientPortal": "Client portal",
            "Close": "Close",
            "ClosestFirst": "Nearest property first",
            "CloseToGym": "Close to gym",
            "CloseToInternationalSchool": "Close to international school",
            "CloseToPark": "Close to park",
            "CloseToSchool": "Close to school",
            "CloseToTransport": "Close to transport",
            "ClubFacilities": "Club facilities",
            "Comments_label": "Enquiry",
            "CommentsRequiredValidation": "Please enter your enquiry",
            "Commercial": "Commercial \u0026 development land",
            "CommercialPlot": "Commercial plot",
            "CommercialPropertyType": "Commercial property type",
            "CommercialPropertyTypes": "Commercial property types",
            "CommercialSearchTag": "Commercial \u0026 development",
            "CommercialSearchTagNonUK": "Commercial property",
            "CommercialSize": "Property size",
            "Compound": "Compound",
            "Concierge": "Concierge",
            "Condominium": "Condominium",
            "ConferenceArea": "Conference area",
            "Confidential": "Confidential",
            "Confirm": "Confirm",
            "ConfirmNewPassword": "Confirm new password",
            "ConnectingToSP": "Logging you in to Savills Plus. Please wait...",
            "ConsentMessage": "Yes, I’d like to receive news, event info and exclusive offers from Savills.",
            "Contact": "Contact",
            "ContactAgent": "Contact agent",
            "ContactAgentClientIntroductionText": "Thank you for visiting our website. I wanted to confirm that your enquiry has been passed to {0} who will be in contact with you shortly. We attach brief particulars for your reference.",
            "ContactAgentClientSubject": "Thank you",
            "ContactAgentClientTitle": "Thank you",
            "ContactAgentDialogSuccess": "We have received your enquiry. {0} will be in contact within the next {1} hours.",
            "ContactAgentDialogSuccessMessage": "We will contact you shortly.",
            "ContactAgentDialogSuccessTitle": "Thank you for your enquiry.",
            "ContactAgentSavillsSubject": "Savills Global Search - Contact Agent",
            "ContactAgentSavillsTitle": "Thank You",
            "ContactAnAgent": "Contact an agent",
            "ContactNow": "Contact now",
            "ContactSavills": "Contact Savills",
            "ContactSavillsClientIntroductionText": "Thank you for visiting our website. We have forwarded your interest to the appropriate office who will be in contact with you with any privately available properties.",
            "ContactSavillsClientSignOff": "Yours sincerely,",
            "ContactSavillsClientSubject": "Thank you for contacting us",
            "ContactSavillsDialogSuccessMessage": "We will contact you shortly",
            "ContactSavillsDialogSuccessTitle": "Thank you",
            "ContactSavillsSavillsSubject": "Savills Global Search - Contact Savills",
            "Continent": "Continent",
            "ContinentPageHeaderBuy": "Property for sale in {0}",
            "ContinentPageHeaderRent": "Property to rent in {0}",
            "ContinuetoMyAccount": "Continue to My Account",
            "CookieInformation": "We use cookies to enhance your experience with Savills, including to show you more personalised content and tailored advertisements. ",
            "CookieInformationButton": "Allow all cookies",
            "CookieInformationMoreInfo": "Find out more",
            "Copy": "Copy",
            "CoSoleAgency": "Joint Sole Agent",
            "Cottage": "Cottage",
            "CountiesAndRegions": "Regions",
            "Countries": "Countries",
            "Country": "Country",
            "CountryName_gb": "UK",
            "CountryName_sg": "Singapore",
            "CountryName_vn": "Vietnam",
            "County": "County",
            "CreateAccount": "Create account",
            "CreatePrincipalEmailSubject": "Savills and property tracker - registration",
            "CurrenciesNotice": "*Currency rates are updated daily at approximately 01:00 GMT",
            "Currency": "Currency",
            "CurrentLanguage": "The current language is",
            "CurrentPassword": "Current password",
            "CustomArea": "custom area",
            "D1DevelopmentLand": "D1 development land",
            "D1Property": "D1 Property",
            "DairyFarm": "Dairy farm",
            "Date": "Preferred date (optional)",
            "DateValidation": "Please enter a valid date \u0026 time in the future",
            "Dear": "Dear",
            "December": "December",
            "Delete": "Delete",
            "DeleteSavedSearchConfirmationMessage": "Are you sure you want to delete this saved search?",
            "Description": "Description",
            "DetailPageMetaDescriptionBuy": "Property details for {0}. One of many properties for sale {1} from Savills, world leading estate agents.",
            "DetailPageMetaDescriptionBuy_ND": "Property details for {0}. One of many new homes for sale {1} from Savills, world leading estate agents.",
            "DetailPageMetaDescriptionRent": "Property details for {0}. One of many properties to rent {1} from Savills, world leading estate agents.",
            "DetailPageMetaDescriptionRent_ND": "Property details for {0}. One of many new homes to rent {1} from Savills, world leading estate agents.",
            "DetailPageTitle": "Savills | {0} | {1} ",
            "DetailPageTitleBuy": "Savills | {0} | {1} for sale",
            "DetailPageTitleBuy_ND": "Savills | {0} | New homes for sale",
            "DetailPageTitleRent": "Savills | {0} | {1} to rent",
            "DetailPageTitleRent_ND": "Savills | {0} | New homes to rent",
            "DetailPageTitleSimple": "Savills | {0}",
            "DetailsChangedMessage": "Your details have been updated.",
            "DetailUrlPart": "detail",
            "Development": "Development land",
            "DialogBoth": "Both",
            "DialogLet": "Let",
            "DialogMessage": "Please complete the form below and our agent will respond to your query shortly:",
            "DialogSell": "Sell",
            "DisabledFriendly": "Disabled friendly",
            "Distance": "Distance",
            "DownloadFloorPlans": "Download floor plans",
            "DownloadPDFBrochure": "Download PDF brochure",
            "Duplex": "Duplex",
            "EALicence": "EA Licence",
            "EditDetails": "Edit details",
            "Email_label": "Email address",
            "EmailAddress": "Email address",
            "EmailAddressInvalidValidation": "Please enter a valid email address",
            "EnergyText": "Energy performance",
            "Enquiry": "Enquiry",
            "EnterAddressManually": "Enter address manually",
            "EntertainmentVenue": "Entertainment venue",
            "EnterTownCountyPostcodeOrStation": "Enter country, town, county or postcode",
            "EPCGraph": "EPC Graph",
            "Equestrian": "Equestrian",
            "Error": "Sorry, an error occurred while processing your request.",
            "ErrorMessage_InvalidEmailPassword": "Invalid password",
            "ErrorMessage_InvalidPassword": "Please enter a valid password",
            "ErrorMessage_InvalidTelephone": "Please enter a valid telephone number",
            "ErrorMessage_LockedAccount": "Sorry, but you have entered incorrect details too many times and your account has been locked.",
            "ErrorMessage_MemberNotValidated": "Your account has not been verified. To verify use the link sent to your registered email address and follow the verification steps",
            "ErrorMessage_NoConfirmNewPassword": "Please confirm your new password",
            "ErrorMessage_NoEmailAddress": "Please enter your email address",
            "ErrorMessage_NoFirstName": "Please enter your first name",
            "ErrorMessage_NoMatchNewPasswords": "The two new passwords do not match. Please try again.",
            "ErrorMessage_NoMatchPasswords": "The two passwords do not match. Please try again.",
            "ErrorMessage_NoNewPassword": "Please enter your new password",
            "ErrorMessage_NoPassword": "Please enter your password",
            "ErrorMessage_NoPreferredLanguage": "Please select your preferred language",
            "ErrorMessage_NoSurname": "Please enter your surname",
            "ErrorMessage_ReEnterPassword": "Please re-enter your password",
            "ErrorMessage_UserAlreadyRegistered": "There is already a user registered with this email address.",
            "ErrorMessage_UserEmailDoesNotExist": "Your login details may be wrong. Please check your details or register a new account",
            "ErrorOccurredOnSendingEnquiry": "Sorry, an error occurred whilst trying to send your enquiry.",
            "ErrorOccurredOnSengingYourEmail": "Sorry, an error occurred whilst trying to send your email.",
            "ErrorPage": "Apologies, an error has occured. Please return to the home page",
            "ErrorPage404Body": "Sorry, but the page you are looking for has not been found. Try checking the URL for errors, then hit the refresh button on your browser.",
            "ErrorPage404Heading": "404: Page not found",
            "ErrorPage500Body": "{0} is currently down for scheduled maintenance and will be back up by {1}",
            "ErrorPage500Footer": "For urgent enquiries please contact our customer service team on {0}",
            "ErrorPage500Heading": "Our site is temporarily down for maintenance",
            "ErrorPageBody": "We're working on getting this fixed as soon as possible. In the meantime, try one of the links below to continue",
            "ErrorPageHeading": "Sorry, something went wrong",
            "Estate": "Estate",
            "ExcessOf": "Excess of",
            "ExecutiveCondominium": "Executive Condominium",
            "ExistingBuilding": "Existing building",
            "ExitDialogText": "This page will be shown in another language",
            "ExposedME": "Exposed M\u0026E",
            "ExtraCare": "Extra Care",
            "Farm": "Farm",
            "FarmAreaSize": "Acres/Hectares",
            "Farms": "Farms",
            "FarmsAndEstates": "Farms, estates \u0026 agricultural land",
            "FeaturedProperties": "Featured property",
            "FeaturedPropertiesFirst": "Featured properties",
            "Features": "Features",
            "February": "February",
            "Filter_LessThan": "Less than {0} {1}",
            "Filter_MarketTypes": "{0} Market type(s)",
            "Filter_MoreThan": "More than {0} {1}",
            "Filter_PropertyTypes": "{0} Property type(s)",
            "Filter_PropertyUses": "{0} Property use(s)",
            "Filter_Range": "{0} to {1} {2}",
            "Filter_Selected": "{0} selected",
            "FilterResults": "Filter results",
            "Filters": "Filters",
            "FilterSearch": "Filter search",
            "FindAddress": "Find address",
            "FindAProperty": "Find a property",
            "FindAPropertyUrlPart": "find-a-property",
            "FindingLocation": "Finding location",
            "FindOutMore": "find out more",
            "FindService": "Find a service",
            "Fireplace": "Fireplace",
            "FirstDayofWeek": "1",
            "FirstName": "First name",
            "Fishing": "Fishing",
            "Fitted": "Fitted",
            "FixedPrice": "Fixed price",
            "FlatApartment": "Flat / Apartment",
            "FlatOutsideCity": "Flat outside the city",
            "FlexibleFurnishing": "Flexible furnishing",
            "FlexSpace": "FlexSpace",
            "ForgottenPassword": "Forgotten password?",
            "ForgottenYourPassword": "Forgotten your password?",
            "ForgottenYourPasswordMessage": "No problem - just enter the email address you use for this account and we'll send you a email to reset it.",
            "ForSell": "For sell",
            "FreeOfCommission": "Free of commission",
            "Friday": "Friday",
            "FriendEmail_label": "Your friend's email address",
            "FriendEmailAddressInvalidValidation": "Please enter a valid email address to continue",
            "FriendName_label": "Your friend's name",
            "FriendNameRequiredValidaton": "Please enter your friend's name",
            "From": "Offers over",
            "FromWord": "from",
            "Furnished": "Furnished",
            "Furnishing": "Furnishing",
            "Gallery": "Gallery",
            "Garage": "Garage",
            "Garden": "Garden",
            "Global": "Global",
            "Golf": "Golf",
            "GoToHomepage": "Go to homepage",
            "Grade1": "Grade I",
            "Grade2": "Grade II",
            "GradeA": "Grade A",
            "GradeB": "Grade B",
            "GradeC": "Grade C",
            "GradePremium": "Premium",
            "GridUrlPart": "grid",
            "GridView": "Grid",
            "GrossArea": "Gross area",
            "GrossAreaAbbr": "G",
            "GRS_T_B": "buy",
            "GRS_T_R": "rent",
            "GuidePrice": "Guide price",
            "Gym": "Gym",
            "HalfKm": "half a km",
            "HalfMile": "half a mile",
            "HDBApartment": "HDB Apartment",
            "HeadMarketYourPropertyLink": "Market your property",
            "HeadMortgageAdviceLink": "Need mortgage advice?",
            "HeadSubscribeToResearchLink": "Subscribe to Research",
            "Healthcare": "Healthcare",
            "Hectares": "Hectares",
            "HelpersQuarters": "Staff Accommodation",
            "HelpToBuy": "Help To Buy",
            "Here": "Here",
            "Hide": "Hide",
            "HideMap": "Hide map",
            "HighestAcreageFirst": "Acreage - high to low",
            "HighestPriceFirst": "Highest price first",
            "HighestPropertySizeFirst": "Largest size first",
            "HighRiseBuilding": "High rise building",
            "HighStreet": "High Street",
            "Holidaycottagecomplex": "Holiday cottage complex",
            "Holidaypark": "Holiday park",
            "HomePageTitle": "Global Search",
            "Hotel": "Hotel",
            "Hotels": "Hotels",
            "HoursValidation": "Please enter valid hours",
            "House": "House",
            "HrefLangPropertyDetail_ar": "property-detail",
            "HrefLangPropertyDetail_cs": "property-detail",
            "HrefLangPropertyDetail_da": "ejendomsoplysninger",
            "HrefLangPropertyDetail_de": "detail",
            "HrefLangPropertyDetail_en": "property-detail",
            "HrefLangPropertyDetail_es": "detalle-inmueble",
            "HrefLangPropertyDetail_fr": "bien-immobilier-details",
            "HrefLangPropertyDetail_id": "detail-properti",
            "HrefLangPropertyDetail_it": "dettagli-immobile",
            "HrefLangPropertyDetail_jp": "property-detail",
            "HrefLangPropertyDetail_ko": "property-detail",
            "HrefLangPropertyDetail_ms": "butiran-harta-tanah",
            "HrefLangPropertyDetail_nl": "gegevens-onroerend-goed",
            "HrefLangPropertyDetail_no": "eiendoms-detalj",
            "HrefLangPropertyDetail_pl": "informacje-o-nieruchomosci",
            "HrefLangPropertyDetail_pt": "imovel-pormenor",
            "HrefLangPropertyDetail_pt-br": "detalhes-do-imovel",
            "HrefLangPropertyDetail_ru": "property-detail",
            "HrefLangPropertyDetail_sv": "fastighetsuppgifter",
            "HrefLangPropertyDetail_th": "property-detail",
            "HrefLangPropertyDetail_tr": "gayrimenkul-ayrinti",
            "HrefLangPropertyDetail_vi": "chi-tiet-bat-dong-san",
            "HrefLangPropertyDetail_x-default": "property-detail",
            "HrefLangPropertyDetail_zh-Hans": "property-detail",
            "HrefLangPropertyDetail_zh-Hant": "property-detail",
            "In": "in",
            "Industrial": "Industrial",
            "IndustrialPlot": "Industrial plot",
            "IndustrialTypes": "Industrial types",
            "Insight": "Insight \u0026 Opinion",
            "Investment": "Investment",
            "Investments": "Investments",
            "InYourSearchedArea": "in your searched area",
            "IrishCountryHome": "Country Homes",
            "Jacuzzi": "Jacuzzi",
            "January": "January",
            "July": "July",
            "June": "June",
            "KeyFeatures": "Key features",
            "Keyword": "Keyword",
            "KeywordBuyMetaDescription": "Property for sale containing the words  {0}  from Savills, world leading estate agents. From country estates to city apartments, your ideal property is just a click away.",
            "KeywordLabel": "Keyword",
            "KeywordPageTitleBuy": "Savills | Properties for sale containing the word(s)",
            "KeywordPageTitleRent": "Savills | Property to rent containing the word(s)",
            "KeywordPlaceholder": "Building name or street",
            "KeywordRentMetaDescription": "Property for rent containing the words  {0}  from Savills, world leading estate agents. From country estates to city apartments, your ideal property is just a click away.",
            "KeywordSearchPrompt": "Find properties that contain the word(s) \"{0}\"",
            "KeywordUrlPart": "keywords",
            "Kilometers": "Kilometers",
            "Land": "Land",
            "LandPlot": "Land plot",
            "Landscaping": "Landscaping",
            "LandSize": "Land size",
            "LargestSizeFirst": "Largest size first",
            "LastModifiedPropertiesFirst": "Most recent",
            "LastName": "Last name",
            "LateralLiving": "Lateral living",
            "LatestProperties": "Latest properties",
            "Launching": "Launching {0}",
            "LeadAgent": "Lead agent",
            "Leisure": "Leisure",
            "Let": "Let",
            "LetType": "Let type",
            "LicenseNo": "Licence no",
            "Lifestyle": "Lifestyle",
            "LifeStyleMetaDescription": "Property for sale in the world from Savills, world leading estate agents. From country estates to city apartments, your ideal property is just a click away.",
            "LifeStyleOptions": "Lifestyle options",
            "LifeStylePageTitleBuy": "Savills | Property for sale in the world",
            "LifeStylePageTitleRent": "Savills | Property to rent in the world",
            "LifeStyleRentMetaDescription": "Property to rent in the world from Savills, world leading estate agents. From country estates to city apartments, your ideal property is just a click away.",
            "Lift": "Lift",
            "LinkPrincipalEmailSubject": "Savills - Property Tracker upgrade",
            "List": "List",
            "ListBuyMetaDescription": "{0} for sale in {1} from Savills, world leading estate agents. From country estates to city apartments, your ideal property is just a click away.",
            "ListBuyMetaDescriptionCommercial": "{0} for sale in {1} from Savills, one of the leading commercial property agents globally.",
            "ListHeaderKeywords": "\u003cspan class=\"sv--value\"\u003e{0}\u003c/span\u003e {1} containing the word(s) \u003cspan class=\"sv--location\"\u003e{2}\u003c/span\u003e",
            "ListHeaderMultipleLocations": "\u003cspan class=\"sv--value\"\u003e{0}\u003c/span\u003e {1} in \u003cspan class=\"sv--location\"\u003e{2}\u003c/span\u003e",
            "ListHeaderNoPropertiesLifestyle": "We are sorry we currently have no {0} in the world.",
            "ListHeaderNoResults": "We are sorry we currently have no {0} that match your search criteria. Please choose an alternative location or widen your search criteria.",
            "ListHeaderNoResultsGeneralMessage": "We are sorry but your search has returned no publicly available results at this time.",
            "ListHeaderNoResultsGeneralMessageLinkText": "Please contact us for off-book properties",
            "ListHeaderNoResultsWithRefineSearch": "We are sorry we currently have no {0} that match your search criteria.",
            "ListHeaderOffice": "\u003cspan class=\"sv--value\"\u003e{0}\u003c/span\u003e {1} from \u003cspan class=\"sv--office\"\u003e{2}\u003c/span\u003e",
            "ListHeaderOfficeNoProperties": "We are sorry we currently have no {0} from {1}",
            "ListHeaderPolygon": "\u003cspan class=\"sv--value\"\u003e{0}\u003c/span\u003e {1} in \u003cspan class=\"sv--location\"\u003e{2}\u003c/span\u003e",
            "ListHeaderRadial": "\u003cspan class=\"sv--value\"\u003e{0}\u003c/span\u003e {1} {2} \u003cspan class=\"sv--distance\"\u003e{3}\u003c/span\u003e of \u003cspan class=\"sv--location\"\u003e{4}\u003c/span\u003e",
            "ListPageTitleBuy": "{0} for sale in {1} | Savills",
            "ListPageTitleBuyResidential": "Properties for sale in {0} | Homes for sale | Savills",
            "ListPageTitleRent": "{0} to rent in {1} | Savills",
            "ListPageTitleRentResidential": "Properties to rent in {0} | Homes to rent | Savills",
            "ListRentMetaDescription": "{0} to rent in {1} from Savills, world leading estate agents. From country estates to city apartments, your ideal property is just a click away.",
            "ListRentMetaDescriptionCommercial": "{0} to let in {1} from Savills, one of the leading commercial property agents globally.",
            "ListUrlPart": "list",
            "ListViewTitle_Plural_GRS_CPT_BP": "Business Park properties",
            "ListViewTitle_Plural_GRS_CPT_D": "Development properties",
            "ListViewTitle_Plural_GRS_CPT_FS": "FlexSpace units",
            "ListViewTitle_Plural_GRS_CPT_H": "Healthcare properties",
            "ListViewTitle_Plural_GRS_CPT_HO": "Hotels",
            "ListViewTitle_Plural_GRS_CPT_I": "Industrial properties",
            "ListViewTitle_Plural_GRS_CPT_IN": "Investment properties",
            "ListViewTitle_Plural_GRS_CPT_L": "Leisure properties",
            "ListViewTitle_Plural_GRS_CPT_LAN": "Land",
            "ListViewTitle_Plural_GRS_CPT_O": "Offices",
            "ListViewTitle_Plural_GRS_CPT_OC": "Other commercial properties",
            "ListViewTitle_Plural_GRS_CPT_R": "Retail properties",
            "ListViewTitle_Plural_GRS_CPT_SH": "Shophouses",
            "ListViewTitle_Plural_GRS_CPT_SNL": "Senior living properties",
            "ListViewTitle_Plural_GRS_CPT_SO": "Serviced offices",
            "ListViewTitle_Plural_GRS_FPT_AF": "Arable farms",
            "ListViewTitle_Plural_GRS_FPT_BL": "Bare lands",
            "ListViewTitle_Plural_GRS_FPT_DF": "Dairy farms",
            "ListViewTitle_Plural_GRS_FPT_EQ": "Equestrian properties",
            "ListViewTitle_Plural_GRS_FPT_F": "Farms",
            "ListViewTitle_Plural_GRS_FPT_FSH": "Fishing estates",
            "ListViewTitle_Plural_GRS_FPT_LF": "Livestock farms",
            "ListViewTitle_Plural_GRS_FPT_RFE": "Estates",
            "ListViewTitle_Plural_GRS_FPT_S": "Sporting estates",
            "ListViewTitle_Plural_GRS_FPT_VY": "Vineyards",
            "ListViewTitle_Plural_GRS_FPT_W": "Woodlands",
            "ListViewTitle_Plural_GRS_PT_APT": "Flats",
            "ListViewTitle_Plural_GRS_PT_B": "Bungalows",
            "ListViewTitle_Plural_GRS_PT_BP": "Building plots",
            "ListViewTitle_Plural_GRS_PT_C": "Compounds",
            "ListViewTitle_Plural_GRS_PT_CAST": "Castles / Chateaux",
            "ListViewTitle_Plural_GRS_PT_CHLT": "Chalets",
            "ListViewTitle_Plural_GRS_PT_CONDO": "Condominiums",
            "ListViewTitle_Plural_GRS_PT_CTTG": "Cottages",
            "ListViewTitle_Plural_GRS_PT_D": "Duplex",
            "ListViewTitle_Plural_GRS_PT_ECONDO": "Executive condominiums",
            "ListViewTitle_Plural_GRS_PT_EST": "Estates",
            "ListViewTitle_Plural_GRS_PT_FOC": "Flat outside the city",
            "ListViewTitle_Plural_GRS_PT_H": "Houses",
            "ListViewTitle_Plural_GRS_PT_HDB": "HDB apartments",
            "ListViewTitle_Plural_GRS_PT_HRB": "High rise buildings",
            "ListViewTitle_Plural_GRS_PT_HTB": "Help To Buy properties",
            "ListViewTitle_Plural_GRS_PT_LP": "Land plots",
            "ListViewTitle_Plural_GRS_PT_M": "Mews",
            "ListViewTitle_Plural_GRS_PT_MRB": "Middle rise buildings",
            "ListViewTitle_Plural_GRS_PT_ND": "New homes",
            "ListViewTitle_Plural_GRS_PT_PENT": "Penthouses",
            "ListViewTitle_Plural_GRS_PT_SAPT": "Serviced apartments",
            "ListViewTitle_Plural_GRS_PT_SNRL": "Senior living properties",
            "ListViewTitle_Plural_GRS_PT_SOW": "Shared ownership properties",
            "ListViewTitle_Plural_GRS_PT_STU": "Studios",
            "ListViewTitle_Plural_GRS_PT_T": "Triplex",
            "ListViewTitle_Plural_GRS_PT_TH": "Townhouses",
            "ListViewTitle_Plural_GRS_PT_TUA": "Temporary use apartments",
            "ListViewTitle_Plural_GRS_PT_V": "Villas",
            "ListViewTitle_Plural_GRS_PT_VY": "Vineyards",
            "ListViewTitle_Single_GRS_CPT_BP": "Business Park property",
            "ListViewTitle_Single_GRS_CPT_D": "Development property",
            "ListViewTitle_Single_GRS_CPT_FS": "FlexSpace unit",
            "ListViewTitle_Single_GRS_CPT_H": "Healthcare property",
            "ListViewTitle_Single_GRS_CPT_HO": "Hotel",
            "ListViewTitle_Single_GRS_CPT_I": "Industrial property",
            "ListViewTitle_Single_GRS_CPT_IN": "Investment property",
            "ListViewTitle_Single_GRS_CPT_L": "Leisure property",
            "ListViewTitle_Single_GRS_CPT_LAN": "Land",
            "ListViewTitle_Single_GRS_CPT_O": "Office",
            "ListViewTitle_Single_GRS_CPT_R": "Retail property",
            "ListViewTitle_Single_GRS_CPT_SH": "Shophouse",
            "ListViewTitle_Single_GRS_CPT_SNL": "Senior living property",
            "ListViewTitle_Single_GRS_CPT_SO": "Serviced office",
            "ListViewTitle_Single_GRS_FPT_AF": "Arable farm",
            "ListViewTitle_Single_GRS_FPT_BL": "Bare land",
            "ListViewTitle_Single_GRS_FPT_DF": "Dairy farm",
            "ListViewTitle_Single_GRS_FPT_EQ": "Equestrian property",
            "ListViewTitle_Single_GRS_FPT_F": "Farm",
            "ListViewTitle_Single_GRS_FPT_FSH": "Fishing estate",
            "ListViewTitle_Single_GRS_FPT_LF": "Livestock farm",
            "ListViewTitle_Single_GRS_FPT_RFE": "Residential farm estate",
            "ListViewTitle_Single_GRS_FPT_S": "Sporting estate",
            "ListViewTitle_Single_GRS_FPT_VY": "Vineyard",
            "ListViewTitle_Single_GRS_FPT_W": "Woodland",
            "ListViewTitle_Single_GRS_PT_APT": "Flat",
            "ListViewTitle_Single_GRS_PT_B": "Bungalow",
            "ListViewTitle_Single_GRS_PT_BP": "Building plot",
            "ListViewTitle_Single_GRS_PT_C": "Compound",
            "ListViewTitle_Single_GRS_PT_CAST": "Castle / Chateau",
            "ListViewTitle_Single_GRS_PT_CHLT": "Chalet",
            "ListViewTitle_Single_GRS_PT_CONDO": "Condominium",
            "ListViewTitle_Single_GRS_PT_CTTG": "Cottage",
            "ListViewTitle_Single_GRS_PT_D": "Duplex",
            "ListViewTitle_Single_GRS_PT_ECONDO": "Executive condominium",
            "ListViewTitle_Single_GRS_PT_EST": "Estate",
            "ListViewTitle_Single_GRS_PT_FOC": "Flat outside the city",
            "ListViewTitle_Single_GRS_PT_H": "House",
            "ListViewTitle_Single_GRS_PT_HDB": "HDB apartment",
            "ListViewTitle_Single_GRS_PT_HRB": "High rise building",
            "ListViewTitle_Single_GRS_PT_HTB": "Help To Buy property",
            "ListViewTitle_Single_GRS_PT_LP": "Land plot",
            "ListViewTitle_Single_GRS_PT_M": "Mews",
            "ListViewTitle_Single_GRS_PT_MRB": "Middle rise building",
            "ListViewTitle_Single_GRS_PT_ND": "New home",
            "ListViewTitle_Single_GRS_PT_PENT": "Penthouse",
            "ListViewTitle_Single_GRS_PT_SAPT": "Serviced apartment",
            "ListViewTitle_Single_GRS_PT_SNRL": "Senior living properties",
            "ListViewTitle_Single_GRS_PT_SOW": "Shared ownership property",
            "ListViewTitle_Single_GRS_PT_STU": "Studio",
            "ListViewTitle_Single_GRS_PT_T": "Triplex",
            "ListViewTitle_Single_GRS_PT_TH": "Townhouse",
            "ListViewTitle_Single_GRS_PT_TUA": "Temporary use apartment",
            "ListViewTitle_Single_GRS_PT_V": "Villa",
            "ListViewTitle_Single_GRS_PT_VY": "Vineyard",
            "LivestockFarm": "Livestock farm",
            "Loading": "Loading",
            "LocalInformation": "Local information",
            "LocalKnowledge": "Local knowledge",
            "LocalMarket": "Local Market",
            "Location": "Location",
            "LocationBoxPlaceHolder": "Search by location, postcode or keyword",
            "LocationNotFound": "Sorry we couldn't find your location.",
            "LoggedOut": "Logged out",
            "Login": "Login",
            "LoginFailure": "Login attempt failed",
            "LoginOrCreateAccount": "Log in or create account",
            "LoginOrCreateAccountMessage": "Enter your details to log in or create a new user account",
            "LoginToYourAccount": "Login to your account",
            "LoginUsingNewPassword": "You can now log in using your new password.",
            "Logistic": "Logistic",
            "LogOut": "Logout",
            "LongTerm": "Long term",
            "LowestAcreageFirst": "Acreage - low to high",
            "LowestPriceFirst": "Lowest price first",
            "LowestPropertySizeFirst": "Smallest size first",
            "Managed": "Managed",
            "ManagedBySavillsFlag": "Managed by Savills",
            "Map": "Map",
            "MapUrlPart": "map",
            "March": "March",
            "Marina": "Marina",
            "MarketAppraisalClientIntroductionText": "Thank you for visiting our website. We have forwarded your request for a market appraisal to the appropriate office who will be in contact with you shortly.",
            "MarketAppraisalClientSignOff": "Yours sincerely,",
            "MarketAppraisalClientSubject": "Thank you for requesting a market appraisal",
            "MarketAppraisalDialogMessage": "Please complete the form below and one of our local property experts will be in touch shortly to arrange your free market appraisal.",
            "MarketAppraisalDialogSuccess": "We have received your details and a member of the Savills team will be in contact within the next {0} hours to arrange your market appraisal.",
            "MarketAppraisalSavillsSubject": "Savills Global Search - Market Appraisal",
            "MarketAProperty": "Market your property",
            "MarketPropertyBannerBodyCOM": "Book a market appraisal for your property today. Our virtual options are still available if you prefer.",
            "MarketPropertyBannerBodyFES": "Book a market appraisal for your property today. Our virtual options are still available if you prefer.",
            "MarketPropertyBannerBodyRES": "Book a market appraisal for your property today. Our virtual options are still available if you prefer.",
            "MarketPropertyBannerHeading": "Market your property with Savills",
            "MarketPropertyBannerHeadingCOM": "Market your property with Savills",
            "MarketPropertyBannerHeadingFES": "Market your property with Savills",
            "MarketPropertyBannerHeadingRES": "Market your property with Savills",
            "MarketType": "Market type",
            "MarketTypes": "Market types",
            "MarketYourPropertyActionLabel": "Book now",
            "MarketYourPropertyActionLabelCOM": "Book now",
            "MarketYourPropertyActionLabelFES": "Book now",
            "MarketYourPropertyActionLabelRES": "Book now",
            "MarketYourPropertyBody": "Book a market appraisal for your property today. Our virtual options are still available if you prefer.",
            "Max": "max",
            "Maximum": "maximum",
            "MaxPrice": "Max price",
            "May": "May",
            "Mem": "",
            "MemberDeleted": "Member deleted",
            "MemberDeletedConfirmationMessage": "Are you sure you want to remove your Savills+ account?",
            "MemberDeletedMessage": "The member was deleted successfully",
            "MemberNotDeleted": "Member not deleted",
            "MemberNotDeletedMessage": "Sorry, but the member could not be deleted",
            "MemberNotExists": "Member does not exist",
            "MemberNotExistsMessage": "Sorry, but the member does not exist",
            "Message_label": "Personal message",
            "MessageRequiredValidation": "Please enter your personal message",
            "METADescriptionDefault": "Savills estate agents have over 100 offices throughout the United Kingdom, and over 600 offices and associates throughout Europe, Asia Pacific, Africa and the Middle East. Our unrivalled network means we can offer property for sale that meets your requirements anywhere in the world. We sell everything from houses and flats to farms and estates.",
            "METADescriptionIn": "{0} in {1} from Savills, one of the leading commercial property agents globally",
            "METADescriptionNear": "{0} near {1} from Savills, one of the leading commercial property agents globally",
            "Metropolitan": "Metropolitan",
            "Mews": "Mews",
            "Mezzanine": "Mezzanine",
            "MiddleRiseBuilding": "Middle rise building",
            "Mile": "mile",
            "Miles": "miles",
            "Min": "min",
            "MinBathrooms": "Min bathrooms",
            "MinBedrooms": "Min bedrooms",
            "MinCarSpaces": "Min parking",
            "Minimum": "minimum",
            "MinPrice": "Min price",
            "MinReceptions": "Min receptions",
            "MinutesValidation": "Please enter valid minutes",
            "Mixed": "Mixed",
            "MixedUse": "Mixed Use",
            "Monday": "Monday",
            "Monthly": "Monthly",
            "More": "More",
            "MostPopularSearches": "Here are some of our most popular searches.",
            "MultipleLocationsMessage": "Multiple locations not allowed.",
            "MultipleLocationsTitle": "Multiple locations",
            "MultipleMarketTypes": "Multiple market types",
            "MultiplePropertyTypes": "Multiple property types",
            "MultiplePropertyuses": "Multiple property uses",
            "MyAccount": "My account",
            "MySavedProperties": "My saved properties",
            "MySearches": "My searches",
            "Name_label": "Name",
            "NameRequiredValidaton": "Please enter your name",
            "NearestPropertiesFirst": "Nearest property first",
            "NearTo": "Near to",
            "NewDevelopment": "New development",
            "NewDevelopmentViewAllProperties": "View all {0} properties",
            "NewPassword": "New password",
            "NewPasswordMailSent": "We have sent you an email. Please click on the link in the email in order to change your password.",
            "Next": "Next",
            "NextProperty": "Next property",
            "No": "No",
            "NoAccount": "No account?",
            "NoFilterOptionsAvailableForLocation": "There are no filter options available for your current location",
            "NoFiltersAvailable": "No filters available for this criteria",
            "NoLocationSelected": "Please enter location before actioning your search",
            "NoMax": "No max",
            "NoMin": "No min",
            "NoPropertiesFoundMessage": "Sorry, we don't currently have any properties that match your location.",
            "NoResultsBody": "Please alter the filters or use the map to explore other locations",
            "NoResultsFooter": "This icon indicates properties that match your criteria outside your search location",
            "NoResultsHeader": "Your search didn't return any results",
            "NoResultsXNearBy": "We couldn't find any results in your search area but there are {0} results nearby",
            "NoSavedProperties": "Looks like you haven't saved any properties yet.",
            "NoSavedSearches": "Looks like you haven't saved any searches yet.",
            "NotAdded": "Not added",
            "Note": "Note",
            "NotLoggedIn": "You must be logged in to do this",
            "November": "November",
            "Number2AsText": "Two",
            "Number3AsText": "Three",
            "Number4AsText": "Four",
            "NumberOfBedrooms": "Number of bedrooms",
            "NumberofPeople": "Number of people",
            "NumBn": "bn",
            "NumK": "k",
            "NumM": "m",
            "NursingHome": "Nursing home",
            "October": "October",
            "Of": "of",
            "OffersInvited": "Offers in excess of",
            "Office": "Office",
            "OfficeBuyMetaDescription": "Property for sale from Savills {0}, world leading estate agents. From country estates to city apartments, your ideal property is just a click away.",
            "OfficeGrade": "Grade of space",
            "OfficePageTitleBuy": "Savills | Property for sale from {0}",
            "OfficePageTitleRent": "Savills | Property to rent from {0}",
            "OfficeRentMetaDescription": "Property to rent from Savills {0}, world leading estate agents. From country estates to city apartments, your ideal property is just a click away.",
            "OffStreetParking": "Off-street parking",
            "OneLowerCaseCharacter": "One lower case character",
            "OneNumericalCharacter": "One numerical character",
            "OneSpecialCharacter": "One special character",
            "OneUpperCaseCharacter": "One upper case character",
            "OnRequest": "Price on request",
            "OpenHouse": "Open house",
            "OpenMarket": "Open Market",
            "Optional": "optional",
            "OrderBy": "Order by",
            "OrEnterAddressManually": "or enter address manually",
            "OtherCommercial": "Other commercial",
            "OtherFees": "Fees apply",
            "OtherFeesMay": "Other fees may also apply",
            "OtherFeesShort": "Fees apply",
            "Othertradingproperty": "Other trading property",
            "OwnershipType": "Ownership type",
            "PageNotFound": "Page not found",
            "PageSize": "Page size",
            "PageTitle_GRS_CPT_BP": "Business park properties",
            "PageTitle_GRS_CPT_D": "Development land",
            "PageTitle_GRS_CPT_FS": "FlexSpace",
            "PageTitle_GRS_CPT_H": "Healthcare properties ",
            "PageTitle_GRS_CPT_HO": "Hotels",
            "PageTitle_GRS_CPT_I": "Industrial properties",
            "PageTitle_GRS_CPT_IN": "Investment properties",
            "PageTitle_GRS_CPT_L": "Leisure properties",
            "PageTitle_GRS_CPT_LAN": "Lands",
            "PageTitle_GRS_CPT_O": "Offices",
            "PageTitle_GRS_CPT_OC": "Other commercial properties",
            "PageTitle_GRS_CPT_R": "Retail properties",
            "PageTitle_GRS_CPT_SH": "Shophouse",
            "PageTitle_GRS_CPT_SNL": "Senior living properties",
            "PageTitle_GRS_CPT_SO": "Serviced offices",
            "PageTitle_GRS_FPT_AF": "Arable farms",
            "PageTitle_GRS_FPT_BL": "Bare land",
            "PageTitle_GRS_FPT_DF": "Dairy farms",
            "PageTitle_GRS_FPT_EQ": "Equestrian properties",
            "PageTitle_GRS_FPT_F": "Farms",
            "PageTitle_GRS_FPT_FSH": "Fishing estates",
            "PageTitle_GRS_FPT_LF": "Livestock farms",
            "PageTitle_GRS_FPT_RFE": "Estates",
            "PageTitle_GRS_FPT_S": "Sporting estates",
            "PageTitle_GRS_FPT_VY": "Vineyards",
            "PageTitle_GRS_FPT_W": "Woodlands",
            "PageTitle_GRS_PT_APT": "Flats",
            "PageTitle_GRS_PT_B": "Bungalows",
            "PageTitle_GRS_PT_BP": "Building plots",
            "PageTitle_GRS_PT_CAST": "Castles",
            "PageTitle_GRS_PT_CHLT": "Chalet",
            "PageTitle_GRS_PT_CONDO": "Condominium",
            "PageTitle_GRS_PT_CTTG": "Cottages",
            "PageTitle_GRS_PT_D": "Duplex properties",
            "PageTitle_GRS_PT_ECONDO": "Executive condominium",
            "PageTitle_GRS_PT_EST": "Estates",
            "PageTitle_GRS_PT_FOC": "Flats outside the city",
            "PageTitle_GRS_PT_H": "Houses",
            "PageTitle_GRS_PT_HDB": "HDB apartments",
            "PageTitle_GRS_PT_HRB": "High rise buildings",
            "PageTitle_GRS_PT_HTB": "Help To Buy properties",
            "PageTitle_GRS_PT_M": "Mews properties",
            "PageTitle_GRS_PT_MRB": "Middle rise buildings",
            "PageTitle_GRS_PT_ND": "New homes",
            "PageTitle_GRS_PT_PENT": "Penthouses",
            "PageTitle_GRS_PT_S": "Compounds",
            "PageTitle_GRS_PT_SAPT": "Serviced apartments",
            "PageTitle_GRS_PT_SNRL": "Senior living properties",
            "PageTitle_GRS_PT_SOW": "Shared ownership properties",
            "PageTitle_GRS_PT_STU": "Studios",
            "PageTitle_GRS_PT_T": "Triplex",
            "PageTitle_GRS_PT_TH": "Townhouse",
            "PageTitle_GRS_PT_TUA": "Temporary use apartments",
            "PageTitle_GRS_PT_V": "Villa",
            "PageTitle_GRS_PT_VY": "Vineyards",
            "PageTitle_Property": "Property",
            "PanoramicView": "Panoramic view",
            "Parking": "Parking",
            "PartFurnished": "Part furnished",
            "Password": "Password",
            "PasswordChangedMessage": "Your password has been reset.",
            "PasswordTooltip": "Passwords should contain a minimum of 8 characters, with a mixture of numbers and letters, including at least 1 capital letter",
            "Penthouse": "Penthouse",
            "People": "people",
            "PerDesk": "Price per desk",
            "Period": "Period",
            "PerMonth": "per month",
            "PerMonthFilter": "Per month",
            "Person": "person",
            "PerSqFt": "per sq ft",
            "PerSqMt": "per sq m",
            "PerWeek": "per week",
            "PerWeekFilter": "Per week",
            "PerYear": "per year",
            "PerYearFilter": "Per year",
            "PetFriendly": "Pet-friendly",
            "Phone": "Phone",
            "PhoneNumber": "Phone number",
            "PhoneNumberInvalidValidation": "Please enter a valid phone number",
            "Ping": "Ping",
            "PlanningPermission": "Planning permission",
            "Plans": "Plans",
            "PleaseAddLocation": "Please add location to see filter options",
            "PleaseGoToMainSite": "This site is in version alpha. Please go to the main site",
            "PleaseScrollHorizontally": "Please scroll horizontally to view all table data",
            "PleaseSelect": "Please select",
            "PleaseSelectAllMarketTypesThatApply": "Please select all market types that apply",
            "PleaseSelectAllOwnerShipTypesThatApply": "Please select all ownership types that apply",
            "PleaseSelectAllPropertyTypesThatApply": "Please select all property types that apply",
            "PleaseSelectAllPropertyUsageOptionsThatApply": "Please select all property usage options that apply",
            "PleaseSelectTheRangeOfPeople": "Please select the range of people using the property",
            "PleaseSetYourMinimumAmountofBedrooms": "Please set minimum amount of bedrooms required",
            "PleaseType": "Please type...",
            "PleaseWait": "Please wait ...",
            "PopularLocations": "Popular locations",
            "Portfolio": "Portfolio",
            "Postcode": "Postcode",
            "PostcodesZipCodes": "Postcodes \u0026 Zipcodes",
            "PreferredDate": "Preferred date (optional)",
            "PreferredLanguage": "Preferred language",
            "PreferredTime": "Preferred time (optional)",
            "Premium": "P",
            "Prev": "Prev",
            "Previous": "Previous",
            "PreviousProperty": "Previous property",
            "Price": "Price",
            "PriceOnApplication": "Price on application",
            "PriceRange": "Price range",
            "PriceXtoY": "{0} to {1}",
            "PrivacyPolicy": "Privacy \u0026 Cookies",
            "PrivacyStatementBody": "Savills is a trading name of Savills plc. Savills is committed to ensuring that your privacy is protected. This privacy policy explains how we use the information we collect about you, how you can instruct us if you prefer to limit the use of that information and procedures that we have in place to safeguard your privacy.",
            "PrivacyStatementTitle": "Privacy statement",
            "PrivateAreaLoginButton": "Clients' Private Area Login",
            "PrivateAreaLoginIntro": "If you're looking for our Savills Clients' Private Area, please login below",
            "Processing": "Processing",
            "Production": "Production",
            "Properties": "Properties",
            "PropertiesForSale": "{0} for sale",
            "PropertiesInThisDevelopment": "Properties in this development",
            "PropertiesLet": "{0} let",
            "PropertiesPerPage": "Properties per page",
            "PropertiesPerPage12": "12 properties per page",
            "PropertiesPerPage16": "16 properties per page",
            "PropertiesPerPage20": "20 properties per page",
            "PropertiesPerPage8": "8 properties per page",
            "PropertiesSold": "{0} sold",
            "PropertiesThatMatchYourCriteriaWorldwide": "Newest properties that match your criteria ",
            "PropertiesToRent": "{0} for let and under offer",
            "Property": "property",
            "PropertyAgent": "Property agent",
            "PropertyAlerts": "Property alerts",
            "PropertyDetail": "Property detail",
            "PropertyDetails": "Property details",
            "PropertyDetailUrlPart": "property-detail",
            "PropertyFeatures": "Property features",
            "PropertyforSaleFromThisOffice": "Property for sale from this office",
            "PropertyHistory": "Further information",
            "PropertyNotAvailable_GRS_T_B": "Sorry, the property you looked for is no longer available. Below are similar properties for sale in {0}.",
            "PropertyNotAvailable_GRS_T_R": "Sorry, the property you looked for is no longer available. Below are similar properties to rent in {0}.",
            "PropertyOutsideDefinedArea": "This property is outside your defined search area. Expand your search radius to include more properties.",
            "PropertyReferenceNumber": "Property reference number",
            "PropertySearch": "Property search",
            "PropertySearchTagLine": "What kind of property are you looking for?",
            "PropertyStatusAvailable": "Available",
            "PropertyStatusLet": "Let",
            "PropertyStatusNew": "New",
            "PropertyStatusRecentlyLet": "Recently let",
            "PropertyStatusRecentlySold": "Recently sold",
            "PropertyStatusReduced": "Reduced",
            "PropertyStatusReserved": "Reserved",
            "PropertyStatusSold": "Sold",
            "PropertyStatusUnderOffer": "Under offer",
            "PropertySubType": "Property sub-type",
            "PropertyToRentFromThisOffice": "Property to rent from this office",
            "PropertyType": "Property type",
            "PropertyTypes": "Property types",
            "PropertyTypesForRentIn": "{0} for rent in {1}",
            "PropertyTypesForSaleIn": "{0} for sale in {1}",
            "PropertyTypesToLetIn": "{0} to let in {1}",
            "PropertyUses": "Property use",
            "RaisedAccessFloors": "Raised access floors",
            "ReadMore": "Read more",
            "ReadTheAreaGuide": "Read the area guide",
            "RecentLocations": "Recent locations",
            "RecentlyLetProperties": "Recently let properties",
            "RecentlySoldProperties": "Recently sold properties",
            "Reception": "Reception",
            "ReceptionFilterDescription": "Please set your minimum amount of receptions required",
            "Receptions": "Receptions",
            "Receptions1": "1",
            "Receptions2": "2",
            "Receptions3": "3",
            "Receptions4": "4",
            "Receptions5": "5",
            "Receptions6": "6",
            "ReceptionsCount": "{0} reception rooms",
            "ReceptionsCountSingle": "1 reception room",
            "ReEnterPassword": "Re-enter your password",
            "Region": "Region",
            "RegionOf": "Offers in the region of",
            "RegionsCountiesCountries": "Regions, counties \u0026 countries",
            "Register": "Register",
            "RegisterDescription1": "Personalise your Savills experience.",
            "RegisterDescription2": "Save searches and properties.",
            "RegisterDescription3": "Register an account today.",
            "RegisterWithSavillsPlus": "Register a new account",
            "RegistrationEmail": "Registration email",
            "RegistrationEmailIntroductionText": "Thank you for registering for property alerts from Savills.\u003cbr/\u003e\u003cbr/\u003e\u003cbr/\u003eYou will receive email updates with properties which match your search criteria and you can log in at any time to view properties you have saved.\u003cbr/\u003e\u003cbr/\u003eIf you haven’t been able to find exactly what you are looking for on our website, we would be delighted to talk to you about your search, as we also have a number of properties available privately. If you would like to speak to one of our agents, please contact our team on +44 20 7877 4640 or \u003ca conversion=\"false\" alias=\"get in contact\" title=\"get in contact\" href=\"mailto:clientservices@savills.com\"\u003eclientservices@savills.com\u003c/a\u003e who will be able to help you whereever you are.\u003cbr/\u003e\u003cbr/\u003eOur local, national and global network means we can show you a very broad range of property and help you find what you are looking for.\u003cbr/\u003e\u003cbr/\u003eIf you did not request to register this email address with Savills, please click {0}here{1}.",
            "RegistrationEmailSubject": "Savills - registration",
            "RegistrationMessage": "An email has been sent to the address you registered with. Please click on the link in the email to confirm your registration with Savills Plus.",
            "RegistrationNotValidated": "Registration not validated",
            "RegistrationNotValidatedMessage": "Sorry, but your validation code was not recognised.",
            "RegistrationValidated": "Registration validated",
            "RegistrationValidatedMessage": "Thank you. Your registration has been confirmed. If this page does not refresh shortly, please click",
            "RelatedArticles": "Related articles",
            "RelatedProperties": "Related properties",
            "Remove": "Remove",
            "RemoveSearch": "Remove search",
            "Rent": "Rent",
            "RentingBusiness": "Renting business",
            "RequiredFields": "Required fields",
            "ResetMap": "Reset map to original view",
            "ResetMyPassword": "Reset my password",
            "ResetPassword": "Reset password",
            "ResetPasswordEmailIntroductionText": "Please click on the link below to verify your email address, after which you will be able to create a new password.\u003cbr/\u003e\u003cbr/\u003e{0}Change password{1}",
            "ResetPasswordEmailSubject": "Savills - reset password",
            "ResetPasswordNotValidated": "Reset password not validated",
            "ResetPasswordNotValidatedMessage": "Sorry, but your validation code was not recognised.",
            "ResetPasswordValidated": "Reset password validated",
            "ResetPasswordValidatedMessage": "Thank you. Your password reset has been confirmed. If this page does not refresh shortly, please click",
            "ResetYourPassoword": "Reset your password",
            "Residential": "Residential \u0026 New Developments",
            "ResidentialFarmEstate": "Residential farm/estate",
            "Residentialmobilehomepark": "Residential mobile home park",
            "ResidentialSearchTag": "Residential \u0026 New Homes",
            "RestaurantsPubBars": "Restaurants/Pub/Bars",
            "Retail": "Retail",
            "RetailPark": "Retail park",
            "Retirement": "Retirement",
            "ReturnTo": "Return to {0}",
            "ReturnToListing": "Return to listing",
            "Road": "Road",
            "Roads": "Roads",
            "RoofTerrace": "Roof terrace",
            "RSSCategory": "Global Property Search",
            "RSSDescription": "RSS Feed",
            "RSSTitle": "Savills - Global Property Search",
            "RuralSearchTag": "Farms, estates \u0026 agricultural land",
            "SaleableArea": "Saleable area",
            "SaleableAreaAbbr": "S",
            "Satellite": "Satellite",
            "Saturday": "Saturday",
            "Save": "Save",
            "Saved": "saved",
            "SavedProperties": "Saved properties",
            "SavedSearchEmailSubject": "New saved search",
            "SavedSearches": "Saved searches",
            "SavedViewNoProperties": "You currently don't have any properties saved",
            "SaveRSS": "Save RSS",
            "SaveSearch": "Save search",
            "SavillsAgents": "Savills agents",
            "School": "School",
            "Schools": "Schools",
            "Search": "Search",
            "SearchAgain": "Search again",
            "SearchDeleted": "Search deleted",
            "SearchDescription_COM": "{3} {0} {4} {1} {2}",
            "SearchDescription_For": "for",
            "SearchDescription_From": "from",
            "SearchDescription_In": "in",
            "SearchDescription_Let": "let",
            "SearchDescription_Near": "near",
            "SearchDescription_On": "on",
            "SearchDescription_Rent": "rent",
            "SearchDescription_RES": "{3} {0} for {1} {2}",
            "SearchDescription_Sale": "sale",
            "SearchDescription_SearchedArea": "Searched area",
            "SearchDescription_To": "to",
            "SearchForProperty": "Search for property",
            "SearchNearMe": "Search near me",
            "SearchProperties": "Search properties",
            "SearchRegionsOf": "Search regions of",
            "Seaview": "Sea view",
            "Security": "Security",
            "SeePropertiesFromThisOffice": "See all properties from this office",
            "SelectAll": "Select all",
            "SelectAllPropertyUses": "Select all property uses",
            "SelectAllThatApply": "Select all that apply",
            "SelectCommercialPropertyType": "Select property type",
            "SelectCommercialPropertyTypeError": "Please select a property type to search Savills commercial properties",
            "SelectedLocations": "Selected locations",
            "SelectLanguage": "Select the current language",
            "SelectRegion": "Select region",
            "SelectRegionOf": "Select region of",
            "SelectYourAddress": "Select your address",
            "Send": "Send",
            "SendAlinkToThisPropertyToAFriend": "Send a link to this property to a friend",
            "SendToFriendDialogSuccessMessage": "We will send details to {0}",
            "SendToFriendDialogSuccessTitle": "Thank you",
            "SeniorLiving": "Senior living",
            "SeniorLiving_CPT": "Senior living",
            "Seo_GRS_CPT_BP": "business-park",
            "Seo_GRS_CPT_D": "development-land",
            "Seo_GRS_CPT_FS": "flexspace",
            "Seo_GRS_CPT_H": "healthcare",
            "Seo_GRS_CPT_HO": "hotel",
            "Seo_GRS_CPT_I": "industrial",
            "Seo_GRS_CPT_IN": "investment",
            "Seo_GRS_CPT_L": "leisure",
            "Seo_GRS_CPT_LAN": "land",
            "Seo_GRS_CPT_O": "office",
            "Seo_GRS_CPT_OC": "other-commercial",
            "Seo_GRS_CPT_R": "retail",
            "Seo_GRS_CPT_SH": "shophouse",
            "Seo_GRS_CPT_SNL": "senior-living",
            "Seo_GRS_CPT_SO": "serviced-office",
            "Seo_GRS_FPT_AF": "arable-farms",
            "Seo_GRS_FPT_BL": "bare-land",
            "Seo_GRS_FPT_DF": "dairy-farms",
            "Seo_GRS_FPT_EQ": "equestrian-property",
            "Seo_GRS_FPT_F": "farms",
            "Seo_GRS_FPT_FSH": "fishing-estates",
            "Seo_GRS_FPT_LF": "livestock-farms",
            "Seo_GRS_FPT_RFE": "estates",
            "Seo_GRS_FPT_S": "sporting-estates",
            "Seo_GRS_FPT_VY": "vineyards",
            "Seo_GRS_FPT_W": "woodlands-estates",
            "Seo_GRS_PT_APT": "flats",
            "Seo_GRS_PT_B": "bungalows",
            "Seo_GRS_PT_BP": "building-plots",
            "Seo_GRS_PT_C": "compounds",
            "Seo_GRS_PT_CAST": "castles",
            "Seo_GRS_PT_CHLT": "chalet",
            "Seo_GRS_PT_CONDO": "condominiums",
            "Seo_GRS_PT_CTTG": "cottages",
            "Seo_GRS_PT_D": "duplex",
            "Seo_GRS_PT_ECONDO": "executivecondominiums",
            "Seo_GRS_PT_EST": "estates",
            "Seo_GRS_PT_FOC": "flat-outside-the-city",
            "Seo_GRS_PT_H": "houses",
            "Seo_GRS_PT_HDB": "hdbapartments",
            "Seo_GRS_PT_HRB": "high-rise-buildings",
            "Seo_GRS_PT_HTB": "help-to-buy",
            "Seo_GRS_PT_LP": "land-plots",
            "Seo_GRS_PT_M": "mews",
            "Seo_GRS_PT_MRB": "middle-rise-buildings",
            "Seo_GRS_PT_ND": "new-homes",
            "Seo_GRS_PT_PENT": "penthouses",
            "Seo_GRS_PT_SAPT": "serviced-apartments",
            "Seo_GRS_PT_SNRL": "senior-living",
            "Seo_GRS_PT_SOW": "shared-ownership",
            "Seo_GRS_PT_STU": "studios",
            "Seo_GRS_PT_T": "triplex",
            "Seo_GRS_PT_TH": "townhouses",
            "Seo_GRS_PT_TUA": "temporary-use-apartments",
            "Seo_GRS_PT_V": "villas",
            "Seo_GRS_PT_VY": "vineyards",
            "SeoAptBedroom": "-bedroom-appartment",
            "SeoCommercial": "commercial",
            "SeoFarms": "farms",
            "SeoForSale": "-for-sale",
            "SeoHouseBedroom": "-bedroom-houses",
            "SeoHouseBedroomLinkText": "bedroom houses",
            "SeoLifeStyle": "lifestyle",
            "SeoOffice": "estate-agents",
            "SeoProperty": "property",
            "SeoPropertyForSale": "property-for-sale",
            "SeoPropertyToRent": "property-to-rent",
            "SeoRural": "property",
            "SeoToLet": "-to-let",
            "SeoToRent": "-to-rent",
            "September": "September",
            "ServicedApartment": "Serviced apartment",
            "ServicedOffice": "Serviced office",
            "ServiceOffice": "Serviced office",
            "Share": "Share",
            "ShareDetailsWithAFriend": "Share details with a friend",
            "SharedOwnership": "Shared Ownership",
            "ShareThisProperty": "Share this property",
            "Shooting": "Shooting",
            "Shophouse": "Shophouse",
            "ShoppingCentre": "Shopping centre",
            "ShortAcres": "Ac",
            "ShortBuy": "Buy",
            "ShortCommercial": "Commercial",
            "ShortFarmsAndEstates": "Rural",
            "ShortFriday": "Fri",
            "ShortHectares": "Ha",
            "ShortMonday": "Mon",
            "ShortPerMonth": "per month",
            "ShortPerMonthFilter": "Per month",
            "ShortPerWeek": "per week",
            "ShortPerWeekFilter": "Per week",
            "ShortPerYear": "per year",
            "ShortPerYearFilter": "Per year",
            "ShortPing": "Ping",
            "ShortRent": "Rent",
            "ShortResidential": "Residential",
            "ShortSaturday": "Sat",
            "ShortSqFeet": "sq ft",
            "ShortSqMeters": "sq m",
            "ShortSunday": "Sun",
            "ShortTerm": "Short term",
            "ShortThursday": "Thu",
            "ShortTuesday": "Tue",
            "ShortWednesday": "Wed",
            "Show": "Show",
            "ShowLetProperties": "Show let properties",
            "ShowMap": "Show map",
            "ShowRecentlySoldProperties": "Show recently sold properties",
            "ShowSavillsOffices": "Show Savills offices",
            "ShowSoldProperties": "Show sold properties",
            "ShuttleBus": "Shuttle bus",
            "Size": "Property size",
            "SizeDescription": "{0} {1} {2} {3}",
            "SizeSymbolGross": "G",
            "SizeSymbolSaleable": "S",
            "Skiing": "Skiing",
            "SmallestSizeFirst": "Smallest size first",
            "SoleAgency": "Sole agency",
            "SortOrder": "Sort order",
            "SP_LogOut": "Log out",
            "SP_MyDetails": "My details",
            "SP_MyPassword": "My password",
            "SP_MyProperties": "My properties",
            "SP_MySavills": "My Savills",
            "SP_MySearches": "My searches",
            "SP_PropertySearch": "Property search",
            "SP_SavedProperties": "Saved properties",
            "SP_SavedSearches": "Saved searches",
            "SP_Welcome": "Welcome",
            "SPA": "SPA",
            "SpecialistCareHome": "Specialist care home",
            "Sporting": "Sporting",
            "Sportsvenue": "Sports venue",
            "SqFeet": "sq. feet",
            "SqFt": "sq ft",
            "Sqm": "sq m",
            "SqMeters": "sq. metres",
            "Station": "Station",
            "Stations": "Stations",
            "StepFreeAccess": "Step free access",
            "StreetView": "Street View",
            "StreetViewUnavailable": "Sorry, street view is unavailable for this property",
            "StudentAccomodation": "Student accommodation",
            "Studio": "Studio",
            "SubHeadingViewEPCRating": "Subheading about the EPC graph",
            "Submit": "Submit",
            "SubscribeToResearch": "Subscribe to Research",
            "Suburban": "Suburban",
            "SuggestedSearches": "Suggested searches",
            "Sunday": "Sunday",
            "Surname": "Surname",
            "SuspendedCeiling": "Suspended ceiling",
            "SwimmingPool": "Swimming pool",
            "T": "T",
            "TalkToUs": "Talk To Us",
            "Telephone": "Telephone",
            "Telephone_label": "Telephone",
            "TemporaryUseApartments": "Temporary use apartments",
            "TennisCourt": "Tennis Court",
            "Tenure": "Tenure",
            "TenureFlyingFreehold": "Flying freehold",
            "TenureFreehold": "Freehold",
            "TenureLeasehold": "Leasehold",
            "TenureShareOfFreehold": "Share of freehold",
            "TenureShareTransfer": "Share transfer",
            "TenureVirtualFreehold": "Virtual freehold",
            "Terrace": "Terrace",
            "ThankYou": "Thank you",
            "ThisSearch": "this search",
            "ThreeLocations": "{0}, {1} and {2}",
            "Thursday": "Thursday",
            "Time": "Preferred time (optional)",
            "To": "to",
            "ToChangePasswordMessage": "To change your password simply enter your current and new passwords below",
            "ToLetByTender": "To let by tender",
            "ToLogin": "To login",
            "ToRegister": "To register",
            "ToRent": "To rent",
            "ToReset": "To reset It",
            "Town": "Town",
            "TownCity": "Town /city",
            "Townhouse": "Townhouse",
            "TownsAndCities": "Towns and cities",
            "TownsCities": "Towns /cities",
            "Transit": "Transit",
            "Triplex": "Triplex",
            "Tuesday": "Tuesday",
            "TwoLocations": "{0} and {1}",
            "TypeToSearch": "Type to search Savills properties...",
            "UndergroundParking": "Underground parking",
            "Unfurnished": "Unfurnished",
            "UnitOfMeasure": "Unit of measure",
            "Unsubscribe": "Unsubscribe",
            "UodoChanges": "Undo changes",
            "Update": "Update",
            "Updated": "Updated",
            "UpdatePassword": "Update password",
            "UpdateResults": "Update results",
            "UpdateResultsAsIEdit": "Update results as I edit my search",
            "UpdateSearch": "Update search",
            "UpdateXResults": "Update ({0} results)",
            "UpTo": "Up to",
            "UrgentSale": "Urgent sale",
            "UseMyCurrentLocation": "Use my current location",
            "Video": "Video",
            "Video360": "360º Video",
            "Videos": "Videos",
            "ViewAll": "View all",
            "ViewAllRelatedProperties": "View all related properties",
            "ViewEPCRating": "View detailed EPC rating for this property",
            "ViewFullDetails": "View full details",
            "ViewImages": "View images",
            "ViewLess": "View less",
            "ViewMore": "View more",
            "ViewMySavedProperties": "\u003cb\u003eView my\u003c/b\u003e saved properties",
            "ViewOffice": "View office",
            "ViewPropertyOnMap": "View property on map",
            "ViewResults": "View results",
            "Villa": "Villa",
            "Vineyard": "Vineyard",
            "VisitorAttraction": "Visitor attraction",
            "Warehouse": "Warehouse",
            "WaterBasedLeisure": "Water based leisure",
            "Waterside": "Waterfront",
            "Website": "Website",
            "Wednesday": "Wednesday",
            "Weekly": "Weekly",
            "Welcome": "Welcome, {0}",
            "WelcomeToMySavills": "Welcome to MySavills",
            "WelcomeToMySavillsMessage": "Thank you for signing up to MySavills. Please add your details here - they're optional but will allow us to give you better service.",
            "WetRoomFlatFloorShower": "Wet room/flat floor shower",
            "WhatAreYouPlanningToDoWithProperty": "What are you planning to do with the property?",
            "WhySavills": "Why Savills",
            "WineCountry": "Wine country",
            "WithinHalfKm": "Within half a km",
            "WithinHalfMile": "Within half a mile",
            "WithinKm": "Within {0} km",
            "WithinMiles": "Within {0} miles",
            "WithinSingle": "Within {0} mile",
            "WithinSingleKm": "Within {0} mile",
            "WithinWord": "within",
            "Woodland": "Woodland",
            "WorldwideSites": "Worldwide sites",
            "XPropertiesAtThisLocation": "There are {0} properties at this location",
            "Yard": "Yard",
            "Yearly": "per year",
            "Years": "years",
            "Yes": "Yes",
            "Yield": "Yield",
            "YouAreContacting": "You are contacting",
            "YourDetails": "Your details",
            "YourLanguageContact": "Your English contact",
            "YourMarketAppraisal": "Your market appraisal",
            "YourSavedItems": "Your saved items",
            "YourViewing": "Your viewing",
            "ZoomIn": "Zoom in",
            "ZoomOut": "Zoom out"
          },
          "loginModal": {
            "isOpen": false
          },
          "forms": {
            "data": {}
          },
          "routerMap": {
            "currentRoute": {
              "page": "/propertyDetail",
              "as": "/property-detail/gb37650000104692"
            },
            "previousRoute": {
              "page": "/propertyDetail",
              "as": "/property-detail/gb37650000104692"
            }
          }
        }
      },
      "page": "/property",
      "query": {
        "propertyId": "gb37650000104692",
        "url": "/property-detail/gb37650000104692",
        "searchParams": {}
      },
      "buildId": "1655890066524",
      "dynamicBuildId": false,
      "assetPrefix": "https://assets.savills.com"
    }
  </script>
  <script async="" id="__NEXT_PAGE__/property" src="Savills%20|%2010%20Argyll%20Street,%20Argyll%20Street,%20London,%20W1F%207TQ%20|%20Property%20to%20rent_files/property.js"></script>
  <script async="" id="__NEXT_PAGE__/_app" src="Savills%20|%2010%20Argyll%20Street,%20Argyll%20Street,%20London,%20W1F%207TQ%20|%20Property%20to%20rent_files/_app.js"></script>
  <script src="Savills%20|%2010%20Argyll%20Street,%20Argyll%20Street,%20London,%20W1F%207TQ%20|%20Property%20to%20rent_files/webpack-839dcae5296cf621cbbc.js" async=""></script>
  <script src="Savills%20|%2010%20Argyll%20Street,%20Argyll%20Street,%20London,%20W1F%207TQ%20|%20Property%20to%20rent_files/commons.js" async=""></script>
  <script src="Savills%20|%2010%20Argyll%20Street,%20Argyll%20Street,%20London,%20W1F%207TQ%20|%20Property%20to%20rent_files/main-88d642919e8fc127ed7e.js" async=""></script>
  <script>
    var dataLayer = window.dataLayer || [];
    dataLayer.push({
      "event": "routeChange",
      "pageTitle": "Savills | 10 Argyll Street, Argyll Street, London, W1F 7TQ | Property to rent",
      "pageDivision": "Commercial | Lettings | Retail",
      "path": "/property-detail/gb37650000104692"
    });

    if (undefined) {
      var initialSearchField = undefined;
      initialSearchField.forEach(function(tag) {
        dataLayer.push(tag);
      });
    }

    dataLayer.push({
      "searchType": null,
      "noOfResults": null,
      "sortByChanged": null,
      "userType": "guest",
      "userId": null,
      "pageLanguage": "en",
      "pageCountry": "gb",
      "pageCurrency": "GBP",
      "pageType": "property",
      "propertySoldState": "Available",
      "price": "33333.33333",
      "parentPropertyId": null,
      "propertyType": ["retail"],
      "propertyLocation": "Head Office – London",
      "propertyBedrooms": "0",
      "propertyBathrooms": "0",
      "propertyRoomsOther": "0",
      "propertySaveState": "not saved",
      "searchSaveState": null,
      "event": "propertyImpressions",
      "ecommerce": null,
      "propertyCountry": "gb"
    });
    dataLayer.push({
      "event": "routeChangeManual",
      "pageTitle": "Savills | 10 Argyll Street, Argyll Street, London, W1F 7TQ | Property to rent",
      "pageDivision": "Commercial | Lettings | Retail"
    });
  </script>
  <script id="core-js" type="text/javascript" src="Savills%20|%2010%20Argyll%20Street,%20Argyll%20Street,%20London,%20W1F%207TQ%20|%20Property%20to%20rent_files/savills.js" defer="defer" async=""></script>
  <script type="text/javascript" id="">
    EPHGTM = window.EPHGTM || [];
    EPHGTM.common = EPHGTM.common || [];
    EPHGTM.common.setupEventListener = function(a, c, b, d) {
      !1 !== EPHGTM.common.nullCheck(a) && (b = b || "click", d = d || void 0, "click" === b || "onclick" === b ? a.addEventListener ? a.addEventListener("click", c, d) : a.attachEvent("onclick", c) : "submit" === b || "onsubmit" === b ? a
        .addEventListener ? a.addEventListener("submit", c, d) : a.attachEvent("onsubmit", c) : a.addEventListener(b, c, d))
    };
    EPHGTM.common.nullCheck = function(a) {
      return "undefined" === typeof a || null === a || "null" === a || "" === a || "undefined" === a ? !1 : a
    };
    EPHGTM.common.queue = {};
    EPHGTM.common.queue.defer = [];
    EPHGTM.common.queue.setup = function(a) {
      "undefined" === typeof EPHGTM.common.queue.interval && (EPHGTM.common.queue.interval = setInterval(function() {
        if ("undefined" !== typeof EPHGTM.common.queue.defer)
          for (var a = 0; a < EPHGTM.common.queue.defer.length; a++) {
            var b = EPHGTM.common.queue.defer[a];
            if ("window" == b.query.type && window.hasOwnProperty(b.query.value) || "element" == b.query.type && document.querySelector(b.query.value)) {
              b = EPHGTM.common.queue.defer.shift();
              "function" === typeof b.exec && b.exec();
              if ("undefined" === typeof EPHGTM.common.queue.interval) break;
              a--
            }
          }
      }, a))
    };
    EPHGTM.common.queue.terminate = function() {
      clearInterval(EPHGTM.common.queue.interval);
      EPHGTM.common.queue.interval = void 0
    };
    EPHGTM.library = EPHGTM.library || [];
    EPHGTM.library.formDrop = EPHGTM.library.formDrop || [];
    EPHGTM.library.formDrop.mute = [];
    EPHGTM.library.formDrop.mute.state = !1;
    EPHGTM.library.formDrop.mute.list = [];
    EPHGTM.library.formDrop.build = function(a) {
      var c = a.exclusions || [],
        b = a.interactions || "focus",
        d = a["form-element"] || document,
        f = a["form-fields"] || ["input", "select", "textarea"];
      EPHGTM.library.formDrop.name = a["form-name"] || "NO NAME SET";
      EPHGTM.library.formDrop.mute.state = a.mute || !1;
      EPHGTM.library.formDrop.mute.list = [];
      if (d && f && 0 < f.length) {
        a = [];
        for (var e = 0; e < f.length; e++) "string" == typeof f[e] ? a.push.apply(a, d.getElementsByTagName(f[e])) : "object" == typeof f[e] && a.push.apply(a, f[e]);
        for (e = 0; e < a.length; e++)
          if (!this.exclusionTest(c,
              a[e]))
            if ("string" === typeof b) EPHGTM.common.setupEventListener(a[e], EPHGTM.library.formDrop.itemState, b);
            else
              for (d = 0; d < b.length; d++) EPHGTM.common.setupEventListener(a[e], EPHGTM.library.formDrop.itemState, b[d])
      }
    };
    EPHGTM.library.formDrop.itemState = function(a) {
      var c = a.target ? a.target : a.srcElement;
      a = a.type || "UNKNOWN STATE";
      var b = c.getAttribute("name") || c.getAttribute("id") || "UNKNOWN NAME";
      c = c.getAttribute("type") && "checkbox" == c.getAttribute("type").toLowerCase() ? c.checked ? "YES" : "NO" : c.value || "UNKNOWN VALUE";
      EPHGTM.library.formDrop.output({
        state: a,
        name: b,
        value: c
      })
    };
    EPHGTM.library.formDrop.exclusionTest = function(a, c) {
      if (c.getAttribute("type") && ("hidden" === c.getAttribute("type").toLowerCase() || "submit" === c.getAttribute("type").toLowerCase())) return !0;
      for (var b = 0; b < a.length; b++)
        if (a[b] == c) return !0;
      return !1
    };
    EPHGTM.library.formDrop.output = function(a) {
      var c = {
        event: "formInteractions",
        formName: EPHGTM.library.formDrop.name,
        formData: a
      };
      EPHGTM.library.formDrop.mute.state || dataLayer.push(c);
      "undefined" === typeof EPHGTM.library.formDrop.mute.list[a.state + a.name] && (EPHGTM.library.formDrop.mute.list[a.state + a.name] = !0, dataLayer.push(c))
    };
  </script>
  <script type="text/javascript" id="">
    var head = document.getElementsByTagName("head")[0],
      js = document.createElement("script");
    js.src = "https://savills-auth-redirect-prod.s3.eu-west-1.amazonaws.com/auth-redirect.js";
    head.appendChild(js);
  </script>
  <script type="text/javascript" id="">
    function loadGiosg() {
      var a = window,
        c = document,
        g = 2568,
        e = "script",
        b = "_giosg",
        f = "https://service.giosg.com",
        d = c.createElement(e);
      d.async = 1;
      d.src = f + "/live/";
      a[b] = a[b] || function() {
        (a[b]._e = a[b]._e || []).push(arguments)
      };
      a[b]._c = g;
      a[b]._h = f;
      c = c.getElementsByTagName(e)[0];
      c.parentNode.insertBefore(d, c)
    }
    setTimeout(loadGiosg, 0);
  </script><img src="../../../assets/savills/adsct.gif" style="display: none;" width="1" height="1"><img
    src="../../../assets/savills/adsct_002.gif" style="display: none;" width="1" height="1">
  <div id="giosg_live_chat_dialog" style="z-index: 99999999; position: fixed; top: 367px; left: 1550px; display: none;" class="notranslate giosg-dialog-container ui-draggable ui-draggable-disabled">
    <div class="giosg-chat-dialog">
      <div class="giosg-header ui-draggable-handle">
        <div id="giosg_chat_operator_avatar" class="avatar_image">
          <div id="avatar-image" style="background-color: transparent;">&nbsp;</div>
        </div>
        <span class="giosg_live_chat_dialog-title">
          <img class="giosg giosg-con-indicator giosg-hidden" title="giosg Live reconnecting!" id="giosg_indicator_problem"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAB/klEQVR4AaWTA5AdQRCG/31vz7Zt275CbB9KsVVOObZRiG3bRim27TN3pid7E1td1e7+dlbK4V74L1F/1ogpzjEKLi4IojIikX9tw2mOH4jhZwB9eZape4soU7fm2YJTHwD4Y0B092xHIiq1Dh0ES99O0AFjQlolOf4xgDgts/AtslEMBqhWjrAMKHJsgvwRILJLZrwgamET0hvam6VgFbthG1baBO0TUBgT/1uAIFphHT7cQKwad/atw+09S0Dac9jHjjTqkNm/BER0TC8ycciIsvJrj7pHwyEEpNY/ngCb4FYwc8/J9k4PLfoBQC5bE9Esy8BuIPYGILktlUgD1d+EXXh3EKdJHomB1t8B9MYiS9+uTmYOgdBebwIgYG5v+fHG0PB8LixcfPXn0cOL1WujvgKEtUsNFkQdbCP6gtfdANWekU2PWB+4RnpCnoTqoZXtgnNyH7CGxhG23k7BnwCC0yrr0P6qoGrwKn1ZkLzqvWM38PLqUwBC1ljZASiGGrikDzdlDdoMAFCebEluZbSL3eGaMwus6ii0V8shb4kEhFDkrsEooIhGCMH12BnmARNweUERKm4ea60S5wvsg0vkkmqdqWsGINHKx9uXRurHgmIKj6y+KLtyeKoqOB1+dWJoqe5Bun7pucbBGzQwXblUBt7IZJ2Y1HP//Tu/AyHiC6dpMLY6AAAAAElFTkSuQmCC"
            style="display: none;">
          <img class="giosg giosg-con-indicator" title="giosg Live connected but no operators online!" id="giosg_indicator_offline"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAClElEQVR4AZWUA4xdQRiFd2pjvVHjNC5iVC9GFTupEdWMaodVsLZt27a9+7B85t85mZ3k9mY5ybmaf757zn/htc7YtqIdKsnr64zVIbu49nEdUAjnezAn4evB1KAjXL5cAQr5c3kDrgBvWw+GIhT7/o4P+Jicc1hbnMOoJFcoL2+3MyrDp+b5V7+zKzfcJ6GrATEBmH981tG+ikJGQ12MlnWMXEZGZgMj7Rij9gZGmTn7rGHp/klwC+hq8XEBE76AVZcw0o0L2JIWeylx3tsqHP9NDPwAp0gGoNrdkV9xAR/L8oWzmRFG08PYr66GSka8JTokUriUQOEOPYM7OBjuBnh1Ya6lhlFpHqMfYQF3+NpDMKWMi94F4AHUlDJqrWPU0SjU1ST1/3l9BaPyAkaR/CGJ2PLhKIDoC4oAba4WYDiReynE5Q8NDiXQW/ZR5VAUoY9YUFsmVFcuhGO0BDdFHRS1KlD00D8nd7cTRXCq3quPIRgISfWPUUfGBnTvqAzfGhTBIRbjGFICsJfzOL7/2vs0Egpjqpf6+Rffs3hpsaCySPZJClHldQGPzvRpknGVQBkbF4+EpvknSSfol+ydFKCYxweANsGIMq7aJXrpnVtxPDy/+LBRGRGSsYurgiYy0y6dFO+fyh0RHfN4PA+dTkeZ1WpeNJqWaGFeRzrdJDmWGY31MRrtFdJPMJqY7aX5eS0ZlxeI15PD4Rh1u91pRHRVAo+6XK4wu926aDIv0+KCgXT6KZqaGqaR0R4aGOygvv426h9op+GRbpqcGqJZ7Ti/qZ6MxkWy2azEgaNE9NBrteFw2DUWi0ljMi1pzGbjY7PF9JYvfMedv7NYzW/53HWLxaix2SwaIjqlXr+VvzgTWn/8A/y+QgVagCP/AAAAAElFTkSuQmCC"
            style="display: none;">
          <img class="giosg giosg-con-indicator giosg-hidden" title="giosg Live connected!" id="giosg_indicator_online"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAIAAAAP9fodAAAABnRSTlMA/wD/AP83WBt9AAACMElEQVR4AcVTQ8PdTBQe3Znkvkxq27a1qa3tuyt+Sfflr+iuNjc1PtV2cJMbz2SmxkWN7xnj+ByolALfDwR+CKTqfPHZqfv2iZvOEQUjrKjBevcypszpvx5UokLJA/9uelo6ZzR17GwO11inMEnD7Jrr3wdZc48O0yf1XltHycP/bbH4sS4dOvdsHosTJMtlnZNW3KdXhwlQsy482nHeOVlN9tfjc7fc3RS101H3OAlj6NrJP6H8O07+Scth+8Z+zY387tOd1bZdt/cq6muodxjf4SDLU5NpZZ6WMIrSuCQzTAh58uLKvReXenUY/VHa/fJ+jlQ5tlIVhjLk0kky1/W9MCWAoRiU/UyiIntRulshLUsV1Jsi4KvYEBogKkgSiBkJpJMGChUaudDjLE6EW0Gms0af+wDlnAsEmVCA0aLg5Vf7XCoR31cwpLARYVFB1ih6CXor4DQBQVFEEkAehZJDjAsYYyF1ASgmqj0aV+HJvl3HgETRgkcp4WlrCPSyLAiNBqhcyi2gKcqIzLOB/cdWkM3qv7EDG0lyQUjEimmLACYGmsx1SNu36FD5KtKHdG2rk1x920/2H97mwkU0aSnCVCaQMIVwEiUkaeljzlvYe0P95LryfM/dF2cfWuciaiHcgGHOs5hlHUd1XzVzQFtNTlbilvvvXec0RsUsFWZL6/guS0EN/t96e4VSqSSlfKcMhIZhfEVJy7I4569oXs2vNUEI49dJXCgUTNP8lOwl2Ycu7vimPgYAAAAASUVORK5CYII="
            style="display: inline-block;">
          &nbsp;<span class="chat-text-container" id="giosg-chat-title">Savills Live Chat</span>
        </span>
        <!--<button class="giosg-button__close">Close</button>-->
        <!-- <button class="close giosg-button__close">Close</button>-->
        <button class="close giosg-button__close">Close</button>
        <!--<button class="close" href="#">&times;</button>-->
      </div>
      <div class="giosg-chat-dialog-body">
        <div id="giosg_tool_button_bar">
          <div id="switch-conversation" style="display: none;" class="new giosg-chat-room-selector-button-unread">
            <div class="new_chat_messages giosg-chat-room-selector-badge" style="display: block;">1</div>
          </div>
        </div>
        <div id="giosg_live_chat_container"><iframe allow="fullscreen; camera; microphone" loading="eager" id="giosg_chat_history" title="Chat history" name="giosg_chat_history"
            src="Savills%20|%2010%20Argyll%20Street,%20Argyll%20Street,%20London,%20W1F%207TQ%20|%20Property%20to%20rent_files/a.html" style="border: 0px none;"></iframe></div>
        <div id="giosg_chat_dialog_footer">

          <!-- Do not remove/modify. Contact giosg support details. -->
          <a href="https://service.giosg.com/identity/login?&amp;utm_source=product&amp;utm_medium=livechat&amp;utm_campaign=giosg-logo" target="_blank"><img class="giosg giosg_powered_byimg hidden-tablet"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFUAAAAlCAYAAAAz16WbAAAE9UlEQVRo3u2Yf0zUZRzHyUsEyx9LIJPgPKECrna4tElIP9xIs6LSCoMjoFT0AIG0RVC50ao/qIWlma3YWtS5moS6iog513I2fuQKCoez0zLUIWp3h52iPL2/33ue6+Hp+aZsdkfb89leu+99nue+fPfi+X6eH2FhKlSoUKFChQoVKv6P0THfkwnqKXcqI5dH6iZAKK8qI5dH6kpwhGJXRlT89+G177CAPFACskA4zU8CNpDqy/80Rcu1p3vHt6WfsWF0WkEiZZLsvk/8VJYNtoNe8B1YD+KNnmPmrV4TyATFoATcCyINH9xZnQYcoBhkcHkLsIFUEB8KoRsAEegHdvADy0EqoVKtkEq4ekraM9zl/D3t+0uiIG8fIBQPd62xViL0IXAMEIFTYMWIzlufi4as3YAINIFCIdcWbKFvCzJPAo9E8jCkHqJSZ0HqAGT6mNTv086Usnved2pZZH53+VEqbxUwcSP3MSa2oNdRzPLTzSRFELkPtPO5G+NJrN65pSgizFl1RBA3IBGs8Sf4IJhCbZw0H3gAjAeRtBTwUqskE9UaJnW/lThYPueAo4EJNSgJs/X2n9eQooGcOC0XHUNqOIHl3OgtDkiNI6l6snX5C5y0TpAETCAGbBKk2oI9Sms5aWsl7Vu49lKJ1NVMqmuWX2rWidyJmixI+8NAaCz91MU7Bh+p0b5Pm0oKLHM9TGojmMKJXZCQfP7++LST/tr6WUUnFTYMZkjq7K+cVHOwpe7gpFkl7Uu49mckUsuY1ENmv9TFp7Pn0VHaIhHaRdt2gb3a9Yq+wkZd3GyvKS7lHP+6D4FPwIMjbvJt3pVhDS+yV/0Xg8mrgZOaEmypn3PSUiTtWZcq9fDfUu+i4o6DCE7oh8JE5ae7bKcwWdUCt1Bff0+0kGy9Q/Pq8LCtVaepsAMGUutDKfUdTlqhpL1ytCN1kefRaExSTJoL1IHdcqHlqKm5b0pWAVeBPPAVE2tJ9ZHEG4i/Pn7p6KHChsBkidTOUEpdyEk7CqZzbVPBsYtIfZJJ7bnlQi7LL+kraC3oqiBSkRz5PaVk5Ql7sl5To8hm1NRmCHxYEPy4LnaOh9wUS97Vk7ue2sxJaxSELhYmquBKpfI6OHGDdIn1Fl0N6Esp/+f2gNQ9tw2bOua7zZBZE1in3uFe77IQfVLI6rcn5PQWX1Tq8r7CjeyecdYh9qq7gBVcASLAusDsbya1eucvHDNRAnhxXWAD+EiypAqJVG3H9KNkXarRAi5o14N5Tc+y3+ydN5TEL/x1MkC6lxyPIjFan4XepXPzuyr6/0XqeyNG5BzP60IdHQA+PpeQRK4P/GDbunsg7JxEYh/Yw323hnKb6gBN4BvgBMngdiYYUis5qQkQeR6cBR6KD7sqT2/S8DTWb9nBVVdD3kugE2iCfwPbwCKDLWolOCzZUTVDulVSO2eAl8HXdHf1BpgA6jipN4+184DXuFGbJ9TTCSBCINzoXhA57lL/LiQmgbtBOrh21A/urHZxUqeESl4amCjkFoCznNRrxtR//OPnJ+uHKf8Uyu+2WkMpVJPmBq2gEbQJtbVkzB2rOas3UnEHwU56kOIS6mtyqKSWGUxSGl7w9Jg8q3RWNxscoGh0S0dxEKWO085KQTldStWDOlAErhuzB8DO6giQSV/3LeB98ApYqk7HVahQoUKFChUqVKhQMar4CyyNXzM10sCSAAAAAElFTkSuQmCC"
              width="85px" height="37px"></a>


        </div>
      </div>
    </div>





    <div id="conversation-list" class="giosg-chat-room-selector-menu">
      <div class="convs-header giosg-chat-room-selector-header"><span class="giosg-chat-room-selector-header-text">Conversations</span></div>

      <div class="convs-row giosg-chat-room-selector-menu-item " room-id="mturzfeeocq2y7bxhiaaf2uoww6nkpyr4svzmaawhzlq2fqm">
        <div class="roomname">Savills Residential</div>
      </div>

    </div>
  </div><button tabindex="1" aria-live="polite" class="giosg_button_settings_3094 rot0deg dock-left" style="z-index: 99999998; position: fixed; visibility: visible; right: 20px; bottom: 20px;"><span class="chatbuttontext"></span></button>
</body>

</html>
