import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    searchText = searchText.toLocaleLowerCase();

    return items.filter(it => {
      return it["name"].toLocaleLowerCase().includes(searchText);
    });
  }
}
