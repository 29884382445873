import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from 'src/app/services/data.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-neare',
  templateUrl: './neare.component.html',
  styleUrls: ['./neare.component.css']
})
export class NeareComponent implements OnInit {
  isCatchmentArea: boolean = true;
  isAudience: boolean = false;
  isSmartLeasing: boolean = false;
  isEngagement: boolean = false;
  isEngagementActivity: boolean = false;
  isArchitecture: boolean = false;
  isBrandRadar: boolean = false;
  isBrandRadarDemo: boolean = false;
  isWidget: boolean = false;

  catchment_area: boolean = false;
  audience: boolean = false;
  smart_leasing: boolean = false;
  engagement_curator: boolean = false;
  engagement_activity: boolean = false;
  architecture: boolean = false;
  brand_radar: boolean = false;
  brand_radar_demo: boolean = false;

  section_list = [];

  isDataReady: boolean = false;
  selectedProject: any;
  features = [];

  currentUser: any;

  tabOrder = [
    "catchment-area",
    "audience",
    "smart-leasing",
    "engagement-curator",
    "engagement-activity",
    "architecture",
    "brand-radar",
    "brand-radar-demo"
  ]

  constructor(
    private router: Router,
    private dataservice: DataService,
    private authService:AuthService,
  ) { }

  ngOnInit(): void {
    if (this.authService.getToken()) {
      if (this.authService.getToken()) {
        this.authService.getCurrentUser().then(value => {
          this.currentUser = value
          this.getSelectedProject()
          this.authService.getUserProfile(this.currentUser["id"]).then(value => {
            this.currentUser["profile"]  = value;
          })
        })
      }
    } else {
      window.location.href = '/';
    }

    let current_route = this.router.url
    if (current_route.split("/").length > 2) {
      this.isWidget = true;
    }
    let project_slug = current_route.split("/")[1]
  }

  getSelectedProject() {
    let current_route = this.router.url
    let name = current_route.split("/")[1]
    this.dataservice.getProjectDetail(name).then((project: any)  => {
      this.selectedProject = project;
      this.features = this.selectedProject["features"]
      this.showFeatures()
      this.isDataReady = true;
    })
  }

  showFeatures() {
    for (let feature of this.features) {
      if (feature["name"] == "Catchment Area") {
        this.catchment_area = true;
        if (this.section_list.indexOf("catchment-area") <  0) {
          this.section_list.push("catchment-area")
        }
      }
      if (feature["name"] == "Audience") {
        this.audience = true;
        if (this.section_list.indexOf("audience") < 0) {
          this.section_list.push("audience")
        }
      }
      if (feature["name"] == "Smart Leasing") {
        this.smart_leasing = true;
        if (this.section_list.indexOf("smart-leasing") <  0) {
          this.section_list.push("smart-leasing")
        }
      }
      if (feature["name"] == "Engagement Curator") {
        this.engagement_curator = true;
        if (this.section_list.indexOf("engagement-curator") <  0) {
          this.section_list.push("engagement-curator")
        }
      }
      if (feature["name"] == "Engagement Activity") {
        this.engagement_activity= true;
        if (this.section_list.indexOf("engagement-activity") <  0) {
          this.section_list.push("engagement-activity")
        }
      }
      if (feature["name"] == "Architecture/Building") {
        this.architecture = true;
        if (this.section_list.indexOf("architecture") <  0) {
          this.section_list.push("architecture")
        }
      }
      if (feature["name"] == "Brand Radar") {
        this.brand_radar = true;
        if (this.section_list.indexOf("brand-radar") <  0) {
          this.section_list.push("brand-radar")
        }
      }
      if (feature["name"] == "Brand Radar Demo") {
        this.brand_radar_demo = true;
        if (this.section_list.indexOf("brand-radar-demo") <  0) {
          this.section_list.push("brand-radar-demo")
        }
      }
    }

    let is_break = false;
    for (let tab of this.tabOrder) {
      if (!is_break) {
        for (let section of this.section_list) {
          if (tab == section) {
            this.toggleMenuTabs(tab)
            is_break = true;
            break;
          }
        }
      }
    }
  }

  toggleMenuTabs(type) {
    let project = this.selectedProject["slug"]
    if (this.isWidget) {
      this.router.navigate([`/${project}/widget`]);
      this.isCatchmentArea = true;
      this.isAudience = false;
      this.isSmartLeasing = false;
      this.isEngagement = false;
      this.isEngagementActivity = false;
      this.isArchitecture = false;
      this.isBrandRadar = false;
      this.isBrandRadarDemo = false;
    } else {
      if (type == "catchment-area") {
        this.router.navigate([`/${project}`]);
        this.isCatchmentArea = true;
        this.isAudience = false;
        this.isSmartLeasing = false;
        this.isEngagement = false;
        this.isEngagementActivity = false;
        this.isArchitecture = false;
        this.isBrandRadar = false;
        this.isBrandRadarDemo = false;
      }
      else if (type == "audience") {
        this.router.navigate([`/${project}`]);
        this.isCatchmentArea = false;
        this.isAudience = true;
        this.isSmartLeasing = false;
        this.isEngagement = false;
        this.isEngagementActivity = false;
        this.isArchitecture = false;
        this.isBrandRadar = false;
        this.isBrandRadarDemo = false;
      }
      else if (type == "smart-leasing") {
        this.router.navigate([`/${project}`]);
        this.isCatchmentArea = false;
        this.isAudience = false;
        this.isSmartLeasing = true;
        this.isEngagement = false;
        this.isEngagementActivity = false;
        this.isArchitecture = false;
        this.isBrandRadar = false;
        this.isBrandRadarDemo = false;
      }
      else if (type == "engagement-curator") {
        this.router.navigate([`/${project}`]);
        this.isCatchmentArea = false;
        this.isAudience = false;
        this.isSmartLeasing = false;
        this.isEngagement = true;
        this.isEngagementActivity = false;
        this.isArchitecture = false;
        this.isBrandRadar = false;
        this.isBrandRadarDemo = false;
      }
      else if (type == "engagement-activity") {
        this.router.navigate([`/${project}`]);
        this.isCatchmentArea = false;
        this.isAudience = false;
        this.isSmartLeasing = false;
        this.isEngagement = false;
        this.isEngagementActivity = true;
        this.isArchitecture = false;
        this.isBrandRadar = false;
        this.isBrandRadarDemo = false;
      }
      else if (type == "architecture") {
        this.router.navigate([`/${project}`]);
        this.isCatchmentArea = false;
        this.isAudience = false;
        this.isSmartLeasing = false;
        this.isEngagement = false;
        this.isEngagementActivity = false;
        this.isArchitecture = true;
        this.isBrandRadar = false;
        this.isBrandRadarDemo = false;
      }
      else if (type == "brand-radar") {
        this.router.navigate([`/${project}`]);
        this.isCatchmentArea = false;
        this.isAudience = false;
        this.isSmartLeasing = false;
        this.isEngagement = false;
        this.isEngagementActivity = false;
        this.isArchitecture = false;
        this.isBrandRadar = true;
        this.isBrandRadarDemo = false;
      }
      else if (type == "brand-radar-demo") {
        this.router.navigate([`/${project}`]);
        this.isCatchmentArea = false;
        this.isAudience = false;
        this.isSmartLeasing = false;
        this.isEngagement = false;
        this.isEngagementActivity = false;
        this.isArchitecture = false;
        this.isBrandRadar = false;
        this.isBrandRadarDemo = true;
      }
    }
  }

  logout(){
    this.authService.logout();
    this.authService.removeToken();
    window.location.href = '/';
  }
}
