import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";

import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {

  users = [];
  towns = [];
  projects = [];
  types = [
    {"id": "town_based", "name": "Town Based"},
    {"id": "area_based", "name": "Area Based"},
    {"id": "global", "name": "Global"}
  ];

  projectsForm: FormGroup;
  name = new FormControl('', Validators.compose([Validators.required]));
  slug = new FormControl('', Validators.compose([Validators.required]));
  client_id = new FormControl('', Validators.compose([Validators.required]));
  town = new FormControl('', Validators.compose([]));
  type = new FormControl('', Validators.compose([Validators.required]));
  image = new FormControl('', Validators.compose([Validators.required]));
  title = new FormControl('', Validators.compose([Validators.required]));
  subtitle = new FormControl('', Validators.compose([Validators.required]));
  tag = new FormControl('[Number of Businesses: ??] | [Active Consumers: ??]', Validators.compose([Validators.required]));
  detail_image = new FormControl('', Validators.compose([Validators.required]));


  constructor (
    public fb: FormBuilder,
    private dataservice: DataService
  ) { }

  ngOnInit(): void {
    this.projectsForm = this.fb.group({
      name: this.name,
      slug: this.slug,
      client_id: this.client_id,
      town: this.town,
      type: this.type,
      image: this.image,
      title: this.title,
      subtitle: this.subtitle,
      tag: this.tag,
      detail_image: this.detail_image
    })

    this.getUsers()
    this.getAllTowns()
    this.getAllProjects()
  }

  getUsers() {
    this.dataservice.getUsers().then((value: any[]) => {
      this.users = value;
    });
  }

  getAllTowns() {
    this.dataservice.getAllTowns().then((value: any[]) => {
      this.towns = value;
      console.log(this.towns)
    });
  }

  getAllProjects() {
    this.dataservice.getAllProjects().then((value: any[]) => {
      this.projects = value;
    });
  }

  createProjects(data) {
    console.log(data)
    this.dataservice.createProject(data).then(value => {
      this.projectsForm.reset()
      this.getAllProjects()
    });
  }

}
