import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";

import { AuthService } from 'src/app/auth/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userLogin: boolean = false;
  currentUser: any;
  message: any;
  register_message: any;

  loginForm: FormGroup;
  username = new FormControl('', Validators.compose([Validators.required]));
  password = new FormControl('', Validators.compose([Validators.required]));

  registerForm: FormGroup;
  register_username = new FormControl('', Validators.compose([Validators.required]));
  register_password = new FormControl('', Validators.compose([Validators.required]));

  constructor(
    private router: Router,
    public fb: FormBuilder,
    private authService:AuthService,
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: this.username,
      password: this.password,
    })
    this.registerForm = this.fb.group({
      register_username: this.register_username,
      register_password: this.register_password,
    })

    if (this.authService.isLoggedIn()) {
      this.userLogin = true;
    }

    if (this.authService.getToken()) {
      this.authService.getCurrentUser().then(value => {
        this.currentUser = value
        this.authService.getUserProfile(this.currentUser["id"]).then(value => {
          this.currentUser["profile"]  = value;
        })
      })
    }
  }

  resetRegisterForm() {
    this.register_message = null;
    this.registerForm.reset()
  }

  register(data) {
    let login_data = {
      "username": data["register_username"],
      "password": data["register_password"]
    }
    this.authService.register(login_data).then(value => {
      this.login(login_data)
    }).catch(err => {
      this.register_message = err["error"]["username"][0]
    });
  }

  resetLoginForm() {
    this.message = null;
    this.loginForm.reset()
  }

  login(data) {
    this.authService.login(data).then(value => {
      this.getCurrentUser()
    }).catch(err => {
      this.message = err["error"]["non_field_errors"]
      this.loginForm.reset()
    });
  }

  getCurrentUser() {
    this.authService.getCurrentUser().then(value => {
      this.currentUser = value
      this.userLogin = true;
      this.getUserProfile(this.currentUser["id"])
    })
  }

  getUserProfile(userId) {
    this.authService.getUserProfile(userId).then(value => {
      this.currentUser["profile"]  = value;
      if (this.currentUser["profile"]["user"]["username"] == "admin") {
        window.location.href = "/settings"
      } else {
        window.location.href = "/dashboard"
      }
    })
  }

  logout(){
    this.authService.logout();
    this.authService.removeToken();
    window.location.href = '/';
    this.userLogin = false;
  }

}
