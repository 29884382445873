<section *ngIf="!isCampaign" class="engagement-section">
  <div class="text-center px-5 pt-5 pb-3 mb-5">
    <img src="../../assets/img/leasing-home.png" class="mt-3">
    <p class="leasing-text mt-3">Start to engage with your visitors by using <br>
     curator tool. lorem ipsum...
    </p>
    <button type="button" class="btn create-campaign-btn px-5 text-uppercase my-3" data-bs-toggle="modal" data-bs-target="#campaignModal"> Create Campaign</button>
  </div>
</section>

<!-- Campaign Modal -->
<div class="modal fade" id="campaignModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header p-3 mx-3 mt-3">
        <h5 class="modal-title text-uppercase">Create Campaign</h5>
        <button type="button" class="btn-close btn-lg" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-3">
        <div class="d-flex justify-content-center  ct-piils mt-2">
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item tab-button text-center mr-1 mb-1" role="presentation" (click)="changeTab('campaign-name')">
              <a class="modal-tab nav-link active px-1" id="pills-home-tab" data-bs-toggle="pill" href="#pills-campaign-name" role="tab" aria-controls="pills-home" aria-selected="true">
                <div class="nav-item-bar mb-1"></div>
                Campaign Name
              </a>
            </li>
            <li class="nav-item tab-button text-center mr-1 mb-1" role="presentation" (click)="changeTab('target-audience')">
              <a class="modal-tab nav-link px-1" id="pills-contact-tab" data-bs-toggle="pill" href="#pills-target-audience" role="tab" aria-controls="pills-contact" aria-selected="false">
                <div class="nav-item-bar mb-1"></div>
                Target Audience</a>
            </li>
            <li class="nav-item tab-button text-center mr-1 mb-1" role="presentation" (click)="changeTab('shopper-behaviour')">
              <a class="modal-tab nav-link px-1" id="pills-contact-tab" data-bs-toggle="pill" href="#pills-shopper-behaviour" role="tab" aria-controls="pills-contact" aria-selected="false">
                <div class="nav-item-bar mb-1"></div>
                Shopper Behaviour</a>
            </li>
            <li class="nav-item tab-button text-center mr-1 mb-1" role="presentation" (click)="changeTab('engagement-focus')">
              <a class="modal-tab nav-link px-1" id="pills-contact-tab" data-bs-toggle="pill" href="#pills-engagement-focus" role="tab" aria-controls="pills-contact" aria-selected="false">
                <div class="nav-item-bar mb-1"></div>
                Engagement Focus</a>
            </li>
            <li class="nav-item tab-button text-center mr-1 mb-1" role="presentation" (click)="changeTab('summary')">
              <a class="modal-tab nav-link px-1" id="pills-contact-tab" data-bs-toggle="pill" href="#pills-summary" role="tab" aria-controls="pills-contact" aria-selected="false">
                <div class="nav-item-bar mb-1"></div>
                Summary</a>
            </li>
          </ul>
        </div>
        <div class="tab-content" id="pills-tabContent">
          <div class="modal-tab-content tab-pane fade show active mx-3" id="pills-campaign-name" role="tabpanel" aria-labelledby="pills-home-tab">
            <div class="card modal-content-card p-4">
              <p class="content-text">CAMPAIGN NAME <br>
                you can give a name that you will recognize easily later on.
                Eg: “welcome to gym for 30-35 years old males”
              </p>
              <div class="">
                <input class="campaign-name-input form-control w-100 my-2 p-2" type="text" name="" value="" placeholder="Enter your campaign name">
              </div>
            </div>
          </div>
          <div class="modal-tab-content tab-pane fade mx-3" id="pills-target-audience" role="tabpanel" aria-labelledby="pills-home-tab">
            <p class="content-text">Please select or delesect [target audience]
              criteria according to your strategy
            </p>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">AGE GROUP <br> Catchment area analysis:
                category#1 (32%), category#2 (32%), category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let ageGroup of ageGroups">
                  <button *ngIf="ageGroup['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="toogleAgeGroup(ageGroup['id'])"><span>{{ ageGroup['name'] }} {{ ageGroup['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!ageGroup['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="toogleAgeGroup(ageGroup['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ ageGroup['name'] }}</span></button>
                </span>
              </div>
            </div>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">INCOME LEVEL <br> catchment area analysis:
                category#1 (32%), category#2 (32%), category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let incomeLevel of incomeLevels">
                  <button *ngIf="incomeLevel['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="toogleIncomeLevel(incomeLevel['id'])"><span>{{ incomeLevel['name'] }} {{ incomeLevel['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!incomeLevel['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="toogleIncomeLevel(incomeLevel['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ incomeLevel['name'] }}</span></button>
                </span>
              </div>
            </div>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">GENDER <br> catchment area analysis:
                category#1 (32%), category#2 (32%), category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let gender of genders">
                  <button *ngIf="gender['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="toogleGender(gender['id'])"><span>{{ gender['name'] }} {{ gender['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!gender['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="toogleGender(gender['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ gender['name'] }}</span></button>
                </span>
              </div>
            </div>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">OCCUPATION (INDUSTRY) <br> catchment area
                analysis: category#1 (32%), category#2 (32%), category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let occupation of occupations">
                  <button *ngIf="occupation['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="toogleOccupation(occupation['id'])"><span>{{ occupation['name'] }} {{ occupation['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!occupation['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="toogleOccupation(occupation['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ occupation['name'] }}</span></button>
                </span>
              </div>
            </div>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">LIFESTYLE FOCUS <br> catchment area
                analysis: category#1 (32%), category#2 (32%), category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let lifestyleFocus of lifestyleFocuses">
                  <button *ngIf="lifestyleFocus['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="toogleLifestyleFocus(lifestyleFocus['id'])"><span>{{ lifestyleFocus['name'] }} {{ lifestyleFocus['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!lifestyleFocus['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="toogleLifestyleFocus(lifestyleFocus['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ lifestyleFocus['name'] }}</span></button>
                </span>
              </div>
            </div>
          </div>
          <div class="modal-tab-content tab-pane fade mx-3" id="pills-shopper-behaviour" role="tabpanel" aria-labelledby="pills-contact-tab">
            <p class="content-text">People who visit
            </p>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">PLACES <br>
                Catchment area analysis: category#1 (32%), category#2 (32%),
                category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let place of places">
                  <button *ngIf="place['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="tooglePlace(place['id'])"><span>{{ place['name'] }} {{ place['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!place['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="tooglePlace(place['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ place['name'] }}</span></button>
                </span>
              </div>
            </div>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">TIME PERIOD <br>
                Catchment area analysis: category#1 (32%), category#2 (32%),
                category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let timePeriod of timePeriods">
                  <button *ngIf="timePeriod['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="toogleTimePeriod(timePeriod['id'])"><span>{{ timePeriod['name'] }} {{ timePeriod['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!timePeriod['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="toogleTimePeriod(timePeriod['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ timePeriod['name'] }}</span></button>
                </span>
              </div>
            </div>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">DAYS <br>
                Catchment area analysis: category#1 (32%), category#2 (32%),
                category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let day of days">
                  <button *ngIf="day['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="toogleDay(day['id'])"><span>{{ day['name'] }} {{ day['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!day['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="toogleDay(day['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ day['name'] }}</span></button>
                </span>
              </div>
            </div>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">SEASON <br>
                Catchment area analysis: category#1 (32%), category#2
                (32%), category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let season of seasons">
                  <button *ngIf="season['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="toogleSeason(season['id'])"><span>{{ season['name'] }} {{ season['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!season['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="toogleSeason(season['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ season['name'] }}</span></button>
                </span>
              </div>
            </div>
          </div>
          <div class="modal-tab-content tab-pane fade mx-3" id="pills-engagement-focus" role="tabpanel" aria-labelledby="pills-contact-tab">
            <p class="content-text">Choose the focus of your engagement strategy
            </p>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">ACTIVATION CATEGORY <br>
                Catchment area analysis: category#1 (32%), category#2 (32%),
                category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let activationCategory of activationCategories">
                  <button *ngIf="activationCategory['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="toogleActivationCategory(activationCategory['id'])"><span>{{ activationCategory['name'] }} {{ activationCategory['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!activationCategory['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="toogleActivationCategory(activationCategory['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ activationCategory['name'] }}</span></button>
                </span>
              </div>
            </div>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">ACTIVATION TYPE <br>
                Catchment area analysis: category#1 (32%), category#2 (32%),
                category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let activationType of activationTypes">
                  <button *ngIf="activationType['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="toogleActivationType(activationType['id'])"><span>{{ activationType['name'] }} {{ activationType['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!activationType['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="toogleActivationType(activationType['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ activationType['name'] }}</span></button>
                </span>
              </div>
            </div>
          </div>
          <div class="modal-tab-content tab-pane fade mx-3" id="pills-summary" role="tabpanel" aria-labelledby="pills-contact-tab">
            <p class="content-text">Please review your summary before create.
            </p>
            <div class="row my-5">
              <div class="card modal-content-card p-4 mb-3">
                <p class="content-text-lg text-uppercase">Campaign Name</p>
                <p class="content-text">Campaign 1
                </p>
              </div>
              <div class="card modal-content-card p-4 mb-3">
                <p class="content-text-lg text-uppercase">Target Audience</p>
                <p class="content-text"><span>Age Group: </span>
                  Group#1(23%), group#2 (23%), group#3 (23%)
                </p>
                <p class="content-text"><span>Income Level: </span>
                  level#1 (23%), level#2 (23%), level#3 (23%)
                </p>
                <p class="content-text"><span>Gender: </span>
                  Female (68%)
                </p>
                <p class="content-text"><span>Occupation: </span>
                  Occupation#1 (23%), occupation#2 (23%), occupation#3(23%)
                </p>
                <p class="content-text"><span>Lifestyle Focus: </span>
                  lifestyle#1 (23%), lifestyle#2 (23%), lifestyle#3 (23%)
                </p>
              </div>
              <div class="card modal-content-card p-4 mb-3">
                <p class="content-text-lg text-uppercase">Shopper Behaviour</p>
                <p class="content-text"><span>Places: </span>
                  Place#1 (23%), Place#2 (23%), Place#3 (23%)
                </p>
                <p class="content-text"><span>Time Period: </span>
                  Time Period#1 (23%), Time Period#2 (23%), Time Period#3 (23%)
                </p>
                <p class="content-text"><span>Days: </span>
                  Weekdays
                </p>
                <p class="content-text"><span>Season: </span>
                  Winter
                </p>
              </div>
              <div class="card modal-content-card p-4 mb-3">
                <p class="content-text-lg text-uppercase">Engagement Focus</p>
                <p class="content-text"><span>Activation Category: </span>
                  Art#1 (23%), Music#2 (23%)
                </p>
                <p class="content-text"><span>Activation Type: </span>
                  Pop-up#1 (23%), Experience#2 (23%), Workshop#3 (23%)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer px-3">
        <p class="back-text cursor-pointer text-uppercase mr-auto" (click)="goPrevious()">{{ modalPreviousButtonText }}</p>
        <button *ngIf="modalNextButtonText == 'Create'" type="button" class="btn create-campaign-btn px-5 text-uppercase my-2" data-bs-dismiss="modal" (click)="goNext(); createCampaign();">{{ modalNextButtonText }}</button>
        <button *ngIf="modalNextButtonText != 'Create'" type="button" class="btn create-campaign-btn px-5 text-uppercase my-2" (click)="goNext()">{{ modalNextButtonText }}</button>
      </div>
    </div>
  </div>
</div>

<section *ngIf="isCampaign" class="engagement-section">
  <div class="filter-buttons p-5">
    <div class="d-flex align-items-center">
      <div class="text-left">
        <div *ngIf="isCampaignToggle" class="d-flex justify-content-center align-items-center text-center">
          <button type="button" class="btn btn-dark dark-btn border-radius-left px-4" (click)="toggleCampaign('campaigns')">Campaigns</button>
          <button type="button" class="btn btn-light light-btn border-radius-right px-4" (click)="toggleCampaign('agenda')">Agenda</button>
        </div>
        <div *ngIf="isAgendaToggle" class="d-flex justify-content-center align-items-center text-center">
          <button type="button" class="btn btn-light light-btn border-radius-left px-4" (click)="toggleCampaign('campaigns')">Campaigns</button>
          <button type="button" class="btn btn-dark dark-btn border-radius-right px-4" (click)="toggleCampaign('agenda')">Agenda</button>
        </div>
      </div>
      <div class="filter-text-div">
        <button type="button" class="btn filter-text text-uppercase" data-bs-toggle="modal" data-bs-target="#campaignModal" (click)="changeTab('campaign-name')"><u>Create Campaign</u></button>
      </div>
    </div>
  </div>
  <div *ngIf="isCampaignToggle" class="px-5">
    <table class="table table-borderless campaign-table">
      <thead>
        <tr>
          <th *ngIf="isNameSorted" scope="col" sortable="name" (click)="onSort('name')"><span class="selected">Name</span><i class="fa fa-fw fa-sort selected"></i></th>
          <th *ngIf="!isNameSorted" scope="col" sortable="name" (click)="onSort('name')"><span class="">Name</span><i class="fa fa-fw fa-sort"></i></th>
          <th *ngIf="isNumberOfENtitiesSorted" scope="col" sortable="number_of_entities" (click)="onSort('number_of_entities')"><span class="selected">Number of Entities</span><i class="fa fa-fw fa-sort selected"></i></th>
          <th *ngIf="!isNumberOfENtitiesSorted" scope="col" sortable="number_of_entities" (click)="onSort('number_of_entities')"><span class="">Number of Entities</span><i class="fa fa-fw fa-sort"></i></th>
          <th *ngIf="isLssSorted" scope="col" sortable="lss" (click)="onSort('lss')" class="d-flex align-items-center"><span class="selected">LSS</span>
            <div class="dropdown">
              <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16" class="margin-left: 0px !important;">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
              </button>
              <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                <p class="info-text mb-0">Local Something Score
                </p>
              </div>
            </div>
            <i class="fa fa-fw fa-sort selected"></i>
          </th>
          <th *ngIf="!isLssSorted" scope="col" sortable="lss" (click)="onSort('lss')" class="d-flex align-items-center"><span class="">LSS</span>
            <div class="dropdown">
              <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16" class="margin-left: 0px !important;">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
              </button>
              <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                <p class="info-text mb-0">Local Something Score
                </p>
              </div>
            </div>
            <i class="fa fa-fw fa-sort"></i>
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody *ngFor="let campaign of campaigns">
        <tr>
          <td class="name-text">{{ campaign['name'] }}</td>
          <td class="value-text">{{ campaign['number_of_entities']  | number  }}</td>
          <td class="value-text">{{ campaign['lss'] | number  }}</td>
          <td style="text-align: right; max-width: 40px;">
            <div class="dropdown">
              <button class="table-dropdown btn" type="button" id="table-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa fa-ellipsis-v fa-lg"></i>
              </button>
              <ul class="dropdown-menu detail-dropdown-menu my-2" aria-labelledby="table-dropdown">
                <li class="my-2 cursor-pointer"><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#engagementActivityModal" (click)="selectCampaign(campaign['id'])">See Engagement Activities</a></li>
                <li class="my-2 cursor-pointer"><a class="dropdown-item">View Detail</a></li>
                <li class="my-2 cursor-pointer"><a class="dropdown-item">Delete Campaign</a></li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="isAgendaToggle" class="px-5">
    <p>Agenda</p>
  </div>
</section>

<!--  Engagement Activity Modal -->
<div *ngIf="isCampaign" class="modal fade" id="engagementActivityModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content p-3">
      <div class="modal-header mb-0 pb-0">
        <h5 *ngIf="selectedCampaign" class="modal-title">{{ selectedCampaign['name'] }}</h5>
        <button type="button" class="btn-close btn-lg" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-3">
        <div class="">
          <p class="title text-uppercase">Suggested Engagement Activities</p>
        </div>
        <div class="filter-buttons d-flex mb-4 mt-2">
          <div class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-3 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" (click)="toggleFilterButton('type')"  [class.disabled]="false">
              Types <span *ngIf="selectedTypeCount" class="bold-text"> ({{ selectedTypeCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="typeForm" (ngSubmit)="applyLocationFilters('type')">
                <div class="scroll-div-filter">
                  <li class="my-2" *ngFor="let type of types">
                    <a class="dropdown-item">
                      <div class="form-check">
                        <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="type['id']" (change)="applyLocationFilters('type')" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                          {{ type['name'] }}
                        </label>
                      </div>
                    </a>
                  </li>
                </div>
              </form>
            </ul>
          </div>
          <div class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-3 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" (click)="toggleFilterButton('category')" [class.disabled]="false">
              Category <span *ngIf="selectedSubCategoryCount" class="bold-text"> ({{ selectedSubCategoryCount }})</span>
            </button>
            <ul class="dropdown-menu tree-dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink" (ngxOutsideClick)="clickOutside($event)">
              <form class="form-inline pb-0 mb-3">
                <div class="search-box">
                  <div class="d-flex align-items-center">
                    <input #filter (keyup)="tree.treeModel.filterNodes(filter.value)" name="search" style="border: 0px;" type="search" class="form-control rounded search-input" placeholder="Search in Categories" aria-label="Search" aria-describedby="search-addon"
                      autofocus />
                  </div>
                </div>
              </form>
              <div class="scroll-div-filter">
                <tree-root #tree
                  [nodes]="subCategoriesTreeData"
                  [options]="treeOptions"
                  (select)="onSelectSubCategory($event)"
                  (deselect)="onDeselectSubCategory($event)"
                  (toggleExpanded)="onToggleSubCategory($event)">
                </tree-root>
              </div>
            </ul>
          </div>
          <div class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-3 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" (click)="toggleFilterButton('tier')"  [class.disabled]="false">
              All Tiers <span *ngIf="selectedTierCount" class="bold-text"> ({{ selectedTierCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="tierForm" (ngSubmit)="applyLocationFilters('tier')">
                <div class="scroll-div-filter">
                  <li class="my-2" *ngFor="let tier of tiers">
                    <a class="dropdown-item">
                      <div class="form-check">
                        <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="tier['id']" (change)="applyLocationFilters('tier')" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                          {{ tier['name'] }}
                        </label>
                      </div>
                    </a>
                  </li>
                </div>
              </form>
            </ul>
          </div>
          <div class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-3 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" (click)="toggleFilterButton('follower-number')"  [class.disabled]="false">
              Followers <span *ngIf="selectedFollewerNumber" class="little-bold-text"> ({{ selectedFollewerNumber }})</span>
            </button>
            <ul class="dropdown-menu follower-dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink" (ngxOutsideClick)="clickOutsideFollower($event)">
              <div class="range-card p-2">
                <p class="content-text">FOLLOWER NUMBER <br>
                  Choose a range to filter by follower number
                </p>
                <div class="range-input mx-1 my-5">
                  <ngx-slider
                    [(value)]="minFollowerValue"
                    [(highValue)]="maxFollowerValue"
                    [options]="followerOptions"
                    (userChangeEnd)="onFollowerNumberValueChange($event)">
                  </ngx-slider>
                </div>
                <div class="row">
                  <div class="col-12 col-md-5">
                    <input class="form-control mr-3" type="text" name="" value="{{ minFollowerValue }}" placeholder="Minimum" (input)="onMinFollowerValueChange($event.target.value)">
                  </div>
                  <div class="col-12 col-md-2">
                  </div>
                  <div class="col-12 col-md-5">
                    <input class="form-control ml-3" type="text" name="" value="{{ maxFollowerValue }}" placeholder="Maximum" (input)="onMaxFollowerValueChange($event.target.value)">
                  </div>
                </div>
                <div class="row d-flex align-items-center mt-4">
                  <div class="col-12 col-md-6 text-left">
                    <a class="clear-text text-uppercase mt-3 cursor-pointer" (click)="clearRangeFilters('follower-number')"><u>Clear</u></a>
                  </div>
                  <div class="col-12 col-md-6 text-right">
                    <button style="float: right;" type="submit" class="btn btn-dark dark-btn save-btn text-uppercase px-2" (click)="applyLocationFilters('follower-number')">Save</button>
                  </div>
                </div>
              </div>
            </ul>
          </div>
          <div class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-3 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" (click)="toggleFilterButton('engagement-rate')"  [class.disabled]="false">
              Engagement Rate <span *ngIf="selectedEngagementRate" class="little-bold-text"> ({{ selectedEngagementRate }})</span>
            </button>
            <ul class="dropdown-menu engagement-dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink" (ngxOutsideClick)="clickOutsideEngagement($event)">
              <div class="range-card p-2">
                <p class="content-text">ENGAGEMENT RATE <br>
                  Choose a range to filter by engagement rate
                </p>
                <div class="range-input mx-1 my-5">
                  <ngx-slider
                    [(value)]="minEngagementValue"
                    [(highValue)]="maxEngagementValue"
                    [options]="engagementOptions"
                    (userChangeEnd)="onEngagementNumberValueChange($event)">
                  </ngx-slider>
                </div>
                <div class="row">
                  <div class="col-12 col-md-5">
                    <input class="form-control mr-3" type="text" name="" value="{{ minEngagementValue }}" placeholder="Minimum" (input)="onMinEngagementValueChange($event.target.value)">
                  </div>
                  <div class="col-12 col-md-2">
                  </div>
                  <div class="col-12 col-md-5">
                    <input class="form-control ml-3" type="text" name="" value="{{ maxEngagementValue }}" placeholder="Maximum" (input)="onMaxEngagementValueChange($event.target.value)">
                  </div>
                </div>
                <div class="row d-flex align-items-center mt-4">
                  <div class="col-12 col-md-6 text-left">
                    <a class="clear-text text-uppercase mt-3 cursor-pointer" (click)="clearRangeFilters('engagement-rate')"><u>Clear</u></a>
                  </div>
                  <div class="col-12 col-md-6 text-right">
                    <button style="float: right;" type="submit" class="btn btn-dark dark-btn save-btn text-uppercase px-2" (click)="applyLocationFilters('engagement-rate')">Save</button>
                  </div>
                </div>
              </div>
            </ul>
          </div>
          <div class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-3 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" (click)="toggleFilterButton('event-type')"  [class.disabled]="false">
              Event Type  <span *ngIf="selectedEventTypeCount" class="bold-text"> ({{ selectedEventTypeCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="eventTypeForm" (ngSubmit)="applyLocationFilters('eventType')">
                <div class="scroll-div-filter">
                  <li class="my-2" *ngFor="let eventType of eventTypes">
                    <a class="dropdown-item">
                      <div class="form-check">
                        <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="eventType['id']" (change)="applyLocationFilters('event-type')" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                          {{ eventType['name'] }}
                        </label>
                      </div>
                    </a>
                  </li>
                </div>
              </form>
            </ul>
          </div>
        </div>
        <div *ngIf="!suggested_engagement_activity_ready" class=" d-flex justify-content-center">
          <div class="spinner-border text-danger d-flex justify-content-center" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div *ngIf="suggested_engagement_activity_ready" class="suggested-engagement-activity-div px-5">
          <div *ngFor="let item of suggested_engagement_activities.slice(0, suggested_engagement_activities.length/3), let i=index" class="row w-100 mb-4 d-flex justify-content-center" style="position: relative;">
            <div *ngFor="let item of suggested_engagement_activities.slice(i*3, i*3+3)" class="col-md-12 col-lg-4">
              <div class="card-flip cursor-pointer">
                <div class="card-front">
                  <div class="brand-card card">
                    <div class="card-img-top">
                      <div class="d-flex justify-content-center">
                        <div class="d-flex justify-content-center align-items-center label-div-black mx-5 my-3">
                          <p class="label-text text-uppercase p-2 my-1">{{ item["type"] }}</p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center mb-3">
                        <img src="../../../assets/img/{{ item['image'] }}" class="brand-logo">
                      </div>
                      <div class="text-center">
                        <p class="large-text mb-0">{{ item["name"] }}</p>
                        <p class="small-text">{{ item["job"] }}</p>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="fix-bottom mx-2">
                        <div class="row d-flex align-items-bottom">
                          <div class="col-md-12 col-lg-6 text-align-left">
                            <p class="xlarge-text mb-0">{{ item["follower_number"] }}</p>
                            <p class="small-text">Followers</p>
                          </div>
                          <div class="col-md-12 col-lg-6 text-align-right">
                            <p class="xlarge-text mb-0">{{ item["engagement_rate"] }}%</p>
                            <p class="small-text">Engagement</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <div class="text-center">
                        <a class="agenda-text cursor-pointer" data-bs-toggle="modal" data-bs-target="#addToAgendaModal"><u>Add to Agenda</u></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-back">
                  <div class="brand-card-back card p-3">
                    <div class="card-top text-align-left">
                      <div class="row ml-1">
                        <div class="col-md-12 col-lg-4">
                          <img src="../../../assets/img/{{ item['image'] }}" class="brand-logo">
                        </div>
                        <div class="col-md-12 col-lg-8 mt-3">
                          <p class="small-text mb-0">{{ item["job"] }}</p>
                          <p class="large-text mb-0">{{ item["name"] }}</p>
                          <p class="small-text">@{{ item["instagram_name"] }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="card-body text-align-left">
                      <div class="row ml-1">
                        <div class="col-md-12 col-lg-12">
                          <p class="desc-text mb-3">{{ item["description"] }}</p>
                        </div>
                      </div>
                      <div class="row ml-1">
                        <div class="col-md-12 col-lg-8">
                          <p class="back-small-text mb-1">Follower Number</p>
                        </div>
                        <div class="col-md-12 col-lg-4 text-align-right">
                          <p class="back-large-text mb-1">{{ item["follower_number"] }}</p>
                        </div>
                      </div>
                      <div class="row ml-1">
                        <div class="col-md-12 col-lg-8">
                          <p class="back-small-text mb-1">Engagement Rate</p>
                        </div>
                        <div class="col-md-12 col-lg-4 text-align-right">
                          <p class="back-large-text mb-1">{{ item["engagement_rate"] }}%</p>
                        </div>
                      </div>
                      <div class="row ml-1">
                        <div class="col-md-12 col-lg-8">
                          <p class="back-small-text mb-1">Number of Posts</p>
                        </div>
                        <div class="col-md-12 col-lg-4 text-align-right">
                          <p class="back-large-text mb-1">{{ item["number_of_posts"] }}</p>
                        </div>
                      </div>
                      <div class="row ml-1">
                        <div class="col-md-12 col-lg-8">
                          <p class="back-small-text mb-1">Popularity Change (3 days)</p>
                        </div>
                        <div class="col-md-12 col-lg-4 text-align-right">
                          <p class="back-large-text mb-1">{{ item["popularity_change"] }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <div class="text-center">
                        <a class="agenda-text cursor-pointer" data-bs-toggle="modal" data-bs-target="#addToAgendaModal"><u>Add to Agenda</u></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center py-0 mt-0">
        <a class="footer-text text-uppercase cursor-pointer mr-auto" (click)="showMore()"><u>Show More({{ suggested_engagement_activities.length }}/{{ all_suggested_engagement_activities.length }})</u></a>
      </div>
    </div>
  </div>
</div>

<!-- addToAgendaModal Modal -->
<div *ngIf="isCampaign" class="modal fade" id="addToAgendaModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="left: -60px;">
    <div class="modal-content">
      <div class="modal-body px-5 mx-5">
        <div class="remove-card d-flex align-items-center justify-content-center py-4">
          <div class="text-center mb-5">
            <h4 class="mt-5">Successfully added to the agenda</h4>
            <i class="fa fa-check fa-2x success-icon my-5"></i>
            <br>
            <p>When you go to the agenda section your newly added event will
              be appeared on the “to-do” section. When you schedule and realize
              your event you can drag and drop its card under to other sections.
            </p>
            <button type="button" class="btn create-campaign-btn px-5 text-uppercase my-3" data-bs-dismiss="modal" aria-label="Close">Done</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
