import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";

import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  isForm: boolean = true;
  isFormSuccess: boolean = false;

  contactForm: FormGroup;
  firstname = new FormControl('', Validators.compose([Validators.required]));
  lastname = new FormControl('', Validators.compose([Validators.required]));
  mobile = new FormControl('', Validators.compose([Validators.required]));
  email = new FormControl('', Validators.compose([Validators.required, Validators.email]));
  website = new FormControl('', Validators.compose([Validators.required]));
  interest = new FormControl('', Validators.compose([Validators.required]));

  constructor(
    private fb: FormBuilder,
    private contactService: ContactService,

  ) {}

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      firstname: this.firstname,
      lastname: this.lastname,
      mobile: this.mobile,
      email: this.email,
      website: this.website,
      interest: this.interest,
    })
  }

  submitContact(formData) {
    console.log(formData)
    this.contactService.submitContact(formData).then(value => {
      this.isForm = false
      this.isFormSuccess = true
    })
  }

}
