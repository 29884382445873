import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit {

  locations = [];

  constructor(
    private dataservice: DataService
  ) { }

  ngOnInit(): void {
    this.getLocations()
  }

  getLocations() {
    // this.dataservice.applyLocationFilters({}).then((value: any[]) => {
    //   this.locations = value;
    // })
  }

}
