<section>
  <div class="px-5 pt-5 pb-3 d-flex align-items-center">
    <p class="section-header-text text-uppercase cursor-pointer">Reference Images</p>
  </div>

  <div class="white-card p-3 mx-5 shadow" style="height: 100%;">
    <div *ngIf="references.length > 0" id="carouselExampleControlsReference" class="carousel slide my-3 pt-4" data-bs-ride="carousel" data-bs-interval="false">
      <div class="d-flex justify-content-center control1">
        <a class="carousel-control-prev" href="#carouselExampleControlsReference" role="button" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        </a>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-md-12 col-lg-2 text-center" *ngFor="let reference of references.slice(0,6)">
                <img class="building-img img-fluid cursor-pointer m-2" src="{{ reference }}" alt="">
              </div>
            </div>
          </div>
          <div *ngFor="let item of [1, 2]; let i=index" class="carousel-item">
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-md-12 col-lg-2 text-center" *ngFor="let reference of references.slice(i*6+6, i*6+12)">
                <img class="building-img img-fluid cursor-pointer m-2" src="{{ reference }}" alt="">
              </div>
            </div>
          </div>
        </div>
        <a class="carousel-control-next" href="#carouselExampleControlsReference" role="button" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
        </a>
      </div>
    </div>
  </div>
</section>

<section class="building-section">
  <div class="px-5 pt-5 pb-3 d-flex align-items-center">
    <p class="section-header-text text-uppercase cursor-pointer">Building</p>
  </div>

  <div class="white-card p-3 mx-5 shadow" style="height: 100%;">
    <div class="row px-3 py-2">
      <div class="col-md-12 col-lg-7">
        <ul class="filter-buttons m-0 p-0 me-1">
          <!-- <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!building_locations.length">
              By Location <span *ngIf="selectedBuildingLocationCount" class="bold-text"> ({{ selectedBuildingLocationCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="buildingLocationForm">
                <li class="my-2" *ngFor="let location of building_locations">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="location['id']" (change)="applyBuildingFilters('location', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ location['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li> -->
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!building_personas.length">
              By Persona <span *ngIf="selectedBuildingPersonaCount" class="bold-text"> ({{ selectedBuildingPersonaCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="buildingPersonaForm">
                <li class="my-2" *ngFor="let persona of building_personas">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="persona['id']" (change)="applyBuildingFilters('persona', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ persona['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!building_scales.length">
              By Scale <span *ngIf="selectedBuildingScaleCount" class="bold-text"> ({{ selectedBuildingScaleCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="buildingScaleForm">
                <li class="my-2" *ngFor="let scale of building_scales">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="scale['id']" (change)="applyBuildingFilters('scale', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ scale['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!building_materials.length">
              By Material <span *ngIf="selectedBuildingMaterialCount" class="bold-text"> ({{ selectedBuildingMaterialCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="buildingMaterialForm">
                <li class="my-2" *ngFor="let material of building_materials">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="material['id']" (change)="applyBuildingFilters('material', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ material['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
          <!-- <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!building_transitions.length">
              By Transition <span *ngIf="selectedBuildingTransitionCount" class="bold-text"> ({{ selectedBuildingTransitionCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="buildingTransitionForm">
                <li class="my-2" *ngFor="let transition of building_transitions">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="transition['id']" (change)="applyBuildingFilters('transition', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ transition['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li> -->
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!building_lights.length">
              By Light <span *ngIf="selectedBuildingLightCount" class="bold-text"> ({{ selectedBuildingLightCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="buildingLightForm">
                <li class="my-2" *ngFor="let light of building_lights">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="light['id']" (change)="applyBuildingFilters('light', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ light['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!building_structures.length">
              By Structure <span *ngIf="selectedBuildingStructureCount" class="bold-text"> ({{ selectedBuildingStructureCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="buildingStructureForm">
                <li class="my-2" *ngFor="let structure of building_structures">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="structure['id']" (change)="applyBuildingFilters('structure', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ structure['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!building_facades.length">
              By Facade <span *ngIf="selectedBuildingFacadeCount" class="bold-text"> ({{ selectedBuildingFacadeCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="buildingFacadeForm">
                <li class="my-2" *ngFor="let facade of building_facades">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="facade['id']" (change)="applyBuildingFilters('facade', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ facade['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!building_colors.length">
              By Color <span *ngIf="selectedBuildingColorCount" class="bold-text"> ({{ selectedBuildingColorCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="buildingColorForm">
                <li class="my-2" *ngFor="let color of building_colors">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="color['id']" (change)="applyBuildingFilters('color', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ color['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!building_types.length">
              By Type <span *ngIf="selectedBuildingTypeCount" class="bold-text"> ({{ selectedBuildingTypeCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="buildingTypeForm">
                <li class="my-2" *ngFor="let type of building_types">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="type['id']" (change)="applyBuildingFilters('type', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ type['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
        </ul>

        <div *ngIf="buildings.length > 0" id="carouselExampleControls2" class="carousel slide my-3" data-bs-ride="carousel" data-bs-interval="false">
          <div class="d-flex justify-content-center control1">
            <a class="carousel-control-prev" href="#carouselExampleControls2" role="button" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            </a>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row d-flex justify-content-center align-items-center">
                  <div class="col-md-12 col-lg-4 text-center" *ngFor="let building of buildings.slice(0,9)">
                    <img class="building-img img-fluid cursor-pointer m-2" src="{{ building['images'][0] }}" alt="" (click)="getBuilgingDetail(building['id'])">
                  </div>
                </div>
              </div>
              <div *ngFor="let item of counter(building_rowLength); let i=index" class="carousel-item">
                <div class="row d-flex justify-content-center align-items-center">
                  <div class="col-md-12 col-lg-4 text-center" *ngFor="let building of buildings.slice(i*9+9, i*9+18)">
                    <img class="building-img img-fluid cursor-pointer m-2" src="{{ building['images'][0] }}" alt="" (click)="getBuilgingDetail(building['id'])">
                  </div>
                </div>
              </div>
            </div>
            <a class="carousel-control-next" href="#carouselExampleControls2" role="button" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-5">
        <div *ngIf="!isBuildingDetail && buildings.length > 0" class="d-flex justify-content-center align-items-center h-100 ms-3 me-3">
          <div class="d-flex justify-content-center my-5 py-5">
            <div class="spinner-border text-danger d-flex justify-content-center" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <div *ngIf="isBuildingDetail && buildings.length > 0" class="ms-3 me-3">
          <div class="row">
            <div class="col-md-12 col-lg-12 mb-3">
              <!-- <img class="detail-img img-fluid cursor-pointer" src="{{ buildingDetail['images'][0]['rectangular'] }}" data-bs-toggle="modal" data-bs-target="#imageModalBuilding" (click)=getSliderIndexBuilding(0)> -->
              <img class="detail-img img-fluid" src="{{ buildingDetail['images'][0]['rectangular'] }}">
            </div>
            <div *ngFor="let image of buildingDetail['images'].slice(1,7); let i=index" class="col-md-12 col-lg-2">
              <img *ngIf="i==0" class="building-detail-img img-fluid cursor-pointer" src="{{ image['square'] }}" data-bs-toggle="modal" data-bs-target="#imageModalBuilding" style="border-radius: 0 0 0 8px;" (click)=getSliderIndexBuilding(i+1)>
              <img *ngIf="(i != 0) && (i != 5)" class="building-detail-img img-fluid cursor-pointer" src="{{ image['square'] }}" data-bs-toggle="modal" data-bs-target="#imageModalBuilding" (click)=getSliderIndexBuilding(i+1)>
              <div class="mask-img cursor-pointer" *ngIf="i==5" data-bs-toggle="modal" data-bs-target="#imageModalBuilding" (click)=getSliderIndexBuilding(i+1)>
                <img *ngIf="i==5" class="building-detail-img img-fluid cursor-pointer" src="{{ image['square'] }}" data-bs-toggle="modal" data-bs-target="#imageModalBuilding" style="border-radius: 0 0 8px 0;" (click)=getSliderIndexBuilding(i+1)>
                <div class="mask" style="background-color: rgba(0, 0, 0, 0.6)">
                  <div class="text">
                    <p>+ {{  buildingDetail['images'].length - 7 }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="mt-3">
            <p class="section-header-text-min text-uppercase cursor-pointer">Reference Images</p>
            <div class="row">
              <div class="col-md-12 col-lg-2">
                <img class="img-fluid cursor-pointer" src="../../assets/img/Bloom & Wild.jpg" alt="">
              </div>
              <div class="col-md-12 col-lg-2">
                <img class="img-fluid cursor-pointer" src="../../assets/img/Bloom & Wild.jpg" alt="">
              </div>
              <div class="col-md-12 col-lg-2">
                <img class="img-fluid cursor-pointer" src="../../assets/img/Bloom & Wild.jpg" alt="">
              </div>
            </div>
          </div> -->
          <div class="mt-3">
            <p class="section-header-text-min text-uppercase cursor-pointer">Project Details</p>
            <table class="table table-borderless">
              <tbody>
                <tr>
                  <td class="light-text ps-0 py-1">Project Name</td>
                  <td class="dark-text text-end py-1">{{ buildingDetail["project_name"] }}</td>
                </tr>
                <tr>
                  <td class="light-text ps-0 py-1">Architecture Firms</td>
                  <td class="dark-text text-end py-1">{{ buildingDetail["architect_firm"] }}</td>
                </tr>
                <tr>
                  <td class="light-text ps-0 py-1">Area</td>
                  <td class="dark-text text-end py-1">{{ buildingDetail["area"] }}</td>
                </tr>
                <tr>
                  <td class="light-text ps-0 py-1">Year</td>
                  <td class="dark-text text-end py-1">{{ buildingDetail["year"] }}</td>
                </tr>
                <tr>
                  <td class="light-text ps-0 py-1">Manufacturers </td>
                  <td class="dark-text text-end py-1">{{ buildingDetail["manufacturer"] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-3">
            <p class="section-header-text-min text-uppercase cursor-pointer">Persona appeal</p>
            <!-- <button class="btn-dark dark-btn persona-btn btn px-4" type="button" name="button" data-bs-toggle="modal" data-bs-target="#personaModal">{{ buildingDetail['persona']["name"] }}</button> -->
            <button class="btn-dark dark-btn persona-btn btn px-4" type="button" name="button" data-bs-toggle="dropdown" aria-expanded="false">{{ buildingDetail['persona']["name"] }}</button>
            <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
              <div class="persona-card card">
                <div class="card-body">
                  <div class="d-flex justify-content-center mb-2">
                    <img src="../../assets/img/Bloom & Wild.jpg" class="persona-img">
                  </div>
                  <div class="text-center">
                    <p class="mid-text mb-3">{{ buildingDetail['persona']["name"] }}</p>
                  </div>
                  <div class="text-left mb-2">
                    <p class="large-text mb-0">{{ buildingDetail['persona']["percentage"] }} %</p>
                    <p class="small-text mb-0">Profile Percentage
                  </div>
                  <div class="text-left">
                    <p class="desc-text mb-0">{{ buildingDetail['persona']["description"] }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <p class="section-header-text-min text-uppercase cursor-pointer">Keywords</p>
            <div *ngIf="!isScrollDivKeywordsBuilding">
              <button *ngFor="let item of buildingDetail['keywords'].slice(0,12);" type="button" class="btn btn-light keyword-btn p-2 mb-2">{{ item }}</button>
              <button *ngIf="buildingDetail['keywords'].length > 12" type="button" class="btn btn-light more-btn p-2 mb-2" (click)="moreBuildingKeywords()"><u>More</u></button>
            </div>
            <div *ngIf="isScrollDivKeywordsBuilding"  class="scroll-div">
              <button *ngFor="let item of buildingDetail['keywords'];" type="button" class="btn btn-light keyword-btn p-2 mb-2">{{ item }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isBuildingDetail" class="image-modal modal fade" id="imageModalBuilding" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-uppercase"></h5>
          <button type="button" class="btn-close btn-close-white btn-lg m-2" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center">
          <div id="carouselExampleIndicators" class="carousel slide control2" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div *ngFor="let image of buildingDetail['images']; let i=index" class="building carousel-item {{ returnSliderActiveBuilding(i) }}">
                <img src="{{ image['rectangular'] }}" class="img-fluid slider-img">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isBuildingDetail" class="persona-modal modal fade" id="personaModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header p-0">
          <h5 class="modal-title text-uppercase"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center">
          <div class="persona-card card">
            <div class="card-body">
              <div class="d-flex justify-content-center mb-2">
                <img src="../../assets/img/Bloom & Wild.jpg" class="persona-img">
              </div>
              <div class="text-center">
                <p class="large-text mb-3">{{ buildingDetail['persona']["name"] }}</p>
              </div>
              <div class="text-center">
                <p class="small-text mb-0">{{ buildingDetail['persona']["description"] }}</p>
              </div>
              <!-- <div class="fix-bottom mx-2 mt-4">
                <div class="row d-flex align-items-bottom">
                  <div class="col-md-12 col-lg-6 text-align-left">
                    <p class="xlarge-text mb-0">{{ milesSeperator(item["follower_number"]) }}</p>
                    <p class="small-text">Followers</p>
                  </div>
                  <div class="col-md-12 col-lg-6 text-align-right">
                    <p *ngIf="item['engagement_rate']" class="xlarge-text mb-0">{{ item["engagement_rate"] }}%</p>
                    <p *ngIf="!item['engagement_rate']" class="xlarge-text mb-0">-%</p>
                    <p class="small-text">Engagement</p>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="interior-section">
  <div class="px-5 pt-5 pb-3 d-flex align-items-center">
    <p class="section-header-text text-uppercase cursor-pointer">Interior</p>
  </div>

  <div class="white-card p-3 mx-5 shadow" style="height: 100%;">
    <div class="row px-3 py-2">
      <div class="col-md-12 col-lg-7">
        <ul class="filter-buttons m-0 p-0 me-1">
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!interior_personas.length">
              By Persona <span *ngIf="selectedInteriorPersonaCount" class="bold-text"> ({{ selectedInteriorPersonaCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="interiorPersonaForm">
                <li class="my-2" *ngFor="let persona of interior_personas">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="persona['id']" (change)="applyInterierFilters('persona', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ persona['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!interior_scales.length">
              By Scale <span *ngIf="selectedInteriorScaleCount" class="bold-text"> ({{ selectedInteriorScaleCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="interiorScaleForm">
                <li class="my-2" *ngFor="let scale of interior_scales">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="scale['id']" (change)="applyInterierFilters('scale', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ scale['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!interior_materials.length">
              By Material <span *ngIf="selectedInteriorMaterialCount" class="bold-text"> ({{ selectedInteriorMaterialCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="interiorMaterialForm">
                <li class="my-2" *ngFor="let material of interior_materials">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="material['id']" (change)="applyInterierFilters('material', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ material['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!interior_lights.length">
              By Light <span *ngIf="selectedInteriorLightCount" class="bold-text"> ({{ selectedInteriorLightCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="interiorLightForm">
                <li class="my-2" *ngFor="let light of interior_lights">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="light['id']" (change)="applyInterierFilters('light', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ light['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!interior_structures.length">
              By Structure <span *ngIf="selectedInteriorStructureCount" class="bold-text"> ({{ selectedInteriorStructureCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="interiorStructureForm">
                <li class="my-2" *ngFor="let structure of interior_structures">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="structure['id']" (change)="applyInterierFilters('structure', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ structure['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!interior_facades.length">
              By Facade <span *ngIf="selectedInteriorFacadeCount" class="bold-text"> ({{ selectedInteriorFacadeCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="interiorFacadeForm">
                <li class="my-2" *ngFor="let facade of interior_facades">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="facade['id']" (change)="applyInterierFilters('facade', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ facade['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!interior_colors.length">
              By Color <span *ngIf="selectedInteriorColorCount" class="bold-text"> ({{ selectedInteriorColorCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="interiorColorForm">
                <li class="my-2" *ngFor="let color of interior_colors">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="color['id']" (change)="applyInterierFilters('color', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ color['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!interior_types.length">
              By Type <span *ngIf="selectedInteriorTypeCount" class="bold-text"> ({{ selectedInteriorTypeCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="interiorTypeForm">
                <li class="my-2" *ngFor="let type of interior_types">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="type['id']" (change)="applyInterierFilters('type', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ type['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
        </ul>

        <div *ngIf="interiors.length > 0" id="carouselExampleControls3" class="carousel slide my-3" data-bs-ride="carousel" data-bs-interval="false">
          <div class="d-flex justify-content-center control1">
            <a class="carousel-control-prev" href="#carouselExampleControls3" role="button" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            </a>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row d-flex justify-content-center align-items-center">
                  <div class="col-md-12 col-lg-4 text-center" *ngFor="let interior of interiors.slice(0,9)">
                    <img *ngIf="interior['images'] && interior['images'].length > 0" class="building-img img-fluid cursor-pointer m-2" src="{{ interior['images'][0] }}" alt="" (click)="getInteriorDetail(interior['id'])">
                    <img *ngIf="interior['images'] && interior['images'].length == 0" class="building-img img-fluid cursor-pointer m-2" src="../../assets/img/Bloom & Wild.jpg" alt="" (click)="getInteriorDetail(1)">
                  </div>
                </div>
              </div>
              <div *ngFor="let item of counter(interior_rowLength); let i=index" class="carousel-item">
                <div class="row d-flex justify-content-center align-items-center">
                  <div class="col-md-12 col-lg-4 text-center" *ngFor="let interior of interiors.slice(i*9+9, i*9+18)">
                    <img *ngIf="interior['images'] && interior['images'].length > 0" class="building-img img-fluid cursor-pointer m-2" src="{{ interior['images'][0] }}" alt="" (click)="getInteriorDetail(interior['id'])">
                    <img *ngIf="interior['images'] && interior['images'].length == 0" class="building-img img-fluid cursor-pointer m-2" src="../../assets/img/Bloom & Wild.jpg" alt="" (click)="getInteriorDetail(1)">
                  </div>
                </div>
              </div>
            </div>
            <a class="carousel-control-next" href="#carouselExampleControls3" role="button" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-5">
        <div *ngIf="!isInteriorDetail && interiors.length > 0" class="d-flex justify-content-center align-items-center h-100 ms-3 me-3">
          <div class="d-flex justify-content-center my-5 py-5">
            <div class="spinner-border text-danger d-flex justify-content-center" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <div *ngIf="isInteriorDetail && interiors.length > 0" class="ms-3 me-3">
          <div class="row">
            <div class="col-md-12 col-lg-12 mb-3">
              <!-- <img class="detail-img img-fluid cursor-pointer" src="{{ interiorDetail['images'][0]['rectangular'] }}" data-bs-toggle="modal" data-bs-target="#imageModalInterior" (click)=getSliderIndexInterior(0)> -->
              <img class="detail-img img-fluid" src="{{ interiorDetail['images'][0]['rectangular'] }}">
            </div>
            <div *ngFor="let image of interiorDetail['images'].slice(1,7); let i=index" class="col-md-12 col-lg-2">
              <img *ngIf="i==0" class="building-detail-img img-fluid cursor-pointer" src="{{ image['square'] }}" data-bs-toggle="modal" data-bs-target="#imageModalInterior" style="border-radius: 0 0 0 8px;" (click)=getSliderIndexInterior(i+1)>
              <img *ngIf="(i != 0) && (i != 5)" class="building-detail-img img-fluid cursor-pointer" src="{{ image['square'] }}" data-bs-toggle="modal" data-bs-target="#imageModalInterior" (click)=getSliderIndexInterior(i+1)>
              <div class="mask-img cursor-pointer" *ngIf="i==5" data-bs-toggle="modal" data-bs-target="#imageModalInterior" (click)=getSliderIndexInterior(i+1)>
                <img *ngIf="i==5" class="building-detail-img img-fluid cursor-pointer" src="{{ image['square'] }}" data-bs-toggle="modal" data-bs-target="#imageModalInterior" style="border-radius: 0 0 8px 0;" (click)=getSliderIndexInterior(i+1)>
                <div class="mask" style="background-color: rgba(0, 0, 0, 0.6)">
                  <div class="text">
                    <p>+ {{  interiorDetail['images'].length - 7 }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="mt-3">
            <p class="section-header-text-min text-uppercase cursor-pointer">Reference Images</p>
            <div class="row">
              <div class="col-md-12 col-lg-2">
                <img class="img-fluid cursor-pointer" src="../../assets/img/Bloom & Wild.jpg" alt="">
              </div>
              <div class="col-md-12 col-lg-2">
                <img class="img-fluid cursor-pointer" src="../../assets/img/Bloom & Wild.jpg" alt="">
              </div>
              <div class="col-md-12 col-lg-2">
                <img class="img-fluid cursor-pointer" src="../../assets/img/Bloom & Wild.jpg" alt="">
              </div>
            </div>
          </div> -->
          <div class="mt-3">
            <p class="section-header-text-min text-uppercase cursor-pointer">Project Details</p>
            <table class="table table-borderless">
              <tbody>
                <tr>
                  <td class="light-text ps-0 py-1">Project Name</td>
                  <td class="dark-text text-end py-1">{{ interiorDetail["project_name"] }}</td>
                </tr>
                <tr>
                  <td class="light-text ps-0 py-1">Architecture Firms</td>
                  <td class="dark-text text-end py-1">{{ interiorDetail["architect_firm"] }}</td>
                </tr>
                <tr>
                  <td class="light-text ps-0 py-1">Area</td>
                  <td class="dark-text text-end py-1">{{ interiorDetail["area"] }}</td>
                </tr>
                <tr>
                  <td class="light-text ps-0 py-1">Year</td>
                  <td class="dark-text text-end py-1">{{ interiorDetail["year"] }}</td>
                </tr>
                <tr>
                  <td class="light-text ps-0 py-1">Manufacturers </td>
                  <td class="dark-text text-end py-1">{{ interiorDetail["manufacturer"] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-3">
            <p class="section-header-text-min text-uppercase cursor-pointer">Persona appeal</p>
            <button class="btn-dark dark-btn persona-btn btn px-4" type="button" name="button" data-bs-toggle="dropdown" aria-expanded="false">{{ interiorDetail['persona']["name"] }}</button>
            <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
              <div class="persona-card card">
                <div class="card-body">
                  <div class="d-flex justify-content-center mb-2">
                    <img src="../../assets/img/Bloom & Wild.jpg" class="persona-img">
                  </div>
                  <div class="text-center">
                    <p class="mid-text mb-3">{{ interiorDetail['persona']["name"] }}</p>
                  </div>
                  <div class="text-left mb-2">
                    <p class="large-text mb-0">{{ interiorDetail['persona']["percentage"] }} %</p>
                    <p class="small-text mb-0">Profile Percentage
                  </div>
                  <div class="text-left">
                    <p class="desc-text mb-0">{{ interiorDetail['persona']["description"] }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <p class="section-header-text-min text-uppercase cursor-pointer">Keywords</p>
            <div *ngIf="!isScrollDivKeywordsInterior">
              <button *ngFor="let item of interiorDetail['keywords'].slice(0,12);" type="button" class="btn btn-light keyword-btn p-2 mb-2">{{ item }}</button>
              <button *ngIf="interiorDetail['keywords'].length > 12" type="button" class="btn btn-light more-btn p-2 mb-2" (click)="moreInteriorKeywords()"><u>More</u></button>
            </div>
            <div *ngIf="isScrollDivKeywordsInterior"  class="scroll-div">
              <button *ngFor="let item of interiorDetail['keywords'];" type="button" class="btn btn-light keyword-btn p-2 mb-2">{{ item }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isInteriorDetail" class="image-modal modal fade" id="imageModalInterior" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-uppercase"></h5>
          <button type="button" class="btn-close btn-close-white btn-lg m-2" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center">
          <div id="carouselExampleIndicators2" class="carousel slide control2" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div *ngFor="let image of interiorDetail['images']; let i=index" class="interior carousel-item {{ returnSliderActiveInterior(i) }}">
                <img src="{{ image['rectangular'] }}" class="img-fluid slider-img">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isInteriorDetail" class="persona-modal modal fade" id="personaModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header p-0">
          <h5 class="modal-title text-uppercase"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center">
          <div class="persona-card card">
            <div class="card-body">
              <div class="d-flex justify-content-center mb-2">
                <img src="../../assets/img/Bloom & Wild.jpg" class="persona-img">
              </div>
              <div class="text-center">
                <p class="large-text mb-3">{{ interiorDetail['persona']["name"] }}</p>
              </div>
              <div class="text-center">
                <p class="small-text mb-0">{{ interiorDetail['persona']["description"] }}</p>
              </div>
              <!-- <div class="fix-bottom mx-2 mt-4">
                <div class="row d-flex align-items-bottom">
                  <div class="col-md-12 col-lg-6 text-align-left">
                    <p class="xlarge-text mb-0">{{ milesSeperator(item["follower_number"]) }}</p>
                    <p class="small-text">Followers</p>
                  </div>
                  <div class="col-md-12 col-lg-6 text-align-right">
                    <p *ngIf="item['engagement_rate']" class="xlarge-text mb-0">{{ item["engagement_rate"] }}%</p>
                    <p *ngIf="!item['engagement_rate']" class="xlarge-text mb-0">-%</p>
                    <p class="small-text">Engagement</p>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="furniture-section">
  <div class="px-5 pt-5 pb-3 d-flex align-items-center">
    <p class="section-header-text text-uppercase cursor-pointer">Furniture & Object </p>
  </div>

  <div class="white-card p-3 mx-5 shadow" style="height: 100%;">
    <div class="row px-3 py-2">
      <div class="col-md-12 col-lg-7">
        <ul class="filter-buttons m-0 p-0 me-1">
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!furniture_personas.length">
              By Persona <span *ngIf="selectedFurniturePersonaCount" class="bold-text"> ({{ selectedFurniturePersonaCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="furniturePersonaForm">
                <li class="my-2" *ngFor="let persona of furniture_personas">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="persona['id']" (change)="applyFurnitureFilters('persona', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ persona['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!furniture_rooms.length">
              By Room <span *ngIf="selectedFurnitureRoomCount" class="bold-text"> ({{ selectedFurnitureRoomCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="furnitureRoomForm">
                <li class="my-2" *ngFor="let scale of furniture_rooms">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="scale['id']" (change)="applyFurnitureFilters('scale', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ scale['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!furniture_materials.length">
              By Material <span *ngIf="selectedFurnitureMaterialCount" class="bold-text"> ({{ selectedFurnitureMaterialCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="furnitureMaterialForm">
                <li class="my-2" *ngFor="let material of furniture_materials">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="material['id']" (change)="applyFurnitureFilters('material', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ material['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!furniture_colors.length">
              By Color <span *ngIf="selectedFurnitureColorCount" class="bold-text"> ({{ selectedFurnitureColorCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="furnitureColorForm">
                <li class="my-2" *ngFor="let color of furniture_colors">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="color['id']" (change)="applyFurnitureFilters('color', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ color['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
          <li class="dropdown filter-dropdown">
            <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!furniture_types.length">
              By Type <span *ngIf="selectedFurnitureTypeCount" class="bold-text"> ({{ selectedFurnitureTypeCount }})</span>
            </button>
            <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
              <form [formGroup]="furnitureTypeForm">
                <li class="my-2" *ngFor="let type of furniture_types">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="type['id']" (change)="applyFurnitureFilters('type', false)" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ type['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </form>
            </ul>
          </li>
        </ul>

        <div *ngIf="furnitures.length > 0" id="carouselExampleControls4" class="carousel slide my-3" data-bs-ride="carousel" data-bs-interval="false">
          <div class="d-flex justify-content-center control1">
            <a class="carousel-control-prev" href="#carouselExampleControls4" role="button" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            </a>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row d-flex justify-content-center align-items-center">
                  <div class="col-md-12 col-lg-4 text-center" *ngFor="let furniture of furnitures.slice(0,9)">
                    <img *ngIf="furniture['images'].length > 0" class="building-img img-fluid cursor-pointer m-2" src="{{ furniture['images'][0] }}" alt="" (click)="getFurnitureDetail(furniture['id'])">
                    <img *ngIf="furniture['images'].length == 0" class="building-img img-fluid cursor-pointer m-2" src="../../assets/img/Bloom & Wild.jpg" alt="" (click)="getFurnitureDetail(1)">
                  </div>
                </div>
              </div>
              <div *ngFor="let item of counter(interior_rowLength); let i=index" class="carousel-item">
                <div class="row d-flex justify-content-center align-items-center">
                  <div class="col-md-12 col-lg-4 text-center" *ngFor="let furniture of furnitures.slice(i*9+9, i*9+18)">
                    <img *ngIf="furniture['images'].length > 0" class="building-img img-fluid cursor-pointer m-2" src="{{ furniture['images'][0] }}" alt="" (click)="getFurnitureDetail(furniture['id'])">
                    <img *ngIf="furniture['images'].length == 0" class="building-img img-fluid cursor-pointer m-2" src="../../assets/img/Bloom & Wild.jpg" alt="" (click)="getFurnitureDetail(1)">
                  </div>
                </div>
              </div>
            </div>
            <a class="carousel-control-next" href="#carouselExampleControls4" role="button" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-5">
        <div *ngIf="!isFurnitureDetail && furnitures.length > 0" class="d-flex justify-content-center align-items-center h-100 ms-3 me-3">
          <div class="d-flex justify-content-center my-5 py-5">
            <div class="spinner-border text-danger d-flex justify-content-center" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <div *ngIf="isFurnitureDetail && furnitures.length > 0" class="ms-3 me-3">
          <div class="row">
            <div class="col-md-12 col-lg-12 mb-3">
              <!-- <img class="detail-img img-fluid cursor-pointer" src="{{ furnitureDetail['images'][0]['rectangular'] }}" data-bs-toggle="modal" data-bs-target="#imageModalFurniture" (click)=getSliderIndexFurniture(0)> -->
              <img class="detail-img img-fluid" src="{{ furnitureDetail['images'][0]['rectangular'] }}">
            </div>
            <div *ngFor="let image of furnitureDetail['images'].slice(1,7); let i=index" class="col-md-12 col-lg-2">
              <img *ngIf="i==0" class="building-detail-img img-fluid cursor-pointer" src="{{ image['square'] }}" data-bs-toggle="modal" data-bs-target="#imageModalFurniture" style="border-radius: 0 0 0 8px;" (click)=getSliderIndexFurniture(i+1)>
              <img *ngIf="(i != 0) && (i != 5)" class="building-detail-img img-fluid cursor-pointer" src="{{ image['square'] }}" data-bs-toggle="modal" data-bs-target="#imageModalFurniture" (click)=getSliderIndexFurniture(i+1)>
              <div class="mask-img cursor-pointer" *ngIf="i==5" data-bs-toggle="modal" data-bs-target="#imageModalFurniture" (click)=getSliderIndexFurniture(i+1)>
                <img *ngIf="i==5" class="building-detail-img img-fluid cursor-pointer" src="{{ image['square'] }}" data-bs-toggle="modal" data-bs-target="#imageModalFurniture" style="border-radius: 0 0 8px 0;" (click)=getSliderIndexFurniture(i+1)>
                <div class="mask" style="background-color: rgba(0, 0, 0, 0.6)">
                  <div class="text">
                    <p>+ {{  furnitureDetail['images'].length - 7 }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="mt-3">
            <p class="section-header-text-min text-uppercase cursor-pointer">Reference Images</p>
            <div class="row">
              <div class="col-md-12 col-lg-2">
                <img class="img-fluid cursor-pointer" src="../../assets/img/Bloom & Wild.jpg" alt="">
              </div>
              <div class="col-md-12 col-lg-2">
                <img class="img-fluid cursor-pointer" src="../../assets/img/Bloom & Wild.jpg" alt="">
              </div>
              <div class="col-md-12 col-lg-2">
                <img class="img-fluid cursor-pointer" src="../../assets/img/Bloom & Wild.jpg" alt="">
              </div>
            </div>
          </div> -->
          <div class="mt-3">
            <p class="section-header-text-min text-uppercase cursor-pointer">Product Details</p>
            <table class="table table-borderless">
              <tbody>
                <tr>
                  <td class="light-text ps-0 py-1">Product Name</td>
                  <td class="dark-text text-end py-1">{{ furnitureDetail["product_name"] }}</td>
                </tr>
                <tr>
                  <td class="light-text ps-0 pt-1 pb-0">Detail</td>
                </tr>
                <tr>
                  <td class="dark-text pb-1 px-0" colspan="2">{{ furnitureDetail["description"] }}</td>
                </tr>
                <tr>
                  <td class="light-text ps-0 py-1">Color</td>
                  <td class="dark-text text-end py-1">
                    <span *ngFor="let color of furnitureDetail['colors']; let i=index;">{{ color["name"] }}<span *ngIf="i != (furnitureDetail['colors'].length - 1)">, </span></span>
                  </td>
                </tr>
                <tr>
                  <td class="light-text ps-0 py-1">Size</td>
                  <td class="dark-text text-end py-1">{{ furnitureDetail["dimension"] }}</td>
                </tr>
                <tr>
                  <td class="light-text ps-0 py-1">Material</td>
                  <td class="dark-text text-end py-1">
                    <span *ngFor="let material of furnitureDetail['materials']; let i=index;">{{ material["name"] }}<span *ngIf="i != (furnitureDetail['materials'].length - 1)">, </span></span>
                  </td>
                </tr>
                <tr>
                  <td class="light-text ps-0 py-1">Manufacturers</td>
                  <td class="dark-text text-end py-1">{{ furnitureDetail["manufacturer"] }}</td>
                </tr>
                <tr>
                  <td class="light-text ps-0 py-1">Type</td>
                  <td class="dark-text text-end py-1">{{ furnitureDetail["type"]["name"] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-3">
            <p class="section-header-text-min text-uppercase cursor-pointer">Persona appeal</p>
            <button class="btn-dark dark-btn persona-btn btn px-4" type="button" name="button" data-bs-toggle="dropdown" aria-expanded="false">{{ furnitureDetail['persona']["name"] }}</button>
            <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
              <div class="persona-card card">
                <div class="card-body">
                  <div class="d-flex justify-content-center mb-2">
                    <img src="../../assets/img/Bloom & Wild.jpg" class="persona-img">
                  </div>
                  <div class="text-center">
                    <p class="mid-text mb-3">{{ furnitureDetail['persona']["name"] }}</p>
                  </div>
                  <div class="text-left mb-2">
                    <p class="large-text mb-0">{{ furnitureDetail['persona']["percentage"] }} %</p>
                    <p class="small-text mb-0">Profile Percentage
                  </div>
                  <div class="text-left">
                    <p class="desc-text mb-0">{{ furnitureDetail['persona']["description"] }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isFurnitureDetail" class="image-modal modal fade" id="imageModalFurniture" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-uppercase"></h5>
          <button type="button" class="btn-close btn-close-white btn-lg m-2" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center">
          <div id="carouselExampleIndicators3" class="carousel slide control2" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div *ngFor="let image of furnitureDetail['images']; let i=index" class="furniture carousel-item {{ returnSliderActiveFurniture(i) }}">
                <img src="{{ image['rectangular'] }}" class="img-fluid slider-img">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isFurnitureDetail" class="persona-modal modal fade" id="personaModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header p-0">
          <h5 class="modal-title text-uppercase"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center">
          <div class="persona-card card">
            <div class="card-body">
              <div class="d-flex justify-content-center mb-2">
                <img src="../../assets/img/Bloom & Wild.jpg" class="persona-img">
              </div>
              <div class="text-center">
                <p class="large-text mb-3">{{ furnitureDetail['persona']["name"] }}</p>
              </div>
              <div class="text-center">
                <p class="small-text mb-0">{{ furnitureDetail['persona']["description"] }}</p>
              </div>
              <!-- <div class="fix-bottom mx-2 mt-4">
                <div class="row d-flex align-items-bottom">
                  <div class="col-md-12 col-lg-6 text-align-left">
                    <p class="xlarge-text mb-0">{{ milesSeperator(item["follower_number"]) }}</p>
                    <p class="small-text">Followers</p>
                  </div>
                  <div class="col-md-12 col-lg-6 text-align-right">
                    <p *ngIf="item['engagement_rate']" class="xlarge-text mb-0">{{ item["engagement_rate"] }}%</p>
                    <p *ngIf="!item['engagement_rate']" class="xlarge-text mb-0">-%</p>
                    <p class="small-text">Engagement</p>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
