<!-- <section *ngIf="!isStrategy" class="smart-leasing-section"> -->
<section *ngIf="false" class="smart-leasing-section">
  <div class="text-center px-5 pt-5 pb-3 mb-5">
    <img src="../../assets/img/leasing-home.png" class="mt-3">
    <p class="leasing-text mt-3">We have a space full of potential. <br>
      To see yours, please set your strategy.
    </p>
    <button type="button" class="btn create-strategy-btn px-5 text-uppercase my-3" data-bs-toggle="modal" data-bs-target="#strategyModal"> Create Strategy</button>
  </div>
</section>

<!-- Strategy Modal -->
<div *ngIf="false" class="modal fade" id="strategyModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header p-3 mx-3 mt-3">
        <h5 class="modal-title text-uppercase">Create Strategy</h5>
        <button type="button" class="btn-close btn-lg" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-3">
        <div class="d-flex justify-content-center  ct-piils mt-2">
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item tab-button text-center mr-1 mb-1" role="presentation" (click)="changeTab('tenant-category')">
              <a class="modal-tab nav-link active px-1" id="pills-home-tab" data-bs-toggle="pill" href="#pills-tenant-category" role="tab" aria-controls="pills-home" aria-selected="true">
                <div class="nav-item-bar mb-1"></div>
                Tenant Category
              </a>
            </li>
            <li class="nav-item tab-button text-center mr-1 mb-1" role="presentation" (click)="changeTab('business-preference')">
              <a class="modal-tab nav-link px-1" id="pills-profile-tab" data-bs-toggle="pill" href="#pills-business-preference" role="tab" aria-controls="pills-profile" aria-selected="false">
                <div class="nav-item-bar mb-1"></div>
                Business Preference</a>
            </li>
            <li class="nav-item tab-button text-center mr-1 mb-1" role="presentation" (click)="changeTab('target-audience')">
              <a class="modal-tab nav-link px-1" id="pills-contact-tab" data-bs-toggle="pill" href="#pills-target-audience" role="tab" aria-controls="pills-contact" aria-selected="false">
                <div class="nav-item-bar mb-1"></div>
                Target Audience</a>
            </li>
            <li class="nav-item tab-button text-center mr-1 mb-1" role="presentation" (click)="changeTab('strategy-focus')">
              <a class="modal-tab nav-link px-1" id="pills-contact-tab" data-bs-toggle="pill" href="#pills-strategy-focus" role="tab" aria-controls="pills-contact" aria-selected="false">
                <div class="nav-item-bar mb-1"></div>
                Strategy Focus</a>
            </li>
            <li class="nav-item tab-button text-center mr-1 mb-1" role="presentation" (click)="changeTab('summary')">
              <a class="modal-tab nav-link px-1" id="pills-contact-tab" data-bs-toggle="pill" href="#pills-summary" role="tab" aria-controls="pills-contact" aria-selected="false">
                <div class="nav-item-bar mb-1"></div>
                Summary</a>
            </li>
          </ul>
        </div>
        <div class="tab-content" id="pills-tabContent">
          <div class="modal-tab-content tab-pane fade show active mx-3" id="pills-tenant-category" role="tabpanel" aria-labelledby="pills-home-tab">
            <p class="content-text">Please select or delesect [tenant category]
              criteria according to your strategy
            </p>
            <div class="card modal-content-card p-4">
              <p class="content-text">TENANT CATEGORY <br> catchment area
                analysis: category#1 (32%), category#2 (32%), category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let category of categories">
                  <button *ngIf="category['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="toogleCategory(category['id'])"><span>{{ category['name'] }} {{ category['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!category['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="toogleCategory(category['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ category['name'] }}</span></button>
                </span>
              </div>
            </div>
          </div>
          <div class="modal-tab-content tab-pane fade mx-3" id="pills-business-preference" role="tabpanel" aria-labelledby="pills-home-tab">
            <p class="content-text">Please select or delesect [business
              preference] criteria according to your strategy
            </p>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">SIZE <br> catchment area analysis:
                category#1 (32%), category#2 (32%), category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let size of sizes">
                  <button *ngIf="size['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="toogleSize(size['id'])"><span>{{ size['name'] }} {{ size['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!size['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="toogleSize(size['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ size['name'] }}</span></button>
                </span>
              </div>
            </div>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">NEAREST BRANCH <br> catchment area
                analysis: category#1 (32%), category#2 (32%), category#3 (32%)
              </p>
              <div class="">
                <!-- <input type="text" id="rangePrimary"/> -->
                <!-- <input id="range" type="range" class="form-range" name="range" min="0" max="100" value="50" onchange="rangePrimary.value=value"> -->
                <input id="range" type="range" class="form-range mt-3" name="range" min="0" max="100" value="50">
              </div>
            </div>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">SERVICE <br> catchment area analysis:
                category#1 (32%), category#2 (32%), category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let service of services">
                  <button *ngIf="service['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="toogleService(service['id'])"><span>{{ service['name'] }} {{ service['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!service['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="toogleService(service['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ service['name'] }}</span></button>
                </span>
              </div>
            </div>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">PRICE <br> catchment area analysis:
                category#1 (32%), category#2 (32%), category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let price of prices">
                  <button *ngIf="price['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="tooglePrice(price['id'])"><span>{{ price['name'] }} {{ price['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!price['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="tooglePrice(price['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ price['name'] }}</span></button>
                </span>
              </div>
            </div>
          </div>
          <div class="modal-tab-content tab-pane fade mx-3" id="pills-target-audience" role="tabpanel" aria-labelledby="pills-contact-tab">
            <p class="content-text">Please select or delesect [target audience]
              criteria according to your strategy
            </p>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">AGE GROUP <br> catchment area analysis:
                category#1 (32%), category#2 (32%), category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let ageGroup of ageGroups">
                  <button *ngIf="ageGroup['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="toogleAgeGroup(ageGroup['id'])"><span>{{ ageGroup['name'] }} {{ ageGroup['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!ageGroup['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="toogleAgeGroup(ageGroup['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ ageGroup['name'] }}</span></button>
                </span>
              </div>
            </div>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">INCOME LEVEL <br> catchment area analysis:
                category#1 (32%), category#2 (32%), category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let incomeLevel of incomeLevels">
                  <button *ngIf="incomeLevel['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="toogleIncomeLevel(incomeLevel['id'])"><span>{{ incomeLevel['name'] }} {{ incomeLevel['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!incomeLevel['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="toogleIncomeLevel(incomeLevel['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ incomeLevel['name'] }}</span></button>
                </span>
              </div>
            </div>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">GENDER <br> catchment area analysis:
                category#1 (32%), category#2 (32%), category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let gender of genders">
                  <button *ngIf="gender['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="toogleGender(gender['id'])"><span>{{ gender['name'] }} {{ gender['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!gender['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="toogleGender(gender['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ gender['name'] }}</span></button>
                </span>
              </div>
            </div>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">OCCUPATION (INDUSTRY) <br> catchment area
                analysis: category#1 (32%), category#2 (32%), category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let occupation of occupations">
                  <button *ngIf="occupation['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="toogleOccupation(occupation['id'])"><span>{{ occupation['name'] }} {{ occupation['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!occupation['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="toogleOccupation(occupation['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ occupation['name'] }}</span></button>
                </span>
              </div>
            </div>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text">LIFESTYLE FOCUS <br> catchment area
                analysis: category#1 (32%), category#2 (32%), category#3 (32%)
              </p>
              <div class="">
                <span  *ngFor="let lifestyleFocus of lifestyleFocuses">
                  <button *ngIf="lifestyleFocus['selected']" type="button" class="btn strategy-btn active px-3 my-2" (click)="toogleLifestyleFocus(lifestyleFocus['id'])"><span>{{ lifestyleFocus['name'] }} {{ lifestyleFocus['value'] }}</span><i class="fa fa-times fa-lg"></i></button>
                  <button *ngIf="!lifestyleFocus['selected']" type="button" class="btn strategy-btn px-3 my-2" (click)="toogleLifestyleFocus(lifestyleFocus['id'])"><i class="fa fa-plus fa-lg"></i><span>{{ lifestyleFocus['name'] }}</span></button>
                </span>
              </div>
            </div>
          </div>
          <div class="modal-tab-content tab-pane fade mx-3" id="pills-strategy-focus" role="tabpanel" aria-labelledby="pills-contact-tab">
            <p class="content-text"><span style="font-weight: 500">STRATEGY FOCUS ORDER</span><br> drag and drop
              from most important (1) to least (4)
            </p>
            <div class="d-flex justify-content-center p-4">
              <div class="ngx-dnd-container text-center"
                  ngxDroppable [model]="data">
                <div class="ngx-dnd-item d-flex align-items-center mb-3 px-4"
                  ngxDraggable
                  *ngFor="let item of data; let i = index"
                  [model]="item">
                  <div class="number d-flex justify-content-center align-items-center">
                    <p class="mb-0 pb-0">{{ i + 1 }}</p>
                  </div>
                  <div class="card drag-card cursor-pointer {{ item['color']}}  d-flex justify-content-center m-3 p-3">
                    <div class="row d-flex justify-content-center align-items-center">
                      <div class="col-md-12 col-lg-2 pl-3" style="border-right: 0.5px solid rgba(93, 88, 100, 0.5);">
                        <p class="p1 pb-0 mb-0">67%</p>
                        <p class="p2 pb-0 mb-0">Effective</p>
                      </div>
                      <div class="col-md-12 col-lg-4 pl-3 d-flex align-items-center">
                        <p class="p3 pb-0 mb-0">{{ item["name"] }}</p>
                        <div class="dropdown">
                          <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>
                          </button>
                          <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                            <p class="info-text mb-0">{{ item["info"] }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-4">
                        <p class="p4 pb-0 mb-0">Lorem ipsum sit dolor amet Lorem ipsum sit dolor
                          ametLorem ipsum sit dolor amet
                        </p>
                      </div>
                      <div class="col-md-12 col-lg-2" style="text-align: center;">
                        <img src="../../assets/img/drag.png" class="pr-5">
                      </div>
                    </div>
                  </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="modal-tab-content tab-pane fade mx-3" id="pills-summary" role="tabpanel" aria-labelledby="pills-contact-tab">
            <p class="content-text">Please review your summary before create.
            </p>
            <div class="row my-5">
              <div class="col-md-12 col-lg-6">
                <div class="card modal-content-card p-4 mb-3">
                  <p class="content-text-lg text-uppercase">Tenant Category</p>
                  <p class="content-text">category#1 (23%),  category#2 (23%),
                    category#3 (23%), category#4 (23%)
                  </p>
                </div>
                <div class="card modal-content-card p-4 mb-3">
                  <p class="content-text-lg text-uppercase">Business preferences</p>
                  <p class="content-text"><span>Size: </span>
                    Size#1 (23%), size#2 (23%), size#3 (23%)
                  </p>
                  <p class="content-text"><span>Nearest Branch: </span>
                    5 km (34%)
                  </p>
                  <p class="content-text"><span>Service: </span>
                    Service#1 (23%), service#2 (23%), service#3 (23%)
                  </p>
                  <p class="content-text"><span>Price: </span>
                    £ (23%), ££ (23%), £££ (23%)
                  </p>
                </div>
                <div class="card modal-content-card p-4 mb-3">
                  <p class="content-text-lg text-uppercase">Target Audience</p>
                  <p class="content-text"><span>Age Group: </span>
                    Group#1(23%), group#2 (23%), group#3 (23%)
                  </p>
                  <p class="content-text"><span>Income Level: </span>
                    level#1 (23%), level#2 (23%), level#3 (23%)
                  </p>
                  <p class="content-text"><span>Gender: </span>
                    Female (68%)
                  </p>
                  <p class="content-text"><span>Occupation: </span>
                    Occupation#1 (23%), occupation#2 (23%), occupation#3(23%)
                  </p>
                  <p class="content-text"><span>Lifestyle Focus: </span>
                    lifestyle#1 (23%), lifestyle#2 (23%), lifestyle#3 (23%)
                  </p>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 mb-3">
                <div class="card modal-content-card pt-4 px-4" style="height: 100%;">
                  <p class="content-text-lg pb-0 mb-0">STRATEGY FOCUS ORDER</p>
                  <p class="content-text">from Most important (1) to least (4)</p>
                  <div class="d-flex justify-content-center mt-1">
                    <div class="ngx-dnd-container text-center" style="margin: 10px 2px;">
                      <div class="ngx-dnd-item d-flex align-items-center" *ngFor="let item of data" style="border: 0px;">
                        <div class="number d-flex justify-content-center align-items-center">
                          <p class="mb-0 pb-0">{{ item["id"] }}</p>
                        </div>
                        <div class="card drag-card {{ item['color']}}  d-flex justify-content-center mx-1 my-3 p-3">
                          <div class="row d-flex justify-content-center align-items-center">
                            <div class="col-md-12 col-lg-3 pl-3" style="border-right: 0.5px solid rgba(93, 88, 100, 0.5);">
                              <p class="p1 pb-0 mb-0">67%</p>
                              <p class="p2 pb-0 mb-0">Effective</p>
                            </div>
                            <div class="col-md-12 col-lg-4 pl-3">
                              <p class="p3 pb-0 mb-0" style="padding-left: 0px;">{{ item["name"] }}</p>
                            </div>
                            <div class="col-md-12 col-lg-5">
                              <p class="p4 pb-0 mb-0" style="padding-right: 5px;">Lorem ipsum sit dolor amet Lorem ipsum sit dolor
                                ametLorem ipsum sit dolor amet
                              </p>
                            </div>
                          </div>
                        </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer px-3">
        <p class="back-text cursor-pointer text-uppercase mr-auto" (click)="goPrevious()">{{ modalPreviousButtonText }}</p>
        <button *ngIf="modalNextButtonText == 'Create'" type="button" class="btn create-strategy-btn px-5 text-uppercase my-2" data-bs-dismiss="modal" (click)="goNext(); createStrategy();">{{ modalNextButtonText }}</button>
        <button *ngIf="modalNextButtonText != 'Create'" type="button" class="btn create-strategy-btn px-5 text-uppercase my-2" (click)="goNext()">{{ modalNextButtonText }}</button>
      </div>
    </div>
  </div>
</div>

<!-- <section *ngIf="isStrategy" class="smart-leasing-section"> -->
<section *ngIf="true" class="smart-leasing-section">
  <div class="filter-buttons p-5">
    <div class="d-flex align-items-center">
      <div class="text-left">
        <div class="dropdown filter-dropdown border-right">
          <button class="dropdownMenuLink btn {{ filterButton }} px-4 mr-3 text-left" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" (click)="toggleFilterButton('filter')">
            {{ status }} <i class="fa fa-chevron-down"></i>
          </button>
          <ul class="dropdown-menu my-2" aria-labelledby="dropdownMenuLink">
            <li class="my-2 cursor-pointer"><a class="dropdown-item selected" (click)="changeType('on_agenda')">On Agenda</a></li>
            <li class="my-2 cursor-pointer"><a class="dropdown-item" (click)="changeType('archive')">Archive</a></li>
          </ul>
        </div>
      </div>
      <hr>
      <div *ngIf="!isArchive" class="category-buttons">
        <div class="dropdown filter-dropdown">
          <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
            Category <span *ngIf="selectedCategoryCount" class="bold-text"> ({{ selectedCategoryCount }})</span>
          </button>
          <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
            <form class="form-inline pb-0 mb-3" (submit)="searchCategory()" action="#">
              <div class="search-box">
                <div class="d-flex align-items-center">
                  <!-- <img class="search_icon mr-2" src="../../../assets/icon/search-icon.svg"> -->
                  <input [(ngModel)]="searchTextCategory" name="search" style="border: 0px;" type="search" class="form-control rounded search-input" placeholder="Search Your Categories" aria-label="Search" aria-describedby="search-addon" autofocus />
                </div>
              </div>
            </form>
            <form [formGroup]="categoryForm">
              <div class="scroll-div-filter">
                <li class="my-2" *ngFor="let category of categories | filter: searchTextCategory;">
                  <a class="dropdown-item">
                    <div class="form-check">
                      <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="category['id']" (change)="categorySearchFilter()" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ category['name'] }}
                      </label>
                    </div>
                  </a>
                </li>
              </div>
            </form>
          </ul>
        </div>
      </div>
      <div *ngIf="isArchive" class="category-buttons">
        <button class="dropdownMenuLink btn {{ all_archive }} px-4 mr-3" type="button" id="dropdownMenuLink" (click)="toggleFilterButton('all_archive')">
          All Archive
        </button>
        <button class="dropdownMenuLink btn {{ deleted_archive }} px-4 mr-3" type="button" id="dropdownMenuLink" (click)="toggleFilterButton('deleted_archive')">
          Deleted
        </button>
        <button class="dropdownMenuLink btn {{ factsheet_archive }} px-4 mr-3" type="button" id="dropdownMenuLink" (click)="toggleFilterButton('factsheet_archive')">
          Fact Sheet Generated
        </button>
      </div>
      <div class="filter-text-div">
        <!-- <button type="button" class="btn filter-text text-uppercase cursor-pointer" data-bs-toggle="modal" data-bs-target="#addCategoryModal"><u>Add Category</u></button>
        <button type="button" class="btn filter-text text-uppercase cursor-pointer" data-bs-toggle="modal" data-bs-target="#checkStrategyModal" ><u>Check strategy</u></button> -->
        <button type="button" class="btn filter-text text-uppercase disabled"><u>Add Category</u></button>
        <button type="button" class="btn filter-text text-uppercase disabled"><u>Check strategy</u></button>
      </div>
    </div>
  </div>
  <div *ngIf="isNextBestFit" class="best-fit-div mx-5">
    <div class="content mb-4">
      <div class="card result-card mb-3">
        <div class="d-flex justify-content-center align-items-center px-5 py-3">
          <button class="btn btn-light light-btn rounded-btn cursor-pointer px-4 mt-1" type="button" id="dropdownMenuLink" data-bs-toggle="modal" data-bs-target="#bestFitModal">
            <i class="fa fa-plus fa-lg" style="margin-right: 15px;"></i>Next Best Fit
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="result-div mx-5 mb-5">
    <div *ngIf="!prospective_tenant_list_ready" class=" d-flex justify-content-center">
      <div class="spinner-border text-danger d-flex justify-content-center" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div *ngIf="prospective_tenant_list_ready" class="content mb-4">
      <div *ngFor="let prospective_tenant of prospective_tenant_list" class="">
        <p class="content-text text-uppercase">{{ prospective_tenant["category"] }}</p>
        <span *ngFor="let brand of prospective_tenant['brands']">
          <div class="card result-card {{ getBrandColor(brand['strategy_focus'])[0] }} mb-3">
            <div class="row d-flex justify-content-center align-items-center px-5 py-3">
              <div class="col-md-12 col-lg-1 d-flex align-items-center" style="text-align: left;">
                <img *ngIf="brand['image']" src="{{ brand['image'] }}" class="pr-5 brand-image">
                <img *ngIf="!brand['image']" src="../../assets/img/{{ brand['name'] }}.jpg" class="pr-5 brand-image">
              </div>
              <div class="col-md-12 col-lg-2" style="text-align: left;">
                <p class="p3 pb-0 mb-0">{{ brand["name"] }}</p>
              </div>
              <div class="col-md-12 col-lg-4" style="text-align: left;">
                <p class="p4 pb-0 mb-0" style="font-size: 11px; margin-right: 65px;">
                  {{ brand["description"] }}
                </p>
              </div>
              <div class="col-md-12 col-lg-4 d-flex" style="text-align: center;">
                <p *ngIf="brand['radius_tenant_size']" class="p5 light-background px-2 py-2 mr-1 mb-0">{{ brand["radius_tenant_size"]["name"] }}</p>
                <p *ngIf="!brand['radius_tenant_size']" class="p5 light-background px-2 py-2 mr-1 mb-0">-</p>
                <p class="p6 {{ getBrandColor(brand['strategy_focus'])[1] }} px-2 py-2 mr-1 mb-0">{{ brand["strategy_focus"] }}</p>
                <p class="p5 light-background-min px-2 py-2 mb-0">{{ brand["price_level_fk"] }}</p>
              </div>
              <div class="col-md-12 col-lg-1" style="text-align: right;">
                <div class="dropdown">
                  <button class="detail-ropdown btn" type="button" id="detail-ropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fa fa-ellipsis-v fa-lg"></i>
                  </button>
                  <ul class="dropdown-menu detail-dropdown-menu my-2" aria-labelledby="detail-ropdown">
                    <li *ngIf="!isArchive" class="my-2 cursor-pointer"><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#tenantDetailModal" (click)="getProspectiveTenanatDetail(brand['id'])">View Detail</a></li>
                    <li *ngIf="!isArchive" class="my-2 cursor-pointer"><a class="dropdown-item">Generate Fact Sheet</a></li>
                    <li *ngIf="!isArchive" class="my-2 cursor-pointer"><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#removeModal" (click)="keepSelectedBrandId(brand['id'])">Remove</a></li>
                    <li *ngIf="!isArchive" class="my-2 cursor-pointer"><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#refreshModal">Refresh Match</a></li>
                    <li *ngIf="isArchive" class="my-2 cursor-pointer"><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#tenantDetailModal" (click)="getProspectiveTenanatDetail(brand['id'])">View Detail</a></li>
                    <li *ngIf="isArchive" class="my-2 cursor-pointer"><a class="dropdown-item">View Fact Sheet</a></li>
                    <li *ngIf="isArchive" class="my-2 cursor-pointer"><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#restoreModal" (click)="keepSelectedBrandId(brand['id'])">Restore</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</section>

<!-- Remove Modal -->
<!-- <div *ngIf="isStrategy" class="modal fade" id="removeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true"> -->
<div *ngIf="true" class="modal fade" id="removeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="left: -60px;">
    <div class="modal-content">
      <div class="modal-body px-5 mx-5">
        <div class="remove-card d-flex align-items-center justify-content-center py-4">
          <div class="text-center mb-5">
            <h4 class="mt-5">Remove Smart Leasing Match</h4>
            <i class="fa fa-exclamation fa-2x success-icon my-5"></i>
            <br>
            <p>When you remove your match you will access another match under
              the same category but the previous match will be disappear.
              Are you sure?
            </p>
            <button type="button" class="btn create-strategy-btn px-5 text-uppercase my-3" data-bs-dismiss="modal" aria-label="Close" (click)="updateBrandStatus('archive')">Yes, Remove</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Restore Modal -->
<!-- <div *ngIf="isStrategy" class="modal fade" id="restoreModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true"> -->
<div *ngIf="true" class="modal fade" id="restoreModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="left: -60px;">
    <div class="modal-content">
      <div class="modal-body px-5 mx-5">
        <div class="remove-card d-flex align-items-center justify-content-center py-4">
          <div class="text-center mb-5">
            <h4 class="mt-5">Restore Smart Leasing Match</h4>
            <i class="fa fa-exclamation fa-2x success-icon my-5"></i>
            <br>
            <p>
              Are you sure?
            </p>
            <button type="button" class="btn create-strategy-btn px-5 text-uppercase my-3" data-bs-dismiss="modal" aria-label="Close" (click)="updateBrandStatus('on_agenda')">Yes, Restore</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Refresh Modal -->
<!-- <div *ngIf="isStrategy" class="modal fade" id="refreshModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true"> -->
<div *ngIf="true" class="modal fade" id="refreshModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="left: -60px;">
    <div class="modal-content">
      <div class="modal-body px-5 mx-5">
        <div class="remove-card d-flex align-items-center justify-content-center py-4">
          <div class="text-center mb-5">
            <h4 class="mt-5">Refresh Match</h4>
            <i class="fa fa-exclamation fa-2x success-icon my-5"></i>
            <br>
            <p>When you refresh your match you will access another match under
              the same category but the previous match will be disappear.
              Are you sure?
            </p>
            <button type="button" class="btn create-strategy-btn px-5 text-uppercase my-3" data-bs-dismiss="modal" aria-label="Close">Yes, Refresh</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Add Category Modal -->
<!-- <div *ngIf="isStrategy" class="modal fade" id="addCategoryModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true"> -->
<div *ngIf="true" class="modal fade" id="addCategoryModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content p-3">
      <div class="modal-header mb-0 pb-0">
        <h5 class="modal-title text-uppercase">Add Category</h5>
        <button type="button" class="btn-close btn-lg" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-3 mt-4">
        <p class="add-category-title">Please select a new category to add new prospective tenant.</p>
        <div class="row">
          <div *ngFor="let category of categories;  let i = index" class="col-md-12 col-lg-4">
            <div class="category-card d-flex justify-content-center align-items-center cursor-pointer p-3 mb-3" (click)="addCategory(i)">
              <p class="pb-0 mb-0">{{ category['name'] }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer mt-2 pb-0">
        <button style="float: right;" type="submit" class="btn add-btn text-uppercase px-5 mb-0 my-2" data-bs-dismiss="modal" aria-label="Close" [disabled]="addCategoryButtonDisabled">Add</button>
      </div>
    </div>
  </div>
</div>

<!-- Check Strategy Modal -->
<!-- <div *ngIf="isStrategy" class="modal fade" id="checkStrategyModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true"> -->
<div *ngIf="true" class="modal fade" id="checkStrategyModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content p-3">
      <div class="modal-header mb-0 pb-0">
        <h5 class="modal-title text-uppercase">Check Strategy</h5>
        <button type="button" class="btn-close btn-lg" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-3 mt-4">
        <div class="row">
          <div class="col-md-12 col-lg-6">
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text-lg text-uppercase">Tenant Category</p>
              <p class="content-text">category#1 (23%),  category#2 (23%),
                category#3 (23%), category#4 (23%)
              </p>
            </div>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text-lg text-uppercase">Business preferences</p>
              <p class="content-text"><span>Size: </span>
                Size#1 (23%), size#2 (23%), size#3 (23%)
              </p>
              <p class="content-text"><span>Nearest Branch: </span>
                5 km (34%)
              </p>
              <p class="content-text"><span>Service: </span>
                Service#1 (23%), service#2 (23%), service#3 (23%)
              </p>
              <p class="content-text"><span>Price: </span>
                £ (23%), ££ (23%), £££ (23%)
              </p>
            </div>
            <div class="card modal-content-card p-4 mb-3">
              <p class="content-text-lg text-uppercase">Target Audience</p>
              <p class="content-text"><span>Age Group: </span>
                Group#1(23%), group#2 (23%), group#3 (23%)
              </p>
              <p class="content-text"><span>Income Level: </span>
                level#1 (23%), level#2 (23%), level#3 (23%)
              </p>
              <p class="content-text"><span>Gender: </span>
                Female (68%)
              </p>
              <p class="content-text"><span>Occupation: </span>
                Occupation#1 (23%), occupation#2 (23%), occupation#3(23%)
              </p>
              <p class="content-text"><span>Lifestyle Focus: </span>
                lifestyle#1 (23%), lifestyle#2 (23%), lifestyle#3 (23%)
              </p>
            </div>
          </div>
          <div class="col-md-12 col-lg-6 mb-3">
            <div class="card modal-content-card pt-4 px-4" style="height: 100%;">
              <p class="content-text-lg pb-0 mb-0">STRATEGY FOCUS ORDER</p>
              <p class="content-text">from Most important (1) to least (4)</p>
              <div class="d-flex justify-content-center mt-1">
                <div class="ngx-dnd-container text-center" style="margin: 10px 2px;">
                  <div class="ngx-dnd-item d-flex align-items-center" *ngFor="let item of data" style="border: 0px;">
                    <div class="number d-flex justify-content-center align-items-center" style="margin-right: 5px;">
                      <p class="mb-0 pb-0">{{ item["id"] }}</p>
                    </div>
                    <div class="card drag-card2 d-flex justify-content-center mx-1 my-3 p-3">
                      <div class="row d-flex justify-content-center align-items-center">
                        <div class="col-md-12 col-lg-3" style="border-right: 0.5px solid rgba(93, 88, 100, 0.5);">
                          <p class="p1 pb-0 mb-0">67%</p>
                          <p class="p2 pb-0 mb-0">Effective</p>
                        </div>
                        <div class="col-md-12 col-lg-4">
                          <p class="p3 pb-0 mb-0" style="padding-left: 0px;">{{ item["name"] }}</p>
                        </div>
                        <div class="col-md-12 col-lg-5">
                          <p class="p4 pb-0 mb-0" style="padding-right: 5px;">Lorem ipsum sit dolor amet Lorem ipsum sit dolor
                            ametLorem ipsum sit dolor amet
                          </p>
                        </div>
                      </div>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center my-3">
          <p class="filter-text text-uppercase cursor-pointer" data-bs-toggle="modal" data-bs-target="#checkStrategyModal2" data-bs-dismiss="modal" aria-label="Close"><span style="margin-right: 10px;"><i class="fa fa-redo"></i></span><u>Recreate / Edit Strategy</u></p>
        </div>
      </div>
      <div class="modal-footer mt-2 pb-0">
        <button style="float: right;" type="submit" class="btn add-btn text-uppercase px-5 mb-0 my-2" data-bs-dismiss="modal" aria-label="Close">Done</button>
      </div>
    </div>
  </div>
</div>

<!-- Check Strategy Modal 2 -->
<!-- <div *ngIf="isStrategy" class="modal fade" id="checkStrategyModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true"> -->
<div *ngIf="true" class="modal fade" id="checkStrategyModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="left: -60px;">
    <div class="modal-content">
      <div class="modal-body px-5 mx-5">
        <div class="remove-card d-flex align-items-center justify-content-center py-4">
          <div class="text-center mb-5">
            <h4 class="mt-5">Recreate / Edit Strategy</h4>
            <i class="fa fa-exclamation fa-2x success-icon my-5"></i>
            <br>
            <p>Once you edit your strategy, the current prospective tenant list
              might change and might not be available to you.
            </p>
            <button type="button" class="btn create-strategy-btn px-5 text-uppercase my-3" data-bs-dismiss="modal" aria-label="Close">Yes, Refresh</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Brand Detail Modal -->
<!-- <div *ngIf="isStrategy" class="modal fade" id="tenantDetailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true"> -->
<div *ngIf="true" class="modal fade" id="tenantDetailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content p-3">
      <div class="modal-header mb-0 pb-0">
        <h5 class="modal-title text-uppercase">Tenant Detail</h5>
        <button type="button" class="btn-close btn-lg" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-3 mt-4">
        <div *ngIf="!prospective_tenant_detail_ready" class=" d-flex justify-content-center">
          <div class="spinner-border text-danger d-flex justify-content-center" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div *ngIf="prospective_tenant_detail_ready" class="row">
          <div class="col-md-12 col-lg-6 mb-3">
            <div class="detail-card p-4">
              <p class="content-text-lg text-uppercase">Brand Details</p>
              <div class="row">
                <div class="col-md-12 col-lg-6">
                  <div class="d-flex align-items-center mt-4">
                    <img *ngIf="prospective_tenant_detail['image']" src="{{ prospective_tenant_detail['image'] }}" class="pr-5 brand-image">
                    <img *ngIf="!prospective_tenant_detail['image']" src="../../assets/img/{{ prospective_tenant_detail['name'] }}.jpg" class="pr-5 brand-image">
                    <p class="p3 mb-0">{{ prospective_tenant_detail["name"] }}</p>
                  </div>
                </div>
                <div class="col-md-12 col-lg-6">
                  <div class="">
                    <p class="p4 pb-0 mb-1">
                      {{ prospective_tenant_detail["description"] }}
                    </p>
                    <p *ngIf="prospective_tenant_detail['radius_tenant_size']" class="p4 pb-0 mb-1"><span>Size: </span>
                      {{ prospective_tenant_detail["radius_tenant_size"]["name"] }}
                    </p>
                    <p *ngIf="!prospective_tenant_detail['radius_tenant_size']" class="p4 pb-0 mb-1"><span>Size: </span>
                      -
                    </p>
                    <p class="p4 pb-0 mb-1"><span>Nearest Branch: </span>
                      {{ prospective_tenant_detail['match']["nearest_branch"] }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-6 mb-3">
            <div class="detail-card d-flex justify-content-center align-items-center p-4">
              <div class="row d-flex justify-content-center align-items-center" style="width: 100%;">
                <div class="col-md-12 col-lg-4 border-right">
                  <div class="text-center">
                    <p class="p7 mb-1">{{ prospective_tenant_detail['match']["brand_affinity_score"] }}</p>
                    <div class="d-flex justify-content-center align-items-center">
                      <p class="p7">Brand Affinity <br> Score</p>
                      <div class="dropdown">
                        <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                          </svg>
                        </button>
                        <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                          <p class="info-text mb-0">Brand Affinty Score is
                            comparative score that meaures a brand's popularity
                            within the Catchment Area.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-4 border-right">
                  <div class="text-center">
                    <p class="p7 mb-1">{{ prospective_tenant_detail["follower_change"] }}</p>
                    <div class="d-flex justify-content-center align-items-center">
                      <p class="p7">Follower Change <br> (30 Days)</p>
                      <div class="dropdown">
                        <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                          </svg>
                        </button>
                        <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                          <p class="info-text mb-0">Increase or decrease in
                            Brand's online audience size (sources: Instgram,
                            Twitter and Facebook)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-4">
                  <div class="text-center">
                    <p class="p7 mb-1">{{ prospective_tenant_detail["engagement_rate"] }}</p>
                    <div class="d-flex justify-content-center align-items-center">
                      <p class="p7">Engagement<br> Rate</p>
                      <div class="dropdown">
                        <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                          </svg>
                        </button>
                        <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                          <p class="info-text mb-0">Percentage of brand's
                            audience interacts with the brand on online
                            channels, including Twitter, Instagram, Facebook,
                            FourSquare, Google and Tripadvisor.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12 mb-3">
            <div class="detail-card p-4">
              <div class="d-flex align-items-center">
                <p class="content-text-lg-dark text-uppercase px-2 py-1 mb-3">
                  Social DNA: {{ prospective_tenant_detail['match']["dna_score_label"] }}
                </p>
                <div class="dropdown mb-3">
                  <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                  </button>
                  <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                    <p class="info-text mb-0">The Social DNA Match score is the
                      correlation between the brand's offerings and the
                      preferences of the Catchment Area Audience.
                    </p>
                  </div>
                </div>
              </div>
              <p class="content-text">Social DNA match is the scoring algorithm
                that checks the correlation of prospective tenants audience with
                the consumers in the catchment area.
              </p>
              <div class="row d-flex justify-content-center mx-4 mt-4">
                <div class="col-md-12 col-lg-6">
                  <div class="d-flex align-items-center">
                    <p class="content-text-lg text-uppercase">Demographic Match</p>
                    <div class="dropdown mb-3">
                      <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>
                      </button>
                      <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                        <p class="info-text mb-0">The Demographic Match score
                          is the correlation between the demographics of the
                          brand's audience and the demographics of the
                          Catchment Area Audience.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center align-items-center mb-4" style="margin-right: 40px;">
                    <div class="col-md-12 col-lg-2">
                      <p class="p8 mb-0">Age</p>
                    </div>
                    <div class="col-md-12 col-lg-9">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: {{ prospective_tenant_detail['match']['age'] }}%" aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-1">
                      <p class="content-text-lg mb-0">{{ prospective_tenant_detail['match']['age'] }}%</p>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center align-items-center mb-4" style="margin-right: 40px;">
                    <div class="col-md-12 col-lg-2">
                      <p class="p8 mb-0">Gender</p>
                    </div>
                    <div class="col-md-12 col-lg-9">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: {{ prospective_tenant_detail['match']['gender'] }}%" aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-1">
                      <p class="content-text-lg mb-0">{{ prospective_tenant_detail['match']['gender'] }}%</p>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center align-items-center mb-4" style="margin-right: 40px;">
                    <div class="col-md-12 col-lg-2">
                      <p class="p8 mb-0">Income</p>
                    </div>
                    <div class="col-md-12 col-lg-9">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: {{ prospective_tenant_detail['match']['income'] }}%" aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-1">
                      <p class="content-text-lg mb-0">{{ prospective_tenant_detail['match']['income'] }}%</p>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center align-items-center mb-4" style="margin-right: 40px;">
                    <div class="col-md-12 col-lg-2">
                      <p class="p8 mb-0">Location</p>
                    </div>
                    <div class="col-md-12 col-lg-9">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: {{ prospective_tenant_detail['match']['location'] }}%" aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-1">
                      <p class="content-text-lg mb-0">{{ prospective_tenant_detail['match']['location'] }}%</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-6"  style="padding-left: 40px;">
                  <div class="d-flex align-items-center">
                    <p class="content-text-lg text-uppercase">Lifestyle Match</p>
                    <div class="dropdown mb-3">
                      <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>
                      </button>
                      <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                        <p class="info-text mb-0">The Lifestyle Match score is
                          the correlation between the psychographics of the
                          brand's audience and the psychographics of the
                          Catchment Area Audience.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center align-items-center mb-4">
                    <div class="col-md-12 col-lg-2">
                      <p class="p8 mb-0">Likes</p>
                    </div>
                    <div class="col-md-12 col-lg-9">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: {{ prospective_tenant_detail['match']['likes'] }}%" aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-1">
                      <p class="content-text-lg mb-0">{{ prospective_tenant_detail['match']['likes'] }}%</p>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center align-items-center mb-4">
                    <div class="col-md-12 col-lg-2">
                      <p class="p8 mb-0">Brands</p>
                    </div>
                    <div class="col-md-12 col-lg-9">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: {{ prospective_tenant_detail['match']['brands'] }}%" aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-1">
                      <p class="content-text-lg mb-0">{{ prospective_tenant_detail['match']['brands'] }}%</p>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center align-items-center mb-4">
                    <div class="col-md-12 col-lg-2">
                      <p class="p8 mb-0">Influencers</p>
                    </div>
                    <div class="col-md-12 col-lg-9">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: {{ prospective_tenant_detail['match']['influencers'] }}%" aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-1">
                      <p class="content-text-lg mb-0">{{ prospective_tenant_detail['match']['influencers'] }}%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12 mb-3">
            <div class="detail-card p-4">
              <p class="content-text-lg text-uppercase">
                Audience
              </p>
              <div class="row px-3">
                <div class="col-md-12 col-lg-4 px-5 mb-4">
                  <p class="content-text text-uppercase p-3">Age</p>
                  <div class="chartjs-container m-1">
                    <canvas baseChart [data]="ageData" [labels]="ageDataLabels" [colors]="ageDataColors" [options]="ageOptions" chartType="horizontalBar">
                    </canvas>
                  </div>
                </div>
                <div class="col-md-12 col-lg-4 px-5">
                  <p class="content-text text-uppercase p-3">Personal Income</p>
                  <div class="chartjs-container m-1">
                    <canvas baseChart [data]="personalIncomeData" [labels]="personalIncomeDataLabels" [colors]="personalIncomeDataColors" [options]="personalIncomeOptions" chartType="horizontalBar">
                    </canvas>
                  </div>
                </div>
                <div class="col-md-12 col-lg-4 px-5">
                  <p class="content-text text-uppercase p-3">Gender</p>
                  <div class="chartjs-container m-1">
                    <canvas baseChart [data]="genderData" [labels]="genderDataLabels" [colors]="genderDataColors" [options]="genderOptions" chartType="horizontalBar">
                    </canvas>
                  </div>
                </div>
              </div>
              <div class="row px-5 mt-3 mb-1">
                <div class="col-md-12 col-lg-6 mb-4">
                  <p class="content-text text-uppercase p-3">Occupations</p>
                  <div *ngIf="!isScrollDivOccupation">
                    <button *ngFor="let item of occupations_;" type="button" class="btn btn-light occupation-btn p-2 m-1" data-toggle="tooltip" data-placement="top" title="{{ item['value'] }}">{{ item["name"] }}</button>
                    <button *ngIf="isMoreOccupation" type="button" class="btn btn-light more-btn text-uppercase m-0" (click)="moreOccupation()"><u>More</u></button>
                  </div>
                  <div *ngIf="isScrollDivOccupation" class="scroll-div">
                    <button *ngFor="let item of occupations_;" type="button" class="btn btn-light occupation-btn p-2 m-1" data-toggle="tooltip" data-placement="top" title="{{ item['value'] }}">{{ item["name"] }}</button>
                    <button *ngIf="isMoreOccupation" type="button" class="btn btn-light more-btn text-uppercase m-0" (click)="moreOccupation()"><u>More</u></button>
                  </div>
                </div>
                <div class="col-md-12 col-lg-6 mb-4">
                  <p class="content-text text-uppercase p-3">Likes & Interests</p>
                  <div *ngIf="!isScrollDivLike">
                    <button *ngFor="let item of like_and_interests;" type="button" class="btn btn-light occupation-btn p-2 m-1" data-toggle="tooltip" data-placement="top" title="{{ item['value'] }}">{{ item["name"] }}</button>
                    <button *ngIf="isMoreLike" type="button" class="btn btn-light more-btn text-uppercase m-0" (click)="moreLike()"><u>More</u></button>
                  </div>
                  <div *ngIf="isScrollDivLike" class="scroll-div">
                    <button *ngFor="let item of like_and_interests;" type="button" class="btn btn-light occupation-btn p-2 m-1" data-toggle="tooltip" data-placement="top" title="{{ item['value'] }}">{{ item["name"] }}</button>
                    <button *ngIf="isMoreLike" type="button" class="btn btn-light more-btn text-uppercase m-0" (click)="moreLike()"><u>More</u></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12 mb-3">
            <div class="detail-card p-4">
              <p class="content-text-lg text-uppercase">
                Sample Posts
              </p>
              <div id="carouselExampleControls" class="carousel slide my-4" data-bs-ride="carousel">
                <div class="div d-flex justify-content-center">
                  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-bs-slide="prev" style="background-color: #fff !important;">
                    <span class="carousel-control-prev-icon" aria-hidden="true" style="width: 1rem; height: 1rem;"></span>
                  </a>
                  <div class="carousel-inner mt-2 mx-5">
                    <div *ngIf="prospective_tenant_detail['post_images'].length > 0" class="carousel-item active">
                      <div class="row d-flex justify-content-center align-items-center p-1">
                        <div *ngFor="let image of prospective_tenant_detail['post_images'].slice(0,3)"class="col-md-12 col-lg-4" align="center">
                          <img src="{{ image }}" class="img-fluid post-image">
                        </div>
                      </div>
                    </div>
                    <div *ngIf="prospective_tenant_detail['post_images'].length > 3" class="carousel-item">
                      <div class="row d-flex justify-content-center align-items-center p-1">
                        <div *ngFor="let image of prospective_tenant_detail['post_images'].slice(3,6)" class="col-md-12 col-lg-4" align="center">
                          <img src="{{ image }}" class="img-fluid post-image">
                        </div>
                      </div>
                    </div>
                    <div *ngIf="prospective_tenant_detail['post_images'].length > 6"class="carousel-item">
                      <div class="row d-flex justify-content-center align-items-center p-1">
                        <div *ngFor="let image of prospective_tenant_detail['post_images'].slice(6,9)" class="col-md-12 col-lg-4" align="center">
                          <img src="{{ image }}" class="img-fluid post-image">
                        </div>
                      </div>
                    </div>
                    <div *ngIf="prospective_tenant_detail['post_images'].length > 9"class="carousel-item">
                      <div class="row d-flex justify-content-center align-items-center p-1">
                        <div *ngFor="let image of prospective_tenant_detail['post_images'].slice(9,12)" class="col-md-12 col-lg-4" align="center">
                          <img src="{{ image }}" class="img-fluid post-image">
                        </div>
                      </div>
                    </div>
                  </div>
                  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-bs-slide="next" style="background-color: #fff !important;">
                    <span class="carousel-control-next-icon" aria-hidden="true" style="width: 1rem; height: 1rem;"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12 mb-3">
            <div class="detail-card p-4">
              <p class="content-text-lg text-uppercase">
                Contact details
              </p>
              <div class="">
                <p class="p4 pb-0 mb-2"><span>Website: </span>
                  <a href="{{ prospective_tenant_detail['website'] }}" target="_blank">{{ prospective_tenant_detail['website'] }}</a>
                </p>
                <p class="p4 pb-0 mb-2"><span>Email: </span>
                  <a href="mailto: {{ prospective_tenant_detail['email'] }}" target="_blank">{{ prospective_tenant_detail['email'] }}</a>
                </p>
                <p class="p4 pb-0 mb-2"><span>Phone Number: </span>
                  {{ prospective_tenant_detail['phone'] }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12 mb-3">
            <div class="d-flex justify-content-center mt-3">
              <p class="filter-text text-uppercase cursor-pointer" data-bs-dismiss="modal" aria-label="Close"><span style="margin-right: 10px;"><i class="fa fa-redo"></i></span><u>Refresh Match</u></p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer mt-2 pb-0" style="justify-content: space-between;">
        <p class="footer-text text-uppercase cursor-pointer mr-auto" data-bs-dismiss="modal" aria-label="Close"><u>Remove Match From The List</u></p>
        <button type="submit" class="btn add-btn text-uppercase px-5 mb-0 my-2" data-bs-dismiss="modal" aria-label="Close">Generate Fact Sheet</button>
      </div>
    </div>
  </div>
</div>

<!-- Best Modal -->
<!-- <div *ngIf="isStrategy" class="modal fade" id="bestFitModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true"> -->
<div *ngIf="true" class="modal fade" id="bestFitModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content p-3">
      <div class="modal-header mb-0 pb-0">
        <h5 class="modal-title text-uppercase">Discover The Next Best Fit Prospective Tenant</h5>
        <button type="button" class="btn-close btn-lg" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-3 mt-4">
        <p class="add-category-title">Please select among existing categories to add new prospective tenant.</p>
        <div class="row">
          <div *ngFor="let bf of bestfits;  let i = index" class="col-md-12 col-lg-4">
            <div class="category-card2 d-flex justify-content-center align-items-center cursor-pointer p-3 mb-3" (click)="addBestFit(i)">
              <p class="pb-0 mb-0">{{ bf['name'] }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer mt-2 pb-0">
        <button style="float: right;" type="submit" class="btn add-btn text-uppercase px-5 mb-0 my-2" data-bs-dismiss="modal" aria-label="Close" [disabled]="bestFitButtonDisabled">Discover</button>
      </div>
    </div>
  </div>
</div>
