import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';

import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-brand-radar-demo',
  templateUrl: './brand-radar-demo.component.html',
  styleUrls: ['./brand-radar-demo.component.css']
})
export class BrandRadarDemoComponent implements OnInit {


  selectedProjectId: Number;
  selectedBrand = {};

  categories = [];
  sizes = [];
  strategy_focuses = [];

  statuses = [
    {"id": "trending", "name": "Trending"},
    {"id": "stable", "name": "Stable"},
    {"id": "slowing-down", "name": "Slowing Down"},
    {"id": "declining", "name": "Declining"},
  ];

  orders = [
    {"id": "instagram_account", "name": "Name", "isSelected": false},
    {"id": "rank", "name": "Rank", "isSelected": false},
    {"id": "follower_count", "name": "Follower Count", "isSelected": false},
    {"id": "google_rating", "name": "Review Rating", "isSelected": false},
    {"id": "popularity_score_month", "name": "Popularity Score-30 days", "isSelected": false},
    {"id": "popularity_score_year", "name": "Popularity Score-1 year", "isSelected": false}
  ];

  groups = [
    {"id": "categories", "name": "Categories"}
  ];

  categoryForm: FormGroup;
  searchTextCategory = "";
  searchedCategories = []
  selectedCategoryCount = 0;

  sizeForm: FormGroup;
  selectedSizeCount = 0;

  strategyFocusForm: FormGroup;
  selectedStrategyFocusCount = 0;

  statusForm: FormGroup;
  selectedStatusCount = 0;

  orderForm: FormGroup;
  selectedOrder;

  groupForm: FormGroup;
  selectedGroup = "";

  is_brands_ready: boolean = false;
  is_category_ui: boolean = false;

  brands = [];

  filterPostData = {};

  constructor (
      public fb: FormBuilder,
      private dataservice: DataService,
      private router: Router
  ) { }

  ngOnInit(): void {
    let current_route = this.router.url
    let name = current_route.split("/")[1]
    this.dataservice.getProject(name).then((project: any)  => {
      this.selectedProjectId = project["id"]
      this.getBrands(this.selectedProjectId)
    });
    this.filterPostData["order_by"] = ["rank"];
    this.getCategories()
    this.getSizes()
    this.getStrategyFocuses()
  }

  getCategories() {
    this.dataservice.getCategories().then((value: any[]) => {
      this.categories = value;
      this.categoryForm = this.fb.group({});
      let categoryGroup = {};
      this.categories.forEach(x=>{
        categoryGroup[x["id"]]=new FormControl('');
      })
      this.categoryForm = this.fb.group(categoryGroup);
    })

    // STATUS
    this.statusForm = this.fb.group({});
    let statusGroup = {};
    this.statuses.forEach(x=>{
      statusGroup[x["id"]]=new FormControl('');
    })
    this.statusForm = this.fb.group(statusGroup);


    // ORDER
    this.orderForm = this.fb.group({});
    let orderGroup = {};
    this.orders.forEach(x=>{
      orderGroup[x["id"]]=new FormControl('');
    })
    this.orderForm = this.fb.group(orderGroup);

    // GROUP
    this.groupForm = this.fb.group({});
    let groupGroup = {};
    this.groups.forEach(x=>{
      groupGroup[x["id"]]=new FormControl('');
    })
    this.groupForm = this.fb.group(groupGroup);
  }

  getSizes() {
    this.dataservice.getSizes().then((value: any[]) => {
      this.sizes = value;
      this.sizeForm = this.fb.group({});
      let sizeGroup = {};
      this.sizes.forEach(x=>{
        sizeGroup[x["id"]]=new FormControl('');
      })
      this.sizeForm = this.fb.group(sizeGroup);
    })
  }

  getStrategyFocuses() {
    this.dataservice.getStrategyFocuses().then((value: any[]) => {
      this.strategy_focuses = value;
      this.strategyFocusForm = this.fb.group({});
      let strategyFocusGroup = {};
      this.strategy_focuses.forEach(x=>{
        strategyFocusGroup[x["id"]]=new FormControl('');
      })
      this.strategyFocusForm = this.fb.group(strategyFocusGroup);
    })
  }

  searchCategory() {
    this.searchedCategories = this.categories
  }

  categorySearchFilter() {
    this.selectedCategoryCount = 0;
    let form_values = this.categoryForm.value;
    for (let item of this.categories) {
      if (form_values[item["id"]] == true) {
        this.selectedCategoryCount = this.selectedCategoryCount + 1
      }
    }
    this.applyBrandFilters("category")
  }

  statusSearchFilter() {
    this.selectedStatusCount = 0;
    let form_values = this.statusForm.value;
    for (let item of this.statuses) {
      if (form_values[item["id"]] == true) {
        this.selectedStatusCount = this.selectedStatusCount + 1
      }
    }
    this.applyBrandFilters("status")
  }

  sizeSearchFilter() {
    this.selectedSizeCount = 0;
    let form_values = this.sizeForm.value;
    for (let item of this.sizes) {
      if (form_values[item["id"]] == true) {
        this.selectedSizeCount = this.selectedSizeCount + 1
      }
    }
    this.applyBrandFilters("size")
  }

  strategyFocusSearchFilter() {
    this.selectedStrategyFocusCount = 0;
    let form_values = this.strategyFocusForm.value;
    for (let item of this.strategy_focuses) {
      if (form_values[item["id"]] == true) {
        this.selectedStrategyFocusCount = this.selectedStrategyFocusCount + 1
      }
    }
    this.applyBrandFilters("strategy_focus")
  }

  selectOrder(order) {
    if (this.selectedOrder == order) {
      for (let i in this.orders) {
        this.orders[i]["isSelected"] = false;
      }
    } else {
      for (let i in this.orders) {
        if (this.orders[i]["id"] == order) {
          this.orders[i]["isSelected"] = true;
          this.selectedOrder = order;
        } else {
          this.orders[i]["isSelected"] = false;
        }
      }
    }
    this.applyBrandFilters('order_by');
    setTimeout(() => {
      let orderDropdown: HTMLElement = document.getElementsByClassName("order-dropdown")[0] as HTMLElement
      orderDropdown.className = "dropdown-menu order-dropdown px-3 py-4 my-2";
      let orderDropdownLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[0] as HTMLElement
      orderDropdownLink.setAttribute('aria-expanded', 'false');
    }, 20);
  }

  returnOrderText(order) {
    for (let item of this.orders) {
      if (item["id"] == order) {
        return item["name"]
      }
    }
  }

  orderSearchFilter() {
    this.selectedOrder = "";
    let form_values = this.orderForm.value;
    for (let item of this.orders) {
      if (form_values[item["id"]] == true) {
        if (this.selectedOrder == "") {
          this.selectedOrder = this.selectedOrder + item["name"]
        } else {
          this.selectedOrder = this.selectedOrder + "," + item["name"]
        }
      }
    }
    this.applyBrandFilters("order_by")
  }

  groupSearchFilter() {
    this.selectedGroup = "";
    this.is_category_ui = false;
    let form_values = this.groupForm.value;
    for (let item of this.groups) {
      if (form_values[item["id"]] == true) {
        if (this.selectedGroup == "") {
          this.selectedGroup = this.selectedGroup + item["name"]
        } else {
          this.selectedGroup = this.selectedGroup + "," + item["name"]
        }
        if (item["id"] == "categories") {
          this.is_category_ui = true;
        }
      }
    }
    this.applyBrandFilters("group_by")
  }

  getBrands(project_id) {
    this.dataservice.getBrandsDemo(project_id, this.filterPostData).then((value: any[]) => {
      this.brands = value;
      this.is_brands_ready = true;
    })
  }

  getBrand(brand) {
    this.selectedBrand = {};
    console.log(1111111111, brand)
    this.selectedBrand = brand;
    console.log(this.selectedBrand)
  }

  getBrandColor(status) {
    let color_mapping = {
      "slowing-down": ["gray-border", "gray-background"],
      "trending": ["red-border", "red-background"],
      "declining": ["black-border", "black-background"],
      "stable": ["light-red-border", "light-red-background"]
    }
    return color_mapping[status]
  }

  applyBrandFilters(type) {
    this.is_brands_ready = false;
    if (type == "category") {
      let categoryIds = []
      for (let i in this.categoryForm.value) {
        if (this.categoryForm.value[i] == true) {
          categoryIds.push(Number(i))
        }
      }
      this.filterPostData["categories"] = categoryIds
    }
    else if (type == "status") {
      let statusIds = []
      for (let i in this.statusForm.value) {
        if (this.statusForm.value[i] == true) {
          statusIds.push(i)
        }
      }
      this.filterPostData["statuses"] = statusIds
    }
    else if (type == "size") {
      let sizeIds = []
      for (let i in this.sizeForm.value) {
        if (this.sizeForm.value[i] == true) {
          sizeIds.push(i)
        }
      }
      this.filterPostData["sizes"] = sizeIds
    }
    else if (type == "strategy_focus") {
      let strategyFocusIds = []
      for (let i in this.strategyFocusForm.value) {
        if (this.strategyFocusForm.value[i] == true) {
          strategyFocusIds.push(i)
        }
      }
      this.filterPostData["strategy_focuses"] = strategyFocusIds
    }
    else if (type == "order_by") {
      let orderIds = []
      for (let order of this.orders) {
        if (order["isSelected"] == true) {
          orderIds.push(order["id"])
        }
      }
      this.filterPostData["order_by"] = orderIds
      if (this.filterPostData["order_by"] == 0) {
        this.selectedOrder = "";
        this.filterPostData["order_by"] = ["rank"];
      }
    }
    else if (type == "group_by") {
      let groupIds = []
      for (let i in this.groupForm.value) {
        if (this.groupForm.value[i] == true) {
          groupIds.push(i)
        }
      }
      this.filterPostData["group_by"] = groupIds
    }
    this.getBrands(this.selectedProjectId)
  }

  resetFilters() {
    this.is_brands_ready = false;
    this.categoryForm.reset();
    this.statusForm.reset();
    this.sizeForm.reset();
    this.strategyFocusForm.reset();
    this.orderForm.reset();
    this.groupForm.reset();
    this.selectedCategoryCount = 0;
    this.selectedStatusCount = 0;
    this.selectedSizeCount = 0;
    this.selectedStrategyFocusCount = 0;
    this.selectedOrder = "";
    this.selectedGroup = "";
    this.filterPostData = {};
    this.filterPostData["order_by"] = ["rank"];
    this.is_category_ui = false;
    this.getBrands(this.selectedProjectId)
  }

  toTitleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
  }

  milesSeperator(value) {
    if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
}
