import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FilterPipe } from './pipes/filter.pipe';
import { SafePipe } from './pipes/safe.pipe';

import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NeareComponent } from './components/neare/neare.component';
import { CatchmentComponent } from './components/neare/catchment/catchment.component';
import { LeasingComponent } from './components/neare/leasing/leasing.component';
import { EngagementComponent } from './components/neare/engagement/engagement.component';
import { AudienceComponent } from './components/neare/audience/audience.component';
import { EngagementActivityComponent } from './components/neare/engagement-activity/engagement-activity.component';
import { ArchitectureComponent } from './components/neare/architecture/architecture.component';
import { BrandRadarComponent } from './components/neare/brand-radar/brand-radar.component';
import { BrandRadarDemoComponent } from './components/neare/brand-radar-demo/brand-radar-demo.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ProjectComponent } from './components/settings/project/project.component';
import { LogoComponent } from './components/logo/logo.component';
import { VideoComponent } from './components/video/video.component';
import { HealthComponent } from './components/health/health.component';
import { PingComponent } from './components/ping/ping.component';
import { WidgetComponent } from './components/widget/widget.component';
import { Widget2Component } from './components/widget2/widget2.component';
import { EmbedComponent } from './components/embed/embed.component';

import { DataService } from './services/data.service';
import { ContactService } from './services/contact.service';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { ChartsModule } from 'ng2-charts';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { TreeModule } from '@circlon/angular-tree-component';
import { NgxOutsideClickModule } from 'ngx-outside-click';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NeareComponent,
    HeaderComponent,
    CatchmentComponent,
    LeasingComponent,
    EngagementComponent,
    AudienceComponent,
    EngagementActivityComponent,
    ArchitectureComponent,
    BrandRadarComponent,
    BrandRadarDemoComponent,
    DashboardComponent,
    LogoComponent,
    VideoComponent,
    FilterPipe,
    SafePipe,
    HealthComponent,
    PingComponent,
    SettingsComponent,
    ProjectComponent,
    WidgetComponent,
    Widget2Component,
    EmbedComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    LeafletModule,
    TreeModule,
    NgxOutsideClickModule,
    NgxDnDModule.forRoot(),
    NgxSliderModule
  ],
  providers: [
    DataService,
    ContactService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
