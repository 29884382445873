import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';

import { DataService } from 'src/app/services/data.service';

import { ITreeOptions } from '@circlon/angular-tree-component';

@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.css']
})
export class AudienceComponent implements OnInit {

  project_name = "";
  selectedProject = {};

  by_benchmark = "btn-light light-btn"

  audience = [];
  brandCategories = [];

  benchmarks = [];
  selectedBenchmark;
  selectedBrandCategoryCount = 0;
  selectedBenchmarkCount = 0;

  isBenchmarkDataReady: boolean = false;
  isFilterDataReady: boolean = false;

  isMultiple: boolean = false;

  filterPostData = {};

  benchmarkForm: FormGroup;
  brandCategoryForm: FormGroup;

  ageDataLabels = [];
  ageData = [];
  ageDataColors = [];
  ageOptions = {};

  personalIncomeDataLabels = [];
  personalIncomeData = [];
  personalIncomeDataColors = [];
  personalIncomeOptions = {};

  genderDataLabels = [];
  genderData = [];
  genderDataColors = [];
  genderOptions = {};

  familyDataLabels = [];
  familyData = [];
  familyDataColors = [];
  familyOptions = {};

  countryDataLabels = [];
  countryData = [];
  countryDataColors = [];
  countryOptions = {};

  cityDataLabels = [];
  cityData = [];
  cityDataColors = [];
  cityOptions = {};

  occupations = [];
  all_occupations = [];
  isMoreOccupation: boolean = true;
  isScrollDivOccupation: boolean = false;

  like_and_interesets = [];
  all_like_and_interesets = [];
  isMoreLike: boolean = true;
  isScrollDivLike: boolean = false;

  top_brands = [];
  all_top_brands = [];
  isMoreTopBrands: boolean = true;
  isScrollDivTopBrands: boolean = false;

  mostly_liked_celebrities = [];
  all_mostly_liked_celebrities = [];
  isMoreCelebrity: boolean = true;
  isScrollDivCelebrity: boolean = false;

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private dataservice: DataService
  ) { }

  ngOnInit(): void {
    this.brandCategoryForm = this.fb.group({});
    this.benchmarkForm = this.fb.group({});
    this.getBenchmarks()
    this.getBrandCategories()
    this.getInitalAudience()
    this.getBrandCategories()
  }

  getInitalAudience() {
    let current_route = this.router.url
    this.project_name = current_route.split("/")[1]
    this.filterPostData["project"] = this.project_name
    this.dataservice.getAudience(this.filterPostData).then((value: any[]) => {
      this.audience = value;
      this.getInıtialChartData()
    })
  }

  getBenchmarks() {
    this.benchmarks = [];
    let current_route = this.router.url
    this.project_name = current_route.split("/")[1]
    this.dataservice.getProject(this.project_name).then((project: any)  => {
      this.selectedProject = project;
      this.dataservice.getBenchmarks(this.selectedProject["id"]).then((value: any[]) => {
        for (let benchmark of value) {
          this.benchmarks.push({
            "id": benchmark["demographic_constraint"]["id"],
            "name": benchmark["demographic_constraint"]["name"],
            "isSelected": false
          })
        }
        this.isBenchmarkDataReady = true;
        this.isFilterDataReady = true;
        // create dynamic benchmark form
        let benchmarkGroup = {};
        this.benchmarks.forEach(x=>{
          benchmarkGroup[x["id"]]=new FormControl('');
        })
        this.benchmarkForm = this.fb.group(benchmarkGroup);
      })
    })
  }

  brandCategorySearchFilter() {
    this.selectedBrandCategoryCount = 0;
    let form_values = this.brandCategoryForm.value;
    for (let item of this.brandCategories) {
      if (form_values[item["id"]] == true) {
        this.selectedBrandCategoryCount = this.selectedBrandCategoryCount + 1
      }
    }
  }

  getAgeData() {
    if (this.audience["ages"]) {
      this.ageDataLabels = [];
      this.ageData = [];
      for (let age of Object.keys(this.audience["ages"])) {
        this.ageDataLabels.push(age)
        this.ageData.push(this.audience["ages"][age])
      }

      this.ageDataColors = [
        { backgroundColor: '#ECB4AE' }
      ]

      this.ageOptions = {
        responsive: true,
        legend: { display: false },
        scales: {
          xAxes: [{
            ticks: { display: false, beginAtZero:true },
        }],
          yAxes: [{
            barPercentage : 0.6,
            gridLines: { display: false, drawBorder: false }
          }]
        },
        tooltips: {
          xPadding: 10,
          yPadding: 10,
          bodyFontSize: 15,
          bodySpacing: 10,
          callbacks: {
            label : function(tooltipItem, data) {
              var dataset = data["datasets"][0];
              var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
               return previousValue + currentValue;
              });
              var currentValue = dataset["data"][tooltipItem.index];
              var percentage = Math.floor(((currentValue/total) * 100)+0.5);
              return " (" + percentage + "%)";
            }
          }
        }
      };
    } else {
      this.getAgeData2()
    }
  }

  getAgeData2() {
    this.isMultiple = true;
    this.ageDataLabels = [];
    this.ageData = [];

    this.ageData = [
      {
        data: [],
        label: this.project_name
      },
      {
        data: [],
        label: this.filterPostData["benchmarks"][0]
      },
    ];

    for (let age of Object.keys(this.audience[this.filterPostData["benchmarks"][0]]["ages"])) {
      this.ageDataLabels.push(age)
      this.ageData[0]["data"].push(this.audience[this.project_name]["ages"][age])
    }

    for (let age of Object.keys(this.audience[this.filterPostData["benchmarks"][0]]["ages"])) {
      this.ageData[1]["data"].push(this.audience[this.filterPostData["benchmarks"][0]]["ages"][age])
    }
    this.ageDataColors = [
      { backgroundColor: '#ECB4AE' },
      { backgroundColor: '#E05B4C' },
    ]

    this.ageOptions = {
      responsive: true,
      borderRadius: 20,
      scales: {
        xAxes: [{
          stacked: true,
      }],
        yAxes: [{
          stacked: true,
          barPercentage: 0.6,
          gridLines: { display: false, drawBorder: false }
        }]
      },
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
          boxWidth: 6
        }
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
        callbacks: {
          label : function(tooltipItem, data) {
            var dataset = data["datasets"][tooltipItem.datasetIndex];
            var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
             return previousValue + currentValue;
            });
            var currentValue = dataset["data"][tooltipItem.index];
            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
            return dataset["label"] + " (" + percentage + "%)";
          }
        }
      }
    };
  }

  getPersonalIncomeData() {
    if (this.audience["personal_incomes"]) {
      this.personalIncomeDataLabels = [];
      this.personalIncomeData = [];
      for (let pi of Object.keys(this.audience["personal_incomes"])) {
        this.personalIncomeDataLabels.push(pi)
        this.personalIncomeData.push(this.audience["personal_incomes"][pi])
      }

      this.personalIncomeDataColors = [
        { backgroundColor: '#ECB4AE' }
      ]

      this.personalIncomeOptions = {
        responsive: true,
        legend: { display: false },
        scales: {
          xAxes: [{
            ticks: { display: false, beginAtZero:true },
        }],
          yAxes: [{
            barPercentage : 0.6,
            gridLines: { display: false, drawBorder: false }
          }]
        },
        tooltips: {
          xPadding: 10,
          yPadding: 10,
          bodyFontSize: 15,
          bodySpacing: 10,
          callbacks: {
            label : function(tooltipItem, data) {
              var dataset = data["datasets"][0];
              var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
               return previousValue + currentValue;
              });
              var currentValue = dataset["data"][tooltipItem.index];
              var percentage = Math.floor(((currentValue/total) * 100)+0.5);
              return " (" + percentage + "%)";
            }
          }
        }
      };
    } else {
      this.getPersonalIncomeData2()
    }
  }

  getPersonalIncomeData2() {
    this.personalIncomeDataLabels = [];
    this.personalIncomeData = [];

    this.personalIncomeData = [
      {
        data: [],
        label: this.project_name
      },
      {
        data: [],
        label: this.filterPostData["benchmarks"][0]
      },
    ];

    for (let personal_income of Object.keys(this.audience[this.filterPostData["benchmarks"][0]]["personal_incomes"])) {
      this.personalIncomeDataLabels.push(personal_income)
      this.personalIncomeData[0]["data"].push(this.audience[this.project_name]["personal_incomes"][personal_income])
    }

    for (let personal_income of Object.keys(this.audience[this.filterPostData["benchmarks"][0]]["personal_incomes"])) {
      this.personalIncomeData[1]["data"].push(this.audience[this.filterPostData["benchmarks"][0]]["personal_incomes"][personal_income])
    }
    this.personalIncomeDataColors = [
      { backgroundColor: '#ECB4AE' },
      { backgroundColor: '#E05B4C' },
    ]

    this.personalIncomeOptions = {
      responsive: true,
      borderRadius: 20,
      scales: {
        xAxes: [{
          stacked: true,
      }],
        yAxes: [{
          stacked: true,
          barPercentage: 0.6,
          gridLines: { display: false, drawBorder: false }
        }]
      },
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
          boxWidth: 6
        }
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
        callbacks: {
          label : function(tooltipItem, data) {
            var dataset = data["datasets"][tooltipItem.datasetIndex];
            var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
             return previousValue + currentValue;
            });
            var currentValue = dataset["data"][tooltipItem.index];
            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
            return dataset["label"] + " (" + percentage + "%)";
          }
        }
      }
    };
  }

  getGenderData() {
    if (this.audience["genders"]) {
      this.genderDataLabels = [];
      this.genderData = [];
      for (let gender of Object.keys(this.audience["genders"])) {
        this.genderDataLabels.push(gender)
        this.genderData.push(this.audience["genders"][gender])
      }

      this.genderDataColors = [
        { backgroundColor: ['#ECB4AE', '#938E9E', '#42404a'] }
      ]

      this.genderOptions = {
        responsive: true,
        legend: { display: false },
        scales: {
          xAxes: [{
            ticks: { display: false, beginAtZero:true },
        }],
          yAxes: [{
            barPercentage : 0.6,
            gridLines: { display: false, drawBorder: false }
          }]
        },
        tooltips: {
          xPadding: 10,
          yPadding: 10,
          bodyFontSize: 15,
          bodySpacing: 10,
          callbacks: {
            label : function(tooltipItem, data) {
              var dataset = data["datasets"][0];
              var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
               return previousValue + currentValue;
              });
              var currentValue = dataset["data"][tooltipItem.index];
              var percentage = Math.floor(((currentValue/total) * 100)+0.5);
              return " (" + percentage + "%)";
            }
          }
        }
      };
    } else {
      this.getGenderData2()
    }
  }

  getGenderData2() {
    this.genderDataLabels = [];
    this.genderData = [];

    this.genderData = [
      {
        data: [],
        label: this.project_name
      },
      {
        data: [],
        label: this.filterPostData["benchmarks"][0]
      },
    ];

    for (let gender of Object.keys(this.audience[this.filterPostData["benchmarks"][0]]["genders"])) {
      this.genderDataLabels.push(gender)
      this.genderData[0]["data"].push(this.audience[this.project_name]["genders"][gender])
    }

    for (let gender of Object.keys(this.audience[this.filterPostData["benchmarks"][0]]["genders"])) {
      this.genderData[1]["data"].push(this.audience[this.filterPostData["benchmarks"][0]]["genders"][gender])
    }
    this.genderDataColors = [
      { backgroundColor: ['#ECB4AE', '#938E9E', '#42404a'] },
      { backgroundColor: ['#E05B4C', '#615e69', '#050505'] },
    ]

    this.genderOptions = {
      responsive: true,
      borderRadius: 20,
      scales: {
        xAxes: [{
          stacked: true,
      }],
        yAxes: [{
          stacked: true,
          barPercentage: 0.6,
          gridLines: { display: false, drawBorder: false }
        }]
      },
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
          boxWidth: 6
        }
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
        callbacks: {
          label : function(tooltipItem, data) {
            var dataset = data["datasets"][tooltipItem.datasetIndex];
            var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
             return previousValue + currentValue;
            });
            var currentValue = dataset["data"][tooltipItem.index];
            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
            return dataset["label"] + " (" + percentage + "%)";
          }
        }
      }
    };
  }

  getFamilyData() {
    if (this.audience["marital_status"]) {
      this.familyDataLabels = [];
      this.familyData = [];
      for (let status of Object.keys(this.audience["marital_status"])) {
        this.familyDataLabels.push(status)
        this.familyData.push(this.audience["marital_status"][status])
      }

      this.familyDataColors = [
        { backgroundColor: ['#ECB4AE', '#938E9E', '#42404a'] }
      ]

      this.familyOptions = {
        responsive: true,
        legend: { display: false },
        scales: {
          xAxes: [{
            ticks: { display: false, beginAtZero:true },
        }],
          yAxes: [{
            barPercentage : 0.6,
            gridLines: { display: false, drawBorder: false }
          }]
        },
        tooltips: {
          xPadding: 10,
          yPadding: 10,
          bodyFontSize: 15,
          bodySpacing: 10,
          callbacks: {
            label : function(tooltipItem, data) {
              var dataset = data["datasets"][0];
              var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
               return previousValue + currentValue;
              });
              var currentValue = dataset["data"][tooltipItem.index];
              var percentage = Math.floor(((currentValue/total) * 100)+0.5);
              return " (" + percentage + "%)";
            }
          }
        }
      };
    } else {
      this.getFamilyData2()
    }
  }

  getFamilyData2() {
    this.familyDataLabels = [];
    this.familyData = [];

    this.familyData = [
      {
        data: [],
        label: this.project_name
      },
      {
        data: [],
        label: this.filterPostData["benchmarks"][0]
      },
    ];

    for (let status of Object.keys(this.audience[this.filterPostData["benchmarks"][0]]["marital_status"])) {
      this.familyDataLabels.push(status)
      this.familyData[0]["data"].push(this.audience[this.project_name]["marital_status"][status])
    }

    for (let status of Object.keys(this.audience[this.filterPostData["benchmarks"][0]]["marital_status"])) {
      this.familyData[1]["data"].push(this.audience[this.filterPostData["benchmarks"][0]]["marital_status"][status])
    }
    this.familyDataColors = [
      { backgroundColor: ['#ECB4AE', '#938E9E', '#42404a'] },
      { backgroundColor: ['#E05B4C', '#615e69', '#050505'] },
    ]

    this.familyOptions = {
      responsive: true,
      borderRadius: 20,
      scales: {
        xAxes: [{
          stacked: true,
      }],
        yAxes: [{
          stacked: true,
          barPercentage: 0.6,
          gridLines: { display: false, drawBorder: false }
        }]
      },
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
          boxWidth: 6
        }
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
        callbacks: {
          label : function(tooltipItem, data) {
            var dataset = data["datasets"][tooltipItem.datasetIndex];
            var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
             return previousValue + currentValue;
            });
            var currentValue = dataset["data"][tooltipItem.index];
            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
            return dataset["label"] + " (" + percentage + "%)";
          }
        }
      }
    };
  }

  getCountryData() {
    if (this.audience["countries"]) {
      this.countryDataLabels = [];
      this.countryData = [];
      for (let country of Object.keys(this.audience["countries"])) {
        this.countryDataLabels.push(country)
        this.countryData.push(this.audience["countries"][country])
      }

      this.countryDataColors = [
        { backgroundColor: '#ECB4AE' }
      ]

      this.countryOptions = {
        responsive: true,
        legend: { display: false },
        scales: {
          xAxes: [{
            ticks: { display: false, beginAtZero:true },
        }],
          yAxes: [{
            barPercentage : 0.6,
            gridLines: { display: false, drawBorder: false }
          }]
        },
        tooltips: {
          xPadding: 10,
          yPadding: 10,
          bodyFontSize: 15,
          bodySpacing: 10,
          callbacks: {
            label : function(tooltipItem, data) {
              var dataset = data["datasets"][0];
              var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
               return previousValue + currentValue;
              });
              var currentValue = dataset["data"][tooltipItem.index];
              var percentage = Math.floor(((currentValue/total) * 100)+0.5);
              return " (" + percentage + "%)";
            }
          }
        }
      };
    } else {
      this.getCountryData2()
    }
  }

  getCountryData2() {
    this.countryDataLabels = [];
    this.countryData = [];

    this.countryData = [
      {
        data: [],
        label: this.project_name
      },
      {
        data: [],
        label: this.filterPostData["benchmarks"][0]
      },
    ];

    for (let country of Object.keys(this.audience[this.filterPostData["benchmarks"][0]]["countries"])) {
      this.countryDataLabels.push(country)
      this.countryData[0]["data"].push(this.audience[this.project_name]["countries"][country])
    }

    for (let country of Object.keys(this.audience[this.filterPostData["benchmarks"][0]]["countries"])) {
      this.countryData[1]["data"].push(this.audience[this.filterPostData["benchmarks"][0]]["countries"][country])
    }
    this.countryDataColors = [
      { backgroundColor: '#ECB4AE' },
      { backgroundColor: '#E05B4C' },
    ]

    this.countryOptions = {
      responsive: true,
      borderRadius: 20,
      scales: {
        xAxes: [{
          stacked: true,
      }],
        yAxes: [{
          stacked: true,
          barPercentage: 0.6,
          gridLines: { display: false, drawBorder: false }
        }]
      },
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
          boxWidth: 6
        }
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
        callbacks: {
          label : function(tooltipItem, data) {
            var dataset = data["datasets"][tooltipItem.datasetIndex];
            var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
             return previousValue + currentValue;
            });
            var currentValue = dataset["data"][tooltipItem.index];
            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
            return dataset["label"] + " (" + percentage + "%)";
          }
        }
      }
    };
  }

  getCityData() {
    if (this.audience["cities"]) {
      this.cityDataLabels = [];
      this.cityData = [];
      for (let city of Object.keys(this.audience["cities"])) {
        this.cityDataLabels.push(city)
        this.cityData.push(this.audience["cities"][city])
      }

      this.cityDataColors = [
        { backgroundColor: '#ECB4AE' }
      ]

      this.cityOptions = {
        responsive: true,
        legend: { display: false },
        scales: {
          xAxes: [{
            ticks: { display: false, beginAtZero:true },
        }],
          yAxes: [{
            barPercentage : 0.6,
            gridLines: { display: false, drawBorder: false }
          }]
        },
        tooltips: {
          xPadding: 10,
          yPadding: 10,
          bodyFontSize: 15,
          bodySpacing: 10,
          callbacks: {
            label : function(tooltipItem, data) {
              var dataset = data["datasets"][0];
              var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
               return previousValue + currentValue;
              });
              var currentValue = dataset["data"][tooltipItem.index];
              var percentage = Math.floor(((currentValue/total) * 100)+0.5);
              return " (" + percentage + "%)";
            }
          }
        }
      };
    } else {
      this.getCityData2()
    }
  }

  getCityData2() {
    this.cityDataLabels = [];
    this.cityData = [];

    this.cityData = [
      {
        data: [],
        label: this.project_name
      },
      {
        data: [],
        label: this.filterPostData["benchmarks"][0]
      },
    ];

    for (let city of Object.keys(this.audience[this.filterPostData["benchmarks"][0]]["cities"])) {
      this.cityDataLabels.push(city)
      this.cityData[0]["data"].push(this.audience[this.project_name]["cities"][city])
    }

    for (let city of Object.keys(this.audience[this.filterPostData["benchmarks"][0]]["cities"])) {
      this.cityData[1]["data"].push(this.audience[this.filterPostData["benchmarks"][0]]["cities"][city])
    }
    this.cityDataColors = [
      { backgroundColor: '#ECB4AE' },
      { backgroundColor: '#E05B4C' },
    ]

    this.cityOptions = {
      responsive: true,
      borderRadius: 20,
      scales: {
        xAxes: [{
          stacked: true,
      }],
        yAxes: [{
          stacked: true,
          barPercentage: 0.6,
          gridLines: { display: false, drawBorder: false }
        }]
      },
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
          boxWidth: 6
        }
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
        callbacks: {
          label : function(tooltipItem, data) {
            var dataset = data["datasets"][tooltipItem.datasetIndex];
            var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
             return previousValue + currentValue;
            });
            var currentValue = dataset["data"][tooltipItem.index];
            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
            return dataset["label"] + " (" + percentage + "%)";
          }
        }
      }
    };
  }

  getBrandCategories() {
    this.dataservice.getBrandCategories().then((value: any[]) => {
      this.brandCategories = value;

      // create dynamic brand category form
      let categoryGroup = {};
      this.brandCategories.forEach(x=>{
        categoryGroup[x["id"]]=new FormControl('');
      })
      this.brandCategoryForm = this.fb.group(categoryGroup);
    })
  }

  benchmarkSearchFilter() {
    this.selectedBenchmarkCount = 0;
    for (let item of this.benchmarks) {
      if (item["isSelected"] == true) {
        this.selectedBenchmarkCount = this.selectedBenchmarkCount + 1
      }
    }
  }

  applyBrandCategoryFilter() {
    let postData = []
    for (let i in this.brandCategoryForm.value) {
      if (this.brandCategoryForm.value[i] == true) {
        postData.push(Number(i))
      }
    }
    this.filterPostData["brandCategories"] = postData
    this.brandCategorySearchFilter()
    this.dataservice.getAudience(this.filterPostData).then((value: any[]) => {
      this.audience = value;
      this.updateAudienceData()
      this.isScrollDivOccupation = false;
      this.isScrollDivLike = false;
      this.isScrollDivTopBrands = false;
      this.isScrollDivCelebrity = false;
    })
  }

  getInıtialChartData() {
    this.getAgeData()
    this.getPersonalIncomeData()
    this.getGenderData()
    this.getFamilyData()
    this.getCountryData()
    this.getCityData()
    this.updateCharts()
  }

  updateCharts() {
    this.getAgeData()
    this.getPersonalIncomeData()
    this.getGenderData()
    this.getFamilyData()
    this.getCountryData()
    this.getCityData()
    this.updateAudienceData()

    this.isMoreOccupation = true;
    this.isScrollDivOccupation = false;
    this.isMoreLike = true;
    this.isScrollDivLike = false;
    this.isMoreTopBrands = true;
    this.isScrollDivTopBrands = false;
    this.isMoreCelebrity = true;
    this.isScrollDivCelebrity = false;
  }

  updateAudienceData() {
    // occupation
    this.all_occupations = [];
    this.occupations = [];
    if (this.audience["occupations"]) {
      for (let occupation of Object.keys(this.audience["occupations"])) {
        this.all_occupations.push({
          "name": occupation,
          "type": this.project_name,
          "value": this.audience["occupations"][occupation],
          "color": "#ECB4AE"
        })
      }
    } else {
      for (let occupation of Object.keys(this.audience[this.project_name]["occupations"])) {
        let value1 = this.audience[this.project_name]["occupations"][occupation]
        let value2 = this.audience[this.filterPostData["benchmarks"][0]]["occupations"][occupation]
        let color = "";
        let type = "";
        let value;
        if (value1 > value2) {
          color = "#ECB4AE"
          type = this.project_name
          value = value1
        } else {
          color = "#E05B4C"
          type = this.filterPostData["benchmarks"][0]
          value = value2
        }
        this.all_occupations.push({
          "name": occupation,
          "type": type,
          "value": value,
          "value1": value1,
          "value2": value2,
          "color": color
        })
      }
      for (let occupation of Object.keys(this.audience["both"]["occupations"])) {
        this.all_occupations.push({
          "name": occupation,
          "type": this.project_name + ", " + this.filterPostData["benchmarks"][0],
          "value": this.audience["both"]["occupations"][occupation],
          "color": "#000000"
        })
      }
    }
    this.all_occupations = this.all_occupations.sort((a,b) => b.value - a.value);
    this.occupations = this.all_occupations.slice(0,8)
    if (this.all_occupations.length > 8) {
      this.isMoreOccupation = true;
    } else  {
      this.isMoreOccupation = false;
    }

    // like_and_interesets
    this.all_like_and_interesets = [];
    this.like_and_interesets = [];
    if (this.audience["likes_and_interests"]) {
      for (let like of Object.keys(this.audience["likes_and_interests"])) {
        this.all_like_and_interesets.push({
          "name": like,
          "type": this.project_name,
          "value": this.audience["likes_and_interests"][like],
          "color": "#ECB4AE"
        })
      }
    } else {
      for (let like of Object.keys(this.audience[this.project_name]["likes_and_interests"])) {
        let value1 = this.audience[this.project_name]["likes_and_interests"][like]
        let value2 = this.audience[this.filterPostData["benchmarks"][0]]["likes_and_interests"][like]
        let color = "";
        let type = "";
        let value;
        if (value1 > value2) {
          color = "#ECB4AE"
          type = this.project_name
          value = value1
        } else {
          color = "#E05B4C"
          type = this.filterPostData["benchmarks"][0]
          value = value2
        }
        this.all_like_and_interesets.push({
          "name": like,
          "type": type,
          "value": value,
          "value1": value1,
          "value2": value2,
          "color": color
        })
      }
      for (let like of Object.keys(this.audience["both"]["likes_and_interests"])) {
        this.all_like_and_interesets.push({
          "name": like,
          "type": this.project_name + ", " + this.filterPostData["benchmarks"][0],
          "value": this.audience["both"]["likes_and_interests"][like],
          "color": "#000000"
        })
      }
    }
    this.all_like_and_interesets = this.all_like_and_interesets.sort((a,b) => b.value - a.value);
    this.like_and_interesets = this.all_like_and_interesets.slice(0,8)
    if (this.all_like_and_interesets.length > 8) {
      this.isMoreLike = true;
    } else {
      this.isMoreLike = false;
    }

    // top_brands
    this.all_top_brands = [];
    this.top_brands = [];
    if (this.audience["top_brands"]) {
      for (let top_brand of Object.keys(this.audience["top_brands"])) {
        this.all_top_brands.push({
          "name": top_brand,
          "type": this.project_name,
          "value": this.audience["top_brands"][top_brand],
          "color": "#ECB4AE"
        })
      }
    } else {
      for (let top_brand of Object.keys(this.audience[this.project_name]["top_brands"])) {
        let value1 = this.audience[this.project_name]["top_brands"][top_brand]
        let value2 = this.audience[this.filterPostData["benchmarks"][0]]["top_brands"][top_brand]
        let color = "";
        let type = "";
        let value;
        if (value1 > value2) {
          color = "#ECB4AE"
          type = this.project_name
          value = value1
        } else {
          color = "#E05B4C"
          type = this.filterPostData["benchmarks"][0]
          value = value2
        }
        this.all_top_brands.push({
          "name": top_brand,
          "type": type,
          "value": value,
          "value1": value1,
          "value2": value2,
          "color": color
        })
      }
      for (let top_brand of Object.keys(this.audience["both"]["top_brands"])) {
        this.all_top_brands.push({
          "name": top_brand,
          "type": this.project_name + ", " + this.filterPostData["benchmarks"][0],
          "value": this.audience["both"]["top_brands"][top_brand],
          "color": "#000000"
        })
      }
    }
    this.all_top_brands = this.all_top_brands.sort((a,b) => b.value - a.value);
    this.top_brands = this.all_top_brands.slice(0,8)
    if (this.all_top_brands.length > 8) {
      this.isMoreTopBrands = true;
    } else {
      this.isMoreTopBrands = false;
    }

    // mostly_liked_celebrity
    this.all_mostly_liked_celebrities = [];
    this.mostly_liked_celebrities = [];
    if (this.audience["mostly_liked_celebrities"]) {
      for (let mostly_liked_celebrity of Object.keys(this.audience["mostly_liked_celebrities"])) {
        this.all_mostly_liked_celebrities.push({
          "name": mostly_liked_celebrity,
          "type": this.project_name,
          "value": this.audience["mostly_liked_celebrities"][mostly_liked_celebrity],
          "color": "#ECB4AE"
        })
      }
    } else {
      for (let mostly_liked_celebrity of Object.keys(this.audience[this.project_name]["mostly_liked_celebrities"])) {
        let value1 = this.audience[this.project_name]["mostly_liked_celebrities"][mostly_liked_celebrity]
        let value2 = this.audience[this.filterPostData["benchmarks"][0]]["mostly_liked_celebrities"][mostly_liked_celebrity]
        let color = "";
        let type = "";
        let value;
        if (value1 > value2) {
          color = "#ECB4AE"
          type = this.project_name
          value = value1
        } else {
          color = "#E05B4C"
          type = this.filterPostData["benchmarks"][0]
          value = value2
        }
        this.all_mostly_liked_celebrities.push({
          "name": mostly_liked_celebrity,
          "type": type,
          "value": value,
          "value1": value1,
          "value2": value2,
          "color": color
        })
      }
      for (let mostly_liked_celebrity of Object.keys(this.audience["both"]["mostly_liked_celebrities"])) {
        this.all_mostly_liked_celebrities.push({
          "name": mostly_liked_celebrity,
          "type": this.project_name + ", " + this.filterPostData["benchmarks"][0],
          "value": this.audience["both"]["mostly_liked_celebrities"][mostly_liked_celebrity],
          "color": "#000000"
        })
      }
    }
    this.all_mostly_liked_celebrities = this.all_mostly_liked_celebrities.sort((a,b) => b.value - a.value);
    this.mostly_liked_celebrities = this.all_mostly_liked_celebrities.slice(0,8)
    if (this.all_mostly_liked_celebrities.length > 8) {
      this.isMoreCelebrity = true;
    } else {
      this.isMoreCelebrity = false;
    }
  }

  moreOccupation() {
    this.isMoreOccupation = false;
    this.isScrollDivOccupation = true;
    this.occupations = this.all_occupations;
  }

  moreLike() {
    this.isMoreLike = false;
    this.isScrollDivLike = true;
    this.like_and_interesets = this.all_like_and_interesets;
  }

  moreTopBrands() {
    this.isMoreTopBrands = false;
    this.isScrollDivTopBrands = true;
    this.top_brands = this.all_top_brands;
  }

  moreCelebrity() {
    this.isMoreCelebrity = false;
    this.isScrollDivCelebrity = true;
    this.mostly_liked_celebrities = this.all_mostly_liked_celebrities;
  }

  selectBenchmark(benchmark) {
    if (this.selectedBenchmark == benchmark) {
      for (let i in this.benchmarks) {
        this.benchmarks[i]["isSelected"] = false;
      }
      this.resetFilters()
    } else {
      for (let i in this.benchmarks) {
        if (this.benchmarks[i]["id"] == benchmark) {
          this.benchmarks[i]["isSelected"] = true;
          this.selectedBenchmark = benchmark;
        } else {
          this.benchmarks[i]["isSelected"] = false;
        }
      }
    }
    this.applyFilters('benchmark');
    setTimeout(() => {
      let benchmarkDropdown: HTMLElement = document.getElementsByClassName("benchmark-dropdown")[0] as HTMLElement
      benchmarkDropdown.className = "dropdown-menu benchmark-dropdown px-3 py-4 my-2";
      let benchmarkDropdownLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[0] as HTMLElement
      benchmarkDropdownLink.setAttribute('aria-expanded', 'false');
    }, 20);

  }

  applyFilters(type) {
    this.filterPostData["project"] = this.project_name
    this.filterPostData["benchmarks"] = []
    if (type == "benchmark") {
      let postData = []
      for (let i in this.benchmarks) {
        if (this.benchmarks[i]["isSelected"] == true) {
          postData.push(this.benchmarks[i]["name"].toLowerCase().replaceAll(" ", "-"))
        }
      }
      this.filterPostData["benchmarks"] = postData
      this.benchmarkSearchFilter()
    }
    this.dataservice.getAudience(this.filterPostData).then((value: any[]) => {
      this.audience = value;
      this.updateCharts()
    })

  }

  clickOutside(event, type) {
    if (type == "benchmark") {
      let benchmarkDropdown: HTMLElement = document.getElementsByClassName("benchmark-dropdown")[0] as HTMLElement
      benchmarkDropdown.className = "dropdown-menu benchmark-dropdown px-3 py-4 my-2";
      let benchmarkDropdownLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[0] as HTMLElement
      benchmarkDropdownLink.setAttribute('aria-expanded', 'false')
    }
  }

  // toggleFilterButton(type) {
  //   if (type == "compare-category") {
  //     this.by_benchmark = "btn-light light-btn"
  //     this.by_category = "btn-light light-btn"
  //     this.by_compare = "btn-dark dark-btn"
  //   }
  // }

  resetFilters() {
    this.isMultiple = false;
    this.filterPostData = {};
    this.getInitalAudience();
    this.benchmarkForm.reset();
    this.selectedBenchmarkCount = 0;
  }
}
