<section class="filter-section">
  <div class=" d-flex align-items-center px-5">
    <div class="filter-buttons d-flex align-items-center">
      <div *ngIf="!isFilterDataReady" class="div mx-3">
        <div class="spinner-border text-danger" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="dropdown filter-dropdown">
        <button *ngIf="selectedBenchmarkCount" style="border: 3px solid #E05B4C" class="dropdownMenuLink btn {{ by_benchmark }} px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!isBenchmarkDataReady">
          Benchmark <span *ngIf="selectedBenchmarkCount" class="bold-text"> ({{ selectedBenchmarkCount }}) </span>
        </button>
        <button *ngIf="!selectedBenchmarkCount" class="dropdownMenuLink btn {{ by_benchmark }} px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!isBenchmarkDataReady">
          Benchmark <span *ngIf="selectedBenchmarkCount" class="bold-text"> ({{ selectedBenchmarkCount }}) </span>
        </button>
        <ul class="dropdown-menu benchmark-dropdown px-3 py-4 my-2" aria-labelledby="dropdownMenuLink" (ngxOutsideClick)="clickOutside($event, 'benchmark')">
          <form [formGroup]="benchmarkForm" (ngSubmit)="benchmarkSearchFilter()">
            <li class="my-2" *ngFor="let benchmark of benchmarks">
              <a class="dropdown-item">
                <div class="form-check">
                  <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="benchmark['id']" (change)="selectBenchmark(benchmark['id']);" id="flexCheckDefault" [(ngModel)]="benchmark['isSelected']">
                  <label class="form-check-label" for="flexCheckDefault">
                    {{ benchmark['name'] }}
                  </label>
                </div>
              </a>
            </li>
          </form>
        </ul>
      </div>
      <button type="button" class="btn filter-text text-uppercase" (click)="resetFilters()"><u>Reset Filters</u></button>
    </div>
  </div>
</section>

<section class="audience-section">
  <div class="row px-5 py-3">
    <div class="col-md-12 col-lg-12">
      <div class="white-card p-3">
        <div class="d-flex align-items-center">
          <p class="section-header-text text-uppercase cursor-pointer">Audience</p>
          <div class="dropdown mb-3">
            <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
            </button>
            <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
              <p class="info-text mb-0">Radius identifies consumers within the
                local population who represent the majority of discretionary
                spend. Typically, this is 8-15% of the wider catchment
                population. These active consumers engage with the community
                on-line and shop from the local businesses the most frequently.
              </p>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-md-12 col-lg-4 px-5 mb-4">
            <p class="box-header text-uppercase p-3">Age</p>
            <div class="chartjs-container m-1">
              <canvas *ngIf="!isMultiple" baseChart [data]="ageData" [labels]="ageDataLabels" [colors]="ageDataColors" [options]="ageOptions" chartType="horizontalBar">
              </canvas>
              <canvas *ngIf="isMultiple" baseChart [datasets]="ageData" [labels]="ageDataLabels" [colors]="ageDataColors" [options]="ageOptions" chartType="horizontalBar">
              </canvas>
            </div>
          </div>
          <div class="col-md-12 col-lg-4 px-5">
            <p class="box-header text-uppercase p-3">Personal Income</p>
            <div class="chartjs-container m-1">
              <canvas *ngIf="!isMultiple" baseChart [data]="personalIncomeData" [labels]="personalIncomeDataLabels" [colors]="personalIncomeDataColors" [options]="personalIncomeOptions" chartType="horizontalBar">
              </canvas>
              <canvas *ngIf="isMultiple" baseChart [datasets]="personalIncomeData" [labels]="personalIncomeDataLabels" [colors]="personalIncomeDataColors" [options]="personalIncomeOptions" chartType="horizontalBar">
              </canvas>
            </div>
          </div>
          <div class="col-md-12 col-lg-4 px-5">
            <p class="box-header text-uppercase p-3">Gender</p>
            <div class="chartjs-container m-1">
              <canvas *ngIf="!isMultiple" baseChart [data]="genderData" [labels]="genderDataLabels" [colors]="genderDataColors" [options]="genderOptions" chartType="horizontalBar">
              </canvas>
              <canvas *ngIf="isMultiple" baseChart [datasets]="genderData" [labels]="genderDataLabels" [colors]="genderDataColors" [options]="genderOptions" chartType="horizontalBar">
              </canvas>
            </div>
          </div>
          <div class="col-md-12 col-lg-4 px-5">
            <p class="box-header text-uppercase p-3">Family Status</p>
            <div class="chartjs-container m-1">
              <canvas *ngIf="!isMultiple" baseChart [data]="familyData" [labels]="familyDataLabels" [colors]="familyDataColors" [options]="familyOptions" chartType="horizontalBar">
              </canvas>
              <canvas *ngIf="isMultiple" baseChart [datasets]="familyData" [labels]="familyDataLabels" [colors]="familyDataColors" [options]="familyOptions" chartType="horizontalBar">
              </canvas>
            </div>
          </div>
          <div class="col-md-12 col-lg-4 px-5 mb-4">
            <p class="box-header text-uppercase p-3">Countries</p>
            <div class="chartjs-container m-1">
              <canvas *ngIf="!isMultiple" baseChart [data]="countryData" [labels]="countryDataLabels" [colors]="countryDataColors" [options]="countryOptions" chartType="horizontalBar">
              </canvas>
              <canvas *ngIf="isMultiple" baseChart [datasets]="countryData" [labels]="countryDataLabels" [colors]="countryDataColors" [options]="countryOptions" chartType="horizontalBar">
              </canvas>
            </div>
          </div>
          <div class="col-md-12 col-lg-4 px-5 mb-4">
            <p class="box-header text-uppercase p-3">Cities</p>
            <div class="chartjs-container m-1">
              <canvas *ngIf="!isMultiple" baseChart [data]="cityData" [labels]="cityDataLabels" [colors]="cityDataColors" [options]="cityOptions" chartType="horizontalBar">
              </canvas>
              <canvas *ngIf="isMultiple" baseChart [datasets]="cityData" [labels]="cityDataLabels" [colors]="cityDataColors" [options]="cityOptions" chartType="horizontalBar">
              </canvas>
            </div>
          </div>
        </div>
        <div class="row px-3 mt-5">
          <div class="d-flex align-items-center px-5 mb-4">
            <div class="dropdown brand-category-dropdown">
              <button class="btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                Category <i class="fa fa-chevron-down"></i>
              </button>
              <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
                <form [formGroup]="brandCategoryForm" (ngSubmit)="applyBrandCategoryFilter()">
                  <div class="scroll-div-filter">
                    <li class="my-2" *ngFor="let category of brandCategories">
                      <a class="dropdown-item">
                        <div class="form-check">
                          <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="category['id']" (change)="applyBrandCategoryFilter()" id="flexCheckDefault">
                          <label class="form-check-label" for="flexCheckDefault">
                            {{ category['name'] }}
                          </label>
                        </div>
                      </a>
                    </li>
                  </div>
                </form>
              </ul>
            </div>
            <div class="dropdown">
              <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
              </button>
              <div class="dropdown-menu info-dropdown-menu p-2" aria-labelledby="dropdownMenuLink">
                <p class="info-text mb-0">Category filter impacts on:</p>
                <ul style="padding-left: 1.5rem;">
                  <li class="info-text">Top Brands</li>
                  <li class="info-text">Likes & Interests</li>
                  <li class="info-text">Top Celebrities</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-6 px-5 mb-4">
            <div class="d-flex align-items-center">
              <p class="box-header text-uppercase py-3 pl-3">Occupation</p>
              <div class="dropdown mb-3">
                <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                  </svg>
                </button>
                <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                  <p class="info-text mb-0">Occupation results are not affected by Category filter</p>
                </div>
              </div>
            </div>
            <div *ngIf="!isScrollDivOccupation">
              <button style="border: 2px solid {{ item['color'] }}" *ngFor="let item of occupations;" type="button" class="btn btn-light occupation-btn p-2 mr-4 ml-0" data-toggle="tooltip" data-placement="top" title="{{ item['type']}}: {{item['value'] }}">{{ item["name"] }}</button>
              <button *ngIf="isMoreOccupation" type="button" class="btn btn-light more-btn p-2 mb-2" (click)="moreOccupation()"><u>More</u></button>
            </div>
            <div *ngIf="isScrollDivOccupation"  class="scroll-div">
              <button style="border: 2px solid {{ item['color'] }}" *ngFor="let item of occupations;" type="button" class="btn btn-light occupation-btn p-2 mr-4 ml-0 mb-2" data-toggle="tooltip" data-placement="top" title="{{ item['type']}}: {{item['value'] }}">{{ item["name"] }}</button>
              <button *ngIf="isMoreOccupation" type="button" class="btn btn-light more-btn p-2 mb-2" (click)="moreOccupation()"><u>More</u></button>
            </div>
          </div>
          <div class="col-md-12 col-lg-6 px-5 mb-4">
            <p class="box-header text-uppercase py-3 pl-3">Top Brands</p>
            <div *ngIf="!isScrollDivTopBrands">
              <button style="border: 2px solid {{ item['color'] }}" *ngFor="let item of top_brands;" type="button" class="btn btn-light occupation-btn p-2 mr-4 ml-0" data-toggle="tooltip" data-placement="top" title="{{ item['type']}}: {{item['value'] }}">{{ item["name"] }}</button>
              <button *ngIf="isMoreTopBrands" type="button" class="btn btn-light more-btn p-2 mb-2" (click)="moreTopBrands()"><u>More</u></button>
            </div>
            <div *ngIf="isScrollDivTopBrands"  class="scroll-div">
              <button style="border: 2px solid {{ item['color'] }}" *ngFor="let item of top_brands;" type="button" class="btn btn-light occupation-btn p-2 mr-4 ml-0 mb-2" data-toggle="tooltip" data-placement="top" title="{{ item['type']}}: {{item['value'] }}">{{ item["name"] }}</button>
              <button *ngIf="isMoreTopBrands" type="button" class="btn btn-light more-btn p-2 mb-2" (click)="moreTopBrands()"><u>More</u></button>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-md-12 col-lg-6 px-5 mb-4">
            <p class="box-header text-uppercase py-3 pl-3 mt-3">Likes & Interests</p>
            <div *ngIf="!isScrollDivLike">
              <button style="border: 2px solid {{ item['color'] }}" *ngFor="let item of like_and_interesets;" type="button" class="btn btn-light occupation-btn p-2 mr-4 ml-0" data-toggle="tooltip" data-placement="top" title="{{ item['type']}}: {{item['value'] }}">{{ item["name"] }}</button>
              <button *ngIf="isMoreLike" type="button" class="btn btn-light more-btn p-2 mb-2" (click)="moreLike()"><u>More</u></button>
            </div>
            <div *ngIf="isScrollDivLike"  class="scroll-div">
              <button style="border: 2px solid {{ item['color'] }}" *ngFor="let item of like_and_interesets;" type="button" class="btn btn-light occupation-btn p-2 mr-4 ml-0 mb-2" data-toggle="tooltip" data-placement="top" title="{{ item['type']}}: {{item['value'] }}">{{ item["name"] }}</button>
              <button *ngIf="isMoreLike" type="button" class="btn btn-light more-btn p-2 mb-2" (click)="moreLike()"><u>More</u></button>
            </div>
          </div>
          <div class="col-md-12 col-lg-6 px-5 mb-4">
            <p class="box-header text-uppercase py-3 pl-3 mt-3">Top Celebrities</p>
            <div *ngIf="!isScrollDivCelebrity">
              <button style="border: 2px solid {{ item['color'] }}" *ngFor="let item of mostly_liked_celebrities;" type="button" class="btn btn-light occupation-btn p-2 mr-4 ml-0" data-toggle="tooltip" data-placement="top" title="{{ item['type']}}: {{item['value'] }}">{{ item["name"] }}</button>
              <button *ngIf="isMoreCelebrity" type="button" class="btn btn-light more-btn p-2 mb-2" (click)="moreCelebrity()"><u>More</u></button>
            </div>
            <div *ngIf="isScrollDivCelebrity"  class="scroll-div">
              <button style="border: 2px solid {{ item['color'] }}" *ngFor="let item of mostly_liked_celebrities;" type="button" class="btn btn-light occupation-btn p-2 mr-4 ml-0 mb-2" data-toggle="tooltip" data-placement="top" title="{{ item['type']}}: {{item['value'] }}">{{ item["name"] }}</button>
              <button *ngIf="isMoreCelebrity" type="button" class="btn btn-light more-btn p-2 mb-2" (click)="moreCelebrity()"><u>More</u></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
