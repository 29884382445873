<section class="brand-radar-section">
  <div class="filter-buttons d-flex align-items-center p-5">
    <div class="category-buttons d-flex align-items-center">
      <div *ngIf="districts.length != 0" class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-1" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
          District <span *ngIf="selectedDistrictCount" class="bold-text"> ({{ selectedDistrictCount }})</span>
        </button>
        <ul *ngIf="districts.length != 0" class="dropdown-menu district-dropdown px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <form [formGroup]="districtForm" (ngSubmit)="districtSearchFilter()">
            <li class="my-2" *ngFor="let district of districts">
              <a class="dropdown-item">
                <div class="form-check">
                  <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="district['id']" (change)="selectDistrict(district['id']);" id="flexCheckDefault" [(ngModel)]="district['isSelected']">
                  <label class="form-check-label">
                    {{ district['name'] }}
                  </label>
                </div>
              </a>
            </li>
          </form>
        </ul>
      </div>
      <div class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-1" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
          Category <span *ngIf="selectedCategoryCount" class="bold-text"> ({{ selectedCategoryCount }})</span>
        </button>
        <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <!-- <form class="form-inline pb-0 mb-3" (submit)="searchCategory()" action="#">
            <div class="search-box">
              <div class="d-flex align-items-center">
                <input [(ngModel)]="searchTextCategory" name="search" style="border: 0px;" type="search" class="form-control rounded search-input" placeholder="Search Your Categories" aria-label="Search" aria-describedby="search-addon" autofocus />
              </div>
            </div>
          </form> -->
          <form *ngIf="categories.length > 0" [formGroup]="categoryForm">
            <div class="scroll-div-filter">
              <li class="my-2" *ngFor="let category of categories | filter: searchTextCategory;">
                <a class="dropdown-item">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="category['id']" (change)="categorySearchFilter()" id="flexCheckDefault">
                    <label class="form-check-label">
                      {{ category['name'] }}
                    </label>
                  </div>
                </a>
              </li>
            </div>
          </form>
        </ul>
      </div>
      <div class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-1" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
          Status <span *ngIf="selectedStatusCount" class="bold-text"> ({{ selectedStatusCount }})</span>
        </button>
        <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <form [formGroup]="statusForm">
            <div class="scroll-div-filter">
              <li class="my-2" *ngFor="let status of statuses">
                <a class="dropdown-item">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="status['id']" (change)="statusSearchFilter()" id="flexCheckDefault">
                    <label class="form-check-label">
                      {{ status['name'] }}
                    </label>
                  </div>
                </a>
              </li>
            </div>
          </form>
        </ul>
      </div>
      <div class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-1" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
          Strategy Focus <span *ngIf="selectedStrategyFocusCount" class="bold-text"> ({{ selectedStrategyFocusCount }})</span>
        </button>
        <ul *ngIf="strategy_focuses.length > 0" class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <form [formGroup]="strategyFocusForm">
            <div class="scroll-div-filter">
              <li class="my-2" *ngFor="let strategy_focus of strategy_focuses">
                <a class="dropdown-item">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="strategy_focus['id']" (change)="strategyFocusSearchFilter()" id="flexCheckDefault">
                    <label class="form-check-label">
                      {{ strategy_focus['name'] }}
                    </label>
                  </div>
                </a>
              </li>
            </div>
          </form>
        </ul>
      </div>
      <div class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-1" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
          Size <span *ngIf="selectedSizeCount" class="bold-text"> ({{ selectedSizeCount }})</span>
        </button>
        <ul *ngIf="sizes.length > 0" class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <form [formGroup]="sizeForm">
            <div class="scroll-div-filter">
              <li class="my-2" *ngFor="let size of sizes">
                <a class="dropdown-item">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="size['id']" (change)="sizeSearchFilter()" id="flexCheckDefault">
                    <label class="form-check-label">
                      {{ size['name'] }}
                    </label>
                  </div>
                </a>
              </li>
            </div>
          </form>
        </ul>
      </div>
      <div class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-1" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
          Factsheet <span *ngIf="selectedFactsheetCount" class="bold-text"> ({{ selectedFactsheetCount }})</span>
        </button>
        <ul *ngIf="fact_sheet_statuses.length > 0" class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <form [formGroup]="factsheetsStatusForm">
            <div class="scroll-div-filter">
              <li class="my-2" *ngFor="let fact_sheet_status of fact_sheet_statuses">
                <a class="dropdown-item">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="fact_sheet_status['id']" (change)="factsheetStatusSearchFilter()" id="flexCheckDefault">
                    <label class="form-check-label">
                      {{ fact_sheet_status['name'] }}
                    </label>
                  </div>
                </a>
              </li>
            </div>
          </form>
        </ul>
      </div>
      <div class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-3 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="false">
          Distance <span *ngIf="selectedDistanceNumber" class="little-bold-text"> ({{ selectedDistanceNumber }})</span>
        </button>
        <ul class="dropdown-menu distance-dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink" (ngxOutsideClick)="clickOutsideDistance($event)">
          <form [formGroup]="distanceForm">
            <div class="scroll-div-filter">
              <li class="my-2" *ngFor="let distance of distances">
                <a class="dropdown-item">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="distance['id']" (change)="distanceSearchFilter()" id="flexCheckDefault">
                    <label class="form-check-label">
                      {{ distance['name'] }}
                    </label>
                  </div>
                </a>
              </li>
            </div>
          </form>
          <div class="range-card p-2">
            <!-- <p class="content-text">DISTANCE<br>
              Choose a range to filter by distance
            </p> -->
            <div class="range-input mx-1 mb-5">
              <ngx-slider [(value)]="minDistanceValue" [(highValue)]="maxDistanceValue" [options]="distanceOptions" (userChangeEnd)="onDistanceNumberValueChange($event)">
              </ngx-slider>
            </div>
            <div class="row">
              <div class="col-12 col-md-5">
                <input class="range-input-text form-control mr-3" type="text" name="" value="{{ minDistanceValue }}" placeholder="Minimum" (input)="onMinDistanceValueChange($event.target.value)" [disabled]="is_distance_disable">
              </div>
              <div class="col-12 col-md-2">
              </div>
              <div class="col-12 col-md-5">
                <input class="range-input-text form-control ml-3" type="text" name="" value="{{ maxDistanceValue }}" placeholder="Maximum" (input)="onMaxDistanceValueChange($event.target.value)" [disabled]="is_distance_disable">
              </div>
            </div>
            <div class="row d-flex align-items-center mt-4">
              <div class="col-12 col-md-6 text-left">
                <a class="clear-text text-uppercase mt-3 cursor-pointer" (click)="clearDistanceFilters()"><u>Clear</u></a>
              </div>
              <div class="col-12 col-md-6 text-right">
                <button style="float: right;" type="submit" class="btn btn-dark dark-btn save-btn text-uppercase px-2" (click)="applyBrandFilters('distance')">Save</button>
              </div>
            </div>
          </div>
        </ul>
      </div>
      <div class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-1" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
          Order By <span *ngIf="selectedOrder" class="bold-text"> ({{ returnOrderText(selectedOrder) }})</span>
        </button>
        <ul class="dropdown-menu order-dropdown px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <form [formGroup]="orderForm" (ngSubmit)="orderSearchFilter()">
            <li class="my-2" *ngFor="let order of orders">
              <a class="dropdown-item">
                <div class="form-check">
                  <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="order['id']" (change)="selectOrder(order['id']);" id="flexCheckDefault" [(ngModel)]="order['isSelected']">
                  <label class="form-check-label">
                    {{ order['name'] }}
                  </label>
                </div>
              </a>
            </li>
          </form>
        </ul>
      </div>
      <!-- <div class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-1" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
          Group By <span *ngIf="selectedGroup" class="bold-text"> ({{ selectedGroup }})</span>
        </button>
        <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <form [formGroup]="groupForm">
            <div class="scroll-div-filter">
              <li class="my-2" *ngFor="let group of groups">
                <a class="dropdown-item">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="group['id']" (change)="groupSearchFilter()" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      {{ group['name'] }}
                    </label>
                  </div>
                </a>
              </li>
            </div>
          </form>
        </ul>
      </div> -->
      <button type="button" class="btn filter-text text-uppercase" (click)="resetFilters()"><u>Reset Filters</u></button>
    </div>
  </div>
  <div class="mx-5 mb-4">
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <div class="white-card">
          <div class="d-flex mt-2">
            <ul class="nav nav-pills nav-pills-shadow mb-3" id="pills-tab" role="tablist">
              <li class="nav-item tab-button text-center mx-3 my-3" role="presentation">
                <a class="nav-link active d-flex align-items-center text-uppercase px-3" id="pills-home-tab" data-bs-toggle="pill" href="#pills-price-breakdown" role="tab" aria-controls="pills-home" aria-selected="true">Category Trendiness</a>
              </li>
              <li class="nav-item tab-button text-center me-3 my-3" role="presentation">
                <a class="nav-link d-flex align-items-center text-uppercase px-3" id="pills-profile-tab" data-bs-toggle="pill" href="#pills-tenant-breakdown" role="tab" aria-controls="pills-profile" aria-selected="false">Cost Efficiency</a>
              </li>
            </ul>
          </div>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active text-center mx-3" id="pills-price-breakdown" role="tabpanel" aria-labelledby="pills-home-tab">
              <div class="px-3">
                <div class="chartjs-container">
                  <canvas id="line-chart" baseChart
                    [datasets]="lineChartData"
                    [labels]="lineChartLabels"
                    [options]="lineChartOptions"
                    [legend]="lineChartLegend"
                    [chartType]="lineChartType"
                    [plugins]="lineChartPlugins">
                  </canvas>
                </div>
              </div>
            </div>
            <div class="tab-pane fade text-center mx-3" id="pills-tenant-breakdown" role="tabpanel" aria-labelledby="pills-profile-tab">
              <div class="px-3">
                <div class="chartjs-container">
                  <canvas id="radar-chart" baseChart
                    [data]="radarChartData"
                    [labels]="radarChartLabels"
                    [colors]="radarChartColors"
                    [options]="radarChartOptions"
                    [chartType]="radarChartType"
                    [plugins]="radarChartPlugins">
                  </canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-6">
        <div class="white-card">
          <div class="d-flex mt-2">
            <ul class="nav nav-pills nav-pills-shadow mb-3" id="pills-tab" role="tablist">
              <li class="nav-item tab-button text-center mx-3 my-3" role="presentation">
                <a class="nav-link active d-flex align-items-center text-uppercase px-3" id="pills-home-tab" data-bs-toggle="pill" href="#pills-price-breakdown" role="tab" aria-controls="pills-home" aria-selected="true">Opportunity</a>
              </li>
            </ul>
          </div>
          <div class="tab-content d-flex justify-content-center align-items-center" id="pills-tabContent" style="height: 70%; width: 80;">
            <div class="tab-pane fade show active text-center mx-3 d-flex justify-content-center align-items-center" id="pills-price-breakdown" role="tabpanel" aria-labelledby="pills-home-tab" style="width: 80%;">
              <div class="d-flex justify-content-center align-items-center" style="width: 100%;">
                <table *ngIf="is_gap_and_opportunity_ready" class="table table-responsive score-table" style="min-width: 550px;">
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">Category</th>
                      <th scope="col">Sub Category</th>
                      <th scope="col">Strategy Focus</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let i of [0,1,2,3,4,5,6,7,8,9]" class="">
                      <th class="py-1">{{ gap_and_opportunity[i]["rank"] }}</th>
                      <th class="py-1">{{ gap_and_opportunity[i]["category"] }}</th>
                      <th class="py-1">{{ gap_and_opportunity[i]["subcategory"] }}</th>
                      <th class="py-1">{{ gap_and_opportunity[i]["strategy_focus"] }}</th>
                    </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="result-div mx-5 mb-5">
    <div *ngIf="!is_brands_ready" class=" d-flex justify-content-center">
      <div class="spinner-border text-danger d-flex justify-content-center" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div *ngIf="is_brands_ready && !is_category_ui" class="content mb-4">
      <table class="table table-hover table-responsive brand-table">
        <thead>
          <tr>
            <th scope="col">Rank</th>
            <th scope="col">Name</th>
            <th scope="col">Category</th>
            <th scope="col">Size</th>
            <th scope="col">Strategy Focus</th>
            <th scope="col">Distance</th>
            <!-- <th scope="col">Follower Count</th> -->
            <!-- <th scope="col">Review Rating</th> -->
            <th scope="col">ESG</th>
            <th scope="col">Popularity</th>
            <th scope="col">Status</th>
            <th scope="col">Links</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let b of brands" class="cursor-pointer">
            <td>
              <p *ngIf="b['project_dependant_value']['rank_change'] >= 0" class="p5-bold green-color px-1 py-2 mr-2 mb-0"><i class="fa fa-arrow-up me-1"></i>{{ b["filtered_rank"] }}</p>
              <p *ngIf="b['project_dependant_value']['rank_change'] < 0" class="p5-bold red-color px-1 py-2 mr-2 mb-0"><i class="fa fa-arrow-down me-1"></i>{{ b["filtered_rank"] }}</p>
            </td>
            <td>
              <div class="d-flex align-items-center">
                <img src="{{ b['profile_image'] }}" class="pr-5 brand-image">
                <p *ngIf="b['full_name']" class="p3 pb-0 mb-0">{{ b["full_name"] }}</p>
                <p *ngIf="!b['full_name']" class="p3 pb-0 mb-0">{{ toTitleCase(b["instagram_account"]) }}</p>
                <div class="dropdown">
                  <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                  </button>
                  <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                    <p *ngIf="b['biography']" class="info-text mb-2">{{ b["biography"] }}</p>
                    <!-- <p class="info-text mb-0">
                      Brand Size: {{ b["size"]["name"] }}
                    </p> -->
                    <!-- <p class="info-text mb-0">
                      Online Only: {{ b["is_online_only"] }}
                    </p>
                    <p class="info-text mb-0">
                      Unique: {{ b["is_unique"] }}
                    </p>
                    <p class="info-text mb-0">
                      Location: {{ b["has_location"] }}
                    </p>
                    <p class="info-text mb-0">
                      Product: {{ b["is_product"] }}
                    </p>
                    <p class="info-text mb-0">
                      Experience: {{ b["is_experience"] }}
                    </p>
                    <p class="info-text mb-0">
                      Service: {{ b["is_service"] }}
                    </p>
                    <p class="info-text mb-0">
                      Potential: {{ b["has_potential"] }}
                    </p> -->
                  </div>
                </div>
              </div>
            </td>
            <td>
              <p *ngIf="b['category']" class="p8 pb-0 mb-0" style="font-size: 13px; margin-right: 10px; font-weight: bold;">
                {{ b['category']['name'] }}
              </p>
              <p *ngIf="!b['category']" class="p8 pb-0 mb-0" style="font-size: 13px; margin-right: 10px; font-weight: bold;">
                -
              </p>
              <p *ngIf="b['external_sub_category']" class="p8 pb-0 mb-0" style="font-size: 13px; font-style: italic;">
                {{ b['external_sub_category'] }}
              </p>
              <p *ngIf="!b['external_sub_category'] && b['sub_category']" class="p8 pb-0 mb-0" style="font-size: 13px; font-style: italic;">
                {{ b['sub_category']['name'] }}
              </p>
              <p *ngIf="!b['external_sub_category'] && !b['sub_category']" class="p8 pb-0 mb-0" style="font-size: 13px; font-style: italic;">
                -
              </p>
            </td>
            <td>
              <p *ngIf="b['size']" class="p5 px-2 py-2 mr-0 mb-0">
                {{ b['size']['name'] }}
              </p>
              <p *ngIf="!b['size']" class="p5 px-2 py-2 mr-0 mb-0">
                -
              </p>
            </td>
            <td>
              <p *ngIf="b['strategy_focus']" class="p5 px-2 py-2 mr-0 mb-0">
                {{ b['strategy_focus']['name'] }}
              </p>
              <p *ngIf="!b['strategy_focus']" class="p5 px-2 py-2 mr-0 mb-0">
                -
              </p>
            </td>
            <td>
              <p *ngIf="b['project_dependant_value']['distance']" class="p5 px-2 py-2 mr-0 mb-0">
                {{ b["project_dependant_value"]["distance"] }}
              </p>
              <p *ngIf="!b['project_dependant_value']['distance']" class="p5 px-2 py-2 mr-0 mb-0">
                -
              </p>
            </td>
            <!-- <td>
              <p class="p5 px-2 pt-2 pb-0 mr-0 mb-0">{{ milesSeperator(b["follower_count"]) }}</p>
              <p *ngIf="b['follower_change_month'] > 0" class="p5 px-2 pb-0 mr-0 mb-0" style="font-style: italic !important;">+{{ milesSeperator(b["follower_change_month"]) }}</p>
              <p *ngIf="b['follower_change_month'] < 0" class="p5 px-2 pb-0 mr-0 mb-0" style="font-style: italic !important;">{{ milesSeperator(b["follower_change_month"]) }}</p>
              <p *ngIf="b['follower_change_month'] == 0" class="p5 px-2 pb-0 mr-0 mb-0" style="font-style: italic !important;"></p>
            </td> -->
            <!-- <td>
              <p class="p5 px-2 py-2 mr-0 mb-0">{{ b["google_rating"] }}/5</p>
            </td> -->
            <td>
              <div class="d-flex align-items-center">
                <p *ngIf="b['overall_score']" class="p5 py-2 mr-0 mb-0">{{ b["overall_score"] }}/10</p>
                <p *ngIf="!b['overall_score']" class="p5 py-2 mr-0 mb-0">-</p>
                <div class="dropdown">
                  <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                  </button>
                  <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                    <p class="info-text mb-0">
                      Environment Rate: {{ b["environment_score"] }}/10
                    </p>
                    <p class="info-text mb-0">
                      Social Score: {{ b["social_score"] }}/10
                    </p>
                    <p class="info-text mb-0">
                      Governance Score: {{ b["governance_score"] }}/10
                    </p>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center">
                <p *ngIf="b['project_dependant_value']['popularity_score_month']" class="p5 py-2 mr-0 mb-0">{{ b["project_dependant_value"]["popularity_score_month"] }}/100</p>
                <p *ngIf="!b['project_dependant_value']['popularity_score_month']" class="p5 py-2 mr-0 mb-0">-</p>
                <div class="dropdown">
                  <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                  </button>
                  <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                    <p class="info-text mb-0">
                      Engagement Rate: {{ b["engagement_rate"] }}
                    </p>
                    <p class="info-text mb-0">
                      Review Rating: {{ b["project_dependant_value"]["google_rating"] }}
                    </p>
                    <p class="info-text mb-0">
                      Follower Count: {{ milesSeperator(b["follower_count"]) }}
                    </p>
                    <p class="info-text mb-0">
                      Follower Change:
                      <span *ngIf="b['follower_change_month'] > 0">
                        +{{ milesSeperator(b["project_dependant_value"]["follower_change_month"]) }}
                      </span>
                      <span *ngIf="b['follower_change_month'] < 0">
                        {{ milesSeperator(b["project_dependant_value"]["follower_change_month"]) }}
                      </span>
                      <span *ngIf="b['follower_change_month'] == 0">
                        -
                      </span>
                    </p>
                    <!-- <p class="info-text mb-0">
                      Web Trending Score: {{ b["neare"]["google_trend_score_year"] }}/100
                    </p> -->
                    <!-- <p class="info-text mb-0">
                      Digital Footfall: {{ b["digital_footfall"] }}
                    </p> -->
                    <p class="info-text mb-0">
                      Occurrence: {{ b['project_dependant_value']['occurrence'] }}
                    </p>
                    <p class="info-text mb-0">
                      Previous Rank: {{ b['project_dependant_value']['previous_month_rank'] }}
                    </p>
                    <p class="info-text mb-0">
                      Previous Popularity: {{ b['project_dependant_value']['previous_popularity_score_month'] }}
                    </p>
                    <!-- <p class="info-text mb-0">
                      Follower Change Month: <span *ngIf="b['follower_change_month'] > 0">+</span>{{ milesSeperator(b["follower_change_month"]) }}
                    </p> -->
                  </div>
                </div>
              </div>
            </td>
            <td style="text-align: center;">
              <p *ngIf="b['project_dependant_value']['status']" class="p6 {{ getBrandColor(b['project_dependant_value']['status'])[1] }} px-2 py-2 mr-0 mb-0">{{ toTitleCase(b["project_dependant_value"]["status"].replace("-", " ")) }}</p>
              <p *ngIf="!b['project_dependant_value']['status']" class="p6 px-2 py-2 mr-0 mb-0">-</p>
            </td>
            <td style="text-align: center;">
              <div class="d-flex align-items-center mt-1">
                <p class="p8 pb-0 mb-0" style="margin-right: 10px;">
                  <a class="p8 pb-0 mb-0" href="https://www.instagram.com/{{ b['instagram_account'] }}" target="_blank">
                    <i *ngIf="b['instagram_account']" class="fa fa-instagram fa-lg" style="color: #111013;"></i>
                    <i *ngIf="!b['instagram_account']" class="fa fa-instagram fa-lg"></i>
                  </a>
                </p>
                <p class="p8 pb-0 mb-0" style="margin-right: 10px;">
                  <a class="p8 pb-0 mb-0" href="{{ b['website'] }}" target="_blank">
                    <i *ngIf="b['website']" class="fa fa-link fa-lg" style="color: #111013;"></i>
                    <i *ngIf="!b['website']" class="fa fa-link fa-lg"></i>
                  </a>
                </p>
                <p class="p8 pb-0 mb-0" style="margin-right: 5px;">
                  <a class="p8 pb-0 mb-0" data-bs-toggle="modal" data-bs-target="#factsheetModal" (click)="getBrand(b)">
                    <i *ngIf="b['project_dependant_value']['fact_sheet_status'] == 'available'" class="fa fa-file fa-lg" style="color: #111013;"></i>
                    <i *ngIf="b['project_dependant_value']['fact_sheet_status'] != 'available'" class="fa fa-file fa-lg"></i>
                  </a>
                </p>
              </div>
            </td>
          </tr>
          <tr *ngIf="brands.length > 0">
            <td colspan="9" class="py-4" style="text-align: center;">
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                  <li class="page-item cursor-pointer" (click)="paginator('previous', 'None')">
                    <a class="page-link" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li *ngFor="let item of paginator_count" class="page-item cursor-pointer" (click)="paginator('page', item)">
                    <a *ngIf="selected_page == item" class="page-link fw-bolder">{{ item }}</a>
                    <a *ngIf="selected_page != item" class="page-link">{{ item }}</a>
                  </li>
                  <li class="page-item cursor-pointer" (click)="paginator('next', 'None')">
                    <a class="page-link" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="is_selected_brand_ready" class="image-modal modal fade" id="factsheetModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen" role="document">
          <div class="modal-content" style="background-color: #f0eff2 !important;">
            <div class="modal-header">
              <div class="d-flex align-items-center">
                <img src="{{ selectedBrand['profile_image'] }}" class="mx-3 brand-image">
                <h5 *ngIf="selectedBrand['full_name']" class="modal-title">{{ toTitleCase(this.selectedBrand["full_name"]) }}</h5>
                <h5 *ngIf="!selectedBrand['full_name']" class="modal-title">{{ toTitleCase(this.selectedBrand["instagram_account"]) }}</h5>
              </div>
              <button type="button" class="btn-close btn-close btn-lg m-2" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div *ngIf="selectedBrand['project_dependant_value']['fact_sheet_status'] == 'available'" class="modal-body text-center">
              <div class="">
                <section class="brand-detail">
                  <div class="row px-5 py-3">
                    <div class="col-md-12 col-lg-12">
                      <div class="white-card p-3 shadow">
                        <div class="">
                          <p class="box-header text-uppercase pl-3 py-3">Brand Details</p>
                          <div class="row d-flex align-items-center">
                            <div class="col-12 col-md-2">
                              <div class="d-flex align-items-center">
                                <img src="{{ fact_sheet_brand['image_url'] }}" class="brand-image-big ms-4">
                                <h5 class="p1" style="font-size: 20px !important; margin-right: 10px; font-weight: bold;">{{ fact_sheet_brand['full_name'] }}</h5>
                              </div>
                            </div>
                            <div class="col-12 col-md-3">
                              <div class="" style="text-align: left">
                                <p class="p8" style="font-size: 15px !important; margin-right: 10px; font-weight: bold;">{{ fact_sheet_brand["category"] }}</p>
                                <p class="p8" style="font-size: 15px !important; margin-right: 10px; font-weight: bold;">Size:
                                  <span class="p8" style="font-size: 14px !important; margin-right: 10px; font-weight: 500 !important;">{{ fact_sheet_brand["size"]}}</span>
                                </p>
                                <p class="p8" style="font-size: 15px !important; margin-right: 10px; font-weight: bold;">Distance Label:
                                  <span class="p8" style="font-size: 14px !important; margin-right: 10px; font-weight: 500 !important;">{{ fact_sheet_brand["distance_label"]}}</span>
                                </p>
                              </div>
                            </div>
                            <div class="col-12 col-md-7">
                              <div class="row">
                                <div class="col-md-12 col-lg-3 border-right my-1">
                                  <div class="mx-2">
                                    <p class="p3 mb-0" style="font-size: 20px !important; font-weight: bold;">{{ milesSeperator(fact_sheet_brand["follower_count"]) }}</p>
                                    <p class="p8" style="font-size: 15px !important; margin-right: 10px; font-weight: bold;">Follower <br> Number</p>
                                  </div>
                                </div>
                                <div class="col-md-12 col-lg-3 border-right my-1">
                                  <div class="">
                                    <p class="p3 mb-0" style="font-size: 20px !important; font-weight: bold;">{{ milesSeperator(fact_sheet_brand["follower_change_month"]) }}</p>
                                    <p class="p8" style="font-size: 15px !important; margin-right: 10px; font-weight: bold;">Follower Change <br> (30 Days)</p>
                                  </div>
                                </div>
                                <div class="col-md-12 col-lg-3 border-right my-1">
                                  <div class="">
                                    <p class="p3 mb-0" style="font-size: 20px !important; font-weight: bold;">{{ fact_sheet_brand["engagement_rate"]}}</p>
                                    <p class="p8" style="font-size: 15px !important; margin-right: 10px; font-weight: bold;">Engagement <br> Rate</p>
                                  </div>
                                </div>
                                <div class="col-md-12 col-lg-3 my-1">
                                  <div class="">
                                    <p class="p3 mb-0" style="font-size: 20px !important; font-weight: bold;">{{ fact_sheet_brand["popularity_score_month"]}}/100</p>
                                    <p class="p8" style="font-size: 15px !important; margin-right: 10px; font-weight: bold;">Popularity <br> Score</p>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </section>
                <section class="brand-detail">
                  <div class="row px-5 py-3">
                    <div class="col-md-12 col-lg-12">
                      <div class="white-card p-3 shadow">
                        <div class="">
                          <p class="box-header text-uppercase pl-3 py-3">Information On {{ fact_sheet_brand["full_name"] }}</p>
                          <div class="row">
                            <ul class="px-5" style="text-align: left; list-style-type: circle;">
                              <li class="section-detail-item mb-2">{{ fact_sheet_brand["governance_esg_explained"] }}</li>
                              <li class="section-detail-item mb-2">{{ fact_sheet_brand["environment_esg_explained"] }}</li>
                              <li class="section-detail-item mb-2">{{ fact_sheet_brand["overall_esg_explained"] }}</li>
                              <li class="section-detail-item mb-2">{{ fact_sheet_brand["social_esg_explained"] }}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="brand-detail">
                  <div class="row px-5 py-3">
                    <div class="col-md-12 col-lg-12">
                      <div class="white-card p-3 shadow">
                        <div class="">
                          <p class="box-header text-uppercase pl-3 py-3">Opportunity | Experimental</p>
                          <div class="row">
                            <p class="section-detail-text pl-3">
                              The <b>Opportunity</b> is calculated by analysing
                              the <b>Consumer Relevance </b> and <b>Competitive
                              Advantage</b> scores of the focus brand, nearest
                              alternatives to the brand and the competitors in
                              the catchment area.
                            </p>
                            <div class="my-5">
                              <div class="chartjs-container">
                                <canvas baseChart [datasets]="brandData" [labels]="brandDataLabels" [options]="brandOptions" chartType="bubble">
                                </canvas>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="brand-detail">
                  <div class="row px-5 py-3">
                    <div class="col-md-12 col-lg-12">
                      <div class="white-card p-3 shadow">
                        <div class="">
                          <p class="box-header text-uppercase pl-3 py-3">Consumer Relevance</p>
                          <div class="row">
                            <p class="section-detail-text pl-3">
                              A relative index that estimates the catchment area
                              consumers' tendency to buy from the brand.
                              Qualitative information such as demographic and
                              psychographic traits are amplified by consumption
                              habits of consumers to generate a quantitative
                              comparison.
                            </p>
                            <ul class="px-5" style="text-align: left; list-style-type: circle;">
                              <li class="section-detail-item mb-2" *ngFor="let item of fact_sheet_insights['consumer_relevance']">
                                {{ item }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="brand-detail">
                  <div class="row px-5 py-3">
                    <div class="col-md-12 col-lg-12">
                      <div class="white-card p-3 shadow">
                        <div class="">
                          <p class="box-header text-uppercase pl-3 py-3">Competitive Advantage</p>
                          <div class="row">
                            <p class="section-detail-text pl-3">A relative scoring methodology that is built
                              from online reviews, social media activity,
                              physical visits, audience demographics and other
                              physical and digital measures of the focus brand,
                              the nearest alternatives and existing businesses
                              in the catchment area.
                            </p>
                            <ul class="px-5" style="text-align: left; list-style-type: circle;">
                              <li class="section-detail-item mb-2" *ngFor="let item of fact_sheet_insights['competitive_advantage']">
                                {{ item }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="brand-detail">
                  <div class="row px-5 py-3">
                    <div class="col-md-12 col-lg-12">
                      <div class="white-card p-3 shadow">
                        <div class="">
                          <p class="box-header text-uppercase pl-3 py-3">Opportunity</p>
                          <div class="row">
                            <ul class="px-5" style="text-align: left; list-style-type: circle;">
                              <li class="section-detail-item mb-2" *ngFor="let item of fact_sheet_insights['opportunity']">
                                {{ item }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="brand-detail">
                  <div class="row px-5 py-3">
                    <div class="col-md-12 col-lg-12">
                      <div class="white-card p-3 shadow">
                        <div class="">
                          <p class="box-header text-uppercase pl-3 py-3">Age Segment Correlation</p>
                          <div class="row">
                            <p class="section-detail-text pl-3">
                              Brand, Area and Wider Area
                            </p>
                            <p class="section-detail-text pl-3">
                              Segmentation represents the audience that engages
                              with the brand both online and offline. This all
                              existing consumers as well as consumer groups that
                              do not have the purchasing power yet but with a
                              high tendency to be converted once they reach to
                              certain purchasing power.
                            </p>
                            <div class="my-5">
                              <div class="chartjs-container">
                                <canvas baseChart [datasets]="ageData" [labels]="ageDataLabels" [colors]="ageDataColors" [options]="ageOptions" chartType="bar">
                                </canvas>
                              </div>
                            </div>
                            <ul class="px-5" style="text-align: left; list-style-type: circle;">
                              <li class="section-detail-item mb-2" *ngFor="let item of fact_sheet_insights['age']">
                                {{ item }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="brand-detail">
                  <div class="row px-5 py-3">
                    <div class="col-md-12 col-lg-12">
                      <div class="white-card p-3 shadow">
                        <div class="">
                          <p class="box-header text-uppercase pl-3 py-3">Income Correlation</p>
                          <div class="row">
                            <p class="section-detail-text pl-3">
                              Brand, Area and Wider Area
                            </p>
                            <p class="section-detail-text pl-3">
                              Segmentation represents the audience that engages
                              with the brand both online and offline. This all
                              existing consumers as well as consumer
                            </p>
                            <div class="my-5">
                              <div class="chartjs-container">
                                <canvas baseChart [datasets]="incomeData" [labels]="incomeDataLabels" [colors]="incomeDataColors" [options]="incomeOptions" chartType="bar">
                                </canvas>
                              </div>
                            </div>
                            <ul class="px-5" style="text-align: left; list-style-type: circle;">
                              <li class="section-detail-item mb-2" *ngFor="let item of fact_sheet_insights['income']">
                                {{ item }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="brand-detail">
                  <div class="row px-5 py-3">
                    <div class="col-md-12 col-lg-12">
                      <div class="white-card p-3 shadow">
                        <div class="">
                          <p class="box-header text-uppercase pl-3 py-3">Personas</p>
                          <div class="row">
                            <p class="section-detail-text pl-3">
                              Brand, Area and Wider Area
                            </p>
                            <div *ngFor="let item of fact_sheet_insights['persona'] | keyvalue" class="mb-3">
                              <p class="populatiry-key text-uppercase px-3 py-1 mx-3">
                                {{ item.key.replaceAll("_", " ") }}
                              </p>
                              <ul class="px-5" style="text-align: left; list-style-type: circle;">
                                <li class="section-detail-item mb-2" *ngFor="let i of [item.value]">
                                  {{ i }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <!-- <iframe
                  allowfullscreen="allowfullscreen"
                  scrolling="no"
                  class="fp-iframe vh-100"
                  [src]="selectedBrand['fact_sheet'] | safe: 'url'"
                  style="border: 1px solid lightgray; width: 100%;">
                </iframe> -->
              </div>
            </div>
            <div *ngIf="selectedBrand['project_dependant_value']['fact_sheet_status'] == 'unavailable'" class="modal-body text-center">
              <div *ngIf="!is_sended_request" class="container my-5">
                <p class="modal-title mt-3">Do you want to request a factsheet for <i>{{ toTitleCase(this.selectedBrand["instagram_account"]) }}</i> ?</p>
                <p class="modal-text px-5 m-5">
                  Please click the 'Send Request' button to generate the Brand
                  Fact Sheet. Brand Fact Sheet is used for validating the
                  alignment of the prospective tenant with the catchment area
                  market dynamics (i.e. relevance to consumers, competitive
                  advantage and sales opportunity). This is supported with the
                  following information on each brand: affinity score, price
                  points, nearest store, online popularity, competitor analysis
                  etc. Once the Fact Sheet is ready, you may view and download
                  via the Neare platform.
                </p>
                <button type="submit" class="btn btn-dark dark-btn save-btn px-5" (click)="sendFactsheetRequest(selectedBrand)">Send Request</button>
              </div>
              <div *ngIf="is_sended_request" class="container my-5">
                <p class="modal-title mt-3">Factsheet request was sent successfully for <i>{{ toTitleCase(this.selectedBrand["instagram_account"]) }}</i> </p>
              </div>
            </div>
            <div *ngIf="selectedBrand['project_dependant_value']['fact_sheet_status'] == 'requested'" class="modal-body text-center">
              <div class="container my-5">
                <p class="modal-title mt-3">Already requested a factsheet for <i>{{ toTitleCase(this.selectedBrand["instagram_account"]) }}</i></p>
                <p class="modal-text px-5 m-5">
                  Thanks for requesting a Brand Fact Sheet. We are progressing
                  your request. You will be notified as soon as the Fact Sheet
                  becomes available to view/download via the Platform. Brand
                  Fact Sheet is used for validating the alignment of the
                  prospective tenant with the catchment area market dynamics
                  (i.e. relevance to consumers, competitive advantage and sales
                  opportunity). This is supported with the following information
                  on each brand: affinity score, price points, nearest store,
                  online popularity, competitor analysis etc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
