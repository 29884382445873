import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private api = environment.apiURL

  private current_user = {};

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };


  constructor(
    private http: HttpClient,
  ) {}


  register(data) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };
    let promise = new Promise((resolve, reject) => {
      let postData = data
      const req = this.http.post(this.api + "auth/register/", postData, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  login(data) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };
    let promise = new Promise((resolve, reject) => {
      let postData = data
      const req = this.http.post(this.api + "auth/login/", postData, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            localStorage.setItem('access_token', res["token"])
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getCurrentUser() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Token ' + this.getToken()
      })
    };
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + "auth/current-user/", this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getUserProfile(userId) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Token ' + this.getToken()
      })
    };
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(
        this.api + "auth/user/" + userId + "/profile/", this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getToken() {
    return localStorage.getItem('access_token');
  }

  removeToken() {
    localStorage.removeItem('access_token');
  }

  isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return (authToken !== null) ? true : false;
  }

  logout() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Token ' + this.getToken()
      })
    };
    let postData = {}
    let promise = new Promise((resolve, reject) => {
      const req = this.http.post(this.api + "auth/logout/", postData, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
}
