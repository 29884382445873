import { Component, OnInit } from '@angular/core';

import { FilterPipe } from "../../pipes/filter.pipe";


@Component({
  selector: 'app-widget2',
  templateUrl: './widget2.component.html',
  styleUrls: ['./widget2.component.css']
})
export class Widget2Component implements OnInit {

  brands = [
    {"id":1, "name": "Le Labo", "url": "https://drive.google.com/file/d/1lwXbEbcTx1VkvF8yQr0G4M7D1KkzDNt5/preview"},
    {"id":2, "name": "Jo Malone", "url": "https://drive.google.com/file/d/1CWKiyRtecRPy7puUECtyFo--KIaAhqmb/preview"}
  ];
  searchResults = [];

  searchText = "";
  selectedBrand = {};

  constructor() { }

  ngOnInit(): void {
  }

  searchBrand() {
    this.searchResults = this.brands
  }

  selectBrand(brandId) {
    this.selectedBrand = {};
    for (let brand of this.searchResults) {
      if (brand["id"] == brandId) {
        this.selectedBrand = brand;
      }
    }
  }

}
