<section class="m-5 text-center">
  <h3 class="mt-3 mb-4">Location Logos</h3>
  <div *ngIf="locations.length > 0" class="row">
    <div *ngFor="let location of locations" class="col-md-12 col-lg-3 text-align-right mb-3">
      <div class="card location-card p-5 d-flex justify-content-center align-items-center">
        <div class="card-title">
          <p *ngIf="location['has_instagram_profile']"><b>{{ location["location"] }}</b></p>
          <p *ngIf="!location['has_instagram_profile']"><b><u>{{ location["location"] }}</u></b></p>
        </div>
        <div class="card-body text-center">
          <div class="mb-4">
            <p class="pb-0 mb-0">Orijinal</p>
            <img class="location-image" src="{{ location['image'] }}" alt="">
          </div>
          <div class="">
            <p class="pb-0 mb-0">Square</p>
            <img class="location-image-square" src="{{ location['image'] }}" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="locations.length == 0" class="div">
    <div class="spinner-border text-danger" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</section>
