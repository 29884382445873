<section class="brand-radar-section">
  <div class="filter-buttons d-flex align-items-center p-5">
    <div class="category-buttons d-flex align-items-center">
      <div class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-1" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
          Category <span *ngIf="selectedCategoryCount" class="bold-text"> ({{ selectedCategoryCount }})</span>
        </button>
        <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <!-- <form class="form-inline pb-0 mb-3" (submit)="searchCategory()" action="#">
            <div class="search-box">
              <div class="d-flex align-items-center">
                <input [(ngModel)]="searchTextCategory" name="search" style="border: 0px;" type="search" class="form-control rounded search-input" placeholder="Search Your Categories" aria-label="Search" aria-describedby="search-addon" autofocus />
              </div>
            </div>
          </form> -->
          <form *ngIf="categories.length > 0" [formGroup]="categoryForm">
            <div class="scroll-div-filter">
              <li class="my-2" *ngFor="let category of categories | filter: searchTextCategory;">
                <a class="dropdown-item">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="category['id']" (change)="categorySearchFilter()" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      {{ category['name'] }}
                    </label>
                  </div>
                </a>
              </li>
            </div>
          </form>
        </ul>
      </div>
      <div class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-1" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
          Status <span *ngIf="selectedStatusCount" class="bold-text"> ({{ selectedStatusCount }})</span>
        </button>
        <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <form [formGroup]="statusForm">
            <div class="scroll-div-filter">
              <li class="my-2" *ngFor="let status of statuses">
                <a class="dropdown-item">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="status['id']" (change)="statusSearchFilter()" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      {{ status['name'] }}
                    </label>
                  </div>
                </a>
              </li>
            </div>
          </form>
        </ul>
      </div>
      <div class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-1" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
          Strategy Focus <span *ngIf="selectedStrategyFocusCount" class="bold-text"> ({{ selectedStrategyFocusCount }})</span>
        </button>
        <ul *ngIf="strategy_focuses.length > 0" class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <form [formGroup]="strategyFocusForm">
            <div class="scroll-div-filter">
              <li class="my-2" *ngFor="let strategy_focus of strategy_focuses">
                <a class="dropdown-item">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="strategy_focus['id']" (change)="strategyFocusSearchFilter()" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      {{ strategy_focus['name'] }}
                    </label>
                  </div>
                </a>
              </li>
            </div>
          </form>
        </ul>
      </div>
      <div class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-1" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
          Size <span *ngIf="selectedSizeCount" class="bold-text"> ({{ selectedSizeCount }})</span>
        </button>
        <ul *ngIf="sizes.length > 0" class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <form [formGroup]="sizeForm">
            <div class="scroll-div-filter">
              <li class="my-2" *ngFor="let size of sizes">
                <a class="dropdown-item">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="size['id']" (change)="sizeSearchFilter()" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      {{ size['name'] }}
                    </label>
                  </div>
                </a>
              </li>
            </div>
          </form>
        </ul>
      </div>
      <div class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-1" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
          Order By <span *ngIf="selectedOrder" class="bold-text"> ({{ returnOrderText(selectedOrder) }})</span>
        </button>
        <ul class="dropdown-menu order-dropdown px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <form [formGroup]="orderForm" (ngSubmit)="orderSearchFilter()">
            <li class="my-2" *ngFor="let order of orders">
              <a class="dropdown-item">
                <div class="form-check">
                  <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="order['id']" (change)="selectOrder(order['id']);" id="flexCheckDefault" [(ngModel)]="order['isSelected']">
                  <label class="form-check-label" for="flexCheckDefault">
                    {{ order['name'] }}
                  </label>
                </div>
              </a>
            </li>
          </form>
        </ul>
      </div>
      <!-- <div class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-1" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
          Group By <span *ngIf="selectedGroup" class="bold-text"> ({{ selectedGroup }})</span>
        </button>
        <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <form [formGroup]="groupForm">
            <div class="scroll-div-filter">
              <li class="my-2" *ngFor="let group of groups">
                <a class="dropdown-item">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="group['id']" (change)="groupSearchFilter()" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      {{ group['name'] }}
                    </label>
                  </div>
                </a>
              </li>
            </div>
          </form>
        </ul>
      </div> -->
      <button type="button" class="btn filter-text text-uppercase" (click)="resetFilters()"><u>Reset Filters</u></button>
    </div>
  </div>
  <div class="result-div mx-5 mb-5">
    <div *ngIf="!is_brands_ready" class=" d-flex justify-content-center">
      <div class="spinner-border text-danger d-flex justify-content-center" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div *ngIf="is_brands_ready && !is_category_ui" class="content mb-4">
      <table class="table table-hover table-responsive ">
        <thead>
          <tr>
            <th scope="col">Rank</th>
            <th scope="col">Name</th>
            <th scope="col">Category</th>
            <th scope="col">Size</th>
            <th scope="col">Strategy Focus</th>
            <th scope="col">Distance</th>
            <!-- <th scope="col">Follower Count</th> -->
            <!-- <th scope="col">Review Rating</th> -->
            <th scope="col">Popularity</th>
            <th scope="col">Status</th>
            <th scope="col">Links</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let b of brands" class="cursor-pointer">
            <td>
              <p *ngIf="b['rank_change'] >= 0" class="p5-bold green-color px-1 py-2 mr-2 mb-0"><i class="fa fa-arrow-up me-1"></i>{{ b["filtered_rank"] }}</p>
              <p *ngIf="b['rank_change'] < 0" class="p5-bold red-color px-1 py-2 mr-2 mb-0"><i class="fa fa-arrow-down me-1"></i>{{ b["filtered_rank"] }}</p>
            </td>
            <td>
              <div class="d-flex align-items-center">
                <img src="{{ b['profile_image'] }}" class="pr-5 brand-image">
                <p *ngIf="b['full_name']" class="p3 pb-0 mb-0">{{ b["full_name"] }}</p>
                <p *ngIf="!b['full_name']" class="p3 pb-0 mb-0">{{ toTitleCase(b["instagram_account"]) }}</p>
                <div class="dropdown">
                  <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                  </button>
                  <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                    <p class="info-text mb-2">{{ b["biography"] }}</p>
                    <!-- <p class="info-text mb-0">
                      Brand Size: {{ b["size"]["name"] }}
                    </p> -->
                    <!-- <p class="info-text mb-0">
                      Online Only: {{ b["is_online_only"] }}
                    </p>
                    <p class="info-text mb-0">
                      Unique: {{ b["is_unique"] }}
                    </p>
                    <p class="info-text mb-0">
                      Location: {{ b["has_location"] }}
                    </p>
                    <p class="info-text mb-0">
                      Product: {{ b["is_product"] }}
                    </p>
                    <p class="info-text mb-0">
                      Experience: {{ b["is_experience"] }}
                    </p>
                    <p class="info-text mb-0">
                      Service: {{ b["is_service"] }}
                    </p>
                    <p class="info-text mb-0">
                      Potential: {{ b["has_potential"] }}
                    </p> -->
                  </div>
                </div>
              </div>
            </td>
            <td>
              <p *ngIf="b['category']" class="p8 pb-0 mb-0" style="font-size: 13px; margin-right: 10px; font-weight: bold;">
                {{ b['category']['name'] }}
              </p>
              <p *ngIf="b['sub_category']" class="p8 pb-0 mb-0" style="font-size: 13px; font-style: italic;">
                {{ b['sub_category']['name'] }}
              </p>
            </td>
            <td>
              <p *ngIf="b['size']['name']" class="p5 px-2 py-2 mr-0 mb-0">
                {{ b['size']['name'] }}
              </p>
            </td>
            <td>
              <p *ngIf="b['size']['name']" class="p5 px-2 py-2 mr-0 mb-0">
                {{ b['strategy_focus']['name'] }}
              </p>
            </td>
            <td>
              <p class="p5 px-2 py-2 mr-0 mb-0">
                -- km
              </p>
            </td>
            <!-- <td>
              <p class="p5 px-2 pt-2 pb-0 mr-0 mb-0">{{ milesSeperator(b["follower_count"]) }}</p>
              <p *ngIf="b['follower_change_month'] > 0" class="p5 px-2 pb-0 mr-0 mb-0" style="font-style: italic !important;">+{{ milesSeperator(b["follower_change_month"]) }}</p>
              <p *ngIf="b['follower_change_month'] < 0" class="p5 px-2 pb-0 mr-0 mb-0" style="font-style: italic !important;">{{ milesSeperator(b["follower_change_month"]) }}</p>
              <p *ngIf="b['follower_change_month'] == 0" class="p5 px-2 pb-0 mr-0 mb-0" style="font-style: italic !important;"></p>
            </td> -->
            <!-- <td>
              <p class="p5 px-2 py-2 mr-0 mb-0">{{ b["google_rating"] }}/5</p>
            </td> -->
            <td>
              <div class="d-flex align-items-center">
                <p class="p5 py-2 mr-0 mb-0">{{ b["popularity_score_month"] }}/100</p>
                <div class="dropdown">
                  <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                  </button>
                  <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                    <p class="info-text mb-0">
                      Engagement Rate: {{ b["engagement_rate"] }}
                    </p>
                    <p class="info-text mb-0">
                      Review Rating: {{ b["google_rating"] }}
                    </p>
                    <p class="info-text mb-0">
                      Follower Count: {{ milesSeperator(b["follower_count"]) }}
                    </p>
                    <p class="info-text mb-0">
                      Follower Change:
                      <span *ngIf="b['follower_change_month'] > 0">
                        +{{ milesSeperator(b["follower_change_month"]) }}
                      </span>
                      <span *ngIf="b['follower_change_month'] < 0">
                        {{ milesSeperator(b["follower_change_month"]) }}
                      </span>
                      <span *ngIf="b['follower_change_month'] == 0">
                        -
                      </span>
                    </p>
                    <!-- <p class="info-text mb-0">
                      Web Trending Score: {{ b["neare"]["google_trend_score_year"] }}/100
                    </p> -->
                    <p class="info-text mb-0">
                      Digital Footfall: {{ b["digital_footfall"] }}
                    </p>
                    <p class="info-text mb-0">
                      Occurrence: {{ b["occurrence"] }}
                    </p>
                    <!-- <p class="info-text mb-0">
                      Follower Change Month: <span *ngIf="b['follower_change_month'] > 0">+</span>{{ milesSeperator(b["follower_change_month"]) }}
                    </p> -->
                  </div>
                </div>
              </div>
            </td>
            <td style="text-align: center;">
              <p class="p6 {{ getBrandColor(b['status'])[1] }} px-2 py-2 mr-0 mb-0">{{ toTitleCase(b["status"].replace("-", " ")) }}</p>
            </td>
            <td style="text-align: center;">
              <div class="d-flex align-items-center mt-1">
                <p class="p8 pb-0 mb-0" style="margin-right: 10px;">
                  <a class="p8 pb-0 mb-0" href="https://www.instagram.com/{{ b['instagram_account'] }}" target="_blank"><i class="fa fa-instagram"></i></a>
                </p>
                <p class="p8 pb-0 mb-0" style="margin-right: 10px;">
                  <a class="p8 pb-0 mb-0" href="{{ b['website'] }}" target="_blank"><i class="fa fa-link"></i></a>
                </p>
                <p class="p8 pb-0 mb-0" style="margin-right: 5px;">
                  <a class="p8 pb-0 mb-0" data-bs-toggle="modal" data-bs-target="#factsheetModal" (click)="getBrand(b)"><i class="fa fa-file"></i></a>
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="py-4" style="text-align: center;">

                <p>pagination</p>


            </td>
          </tr>
        </tbody>
      </table>

      <div class="image-modal modal fade" id="factsheetModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="d-flex align-items-center">
                <img src="{{ this.selectedBrand['profile_image'] }}" class="mx-3 brand-image">
                <h5 *ngIf="this.selectedBrand['instagram_account']" class="modal-title">{{ toTitleCase(this.selectedBrand["instagram_account"]) }}</h5>
              </div>
              <button type="button" class="btn-close btn-close btn-lg m-2" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
              <div class="">
                <iframe
                  allowfullscreen="allowfullscreen"
                  scrolling="no"
                  class="fp-iframe vh-100"
                  src="https://www.africau.edu/images/default/sample.pdf"
                  style="border: 1px solid lightgray; width: 100%;">
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</section>
