import { Component, Directive, EventEmitter, Input, Output, QueryList, OnInit, ViewChildren } from '@angular/core';

import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from "@angular/forms";

import { DataService } from 'src/app/services/data.service';

import { Options, ChangeContext } from '@angular-slider/ngx-slider';
import { ITreeOptions } from '@circlon/angular-tree-component';

interface Campaign {
  id: number;
  name: string;
  number_of_entities: number;
  lss: number;
}

export type SortColumn = keyof Campaign | '';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: {[key: string]: SortDirection} = { 'asc': 'desc', 'desc': '', '': 'asc' };

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

@Component({
  selector: 'app-engagement',
  templateUrl: './engagement.component.html',
  styleUrls: ['./engagement.component.css']
})
export class EngagementComponent implements OnInit {
  minFollowerValue: number = 50000;
  maxFollowerValue: number = 100000;
  followerOptions: Options = {
    floor: 0,
    ceil: 200000,
    step: 100
  };

  minEngagementValue: number = 50000;
  maxEngagementValue: number = 100000;
  engagementOptions: Options = {
    floor: 0,
    ceil: 200000,
    step: 100
  };

  isCampaign: boolean = false;
  isCampaignToggle: boolean = true;
  isAgendaToggle: boolean = false;

  isNameAsc: boolean = false;
  isNumberOfENtitiesAsc: boolean = false;
  isLssAsc: boolean = false;

  isNameSorted: boolean = false;
  isNumberOfENtitiesSorted: boolean = false;
  isLssSorted: boolean = false;

  modalNextButtonText = "Next";
  modalPreviousButtonText = "Back To Selection";

  selectedCampaign: Campaign;
  suggested_engagement_activity_ready: boolean = false;
  all_suggested_engagement_activities = [];
  suggested_engagement_activities = [];

  ageGroups = [
    {"id": 1, "name": "Millennials", "value": "32%", "selected": true},
    {"id": 2, "name": "Gen Z", "value": "12%", "selected": true},
    {"id": 3, "name": "Gen Z", "value": "8%", "selected": true},
  ]

  incomeLevels = [
    {"id": 1, "name": "High Income", "value": "32%", "selected": true},
    {"id": 2, "name": "Upper Mid Income", "value": "12%", "selected": true},
    {"id": 3, "name": "Lower Mid Income", "value": "8%", "selected": true},
    {"id": 4, "name": "Lower Mid Income", "value": "8%", "selected": true},
  ]

  genders = [
    {"id": 1, "name": "Female", "value": "32%", "selected": true},
    {"id": 2, "name": "Male", "value": "12%", "selected": true},
    {"id": 3, "name": "Non-binary", "value": "8%", "selected": true},
  ]

  occupations = [
    {"id": 1, "name": "Creative", "value": "32%", "selected": true},
    {"id": 2, "name": "Health", "value": "24%", "selected": true},
    {"id": 3, "name": "Finance", "value": "8%", "selected": true},
    {"id": 4, "name": "Construction", "value": "8%", "selected": true},
    {"id": 5, "name": "Creative", "value": "32%", "selected": true},
    {"id": 6, "name": "Health", "value": "24%", "selected": true},
    {"id": 7, "name": "Finance", "value": "8%", "selected": true},
    {"id": 8, "name": "Construction", "value": "8%", "selected": true},
  ]

  lifestyleFocuses = [
    {"id": 1, "name": "Focus #1", "value": "32%", "selected": true},
    {"id": 2, "name": "Focus #2", "value": "24%", "selected": true},
    {"id": 3, "name": "Focus #3", "value": "8%", "selected": true},
    {"id": 4, "name": "Focus #4", "value": "8%", "selected": true},
  ]

  places = [
    {"id": 1, "name": "Restaurants", "value": "32%", "selected": true},
    {"id": 2, "name": "Bars", "value": "24%", "selected": true},
    {"id": 3, "name": "Clubs", "value": "8%", "selected": true},
    {"id": 4, "name": "Cafes", "value": "8%", "selected": true},
  ]

  timePeriods = [
    {"id": 1, "name": "Early Morning", "value": "32%", "selected": true},
    {"id": 2, "name": "Late Morning", "value": "24%", "selected": true},
    {"id": 3, "name": "Early Afternoon", "value": "8%", "selected": true},
    {"id": 4, "name": "Late Afternoon", "value": "8%", "selected": true},
    {"id": 5, "name": "Early Evening", "value": "8%", "selected": true},
    {"id": 6, "name": "Night", "value": "8%", "selected": true}
  ]

  days = [
    {"id": 1, "name": "Weekdays", "value": "32%", "selected": true},
    {"id": 2, "name": "Weekends", "value": "24%", "selected": true}
  ]

  seasons = [
    {"id": 1, "name": "Fall", "value": "32%", "selected": true},
    {"id": 2, "name": "Winter", "value": "24%", "selected": true},
    {"id": 3, "name": "Spring", "value": "32%", "selected": true},
    {"id": 4, "name": "Summar", "value": "24%", "selected": true}
  ]

  activationCategories = [
    {"id": 1, "name": "Music", "value": "32%", "selected": true},
    {"id": 2, "name": "Art", "value": "24%", "selected": true},
    {"id": 3, "name": "Design", "value": "32%", "selected": true},
    {"id": 4, "name": "Fashion", "value": "24%", "selected": true}
  ]

  activationTypes = [
    {"id": 1, "name": "Pop-up", "value": "32%", "selected": true},
    {"id": 2, "name": "Experience", "value": "24%", "selected": true},
    {"id": 3, "name": "Workshop", "value": "32%", "selected": true},
    {"id": 4, "name": "Awareness", "value": "24%", "selected": true}
  ]

  unsorted_campaigns: Campaign[] = [
    {"id": 1, "name": "Families w/ Kids (Christmas)", "number_of_entities": 3213, "lss": 45},
    {"id": 2, "name": "60+ Age Group Engagement", "number_of_entities": 4000, "lss": 12},
    {"id": 3, "name": "Young Couples", "number_of_entities": 2569, "lss": 60},
    {"id": 4, "name": "Daytime Footfall Increase", "number_of_entities": 5200, "lss": 9},
    {"id": 5, "name": "Campaign 1", "number_of_entities": 6257, "lss": 90},
    {"id": 6, "name": "Campaign 2", "number_of_entities": 253, "lss": 19},
    {"id": 7, "name": "Campaign 3", "number_of_entities": 3688, "lss": 13},
    {"id": 8, "name": "Campaign 4", "number_of_entities": 100, "lss": 25}
  ]

  filterPostData = {};

  campaigns = [];
  sub_categories = [];
  subCategoriesTreeData = [];
  selectedSubCategories = [];

  selectedTypeCount = 0;
  selectedTierCount = 0;
  selectedFollewerNumber = "";
  selectedEngagementRate = "";
  selectedSubCategoryCount = 0;
  selectedEventTypeCount = 0;

  types = [
    {"id": 1, "name": "Influencers"},
    {"id": 2, "name": "Brands"},
    {"id": 3, "name": "NGOs"}
  ]

  tiers = [
    {"id": 1, "name": "Tier1"},
    {"id": 2, "name": "Tier2"},
    {"id": 3, "name": "Tier3"},
    {"id": 4, "name": "Tier4"}
  ]

  eventTypes = [
    {"id": 1, "name": "Pop-up"},
    {"id": 2, "name": "Experience"},
    {"id": 3, "name": "Workshop"},
    {"id": 4, "name": "Awareness"}
  ]

  typeForm: FormGroup;
  tierForm: FormGroup;
  eventTypeForm: FormGroup;

  treeOptions: ITreeOptions = {
    useCheckbox: true
  };

  exampleData = [
    {
      "id": 1,
      "type": "influencer",
      "image": "person6.png",
      "name": "Jack Harris",
      "instagram_name": "jackharris",
      "job": "Community ambassodor",
      "description": "You Tube channel/drum teacher/ ARTIST: @paistecymbals @katana_drums NEW DRUM COVER @fallinginreverse - POPULAR MONSTER youtu.be/DJAbkHJdWy4",
      "follower_number": "50K",
      "engagement_rate": "50",
      "number_of_posts": "2678",
      "popularity_change": "+345"
    },
    {
      "id": 2,
      "type": "influencer",
      "image": "person2.png",
      "name": "Lele Pons",
      "instagram_name": "lelepons",
      "job": "Singer",
      "description": "From Venezuela United States, YouTube / singer / actress",
      "follower_number": "44M",
      "engagement_rate": "62",
      "number_of_posts": "2434",
      "popularity_change": "+876"
    },
    {
      "id": 3,
      "type": "influencer",
      "image": "person3.png",
      "name": "PewDiePie",
      "instagram_name": "pewdiepie",
      "job": "Comedian",
      "description": "Swedish YouTuber / New “Mischief” Tsuki Collectionbit.ly/3llikUT",
      "follower_number": "21.9M",
      "engagement_rate": "77",
      "number_of_posts": "771",
      "popularity_change": "+288"
    },
    {
      "id": 4,
      "type": "influencer",
      "image": "person4.png",
      "name": "Zach King",
      "instagram_name": "zachking",
      "job": "Film producer",
      "description": "Digital creator / Contact - hello@zachkingteam.com",
      "follower_number": "24.6M",
      "engagement_rate": "39",
      "number_of_posts": "1022",
      "popularity_change": "+237"
    },
    {
      "id": 5,
      "type": "influencer",
      "image": "person5.png",
      "name": "Estée Lalonde",
      "instagram_name": "esteelalonde",
      "job": "Creative Director",
      "description": "London 💘 Founder @mirrorwater.earth Shop my pieces @daisy_jewellery ✨ agent: ahoward@WMEagency.com",
      "follower_number": "696k",
      "engagement_rate": "32",
      "number_of_posts": "3921",
      "popularity_change": "+318"
    },
    {
      "id": 6,
      "type": "influencer",
      "image": "person.png",
      "name": "Sophie Hinchliffe",
      "instagram_name": "mrshinchhome",
      "job": "Author",
      "description": "‘Home’ is my passion 🏡 / 📖 Mrs Hinch Books ↓ 🙊 bit.ly/MRSHINCHPAGE",
      "follower_number": "4.2M",
      "engagement_rate": "44",
      "number_of_posts": "240",
      "popularity_change": "+189"
    },
    {
      "id": 7,
      "type": "influencer",
      "image": "person6.png",
      "name": "Jack Harris",
      "instagram_name": "jackharris",
      "job": "Community ambassodor",
      "description": "You Tube channel/drum teacher/ ARTIST: @paistecymbals @katana_drums NEW DRUM COVER @fallinginreverse - POPULAR MONSTER youtu.be/DJAbkHJdWy4",
      "follower_number": "50K",
      "engagement_rate": "50",
      "number_of_posts": "2678",
      "popularity_change": "+345"
    },
    {
      "id": 8,
      "type": "influencer",
      "image": "person2.png",
      "name": "Lele Pons",
      "instagram_name": "lelepons",
      "job": "Singer",
      "description": "From Venezuela United States, YouTube / singer / actress",
      "follower_number": "44M",
      "engagement_rate": "62",
      "number_of_posts": "2434",
      "popularity_change": "+876"
    },
    {
      "id": 9,
      "type": "influencer",
      "image": "person3.png",
      "name": "PewDiePie",
      "instagram_name": "pewdiepie",
      "job": "Comedian",
      "description": "Swedish YouTuber / New “Mischief” Tsuki Collectionbit.ly/3llikUT",
      "follower_number": "21.9M",
      "engagement_rate": "77",
      "number_of_posts": "771",
      "popularity_change": "+288"
    },
    {
      "id": 10,
      "type": "influencer",
      "image": "person4.png",
      "name": "Zach King",
      "instagram_name": "zachking",
      "job": "Film producer",
      "description": "Digital creator / Contact - hello@zachkingteam.com",
      "follower_number": "24.6M",
      "engagement_rate": "39",
      "number_of_posts": "1022",
      "popularity_change": "+237"
    },
    {
      "id": 11,
      "type": "influencer",
      "image": "person5.png",
      "name": "Estée Lalonde",
      "instagram_name": "esteelalonde",
      "job": "Creative Director",
      "description": "London 💘 Founder @mirrorwater.earth Shop my pieces @daisy_jewellery ✨ agent: ahoward@WMEagency.com",
      "follower_number": "696k",
      "engagement_rate": "32",
      "number_of_posts": "3921",
      "popularity_change": "+318"
    },
    {
      "id": 12,
      "type": "influencer",
      "image": "person.png",
      "name": "Sophie Hinchliffe",
      "instagram_name": "mrshinchhome",
      "job": "Author",
      "description": "‘Home’ is my passion 🏡 / 📖 Mrs Hinch Books ↓ 🙊 bit.ly/MRSHINCHPAGE",
      "follower_number": "4.2M",
      "engagement_rate": "44",
      "number_of_posts": "240",
      "popularity_change": "+189"
    }
  ]


  constructor(
    public fb: FormBuilder,
    private dataservice: DataService,
  ) { }

  ngOnInit(): void {
    this.campaigns = this.unsorted_campaigns;
    // this.all_suggested_engagement_activities = Array.from(Array(500).keys())
    this.all_suggested_engagement_activities = this.exampleData
    this.suggested_engagement_activities = this.all_suggested_engagement_activities.slice(0, 6)
    this.getCategories()

    // generate form group data
    this.typeForm = this.fb.group({});
    this.tierForm = this.fb.group({});
    this.eventTypeForm = this.fb.group({});

    // create dynamic type form
    let typeGroup = {};
    this.types.forEach(x=>{
      typeGroup[x["id"]]=new FormControl('');
    })
    this.typeForm = this.fb.group(typeGroup);

    // create dynamic tier form
    let tierGroup = {};
    this.tiers.forEach(x=>{
      tierGroup[x["id"]]=new FormControl('');
    })
    this.tierForm = this.fb.group(tierGroup);

    // create dynamic event type form
    let eventTypeGroup = {};
    this.eventTypes.forEach(x=>{
      eventTypeGroup[x["id"]]=new FormControl('');
    })
    this.eventTypeForm = this.fb.group(eventTypeGroup);
  }

  getCategories() {
    this.dataservice.getSubCategories().then((value: any[]) => {
      this.sub_categories = value;
      this.subCategoriesTreeData = this.categoryDataMapper(this.sub_categories, [])
    })
  }

  changeTab(tab) {
    if (tab == "campaign-name") {
      this.modalNextButtonText = "Next";
      this.modalPreviousButtonText = "Back To Selection";
      let campaginName: HTMLElement = document.getElementsByClassName("modal-tab-content tab-pane fade mx-3")[0] as HTMLElement
      campaginName.className = "modal-tab-content tab-pane fade mx-3 active show";
      this.goPrevious()
    }
    else if (tab == "target-audience") {
      this.modalNextButtonText = "Next";
      this.modalPreviousButtonText = "Back To Campaign Name";
    }
    else if (tab == "shopper-behaviour") {
      this.modalNextButtonText = "Next";
      this.modalPreviousButtonText = "Back To Target Audience";
    }
    else if (tab == "engagement-focus") {
      this.modalNextButtonText = "Next";
      this.modalPreviousButtonText = "Back To Shopper Behaviour";
    }
    else if (tab == "summary") {
      this.modalNextButtonText = "Create";
      this.modalPreviousButtonText = "Back To Engagement Focus";
    }
  }

  goNext() {
    let index;
    if (this.modalPreviousButtonText == "Back To Selection") {
      index = 1;
      this.modalPreviousButtonText = "Back To Campaign Name"
      this.modalNextButtonText = "Next";
    }
    else if (this.modalPreviousButtonText == "Back To Campaign Name") {
      index = 2;
      this.modalPreviousButtonText = "Back To Target Audience"
      this.modalNextButtonText = "Next";
    }
    else if (this.modalPreviousButtonText == "Back To Target Audience") {
      index = 3;
      this.modalPreviousButtonText = "Back To Shopper Behaviour"
      this.modalNextButtonText = "Next";
    }
    else if (this.modalPreviousButtonText == "Back To Shopper Behaviour") {
      index = 4;
      this.modalPreviousButtonText = "Back To Engagement Focus"
      this.modalNextButtonText = "Create";
    }
    else if (this.modalPreviousButtonText == "Back To Engagement Focus") {
      index = 5;
      this.modalNextButtonText = "Create";
    }
    for (let item of [0,1,2,3,4]) {
      if (item == index) {
        let tab: HTMLElement = document.getElementsByClassName("modal-tab")[index] as HTMLElement
        tab.className = "modal-tab nav-link active  px-1";
        let content: HTMLElement = document.getElementsByClassName("modal-tab-content")[index] as HTMLElement
        content.className = "modal-tab-content tab-pane fade show active mx-3";
      } else {
        let tab: HTMLElement = document.getElementsByClassName("modal-tab")[item] as HTMLElement
        tab.className = "modal-tab nav-link px-1";
        let content: HTMLElement = document.getElementsByClassName("modal-tab-content")[item] as HTMLElement
        content.className = "modal-tab-content tab-pane fade mx-3";
      }
    }
  }

  goPrevious() {
    let index;
    if (this.modalPreviousButtonText == "Back To Selection") {
      index = 0;
      this.modalPreviousButtonText = "Back To Selection"
      this.modalNextButtonText = "Next";
    }
    else if (this.modalPreviousButtonText == "Back To Campaign Name") {
      index = 0;
      this.modalPreviousButtonText = "Back To Selection"
      this.modalNextButtonText = "Next";
    }
    else if (this.modalPreviousButtonText == "Back To Target Audience") {
      index = 1;
      this.modalPreviousButtonText = "Back To Campaign Name"
      this.modalNextButtonText = "Next";
    }
    else if (this.modalPreviousButtonText == "Back To Shopper Behaviour") {
      index = 2;
      this.modalPreviousButtonText = "Back To Target Audience"
      this.modalNextButtonText = "Next";
    }
    else if (this.modalPreviousButtonText == "Back To Engagement Focus") {
      index = 3;
      this.modalPreviousButtonText = "Back To Shopper Behaviour"
      this.modalNextButtonText = "Next";
    }
    else if (this.modalPreviousButtonText == "Summary") {
      index = 4;
      this.modalPreviousButtonText = "Back To Engagement Focus"
      this.modalNextButtonText = "Next";
    }
    for (let item of [0,1,2,3,4]) {
      if (item == index) {
        let tab: HTMLElement = document.getElementsByClassName("modal-tab")[index] as HTMLElement
        tab.className = "modal-tab nav-link active  px-1";
        let content: HTMLElement = document.getElementsByClassName("modal-tab-content")[index] as HTMLElement
        content.className = "modal-tab-content tab-pane fade show active mx-3";
      } else {
        let tab: HTMLElement = document.getElementsByClassName("modal-tab")[item] as HTMLElement
        tab.className = "modal-tab nav-link px-1";
        let content: HTMLElement = document.getElementsByClassName("modal-tab-content")[item] as HTMLElement
        content.className = "modal-tab-content tab-pane fade mx-3";
      }
    }
  }

  toogleAgeGroup(ageGroupId) {
    for (let ageGroup of this.ageGroups) {
      let index = this.ageGroups.indexOf(ageGroup)
      if (ageGroup["id"] == ageGroupId) {
        if (ageGroup["selected"]) {
          this.ageGroups[index] = {"id": ageGroup["id"], "name": ageGroup["name"], "value": ageGroup["value"], "selected": false}
        } else {
          this.ageGroups[index] = {"id": ageGroup["id"], "name": ageGroup["name"], "value": ageGroup["value"], "selected": true }
        }
      }
    }
  }

  toogleIncomeLevel(incomeLevelId) {
    for (let incomeLevel of this.incomeLevels) {
      let index = this.incomeLevels.indexOf(incomeLevel)
      if (incomeLevel["id"] == incomeLevelId) {
        if (incomeLevel["selected"]) {
          this.incomeLevels[index] = {"id": incomeLevel["id"], "name": incomeLevel["name"], "value": incomeLevel["value"], "selected": false}
        } else {
          this.incomeLevels[index] = {"id": incomeLevel["id"], "name": incomeLevel["name"], "value": incomeLevel["value"], "selected": true }
        }
      }
    }
  }

  toogleGender(genderId) {
    for (let gender of this.genders) {
      let index = this.genders.indexOf(gender)
      if (gender["id"] == genderId) {
        if (gender["selected"]) {
          this.genders[index] = {"id": gender["id"], "name": gender["name"], "value": gender["value"], "selected": false}
        } else {
          this.genders[index] = {"id": gender["id"], "name": gender["name"], "value": gender["value"], "selected": true }
        }
      }
    }
  }

  toogleOccupation(occupationId) {
    for (let occupation of this.occupations) {
      let index = this.occupations.indexOf(occupation)
      if (occupation["id"] == occupationId) {
        if (occupation["selected"]) {
          this.occupations[index] = {"id": occupation["id"], "name": occupation["name"], "value": occupation["value"], "selected": false}
        } else {
          this.occupations[index] = {"id": occupation["id"], "name": occupation["name"], "value": occupation["value"], "selected": true }
        }
      }
    }
  }

  toogleLifestyleFocus(lifestyleFocusId) {
    for (let lifestyleFocus of this.lifestyleFocuses) {
      let index = this.lifestyleFocuses.indexOf(lifestyleFocus)
      if (lifestyleFocus["id"] == lifestyleFocusId) {
        if (lifestyleFocus["selected"]) {
          this.lifestyleFocuses[index] = {"id": lifestyleFocus["id"], "name": lifestyleFocus["name"], "value": lifestyleFocus["value"], "selected": false}
        } else {
          this.lifestyleFocuses[index] = {"id": lifestyleFocus["id"], "name": lifestyleFocus["name"], "value": lifestyleFocus["value"], "selected": true }
        }
      }
    }
  }

  tooglePlace(placeId) {
    for (let place of this.places) {
      let index = this.places.indexOf(place)
      if (place["id"] == placeId) {
        if (place["selected"]) {
          this.places[index] = {"id": place["id"], "name": place["name"], "value": place["value"], "selected": false}
        } else {
          this.places[index] = {"id": place["id"], "name": place["name"], "value": place["value"], "selected": true }
        }
      }
    }
  }

  toogleTimePeriod(timePeriodId) {
    for (let timePeriod of this.timePeriods) {
      let index = this.timePeriods.indexOf(timePeriod)
      if (timePeriod["id"] == timePeriodId) {
        if (timePeriod["selected"]) {
          this.timePeriods[index] = {"id": timePeriod["id"], "name": timePeriod["name"], "value": timePeriod["value"], "selected": false}
        } else {
          this.timePeriods[index] = {"id": timePeriod["id"], "name": timePeriod["name"], "value": timePeriod["value"], "selected": true }
        }
      }
    }
  }

  toogleDay(dayId) {
    for (let day of this.days) {
      let index = this.days.indexOf(day)
      if (day["id"] == dayId) {
        if (day["selected"]) {
          this.days[index] = {"id": day["id"], "name": day["name"], "value": day["value"], "selected": false}
        } else {
          this.days[index] = {"id": day["id"], "name": day["name"], "value": day["value"], "selected": true }
        }
      }
    }
  }

  toogleSeason(seasonId) {
    for (let season of this.seasons) {
      let index = this.seasons.indexOf(season)
      if (season["id"] == seasonId) {
        if (season["selected"]) {
          this.seasons[index] = {"id": season["id"], "name": season["name"], "value": season["value"], "selected": false}
        } else {
          this.seasons[index] = {"id": season["id"], "name": season["name"], "value": season["value"], "selected": true }
        }
      }
    }
  }

  toogleActivationCategory(activationCategoryId) {
    for (let activationCategory of this.activationCategories) {
      let index = this.activationCategories.indexOf(activationCategory)
      if (activationCategory["id"] == activationCategoryId) {
        if (activationCategory["selected"]) {
          this.activationCategories[index] = {"id": activationCategory["id"], "name": activationCategory["name"], "value": activationCategory["value"], "selected": false}
        } else {
          this.activationCategories[index] = {"id": activationCategory["id"], "name": activationCategory["name"], "value": activationCategory["value"], "selected": true }
        }
      }
    }
  }

  toogleActivationType(activationTypeId) {
    for (let activationType of this.activationTypes) {
      let index = this.activationTypes.indexOf(activationType)
      if (activationType["id"] == activationTypeId) {
        if (activationType["selected"]) {
          this.activationTypes[index] = {"id": activationType["id"], "name": activationType["name"], "value": activationType["value"], "selected": false}
        } else {
          this.activationTypes[index] = {"id": activationType["id"], "name": activationType["name"], "value": activationType["value"], "selected": true }
        }
      }
    }
  }

  createCampaign() {
    this.isCampaign = true;
  }

  toggleCampaign(type) {
    if (type == "campaigns") {
      this.isCampaignToggle = true;
      this.isAgendaToggle = false;
    }
    else if (type == "agenda") {
      this.isCampaignToggle = false;
      this.isAgendaToggle = true;
    }
  }

  onSort(column) {
    let direction;
    if (column == "name") {
      this.isNameSorted = true;
      this.isNumberOfENtitiesSorted = false;
      this.isLssSorted = false;
      this.isNameAsc = !this.isNameAsc
      if (this.isNameAsc) {
        direction = 'asc'
      } else {
        direction = 'desc'
      }
    }
    else if (column == "number_of_entities") {
      this.isNameSorted = false;
      this.isNumberOfENtitiesSorted = true;
      this.isLssSorted = false;
      this.isNumberOfENtitiesAsc = !this.isNumberOfENtitiesAsc
      if (this.isNumberOfENtitiesAsc) {
        direction = 'asc'
      } else {
        direction = 'desc'
      }
    }
    else if (column == "lss") {
      this.isNameSorted = false;
      this.isNumberOfENtitiesSorted = false;
      this.isLssSorted = true;
      this.isLssAsc = !this.isLssAsc
      if (this.isLssAsc) {
        direction = 'asc'
      } else {
        direction = 'desc'
      }
    }
    this.campaigns = [...this.unsorted_campaigns].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }

  selectCampaign(campaignId) {
    this.selectedTypeCount = 0;
    this.selectedTierCount = 0;
    this.selectedFollewerNumber = "";
    this.selectedEngagementRate = "";
    this.selectedSubCategoryCount = 0;
    this.selectedEventTypeCount = 0;
    for (let campaign of this.campaigns) {
      if (campaign["id"] == campaignId) {
        this.selectedCampaign = campaign;
        this.suggested_engagement_activities = this.all_suggested_engagement_activities.slice(0, 6)
        this.suggested_engagement_activity_ready = true;
      }
    }
  }

  showMore() {
    let len = this.suggested_engagement_activities.length
    this.suggested_engagement_activities = this.all_suggested_engagement_activities.slice(0, len+6)
    console.log(this.suggested_engagement_activities)
  }

  toggleFilterButton(type) {
    console.log(type)
  }

  applyLocationFilters(type) {
    this.suggested_engagement_activity_ready = false;
    this.suggested_engagement_activities = [];
    console.log(type)
    if (type == "type") {
      let postData = []
      for (let i in this.typeForm.value) {
        if (this.typeForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterPostData["type"] = postData
      this.typeSearchFilter()
    }
    else if (type == "tier") {
      let postData = []
      for (let i in this.tierForm.value) {
        if (this.tierForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterPostData["tier"] = postData
      this.tierSearchFilter()
    }
    else if (type == "follower-number") {
      let postData = []
      // for (let i in this.tierForm.value) {
      //   if (this.tierForm.value[i] == true) {
      //     postData.push(Number(i))
      //   }
      // }
      this.filterPostData["follower-number"] = postData
      this.followerNumberSearchFilter()
    }
    else if (type == "engagement-rate") {
      let postData = []
      // for (let i in this.tierForm.value) {
      //   if (this.tierForm.value[i] == true) {
      //     postData.push(Number(i))
      //   }
      // }
      this.filterPostData["engagement-rate"] = postData
      this.engagementRateSearchFilter()
    }
    else if (type == "sub-category") {
      let postData = this.selectedSubCategories
      // for (let i in this.tierForm.value) {
      //   if (this.tierForm.value[i] == true) {
      //     postData.push(Number(i))
      //   }
      // }
      this.filterPostData["sub-category"] = postData
      this.engagementRateSearchFilter()
    }
    else if (type == "event-type") {
      let postData = []
      for (let i in this.eventTypeForm.value) {
        if (this.eventTypeForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterPostData["event-type"] = postData
      this.eventTypeSearchFilter()
    }
    console.log("FILTER:", this.filterPostData)
    setTimeout(() => {
      this.suggested_engagement_activities = this.all_suggested_engagement_activities.slice(0, 6)
      this.suggested_engagement_activity_ready = true;
    }, 1000);
  }

  typeSearchFilter() {
    this.selectedTypeCount = 0;
    let form_values = this.typeForm.value;
    for (let item of this.types) {
      if (form_values[item["id"]] == true) {
        this.selectedTypeCount = this.selectedTypeCount + 1
      }
    }
  }

  tierSearchFilter() {
    this.selectedTierCount = 0;
    let form_values = this.tierForm.value;
    for (let item of this.tiers) {
      if (form_values[item["id"]] == true) {
        this.selectedTierCount = this.selectedTierCount + 1
      }
    }
  }

  followerNumberSearchFilter() {
    // this.selectedFollewerNumber = "";
    // let form_values = this.tierForm.value;
    // for (let item of this.tiers) {
    //   if (form_values[item["id"]] == true) {
    //     this.selectedFollewerNumber = this.selectedTierCount + 1
    //   }
    // }
  }

  engagementRateSearchFilter() {
    // this.selectedFollewerNumber = "";
    // let form_values = this.tierForm.value;
    // for (let item of this.tiers) {
    //   if (form_values[item["id"]] == true) {
    //     this.selectedFollewerNumber = this.selectedTierCount + 1
    //   }
    // }
  }

  eventTypeSearchFilter() {
    this.selectedEventTypeCount = 0;
    let form_values = this.eventTypeForm.value;
    for (let item of this.eventTypes) {
      if (form_values[item["id"]] == true) {
        this.selectedEventTypeCount = this.selectedEventTypeCount + 1
      }
    }
  }

  onMinFollowerValueChange(value) {
    this.minFollowerValue = value;
  }

  onMaxFollowerValueChange(value) {
    this.maxFollowerValue = value;
  }

  onFollowerNumberValueChange(changeContext: ChangeContext) {
    this.minFollowerValue = changeContext.value;
    this.maxFollowerValue = changeContext.highValue;
    this.selectedFollewerNumber = this.intToString(this.minFollowerValue) + " - " + this.intToString(this.maxFollowerValue)

    let followerDropdownMenu: HTMLElement = document.getElementsByClassName("follower-dropdown-menu")[0] as HTMLElement
    followerDropdownMenu.className = "dropdown-menu follower-dropdown-menu px-3 py-4 my-2 tree-dropdown";

    let dropdownMenuLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[3] as HTMLElement
    dropdownMenuLink.setAttribute('aria-expanded', 'true')
    setTimeout(() => {
      dropdownMenuLink.setAttribute('aria-expanded', 'true')
    }, 10);
  }

  onMinEngagementValueChange(value) {
    this.minEngagementValue = value;
  }

  onMaxEngagementValueChange(value) {
    this.maxEngagementValue = value;
  }

  onEngagementNumberValueChange(changeContext: ChangeContext) {
    this.minEngagementValue = changeContext.value;
    this.maxEngagementValue = changeContext.highValue;
    this.selectedEngagementRate = this.intToString(this.minEngagementValue) + " - " + this.intToString(this.maxEngagementValue)

    let engagementDropdownMenu: HTMLElement = document.getElementsByClassName("engagement-dropdown-menu")[0] as HTMLElement
    engagementDropdownMenu.className = "dropdown-menu engagement-dropdown-menu px-3 py-4 my-2 tree-dropdown";

    let dropdownMenuLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[4] as HTMLElement
    dropdownMenuLink.setAttribute('aria-expanded', 'true')
    setTimeout(() => {
      dropdownMenuLink.setAttribute('aria-expanded', 'true')
    }, 10);
  }

  clearRangeFilters(type) {
    if (type == "follower-number") {
      this.minFollowerValue = 50000;
      this.maxFollowerValue = 100000;
      this.selectedFollewerNumber = "";
    }
    else if (type == "engagement-rate") {
      this.minEngagementValue = 50000;
      this.maxEngagementValue = 100000;
      this.selectedEngagementRate = "";
    }
  }

  getSubCategoryId(name) {
    for (let sub of this.sub_categories) {
      if (sub["name"] == name) {
        return Number(sub["id"])
      }
    }
  }

  onSelectSubCategory(event) {
    let subCategoryId = this.getSubCategoryId(event.node.data.name)
    this.selectedSubCategories.push(subCategoryId)
    this.selectedSubCategoryCount = this.selectedSubCategoryCount + 1
    this.applyLocationFilters("sub-category");
  }

  onDeselectSubCategory(event) {
    let index = this.selectedSubCategories.indexOf(event.node.data.name, 0);
    if (index == -1) {
       this.selectedSubCategories.splice(index, 1);
       this.selectedSubCategoryCount = this.selectedSubCategoryCount - 1
    }
    this.applyLocationFilters("sub-category");
  }

  onToggleSubCategory(event) {
    let treeDropdownMenu: HTMLElement = document.getElementsByClassName("tree-dropdown-menu")[0] as HTMLElement
    treeDropdownMenu.className = "dropdown-menu tree-dropdown-menu px-3 py-4 my-2 tree-dropdown";

    let dropdownMenuLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[1] as HTMLElement
    dropdownMenuLink.setAttribute('aria-expanded', 'true')
    setTimeout(() => {
      dropdownMenuLink.setAttribute('aria-expanded', 'true')
    }, 10);
  }

  clickOutside(event) {
    let treeDropdownMenu: HTMLElement = document.getElementsByClassName("tree-dropdown-menu")[0] as HTMLElement
    treeDropdownMenu.className = "dropdown-menu tree-dropdown-menu px-3 py-4 my-2";
    let dropdownMenuLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[1] as HTMLElement
    dropdownMenuLink.setAttribute('aria-expanded', 'false')
  }

  clickOutsideFollower(event) {
    let treeDropdownMenu: HTMLElement = document.getElementsByClassName("follower-dropdown-menu")[0] as HTMLElement
    treeDropdownMenu.className = "dropdown-menu follower-dropdown-menu px-3 py-4 my-2";
    let dropdownMenuLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[3] as HTMLElement
    dropdownMenuLink.setAttribute('aria-expanded', 'false')
  }

  clickOutsideEngagement(event) {
    let treeDropdownMenu: HTMLElement = document.getElementsByClassName("engagement-dropdown-menu")[0] as HTMLElement
    treeDropdownMenu.className = "dropdown-menu engagement-dropdown-menu px-3 py-4 my-2";
    let dropdownMenuLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[4] as HTMLElement
    dropdownMenuLink.setAttribute('aria-expanded', 'false')
  }

  categoryDataMapper(sub_categories, category_list) {
    let newCategoryData = [];
    let categories = [];
    for (let sub of sub_categories) {
      if (category_list.length) {
        if ((categories.indexOf(sub["category"]["name"]) < 0) && (category_list.indexOf(sub["category"]["id"]) > -1))  {
          categories.push(sub["category"]["name"])
          newCategoryData.push({
            // id: sub["category"]["id"],
            name: sub["category"]["name"],
            children: []
          })
        }
      } else {
        if (categories.indexOf(sub["category"]["name"]) < 0) {
          categories.push(sub["category"]["name"])
          newCategoryData.push({
            // id: sub["category"]["id"],
            name: sub["category"]["name"],
            children: []
          })
        }
      }
    }
    for (let sub of sub_categories) {
      for (let root of newCategoryData) {
        if (sub["category"]["name"] == root["name"]) {
          root["children"].push({
            // id: sub["id"],
            name: sub["name"],
          })
        }
      }
    }
    return newCategoryData
  }

  intToString(num) {
    if (num > 999 && num < 1000000) {
      return (num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000){
      return (num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  }







}
