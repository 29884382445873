<section class="market-dynamics-section" style="backgroundcolor: white;">
  <div class="px-5 pt-5 pb-3 d-flex align-items-center">
    <p class="section-header-text text-uppercase cursor-pointer">Market Dynamics</p>
    <div class="dropdown mb-3">
      <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>
      </button>
      <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
        <p class="info-text mb-0">Insight into the existing retail landscape
          and consumer base, using data analytics from sources including:
          Google, TripAdvisor, Foursquare and Instagram.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="filter-section">
  <div class=" d-flex align-items-center px-5">
    <div class="filter-buttons d-flex align-items-center">
      <div *ngIf="!isFilterDataReady" class="div mx-3">
        <div class="spinner-border text-danger" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" (click)="toggleFilterButton('category')" [class.disabled]="false">
          Category <span *ngIf="selectedCategoryCount" class="bold-text"> ({{ selectedCategoryCount }})</span>
        </button>
        <ul class="dropdown-menu tree-dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink" (ngxOutsideClick)="clickOutside($event, 'category')">
          <form class="form-inline pb-0 mb-3">
            <div class="search-box">
              <div class="d-flex align-items-center">
                <input #filter (keyup)="tree.treeModel.filterNodes(filter.value)" name="search" style="border: 0px;" type="search" class="form-control rounded search-input" placeholder="Search in Categories" aria-label="Search" aria-describedby="search-addon"
                  autofocus />
              </div>
            </div>
          </form>
          <div class="scroll-div-filter">
            <tree-root #tree
              [nodes]="subCategoriesTreeData"
              [options]="treeOptions"
              (stateChange)="onStateChangeSubCategory($event)"
              (select)="onSelectSubCategory($event)"
              (deselect)="onDeselectSubCategory($event)"
              (toggleExpanded)="onToggleSubCategory($event)">
            </tree-root>
          </div>
        </ul>
      </div>
      <div *ngIf="periods.length" class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn {{ by_period }} px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" (click)="toggleFilterButton('period')"  [class.disabled]="!isPeriodDataReady">
          Period <span *ngIf="selectedPeriodCount" class="bold-text"> ({{ selectedPeriodCount }})</span>
        </button>
        <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <form [formGroup]="periodForm" (ngSubmit)="periodSearchFilter()">
            <li class="my-2" *ngFor="let period of periods">
              <a class="dropdown-item">
                <div class="form-check">
                  <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="period['id']" (change)="applyLocationFilters('period', false)" id="flexCheckDefault">
                  <label class="form-check-label" for="flexCheckDefault">
                    {{ period['name'].replace("_", "-") | titlecase }}
                  </label>
                </div>
              </a>
            </li>
          </form>
        </ul>
      </div>
      <div *ngIf="catchmentAreas.length" class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn {{ by_catchment_area }} px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" (click)="toggleFilterButton('catchment')"  [class.disabled]="!isAreaDataReady">
          Catchment Area <span *ngIf="selectedCatchmentAreasCount" class="bold-text"> ({{ selectedCatchmentAreasCount }})</span>
        </button>
        <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <form class="form-inline pb-0 mb-3" (submit)="searchCatchmentArea()" action="#">
            <div class="search-box">
              <div class="d-flex align-items-center">
                <!-- <img class="search_icon mr-2" src="../../../assets/icon/search-icon.svg"> -->
                <input [(ngModel)]="searchTextCatchmentArea" name="search" style="border: 0px;" type="search" class="form-control rounded search-input" placeholder="Search Your Catchment Area" aria-label="Search" aria-describedby="search-addon"
                  autofocus />
              </div>
            </div>
          </form>
          <form [formGroup]="catchmentAreaForm" (ngSubmit)="applyLocationFilters('catchment-area', false)">
            <div class="scroll-div-filter">
            <li class="my-2" *ngFor="let catchment_area of catchmentAreas | filter: searchTextCatchmentArea;">
              <a class="dropdown-item">
                <div class="form-check">
                  <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="catchment_area['id']" (change)="applyLocationFilters('catchment-area', false)" id="flexCheckDefault">
                  <label class="form-check-label" for="flexCheckDefault">
                    {{ catchment_area['name'] }}
                  </label>
                </div>
              </a>
            </li>
            </div>
          </form>
        </ul>
      </div>
      <div *ngIf="streetsTreeData.length" class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn {{ by_catchment_area }} px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" (click)="toggleFilterButton('street')" [class.disabled]="!isStreetDataReady">
          Street Name <span *ngIf="selectedStreetCount" class="bold-text"> ({{ selectedStreetCount }})</span>
        </button>
        <ul class="dropdown-menu tree-dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink" (ngxOutsideClick)="clickOutside($event, 'street')">
          <form class="form-inline pb-0 mb-3">
            <div class="search-box">
              <div class="d-flex align-items-center">
                <input #filter (keyup)="tree.treeModel.filterNodes(filter.value)" name="search" style="border: 0px;" type="search" class="form-control rounded search-input" placeholder="Search in Streets" aria-label="Search" aria-describedby="search-addon"
                  autofocus />
              </div>
            </div>
          </form>
          <div id="street-tree" class="scroll-div-filter">
            <tree-root #tree
              [nodes]="streetsTreeData"
              [options]="treeOptions"
              (stateChange)="onStateChangeStreet($event)"
              (select)="onSelectStreet($event)"
              (deselect)="onDeselectStreet($event)"
              (toggleExpanded)="onToggleStreet($event)">
            </tree-root>
          </div>
        </ul>
      </div>
      <button type="button" class="btn filter-text text-uppercase" (click)="resetFilters()"><u>Reset Filters</u></button>
      <!-- <button type="button" class="btn filter-text text-uppercase factsheet-button" data-bs-toggle="modal" data-bs-target="#factsheet-modal" (click)="openFactsheetModal()"><u>Generate Factsheet</u></button> -->
      <button type="button" class="btn filter-text text-uppercase factsheet-button"><u>Generate Factsheet</u></button>
    </div>
  </div>
</section>

<!-- Factsheet Modal -->
<div class="modal fade" id="factsheet-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered factsheet-modal" role="document">
    <div *ngIf="isFactsheetForm" class="modal-content p-3">
      <div class="modal-header">
        <h5 class="modal-title text-uppercase">Generate Factsheet</h5>
        <button type="button" class="btn-close btn-lg" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-center align-items-canter">
          <form class="factsheet-form" [formGroup]="factsheetForm" (ngSubmit)="generateFactsheet()">
            <div class="modal-form mb-4">
              <label class="form-label pl-2">Catchment Area</label>
              <select class="form-select p-3 m-0" formControlName="catchment_area">
                <option disabled>Please select one</option>
                <option *ngFor="let catchment_area of catchmentAreas" value="{{ catchment_area['id'] }}">{{ catchment_area['name'] }}</option>
              </select>
            </div>
            <div class="modal-form mb-4">
              <label class="form-label pl-2">Street</label>
              <select class="form-select p-3 m-0" formControlName="street">
                <option disabled>Please select one</option>
                <option *ngFor="let street of streets" value="{{ street['id'] }}">{{ street['name'] }}</option>
              </select>
            </div>
            <div class="modal-form mb-4">
              <label class="form-label pl-2">Category</label>
              <select class="form-select p-3 m-0" formControlName="category">
                <option disabled>Please select one</option>
                <option *ngFor="let category of categories" value="{{ category['id'] }}">{{ category['name'] }}</option>
              </select>
            </div>
            <div class="factsheet-modal-footer mt-5">
              <button style="float: right;" type="submit" class="btn btn-dark dark-btn filter-save-btn text-uppercase px-5 mt-4">Genereate Factsheet</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div *ngIf="!isFactsheetForm" class="modal-content p-5">
      <div class="modal-body m-3">
        <div class="d-flex justify-content-center align-items-canter px-5">
          <div class="text-center">
            <h5 class="modal-title factsheet-modal-title">You successfully created your request</h5>
            <i class="fa fa-check fa-xs fa-2x success-icon my-5"></i>
            <p class="factsheet-modal-content">When your “Fact Sheet” is ready you can find it in “Fact Sheet” section under your profile picture.</p>
            <button type="button" class="btn btn-dark dark-btn filter-save-btn text-uppercase px-5 mt-5 mb-3" data-bs-dismiss="modal">Done</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section *ngIf="returnSectionSituation('Map') || isMapContainer" class="map-section">
  <div class="px-5 pt-4 d-flex align-items-center">
    <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked (change)="applyLocationFilters('grayMarker', false)">
      <label class="form-check-label" for="flexSwitchCheckDefault">Dynamic Locations</label>
    </div>
    <div class="dropdown">
      <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>
      </button>
      <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
        <p class="info-text mb-0">Dynamic Locations are the local attraction
          points that NeaRE monitors the visit, consumption and engagement
          data in near real-time. The remaining locations are marked by Grey
          Pins on the map below.
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="isMapContainer" class="px-5 py-4">
    <div id="map" class="{{ isShadow }}"></div>
  </div>
</section>

<section *ngIf="returnSectionSituation('Business Breakdown Charts')" class="tenant-popularity-section">
  <div class="row px-5 py-2">
    <div class="col-md-12 col-lg-6">
      <div class="white-card p-3 {{ isShadow }}" style="height: 100%;">
        <div class="d-flex mt-2">
          <ul class="nav nav-pills nav-pills-shadow mb-3" id="pills-tab" role="tablist">
            <li class="nav-item tab-button text-center mr-2 mb-1" role="presentation">
              <a class="nav-link active d-flex align-items-center px-3" id="pills-home-tab" data-bs-toggle="pill" href="#pills-price-breakdown" role="tab" aria-controls="pills-home" aria-selected="true">
                PRICE BREAKDOWN
                <div class="dropdown">
                  <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                  </button>
                  <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                    <p class="info-text mb-0">The relative price point of the
                      specific location (scale: 0 to 4). The exact amount
                      indicated by a specific value will vary from region to
                      region. Price levels are interpreted as follows: <br>
                      0 Free <br>
                      1 Inexpensive <br>
                      2 Moderate <br>
                      3 Expensive <br>
                      4 Very Expensive
                    </p>
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item tab-button text-center mr-2 mb-1" role="presentation">
              <a class="nav-link d-flex align-items-center text-uppercase px-3" id="pills-profile-tab" data-bs-toggle="pill" href="#pills-tenant-breakdown" role="tab" aria-controls="pills-profile" aria-selected="false">Tenant Breakdown</a>
            </li>
          </ul>
        </div>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active text-center mx-5" id="pills-price-breakdown" role="tabpanel" aria-labelledby="pills-home-tab">
            <div class="p-4">
              <div class="chartjs-container">
                <canvas baseChart [data]="priceBreakdownData" [labels]="priceBreakdownDataLabels" [colors]="priceBreakdownDataColors" [options]="priceBreakdownOptions" chartType="doughnut">
                </canvas>
              </div>
            </div>
          </div>
          <div class="tab-pane fade  text-center mx-5" id="pills-tenant-breakdown" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div class="p-4">
              <div class="chartjs-container">
                <canvas baseChart [data]="tenantBreakdownData" [labels]="tenantBreakdownDataLabels" [colors]="tenantBreakdownDataColors" [options]="tenantBreakdownOptions" chartType="doughnut">
                </canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-6">
      <div class="white-card popularity-card p-3 {{ isShadow }}">
        <div class="d-flex mt-2">
          <ul class="nav nav-pills nav-pills-shadow mb-3" id="pills-tab" role="tablist">
            <li class="nav-item tab-button text-center mr-2 mb-1" role="presentation">
              <a class="nav-link active d-flex align-items-center text-uppercase px-3" id="pills-home-tab" data-bs-toggle="pill" href="#pills-popularity" role="tab" aria-controls="pills-home" aria-selected="true">Popularity</a>
            </li>
            <li class="nav-item tab-button text-center mr-2 mb-1" role="presentation">
              <a class="nav-link d-flex align-items-center px-3" id="pills-profile-tab" data-bs-toggle="pill" href="#pills-popular-times" role="tab" aria-controls="pills-profile" aria-selected="false">
                POPULAR TIMES
                <div class="dropdown">
                  <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                  </button>
                  <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                    <p class="info-text mb-0">The relative price point of the
                      Showing how busy a location typically is during different
                      times of the day. Figures shown are based on average
                      popularity over the last 24 months.
                    </p>
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item tab-button text-center mr-2 mb-1" role="presentation">
              <a class="nav-link d-flex align-items-center px-3" id="pills-profile-tab" data-bs-toggle="pill" href="#pills-digital-footfall" role="tab" aria-controls="pills-profile" aria-selected="false">
                DIGITAL FOOTFALL
                <div class="dropdown">
                  <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                  </button>
                  <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                    <p class="info-text mb-0">
                      The Digital Footfall Score is an intelligent ranking
                      method to estimate the amount of digital and physical
                      engagement for a brand in a location. <br>
                      This score is built from online reviews, social media
                      activity, physical visits, audience demographics and
                      other physical and digital measures.
                    </p>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="tab-content w-100" id="pills-tabContent">
          <div class="tab-pane fade show active text-center mx-5" id="pills-popularity" role="tabpanel" aria-labelledby="pills-home-tab">
            <div class="">
              <div class="row d-flex justify-content-center text-center px-4 pt-5 mt-5">
                <div class="col-md-12 col-lg-4 border-right my-1">
                  <div class="">
                    <p class="popularity-value">{{ milesSeperator(popularities["average_followers"]) }}</p>
                    <p class="popularity-label pb-0 mb-0">Average <br> Followers</p>
                  </div>
                </div>
                <div class="col-md-12 col-lg-4 border-right my-1">
                  <div class="">
                    <p class="popularity-value">{{ popularities["engagement_rate"] }}</p>
                    <div class="d-flex justify-content-center align-items-center">
                      <p class="popularity-label pb-0 mb-0">Engagement <br> Rate</p>
                      <div class="dropdown">
                        <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                          </svg>
                        </button>
                        <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                          <p class="info-text mb-0">Percentage of brand's
                            audience interacts with the brand on online
                            channels, including Twitter, Instagram, Facebook,
                            Four Squared, Google and Tripadvisor.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-4 my-1">
                  <div class="">
                    <p *ngIf="popularities['rating']" class="popularity-value">{{ popularities["rating"] }}/5</p>
                    <div class="d-flex justify-content-center align-items-center">
                      <p class="popularity-label pb-0 mb-0">Average <br> Customer Rating</p>
                      <div class="dropdown">
                        <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                          </svg>
                        </button>
                        <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                          <p class="info-text mb-0">Contains the place's rating,
                            based on aggregated consumer reviews from online
                            sources (scale: 1.0 to 5.0).
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade text-center mx-5" id="pills-popular-times" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div class="">
              <div class="d-flex justify-content-center my-2">
                <ul class="nav nav-pills nav-pills-popular-times mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item tab-button text-center mr-2 mb-1" role="presentation">
                    <a class="nav-link active px-4" id="pills-home-tab" data-bs-toggle="pill" href="#pills-hour" role="tab" aria-controls="pills-home" aria-selected="true">Hour</a>
                  </li>
                  <li class="nav-item tab-button text-center mr-2 mb-1" role="presentation">
                    <a class="nav-link px-4" id="pills-profile-tab" data-bs-toggle="pill" href="#pills-day" role="tab" aria-controls="pills-profile" aria-selected="false">Day</a>
                  </li>
                  <li class="nav-item tab-button text-center mr-2 mb-1" role="presentation">
                    <a class="nav-link px-4" id="pills-profile-tab" data-bs-toggle="pill" href="#pills-month" role="tab" aria-controls="pills-profile" aria-selected="false">Month</a>
                  </li>
                </ul>
              </div>
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active text-center mx-5" id="pills-hour" role="tabpanel" aria-labelledby="pills-home-tab">
                  <div class="p-2">
                    <div class="chartjs-container px-5 mt-4">
                      <canvas baseChart [data]="popularTimesHourData" [labels]="popularTimesHourDataLabels" [colors]="popularTimesHourDataColors" [options]="popularTimesHourOptions" chartType="bar">
                      </canvas>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade  text-center mx-5" id="pills-day" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div class="p-2">
                    <div class="chartjs-container px-5 mt-4">
                      <canvas baseChart [data]="popularTimesDayData" [labels]="popularTimesDayDataLabels" [colors]="popularTimesDayDataColors" [options]="popularTimesDayOptions" chartType="bar">
                      </canvas>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade  text-center mx-5" id="pills-month" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div class="p-2">
                    <div class="chartjs-container px-5 mt-4">
                      <canvas baseChart [data]="popularTimesMonthData" [labels]="popularTimesMonthDataLabels" [colors]="popularTimesMonthDataColors" [options]="popularTimesMonthOptions" chartType="bar">
                      </canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade show text-center mx-5" id="pills-digital-footfall" role="tabpanel" aria-labelledby="pills-home-tab">
            <div class="">
              <div class="row d-flex justify-content-center text-center px-4 pt-5 mt-5">
                <div class="col-md-12 col-lg-4 my-1">
                </div>
                <div class="col-md-12 col-lg-4 my-1">
                  <div class="">
                    <p class="popularity-value">{{ digital_footfall["digital_footfall_avg"] }}</p>
                    <p class="popularity-label pb-0 mb-0">Average <br> Digital Footfall</p>
                  </div>
                </div>
                <div class="col-md-12 col-lg-4 my-1">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section *ngIf="returnSectionSituation('Top Ten Businesses')" class="top-ten-business-section">
  <div class="row px-5 py-3">
    <div class="col-md-12 col-lg-12">
      <div class="white-card p-3 {{ isShadow }}">
        <div class="d-flex align-items-center">
          <p class="box-header text-uppercase pl-3 py-3">Top Ten Business</p>
          <div class="dropdown mb-3">
            <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
            </button>
            <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
              <p class="info-text mb-0">Most popular businesses in the catchment
                area. Popularity is measured by a scoring algorithm based on
                customer reviews and affinities.
              </p>
            </div>
          </div>
        </div>
        <div id="carouselExampleControls" class="carousel slide my-4" data-bs-ride="carousel">
          <div class="div d-flex justify-content-center">
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-bs-slide="prev" style="background-color: #fff !important;">
              <span class="carousel-control-prev-icon" aria-hidden="true" style="width: 1rem; height: 1rem;"></span>
            </a>
            <div class="carousel-inner mt-2 mx-5">
              <div class="carousel-item active">
                <div class="row d-flex justify-content-center align-items-center p-1">
                  <div *ngFor="let ttb of topTenBusinesses.slice(0,3)" class="col-md-12 col-lg-4" align="center">
                    <div class="card-flip">
                      <div class="card-front">
                        <div class="brand-card card">
                          <div class="card-img-top d-flex justify-content-center align-items-center">
                            <div *ngIf="ttb['radius_tenant_new_gen']" class="d-flex justify-content-center align-items-center label-div-left-red">
                              <p class="text-uppercase p-2 my-1">New Gen</p>
                            </div>
                            <div *ngIf="!ttb['radius_tenant_new_gen']" class="d-flex justify-content-center align-items-center label-div-left-black">
                              <p class="text-uppercase p-2 my-1">Traditional</p>
                            </div>
                            <div class="brand-logo-div">
                              <img *ngIf="ttb['image'] != '-'" src="{{ ttb['image'] }}" class="brand-logo">
                              <div *ngIf="ttb['image'] == '-'" class="logo-div d-flex justify-content-center align-items-center">
                                <p class="logo-name mb-0 pb-0"> {{ ttb['location'] }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="card-body mt-3">
                            <div class="fix-bottom">
                              <p class="middle-text text-align-left">{{ ttb["radius_tenant_category"]["name"] }}</p>
                              <div class="row d-flex align-items-bottom">
                                <div class="col-md-12 col-lg-7 text-align-left">
                                  <p class="large-text mt-3 mb-2">{{ ttb["location"] }}</p>
                                  <p *ngIf="ttb['distance_km'] == 0"class="small-text">- km</p>
                                  <p *ngIf="ttb['distance_km'] != 0"class="small-text">{{ ttb["distance_km"] }} km</p>
                                </div>
                                <div class="col-md-12 col-lg-5 text-align-right">
                                  <p class="xlarge-text mb-0">{{ ttb["local_popularity"] }}/100</p>
                                  <p class="small-text">Local Popularity</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-back">
                        <div *ngIf="ttb['radius_tenant_new_gen']" class="d-flex justify-content-center align-items-center label-div-right-red">
                          <p class="text-uppercase p-2 my-1">New Gen</p>
                        </div>
                        <div *ngIf="!ttb['radius_tenant_new_gen']" class="d-flex justify-content-center align-items-center label-div-right-black">
                          <p class="text-uppercase p-2 my-1">Traditional</p>
                        </div>
                        <div class="brand-card-back card p-3">
                          <div class="card-top text-align-left mt-4">
                            <p class="middle-text">{{ ttb["radius_tenant_category"]["name"] }}</p>
                            <p class="large-text mt-3 mb-2">{{ ttb["location"] }}</p>
                            <p *ngIf="ttb['distance_km'] == 0" class="small-text">- km</p>
                            <p *ngIf="ttb['distance_km'] != 0" class="small-text">{{ ttb["distance_km"] }} km</p>
                          </div>
                          <div class="card-body text-align-left">
                            <div class="row ml-1">
                              <div class="col-md-12 col-lg-7 text-left">
                                <p class="back-small-text">Size</p>
                              </div>
                              <div class="col-md-12 col-lg-5 text-left">
                                <p class="back-large-text">{{ milesSeperator(ttb["radius_tenant_size"]) }}</p>
                              </div>
                            </div>
                            <div class="row ml-1">
                              <div class="col-md-12 col-lg-7 text-left">
                                <p class="back-small-text">Sub-category</p>
                              </div>
                              <div class="col-md-12 col-lg-5 text-left">
                                <p class="back-large-text">{{ ttb["types"] }}</p>
                              </div>
                            </div>
                            <div class="row ml-1">
                              <div class="col-md-12 col-lg-7 text-left">
                                <div class="d-flex align-items-center">
                                  <p class="back-small-text">New Generation</p>
                                  <div class="dropdown mb-3">
                                    <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                      </svg>
                                    </button>
                                    <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                      <p class="info-text mb-0">A business is
                                        defined as "NGR" if it offers a service,
                                        experience or product that is recognised
                                        as being unique.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 col-lg-5 text-left">
                                <p *ngIf="ttb['radius_tenant_new_gen']" class="back-large-text">Yes</p>
                                <p *ngIf="!ttb['radius_tenant_new_gen']" class="back-large-text">No</p>
                              </div>
                            </div>
                            <div class="row ml-1">
                              <div class="col-md-12 col-lg-7 text-left">
                                <div class="d-flex align-items-center">
                                  <p class="back-small-text">Price Point</p>
                                  <div class="dropdown mb-3">
                                    <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                      </svg>
                                    </button>
                                    <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                      <p class="info-text mb-0">The relative
                                        price point of the specific location
                                        (scale: 0 to 4). The exact amount
                                        indicated by a specific value will
                                        vary from region to region. Price
                                        levels are interpreted as follows: <br>
                                        0 Free <br>
                                        1 Inexpensive <br>
                                        2 Moderate <br>
                                        3 Expensive <br>
                                        4 Very Expensive <br>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 col-lg-5 text-left">
                                <p class="back-large-text">{{ convertPriceLevel(ttb["price_level"]) }}</p>
                              </div>
                            </div>
                            <!-- <div class="row ml-1">
                              <div class="col-md-12 col-lg-7 text-left">
                                <p class="back-small-text">Brand Affinity</p>
                              </div>
                              <div class="col-md-12 col-lg-5 text-left">
                                <p class="back-large-text">{{ ttb["brand_affinity"] }}</p>
                              </div>
                            </div> -->
                            <div class="row ml-1">
                              <div class="col-md-12 col-lg-7 text-left">
                                <div class="d-flex align-items-center">
                                  <p class="back-small-text">Local Popularity</p>
                                  <div class="dropdown mb-3">
                                    <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                      </svg>
                                    </button>
                                    <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                      <p class="info-text mb-0">A comparative
                                        score which indicates the relative
                                        popularity of the brand/business
                                        within the specified catchment area.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 col-lg-5 text-left">
                                <p class="back-large-text">{{ ttb["local_popularity"] }}%</p>
                              </div>
                            </div>
                            <div class="row ml-1">
                              <div class="col-md-12 col-lg-7 text-left">
                                <p class="back-small-text">Number of Followers</p>
                              </div>
                              <div class="col-md-12 col-lg-5 text-left">
                                <p class="back-large-text">{{ ttb["number_of_followers"] }}</p>
                              </div>
                            </div>
                            <div class="row ml-1">
                              <div class="col-md-12 col-lg-7 text-left">
                                <div class="d-flex align-items-center">
                                  <p class="back-small-text">Engagement Rate</p>
                                  <div class="dropdown mb-3">
                                    <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                      </svg>
                                    </button>
                                    <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                      <p class="info-text mb-0">Percentage of
                                        brand's audience interacts with the
                                        brand on online channels, including
                                        Twitter, Instagram, Facebook, Four
                                        Squared, Google and Tripadvisor.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 col-lg-5 text-left">
                                <p *ngIf="ttb['engagement_rate'] != '-'" class="back-large-text">{{ ttb["engagement_rate"] }}%</p>
                                <p *ngIf="ttb['engagement_rate'] == '-'" class="back-large-text">-</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngFor="let item of topTenBusinessesSlider" class="carousel-item">
                <div class="row d-flex align-items-center p-1">
                  <div *ngFor="let ttb of topTenBusinesses.slice(item, item+3)" class="col-md-12 col-lg-4" align="center">
                    <div class="card-flip">
                      <div class="card-front">
                        <div class="brand-card card">
                          <div class="card-img-top d-flex justify-content-center align-items-center">
                            <div *ngIf="ttb['radius_tenant_new_gen']" class="d-flex justify-content-center align-items-center label-div-left-red">
                              <p class="text-uppercase p-2 my-1">New Gen</p>
                            </div>
                            <div *ngIf="!ttb['radius_tenant_new_gen']" class="d-flex justify-content-center align-items-center label-div-left-black">
                              <p class="text-uppercase p-2 my-1">Traditional</p>
                            </div>
                            <div class="brand-logo-div">
                              <img *ngIf="ttb['image'] != '-'" src="{{ ttb['image'] }}" class="brand-logo">
                              <div *ngIf="ttb['image'] == '-'" class="logo-div d-flex justify-content-center align-items-center">
                                <p class="logo-name mb-0 pb-0"> {{ ttb['location'] }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="card-body mt-3">
                            <div class="fix-bottom">
                              <p class="middle-text text-align-left">{{ ttb["radius_tenant_category"]["name"] }}</p>
                              <div class="row d-flex align-items-bottom">
                                <div class="col-md-12 col-lg-7 text-align-left">
                                  <p class="large-text mt-3 mb-2">{{ ttb["location"] }}</p>
                                  <p class="small-text">{{ ttb["distance_km"] }} km</p>
                                </div>
                                <div class="col-md-12 col-lg-5 text-align-right">
                                  <p class="xlarge-text mb-0">{{ ttb["local_popularity"] }}/100</p>
                                  <p class="small-text">Local Popularity</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-back">
                        <div *ngIf="ttb['radius_tenant_new_gen']" class="d-flex justify-content-center align-items-center label-div-right-red">
                          <p class="text-uppercase p-2 my-1">New Gen</p>
                        </div>
                        <div *ngIf="!ttb['radius_tenant_new_gen']" class="d-flex justify-content-center align-items-center label-div-right-black">
                          <p class="text-uppercase p-2 my-1">Traditional</p>
                        </div>
                        <div class="brand-card-back card p-3">
                          <div class="card-top text-align-left mt-4">
                            <p class="middle-text">{{ ttb["radius_tenant_category"]["name"] }}</p>
                            <p class="large-text mt-3 mb-2">{{ ttb["location"] }}</p>
                            <p *ngIf="ttb['distance_km'] == 0" class="small-text">- km</p>
                            <p *ngIf="ttb['distance_km'] != 0" class="small-text">{{ ttb["distance_km"] }} km</p>
                          </div>
                          <div class="card-body text-align-left">
                            <div class="row ml-1">
                              <div class="col-md-12 col-lg-7 text-left">
                                <p class="back-small-text">Size</p>
                              </div>
                              <div class="col-md-12 col-lg-5 text-left">
                                <p class="back-large-text">{{ milesSeperator(ttb["radius_tenant_size"]) }}</p>
                              </div>
                            </div>
                            <div class="row ml-1">
                              <div class="col-md-12 col-lg-7 text-left">
                                <p class="back-small-text">Sub-category</p>
                              </div>
                              <div class="col-md-12 col-lg-5 text-left">
                                <p class="back-large-text">{{ ttb["types"] }}</p>
                              </div>
                            </div>
                            <div class="row ml-1">
                              <div class="col-md-12 col-lg-7 text-left">
                                <div class="d-flex align-items-center">
                                  <p class="back-small-text">New Generation</p>
                                  <div class="dropdown mb-3">
                                    <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                      </svg>
                                    </button>
                                    <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                      <p class="info-text mb-0">A business is
                                        defined as "NGR" if it offers a service,
                                        experience or product that is recognised
                                        as being unique.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 col-lg-5 text-left">
                                <p *ngIf="ttb['radius_tenant_new_gen']" class="back-large-text">Yes</p>
                                <p *ngIf="!ttb['radius_tenant_new_gen']" class="back-large-text">No</p>
                              </div>
                            </div>
                            <div class="row ml-1">
                              <div class="col-md-12 col-lg-7 text-left">
                                <div class="d-flex align-items-center">
                                  <p class="back-small-text">Price Point</p>
                                  <div class="dropdown mb-3">
                                    <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                      </svg>
                                    </button>
                                    <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                      <p class="info-text mb-0">The relative
                                        price point of the specific location
                                        (scale: 0 to 4). The exact amount
                                        indicated by a specific value will
                                        vary from region to region. Price
                                        levels are interpreted as follows: <br>
                                        0 Free <br>
                                        1 Inexpensive <br>
                                        2 Moderate <br>
                                        3 Expensive <br>
                                        4 Very Expensive <br>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 col-lg-5 text-left">
                                <p class="back-large-text">{{ convertPriceLevel(ttb["price_level"]) }}</p>
                              </div>
                            </div>
                            <!-- <div class="row ml-1">
                              <div class="col-md-12 col-lg-7 text-left">
                                <p class="back-small-text">Brand Affinity</p>
                              </div>
                              <div class="col-md-12 col-lg-5 text-left">
                                <p class="back-large-text">{{ ttb["brand_affinity"] }}</p>
                              </div>
                            </div> -->
                            <div class="row ml-1">
                              <div class="col-md-12 col-lg-7 text-left">
                                <div class="d-flex align-items-center">
                                  <p class="back-small-text">Local Popularity</p>
                                  <div class="dropdown mb-3">
                                    <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                      </svg>
                                    </button>
                                    <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                      <p class="info-text mb-0">A comparative
                                        score which indicates the relative
                                        popularity of the brand/business
                                        within the specified catchment area.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 col-lg-5 text-left">
                                <p class="back-large-text">{{ ttb["local_popularity"] }}%</p>
                              </div>
                            </div>
                            <div class="row ml-1">
                              <div class="col-md-12 col-lg-7 text-left">
                                <p class="back-small-text">Number of Followers</p>
                              </div>
                              <div class="col-md-12 col-lg-5 text-left">
                                <p class="back-large-text">{{ ttb["number_of_followers"] }}</p>
                              </div>
                            </div>
                            <div class="row ml-1">
                              <div class="col-md-12 col-lg-7 text-left">
                                <div class="d-flex align-items-center">
                                  <p class="back-small-text">Engagement Rate</p>
                                  <div class="dropdown mb-3">
                                    <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                      </svg>
                                    </button>
                                    <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                      <p class="info-text mb-0">Percentage of
                                        brand's audience interacts with the
                                        brand on online channels, including
                                        Twitter, Instagram, Facebook, Four
                                        Squared, Google and Tripadvisor.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 col-lg-5 text-left">
                                <p *ngIf="ttb['engagement_rate'] != '-'" class="back-large-text">{{ ttb["engagement_rate"] }}%</p>
                                <p *ngIf="ttb['engagement_rate'] == '-'" class="back-large-text">-</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-bs-slide="next" style="background-color: #fff !important;">
              <span class="carousel-control-next-icon" aria-hidden="true" style="width: 1rem; height: 1rem;"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section *ngIf="returnSectionSituation('Audience') && !isWidget" class="audience-section">
  <div class="row px-5 py-3">
    <div class="col-md-12 col-lg-12">
      <div class="white-card p-3 {{ isShadow }}">
        <div class="d-flex align-items-center">
          <p class="box-header text-uppercase pl-3 py-3">Audience</p>
          <div class="dropdown mb-3">
            <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
            </button>
            <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
              <p class="info-text mb-0">Radius identifies consumers within the
                local population who represent the majority of discretionary
                spend. Typically, this is 8-15% of the wider catchment
                population. These active consumers engage with the community
                on-line and shop from the local businesses the most frequently.
              </p>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-md-12 col-lg-4 px-5 mb-4">
            <p class="box-header text-uppercase p-3">Age</p>
            <div class="chartjs-container m-1">
              <canvas baseChart [data]="ageData" [labels]="ageDataLabels" [colors]="ageDataColors" [options]="ageOptions" chartType="horizontalBar">
              </canvas>
            </div>
          </div>
          <div class="col-md-12 col-lg-4 px-5">
            <p class="box-header text-uppercase p-3">Personal Income</p>
            <div class="chartjs-container m-1">
              <canvas baseChart [data]="personalIncomeData" [labels]="personalIncomeDataLabels" [colors]="personalIncomeDataColors" [options]="personalIncomeOptions" chartType="horizontalBar">
              </canvas>
            </div>
          </div>
          <div class="col-md-12 col-lg-4 px-5">
            <p class="box-header text-uppercase p-3">Gender</p>
            <div class="chartjs-container m-1">
              <canvas baseChart [data]="genderData" [labels]="genderDataLabels" [colors]="genderDataColors" [options]="genderOptions" chartType="horizontalBar">
              </canvas>
            </div>
          </div>
        </div>
        <div class="row px-3 mt-5">
          <div class="d-flex align-items-center px-5 mb-4">
            <div class="dropdown brand-category-dropdown">
              <button class="btn btn-light light-btn px-4 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                Category <i class="fa fa-chevron-down"></i><span *ngIf="selectedBrandCategoryCount" class="bold-text"> ({{ selectedBrandCategoryCount }})</span>
              </button>
              <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
                <form [formGroup]="brandCategoryForm" (ngSubmit)="applyBrandCategoryFilter()">
                  <div class="scroll-div-filter">
                    <li class="my-2" *ngFor="let category of brandCategories">
                      <a class="dropdown-item">
                        <div class="form-check">
                          <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="category['id']" (change)="applyBrandCategoryFilter()" id="flexCheckDefault">
                          <label class="form-check-label" for="flexCheckDefault">
                            {{ category['name'] }}
                          </label>
                        </div>
                      </a>
                    </li>
                  </div>
                </form>
              </ul>
            </div>
            <div class="dropdown">
              <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
              </button>
              <div class="dropdown-menu info-dropdown-menu p-2" aria-labelledby="dropdownMenuLink">
                <p class="info-text mb-0">Category filter impacts on:</p>
                <ul style="padding-left: 1.5rem;">
                  <li class="info-text">Top Brands</li>
                  <li class="info-text">Likes & Interests</li>
                  <li class="info-text">Top Celebrities</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-6 px-5 mb-4">
            <div class="d-flex align-items-center">
              <p class="box-header text-uppercase py-3 pl-3">Occupation</p>
              <div class="dropdown mb-3">
                <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                  </svg>
                </button>
                <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                  <p class="info-text mb-0">Occupation results are not affected by Category filter</p>
                </div>
              </div>
            </div>
            <div *ngIf="!isScrollDivOccupation">
              <button *ngFor="let item of occupations;" type="button" class="btn btn-light occupation-btn p-2 mr-4 ml-0" data-toggle="tooltip" data-placement="top" title="{{ item['value'] }}">{{ item["name"] }}</button>
              <button *ngIf="isMoreOccupation" type="button" class="btn btn-light more-btn p-2 mb-2" (click)="moreOccupation()"><u>More</u></button>
            </div>
            <div *ngIf="isScrollDivOccupation"  class="scroll-div">
              <button *ngFor="let item of occupations;" type="button" class="btn btn-light occupation-btn p-2 mr-4 ml-0 mb-2" data-toggle="tooltip" data-placement="top" title="{{ item['value'] }}">{{ item["name"] }}</button>
              <button *ngIf="isMoreOccupation" type="button" class="btn btn-light more-btn p-2 mb-2" (click)="moreOccupation()"><u>More</u></button>
            </div>
          </div>
          <div class="col-md-12 col-lg-6 px-5 mb-4">
            <p class="box-header text-uppercase py-3 pl-3">Top Brands</p>
            <div *ngIf="!isScrollDivTopBrands">
              <button *ngFor="let item of top_brands;" type="button" class="btn btn-light occupation-btn p-2 mr-4 ml-0" data-toggle="tooltip" data-placement="top" title="{{ item['value'] }}">{{ item["name"] }}</button>
              <button *ngIf="isMoreTopBrands" type="button" class="btn btn-light more-btn p-2 mb-2" (click)="moreTopBrands()"><u>More</u></button>
            </div>
            <div *ngIf="isScrollDivTopBrands"  class="scroll-div">
              <button *ngFor="let item of top_brands;" type="button" class="btn btn-light occupation-btn p-2 mr-4 ml-0 mb-2" data-toggle="tooltip" data-placement="top" title="{{ item['value'] }}">{{ item["name"] }}</button>
              <button *ngIf="isMoreTopBrands" type="button" class="btn btn-light more-btn p-2 mb-2" (click)="moreTopBrands()"><u>More</u></button>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-md-12 col-lg-6 px-5 mb-4">
            <p class="box-header text-uppercase py-3 pl-3 mt-3">Likes & Interests</p>
            <div *ngIf="!isScrollDivLike">
              <button *ngFor="let item of like_and_interesets;" type="button" class="btn btn-light occupation-btn p-2 mr-4 ml-0" data-toggle="tooltip" data-placement="top" title="{{ item['value'] }}">{{ item["name"] }}</button>
              <button *ngIf="isMoreLike" type="button" class="btn btn-light more-btn p-2 mb-2" (click)="moreLike()"><u>More</u></button>
            </div>
            <div *ngIf="isScrollDivLike"  class="scroll-div">
              <button *ngFor="let item of like_and_interesets;" type="button" class="btn btn-light occupation-btn p-2 mr-4 ml-0 mb-2" data-toggle="tooltip" data-placement="top" title="{{ item['value'] }}">{{ item["name"] }}</button>
              <button *ngIf="isMoreLike" type="button" class="btn btn-light more-btn p-2 mb-2" (click)="moreLike()"><u>More</u></button>
            </div>
          </div>
          <div class="col-md-12 col-lg-6 px-5 mb-4">
            <p class="box-header text-uppercase py-3 pl-3 mt-3">Top Celebrities</p>
            <div *ngIf="!isScrollDivCelebrity">
              <button *ngFor="let item of mostly_liked_celebrities;" type="button" class="btn btn-light occupation-btn p-2 mr-4 ml-0" data-toggle="tooltip" data-placement="top" title="{{ item['value'] }}">{{ item["name"] }}</button>
              <button *ngIf="isMoreCelebrity" type="button" class="btn btn-light more-btn p-2 mb-2" (click)="moreCelebrity()"><u>More</u></button>
            </div>
            <div *ngIf="isScrollDivCelebrity"  class="scroll-div">
              <button *ngFor="let item of mostly_liked_celebrities;" type="button" class="btn btn-light occupation-btn p-2 mr-4 ml-0 mb-2" data-toggle="tooltip" data-placement="top" title="{{ item['value'] }}">{{ item["name"] }}</button>
              <button *ngIf="isMoreCelebrity" type="button" class="btn btn-light more-btn p-2 mb-2" (click)="moreCelebrity()"><u>More</u></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section *ngIf="returnSectionSituation('Persona')" class="personas-section pb-5">
  <div class="row px-5 py-3">
    <div class="col-md-12 col-lg-12">
      <div class="white-card p-3 {{ isShadow }}">
        <p class="box-header text-uppercase p-3">Personas (Coming Soon)</p>
        <div id="carouselExampleControls" class="carousel slide my-4" data-bs-ride="carousel">
          <div class="div d-flex justify-content-center">
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-bs-slide="prev" style="background-color: #fff !important;">
              <span class="carousel-control-prev-icon" aria-hidden="true" style="width: 1rem; height: 1rem;"></span>
            </a>
            <div class="carousel-inner mt-2 mx-5">
              <div class="carousel-item active">
                <div class="row d-flex justify-content-center align-items-center p-1">
                  <div *ngFor="let persona of personas.slice(0,3)" class="col-md-12 col-lg-4" align="center">
                    <div class="persona-card card">
                      <div class="row p-1">
                        <div class="col-md-12 col-lg-4">
                          <img src="../../assets/img/person.png" class="person-logo">
                        </div>
                        <div class="col-md-12 col-lg-8 text-align-left">
                          <p class="large-text mb-0">{{ persona["name"] }}</p>
                          <p class="xlarge-text mb-0">{{ persona["profile_percentage"] }}%</p>
                          <p class="small-text">Profile Percentage</p>
                        </div>
                      </div>
                      <div class="card-body mt-2">
                        <div class="row ml-1">
                          <div class="col-md-12 col-lg-3 text-align-left">
                            <p class="back-small-text">Age</p>
                          </div>
                          <div class="col-md-12 col-lg-9 text-align-left">
                            <p class="back-large-text">{{ persona["age"] }}</p>
                          </div>
                        </div>
                        <div class="row ml-1">
                          <div class="col-md-12 col-lg-3 text-align-left">
                            <p class="back-small-text">Gender</p>
                          </div>
                          <div class="col-md-12 col-lg-9 text-align-left">
                            <p class="back-large-text">{{ persona["gender"] }}</p>
                          </div>
                        </div>
                        <div class="row ml-1">
                          <div class="col-md-12 col-lg-3 text-align-left">
                            <p class="back-small-text">Income</p>
                          </div>
                          <div class="col-md-12 col-lg-9 text-align-left">
                            <p class="back-large-text">{{ persona["income"] }}</p>
                          </div>
                        </div>
                        <div class="row ml-1">
                          <div class="col-md-12 col-lg-3 text-align-left">
                            <p class="back-small-text">Occupation</p>
                          </div>
                          <div class="col-md-12 col-lg-9 text-align-left">
                            <p class="back-large-text">{{ persona["occupation"] }}</p>
                          </div>
                        </div>
                        <div class="row ml-1">
                          <div class="col-md-12 col-lg-3 text-align-left">
                            <p class="back-small-text">Likes</p>
                          </div>
                          <div class="col-md-12 col-lg-9 text-align-left">
                            <div *ngFor="let like of persona['likes']" class="d-flex">
                              <p class="back-large-text">#{{ like }}</p>
                            </div>
                          </div>
                        </div>
                        <div class="row ml-1">
                          <div class="col-md-12 col-lg-3 text-align-left">
                            <p class="back-small-text">Interests</p>
                          </div>
                          <div class="col-md-12 col-lg-9 text-align-left">
                            <div *ngFor="let interest of persona['interests']" class="d-flex">
                              <p class="back-large-text">#{{ interest }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-bs-slide="next" style="background-color: #fff !important;">
              <span class="carousel-control-next-icon" aria-hidden="true" style="width: 1rem; height: 1rem;"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
