import { Component, OnInit, ViewChild } from '@angular/core';

import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';

import { DataService } from 'src/app/services/data.service';

import { FilterPipe } from "../../../pipes/filter.pipe";

import { Options, ChangeContext } from '@angular-slider/ngx-slider';
import { ITreeOptions } from '@circlon/angular-tree-component';

@Component({
  selector: 'app-engagement-activity',
  templateUrl: './engagement-activity.component.html',
  styleUrls: ['./engagement-activity.component.css']
})
export class EngagementActivityComponent implements OnInit {
  searchText = "";

  selectedProject = {};
  selectedProjectId: Number;

  minFollowerValue: number = 50000;
  maxFollowerValue: number = 600000000;
  followerOptions: Options = {
    floor: 0,
    ceil: 600000000,
    // step: 100
  };

  minEngagementValue: number = 50000;
  maxEngagementValue: number = 100000;
  engagementOptions: Options = {
    floor: 0,
    ceil: 200000,
    // step: 100
  };

  filterPostData = {
    "limit": 6,
    "offset": 0
  };

  campaigns = [];
  sub_categories = [];
  subCategoriesTreeData = [];
  subCategoriesTreeDataMapping = {};
  selectedSubCategories = [];
  isEngagementCategoriesDataReady: boolean = false;
  isShowMore: boolean = false;

  selectedDistrictCount = 0;
  selectedTypeCount = 0;
  selectedTierCount = 0;
  selectedFollewerNumber = "";
  selectedEngagementRate = "";
  selectedSubCategoryCount = 0;
  selectedEventTypeCount = 0;

  suggested_engagement_activity_ready: boolean = false;
  total_suggested_engagement_activities_count = 0;
  all_suggested_engagement_activities = [];
  suggested_engagement_activities = [];
  rowLength = 0;

  districts = [];
  types = [];
  eventTypes = [];
  tiers = [];
  selectedType = {};

  districtForm: FormGroup;
  typeForm: FormGroup;
  tierForm: FormGroup;
  eventTypeForm: FormGroup;

  treeOptions: ITreeOptions = {
    useCheckbox: true
  };

  constructor(
    public fb: FormBuilder,
    private dataservice: DataService,
    private router: Router,
  ) { }

  @ViewChild('tree') tree;

  ngOnInit(): void {
    let current_route = this.router.url
    let name = current_route.split("/")[1]
    this.dataservice.getProject(name).then((project: any)  => {
      this.selectedProject = project;
      this.selectedProjectId = project["id"]
      this.getEngagementActivities(this.selectedProjectId, this.filterPostData, false)
    })

    this.getDistricts()
    this.getTypes()
    this.getEventTypes()
    this.getTiers()
    this.getEngagementCategories(null)

    // generate form group data
    this.districtForm = this.fb.group({});
    this.typeForm = this.fb.group({});
    this.tierForm = this.fb.group({});
    this.eventTypeForm = this.fb.group({});

  }

  getDistricts() {
    this.dataservice.getDistricts().then((value: any[]) => {
      this.districts = value;
      // create dynamic district form
      let districtGroup = {};
      this.districts.forEach(x=>{
        districtGroup[x["id"]]=new FormControl('');
      })
      this.districtForm = this.fb.group(districtGroup);
    })
  }

  getTypes() {
    this.dataservice.getTypes().then((value: any[]) => {
      this.types = value;
      // create dynamic type form
      let typeGroup = {};
      this.types.forEach(x=>{
        typeGroup[x["name"].toLowerCase()]=new FormControl('');
      })
      this.typeForm = this.fb.group(typeGroup);
    })
  }

  getEventTypes() {
    this.dataservice.getEventTypes().then((value: any[]) => {
      this.eventTypes = value;
      // create dynamic event type form
      let eventTypeGroup = {};
      this.eventTypes.forEach(x=>{
        eventTypeGroup[x["id"]]=new FormControl('');
      })
      this.eventTypeForm = this.fb.group(eventTypeGroup);
    })
  }

  getTiers() {
    this.dataservice.getTiers().then((value: any[]) => {
      this.tiers = value;
      // create dynamic tierGroup form
      let tierGroup = {};
      this.tiers.forEach(x=>{
        tierGroup[x["id"]]=new FormControl('');
      })
      this.tierForm = this.fb.group(tierGroup);
    })
  }

  searchBrand() {
    this.suggested_engagement_activity_ready = false;
    // delete this.filterPostData["limit"];
    delete this.filterPostData["offset"];

    if (this.searchText != "") {
      this.filterPostData["search"] = this.searchText;
      this.getEngagementActivities(this.selectedProjectId, this.filterPostData, false);
    } else {
      delete this.filterPostData["search"];
      this.getEngagementActivities(this.selectedProjectId, this.filterPostData, false)
    }
  }

  getEngagementActivities(project_id, data, showMore) {
    if (showMore) {
      this.isShowMore = true;
      this.dataservice.getEngagementActivities(project_id, data).then((value: any[]) => {
        this.all_suggested_engagement_activities = this.all_suggested_engagement_activities.concat(value["results"])
        this.total_suggested_engagement_activities_count = value["count"]
        this.suggested_engagement_activities = this.all_suggested_engagement_activities.slice(0, this.filterPostData["offset"] + 6)
        this.suggested_engagement_activity_ready = true;
        this.isShowMore = false;
        this.rowLength = this.calculateRow()
      })
    } else {
      this.isShowMore = false;
      data["offset"] = 0;
      this.filterPostData["offset"] = 0;
      this.all_suggested_engagement_activities = [];
      this.suggested_engagement_activities = [];
      this.dataservice.getEngagementActivities(project_id, data).then((value: any[]) => {
        this.all_suggested_engagement_activities = value["results"]
        this.total_suggested_engagement_activities_count = value["count"]
        this.suggested_engagement_activities = this.all_suggested_engagement_activities.slice(0, 6)
        this.suggested_engagement_activity_ready = true;
        this.rowLength = this.calculateRow()
      })
    }
  }

  getEngagementCategories(types) {
    this.sub_categories = [];
    this.dataservice.getEngagementCategories(types).then((value: any[]) => {
      this.sub_categories = value;
      this.filterPostData["categories"] = [];
      this.filterPostData["sub-categories"] = [];
      this.selectedSubCategories = [];
      this.selectedSubCategoryCount = 0;
      this.subCategoriesTreeData = [];
      this.subCategoriesTreeData = this.categoryDataMapper(this.sub_categories, [])
      this.isEngagementCategoriesDataReady = true;
    })
    // this.getEngagementActivities(this.selectedProjectId, this.filterPostData)
  }

  categoryDataMapper(sub_categories, category_list) {
    let newCategoryData = [];
    let categories = [];
    for (let sub of sub_categories) {
      if (category_list.length) {
        if ((categories.indexOf(sub["category"]["name"]) < 0) && (category_list.indexOf(sub["category"]["id"]) > -1))  {
          categories.push(sub["category"]["name"])
          newCategoryData.push({
            // id: sub["category"]["id"],
            name: sub["category"]["name"],
            children: []
          })
        }
      } else {
        if (categories.indexOf(sub["category"]["name"]) < 0) {
          categories.push(sub["category"]["name"])
          newCategoryData.push({
            // id: sub["category"]["id"],
            name: sub["category"]["name"],
            children: []
          })
        }
      }
    }
    for (let sub of sub_categories) {
      for (let root of newCategoryData) {
        if (sub["category"]["name"] == root["name"]) {
          root["children"].push({
            // id: sub["id"],
            name: sub["name"],
          })
        }
      }
    }
    return newCategoryData
  }

  treeAndSubCategoryMapper(treeData) {
    for (let category of treeData) {
      for (let sub of category["children"]) {
        for (let subcat of this.sub_categories) {
          if (sub["name"] == subcat["name"]) {
            this.subCategoriesTreeDataMapping[sub["id"]] = subcat["id"]
          }
        }
      }
    }
  }

  checkCategoryOrSubCategory(categories) {
    let deleted_cat = []
    for (let category of categories) {
      for (let sub of this.sub_categories) {
        let subs = []
        if (sub["category"]["id"] == category) {
          subs.push(sub["id"])
          if (this.selectedSubCategories.indexOf(sub["id"]) == -1) {
            deleted_cat.push(category)
          }
        }
      }
    }
    deleted_cat = [...new Set(deleted_cat)]
    this.filterPostData["categories"] = categories.filter(item => deleted_cat.indexOf(item) < 0);

    let deleted_sub = []
    for (let sub of this.selectedSubCategories) {
      for (let subCat of this.sub_categories) {
        if (sub == subCat["id"]) {
          for (let cat of this.filterPostData["categories"]) {
            if (subCat["category"]["id"] == cat) {
              deleted_sub.push(sub)
            }
          }
        }
      }
    }
    deleted_sub = [...new Set(deleted_sub)]
    this.filterPostData["sub-categories"] = this.filterPostData["sub-categories"].filter(item => deleted_sub.indexOf(item) < 0);
  }

  applyFilters(type) {
    this.suggested_engagement_activity_ready = false;
    this.suggested_engagement_activities = [];
    if (type == "district") {
      let postData = []
      for (let i in this.districtForm.value) {
        if (this.districtForm.value[i] == true) {
          postData.push(i)
        }
      }
      this.filterPostData["district"] = postData
      this.districtSearchFilter()
    }
    if (type == "type") {
      let postData = []
      for (let i in this.typeForm.value) {
        if (this.typeForm.value[i] == true) {
          postData.push(i)
        }
      }
      this.filterPostData["type"] = postData
      this.filterPostData["categories"] = [];
      this.filterPostData["sub-categories"] = [];
      this.selectedSubCategories = [];
      if (!this.selectedProject['district_filter']) {
        this.resetActiveNodes()
      }
      this.filterPostData["type"] = postData
      if (this.filterPostData["type"].length != 0) {
        if (!this.selectedProject['district_filter']) {
            this.getEngagementCategories(this.filterPostData["type"].toString())
        } else {
          this.getEngagementCategories(null)
        }
      }
      this.typeSearchFilter()
    }
    else if (type == "category") {
      let subCatPostData = this.selectedSubCategories
      this.filterPostData["sub-categories"] = subCatPostData
      let catPostData = []
      for (let sub_id of this.selectedSubCategories) {
        for (let sub of this.sub_categories) {
          if (sub_id == sub["id"]) {
            if (catPostData.indexOf(sub["category"]["id"]) == -1) {
              catPostData.push(sub["category"]["id"])
            }
          }
        }
      }
      this.checkCategoryOrSubCategory(catPostData)
    }
    else if (type == "tier") {
      let postData = []
      for (let i in this.tierForm.value) {
        if (this.tierForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterPostData["tier"] = postData
      this.tierSearchFilter()
    }
    else if (type == "follower-number") {
      let postData = [this.minFollowerValue, this.maxFollowerValue]
      this.filterPostData["follower-number"] = postData
      this.followerNumberSearchFilter()
    }
    else if (type == "engagement-rate") {
      let postData = [this.minEngagementValue, this.maxEngagementValue]
      this.filterPostData["engagement-rate"] = postData
      this.engagementRateSearchFilter()
    }
    else if (type == "event-type") {
      let postData = []
      for (let i in this.eventTypeForm.value) {
        if (this.eventTypeForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterPostData["event-type"] = postData
      this.eventTypeSearchFilter()
    }
    this.getEngagementActivities(this.selectedProjectId, this.filterPostData, false)
  }

  showMore() {
    let len = this.suggested_engagement_activities.length
    this.suggested_engagement_activities = this.all_suggested_engagement_activities.slice(0, len+6)
    this.filterPostData["offset"] = len;
    this.getEngagementActivities(this.selectedProjectId, this.filterPostData, true)
  }

  districtSearchFilter() {
    this.selectedDistrictCount = 0;
    let form_values = this.districtForm.value;
    for (let item of this.districts) {
      if (form_values[item["id"]] == true) {
        this.selectedDistrictCount = this.selectedDistrictCount + 1
      }
    }
  }

  typeSearchFilter() {
    this.selectedTypeCount = 0;
    let form_values = this.typeForm.value;
    for (let item of this.types) {
      if (form_values[item["name"].toLowerCase()] == true) {
        this.selectedTypeCount = this.selectedTypeCount + 1
      }
    }
    this.selectedSubCategoryCount = 0;
    this.selectedSubCategories = [];
    this.subCategoriesTreeData = [];
    this.subCategoriesTreeData = this.categoryDataMapper(this.sub_categories, [])
    this.filterPostData["categories"] = [];
    this.filterPostData["sub-categories"] = [];
  }

  tierSearchFilter() {
    this.selectedTierCount = 0;
    let form_values = this.tierForm.value;
    for (let item of this.tiers) {
      if (form_values[item["id"]] == true) {
        this.selectedTierCount = this.selectedTierCount + 1
      }
    }
  }

  followerNumberSearchFilter() {
    // this.selectedFollewerNumber = "";
    // let form_values = this.tierForm.value;
    // for (let item of this.tiers) {
    //   if (form_values[item["id"]] == true) {
    //     this.selectedFollewerNumber = this.selectedTierCount + 1
    //   }
    // }
  }

  engagementRateSearchFilter() {
    // this.selectedFollewerNumber = "";
    // let form_values = this.tierForm.value;
    // for (let item of this.tiers) {
    //   if (form_values[item["id"]] == true) {
    //     this.selectedFollewerNumber = this.selectedTierCount + 1
    //   }
    // }
  }

  eventTypeSearchFilter() {
    this.selectedEventTypeCount = 0;
    let form_values = this.eventTypeForm.value;
    for (let item of this.eventTypes) {
      if (form_values[item["id"]] == true) {
        this.selectedEventTypeCount = this.selectedEventTypeCount + 1
      }
    }
  }

  onMinFollowerValueChange(value) {
    this.minFollowerValue = value;
  }

  onMaxFollowerValueChange(value) {
    this.maxFollowerValue = value;
  }

  onFollowerNumberValueChange(changeContext: ChangeContext) {
    this.minFollowerValue = changeContext.value;
    this.maxFollowerValue = changeContext.highValue;
    this.selectedFollewerNumber = this.intToString(this.minFollowerValue) + " - " + this.intToString(this.maxFollowerValue)

    let followerDropdownMenu: HTMLElement = document.getElementsByClassName("follower-dropdown-menu")[0] as HTMLElement
    followerDropdownMenu.className = "dropdown-menu follower-dropdown-menu px-3 py-4 my-2 tree-dropdown";

    let dropdownMenuLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[3] as HTMLElement
    dropdownMenuLink.setAttribute('aria-expanded', 'true')
    setTimeout(() => {
      dropdownMenuLink.setAttribute('aria-expanded', 'true')
    }, 10);
  }

  onMinEngagementValueChange(value) {
    this.minEngagementValue = value;
  }

  onMaxEngagementValueChange(value) {
    this.maxEngagementValue = value;
  }

  onEngagementNumberValueChange(changeContext: ChangeContext) {
    this.minEngagementValue = changeContext.value;
    this.maxEngagementValue = changeContext.highValue;
    this.selectedEngagementRate = this.intToString(this.minEngagementValue) + " - " + this.intToString(this.maxEngagementValue)

    let engagementDropdownMenu: HTMLElement = document.getElementsByClassName("engagement-dropdown-menu")[0] as HTMLElement
    engagementDropdownMenu.className = "dropdown-menu engagement-dropdown-menu px-3 py-4 my-2 tree-dropdown";

    let dropdownMenuLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[4] as HTMLElement
    dropdownMenuLink.setAttribute('aria-expanded', 'true')
    setTimeout(() => {
      dropdownMenuLink.setAttribute('aria-expanded', 'true')
    }, 10);
  }

  clearRangeFilters(type) {
    if (type == "follower-number") {
      this.minFollowerValue = 50000;
      this.maxFollowerValue = 600000000;
      this.selectedFollewerNumber = "";
      this.filterPostData["follower-number"] = 0;
      this.applyFilters("follower-number");
    }
    else if (type == "engagement-rate") {
      this.minEngagementValue = 50000;
      this.maxEngagementValue = 100000;
      this.selectedEngagementRate = "";
    }
    else if (type == "all") {
      this.minFollowerValue = 50000;
      this.maxFollowerValue = 600000000;
      this.selectedFollewerNumber = "";
      this.minEngagementValue = 50000;
      this.maxEngagementValue = 100000;
      this.selectedEngagementRate = "";
    }
  }

  resetFilters() {
    this.suggested_engagement_activity_ready = false;
    this.searchText = "";
    this.typeForm.reset()
    this.districtForm.reset()
    this.selectedDistrictCount = 0;
    this.selectedTypeCount = 0;
    this.selectedSubCategoryCount = 0;
    this.subCategoriesTreeData = [];
    this.subCategoriesTreeData = this.categoryDataMapper(this.sub_categories, [])
    this.clearRangeFilters("all")
    this.eventTypeForm.reset()
    this.tierForm.reset()
    this.selectedEventTypeCount = 0;
    this.selectedTierCount = 0;
    this.filterPostData = {
      "limit": 6,
      "offset": 0
    };
    if (!this.selectedProject['district_filter']) {
      this.resetActiveNodes()
    }
    this.getEngagementActivities(this.selectedProjectId, this.filterPostData, false)
  }

  getSubCategoryId(name) {
    for (let sub of this.sub_categories) {
      if (sub["name"] == name) {
        return Number(sub["id"])
      }
    }
  }

  convertCategoryToSubcategory() {
    if (this.filterPostData["categories"]) {
      for (let cat of this.filterPostData["categories"]) {
        for (let sub of this.sub_categories) {
          if (cat == sub["category"]["id"]) {
            this.filterPostData["sub-categories"].push(sub["id"])
          }
        }
      }
    }
  }

  onStateChangeSubCategory(event) {
    let selectedNodesIds = Object.keys(event.selectedLeafNodeIds)
    let selectedIds = [];
    let selectedSubIds = [];
    for (let nodeId of selectedNodesIds) {
      if (event.selectedLeafNodeIds[nodeId]) {
        let subId = this.subCategoriesTreeDataMapping[nodeId]
        if (selectedIds.indexOf(subId) == -1) {
          selectedIds.push(Number(subId))
        }
      }
    }

    selectedIds = selectedIds.sort()

    this.convertCategoryToSubcategory()
    setTimeout(() => {
      this.filterPostData["categories"]= [];
      this.filterPostData["sub-categories"]= [];
      this.selectedSubCategories = selectedIds;
      if (this.filterPostData["sub-categories"]) {
        selectedSubIds = this.filterPostData["sub-categories"].sort()
      }

      const equals = (selectedIds, selectedSubIds) => JSON.stringify(selectedIds) === JSON.stringify(selectedSubIds);

      let is_same = equals(selectedIds, selectedSubIds)
      if (!is_same) {
        this.applyFilters("category");
      }
    }, 3);
  }

  onSelectSubCategory(event) {
    this.treeAndSubCategoryMapper(event.treeModel.nodes)
    let subCategoryId = this.getSubCategoryId(event.node.data.name)
    this.selectedSubCategories.push(subCategoryId)
    this.selectedSubCategoryCount = this.selectedSubCategoryCount + 1
  }

  onDeselectSubCategory(event) {
    let index = this.selectedSubCategories.indexOf(event.node.data.name, 0);
    if (index == -1) {
       this.selectedSubCategories.splice(index, 1);
       this.selectedSubCategoryCount = this.selectedSubCategoryCount - 1
    }
  }

  onToggleSubCategory(event) {
    let treeDropdownMenu: HTMLElement = document.getElementsByClassName("tree-dropdown-menu")[0] as HTMLElement
    treeDropdownMenu.className = "dropdown-menu tree-dropdown-menu px-3 py-4 my-2 tree-dropdown";

    let dropdownMenuLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[1] as HTMLElement
    dropdownMenuLink.setAttribute('aria-expanded', 'true')
    setTimeout(() => {
      dropdownMenuLink.setAttribute('aria-expanded', 'true')
    }, 10);
  }

  clickOutside(event) {
    let treeDropdownMenu: HTMLElement = document.getElementsByClassName("tree-dropdown-menu")[0] as HTMLElement
    treeDropdownMenu.className = "dropdown-menu tree-dropdown-menu px-3 py-4 my-2";
    let dropdownMenuLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[1] as HTMLElement
    dropdownMenuLink.setAttribute('aria-expanded', 'false')
  }

  clickOutsideFollower(event) {
    let treeDropdownMenu: HTMLElement = document.getElementsByClassName("follower-dropdown-menu")[0] as HTMLElement
    treeDropdownMenu.className = "dropdown-menu follower-dropdown-menu px-3 py-4 my-2";
    let dropdownMenuLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[3] as HTMLElement
    dropdownMenuLink.setAttribute('aria-expanded', 'false')
  }

  clickOutsideEngagement(event) {
    let treeDropdownMenu: HTMLElement = document.getElementsByClassName("engagement-dropdown-menu")[0] as HTMLElement
    treeDropdownMenu.className = "dropdown-menu engagement-dropdown-menu px-3 py-4 my-2";
    let dropdownMenuLink: HTMLElement = document.getElementsByClassName("dropdownMenuLink")[4] as HTMLElement
    dropdownMenuLink.setAttribute('aria-expanded', 'false')
  }

  resetActiveNodes() {
    // for suncategory tree
    this.selectedSubCategoryCount = 0;
    this.subCategoriesTreeData = [];
    this.subCategoriesTreeData = this.categoryDataMapper(this.sub_categories, [])

    Object.entries(this.tree.treeModel.selectedLeafNodeIds).forEach(([key, value]) => {
      let node = this.tree.treeModel.getNodeById(key)
      if (node) {
        node.setIsSelected(false)
      }
    });
  }

  intToString(num) {
    if (num > 999 && num < 1000000) {
      return (num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000){
      return (num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  }

  milesSeperator(value) {
    if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  calculateRow() {
    if (this.suggested_engagement_activities.length < 3) {
      return this.suggested_engagement_activities.length
    }
    if (this.suggested_engagement_activities.length >= 3) {
      if ((this.suggested_engagement_activities.length % 3) == 0) {
        return this.suggested_engagement_activities.length/3
      }
      if ((this.suggested_engagement_activities.length % 3) != 0) {
        return this.suggested_engagement_activities.length/3 +1
      }
    }
  }

}
