<section *ngIf="!isWidget" class="slider-section">
  <nav class="navbar navbar-expand-lg navbar-light bg-transparent px-3">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img src="../../assets/img/logo-dark.png" alt="">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div *ngIf="currentUser && currentUser['profile']" class="collapse navbar-collapse flex-grow-1 text-right" id="myNavbar">
        <ul class="navbar-nav ms-auto flex-nowrap">
          <li class="nav-item dropdown mr-2">
            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img *ngIf="currentUser['profile']['image']" class="profile-img rounded-circle" src="{{ currentUser['profile']['image'] }}" alt="">
              <div *ngIf="!currentUser['profile']['image']" class="profile-img-div d-flex justify-content-center align-items-center">
                <p id="navbar-profile-name" class="profile-name text-uppercase">{{ currentUser["username"].slice(0, 2) }}</p>
              </div>
            </a>
            <div class="dropdown-menu mb-2" aria-labelledby="navbarDropdown">
              <a class="dropdown-item cursor-pointer" href="/dashboard">Dashboard</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item cursor-pointer" (click)="logout()">Log Out</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div *ngIf="isDataReady && !isWidget" id="carouselCaptions" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button *ngIf="selectedProject['details'][1]" type="button" data-bs-target="#carouselCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button *ngIf="selectedProject['details'][2]" type="button" data-bs-target="#carouselCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
      <button *ngIf="selectedProject['details'][3]" type="button" data-bs-target="#carouselCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
      <button *ngIf="selectedProject['details'][4]" type="button" data-bs-target="#carouselCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
      <button *ngIf="selectedProject['details'][5]" type="button" data-bs-target="#carouselCaptions" data-bs-slide-to="5" aria-label="Slide 6"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div id="mask">
          <img *ngIf="selectedProject['details'].length" class="slider-img d-block w-100" src="{{ selectedProject['details'][0]['image'] }}">
          <img *ngIf="!selectedProject['details'].length" class="slider-img d-block w-100" src="../../../assets/img/no-image.jpg">
        </div>
        <div class="carousel-caption d-none d-md-block">
          <p *ngIf="selectedProject['details'].length">{{ selectedProject['details'][0]["title"] }}</p>
          <h2 *ngIf="selectedProject['details'].length">{{ selectedProject['details'][0]["subtitle"] }}</h2>
          <div *ngIf="selectedProject['details'].length" class="row d-flex flex-row">
            <button type="button" class="btn btn-sm slider-box mx-1">{{ selectedProject['details'][0]['tags'] }}</button>
          </div>
        </div>
      </div>
      <div *ngFor="let detail of selectedProject['details'].slice(1, selectedProject['details'].length);" class="carousel-item ">
        <div id="mask">
          <img class="slider-img d-block w-100" src="{{ detail['image'] }}">
        </div>
        <div class="carousel-caption d-none d-md-block">
          <p>{{ detail["title"] }}</p>
          <h2>{{ detail["subtitle"] }}</h2>
          <div class="row d-flex flex-row">
            <button type="button" class="btn btn-sm slider-box mx-1">{{ detail['tags'] }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section *ngIf="!isWidget" class="menu-section pt-5 pb-3">
  <div class="row">
    <div class="col-md-12 col-lg-1">
    </div>
    <div *ngIf="currentUser" class="col-md-12 col-lg-10 d-flex justify-content-center">
      <div *ngIf="isCatchmentArea" class="d-flex justify-content-center">
        <div *ngIf="catchment_area" class="text-center menu-border-bottom px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('catchment-area')">Catchment Area</p>
        </div>
        <div *ngIf="audience" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('audience')">Audience</p>
        </div>
        <div *ngIf="smart_leasing" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('smart-leasing')">Smart Leasing</p>
        </div>
        <div *ngIf="engagement_curator" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('engagement-curator')">Engagement Curator</p>
        </div>
        <div *ngIf="engagement_activity" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('engagement-activity')">Engagement Activity</p>
        </div>
        <div *ngIf="architecture" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('architecture')">Architecture</p>
        </div>
        <div *ngIf="brand_radar" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('brand-radar')">Brand Radar</p>
        </div>
        <div *ngIf="brand_radar_demo" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('brand-radar-demo')">Brand Radar Demo</p>
        </div>
      </div>
      <div *ngIf="isAudience" class="d-flex justify-content-center">
        <div *ngIf="catchment_area" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('catchment-area')">Catchment Area</p>
        </div>
        <div *ngIf="audience" class="text-center menu-border-bottom px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('audience')">Audience</p>
        </div>
        <div *ngIf="smart_leasing" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('smart-leasing')">Smart Leasing</p>
        </div>
        <div *ngIf="engagement_curator" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('engagement-curator')">Engagement Curator</p>
        </div>
        <div *ngIf="engagement_activity" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('engagement-activity')">Engagement Activity</p>
        </div>
        <div *ngIf="architecture" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('architecture')">Architecture</p>
        </div>
        <div *ngIf="brand_radar" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('brand-radar')">Brand Radar</p>
        </div>
        <div *ngIf="brand_radar_demo" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('brand-radar-demo')">Brand Radar Demo</p>
        </div>
      </div>
      <div *ngIf="isSmartLeasing" class="d-flex justify-content-center">
        <div *ngIf="catchment_area" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('catchment-area')">Catchment Area</p>
        </div>
        <div *ngIf="audience" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('audience')">Audience</p>
        </div>
        <div *ngIf="smart_leasing" class="text-center menu-border-bottom px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('smart-leasing')">Smart Leasing</p>
        </div>
        <div *ngIf="engagement_curator" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('engagement-curator')">Engagement Curator</p>
        </div>
        <div *ngIf="engagement_activity" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('engagement-activity')">Engagement Activity</p>
        </div>
        <div *ngIf="architecture" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('architecture')">Architecture</p>
        </div>
        <div *ngIf="brand_radar" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('brand-radar')">Brand Radar</p>
        </div>
        <div *ngIf="brand_radar_demo" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('brand-radar-demo')">Brand Radar Demo</p>
        </div>
      </div>
      <div *ngIf="isEngagement" class="d-flex justify-content-center">
        <div *ngIf="catchment_area" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('catchment-area')">Catchment Area</p>
        </div>
        <div *ngIf="audience" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('audience')">Audience</p>
        </div>
        <div *ngIf="smart_leasing" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('smart-leasing')">Smart Leasing</p>
        </div>
        <div *ngIf="engagement_curator" class="text-center menu-border-bottom px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('engagement-curator')">Engagement Curator</p>
        </div>
        <div *ngIf="engagement_activity" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('engagement-activity')">Engagement Activity</p>
        </div>
        <div *ngIf="architecture" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('architecture')">Architecture</p>
        </div>
        <div *ngIf="brand_radar" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('brand-radar')">Brand Radar</p>
        </div>
        <div *ngIf="brand_radar_demo" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('brand-radar-demo')">Brand Radar Demo</p>
        </div>
      </div>
      <div *ngIf="isEngagementActivity" class="d-flex justify-content-center">
        <div *ngIf="catchment_area" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('catchment-area')">Catchment Area</p>
        </div>
        <div *ngIf="audience" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('audience')">Audience</p>
        </div>
        <div *ngIf="smart_leasing" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('smart-leasing')">Smart Leasing</p>
        </div>
        <div *ngIf="engagement_curator" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('engagement-curator')">Engagement Curator</p>
        </div>
        <div *ngIf="engagement_activity" class="text-center menu-border-bottom px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('engagement-activity')">Engagement Activity</p>
        </div>
        <div *ngIf="architecture" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('architecture')">Architecture</p>
        </div>
        <div *ngIf="brand_radar" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('brand-radar')">Brand Radar</p>
        </div>
        <div *ngIf="brand_radar_demo" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('brand-radar-demo')">Brand Radar Demo</p>
        </div>
      </div>
      <div *ngIf="isArchitecture" class="d-flex justify-content-center">
        <div *ngIf="catchment_area" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('catchment-area')">Catchment Area</p>
        </div>
        <div *ngIf="audience" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('audience')">Audience</p>
        </div>
        <div *ngIf="smart_leasing" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('smart-leasing')">Smart Leasing</p>
        </div>
        <div *ngIf="engagement_curator" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('engagement-curator')">Engagement Curator</p>
        </div>
        <div *ngIf="engagement_activity" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('engagement-activity')">Engagement Activity</p>
        </div>
        <div *ngIf="architecture" class="text-center menu-border-bottom px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('architecture')">Architecture</p>
        </div>
        <div *ngIf="brand_radar" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('brand-radar')">Brand Radar</p>
        </div>
        <div *ngIf="brand_radar_demo" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('brand-radar-demo')">Brand Radar Demo</p>
        </div>
      </div>
      <div *ngIf="isBrandRadar" class="d-flex justify-content-center">
        <div *ngIf="catchment_area" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('catchment-area')">Catchment Area</p>
        </div>
        <div *ngIf="audience" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('audience')">Audience</p>
        </div>
        <div *ngIf="smart_leasing" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('smart-leasing')">Smart Leasing</p>
        </div>
        <div *ngIf="engagement_curator" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('engagement-curator')">Engagement Curator</p>
        </div>
        <div *ngIf="engagement_activity" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('engagement-activity')">Engagement Activity</p>
        </div>
        <div *ngIf="architecture" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('architecture')">Architecture</p>
        </div>
        <div *ngIf="brand_radar" class="text-center menu-border-bottom px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('brand-radar')">Brand Radar</p>
        </div>
        <div *ngIf="brand_radar_demo" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('brand-radar-demo')">Brand Radar Demo</p>
        </div>
      </div>
      <div *ngIf="isBrandRadarDemo" class="d-flex justify-content-center">
        <div *ngIf="catchment_area" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('catchment-area')">Catchment Area</p>
        </div>
        <div *ngIf="audience" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('audience')">Audience</p>
        </div>
        <div *ngIf="smart_leasing" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('smart-leasing')">Smart Leasing</p>
        </div>
        <div *ngIf="engagement_curator" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('engagement-curator')">Engagement Curator</p>
        </div>
        <div *ngIf="engagement_activity" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('engagement-activity')">Engagement Activity</p>
        </div>
        <div *ngIf="architecture" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('architecture')">Architecture</p>
        </div>
        <div *ngIf="brand_radar" class="text-center px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('brand-radar')">Brand Radar</p>
        </div>
        <div *ngIf="brand_radar_demo" class="text-center menu-border-bottom px-5 pt-4 pb-2 mx-1">
          <p class="menu-text cursor-pointer mb-0" (click)="toggleMenuTabs('brand-radar-demo')">Brand Radar Demo</p>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-1 text-right">
    </div>
  </div>
</section>

<section *ngIf="isCatchmentArea && isDataReady && catchment_area" class="market-dynamics-section">
  <app-catchment></app-catchment>
</section>

<section *ngIf="isAudience && isDataReady && audience" class="market-dynamics-section">
  <app-audience></app-audience>
</section>

<section *ngIf="isSmartLeasing && isDataReady && smart_leasing" class="market-dynamics-section">
  <app-leasing></app-leasing>
</section>

<section *ngIf="isEngagement && isDataReady && engagement_curator" class="market-dynamics-section">
  <app-engagement></app-engagement>
</section>

<section *ngIf="isEngagementActivity && isDataReady && engagement_activity" class="market-dynamics-section">
  <app-engagement-activity></app-engagement-activity>
</section>

<section *ngIf="isArchitecture && isDataReady && architecture" class="market-dynamics-section">
  <app-architecture></app-architecture>
</section>

<section *ngIf="isBrandRadar && isDataReady && brand_radar" class="market-dynamics-section">
  <app-brand-radar></app-brand-radar>
</section>

<section *ngIf="isBrandRadarDemo && isDataReady && brand_radar_demo" class="market-dynamics-section">
  <app-brand-radar-demo></app-brand-radar-demo>
</section>
