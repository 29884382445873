<section class="market-dynamics-section">
  <div class="px-5 pt-5 pb-3 d-flex align-items-center">
    <p class="section-header-text text-uppercase cursor-pointer">Suggested Engagement Activities</p>
  </div>
</section>

<section class="filter-section">
  <div class="px-5 d-flex align-items-center">
    <div class="filter-buttons d-flex mb-4 mt-2">
      <div *ngIf="selectedProject['district_filter']" class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-3 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="districts.length == 0">
          Districts <span *ngIf="selectedDistrictCount" class="bold-text"> ({{ selectedDistrictCount }})</span>
        </button>
        <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <form [formGroup]="districtForm" (ngSubmit)="applyFilters('district')">
            <div class="scroll-div-filter">
              <li class="my-2" *ngFor="let district of districts">
                <a class="dropdown-item">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="district['id']" (change)="applyFilters('district')" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      {{ district['name'] }}
                    </label>
                  </div>
                </a>
              </li>
            </div>
          </form>
        </ul>
      </div>
      <div class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-3 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="types.length == 0">
          Types <span *ngIf="selectedTypeCount" class="bold-text"> ({{ selectedTypeCount }})</span>
        </button>
        <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <form [formGroup]="typeForm" (ngSubmit)="applyFilters('type')">
            <div class="scroll-div-filter">
              <li class="my-2" *ngFor="let type of types">
                <a class="dropdown-item">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="type['name'].toLowerCase()" (change)="applyFilters('type')" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      {{ type['name'] }}
                    </label>
                  </div>
                </a>
              </li>
            </div>
          </form>
        </ul>
      </div>
      <div *ngIf="!selectedProject['district_filter']" class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-3 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="!selectedTypeCount">
          Category <span *ngIf="selectedSubCategoryCount" class="bold-text"> ({{ selectedSubCategoryCount }})</span>
        </button>
        <ul class="dropdown-menu tree-dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink" (ngxOutsideClick)="clickOutside($event)">
          <form class="form-inline pb-0 mb-3">
            <div class="search-box">
              <div class="d-flex align-items-center">
                <input #filter (keyup)="tree.treeModel.filterNodes(filter.value)" name="search" style="border: 0px;" type="search" class="form-control rounded search-input" placeholder="Search in Categories" aria-label="Search"
                  aria-describedby="search-addon" autofocus />
              </div>
            </div>
          </form>
          <div class="scroll-div-filter">
            <tree-root #tree [nodes]="subCategoriesTreeData" [options]="treeOptions" (stateChange)="onStateChangeSubCategory($event)" (select)="onSelectSubCategory($event)" (deselect)="onDeselectSubCategory($event)"
              (toggleExpanded)="onToggleSubCategory($event)">
            </tree-root>
          </div>
        </ul>
      </div>
      <div *ngIf="!selectedProject['district_filter']" class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-3 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="tiers.length == 0">
          Tiers <span *ngIf="selectedTierCount" class="bold-text"> ({{ selectedTierCount }})</span>
        </button>
        <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <form [formGroup]="tierForm" (ngSubmit)="applyFilters('tier')">
            <div class="scroll-div-filter">
              <li class="my-2" *ngFor="let tier of tiers">
                <a class="dropdown-item">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="tier['id']" (change)="applyFilters('tier')" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      {{ tier['name'] }}
                    </label>
                  </div>
                </a>
              </li>
            </div>
          </form>
        </ul>
      </div>
      <div *ngIf="!selectedProject['district_filter']" class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-3 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="false">
          Followers <span *ngIf="selectedFollewerNumber" class="little-bold-text"> ({{ selectedFollewerNumber }})</span>
        </button>
        <ul class="dropdown-menu follower-dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink" (ngxOutsideClick)="clickOutsideFollower($event)">
          <div class="range-card p-2">
            <p class="content-text">FOLLOWER NUMBER <br>
              Choose a range to filter by follower number
            </p>
            <div class="range-input mx-1 my-5">
              <ngx-slider [(value)]="minFollowerValue" [(highValue)]="maxFollowerValue" [options]="followerOptions" (userChangeEnd)="onFollowerNumberValueChange($event)">
              </ngx-slider>
            </div>
            <div class="row">
              <div class="col-12 col-md-5">
                <input class="range-input-text form-control mr-3" type="text" name="" value="{{ minFollowerValue }}" placeholder="Minimum" (input)="onMinFollowerValueChange($event.target.value)">
              </div>
              <div class="col-12 col-md-2">
              </div>
              <div class="col-12 col-md-5">
                <input class="range-input-text form-control ml-3" type="text" name="" value="{{ maxFollowerValue }}" placeholder="Maximum" (input)="onMaxFollowerValueChange($event.target.value)">
              </div>
            </div>
            <div class="row d-flex align-items-center mt-4">
              <div class="col-12 col-md-6 text-left">
                <a class="clear-text text-uppercase mt-3 cursor-pointer" (click)="clearRangeFilters('follower-number')"><u>Clear</u></a>
              </div>
              <div class="col-12 col-md-6 text-right">
                <button style="float: right;" type="submit" class="btn btn-dark dark-btn save-btn text-uppercase px-2" (click)="applyFilters('follower-number')">Save</button>
              </div>
            </div>
          </div>
        </ul>
      </div>
      <!-- <div class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-3 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="true">
          Engagement Rate <span *ngIf="selectedEngagementRate" class="little-bold-text"> ({{ selectedEngagementRate }})</span>
        </button>
        <ul class="dropdown-menu engagement-dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink" (ngxOutsideClick)="clickOutsideEngagement($event)">
          <div class="range-card p-2">
            <p class="content-text">ENGAGEMENT RATE <br>
              Choose a range to filter by engagement rate
            </p>
            <div class="range-input mx-1 my-5">
              <ngx-slider
                [(value)]="minEngagementValue"
                [(highValue)]="maxEngagementValue"
                [options]="engagementOptions"
                (userChangeEnd)="onEngagementNumberValueChange($event)">
              </ngx-slider>
            </div>
            <div class="row">
              <div class="col-12 col-md-5">
                <input class="range-input-text  form-control mr-3" type="text" name="" value="{{ minEngagementValue }}" placeholder="Minimum" (input)="onMinEngagementValueChange($event.target.value)">
              </div>
              <div class="col-12 col-md-2">
              </div>
              <div class="col-12 col-md-5">
                <input class="range-input-text  form-control ml-3" type="text" name="" value="{{ maxEngagementValue }}" placeholder="Maximum" (input)="onMaxEngagementValueChange($event.target.value)">
              </div>
            </div>
            <div class="row d-flex align-items-center mt-4">
              <div class="col-12 col-md-6 text-left">
                <a class="clear-text text-uppercase mt-3 cursor-pointer" (click)="clearRangeFilters('engagement-rate')"><u>Clear</u></a>
              </div>
              <div class="col-12 col-md-6 text-right">
                <button style="float: right;" type="submit" class="btn btn-dark dark-btn save-btn text-uppercase px-2" (click)="applyFilters('engagement-rate')">Save</button>
              </div>
            </div>
          </div>
        </ul>
      </div> -->
      <div *ngIf="!selectedProject['district_filter']" class="dropdown filter-dropdown">
        <button class="dropdownMenuLink btn btn-light light-btn px-3 mr-3" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" [class.disabled]="eventTypes.length == 0">
          Event Type <span *ngIf="selectedEventTypeCount" class="bold-text"> ({{ selectedEventTypeCount }})</span>
        </button>
        <ul class="dropdown-menu px-3 py-4 my-2" aria-labelledby="dropdownMenuLink">
          <form [formGroup]="eventTypeForm" (ngSubmit)="applyFilters('eventType')">
            <div class="scroll-div-filter">
              <li class="my-2" *ngFor="let eventType of eventTypes">
                <a class="dropdown-item">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" type="checkbox" [formControlName]="eventType['id']" (change)="applyFilters('event-type')" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      {{ eventType['name'] }}
                    </label>
                  </div>
                </a>
              </li>
            </div>
          </form>
        </ul>
      </div>
      <button type="button" class="btn filter-text text-uppercase" (click)="resetFilters()"><u>Reset Filters</u></button>
    </div>
    <!-- <div *ngIf="!suggested_engagement_activity_ready" class="d-flex justify-content-center  mb-4 mt-2">
      <div class="spinner-border text-danger d-flex justify-content-center" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div> -->
  </div>
</section>

<section class="filter-section">
  <div class="px-5 pb-3 d-flex align-items-center">
    <div class="filter-buttons d-flex">
      <form class="form-inline pb-0" (submit)="searchBrand()" action="#">
        <div class="search-box2">
          <div class="d-flex align-items-center mt-1">
            <i class="fa fa-search me-2"></i>
            <input [(ngModel)]="searchText" name="search" style="border: 0px;" type="search" class="form-control rounded search-input"  placeholder="Search Brand/Influencer" aria-label="Search"
              aria-describedby="search-addon" autofocus/>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>

<section *ngIf="suggested_engagement_activities.length != 0" class="hopl-section">
  <div *ngIf="suggested_engagement_activity_ready" class="suggested-engagement-activity-div ps-5 pe-4 ms-5 me-4">
    <div *ngFor="let item of suggested_engagement_activities.slice(0, rowLength), let i=index" class="row w-100 mb-4 d-flex justify-content-center" style="position: relative;">
      <div *ngFor="let item of suggested_engagement_activities.slice(i*3, i*3+3)" class="col-md-12 col-lg-4">
        <div class="card-flip cursor-pointer">
          <div class="card-front">
            <div class="brand-card card">
              <div class="card-img-top">
                <div class="d-flex justify-content-center">
                  <div *ngIf="item['type'] == 'influencer'" class="d-flex justify-content-center align-items-center label-div-red mx-5 my-4">
                    <p class="label-text text-uppercase p-2 my-1">{{ item["type"] }}</p>
                  </div>
                  <div *ngIf="item['type'] == 'brand'" class="d-flex justify-content-center align-items-center label-div-black mx-5 my-4">
                    <p class="label-text text-uppercase p-2 my-1">{{ item["type"] }}</p>
                  </div>
                </div>
                <div class="d-flex justify-content-center mb-5">
                  <img *ngIf="!(item['image'] == '')" src="{{ item['image'] }}" class="brand-logo">
                  <div *ngIf="(item['image'] == '') && !(item['name'] == '')" class="logo-div d-flex justify-content-center align-items-center">
                    <p class="logo-name text-center mb-0 pb-0"> {{ item['instagram_name'] }}</p>
                  </div>
                  <div *ngIf="(item['image'] == '') && (item['name'] == '')" class="logo-div d-flex justify-content-center align-items-center">
                    <p class="logo-name text-center mb-0 pb-0">{{ item['instagram_name'] }}</p>
                  </div>
                </div>
                <div class="text-center">
                  <p class="large-text mb-0">{{ item["name"] }}</p>
                  <p class="small-text">{{ item["job"] }}</p>
                </div>
              </div>
              <div class="card-body">
                <div class="fix-bottom mx-2 mt-4">
                  <div class="row d-flex align-items-bottom">
                    <div class="col-md-12 col-lg-6 text-align-left">
                      <p class="xlarge-text mb-0">{{ milesSeperator(item["follower_number"]) }}</p>
                      <p class="small-text">Followers</p>
                    </div>
                    <div class="col-md-12 col-lg-6 text-align-right">
                      <p *ngIf="item['engagement_rate']" class="xlarge-text mb-0">{{ item["engagement_rate"] }}%</p>
                      <p *ngIf="!item['engagement_rate']" class="xlarge-text mb-0">-%</p>
                      <p class="small-text">Engagement</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <!-- <div class="text-center">
                  <a class="agenda-text cursor-pointer" data-bs-toggle="modal" data-bs-target="#addToAgendaModal"><u>Add to Agenda</u></a>
                </div> -->
              </div>
            </div>
          </div>
          <div class="card-back">
            <div class="brand-card-back card p-3">
              <div class="card-top text-align-left">
                <div class="row ml-1">
                  <div class="col-md-12 col-lg-4">
                    <img src="{{ item['image'] }}" class="brand-logo-back">
                  </div>
                  <div class="col-md-12 col-lg-8 mt-3">
                    <p class="small-text mb-0">{{ item["job"] }}</p>
                    <p class="large-text mb-0">{{ item["name"] }}</p>
                    <a class="small-text" href="https://www.instagram.com/{{item['instagram_name']}}" target="_blank">@{{ item["instagram"]["username"] }}</a>
                  </div>
                </div>
              </div>
              <div class="card-body text-align-left">
                <div class="row ml-1">
                  <div class="col-md-12 col-lg-12">
                    <p class="desc-text mb-3">{{ item["description"] }}</p>
                  </div>
                </div>
                <div class="row ml-1">
                  <div class="col-md-12 col-lg-6 d-flex align-items-center">
                    <p class="back-small-text mb-1">Event Type</p>
                    <div class="dropdown">
                      <button class="btn info-btn px-1 mr-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                      </button>
                      <div class="dropdown-menu info-dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                        <p *ngFor="let type of item['event_type_detail']" class="info-text mb-0">
                          <span *ngIf="type['value']">{{ type["name"].replace("is_", "") }}: Yes</span>
                          <span *ngIf="!type['value']">{{ type["name"].replace("is_", "") }}: No</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6 text-align-right">
                    <p *ngIf="item['event_type']" class="back-large-text mb-1 mt-2">{{ item["event_type"]["name"] }}</p>
                    <p *ngIf="!item['event_type']" class="back-large-text mb-1 mt-2">-</p>
                  </div>
                </div>
                <div class="row ml-1">
                  <div class="col-md-12 col-lg-5">
                    <p class="back-small-text mb-1">Tier</p>
                  </div>
                  <div class="col-md-12 col-lg-7 text-align-right">
                    <p *ngIf="item['tier']" class="back-large-text mb-1">{{ item["tier"]["name"] }}</p>
                    <p *ngIf="!item['tier']" class="back-large-text mb-1">-</p>
                  </div>
                </div>
                <div class="row ml-1">
                  <div class="col-md-12 col-lg-5">
                    <p class="back-small-text mb-1">Category</p>
                  </div>
                  <div class="col-md-12 col-lg-7 text-align-right">
                    <p *ngIf="item['category']" class="back-large-text mb-1">{{ item["category"]["name"] }}</p>
                    <p *ngIf="!item['category']" class="back-large-text mb-1">-</p>
                  </div>
                </div>
                <div class="row ml-1">
                  <div class="col-md-12 col-lg-5">
                    <p class="back-small-text mb-1">Sub-category</p>
                  </div>
                  <div class="col-md-12 col-lg-7 text-align-right">
                    <p *ngIf="item['sub_category']" class="back-large-text mb-1">{{ item["sub_category"]["name"] }}</p>
                    <p *ngIf="!item['sub_category']" class="back-large-text mb-1">-</p>
                  </div>
                </div>
                <div class="row ml-1">
                  <div class="col-md-12 col-lg-5">
                    <p class="back-small-text mb-1">Follower Number</p>
                  </div>
                  <div class="col-md-12 col-lg-7 text-align-right">
                    <p class="back-large-text mb-1">{{ milesSeperator(item["follower_number"]) }}</p>
                  </div>
                </div>
                <div class="row ml-1">
                  <div class="col-md-12 col-lg-5">
                    <p class="back-small-text mb-1">E-mail Address</p>
                  </div>
                  <div class="col-md-12 col-lg-7 text-align-right">
                    <p *ngIf="item['email']" class="back-large-text mb-0">{{ item["email"] }}</p>
                    <p *ngIf="!item['email']" class="back-large-text mb-1">-</p>
                  </div>
                </div>
                <div class="row ml-1">
                  <div class="col-md-12 col-lg-5">
                    <p class="back-small-text mb-1">Website</p>
                  </div>
                  <div class="col-md-12 col-lg-7 text-align-right">
                    <p *ngIf="item['website']" class="back-large-text mb-0">{{ item["website"].split(".com")[0] }}.com</p>
                    <p *ngIf="!item['website']" class="back-large-text mb-1">-</p>
                  </div>
                </div>
                <!-- <div class="row ml-1">
                  <div class="col-md-12 col-lg-8">
                    <p class="back-small-text mb-1">Phone Number</p>
                  </div>
                  <div class="col-md-12 col-lg-4 text-align-right">
                    <p class="back-large-text mb-1">{{ item["phone"] }}</p>
                  </div>
                </div> -->
                <!-- <div class="row ml-1">
                  <div class="col-md-12 col-lg-8">
                    <p class="back-small-text mb-1">Engagement Rate</p>
                  </div>
                  <div class="col-md-12 col-lg-4 text-align-right">
                    <p class="back-large-text mb-1">{{ item["engagement_rate"] }}</p>
                  </div>
                </div> -->
                <div class="row ml-1">
                  <div class="col-md-12 col-lg-5">
                    <p class="back-small-text mb-1">Verified</p>
                  </div>
                  <div class="col-md-12 col-lg-7 text-align-right">
                    <p *ngIf="item['is_verified'] == 1" class="back-large-text mb-1">Yes</p>
                    <p *ngIf="item['is_verified'] == 0" class="back-large-text mb-1">No</p>
                  </div>
                </div>
                <div class="row ml-1">
                  <div class="col-md-12 col-lg-5">
                    <p class="back-small-text mb-1">Number of Posts</p>
                  </div>
                  <div class="col-md-12 col-lg-7 text-align-right">
                    <p *ngIf="item['number_of_posts']" class="back-large-text mb-1">{{ milesSeperator(item["number_of_posts"]) }}</p>
                    <p *ngIf="!item['number_of_posts']" class="back-large-text mb-1">-</p>
                  </div>
                </div>
                <div class="row ml-1">
                  <div class="col-md-12 col-lg-5">
                    <p class="back-small-text mb-1">Popularity Change (30days)</p>
                  </div>
                  <div class="col-md-12 col-lg-7 text-align-right">
                    <p *ngIf="item['popularity_change']" class="back-large-text mb-1">{{ item["popularity_change"] }}</p>
                    <p *ngIf="!item['popularity_change']" class="back-large-text mb-1">-</p>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <!-- <div class="text-center">
                  <a class="agenda-text cursor-pointer" data-bs-toggle="modal" data-bs-target="#addToAgendaModal"><u>Add to Agenda</u></a>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isShowMore" class="d-flex justify-content-center my-5">
    <div class="spinner-border text-danger d-flex justify-content-center" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="d-flex justify-content-center my-5">
    <a class="footer-text text-uppercase cursor-pointer mr-auto" (click)="showMore()"><u>Show More({{ suggested_engagement_activities.length }}/{{ total_suggested_engagement_activities_count }})</u></a>
  </div>
</section>

<section *ngIf="suggested_engagement_activities.length == 0 && !suggested_engagement_activity_ready" class="py-5">
  <div class="d-flex justify-content-center my-5 py-5">
    <div class="spinner-border text-danger d-flex justify-content-center" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</section>

<section *ngIf="suggested_engagement_activities.length == 0 && suggested_engagement_activity_ready" class="py-5">
  <div class="d-flex justify-content-center my-5 py-5">
    <p class="section-header-text">There is no matching result according to your filter</p>
  </div>
</section>
