import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private api = environment.apiURL
  private town_id: Number;
  private project_id: Number;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Token ' + this.getToken()
    })
  };
  constructor(
    private http:HttpClient
  ) { }

  getToken() {
    return localStorage.getItem('access_token');
  }

  getProjects() {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + "projects/list", this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getProjectDetail(name) {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `projects/search?name=${name}`, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getProjectFeatureSections(project_id, feature_id) {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `project/features/sections?project_id=${project_id}&feature_id=${feature_id}`, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getTowns() {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + "towns/list", this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getTown(name) {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `towns/search?name=${name}`, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            let town_id = res["id"]
            this.setTownId(town_id)
            resolve(res)
          },
          msg => { // Error
            window.location.href = '/dashboard';
            reject(msg);
          }
        );
    });
    return promise;
  }

  setTownId(value) {
    this.town_id = value;
  }

  getTownId() {
    return new Promise(resolve => {
      resolve(this.town_id);
    });
  }

  getProject(name) {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `projects/search?name=${name}`, this.httpOptions)
        .toPromise()
        .then(
          res => {
            resolve(res)
          },
          msg => {
            window.location.href = '/dashboard';
            reject(msg);
          }
        );
    });
    return promise;
  }


  getBrandCategories() {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + "brand-categories/list", this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getBenchmarks(project_id) {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `benchmarks/list?project_id=${project_id}`, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getCategories() {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + "categories/list", this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getSubCategories() {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + "sub-categories/list", this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getPeriods() {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + "periods/list", this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getAreas(project_id) {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `areas/search?project_id=${project_id}`, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getStreets(project_id, catchments) {
    let url = this.api + `streets/search?project_id=${project_id}`
    url = url.concat("&areas=" + catchments.toString())

    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getSizes() {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    let url = this.api + `sizes`
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getChilds(project_id) {
    let url = this.api + `child-projects/search?project_id=${project_id}`
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getStrategyFocuses() {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    let url = this.api + `strategy_focuses`
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  applyLocationFilters(project_id, data) {
    let url = this.arrangeUrl(this.api + `locations/search?project_id=${project_id}`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  applyBrandCategoryFilter(project_id, data) {
    let url = this.arrangeUrl(this.api + `locations/audience?project_id=${project_id}`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  arrangeUrl(base_url, data) {
    let postData = data
    let url = base_url
    // categories
    if (postData.hasOwnProperty("categories")) {
      if (postData["categories"].length) {
        url = url.concat("&categories=" + postData["categories"].toString())
      }
    }
    // sub-categories
    if (postData.hasOwnProperty("sub-categories")) {
      if (postData["sub-categories"].length) {
        url = url.concat("&sub-categories=" + postData["sub-categories"].toString())
      }
    }
    // periods
    if (postData.hasOwnProperty("periods")) {
      if (postData["periods"].length) {
        url = url.concat("&periods=" + postData["periods"].toString())
      }
    }
    // catchments
    if (postData.hasOwnProperty("catchments")) {
      if (postData["catchments"].length) {
        url = url.concat("&areas=" + postData["catchments"].toString())
      }
    }
    // streets
    if (postData.hasOwnProperty("streets")) {
      if (postData["streets"].length) {
        url = url.concat("&streets=" + postData["streets"].toString())
      }
    }
    // brand-category
    if (postData.hasOwnProperty("brandCategories")) {
      if (postData["brandCategories"].length) {
        url = url.concat("&brandCategories=" + postData["brandCategories"].toString())
      }
    }
    // grayMarker
    if (postData.hasOwnProperty("grayMarker")) {
      if (postData["grayMarker"]) {
        url = url.concat("&grayMarker=" + postData["grayMarker"])
      }
    }
    return url;
  }

  getPriceBreakdownData(project_id, data) {
    let url = this.arrangeUrl(this.api + `locations/prices/group?project_id=${project_id}&by=price`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getTenantBreakdownData(project_id, data) {
    let url = this.arrangeUrl(this.api + `locations/categories/group?project_id=${project_id}&by=category`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getPopularityData(project_id, data) {
    let url = this.arrangeUrl(this.api + `locations/popularity?project_id=${project_id}`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getDigitalFootfall(project_id, data) {
    let url = this.arrangeUrl(this.api + `locations/digital-footfall?project_id=${project_id}`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getPopularTimesHour(project_id, data) {
    let url = this.arrangeUrl(this.api + `locations/popular-times/group?project_id=${project_id}&by=hour`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getPopularTimesDay(project_id, data) {
    let url = this.arrangeUrl(this.api + `locations/popular-times/group?project_id=${project_id}&by=day`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getPopularTimesMonth(project_id, data) {
    let url = this.arrangeUrl(this.api + `locations/popular-times/group?project_id=${project_id}&by=month`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getTopTenBusinessData(project_id, data) {
    let url = this.arrangeUrl(this.api + `locations/top-ten-business?project_id=${project_id}`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getPersonasData(project_id, data) {
    let url = this.arrangeUrl(this.api + `locations/personas?project_id=${project_id}`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getAudienceData(project_id, data) {
    let url = this.arrangeUrl(this.api + `locations/audience?project_id=${project_id}`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  arrangeAudienceUrl(base_url, data) {
    let postData = data
    let url = base_url
    // project
    if (postData.hasOwnProperty("project")) {
      if (postData["project"].length) {
        url = url.concat("audience=" + postData["project"].toString())
      }
    }
    // benchmarks
    if (postData.hasOwnProperty("benchmarks")) {
      if (postData["benchmarks"].length) {
        url = url.concat("&benchmark=" + postData["benchmarks"].toString())
      }
    }
    // brandCategories
    if (postData.hasOwnProperty("brandCategories")) {
      if (postData["brandCategories"].length) {
        url = url.concat("&brandCategories=" + postData["brandCategories"].toString())
      }
    }
    return url;
  }

  getAudience(data) {
    let url = this.arrangeAudienceUrl(this.api + `audience/search?`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getVideoUrl() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + "video", this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  // SMART LEASING

  arrangeSmartLeasingUrl(base_url, data) {
    let postData = data
    let url = base_url
    // status
    if (postData.hasOwnProperty("status")) {
      url = url.concat("&status=" + postData["status"])
    }
    // strategy_id
    if (postData.hasOwnProperty("strategy_id")) {
      url = url.concat("&strategy_id=" + postData["strategy_id"])
    }
    // group_by
    if (postData.hasOwnProperty("group_by")) {
      url = url.concat("&group_by=" + postData["group_by"])
    }
    // categories
    if (postData.hasOwnProperty("categories")) {
      if (postData["categories"].length) {
        url = url.concat("&categories=" + postData["categories"].toString())
      }
    }
    return url;
  }

  getStrategies(project_id) {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `strategy/search?project_id=${project_id}`, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getProspectiveTenanatList(data) {
    let url = this.arrangeSmartLeasingUrl(this.api + `brands/search?`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getProspectiveTenanatDetail(brand_id, strategy_id) {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `brand/${brand_id}?strategy_id=${strategy_id}`, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  updateBrandStatus(strategy_id, brand_id, data) {
    let postData = data
    let promise = new Promise((resolve, reject) => {
      const req = this.http.put(this.api + `strategy/${strategy_id}/brand/${brand_id}`, postData, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  arrangeBrandUrl(base_url, data) {
    let postData = data
    let url = base_url

    // order_by
    if (postData.hasOwnProperty("order_by")) {
      if (postData["order_by"].length) {
        url = url.concat("order_by=" + postData["order_by"].toString())
      }
    }

    // district
    if (postData.hasOwnProperty("district")) {
      if (postData["district"].length) {
        url = url.concat("&child_project=" + postData["district"].toString())
      }
    }

    // group_by
    if (postData.hasOwnProperty("group_by")) {
      if (postData["group_by"].length) {
        url = url.concat("&group_by=" + postData["group_by"].toString())
      }
    }

    // categories
    if (postData.hasOwnProperty("categories")) {
      if (postData["categories"].length) {
        url = url.concat("&categories=" + postData["categories"].toString())
      }
    }

    // status
    if (postData.hasOwnProperty("statuses")) {
      if (postData["statuses"].length) {
        url = url.concat("&status=" + postData["statuses"].toString())
      }
    }

    // sizes
    if (postData.hasOwnProperty("sizes")) {
      if (postData["sizes"].length) {
        url = url.concat("&sizes=" + postData["sizes"].toString())
      }
    }

    // distance
    if (postData.hasOwnProperty("distance")) {
      if (postData["distance"].length) {
        url = url.concat("&distance=" + postData["distance"].toString())
      }
    }

    // distance_locations
    if (postData.hasOwnProperty("distance_locations")) {
      if (postData["distance_locations"].length) {
        url = url.concat("&distance_locations=" + postData["distance_locations"].toString())
      }
    }

    // strategy focuses
    if (postData.hasOwnProperty("strategy_focuses")) {
      if (postData["strategy_focuses"].length) {
        url = url.concat("&strategy_focuses=" + postData["strategy_focuses"].toString())
      }
    }

    // fact_sheet statuses
    if (postData.hasOwnProperty("fact_sheet")) {
      if (postData["fact_sheet"].length) {
        url = url.concat("&fact_sheet=" + postData["fact_sheet"].toString())
      }
    }

    // limit
    if (postData.hasOwnProperty("limit")) {
      if (postData["limit"].length) {
        url = url.concat("&limit=" + postData["limit"].toString())
      }
    }

    // offset
    if (postData.hasOwnProperty("offset")) {
      if (postData["offset"].length) {
        url = url.concat("&offset=" + postData["offset"].toString())
      }
    }

    return url;
  }

  getBrands(project_id, data) {
    let url = this.arrangeBrandUrl(
      this.api + `brand-radar/search?project_id=${project_id}&`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  arrangeCategoryTrendinessUrl(base_url, data) {
    let postData = data
    let url = base_url

    // categories
    if (postData.hasOwnProperty("categories")) {
      if (postData["categories"].length) {
        url = url.concat("&categories=" + postData["categories"].toString())
      }
    }

    // district
    if (postData.hasOwnProperty("district")) {
      if (postData["district"].length) {
        url = url.concat("&child_project=" + postData["district"].toString())
      }
    }

    return url;
  }

  getCategoryTrendiness(project_id, data) {
    let url = this.arrangeCategoryTrendinessUrl(
      this.api + `brand-radar/trendiness/search?project_id=${project_id}`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  sendFactsheetRequest(project_id, brand_id, data) {
    let url = this.api + `brand-radar/search/${brand_id}?project_id=${project_id}`
    let promise = new Promise((resolve, reject) => {
      const req = this.http.patch(url, data, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getFactsheet(project_id, brand_id) {
    let url = this.api + `brand-radar/fact-sheet/${brand_id}?project_id=${project_id}`
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getBrand(project_id, brand_id) {
    // let url = this.api + `brand-radar/search/{brand_id}?project_id=${project_id}&`
    let url = this.api
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            // resolve(res)
            resolve(
              {
            		"id": 1,
            		"profile_image": "https://ig-data-collection.s3.amazonaws.com/profile-images/tommy.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=%20AKIAVPILHXPVNDCT3WGD%2F20221021%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Date=20221021T123205Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=3b621add817cd47660bf00cc25470876bbf6df1bb4edbf8a29f68167183977eb",
            		"popularity_score_month": 22.04,
            		"popularity_score_year": 31.33,
            		"filtered_rank": 1,
            		"status": "declining",
            		"instagram_account": "zara",
            		"digital_footfall": 35,
            		"google_trend_score_year": 0,
            		"google_trend_score_month": 100,
            		"google_rating": 0,
            		"google_rating_year": 0,
            		"google_rating_month": 0,
            		"engagement_rate": 0.17,
            		"follower_count": 21050141,
            		"follower_change_month": 0,
            		"follower_change_year": 1870033,
            		"follower_change_ratio_month": 0,
            		"follower_change_ratio_year": -2,
            		"rank": 1,
            		"previous_rank": 38,
            		"rank_change": 0,
            		"biography": "Women, with their intuitive instinct, understood that I dreamed not only of making them more beautiful.",
            		"website": "https://confirmed.onelink.me/mzYA/5b30338e",
            		"has_potential": false,
            		"has_location": false,
            		"is_product": false,
            		"is_service": true,
            		"is_experience": true,
            		"is_online_only": true,
            		"occurrence": 548,
            		"updated_at": "2022-10-18T13:31:56.250364Z",
            		"created_at": "2022-10-18T13:31:56.250369Z",
            		"project": {
            			"id": 51,
            			"name": "Brand Radar Demo",
            			"slug": "brand-radar-demo",
            			"type": "global",
            			"image": "projects/18/US_London_UK_Header.jpg",
            			"client": 13,
            			"town": 1,
            			"areas": [],
            			"features": [
            				8
            			],
            			"sections": [],
            			"benchmarks": []
            		},
            		"category": {
            			"id": 2,
            			"status": "active",
            			"name": "Food & Beverage",
            			"is_brand": true,
            			"is_influencer": true,
            			"updated_at": "2021-07-07T14:23:36.778341Z",
            			"created_at": "2021-07-07T14:23:36.778446Z"
            		},
            		"sub_category": {
            			"id": 53,
            			"status": "active",
            			"name": "Hindu temple",
            			"is_brand": true,
            			"is_influencer": false,
            			"updated_at": "2022-01-18T11:04:58.497271Z",
            			"created_at": "2022-01-18T11:04:58.497317Z",
            			"category": 5
            		},
            		"size": {
            			"id": 1,
            			"status": "active",
            			"name": "Multinational",
            			"updated_at": "2021-10-11T12:41:53Z",
            			"created_at": "2021-10-11T12:41:53Z"
            		},
            		"strategy_focus": {
            			"id": 4,
            			"status": "active",
            			"name": "Omnipresent",
            			"description": "Lorem ipsum",
            			"updated_at": "2021-10-11T12:46:42Z",
            			"created_at": "2021-10-11T12:46:42Z"
            		}
            	}
            )
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getBrandsDemo(project_id, data) {
    let url = this.arrangeBrandUrl(
      this.api + `brand-radar-demo/search?project_id=${project_id}&`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  checkHealth() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };

    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `health`, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getUsers() {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `clients/list`, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getUserProjects(user) {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `settings/projects/search?client_id=${user}`, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getAllProjects() {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + "settings/projects/list", this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  createProject(data) {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.post(this.api + "settings/projects", data, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getAllTowns() {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + "settings/towns", this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getProjectSections(project) {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `settings/features/search?project_id=${project}`, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  updateSettings(user_id, data) {
    let postData = data
    let promise = new Promise((resolve, reject) => {
      const req = this.http.put(this.api + `settings/update/${user_id}`, postData, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getDistricts() {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `engagement/districts/list`, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getTypes() {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `engagement/types/list`, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getEventTypes() {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `engagement/event-types/list`, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getTiers() {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `engagement/tiers/list`, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getEngagementCategories(type) {
    let url = "";
    if (type) {
      url = this.api + `engagement/sub-categories?type=${type}`
    } else {
      url = this.api + `engagement/sub-categories`
    }
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  arrangeEngagementUrl(base_url, data) {
    let postData = data
    let url = base_url
    // districts
    if (postData.hasOwnProperty("district")) {
      if (postData["district"].length) {
        url = url.concat("&district=" + postData["district"].toString())
      }
    }
    // type
    if (postData.hasOwnProperty("type")) {
      if (postData["type"].length) {
        url = url.concat("&types=" + postData["type"].toString())
      }
    }
    // categories
    if (postData.hasOwnProperty("categories")) {
      if (postData["categories"].length) {
        url = url.concat("&categories=" + postData["categories"].toString())
      }
    }
    // sub-categories
    if (postData.hasOwnProperty("sub-categories")) {
      if (postData["sub-categories"].length) {
        url = url.concat("&sub-categories=" + postData["sub-categories"].toString())
      }
    }
    // follower-number
    if (postData.hasOwnProperty("follower-number")) {
      if (postData["follower-number"].length) {
        url = url.concat("&followers=" + postData["follower-number"].toString())
      }
    }
    // event-type
    if (postData.hasOwnProperty("event-type")) {
      if (postData["event-type"].length) {
        url = url.concat("&event-types=" + postData["event-type"].toString())
      }
    }
    // event-type
    if (postData.hasOwnProperty("tier")) {
      if (postData["tier"].length) {
        url = url.concat("&tiers=" + postData["tier"].toString())
      }
    }
    // limit
    if (postData.hasOwnProperty("limit")) {
      url = url.concat("&limit=" + postData["limit"])
    }
    // search
    if (postData.hasOwnProperty("search")) {
      url = url.concat("&search=" + postData["search"])
    } else {
      // offset
      if (postData.hasOwnProperty("offset")) {
        url = url.concat("&offset=" + postData["offset"])
      }
    }
    return url
  }

  getEngagementActivities(project_id, data) {
    let url = this.arrangeEngagementUrl(this.api + `engagement/activities/search?project_id=${project_id}`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getArchitectureReferences() {
    let promise = new Promise((resolve, reject) => {
      let url = this.api + `architecture/references/list`
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getArchitectureFilters(filter, type) {
    let promise = new Promise((resolve, reject) => {
      let url = "";
      if ((filter == "materials") || (filter == "lights") || (filter == "types")) {
        url = this.api + `architecture/${filter}/search?type=${type}`
      } else {
        url = this.api + `architecture/${filter}/list`
      }
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  arrangeArchitectureUrl(base_url, data) {
    let postData = data
    let url = base_url
    // personas
    if (postData.hasOwnProperty("personas")) {
      if (postData["personas"].length) {
        url = url.concat("&personas=" + postData["personas"].toString())
      }
    }
    // scales
    if (postData.hasOwnProperty("scales")) {
      if (postData["scales"].length) {
        url = url.concat("&scales=" + postData["scales"].toString())
      }
    }
    // materials
    if (postData.hasOwnProperty("materials")) {
      if (postData["materials"].length) {
        url = url.concat("&materials=" + postData["materials"].toString())
      }
    }
    // transitions
    if (postData.hasOwnProperty("transitions")) {
      if (postData["transitions"].length) {
        url = url.concat("&transitions=" + postData["transitions"].toString())
      }
    }
    // lights
    if (postData.hasOwnProperty("lights")) {
      if (postData["lights"].length) {
        url = url.concat("&lights=" + postData["lights"].toString())
      }
    }
    // structures
    if (postData.hasOwnProperty("structures")) {
      if (postData["structures"].length) {
        url = url.concat("&structures=" + postData["structures"].toString())
      }
    }
    // facades
    if (postData.hasOwnProperty("facades")) {
      if (postData["facades"].length) {
        url = url.concat("&facades=" + postData["facades"].toString())
      }
    }
    // colors
    if (postData.hasOwnProperty("colors")) {
      if (postData["colors"].length) {
        url = url.concat("&colors=" + postData["colors"].toString())
      }
    }
    // types
    if (postData.hasOwnProperty("types")) {
      if (postData["types"].length) {
        url = url.concat("&types=" + postData["types"].toString())
      }
    }
    return url
  }

  getArchitectureBuilgings(project_id, data) {
    let url = this.arrangeArchitectureUrl(this.api + `architecture/buildings/search?project_id=${project_id}`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getArchitectureInteriors(project_id, data) {
    let url = this.arrangeArchitectureUrl(this.api + `architecture/interiors/search?project_id=${project_id}`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getArchitectureFurnitures(project_id, data) {
    let url = this.arrangeArchitectureUrl(this.api + `architecture/furnitures/search?project_id=${project_id}`, data)
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(url, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getBuilgingDetail(building_id) {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `architecture/buildings/${building_id}`, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getInteriorDetail(interior_id) {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `architecture/interiors/${interior_id}`, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getFurnitureDetail(furniture_id) {
    let promise = new Promise((resolve, reject) => {
      const req = this.http.get(this.api + `architecture/furnitures/${furniture_id}`, this.httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res)
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
}
