<section>
  <div class="mx-5 mt-3">
    <a id="navbar-brand-dark" class="navbar-brand-dark" href="/">
      <img src="../../assets/img/logo-dark.png" alt="">
    </a>
  </div>

  <div class="video-div d-flex justify-content-center mx-5 mt-3">
    <video src="{{ video_url }}" controls responsive></video>
  </div>
</section>
