<div class="container py-5">
  <h5 class="header-text text-uppercase m-2 mb-4 mt-5">Projects</h5>
  <div class="m-2 mb-4">
    <div class="row p-5">
      <div *ngFor="let project of projects" class="col-md-12 col-lg-4">
        <div class="card city-card cursor-pointer m-2 mb-4">
          <img *ngIf="project['image']" src="{{ project['image'] }}">
          <img *ngIf="!project['image']" src="../../../assets/img/no-image.jpg" style="object-fit: fill !important;">
          <div class="card-body text-center">
            <h5 class="card-title my-2">{{ project["name"] }}</h5>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="card city-card cursor-pointer d-flex justify-content-center align-items-center m-2 mb-4" data-bs-toggle="modal" data-bs-target="#projectModal" style="height: 92%">
          <div class="d-flex justify-content-center align-items-center">
            <i class="plus-icon fa fa-plus fa-5x"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Project Modal -->
<div class="modal fade" id="projectModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header p-3 mx-3 mt-3">
        <h5 class="modal-title text-uppercase">Create New Project</h5>
        <button type="button" class="btn-close btn-lg" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-5">
        <form class="projects-form mx-5" [formGroup]="projectsForm" (ngSubmit)="createProjects(projectsForm.value)">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td class="w-25">
                  <label class="required-field me-3 mt-2">Name:</label>
                </td>
                <td class="w-75">
                  <input type="text" class="form-control" formControlName="name" placeholder="Name">
                </td>
              </tr>
              <tr>
                <td class="w-25">
                  <label class="required-field me-3 mt-2">Slug:</label>
                </td>
                <td class="w-75">
                  <input type="text" class="form-control" formControlName="slug" placeholder="Slug">
                </td>
              </tr>
              <tr>
                <td class="w-25">
                  <label class="required-field me-3 mt-2">Users:</label>
                </td>
                <td class="w-75">
                  <select class="form-select" aria-label="multiple select example" formControlName="client_id" (change)="getUsers()"  placeholder="Name">
                    <option disabled>Select a user</option>
                    <option *ngFor="let user of users" value="{{ user['id'] }}">{{ user['user']['username'] }}</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td class="w-25">
                  <label class="required-field me-3 mt-2">Towns:</label>
                </td>
                <td class="w-75">
                  <select class="form-select" aria-label="multiple select example" formControlName="town" (change)="getAllTowns()">
                    <option disabled>Select a town</option>
                    <option *ngFor="let town of towns" value="{{ town['id'] }}">{{ town['name'] }}</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td class="w-25">
                  <label class="required-field me-3 mt-2">Types:</label>
                </td>
                <td class="w-75">
                  <select class="form-select" aria-label="multiple select example" formControlName="type">
                    <option disabled>Select a type</option>
                    <option *ngFor="let type of types" value="{{ type['id'] }}">{{ type['name'] }}</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td class="w-25">
                  <label class="required-field me-3 mt-2">Image:</label>
                </td>
                <td class="w-75">
                  <input type="text" class="form-control" formControlName="image" placeholder="Image link">
                </td>
              </tr>
              <tr>
                <td class="w-25">
                  <label class="required-field me-3 mt-2">Title:</label>
                </td>
                <td class="w-75">
                  <input type="text" class="form-control" formControlName="title" placeholder="Title">
                </td>
              </tr>
              <tr>
                <td class="w-25">
                  <label class="required-field me-3 mt-2">Subtitle:</label>
                </td>
                <td class="w-75">
                  <input type="text" class="form-control" formControlName="subtitle" placeholder="Subtitle">
                </td>
              </tr>
              <tr>
                <td class="w-25">
                  <label class="required-field me-3 mt-2">Tags:</label>
                </td>
                <td class="w-75">
                  <input type="text" class="form-control" formControlName="tag" placeholder="[Number of Businesses: 106] | [Active Consumers: 26,114]">
                </td>
              </tr>
              <tr>
                <td class="w-25">
                  <label class="required-field me-3 mt-2">Detail Image:</label>
                </td>
                <td class="w-75">
                  <input type="text" class="form-control" formControlName="detail_image" placeholder="Detail Image link">
                </td>
              </tr>
              <tr>
                <td colspan="2" class="w-75">
                  <div class="text-center">
                    <button type="submit" class="btn save-btn px-5 my-5">Save</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div class="modal-footer px-3">
      </div>
    </div>
  </div>
</div>
