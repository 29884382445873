<section>
  <div class="container py-5">
    <h5 class="header-text m-2 mb-4 text-uppercase">Website Health</h5>
    <table class="table table-bordered">
      <tbody>
        <tr *ngFor="let item of health;">
          <td>{{ item["connection"] }}</td>
          <td *ngIf="item['status']"><input type="checkbox" aria-label="Checkbox for following text input" checked="checked" disabled></td>
          <td *ngIf="!item['status']"><input type="checkbox" aria-label="Checkbox for following text input" disabled></td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
