import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';

import { NgxDnDModule } from '@swimlane/ngx-dnd';

import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-leasing',
  templateUrl: './leasing.component.html',
  styleUrls: ['./leasing.component.css']
})
export class LeasingComponent implements OnInit {
  selectedProjectId: Number;
  selectedBrandId: Number;
  selectedStrategyId: Number = 1;

  status = "On Agenda";
  statuses = [
    {"name": "On Agenda", "value":"on_agenda"},
    {"name": "Archive", "value":"archive"},
  ];
  isArchive: boolean = false;
  isStrategy: boolean = false;
  isNextBestFit: boolean = false;

  addCategoryButtonDisabled: boolean = true;
  bestFitButtonDisabled: boolean = true;

  modalNextButtonText = "Next";
  modalPreviousButtonText = "Back To Selection";

  filterButton = "btn-light light-btn";
  all_archive = "btn-light light-btn";
  deleted_archive = "btn-light light-btn";
  factsheet_archive = "btn-light light-btn";

  ageDataLabels = [];
  ageData = [];
  ageDataColors = [];
  ageOptions = {};

  personalIncomeDataLabels = [];
  personalIncomeData = [];
  personalIncomeDataColors = [];
  personalIncomeOptions = {};

  genderDataLabels = [];
  genderData = [];
  genderDataColors = [];
  genderOptions = {};

  occupations_ = [];
  all_occupations = [];
  isMoreOccupation: boolean = true;
  isScrollDivOccupation: boolean = false;

  like_and_interests = [];
  all_like_and_interests = [];
  isMoreLike: boolean = true;
  isScrollDivLike: boolean = false;

  categoryForm: FormGroup;
  searchTextCategory = "";
  searchedCategories = []
  selectedCategoryCount = 0;

  strategy = {};

  prospective_tenant_list = [];
  prospective_tenant_list_ready: boolean = false;

  prospective_tenant_detail = {};
  prospective_tenant_detail_ready: boolean = false;

  filterPostData = {};

  data = [
    {"id": 1, "name":"Aligned", "color":"gray-border", "info": 'When user prioritizes "Aligned" focus, (i) the tenant mix for the prospective list reflects the existing Catchment Area mix, (ii) Prospective Tenants with higher Social DNA match are ranked highest , (iii) the types of business identified will reflect those already within Catchment Area (if existing tenant are mainly multinational, multinational brands will score higher)'},
    {"id": 2, "name":"Inspirational", "color":"black-border", "info": 'When user prioritizes "Inspirational" focus, (i) Prospective Tenants with New Generation Retail approach are ranked higher, (ii) Prospective Tenants with no existing stores or stores that are far away are ranked higher'},
    {"id": 3, "name":"Experimental", "color":"red-border", "info": 'When user prioritizes "Experimental" focus, (i) Prospective Tenants with lower Social DNA are ranked higher, (ii) New Generation Retail is ranked higher, (iii) Local Champions are Ranked Higher, (iii)Tenant Mix could be anything (even electronics)'},
    {"id": 4, "name":"Omnipresent", "color":"light-red-border", "info": 'When user prioritizes "Omnipresent" focus, (i) Prospective Tenants with more followers are ranked higher, (ii) Prospective Tenants with higher Engagement Rates are ranked higher, (iii) Prospective Tenants wtih high Brand Affinity Scores are ranked higher'},
  ]

  categories = [
    {"id": 1, "name": "Leisure", "value": "12%", "selected": true},
    {"id": 2, "name": "Food & Beverage", "value": "32%", "selected": true},
    {"id": 3, "name": "Clothing & Footwear", "value": "8%", "selected": true},
    {"id": 4, "name": "Electronics", "value": "18%", "selected": false},
    {"id": 5, "name": "Other", "value": "42%", "selected": true},
    {"id": 6, "name": "Personal Goods", "value": "15%", "selected": false},
    {"id": 7, "name": "Convenience", "value": "10%", "selected": true},
    {"id": 8, "name": "Durable Goods", "value": "25%", "selected": true},
    {"id": 9, "name": "Luxury", "value": "35%", "selected": true},
    {"id": 10, "name": "House & Home", "value": "5%", "selected": true},
    {"id": 11, "name": "Discount", "value": "15%", "selected": true},
  ]

  bestfits = [
    {"id": 1, "name": "Food & Beverage", "value": "32%", "selected": true},
    {"id": 2, "name": "Clothing & Footwear", "value": "8%", "selected": true},
    {"id": 3, "name": "Electronics", "value": "18%", "selected": false},
    {"id": 4, "name": "Sports", "value": "18%", "selected": false},
  ]

  sizes = [
    {"id": 1, "name": "Multinational", "value": "12%", "selected": true},
    {"id": 2, "name": "National", "value": "32%", "selected": true},
    {"id": 3, "name": "Local Champions", "value": "8%", "selected": true},
    {"id": 4, "name": "Local Champions", "value": "8%", "selected": true},
    {"id": 5, "name": "Local Champions", "value": "8%", "selected": true},
  ]

  services = [
    {"id": 1, "name": "New Generation Retail", "value": "32%", "selected": true},
    {"id": 2, "name": "High Online Exposure", "value": "32%", "selected": true},
    {"id": 3, "name": "Low Online Exposure", "value": "8%", "selected": true},
  ]

  prices = [
    {"id": 1, "name": "£", "value": "32%", "selected": true},
    {"id": 2, "name": "££", "value": "32%", "selected": true},
    {"id": 3, "name": "£££", "value": "8%", "selected": true},
    {"id": 4, "name": "££££", "value": "8%", "selected": true},
  ]

  ageGroups = [
    {"id": 1, "name": "Millennials", "value": "32%", "selected": true},
    {"id": 2, "name": "Gen x", "value": "12%", "selected": true},
    {"id": 3, "name": "Gen Z", "value": "8%", "selected": true},
  ]

  incomeLevels = [
    {"id": 1, "name": "High Income", "value": "32%", "selected": true},
    {"id": 2, "name": "Upper Mid Income", "value": "12%", "selected": true},
    {"id": 3, "name": "Lowe Mid Income", "value": "8%", "selected": true},
    {"id": 4, "name": "Lowe Mid Income", "value": "8%", "selected": true},
  ]

  genders = [
    {"id": 1, "name": "Female", "value": "32%", "selected": true},
    {"id": 2, "name": "Male", "value": "12%", "selected": true},
    {"id": 3, "name": "Non-binary", "value": "8%", "selected": true},
  ]

  occupations = [
    {"id": 1, "name": "Creative", "value": "32%", "selected": true},
    {"id": 2, "name": "Health", "value": "24%", "selected": true},
    {"id": 3, "name": "Finance", "value": "8%", "selected": true},
    {"id": 4, "name": "Construction", "value": "8%", "selected": true},
    {"id": 5, "name": "Creative", "value": "32%", "selected": true},
    {"id": 6, "name": "Health", "value": "24%", "selected": true},
    {"id": 7, "name": "Finance", "value": "8%", "selected": true},
    {"id": 8, "name": "Construction", "value": "8%", "selected": true},
  ]

  lifestyleFocuses = [
    {"id": 1, "name": "Focus #1", "value": "32%", "selected": true},
    {"id": 2, "name": "Focus #2", "value": "24%", "selected": true},
    {"id": 3, "name": "Focus #3", "value": "8%", "selected": true},
    {"id": 4, "name": "Focus #4", "value": "8%", "selected": true},
  ]

  constructor(
    public fb: FormBuilder,
    private dataservice: DataService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.filterPostData["status"] = "on_agenda"
    this.isNextBestFit = false;
    let current_route = this.router.url
    let name = current_route.split("/")[1]
    this.dataservice.getProject(name).then((project: any)  => {
      this.selectedProjectId = project["id"]
      this.getStrategy()
    });
    this.all_occupations = []
    this.occupations_ = []
    this.all_like_and_interests = []
    this.like_and_interests = []

    this.categoryForm = this.fb.group({});
    let categoryGroup = {};
    this.categories.forEach(x=>{
      categoryGroup[x["id"]]=new FormControl('');
    })
    this.categoryForm = this.fb.group(categoryGroup);
  }

  changeType(status) {
    for (let s of this.statuses) {
      if (s["value"] == status) {
        this.status = s["name"];
      }
    }
    if (status == "on_agenda") {
      this.filterPostData["status"] = "on_agenda"
      this.isArchive = false;
    } else if (status == "archive") {
      this.filterPostData["status"] = "archive"
      this.isArchive = true;
      this.filterButton = "btn-light light-btn";
      this.all_archive = "btn-dark dark-btn";
      this.deleted_archive = "btn-light light-btn";
      this.factsheet_archive = "btn-light light-btn";
    }
    this.applyBrandFilters()
  }

  changeTab(tab) {
    if (tab == "tenant-category") {
      this.modalNextButtonText = "Next";
      this.modalPreviousButtonText = "Back To Selection";
    }
    else if (tab == "business-preference") {
      this.modalNextButtonText = "Next";
      this.modalPreviousButtonText = "Back To Tenant Category";
    }
    else if (tab == "target-audience") {
      this.modalNextButtonText = "Next";
      this.modalPreviousButtonText = "Back To Business Preference";
    }
    else if (tab == "strategy-focus") {
      this.modalNextButtonText = "Next";
      this.modalPreviousButtonText = "Back To Target Audience";
    }
    else if (tab == "summary") {
      this.modalNextButtonText = "Create";
      this.modalPreviousButtonText = "Back To Strategy Focus";
    }
  }

  goNext() {
    let index;
    if (this.modalPreviousButtonText == "Back To Selection") {
      index = 1;
      this.modalPreviousButtonText = "Back To Tenant Category"
      this.modalNextButtonText = "Next";
    }
    else if (this.modalPreviousButtonText == "Back To Tenant Category") {
      index = 2;
      this.modalPreviousButtonText = "Back To Business Preference"
      this.modalNextButtonText = "Next";
    }
    else if (this.modalPreviousButtonText == "Back To Business Preference") {
      index = 3;
      this.modalPreviousButtonText = "Back To Target Audience"
      this.modalNextButtonText = "Next";
    }
    else if (this.modalPreviousButtonText == "Back To Target Audience") {
      index = 4;
      this.modalPreviousButtonText = "Back To Strategy Focus"
      this.modalNextButtonText = "Create";
    }
    else if (this.modalPreviousButtonText == "Back To Strategy Focus") {
      index = 5;
      this.modalNextButtonText = "Create";
    }
    for (let item of [0,1,2,3,4]) {
      if (item == index) {
        let tab: HTMLElement = document.getElementsByClassName("modal-tab")[index] as HTMLElement
        tab.className = "modal-tab nav-link active  px-1";
        let content: HTMLElement = document.getElementsByClassName("modal-tab-content")[index] as HTMLElement
        content.className = "modal-tab-content tab-pane fade show active mx-3";
      } else {
        let tab: HTMLElement = document.getElementsByClassName("modal-tab")[item] as HTMLElement
        tab.className = "modal-tab nav-link px-1";
        let content: HTMLElement = document.getElementsByClassName("modal-tab-content")[item] as HTMLElement
        content.className = "modal-tab-content tab-pane fade mx-3";
      }
    }
  }

  goPrevious() {
    let index;
    if (this.modalPreviousButtonText == "Back To Selection") {
      index = 0;
      this.modalPreviousButtonText = "Back To Selection"
      this.modalNextButtonText = "Next";
    }
    else if (this.modalPreviousButtonText == "Back To Tenant Category") {
      index = 0;
      this.modalPreviousButtonText = "Back To Selection"
      this.modalNextButtonText = "Next";
    }
    else if (this.modalPreviousButtonText == "Back To Business Preference") {
      index = 1;
      this.modalPreviousButtonText = "Back To Tenant Category"
      this.modalNextButtonText = "Next";
    }
    else if (this.modalPreviousButtonText == "Back To Target Audience") {
      index = 2;
      this.modalPreviousButtonText = "Back To Business Preference"
      this.modalNextButtonText = "Next";
    }
    else if (this.modalPreviousButtonText == "Back To Strategy Focus") {
      index = 3;
      this.modalPreviousButtonText = "Back To Target Audience"
      this.modalNextButtonText = "Next";
    }
    else if (this.modalPreviousButtonText == "Summary") {
      index = 4;
      this.modalPreviousButtonText = "Back To Strategy Focus"
      this.modalNextButtonText = "Next";
    }
    for (let item of [0,1,2,3,4]) {
      if (item == index) {
        let tab: HTMLElement = document.getElementsByClassName("modal-tab")[index] as HTMLElement
        tab.className = "modal-tab nav-link active  px-1";
        let content: HTMLElement = document.getElementsByClassName("modal-tab-content")[index] as HTMLElement
        content.className = "modal-tab-content tab-pane fade show active mx-3";
      } else {
        let tab: HTMLElement = document.getElementsByClassName("modal-tab")[item] as HTMLElement
        tab.className = "modal-tab nav-link px-1";
        let content: HTMLElement = document.getElementsByClassName("modal-tab-content")[item] as HTMLElement
        content.className = "modal-tab-content tab-pane fade mx-3";
      }
    }
  }

  searchCategory() {
    this.searchedCategories = this.categories
  }

  categorySearchFilter() {
    this.selectedCategoryCount = 0;
    let form_values = this.categoryForm.value;
    for (let item of this.categories) {
      if (form_values[item["id"]] == true) {
        this.selectedCategoryCount = this.selectedCategoryCount + 1
      }
    }
    this.applyBrandFilters()
  }

  toogleCategory(categoryId) {
    for (let category of this.categories) {
      let index = this.categories.indexOf(category)
      if (category["id"] == categoryId) {
        if (category["selected"]) {
          this.categories[index] = {"id": category["id"], "name": category["name"], "value": category["value"], "selected": false}
        } else {
          this.categories[index] = {"id": category["id"], "name": category["name"], "value": category["value"], "selected": true }
        }
      }
    }
  }

  toogleSize(sizeId) {
    for (let size of this.sizes) {
      let index = this.sizes.indexOf(size)
      if (size["id"] == sizeId) {
        if (size["selected"]) {
          this.sizes[index] = {"id": size["id"], "name": size["name"], "value": size["value"], "selected": false}
        } else {
          this.sizes[index] = {"id": size["id"], "name": size["name"], "value": size["value"], "selected": true }
        }
      }
    }
  }

  toogleService(serviceId) {
    for (let service of this.services) {
      let index = this.services.indexOf(service)
      if (service["id"] == serviceId) {
        if (service["selected"]) {
          this.services[index] = {"id": service["id"], "name": service["name"], "value": service["value"], "selected": false}
        } else {
          this.services[index] = {"id": service["id"], "name": service["name"], "value": service["value"], "selected": true }
        }
      }
    }
  }

  tooglePrice(priceId) {
    for (let price of this.prices) {
      let index = this.prices.indexOf(price)
      if (price["id"] == priceId) {
        if (price["selected"]) {
          this.prices[index] = {"id": price["id"], "name": price["name"], "value": price["value"], "selected": false}
        } else {
          this.prices[index] = {"id": price["id"], "name": price["name"], "value": price["value"], "selected": true }
        }
      }
    }
  }

  toogleAgeGroup(ageGroupId) {
    for (let ageGroup of this.ageGroups) {
      let index = this.ageGroups.indexOf(ageGroup)
      if (ageGroup["id"] == ageGroupId) {
        if (ageGroup["selected"]) {
          this.ageGroups[index] = {"id": ageGroup["id"], "name": ageGroup["name"], "value": ageGroup["value"], "selected": false}
        } else {
          this.ageGroups[index] = {"id": ageGroup["id"], "name": ageGroup["name"], "value": ageGroup["value"], "selected": true }
        }
      }
    }
  }

  toogleIncomeLevel(incomeLevelId) {
    for (let incomeLevel of this.incomeLevels) {
      let index = this.incomeLevels.indexOf(incomeLevel)
      if (incomeLevel["id"] == incomeLevelId) {
        if (incomeLevel["selected"]) {
          this.incomeLevels[index] = {"id": incomeLevel["id"], "name": incomeLevel["name"], "value": incomeLevel["value"], "selected": false}
        } else {
          this.incomeLevels[index] = {"id": incomeLevel["id"], "name": incomeLevel["name"], "value": incomeLevel["value"], "selected": true }
        }
      }
    }
  }

  toogleGender(genderId) {
    for (let gender of this.genders) {
      let index = this.genders.indexOf(gender)
      if (gender["id"] == genderId) {
        if (gender["selected"]) {
          this.genders[index] = {"id": gender["id"], "name": gender["name"], "value": gender["value"], "selected": false}
        } else {
          this.genders[index] = {"id": gender["id"], "name": gender["name"], "value": gender["value"], "selected": true }
        }
      }
    }
  }

  toogleOccupation(occupationId) {
    for (let occupation of this.occupations) {
      let index = this.occupations.indexOf(occupation)
      if (occupation["id"] == occupationId) {
        if (occupation["selected"]) {
          this.occupations[index] = {"id": occupation["id"], "name": occupation["name"], "value": occupation["value"], "selected": false}
        } else {
          this.occupations[index] = {"id": occupation["id"], "name": occupation["name"], "value": occupation["value"], "selected": true }
        }
      }
    }
  }

  toogleLifestyleFocus(lifestyleFocusId) {
    for (let lifestyleFocus of this.lifestyleFocuses) {
      let index = this.lifestyleFocuses.indexOf(lifestyleFocus)
      if (lifestyleFocus["id"] == lifestyleFocusId) {
        if (lifestyleFocus["selected"]) {
          this.lifestyleFocuses[index] = {"id": lifestyleFocus["id"], "name": lifestyleFocus["name"], "value": lifestyleFocus["value"], "selected": false}
        } else {
          this.lifestyleFocuses[index] = {"id": lifestyleFocus["id"], "name": lifestyleFocus["name"], "value": lifestyleFocus["value"], "selected": true }
        }
      }
    }
  }

  createStrategy() {
    this.isStrategy = true;
    this.applyBrandFilters()
  }

  toggleFilterButton(type) {
    if (type == "filter") {
      this.filterButton = "btn-light light-btn";
      this.all_archive = "btn-light light-btn";
      this.deleted_archive = "btn-light light-btn";
      this.factsheet_archive = "btn-light light-btn";
    }
    else if (type == "all_archive") {
      this.filterButton = "btn-light light-btn";
      this.all_archive = "btn-dark dark-btn";
      this.deleted_archive = "btn-light light-btn";
      this.factsheet_archive = "btn-light light-btn";
    }
    else if (type == "deleted_archive") {
      this.filterButton = "btn-light light-btn";
      this.all_archive = "btn-light light-btn";
      this.deleted_archive = "btn-dark dark-btn";
      this.factsheet_archive = "btn-light light-btn";
    }
    else if (type == "factsheet_archive") {
      this.filterButton = "btn-light light-btn";
      this.all_archive = "btn-light light-btn";
      this.deleted_archive = "btn-light light-btn";
      this.factsheet_archive = "btn-dark dark-btn";
    }
  }

  addCategory(index) {
    let categoryCard: HTMLElement = document.getElementsByClassName("category-card")[index] as HTMLElement
    if (categoryCard.className.includes("category-card-border")) {
      categoryCard.className = "category-card d-flex justify-content-center align-items-center cursor-pointer p-3 mb-3"
    } else {
      categoryCard.className = "category-card category-card-border d-flex justify-content-center align-items-center cursor-pointer p-3 mb-3"
    }

    let boolList = [];
    for (let category in this.categories) {
      let categoryCard: HTMLElement = document.getElementsByClassName("category-card")[category] as HTMLElement
      if (categoryCard.className.includes("category-card-border")) {
        boolList.push(false)
      } else {
        boolList.push(true)
      }
    }
    if (boolList.includes(false)) {
      this.addCategoryButtonDisabled = false;
    } else {
      this.addCategoryButtonDisabled = true;
    }
  }

  addBestFit(index) {
    let categoryCard: HTMLElement = document.getElementsByClassName("category-card2")[index] as HTMLElement
    if (categoryCard.className.includes("category-card-border")) {
      categoryCard.className = "category-card2 d-flex justify-content-center align-items-center cursor-pointer p-3 mb-3"
    } else {
      categoryCard.className = "category-card2 category-card-border d-flex justify-content-center align-items-center cursor-pointer p-3 mb-3"
    }

    let boolList = [];
    for (let category in this.bestfits) {
      let categoryCard: HTMLElement = document.getElementsByClassName("category-card2")[category] as HTMLElement
      if (categoryCard.className.includes("category-card-border")) {
        boolList.push(false)
      } else {
        boolList.push(true)
      }
    }
    if (boolList.includes(false)) {
      this.bestFitButtonDisabled = false;
    } else {
      this.bestFitButtonDisabled = true;
    }
  }

  getAgeData() {
    this.ageDataLabels = [];
    this.ageData = [];
    for (let age of Object.keys(this.prospective_tenant_detail["audience"]["age"])) {
      this.ageDataLabels.push(age)
      this.ageData.push(this.prospective_tenant_detail["audience"]["age"][age])
    }

    this.ageDataColors = [
      { backgroundColor: '#ECB4AE' }
    ]

    this.ageOptions = {
      responsive: true,
      legend: { display: false },
      scales: {
        xAxes: [{
          ticks: { display: false, beginAtZero:true },
      }],
        yAxes: [{
          barPercentage : 0.6,
          gridLines: { display: false, drawBorder: false }
        }]
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
        callbacks: {
          label : function(tooltipItem, data) {
            var dataset = data["datasets"][0];
            var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
             return previousValue + currentValue;
            });
            var currentValue = dataset["data"][tooltipItem.index];
            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
            return " (" + percentage + "%)";
          }
        }
      }
    };
  }

  getPersonalIncomeData() {
    this.personalIncomeDataLabels = [];
    this.personalIncomeData = [];
    for (let pi of Object.keys(this.prospective_tenant_detail["audience"]["personal_income"])) {
      this.personalIncomeDataLabels.push(pi)
      this.personalIncomeData.push(this.prospective_tenant_detail["audience"]["personal_income"][pi])
    }

    this.personalIncomeDataColors = [
      { backgroundColor: '#ECB4AE' }
    ]

    this.personalIncomeOptions = {
      responsive: true,
      legend: { display: false },
      scales: {
        xAxes: [{
          ticks: { display: false, beginAtZero:true },
      }],
        yAxes: [{
          barPercentage : 0.6,
          gridLines: { display: false, drawBorder: false }
        }]
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
        callbacks: {
          label : function(tooltipItem, data) {
            var dataset = data["datasets"][0];
            var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
             return previousValue + currentValue;
            });
            var currentValue = dataset["data"][tooltipItem.index];
            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
            return " (" + percentage + "%)";
          }
        }
      }
    };
  }

  getGenderData() {
    this.genderDataLabels = [];
    this.genderData = [];
    for (let gender of Object.keys(this.prospective_tenant_detail["audience"]["gender"])) {
      this.genderDataLabels.push(gender)
      this.genderData.push(this.prospective_tenant_detail["audience"]["gender"][gender])
    }

    this.genderDataColors = [
      { backgroundColor: ['#ECB4AE', '#938E9E', '#271D3D'] }
    ]

    this.genderOptions = {
      responsive: true,
      legend: { display: false },
      scales: {
        xAxes: [{
          ticks: { display: false, beginAtZero:true },
      }],
        yAxes: [{
          barPercentage : 0.6,
          gridLines: { display: false, drawBorder: false }
        }]
      },
      tooltips: {
        xPadding: 10,
        yPadding: 10,
        bodyFontSize: 15,
        bodySpacing: 10,
        callbacks: {
          label : function(tooltipItem, data) {
            var dataset = data["datasets"][0];
            var total = dataset["data"].reduce(function(previousValue, currentValue, currentIndex, array) {
             return previousValue + currentValue;
            });
            var currentValue = dataset["data"][tooltipItem.index];
            var percentage = Math.floor(((currentValue/total) * 100)+0.5);
            return " (" + percentage + "%)";
          }
        }
      }
    };
  }

  moreOccupation() {
    this.isMoreOccupation = false;
    this.isScrollDivOccupation = true;
    this.occupations_ = this.all_occupations;
  }

  moreLike() {
    this.isMoreLike = false;
    this.isScrollDivLike = true;
    this.like_and_interests = this.all_like_and_interests;
  }

  applyBrandFilters() {
    this.filterPostData["group_by"] = "categories"
    // TODO: Will be dynamic (strategy_id)
    this.filterPostData["strategy_id"] = this.selectedStrategyId

    let categoryIds = []
    for (let i in this.categoryForm.value) {
      if (this.categoryForm.value[i] == true) {
        categoryIds.push(Number(i))
      }
    }
    this.filterPostData["categories"] = categoryIds
    this.getProspectiveTenanatList()
  }

  getStrategy() {
    this.dataservice.getStrategies(this.selectedProjectId).then((value: any[]) => {
      this.strategy = value;
      this.selectedStrategyId = this.strategy["id"];
      this.applyBrandFilters()
    })
  }

  getProspectiveTenanatList() {
    this.prospective_tenant_list_ready = false;
    this.dataservice.getProspectiveTenanatList(this.filterPostData).then((value: any[]) => {
      this.prospective_tenant_list = value;
      this.prospective_tenant_list_ready = true;
    })
  }

  getBrandColor(type) {
    let color_mapping = {
      "Inspirational": ["gray-border", "gray-background"],
      "Experimental": ["red-border", "red-background"],
      "Aligned": ["black-border", "black-background"],
      "Omnipresent": ["light-red-border", "light-red-background"],
    }
    return color_mapping[type]
  }

  getProspectiveTenanatDetail(brand_id) {
    this.prospective_tenant_detail = {}
    this.prospective_tenant_detail_ready = false;
    this.dataservice.getProspectiveTenanatDetail(brand_id, this.selectedStrategyId).then((value: any[]) => {
      this.prospective_tenant_detail = value;
      this.getAgeData()
      this.getPersonalIncomeData()
      this.getGenderData()
      this.isMoreOccupation = true;
      this.isScrollDivOccupation = false;
      this.isMoreLike = true;
      this.isScrollDivLike = false;
      this.all_occupations = [];
      Object.entries(this.prospective_tenant_detail["audience"]["occupations"]).forEach(
        ([key, value]) => {
          this.all_occupations.push({
            "name": key,
            "value": value
          })
        }
      );
      this.occupations_ = this.all_occupations.slice(0,5)
      this.all_like_and_interests = [];
      Object.entries(this.prospective_tenant_detail["audience"]["likes_and_interests"]).forEach(
        ([key, value]) => {
          this.all_like_and_interests.push({
            "name": key,
            "value": value
          })
        }
      );
      this.like_and_interests = this.all_like_and_interests.slice(0,5)
      this.prospective_tenant_detail_ready = true;
    })
  }

  keepSelectedBrandId(brand_id) {
    this.selectedBrandId = brand_id;
  }

  updateBrandStatus(status) {
    let postData = {"status": status}
    this.dataservice.updateBrandStatus(this.selectedStrategyId, this.selectedBrandId, postData).then((value: any[]) => {
      this.applyBrandFilters()
      if (status == "archive") {
        this.isNextBestFit = true;
      }
    })
  }
}
