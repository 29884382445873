import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';

import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-architecture',
  templateUrl: './architecture.component.html',
  styleUrls: ['./architecture.component.css']
})
export class ArchitectureComponent implements OnInit {

  selectedProject: any;
  selectedProjectId: Number;

  references = [];

  buildings = [];
  buildingDetail = {};
  isBuildingDetail: boolean = false;
  building_rowLength = 0;

  interiors = [];
  interiorDetail = {};
  isInteriorDetail: boolean = false;
  interior_rowLength = 0;

  furnitures = [];
  furnitureDetail = {};
  isFurnitureDetail: boolean = false;
  furniture_rowLength = 0;

  selectedBuildingLocationCount = 0;
  selectedBuildingPersonaCount = 0;
  selectedBuildingScaleCount = 0;
  selectedBuildingMaterialCount = 0;
  selectedBuildingTransitionCount = 0;
  selectedBuildingLightCount = 0;
  selectedBuildingStructureCount = 0;
  selectedBuildingFacadeCount = 0;
  selectedBuildingColorCount = 0;
  selectedBuildingTypeCount = 0;

  selectedInteriorLocationCount = 0;
  selectedInteriorPersonaCount = 0;
  selectedInteriorScaleCount = 0;
  selectedInteriorMaterialCount = 0;
  selectedInteriorTransitionCount = 0;
  selectedInteriorLightCount = 0;
  selectedInteriorStructureCount = 0;
  selectedInteriorFacadeCount = 0;
  selectedInteriorColorCount = 0;
  selectedInteriorTypeCount = 0;

  selectedFurniturePersonaCount = 0;
  selectedFurnitureRoomCount = 0;
  selectedFurnitureMaterialCount = 0;
  selectedFurnitureColorCount = 0;
  selectedFurnitureTypeCount = 0;

  building_locations = [];
  building_personas = [];
  building_scales = [];
  building_materials = [];
  building_transitions = [];
  building_lights = [];
  building_structures = [];
  building_facades = [];
  building_colors = [];
  building_types = [];

  interior_locations = [];
  interior_personas = [];
  interior_scales = [];
  interior_materials = [];
  interior_transitions = [];
  interior_lights = [];
  interior_structures = [];
  interior_facades = [];
  interior_colors = [];
  interior_types = [];

  furniture_personas = [];
  furniture_rooms = [];
  furniture_materials = [];
  furniture_colors = [];
  furniture_types = [];

  buildingLocationForm: FormGroup;
  buildingPersonaForm: FormGroup;
  buildingScaleForm: FormGroup;
  buildingMaterialForm: FormGroup;
  buildingTransitionForm: FormGroup;
  buildingLightForm: FormGroup;
  buildingStructureForm: FormGroup;
  buildingFacadeForm: FormGroup;
  buildingColorForm: FormGroup;
  buildingTypeForm: FormGroup;

  interiorLocationForm: FormGroup;
  interiorPersonaForm: FormGroup;
  interiorScaleForm: FormGroup;
  interiorMaterialForm: FormGroup;
  interiorTransitionForm: FormGroup;
  interiorLightForm: FormGroup;
  interiorStructureForm: FormGroup;
  interiorFacadeForm: FormGroup;
  interiorColorForm: FormGroup;
  interiorTypeForm: FormGroup;

  furniturePersonaForm: FormGroup;
  furnitureRoomForm: FormGroup;
  furnitureMaterialForm: FormGroup;
  furnitureColorForm: FormGroup;
  furnitureTypeForm: FormGroup;

  filterBuildingPostData = {};
  filterInteriorPostData = {};
  filterFurniturePostData = {};

  isScrollDivKeywordsBuilding: boolean = false;
  isScrollDivKeywordsInterior: boolean = false;
  isScrollDivKeywordsFurniture: boolean = false;

  sliderIndexBuilding = 0;
  sliderIndexInterior = 0;
  sliderIndexFurniture= 0;

  constructor(
    public fb: FormBuilder,
    private dataservice: DataService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.buildingLocationForm = this.fb.group({});
    this.buildingPersonaForm = this.fb.group({});
    this.buildingScaleForm = this.fb.group({});
    this.buildingMaterialForm = this.fb.group({});
    this.buildingTransitionForm = this.fb.group({});
    this.buildingLightForm = this.fb.group({});
    this.buildingStructureForm = this.fb.group({});
    this.buildingFacadeForm = this.fb.group({});
    this.buildingColorForm = this.fb.group({});
    this.buildingTypeForm = this.fb.group({});

    this.interiorLocationForm = this.fb.group({});
    this.interiorPersonaForm = this.fb.group({});
    this.interiorScaleForm = this.fb.group({});
    this.interiorMaterialForm = this.fb.group({});
    this.interiorTransitionForm = this.fb.group({});
    this.interiorLightForm = this.fb.group({});
    this.interiorStructureForm = this.fb.group({});
    this.interiorFacadeForm = this.fb.group({});
    this.interiorColorForm = this.fb.group({});
    this.interiorTypeForm = this.fb.group({});

    this.furniturePersonaForm = this.fb.group({});
    this.furnitureRoomForm = this.fb.group({});
    this.furnitureMaterialForm = this.fb.group({});
    this.furnitureColorForm = this.fb.group({});
    this.furnitureTypeForm = this.fb.group({});

    let current_route = this.router.url
    let name = current_route.split("/")[1]
    this.dataservice.getProject(name).then((project: any)  => {
      this.selectedProject = project;
      this.selectedProjectId = this.selectedProject["id"]
      this.applyBuildingFilters("", true)
      this.applyInterierFilters("", true)
      this.applyFurnitureFilters("", true)
    })

    this.getReferences()
    // this.getLocations("building")
    this.getPersonas("building")
    this.getScales("building")
    this.getMaterials("building")
    this.getTransitions("building")
    this.getLights("building")
    this.getStructures("building")
    this.getFacades("building")
    this.getColors("building")
    this.getTypes("building")

    this.getPersonas("interior")
    this.getScales("interior")
    this.getMaterials("interior")
    this.getTransitions("interior")
    this.getLights("interior")
    this.getStructures("interior")
    this.getFacades("interior")
    this.getColors("interior")
    this.getTypes("interior")

    this.getPersonas("furniture")
    this.getRooms("furniture")
    this.getMaterials("furniture")
    this.getColors("furniture")
    this.getTypes("furniture")
  }

  getReferences() {
    this.dataservice.getArchitectureReferences().then((value: any[]) => {
      this.references = value["images"];
    })
  }

  getLocations(type) {
    // this.dataservice.getArchitectureLocations("locations", type).then((value: any[]) => {
    //   this.locations = value;
    // })
  }

  getPersonas(type) {
    this.dataservice.getArchitectureFilters("personas", type).then((value: any[]) => {
      if (type == "building") {
        this.building_personas = value;
        let personasGroup = {};
        this.building_personas.forEach(x=>{
          personasGroup[x["id"]]=new FormControl('');
        })
        this.buildingPersonaForm = this.fb.group(personasGroup);
      }
      else if (type == "interior") {
        this.interior_personas = value;
        let personasGroup = {};
        this.interior_personas.forEach(x=>{
          personasGroup[x["id"]]=new FormControl('');
        })
        this.interiorPersonaForm = this.fb.group(personasGroup);
      }
      else if (type == "furniture") {
        this.furniture_personas = value;
        let personasGroup = {};
        this.furniture_personas.forEach(x=>{
          personasGroup[x["id"]]=new FormControl('');
        })
        this.furniturePersonaForm = this.fb.group(personasGroup);
      }
    })
  }

  getScales(type) {
    this.dataservice.getArchitectureFilters("scales", type).then((value: any[]) => {
      if (type == "building") {
        this.building_scales = value;
        let scalesGroup = {};
        this.building_scales.forEach(x=>{
          scalesGroup[x["id"]]=new FormControl('');
        })
        this.buildingScaleForm = this.fb.group(scalesGroup);
      }
      else if (type == "interior") {
        this.interior_scales = value;
        let scalesGroup = {};
        this.interior_scales.forEach(x=>{
          scalesGroup[x["id"]]=new FormControl('');
        })
        this.interiorScaleForm = this.fb.group(scalesGroup);
      }
    })
  }

  getMaterials(type) {
    this.dataservice.getArchitectureFilters("materials", type).then((value: any[]) => {
      if (type == "building") {
        this.building_materials = value;
        let materialsGroup = {};
        this.building_materials.forEach(x=>{
          materialsGroup[x["id"]]=new FormControl('');
        })
        this.buildingMaterialForm = this.fb.group(materialsGroup);
      }
      else if (type == "interior") {
        this.interior_materials = value;
        let materialsGroup = {};
        this.interior_materials.forEach(x=>{
          materialsGroup[x["id"]]=new FormControl('');
        })
        this.interiorMaterialForm = this.fb.group(materialsGroup);
      }
      else if (type == "furniture") {
        this.furniture_materials = value;
        let materialsGroup = {};
        this.furniture_materials.forEach(x=>{
          materialsGroup[x["id"]]=new FormControl('');
        })
        this.furnitureMaterialForm = this.fb.group(materialsGroup);
      }
    })
  }

  getTransitions(type) {
    this.dataservice.getArchitectureFilters("transitions", type).then((value: any[]) => {
      if (type == "building") {
        this.building_transitions = value;
        let transitionsGroup = {};
        this.building_transitions.forEach(x=>{
          transitionsGroup[x["id"]]=new FormControl('');
        })
        this.buildingTransitionForm = this.fb.group(transitionsGroup);
      }
      else if (type == "interior") {
        this.interior_transitions = value;
        let transitionsGroup = {};
        this.interior_transitions.forEach(x=>{
          transitionsGroup[x["id"]]=new FormControl('');
        })
        this.interiorTransitionForm = this.fb.group(transitionsGroup);
      }
    })
  }

  getLights(type) {
    this.dataservice.getArchitectureFilters("lights", type).then((value: any[]) => {
      if (type == "building") {
        this.building_lights = value;
        let lightsGroup = {};
        this.building_lights.forEach(x=>{
          lightsGroup[x["id"]]=new FormControl('');
        })
        this.buildingLightForm = this.fb.group(lightsGroup);
      }
      else if (type == "interior") {
        this.interior_lights = value;
        let lightsGroup = {};
        this.interior_lights.forEach(x=>{
          lightsGroup[x["id"]]=new FormControl('');
        })
        this.interiorLightForm = this.fb.group(lightsGroup);
      }
    })
  }

  getStructures(type) {
    this.dataservice.getArchitectureFilters("structures", type).then((value: any[]) => {
      if (type == "building") {
        this.building_structures = value;
        let structuresGroup = {};
        this.building_structures.forEach(x=>{
          structuresGroup[x["id"]]=new FormControl('');
        })
        this.buildingStructureForm = this.fb.group(structuresGroup);
      }
      else if (type == "interior") {
        this.interior_structures = value;
        let structuresGroup = {};
        this.interior_structures.forEach(x=>{
          structuresGroup[x["id"]]=new FormControl('');
        })
        this.interiorStructureForm = this.fb.group(structuresGroup);
      }
    })
  }

  getFacades(type) {
    this.dataservice.getArchitectureFilters("facades", type).then((value: any[]) => {
      if (type == "building") {
        this.building_facades = value;
        let facadesGroup = {};
        this.building_facades.forEach(x=>{
          facadesGroup[x["id"]]=new FormControl('');
        })
        this.buildingFacadeForm = this.fb.group(facadesGroup);
      }
      else if (type == "interior") {
        this.interior_facades = value;
        let facadesGroup = {};
        this.interior_facades.forEach(x=>{
          facadesGroup[x["id"]]=new FormControl('');
        })
        this.interiorFacadeForm = this.fb.group(facadesGroup);
      }
    })
  }

  getColors(type) {
    this.dataservice.getArchitectureFilters("colors", type).then((value: any[]) => {
      if (type == "building") {
        this.building_colors = value;
        let colorsGroup = {};
        this.building_colors.forEach(x=>{
          colorsGroup[x["id"]]=new FormControl('');
        })
        this.buildingColorForm = this.fb.group(colorsGroup);
      }
      else if (type == "interior") {
        this.interior_colors = value;
        let colorsGroup = {};
        this.interior_colors.forEach(x=>{
          colorsGroup[x["id"]]=new FormControl('');
        })
        this.interiorColorForm = this.fb.group(colorsGroup);
      }
      else if (type == "furniture") {
        this.furniture_colors = value;
        let colorsGroup = {};
        this.furniture_colors.forEach(x=>{
          colorsGroup[x["id"]]=new FormControl('');
        })
        this.furnitureColorForm = this.fb.group(colorsGroup);
      }
    })
  }

  getTypes(type) {
    this.dataservice.getArchitectureFilters("types", type).then((value: any[]) => {
      if (type == "building") {
        this.building_types = value;
        let typesGroup = {};
        this.building_types.forEach(x=>{
          typesGroup[x["id"]]=new FormControl('');
        })
        this.buildingTypeForm = this.fb.group(typesGroup);
      }
      else if (type == "interior") {
        this.interior_types = value;
        let typesGroup = {};
        this.interior_types.forEach(x=>{
          typesGroup[x["id"]]=new FormControl('');
        })
        this.interiorTypeForm = this.fb.group(typesGroup);
      }
      else if (type == "furniture") {
        this.furniture_types = value;
        let typesGroup = {};
        this.furniture_types.forEach(x=>{
          typesGroup[x["id"]]=new FormControl('');
        })
        this.furnitureTypeForm = this.fb.group(typesGroup);
      }
    })
  }

  getRooms(type) {
    this.dataservice.getArchitectureFilters("rooms", type).then((value: any[]) => {
      if (type == "furniture") {
        this.furniture_rooms = value;
        let roomsGroup = {};
        this.furniture_rooms.forEach(x=>{
          roomsGroup[x["id"]]=new FormControl('');
        })
        this.furnitureRoomForm = this.fb.group(roomsGroup);
      }
    })
  }

  applyBuildingFilters(type, onInıt) {
    if (type == "location") {
      let postData = []
      for (let i in this.buildingLocationForm.value) {
        if (this.buildingLocationForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterBuildingPostData["locations"] = postData
      this.selectedBuildingLocationCount = 0;
      let form_values = this.buildingLocationForm.value;
      for (let item of this.building_locations) {
        if (form_values[item["id"]] == true) {
          this.selectedBuildingLocationCount = this.selectedBuildingLocationCount + 1
        }
      }
    }
    else if (type == "persona") {
      let postData = []
      for (let i in this.buildingPersonaForm.value) {
        if (this.buildingPersonaForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterBuildingPostData["personas"] = postData
      this.selectedBuildingPersonaCount = 0;
      let form_values = this.buildingPersonaForm.value;
      for (let item of this.building_personas) {
        if (form_values[item["id"]] == true) {
          this.selectedBuildingPersonaCount = this.selectedBuildingPersonaCount + 1
        }
      }
    }
    else if (type == "scale") {
      let postData = []
      for (let i in this.buildingScaleForm.value) {
        if (this.buildingScaleForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterBuildingPostData["scales"] = postData
      this.selectedBuildingScaleCount = 0;
      let form_values = this.buildingScaleForm.value;
      for (let item of this.building_scales) {
        if (form_values[item["id"]] == true) {
          this.selectedBuildingScaleCount = this.selectedBuildingScaleCount + 1
        }
      }
    }
    else if (type == "material") {
      let postData = []
      for (let i in this.buildingMaterialForm.value) {
        if (this.buildingMaterialForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterBuildingPostData["materials"] = postData
      this.selectedBuildingMaterialCount = 0;
      let form_values = this.buildingMaterialForm.value;
      for (let item of this.building_materials) {
        if (form_values[item["id"]] == true) {
          this.selectedBuildingMaterialCount = this.selectedBuildingMaterialCount + 1
        }
      }
    }
    else if (type == "transition") {
      let postData = []
      for (let i in this.buildingTransitionForm.value) {
        if (this.buildingTransitionForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterBuildingPostData["transitions"] = postData
      this.selectedBuildingTransitionCount = 0;
      let form_values = this.buildingTransitionForm.value;
      for (let item of this.building_transitions) {
        if (form_values[item["id"]] == true) {
          this.selectedBuildingTransitionCount = this.selectedBuildingTransitionCount + 1
        }
      }
    }
    else if (type == "light") {
      let postData = []
      for (let i in this.buildingLightForm.value) {
        if (this.buildingLightForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterBuildingPostData["lights"] = postData
      this.selectedBuildingLightCount = 0;
      let form_values = this.buildingLightForm.value;
      for (let item of this.building_lights) {
        if (form_values[item["id"]] == true) {
          this.selectedBuildingLightCount = this.selectedBuildingLightCount + 1
        }
      }
    }
    else if (type == "structure") {
      let postData = []
      for (let i in this.buildingStructureForm.value) {
        if (this.buildingStructureForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterBuildingPostData["structures"] = postData
      this.selectedBuildingStructureCount = 0;
      let form_values = this.buildingStructureForm.value;
      for (let item of this.building_structures) {
        if (form_values[item["id"]] == true) {
          this.selectedBuildingStructureCount = this.selectedBuildingStructureCount + 1
        }
      }
    }
    else if (type == "facade") {
      let postData = []
      for (let i in this.buildingFacadeForm.value) {
        if (this.buildingFacadeForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterBuildingPostData["facades"] = postData
      this.selectedBuildingFacadeCount = 0;
      let form_values = this.buildingFacadeForm.value;
      for (let item of this.building_facades) {
        if (form_values[item["id"]] == true) {
          this.selectedBuildingFacadeCount = this.selectedBuildingFacadeCount + 1
        }
      }
    }
    else if (type == "color") {
      let postData = []
      for (let i in this.buildingColorForm.value) {
        if (this.buildingColorForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterBuildingPostData["colors"] = postData
      this.selectedBuildingColorCount = 0;
      let form_values = this.buildingColorForm.value;
      for (let item of this.building_colors) {
        if (form_values[item["id"]] == true) {
          this.selectedBuildingColorCount = this.selectedBuildingColorCount + 1
        }
      }
    }
    else if (type == "type") {
      let postData = []
      for (let i in this.buildingTypeForm.value) {
        if (this.buildingTypeForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterBuildingPostData["types"] = postData
      this.selectedBuildingTypeCount = 0;
      let form_values = this.buildingTypeForm.value;
      for (let item of this.building_types) {
        if (form_values[item["id"]] == true) {
          this.selectedBuildingTypeCount = this.selectedBuildingTypeCount + 1
        }
      }
    }

    this.buildings = [];
    this.dataservice.getArchitectureBuilgings(this.selectedProjectId, this.filterBuildingPostData).then((value: any[]) => {
      this.buildings = value;
      this.building_rowLength = this.calculateBuildingRow()
      this.buildingDetail = {};
      this.isBuildingDetail = false;
      this.getBuilgingDetail(this.buildings[0]["id"])
    })
  }

  applyInterierFilters(type, onInıt) {
    if (type == "location") {
      let postData = []
      for (let i in this.interiorLocationForm.value) {
        if (this.interiorLocationForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterInteriorPostData["locations"] = postData
      this.selectedInteriorLocationCount = 0;
      let form_values = this.interiorLocationForm.value;
      for (let item of this.interior_locations) {
        if (form_values[item["id"]] == true) {
          this.selectedInteriorLocationCount = this.selectedInteriorLocationCount + 1
        }
      }
    }
    else if (type == "persona") {
      let postData = []
      for (let i in this.interiorPersonaForm.value) {
        if (this.interiorPersonaForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterInteriorPostData["personas"] = postData
      this.selectedInteriorPersonaCount = 0;
      let form_values = this.interiorPersonaForm.value;
      for (let item of this.interior_personas) {
        if (form_values[item["id"]] == true) {
          this.selectedInteriorPersonaCount = this.selectedInteriorPersonaCount + 1
        }
      }
    }
    else if (type == "scale") {
      let postData = []
      for (let i in this.interiorScaleForm.value) {
        if (this.interiorScaleForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterInteriorPostData["scales"] = postData
      this.selectedInteriorScaleCount = 0;
      let form_values = this.interiorScaleForm.value;
      for (let item of this.interior_scales) {
        if (form_values[item["id"]] == true) {
          this.selectedInteriorScaleCount = this.selectedInteriorScaleCount + 1
        }
      }
    }
    else if (type == "material") {
      let postData = []
      for (let i in this.interiorMaterialForm.value) {
        if (this.interiorMaterialForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterInteriorPostData["materials"] = postData
      this.selectedInteriorMaterialCount = 0;
      let form_values = this.interiorMaterialForm.value;
      for (let item of this.interior_materials) {
        if (form_values[item["id"]] == true) {
          this.selectedInteriorMaterialCount = this.selectedInteriorMaterialCount + 1
        }
      }
    }
    else if (type == "transition") {
      let postData = []
      for (let i in this.interiorTransitionForm.value) {
        if (this.interiorTransitionForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterInteriorPostData["transitions"] = postData
      this.selectedInteriorTransitionCount = 0;
      let form_values = this.interiorTransitionForm.value;
      for (let item of this.interior_transitions) {
        if (form_values[item["id"]] == true) {
          this.selectedInteriorTransitionCount = this.selectedInteriorTransitionCount + 1
        }
      }
    }
    else if (type == "light") {
      let postData = []
      for (let i in this.interiorLightForm.value) {
        if (this.interiorLightForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterInteriorPostData["lights"] = postData
      this.selectedInteriorLightCount = 0;
      let form_values = this.interiorLightForm.value;
      for (let item of this.interior_lights) {
        if (form_values[item["id"]] == true) {
          this.selectedInteriorLightCount = this.selectedInteriorLightCount + 1
        }
      }
    }
    else if (type == "structure") {
      let postData = []
      for (let i in this.interiorStructureForm.value) {
        if (this.interiorStructureForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterInteriorPostData["structures"] = postData
      this.selectedInteriorStructureCount = 0;
      let form_values = this.interiorStructureForm.value;
      for (let item of this.interior_structures) {
        if (form_values[item["id"]] == true) {
          this.selectedInteriorStructureCount = this.selectedInteriorStructureCount + 1
        }
      }
    }
    else if (type == "facade") {
      let postData = []
      for (let i in this.interiorFacadeForm.value) {
        if (this.interiorFacadeForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterInteriorPostData["facades"] = postData
      this.selectedInteriorFacadeCount = 0;
      let form_values = this.interiorFacadeForm.value;
      for (let item of this.interior_facades) {
        if (form_values[item["id"]] == true) {
          this.selectedInteriorFacadeCount = this.selectedInteriorFacadeCount + 1
        }
      }
    }
    else if (type == "color") {
      let postData = []
      for (let i in this.interiorColorForm.value) {
        if (this.interiorColorForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterInteriorPostData["colors"] = postData
      this.selectedInteriorColorCount = 0;
      let form_values = this.interiorColorForm.value;
      for (let item of this.interior_colors) {
        if (form_values[item["id"]] == true) {
          this.selectedInteriorColorCount = this.selectedInteriorColorCount + 1
        }
      }
    }
    else if (type == "type") {
      let postData = []
      for (let i in this.interiorTypeForm.value) {
        if (this.interiorTypeForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterInteriorPostData["types"] = postData
      this.selectedInteriorTypeCount = 0;
      let form_values = this.interiorTypeForm.value;
      for (let item of this.interior_types) {
        if (form_values[item["id"]] == true) {
          this.selectedInteriorTypeCount = this.selectedInteriorTypeCount + 1
        }
      }
    }

    this.interiors = [];
    this.dataservice.getArchitectureInteriors(this.selectedProjectId, this.filterInteriorPostData).then((value: any[]) => {
      this.interiors = value;
      this.interior_rowLength = this.calculateInteriorRow()
      this.interiorDetail = {};
      this.isInteriorDetail = false;
      this.getInteriorDetail(this.interiors[0]["id"])
    })
  }

  applyFurnitureFilters(type, onInıt) {
    if (type == "persona") {
      let postData = []
      for (let i in this.furniturePersonaForm.value) {
        if (this.furniturePersonaForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterFurniturePostData["personas"] = postData
      this.selectedFurniturePersonaCount = 0;
      let form_values = this.furniturePersonaForm.value;
      for (let item of this.furniture_personas) {
        if (form_values[item["id"]] == true) {
          this.selectedFurniturePersonaCount = this.selectedFurniturePersonaCount + 1
        }
      }
    }
    else if (type == "room") {
      let postData = []
      for (let i in this.furnitureRoomForm.value) {
        if (this.furnitureRoomForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterFurniturePostData["rooms"] = postData
      this.selectedFurnitureRoomCount = 0;
      let form_values = this.furnitureRoomForm.value;
      for (let item of this.furniture_rooms) {
        if (form_values[item["id"]] == true) {
          this.selectedFurnitureRoomCount = this.selectedFurnitureRoomCount + 1
        }
      }
    }
    else if (type == "material") {
      let postData = []
      for (let i in this.furnitureMaterialForm.value) {
        if (this.furnitureMaterialForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterFurniturePostData["materials"] = postData
      this.selectedFurnitureMaterialCount = 0;
      let form_values = this.furnitureMaterialForm.value;
      for (let item of this.furniture_materials) {
        if (form_values[item["id"]] == true) {
          this.selectedFurnitureMaterialCount = this.selectedFurnitureMaterialCount + 1
        }
      }
    }
    else if (type == "color") {
      let postData = []
      for (let i in this.furnitureColorForm.value) {
        if (this.furnitureColorForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterFurniturePostData["colors"] = postData
      this.selectedFurnitureColorCount = 0;
      let form_values = this.furnitureColorForm.value;
      for (let item of this.furniture_colors) {
        if (form_values[item["id"]] == true) {
          this.selectedFurnitureColorCount = this.selectedFurnitureColorCount + 1
        }
      }
    }
    else if (type == "type") {
      let postData = []
      for (let i in this.furnitureTypeForm.value) {
        if (this.furnitureTypeForm.value[i] == true) {
          postData.push(Number(i))
        }
      }
      this.filterFurniturePostData["types"] = postData
      this.selectedFurnitureTypeCount = 0;
      let form_values = this.furnitureTypeForm.value;
      for (let item of this.furniture_types) {
        if (form_values[item["id"]] == true) {
          this.selectedFurnitureTypeCount = this.selectedFurnitureTypeCount + 1
        }
      }
    }

    this.furnitures = [];
    this.dataservice.getArchitectureFurnitures(this.selectedProjectId, this.filterFurniturePostData).then((value: any[]) => {
      this.furnitures = value;
      this.furniture_rowLength = this.calculateFurnitureRow()
      this.furnitureDetail = {};
      this.isFurnitureDetail = false;
      this.getFurnitureDetail(this.furnitures[0]["id"])
    })
  }

  getBuilgingDetail(id) {
    this.buildingDetail = {};
    this.isBuildingDetail = false;
    this.dataservice.getBuilgingDetail(id).then((value: any) => {
      this.buildingDetail = value;
      this.isBuildingDetail = true;
      this.isScrollDivKeywordsBuilding = false;
    })
  }

  getInteriorDetail(id) {
    this.interiorDetail = {};
    this.isInteriorDetail = false;
    this.dataservice.getInteriorDetail(id).then((value: any) => {
      this.interiorDetail = value;
      this.isInteriorDetail = true;
      this.isScrollDivKeywordsInterior = false;
    })
  }

  getFurnitureDetail(id) {
    this.furnitureDetail = {};
    this.isFurnitureDetail = false;
    this.dataservice.getFurnitureDetail(id).then((value: any) => {
      this.furnitureDetail = value;
      this.isFurnitureDetail = true;
      this.isScrollDivKeywordsFurniture = false;
    })
  }

  calculateBuildingRow() {
    if (this.buildings.length < 9) {
      return this.buildings.length
    }
    if (this.buildings.length >= 9) {
      if ((this.buildings.length % 9) == 0) {
        return Math.ceil(this.buildings.length/9)
      }
      if ((this.buildings.length % 9) != 0) {
        return Math.ceil(this.buildings.length/9)
      }
    }
  }

  calculateInteriorRow() {
    if (this.interiors.length < 9) {
      return this.interiors.length
    }
    if (this.interiors.length >= 9) {
      if ((this.interiors.length % 9) == 0) {
        return Math.ceil(this.interiors.length/9)
      }
      if ((this.interiors.length % 9) != 0) {
        return Math.ceil(this.interiors.length/9)
      }
    }
  }

  calculateFurnitureRow() {
    if (this.furnitures.length < 9) {
      return this.furnitures.length
    }
    if (this.furnitures.length >= 9) {
      if ((this.furnitures.length % 9) == 0) {
        return Math.ceil(this.furnitures.length/9)
      }
      if ((this.furnitures.length % 9) != 0) {
        return Math.ceil(this.furnitures.length/9)
      }
    }
  }

  counter(i: number) {
    if (i>1) {
      return new Array(i-1);
    }
  }

  moreBuildingKeywords() {
    this.isScrollDivKeywordsBuilding = true;
  }

  moreInteriorKeywords() {
    this.isScrollDivKeywordsInterior = true;
  }

  moreFurnitureKeywords() {
    this.isScrollDivKeywordsInterior = true;
  }

  getSliderIndexBuilding(index) {
    this.sliderIndexBuilding = index;
    if (this.sliderIndexBuilding != 0) {
      try {
        let activeItem1: HTMLElement = document.getElementsByClassName("building active carousel-item")[0] as HTMLElement
        activeItem1.className = "building carousel-item";
        let activeItem2: HTMLElement = document.getElementsByClassName("building carousel-item active")[1] as HTMLElement
        activeItem2.className = "building carousel-item";
        let activeItem3: HTMLElement = document.getElementsByClassName("building carousel-item active")[2] as HTMLElement
        activeItem3.className = "building carousel-item";
        let activeItem4: HTMLElement = document.getElementsByClassName("carousel-item building active")[0] as HTMLElement
        activeItem4.className = "building carousel-item";
      } finally {
        return "";
      }
    }
  }

  getSliderIndexInterior(index) {
    this.sliderIndexInterior = index;
    if (this.sliderIndexInterior != 0) {
      try {
        let activeItem1: HTMLElement = document.getElementsByClassName("interior active carousel-item")[0] as HTMLElement
        activeItem1.className = "interior carousel-item";
        let activeItem2: HTMLElement = document.getElementsByClassName("interior carousel-item active")[1] as HTMLElement
        activeItem2.className = "interior carousel-item";
        let activeItem3: HTMLElement = document.getElementsByClassName("interior carousel-item active")[2] as HTMLElement
        activeItem3.className = "interior carousel-item";
        let activeItem4: HTMLElement = document.getElementsByClassName("carousel-item interior active")[0] as HTMLElement
        activeItem4.className = "interior carousel-item";
      } finally {
        return "";
      }
    }
  }

  getSliderIndexFurniture(index) {
    this.sliderIndexFurniture = index;
    if (this.sliderIndexFurniture != 0) {
      try {
        let activeItem1: HTMLElement = document.getElementsByClassName("furniture active carousel-item")[0] as HTMLElement
        activeItem1.className = "furniture carousel-item";
        let activeItem2: HTMLElement = document.getElementsByClassName("furniture carousel-item active")[1] as HTMLElement
        activeItem2.className = "furniture carousel-item";
        let activeItem3: HTMLElement = document.getElementsByClassName("furniture carousel-item active")[2] as HTMLElement
        activeItem3.className = "furniture carousel-item";
        let activeItem4: HTMLElement = document.getElementsByClassName("carousel-item furniture active")[0] as HTMLElement
        activeItem4.className = "furniture carousel-item";
      } finally {
        return "";
      }
    }
  }

  returnSliderActiveBuilding(index) {
    if (this.sliderIndexBuilding == index) {
      return "active"
    } else {
      return ""
    }
  }

  returnSliderActiveInterior(index) {
    if (this.sliderIndexInterior == index) {
      return "active"
    } else {
      return ""
    }
  }

  returnSliderActiveFurniture(index) {
    if (this.sliderIndexFurniture == index) {
      return "active"
    } else {
      return ""
    }
  }


}
